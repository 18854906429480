import { process, State } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { getter } from '@progress/kendo-react-common';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { GridColumn as Column, Grid, GridCellProps, GridDataStateChangeEvent, GridHeaderSelectionChangeEvent, GridSelectionChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { useLocalization } from '@progress/kendo-react-intl';
import React from 'react';

import { useAppSelector } from '../../app/StoreHooks';
import GrantAccess from '../../features/GrantAccess/GrantAccess';
import GridSearchBar from '../../features/GridSearchBar/GridSearchBar';
import { ANIMAL_ENTRY, ANIMAL_EXIT, COLUMNS, EXPORT } from '../../languages/languages';
import { selectAnimalsLoading } from '../../slices/AnimalSlice';
import { ColumnType } from '../../slices/data/AnimalColumnsSliceData';
import { AnimalType } from '../../slices/data/AnimalSliceData';
import ColumnMenu from '../ColumnMenu/ColumnMenu';
import { DateOfBirthCell, GridAnimalSexCell } from '../CustomGridCells/CustomGridCells';
import GridLoaderPanel from '../GridLoaderPanel/GridLoaderPanel';

const REQUIRED_ROLES: string[] = [
  'Admin',
  'Farm Manager',
  'Holding Owner',
  'Farm Veterinary Inspector',
  'Abattoir Veterinary Inspector',
];
const DATA_ITEM_KEY: string = 'animalId';
const SELECTED_FIELD: string = 'selected';
const idGetter = getter(DATA_ITEM_KEY);

type AnimalTablePops = {
  data: AnimalType[];
  dataState: State;
  selectedState: { [id: string]: boolean | number[] };
  dataStateChange: (e: GridDataStateChangeEvent) => void;
  onSelectionChange: ({ dataItem, syntheticEvent }: GridSelectionChangeEvent) => void;
  onHeaderSelectionChange: (event: GridHeaderSelectionChangeEvent) => void;
  setDataState: React.Dispatch<React.SetStateAction<State>>;
  handleMenuVisibility: () => void;
  togglePreview: () => void;
  stateColumns: ColumnType[];
  cell: ({ dataItem }: GridCellProps) => JSX.Element;
};

const AnimalTable = ({
  data,
  dataState,
  selectedState,
  dataStateChange,
  onSelectionChange,
  onHeaderSelectionChange,
  setDataState,
  handleMenuVisibility,
  togglePreview,
  stateColumns,
  cell,
}: AnimalTablePops) => {
  const localizationService = useLocalization();
  const loadingState = useAppSelector(selectAnimalsLoading);
  const exportRef = React.useRef<ExcelExport | null>(null);
  const gridRef = React.useRef<any>();

  const excelExport = () => {
    if (exportRef.current !== null) {
      // pass the products, instead the paginated data in the state.
      exportRef.current.save(data, gridRef.current.columns);
    }
  };

  return (
    <>
      <ExcelExport ref={exportRef} />
      <Grid
        sortable
        pageable
        {...dataState}
        data={process(
          data.map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
          })),
          dataState
        )}
        onDataStateChange={dataStateChange}
        dataItemKey={DATA_ITEM_KEY}
        selectedField={SELECTED_FIELD}
        selectable={{
          enabled: true,
          drag: false,
          cell: false,
          mode: 'multiple',
        }}
        onSelectionChange={onSelectionChange}
        onHeaderSelectionChange={onHeaderSelectionChange}
        ref={gridRef}
      >
        <GridToolbar>
          <div className='toolbar-section'>
            <GridSearchBar dataState={dataState} filterBy='lID' setDataState={setDataState} />
            <Button
              name='AnimalTableColumns'
              type='button'
              title={localizationService.toLanguageString(COLUMNS, '')}
              primary
              onClick={handleMenuVisibility}
              icon='align-justify'
              className='ml-s'
            >
              {localizationService.toLanguageString(COLUMNS, '')}
            </Button>
          </div>
          <div className='toolbar-section ml-auto'>
            <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
              <Button
                name='ATCattleGridEntryBtn'
                type='button'
                title={localizationService.toLanguageString(ANIMAL_ENTRY, '')}
                icon='plus'
                primary
              >
                {localizationService.toLanguageString(ANIMAL_ENTRY, '')}
              </Button>
              <Button
                name='ATCattleGridExitBtn'
                type='button'
                title={localizationService.toLanguageString(ANIMAL_EXIT, '')}
                primary
                look='outline'
                icon='logout'
                onClick={togglePreview}
                className='ml-s'
              >
                {localizationService.toLanguageString(ANIMAL_EXIT, '')}
              </Button>
            </GrantAccess>
            <Button
              name='ATCattleGridExportBtn'
              type='button'
              title={localizationService.toLanguageString(EXPORT, '')}
              icon='download'
              primary
              look='outline'
              className='ml-s'
              onClick={excelExport}
            >
              {localizationService.toLanguageString(EXPORT, '')}
            </Button>
          </div>
        </GridToolbar>
        <Column
          field={SELECTED_FIELD}
          headerSelectionValue={data.findIndex((item) => !selectedState[idGetter(item)]) === -1}
          headerClassName='text-center'
        />
        {stateColumns.map((column) => {
          if (column.show && column.field === 'sheepBreed') {
            return (
              <Column
                key={column.field}
                field={column.field}
                title={column.title}
                filter={column.filter}
                format={column.format}
                cell={cell}
                columnMenu={(props) => <ColumnMenu {...props} />}
              />
            );
          }
          if (column.show && column.field === 'dateOfBirth') {
            return (
              <Column
                key={column.field}
                field={column.field}
                title={column.title}
                filter={column.filter}
                format={column.format}
                cell={DateOfBirthCell}
                columnMenu={(props) => <ColumnMenu {...props} />}
              />
            );
          }
          if (column.show && column.field === 'sex') {
            return (
              <Column
                key={column.field}
                field={column.field}
                title={column.title}
                filter={column.filter}
                format={column.format}
                cell={GridAnimalSexCell}
                columnMenu={(props) => <ColumnMenu {...props} />}
              />
            );
          }
          if (column.show) {
            return (
              <Column
                key={column.field}
                field={column.field}
                title={column.title}
                filter={column.filter}
                format={column.format}
                columnMenu={(props) => <ColumnMenu {...props} />}
              />
            );
          }
          return null;
        })}
      </Grid>
      {loadingState && <GridLoaderPanel />}
    </>
  );
};

export default AnimalTable;
