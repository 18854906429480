import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import {
  Field,
  Form,
  FormElement,
  FormRenderProps
} from '@progress/kendo-react-form';
import { useLocalization } from '@progress/kendo-react-intl';
import {
  SAVE,
  CANCEL,
  DATE,
  USED_PESTICIDE_ACTIVE_CHEMICAL,
  USED_PESTICIDE_ENTRY_BAN_PERIOD,
  USED_PESTICIDE_HECTARE_NORM,
  USED_PESTICIDE_MOWING_BAN_PERIOD,
  USED_PESTICIDE_NAME,
  USED_PESTICIDE_NUMBER,
  USED_PESTICIDE_OPERATOR,
  USED_PESTICIDE_TOTAL,
  USED_PESTICIDE_TREATED_AREA,
  USED_PESTICIDE_TREATED_CULTURE
} from '../../languages/languages';
import { UsedPesticideLogType } from '../../slices/data/UsedPesticideLogSliceData';
import { FormDatePicker, FormInput, FormNumericTextBox } from '../FormComponents/FormComponents';

type UsedPesticideLogFormProps = {
  submit: (formData: UsedPesticideLogType) => void;
  close: () => void;
  item?: UsedPesticideLogType;
};
const UsedPesticideLogForm = ({ submit, close, item }: UsedPesticideLogFormProps) => {
  const localizationService = useLocalization();
  return (
    <Form
      initialValues={item}
      onSubmit={submit}
      render={(formRenderProps: FormRenderProps) =>
        (
          <FormElement className='two-column-grid'>
            <fieldset className='k-form-fieldset'>
              <Field
                name='pesticideDate'
                id='pesticideDate'
                label={localizationService.toLanguageString(DATE, '')}
                format='dd-MM-yyyy'
                component={FormDatePicker}
              />
              <Field
                name='operator'
                id='operator'
                label={localizationService.toLanguageString(
                  USED_PESTICIDE_OPERATOR,
                  ''
                )}
                component={FormInput}
              />
              <Field
                name='pesticideName'
                id='pesticideName'
                label={localizationService.toLanguageString(
                  USED_PESTICIDE_NAME,
                  ''
                )}
                component={FormInput}
              />
              <Field
                name='pesticideActiveChemical'
                id='pesticideActiveChemical'
                label={localizationService.toLanguageString(
                  USED_PESTICIDE_ACTIVE_CHEMICAL,
                  ''
                )}
                component={FormInput}
              />
              <Field
                name='partNumber'
                id='partNumber'
                label={localizationService.toLanguageString(
                  USED_PESTICIDE_NUMBER,
                  ''
                )}
                component={FormInput}
              />
              <Field
                name='treatedCulture'
                id='treatedCulture'
                label={localizationService.toLanguageString(
                  USED_PESTICIDE_TREATED_CULTURE,
                  ''
                )}
                component={FormInput}
              />
            </fieldset>
            <fieldset className='k-form-fieldset'>
              <Field
                name='normHectare'
                id='normHectare'
                label={localizationService.toLanguageString(
                  USED_PESTICIDE_HECTARE_NORM,
                  ''
                )}
                component={FormInput}
              />
              <Field
                name='treatedHectare'
                id='treatedHectare'
                label={localizationService.toLanguageString(
                  USED_PESTICIDE_TREATED_AREA,
                  ''
                )}
                component={FormNumericTextBox}
              />
              <Field
                name='total'
                id='total'
                label={localizationService.toLanguageString(
                  USED_PESTICIDE_TOTAL,
                  ''
                )}
                component={FormInput}
              />
              <Field
                name='entryBanPeriod'
                id='entryBanPeriod'
                label={localizationService.toLanguageString(
                  USED_PESTICIDE_ENTRY_BAN_PERIOD,
                  ''
                )}
                component={FormNumericTextBox}
              />
              <Field
                name='mowingBanPeriod'
                id='mowingBanPeriod'
                label={localizationService.toLanguageString(
                  USED_PESTICIDE_MOWING_BAN_PERIOD,
                  ''
                )}
                component={FormNumericTextBox}
              />
            </fieldset>
            <div className='k-form-buttons'>
              <Button
                name='UsedPesticideAddNew'
                type='submit'
                title={localizationService.toLanguageString(SAVE, '')}
                primary
                disabled={!formRenderProps.allowSubmit}
                icon='save'
              >
                {localizationService.toLanguageString(SAVE, '')}
              </Button>
              <Button
                name='UsedPesticideCancel'
                type='submit'
                title={localizationService.toLanguageString(CANCEL, '')}
                primary
                look='outline'
                icon='cancel'
                onClick={close}
              >
                {localizationService.toLanguageString(CANCEL, '')}
              </Button>
            </div>
          </FormElement>
        )}
    />
  );
};

UsedPesticideLogForm.defaultProps = {
  item: {}
};

export default UsedPesticideLogForm;
