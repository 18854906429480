import React, { useEffect, useState } from 'react';
import { useLocalization } from '@progress/kendo-react-intl';
import { Dialog } from '@progress/kendo-react-dialogs';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import PurchasedMedicinesGrid from '../../components/PurchasedMedicinesGrid/PurchasedMedicinesGrid';
import {
  editMecicineLog,
  getPurchasedMedicines,
  saveMedicineLog,
  selectPurchasedMedicines
} from '../../slices/PurchasedMedicinesSlice';
import PurchasedMedicineForm from '../../components/PurchasedMedicineForm/PurchasedMedicineForm';
import {
  PurchasedMedicineType,
  SaveMedicineLogType
} from '../../slices/data/PurchasedMedicinesSliceData';
import { getSupplierFirms } from '../../slices/FirmsAddressBookSlice';
import { ADD_NEW, EDIT } from '../../languages/languages';
import GrantAccess from '../GrantAccess/GrantAccess';

const REQUIRED_ROLES: string[] = [
  'Admin',
  'Holding Owner',
  'Farm Manager',
  'Farm Veterinary Inspector'
];

const PurchasedMedicinesContainer = () => {
  const localizationService = useLocalization();
  const dispatch = useAppDispatch();
  const purchasedMedicines = useAppSelector(selectPurchasedMedicines);

  const [showForm, setShowForm] = useState<boolean>(false);
  const [editForm, setEditForm] = useState(false);
  const [logEntryToEdit, setLogEntryToEdit] = useState<PurchasedMedicineType>(null);

  const closeForm = () => {
    setShowForm(false);
  };

  const openForm = () => {
    setShowForm(true);
    dispatch(getSupplierFirms());
  };

  const closeEditForm = () => {
    setEditForm(false);
  };

  const showEditForm = (editItem: PurchasedMedicineType) => {
    setEditForm(true);
    dispatch(getSupplierFirms());
    setLogEntryToEdit(editItem);
  };

  const submitForm = (formData: SaveMedicineLogType) => {
    dispatch(saveMedicineLog(formData));
    closeForm();
  };

  const editMedicineLogEntry = (formData: PurchasedMedicineType) => {
    const updatedMedicine: SaveMedicineLogType = {
      medicineId: formData.medicineId,
      comment: formData.comment,
      expiryDate: formData.expiryDate,
      lotNumber: formData.lotNumber,
      medicineCategory: formData.medicineCategory,
      medicineName: formData.medicineName,
      purchaseDate: formData.purchaseDate,
      quantity: formData.quantity,
      withdrawalPeriodMeat: formData.withdrawalPeriodMeat,
      withdrawalPeriodMilk: formData.withdrawalPeriodMilk,
      firmABId: formData.firm.firmABId
    };
    dispatch(editMecicineLog(updatedMedicine));
    closeEditForm();
  };

  useEffect(() => {
    const awaitData = () => {
      dispatch(getPurchasedMedicines());
    };
    awaitData();
  }, []);

  return (
    <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
      <PurchasedMedicinesGrid
        data={purchasedMedicines}
        openForm={openForm}
        openEditForm={showEditForm}
      />
      {showForm && (
        <Dialog
          title={localizationService.toLanguageString(ADD_NEW, '')}
          onClose={closeForm}
        >
          <PurchasedMedicineForm close={closeForm} submitForm={submitForm} />
        </Dialog>
      )}
      {editForm && (
        <Dialog
          title={localizationService.toLanguageString(EDIT, '')}
          onClose={closeEditForm}
        >
          <PurchasedMedicineForm
            close={closeEditForm}
            submitForm={editMedicineLogEntry}
            editItem={logEntryToEdit}
          />
        </Dialog>
      )}
    </GrantAccess>
  );
};

export default PurchasedMedicinesContainer;
