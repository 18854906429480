import { Button } from '@progress/kendo-react-buttons';
import { DateInputChangeEvent } from '@progress/kendo-react-dateinputs';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Loader } from '@progress/kendo-react-indicators';
import { NumericTextBoxChangeEvent } from '@progress/kendo-react-inputs';
import { useLocalization } from '@progress/kendo-react-intl';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import { CANCEL, SAVE } from '../../languages/bg_BG';
import { DATE } from '../../languages/languages';
import { ITraderWarehouseItemHistory } from '../../models/warehouse';
import warehouseService from '../../services/warehouse.service';
import { ITraderWarehouseGridItem } from '../../slices/data/WarehouseSliceData';
import { FormDatePicker, FormNumericTextBox } from '../FormComponents/FormComponents';

const TraderWarehouseItemUpdate = ({
  batchCutId,
  onClose,
  onUpdate
}: {
  batchCutId: number;
  onClose: () => void;
  onUpdate: () => void;
}) => {
  const localizationService = useLocalization();
  const [item, setItem] = useState<ITraderWarehouseGridItem>();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ITraderWarehouseItemHistory>({} as ITraderWarehouseItemHistory);
  const soldQuantityValidator = (value: number) => {
    if (!value) {
      return 'Това поле е задължително';
    }

    if (value > item.remainingWeight) {
      return 'Продаденото количество не може да е по-голямо от наличното';
    }

    if (value <= 0) {
      return 'Продаденото количество трябва да е по-голямо от 0';
    }

    return '';
  };

  const sellDateValidator = (value: Date) => {
    if (!value) {
      return 'Това поле е задължително';
    }

    if (value > new Date()) {
      return 'Датата не може да е бъдеще време';
    }

    if (value > new Date(item.expirationDate)) {
      return 'Не може да се направи продажба с изтекъл срок на годност';
    }

    return '';
  };

  useEffect(() => {
    setLoading(true);
    warehouseService
      .getTraderWarehouseItem(batchCutId)
      .then((response) => {
        setItem(response);
        setData({
          ...data,
          wasteQuantity: 0,
          batchCutId: response.batchCutId,
          remainingQuantity: response.remainingWeight,
        });
      })
      .finally(() => setLoading(false));
  }, [batchCutId]);

  const onSubmit = () => {
    setLoading(true);
    warehouseService
      .createTraderWarehouseItemHistory(data)
      .then(() => {
        onUpdate();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSoldQuantityChange = ({ value }: NumericTextBoxChangeEvent) => {
    if (value <= 0) {
      return;
    }

    data.soldQuantity = value;
    data.remainingQuantity = item.remainingWeight - data.soldQuantity;
    setData(data);
  };

  const onEventDateChange = ({ value }: DateInputChangeEvent) => {
    data.eventDate = value;
  };

  return (
    <Dialog title='Складов артикул' onClose={onClose}>
      {loading && <Loader size='large' type='pulsing' className='k-center' />}
      {!loading && item && (
        <div>
          <div className='row'>
            <div className='col-12 col-md-6 mb-m'>
              <div className='mb-s fw-bold'>Партида №</div>
              <div>{item.batchNumber}</div>
            </div>
            <div className='col-12 col-md-6 mb-m'>
              <div className='mb-s fw-bold'>Сечение</div>
              <div>{item.cutName}</div>
            </div>
            <div className='col-12 col-md-6 mb-m'>
              <div className='mb-s fw-bold'>Срок на годност</div>
              <div>{moment(item.expirationDate).format('DD.MM.YYYY')}</div>
            </div>
            <div className='col-12 col-md-6 mb-m'>
              <div className='mb-s fw-bold'>Наличност (кг.)</div>
              <div>{item.remainingWeight}</div>
            </div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-12'>
              <Form
                initialValues={data}
                onSubmit={onSubmit}
                key={JSON.stringify(item)}
                render={(formRenderProps: FormRenderProps) => (
                  <FormElement>
                    <div className='sell-details-form'>
                      <fieldset className='k-form-fieldset'>
                        <legend className='subtitle-1 mb-s ml-xs'>
                          Продажба
                          {/* {localizationService.toLanguageString(DEAL_DATA, '')} */}
                        </legend>
                        <Field
                          key='eventDate'
                          id='eventDate'
                          name='eventDate'
                          label={localizationService.toLanguageString(DATE, '')}
                          format='dd-MMM-yyyy'
                          optional
                          component={FormDatePicker}
                          validator={sellDateValidator}
                          onChange={onEventDateChange}
                        />
                        <Field
                          id='soldQuantity'
                          name='soldQuantity'
                          label='Продадено количество'
                          component={FormNumericTextBox}
                          validator={soldQuantityValidator}
                          onChange={onSoldQuantityChange}
                        />
                      </fieldset>
                      <fieldset className='k-form-fieldset'>
                        <legend className='subtitle-1 mb-s ml-xs'>
                          Наличност след продажба: {data.remainingQuantity} кг.
                        </legend>
                      </fieldset>
                    </div>
                    <hr />
                    <Button
                      name='SubmitBtn'
                      type='submit'
                      primary
                      title='Приеми'
                      disabled={!formRenderProps.valid}
                    >
                      {localizationService.toLanguageString(SAVE, '')}
                    </Button>
                    <Button
                      name='cancelBtn'
                      type='button'
                      title='Откажи'
                      className='ml-s'
                      onClick={onClose}
                    >
                      {localizationService.toLanguageString(CANCEL, '')}
                    </Button>
                  </FormElement>
                )}
              />
            </div>
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default TraderWarehouseItemUpdate;
