export const UNECE_TITLE_FARMER = 'UNECE_TITLE_FARMER';
export const PRODUCING_SYSTEM = 'PRODUCING_SYSTEM';
export const FEEDING_SYSTEM = 'FEEDING_SYSTEM';
export const UNECE_MENU_TITLE = 'UNECE_MENU_TITLE';
export const UNECE_TITLE_PROCESSOR = 'UNECE_TITLE_PROCESSOR';
export const BOVINE_QUALITY_SYSTEM = 'BOVINE_QUALITY_SYSTEM';
export const CONFORMITY_ASSESSMENT = 'CONFORMITY_ASSESSMENT';
export const FAT_THICKNESS = 'FAT_THICKNESS';
export const PACKING_CODES = 'PACKING_CODES';
export const POST_SLAUGHTER_SYSTEM = 'POST_SLAUGHTER_SYSTEM';
export const REFRIGERATION = 'REFRIGERATION';
export const SLAUGHTER_SYSTEM = 'SLAUGHTER_SYSTEM';
export const WEIGHT_RANGING = 'WEIGHT_RANGING';

export default {
  BOVINE_QUALITY_SYSTEM,
  CONFORMITY_ASSESSMENT,
  FAT_THICKNESS,
  FEEDING_SYSTEM,
  PACKING_CODES,
  POST_SLAUGHTER_SYSTEM,
  PRODUCING_SYSTEM,
  REFRIGERATION,
  SLAUGHTER_SYSTEM,
  WEIGHT_RANGING,
  UNECE_MENU_TITLE,
  UNECE_TITLE_FARMER,
  UNECE_TITLE_PROCESSOR
};
