import { Button } from '@progress/kendo-react-buttons';
import React, { useEffect } from 'react';
import { BrowserRouter, Switch, useHistory } from 'react-router-dom';

import { useAppDispatch } from '../../app/StoreHooks';
import AmbulatoryLogContainer from '../../features/AmbulatoryLogContainer/AmbulatoryLogContainer';
import AnimalCoolingContainer from '../../features/AnimalCooling/AnimalCoolingContainer';
import ArtificialInseminationContainer from '../../features/ArtificialInseminationContainer/ArtificialInseminationContainer';
import ButcheredAnimalsTableContainer from '../../features/ButcheredAnimalsTable/ButcheredAnimalsTable';
import ButcheryAnimalTableContainer from '../../features/ButcheryAnimalTableContainer/ButcheryAnimalTableContainer';
import ButcheryProcessContainer from '../../features/ButcheryProcessContainer/ButcheryProcessContainer';
import ButcherySecondarytempMeasurement from '../../features/ButcheryProcessContainer/ButcherySecondaryTempMeasure';
import CarcassQualityGrading from '../../features/CarcassQualityGradingContainer/CarcassQualityGradingContainer';
import ChemicalTreatmentLogContainer from '../../features/ChemicalTreatmentLogContainer/ChemicalTreatmentLogContainer';
import DrawerContainer from '../../features/DrawerContainer/DrawerContainer';
import EnterpriseWarehouseContainer from '../../features/EnterpriseWarehouse/EnterpriseWarehouseContainer';
import FarmerVirtualWarehouse from '../../features/FarmerVirtualWarehouse/FarmerVirtualWarehouse';
import HoldingProfileContainer from '../../features/HoldingProfileContainer/HoldingProfileContainer';
import MortalityLogContainer from '../../features/MortalityLogContainer/MortalityLogContainer';
import PastureLogContainer from '../../features/PastureLogContainer/PastureLogContainer';
import PestControlLogContainer from '../../features/PestControlLogContainer/PestControlLogContainer';
import PurchasedMedicinesContainer from '../../features/PurchasedMedicinesContainer/PurchasedMedicinesContainer';
import PrivateRouteWithLayout from '../../features/Routing/PrivateRoute';
import RouteWithLayout from '../../features/Routing/RouteWithLayout';
import TraderDealsContainer from '../../features/TraderDeals/TraderDealsContainer';
import TraderWarehouseContainer from '../../features/TraderWarehouse/TraderWarehouseContainer';
import UsedPesticideLogContainer from '../../features/UsedPesticideLogContainer/UsedPesticideLogContainer';
import { getAllTypes } from '../../slices/HoldingSlice';
import { getNotifications } from '../../slices/NotificationsSlice';
import AddressBook from '../AddressBook/AddressBook';
import ButcheryProcessTabs from '../ButcheryProcess/ButcheryProcessTabs';
import Dashboard from '../Dashboard/Dashboard';
import DealsContainer from '../DealsContainer/DealsContainer';
import EnterprisePublicData from '../EnterprisePublicData/EnterprisePublicData';
import FarmAnimalTableContainer from '../FarmAnimalTableContainer/FarmAnimalTableContainer';
import FarmerPublicData from '../FarmerPublicData/FarmerPublicData';
import FodderLogs from '../FodderLogs/FodderLogs';
import Logs from '../Logs/Logs';
import MovementRegistrationContainer from '../MovementRegistrationContainer/MovementRegistrationContainer';
import MovementSaleContainer from '../MovementSaleContainer/MovementSaleContainer';
import UnregisterContainer from '../MovementUnregistrationContainer/MovementUnregisterContainer';
import ShoppingCenterPublicData from '../ShoppingCenterPublicData/ShoppingCenterPublicData';
import UneceEnterpriceSettings from '../UneceEnterpriceSettings/UneceEnterpriceSettings';
import UneceFarmSettings from '../UneceFarmSettings/UneceFarmSettings';

const NoAuthLayout: React.FC = ({ children }) => (
  <div style={{ backgroundColor: 'rosybrown ' }}>{children}</div>
);

const PublicLayout: React.FC = ({ children }) => (
  <div>{children}</div>
);

const LoginOrCandidate = () => {
  const history = useHistory();
  const onLoginClick = () => history.push('/dashboard');
  return (
    <div className='k-p-10'>
      <h1> Welcome to BLB Trace</h1>
      <p>
        If you want to candidate, please, click candidate or if have already been approved click
        login
      </p>
      <Button className='k-mr-2'> Candidate </Button>
      <Button onClick={onLoginClick}> Login </Button>
    </div>
  );
};

const Layout = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const onLoad = async () => {
      dispatch(getNotifications());
      dispatch(getAllTypes());
    };
    onLoad();
  }, []);

  return (
    <BrowserRouter>
      <Switch>
        {/* Public routes */}
        <RouteWithLayout path='/' layout={NoAuthLayout} exact component={LoginOrCandidate} />
        <RouteWithLayout
          path='/public/farm/:id'
          layout={PublicLayout}
          exact
          component={FarmerPublicData}
        />
        <RouteWithLayout
          path='/public/enterprise/:id'
          layout={PublicLayout}
          exact
          component={EnterprisePublicData}
        />
        <RouteWithLayout
          path='/public/shoppingcenter/:id'
          layout={PublicLayout}
          exact
          component={ShoppingCenterPublicData}
        />
        {/* Private routes */}
        <PrivateRouteWithLayout
          exact
          path='/dashboard'
          layout={DrawerContainer}
          component={Dashboard}
        />

        {/* Farmer Zone Routes */}
        <PrivateRouteWithLayout
          exact
          path='/farmer/logs'
          layout={DrawerContainer}
          component={Logs}
        />
        <PrivateRouteWithLayout
          exact
          path='/farmer/logs/pastures-entry'
          layout={DrawerContainer}
          component={PastureLogContainer}
        />
        <PrivateRouteWithLayout
          exact
          path='/farmer/logs/fodder'
          layout={DrawerContainer}
          component={FodderLogs}
        />
        <PrivateRouteWithLayout
          exact
          path='/farmer/logs/ambulatory-logs'
          layout={DrawerContainer}
          component={AmbulatoryLogContainer}
        />
        <PrivateRouteWithLayout
          exact
          path='/farmer/logs/purchased-medicines'
          layout={DrawerContainer}
          component={PurchasedMedicinesContainer}
        />
        <PrivateRouteWithLayout
          exact
          path='/farmer/logs/artificial-insemination'
          layout={DrawerContainer}
          component={ArtificialInseminationContainer}
        />
        <PrivateRouteWithLayout
          exact
          path='/farmer/logs/mortality'
          layout={DrawerContainer}
          component={MortalityLogContainer}
        />
        <PrivateRouteWithLayout
          exact
          path='/farmer/logs/chemical-treatment'
          layout={DrawerContainer}
          component={ChemicalTreatmentLogContainer}
        />
        <PrivateRouteWithLayout
          exact
          path='/farmer/logs/pest-control'
          layout={DrawerContainer}
          component={PestControlLogContainer}
        />
        <PrivateRouteWithLayout
          exact
          path='/farmer/logs/used-pesticide'
          layout={DrawerContainer}
          component={UsedPesticideLogContainer}
        />
        <PrivateRouteWithLayout
          exact
          path='/farmer/animals-table-farm'
          layout={DrawerContainer}
          component={FarmAnimalTableContainer}
        />
        <PrivateRouteWithLayout
          exact
          path='/farmer/deals'
          layout={DrawerContainer}
          component={DealsContainer}
        />
        <PrivateRouteWithLayout
          exact
          path='/farmer/movements/registration'
          layout={DrawerContainer}
          component={MovementRegistrationContainer}
        />
        <PrivateRouteWithLayout
          exact
          path='/farmer/movements/unregistration'
          layout={DrawerContainer}
          component={UnregisterContainer}
        />
        <PrivateRouteWithLayout
          exact
          path='/farmer/movements/sale'
          layout={DrawerContainer}
          component={MovementSaleContainer}
        />
        <PrivateRouteWithLayout
          exact
          path='/farmer/virtual-warehouse'
          layout={DrawerContainer}
          component={FarmerVirtualWarehouse}
        />

        {/* Processor Zone Routes */}
        <PrivateRouteWithLayout
          exact
          path='/dashboard'
          layout={DrawerContainer}
          component={Dashboard}
        />
        <PrivateRouteWithLayout
          exact
          path='/processor/logs'
          layout={DrawerContainer}
          component={Logs}
        />
        <PrivateRouteWithLayout
          exact
          path='/processor/animals-table-butchery'
          layout={DrawerContainer}
          component={ButcheryAnimalTableContainer}
        />
        <PrivateRouteWithLayout
          exact
          path='/processor/butchered-animals-table'
          layout={DrawerContainer}
          component={ButcheredAnimalsTableContainer}
        />
        <PrivateRouteWithLayout
          exact
          path='/processor/deals'
          layout={DrawerContainer}
          component={DealsContainer}
        />
        <PrivateRouteWithLayout
          exact
          path='/processor/butchery-process'
          layout={DrawerContainer}
          component={ButcheryProcessContainer}
        />
        <PrivateRouteWithLayout
          exact
          path='/processor/butchery-process/create'
          layout={DrawerContainer}
          component={ButcheryProcessTabs}
        />
        <PrivateRouteWithLayout
          exact
          path='/processor/warehouse'
          layout={DrawerContainer}
          component={EnterpriseWarehouseContainer}
        />
        <PrivateRouteWithLayout
          exact
          path='/processor/butchery-process/secondary-temp-measure/:id'
          layout={DrawerContainer}
          component={ButcherySecondarytempMeasurement}
        />
        <PrivateRouteWithLayout
          exact
          path='/processor/carcass-quality-grading'
          layout={DrawerContainer}
          component={CarcassQualityGrading}
        />
        <PrivateRouteWithLayout
          exact
          path='/processor/animal-cooling'
          layout={DrawerContainer}
          component={AnimalCoolingContainer}
        />

        {/* trader routes */}
        <PrivateRouteWithLayout
          exact
          path='/trader/warehouse'
          layout={DrawerContainer}
          component={TraderWarehouseContainer}
        />
        <PrivateRouteWithLayout
          exact
          path='/trader/deals'
          layout={DrawerContainer}
          component={TraderDealsContainer}
        />-
        {/* Remaining general routes */}
        <PrivateRouteWithLayout
          exact
          path='/address-book'
          layout={DrawerContainer}
          component={AddressBook}
        />
        <PrivateRouteWithLayout
          exact
          path='/holding-profile'
          layout={DrawerContainer}
          component={HoldingProfileContainer}
        />
        <PrivateRouteWithLayout
          exact
          path='/holding/unecefarmsettings'
          layout={DrawerContainer}
          component={UneceFarmSettings}
        />
        <PrivateRouteWithLayout
          exact
          path='/holding/uneceEnterpriceSettings'
          layout={DrawerContainer}
          component={UneceEnterpriceSettings}
        />
      </Switch>
    </BrowserRouter>
  );
};

export default Layout;
