/* eslint-disable import/no-cycle */

import { configureStore } from '@reduxjs/toolkit';

import ambulatoryReducer from '../slices/AmbulatoryLogSlice';
import animalColumnsReducer from '../slices/AnimalColumnsSlice';
import animalObjectsReducer from '../slices/AnimalObjectSlice';
import animalReducer from '../slices/AnimalSlice';
import artificialInseminationReducer from '../slices/ArtificialInseminationSlice';
import chemicalTreatmentsReducer from '../slices/ChemicalTreatmentLogSlice';
import contragentsReducer from '../slices/ContragentsSlice';
import dealsSliceReducer from '../slices/DealsSlice';
import drawerReducer from '../slices/DrawerSlice';
import addressBookEmployeesReducer from '../slices/EmployeesSlice';
import enterpriseReducer from '../slices/EnterpriseSlice';
import errorReducer from '../slices/ErrorSlice';
import firmsAddressBookReducer from '../slices/FirmsAddressBookSlice';
import firmReducer from '../slices/FirmSlice';
import fodderLogReducer from '../slices/FodderLogSlice';
import holdinReducer from '../slices/HoldingSlice';
import languageReducer from '../slices/LanguageSlice';
import menuReducer from '../slices/MenuSlice';
import mortalityLogReducer from '../slices/MortalityLogSlice';
import movementReducer from '../slices/MovementSlice';
import notificationsReducer from '../slices/NotificationsSlice';
import pastureLogReducer from '../slices/PastureLogSlice';
import pestControlLogReducer from '../slices/PestControlSlice';
import purchasedMedicinesReducer from '../slices/PurchasedMedicinesSlice';
import searchReducer from '../slices/SearchSlice';
import sellOperationReducer from '../slices/SellOperationSlice';
import shoppingCenterReducer from '../slices/ShoppingCenterSlice';
import trademarksReducer from '../slices/TrademarkSlice';
import trucksReducer from '../slices/TrucksSlice';
import usedPesticideLogReducer from '../slices/UsedPesticideLogSlice';
import userReducer from '../slices/UserSlice';

const store = configureStore({
  reducer: {
    user: userReducer,
    notificactions: notificationsReducer,
    drawer: drawerReducer,
    search: searchReducer,
    firms: firmReducer,
    holding: holdinReducer,
    animals: animalReducer,
    animalColumns: animalColumnsReducer,
    errors: errorReducer,
    language: languageReducer,
    animalObjects: animalObjectsReducer,
    enterprises: enterpriseReducer,
    shoppingCenter: shoppingCenterReducer,
    trademarks: trademarksReducer,
    contragents: contragentsReducer,
    firmsAddressBook: firmsAddressBookReducer,
    addressBookEmployees: addressBookEmployeesReducer,
    sellOperation: sellOperationReducer,
    deals: dealsSliceReducer,
    mortalityLog: mortalityLogReducer,
    ambulatoryLogs: ambulatoryReducer,
    artificialInsemenation: artificialInseminationReducer,
    pastureLog: pastureLogReducer,
    trucks: trucksReducer,
    purchasedMedicines: purchasedMedicinesReducer,
    fodderLog: fodderLogReducer,
    chemicalTreatementsLog: chemicalTreatmentsReducer,
    pestControlLog: pestControlLogReducer,
    usedPesticideLog: usedPesticideLogReducer,
    menu: menuReducer,
    movement: movementReducer
  }
});

// Export the store
export default store;
