import { Button } from '@progress/kendo-react-buttons';
import { GridCellProps } from '@progress/kendo-react-grid';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import { ColumnType } from '../../slices/data/AnimalColumnsSliceData';
import { IBatch } from '../../slices/data/ButcheryProcessSliceData';
import NonSelectableGrid from '../SelectableGrid/NonSelectableGrid';

const colums = [
  {
    title: 'Партида №',
    field: 'number',
    show: true,
    filter: 'text',
  } as ColumnType,
  {
    title: 'Дата',
    field: 'date',
    show: true,
    filter: 'date',
    format: '{0:dd.MM.yyy}',
  } as ColumnType,
  {
    title: 'Кланица',
    field: 'enterprise',
    show: true,
    filter: 'text',
  } as ColumnType,
  {
    title: 'Брой животни в партидата',
    field: 'animalsCount',
    show: true,
    filter: 'numeric',
  } as ColumnType,
];

const UnfinishedBatchesGrid = ({ data }: { data: IBatch[] }) => {
  useEffect(() => {
    data.forEach((d) => {
      d.date = new Date(d.date);
    });
  }, [data]);

  // const onShowEdit = (item: IBatch) => {
  //   console.log(item);
  // };

  const actionCell = (item: GridCellProps) => {
    console.log(item);

    return (
      <td className='d-flex justify-content-center'>
        <Link to={`/processor/butchery-process/secondary-temp-measure/${item.dataItem.id}`} style={{ textDecoration: 'none' }}>
          <Button
            data-testid='editButton'
            type='button'
            className='k-button k-secondary mr-m'
            iconClass='fas fa-temperature-frigid'
            look='outline'
          />
        </Link>

        {/* <Button
          data-testid='editButton'
          type='button'
          className='k-button k-info'
          icon='eye'
          look='outline'
          onClick={() => onShowEdit(item.dataItem)}
        /> */}
      </td>
    );
  };

  return (
    <div>
      <NonSelectableGrid data={data} stateColumns={colums} actionCell={actionCell} />
    </div>
  );
};

export default UnfinishedBatchesGrid;
