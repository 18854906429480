import CattleBreeds from '../../features/FarmAnimalTableCattle/cattleBreeds';
import SheepBreeds from '../../features/FarmAnimalTableSheep/sheepBreeds';
import datesUtil from '../../utils/dates';
import { AnimalSexEnum, AnimalTypeEnum } from './AnimalSliceData';

export interface IAnimalInButchery {
  animalId: number;
  arrivalDate: Date;
  age: number;
  lID: string;
  dateOfBirth: Date;
  cattleBreed: CattleBreeds;
  sheepBreed: SheepBreeds;
  sex: AnimalSexEnum;
  isCastrated: boolean;
  animalType: AnimalTypeEnum;
  weight?: number;
  butcheredDate: Date;
  processingAsService: boolean;
  isWaste: boolean;
}

export class AnimalInButchery implements IAnimalInButchery {
  constructor(obj: IAnimalInButchery) {
    this.animalId = obj.animalId;
    this.animalType = obj.animalType;
    this.lID = obj.lID;
    this.dateOfBirth = new Date(obj.dateOfBirth);
    this.cattleBreed = obj.cattleBreed;
    this.sheepBreed = obj.sheepBreed;
    this.sex = obj.sex;
    this.weight = obj.weight;
    this.arrivalDate = new Date(obj.arrivalDate);
    this.butcheredDate = new Date(obj.butcheredDate);
    this.processingAsService = obj.processingAsService || false;
    this.isWaste = obj.isWaste || false;
    this.isCastrated = obj.isCastrated || false;
    this.age = datesUtil.monthDiff(this.dateOfBirth, new Date());
  }

  animalId: number;

  animalType: AnimalTypeEnum;

  lID: string;

  dateOfBirth: Date;

  cattleBreed: CattleBreeds;

  sheepBreed: SheepBreeds;

  sex: AnimalSexEnum;

  isCastrated: boolean;

  weight?: number;

  arrivalDate: Date;

  butcheredDate: Date;

  processingAsService: boolean;

  isWaste: boolean;

  // calculates from DOB
  age: number;
}

export class AnimalInButcheryGridModel extends AnimalInButchery implements IAnimalInButchery {
  selected = false;
}

export interface IBatch {
  id?: number;
  number: string;
  date: Date;
  enterpriseId: number;
  enterpriseNumber: string;
  enterpriseCarcaseNextNumber: number;
  finished: boolean;
  createdOn: Date;
  createdBy: string;
  animals: IBatchAnimal[];
  wasteAnimals: IBatchAnimal[];
}

export interface IBatchAnimal extends IAnimalInButchery, IBatchAnimalCreate {
  id?: number;
  cutsCountName: string;
  cutsTypeName: string;
  secondPhT: IPhT;
  firstPhT: IPhT;
  cuts: IBatchCut[];
}

export interface IBatchCarcase {
  cutsCountId: number;
  cutsCountName: string;
  cutsTypeId: number;
  cutsTypeName: string;
  cuts: IBatchCut[];
  firstPhT: IPhT;
  secondPhT: IPhT;
}

export interface IBatchCut extends IBatchCutCreate {
  id?: number;
  cutName: string;
  cuttingTypeName: string;
}

export interface IPhT {
  id?: number;
  batchAnimalId?: number;
  measureNumber: 1 | 2;
  date: Date;
  temp: number;
  ph: number;
}

export interface ISeuropData {
  seuropCode: string;
  number: 1 | 2 | 3 | 4 | 5;
  plusMinusEquals: '+' | '-' | '=';
  usdaId: number;
  usdaText: string;
}

export interface IBatchCreate {
  number: string;
  date: Date;
  enterpriseId: number;
  batchAnimals: IBatchAnimalCreate[];
}

export interface IBatchAnimalCreate {
  age: number;
  ageClass: string;
  animalId: number;
  arrivalDate: Date;
  butcheredDate: Date;
  carcaseInternalNumber: number;
  carcaseNumberTrace: string;
  carcaseWeight: number;
  carcaseWeightCold: number;
  cuts: IBatchCutCreate[];
  cutsCountId: number;
  cutsTypeId: number;
  firstPhT: IPhTCreate;
  isWaste: boolean;
  number: 1 | 2 | 3 | 4 | 5;
  plusMinusEquals: '+' | '-' | '=';
  processingAsService: boolean;
  randeman: number;
  seuropCode: string;
  uneceCode: string;
  usdaId: number;
  usdaText: string;
}

export interface IBatchCutCreate {
  cutNameId: number;
  cuttingTypeId: number;
  weight: number;
  uneceCutCode: string
}

export interface IPhTCreate {
  date: Date;
  temp: number;
  ph: number;
}