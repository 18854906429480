import React, { useEffect, useState } from 'react';
import { useLocalization } from '@progress/kendo-react-intl';
import { CARCASS_QUALITY_GRADE, GENERAL_INFO } from '../../languages/languages';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import {
  getInspectedCarcasses,
  gradeAnimal,
  selectedVetInspectedAnimals
} from '../../slices/AnimalSlice';
import {
  AnimalGradingFormType,
  AnimalToGradeType
} from '../../slices/data/AnimalSliceData';
import {
  getAllABEmployees,
  selectAddressBookEmployees
} from '../../slices/EmployeesSlice';
import CarcassQualityGradingForm from '../../components/CarcassQualityGradingForm/CarcassQualityGradingForm';

const CarcassQualityGrading = () => {
  const [initValues, setInitValues] = useState({});
  const localizationService = useLocalization();

  const dispatch = useAppDispatch();

  const butcheredAnimals = useAppSelector(selectedVetInspectedAnimals);
  const activeUsers = useAppSelector(selectAddressBookEmployees);

  useEffect(() => {
    const awaitData = () => {
      dispatch(getInspectedCarcasses());
      dispatch(getAllABEmployees());
    };
    awaitData();
  }, []);

  const submitGrading = (formData: AnimalGradingFormType) => {
    const gradedAnimal: AnimalToGradeType = {
      animalId: formData.animalId,
      ageClass: formData.ageClass,
      sEUROPClass: formData.seuropMuscleClass.concat(
        formData.muscleClassification,
        formData.fatClass.toString()
      ),
      muscleClass: formData.muscleClass,
      fatColor: formData.fatColor,
      ossifiScore: formData.ossifiScore,
      teethFormula: formData.teethFormula,
      meatColor: formData.meatColor,
      carcassYield: formData.carcassYield,
      carcassQuality: formData.carcassQuality,
      userId: formData.inspector,
      sEUROPDate: formData.inspectionDate
    };
    dispatch(gradeAnimal(gradedAnimal));
    setInitValues({});
  };

  return (
    <>
      <h1 className='page-title'>{localizationService.toLanguageString(CARCASS_QUALITY_GRADE, '')}</h1>
      <div className='content-wrapper section'>
        <h3>
          {localizationService.toLanguageString(GENERAL_INFO, '')}
          :
        </h3>
        <CarcassQualityGradingForm
          animals={butcheredAnimals}
          users={activeUsers}
          handleSubmit={submitGrading}
          initValues={initValues}
        />
      </div>
    </>
  );
};

export default CarcassQualityGrading;
