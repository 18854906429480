import { gql } from '@apollo/client';

// Types
export type UsedPesticideLogType = {
  usedPesticideId?: number;
  pesticideDate: Date;
  operator: string;
  pesticideName: string;
  pesticideActiveChemical: string;
  partNumber: string;
  treatedCulture: string;
  normHectare: string;
  treatedHectare: number;
  total: string;
  entryBanPeriod: number;
  mowingBanPeriod: number;
};

export type GetAllUsedPesticideEntries = {
  items: UsedPesticideLogType[]
};

export type UsedPesticideInputType = {
  usedPesticideDiaryInput: UsedPesticideLogType;
};

// Queries & Mutations
export const GET_ALL_USED_PESTICIDE_ENTRIES = gql`
  {
    usedPesticideDiary {
      items {
        usedPesticideId
        pesticideDate
        operator
        pesticideName
        pesticideActiveChemical
        partNumber
        treatedCulture
        normHectare
        treatedHectare
        total
        entryBanPeriod
        mowingBanPeriod
      }
    }
  }
`;

export const SAVE_USED_PESTICIDE_ENTRY = gql`mutation AddUsedPesticideDiary(
    $usedPesticideDiaryInput: usedPesticideDiaryInput
  ) {
    addUsedPesticideDiary(usedPesticideDiaryInput: $usedPesticideDiaryInput) {
      usedPesticideId
      pesticideDate
      operator
      pesticideName
      pesticideActiveChemical
      partNumber
      treatedCulture
      normHectare
      treatedHectare
      total
      entryBanPeriod
      mowingBanPeriod
    }
  }
  `;

export const EDIT_USED_PESTICIDE_ENTRY = gql`mutation UpdateUsedPesticideDiary(
    $usedPesticideDiaryInput: usedPesticideDiaryInput
  ) {
    updateUsedPesticideDiary(usedPesticideDiaryInput: $usedPesticideDiaryInput) {
      usedPesticideId
      pesticideDate
      operator
      pesticideName
      pesticideActiveChemical
      partNumber
      treatedCulture
      normHectare
      treatedHectare
      total
      entryBanPeriod
      mowingBanPeriod
    }
  }`;
