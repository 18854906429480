import { gql } from '@apollo/client';
import {
  FERTILIZATION_ARTIFICIALLY,
  FERTILIZATION_NATURALLY,
  PREGNANCY_RESULT_NO,
  PREGNANCY_RESULT_UNID,
  PREGNANCY_RESULT_YES
} from '../../languages/languages';
import { AnimalType } from './AnimalSliceData';

// Types & Enums
enum FertilizationEnum {
  NATURALLY = 'NATURALLY',
  AI = 'AI'
}

export const fertilizationVariants = [
  {
    value: FertilizationEnum.NATURALLY,
    label: FERTILIZATION_NATURALLY
  },
  {
    value: FertilizationEnum.AI,
    label: FERTILIZATION_ARTIFICIALLY
  }
];

export enum PregnancyCheckEnum {
  YES = 'YES',
  NO = 'NO',
  UNIDENTIFIELD = 'UNIDENTIFIELD'
}

export const pregnancyChecks = [
  { value: PregnancyCheckEnum.YES, label: PREGNANCY_RESULT_YES },
  { value: PregnancyCheckEnum.NO, label: PREGNANCY_RESULT_NO },
  { value: PregnancyCheckEnum.UNIDENTIFIELD, label: PREGNANCY_RESULT_UNID }
];

export type ArtificialInsemenationType = {
  aIDiaryId: number;
  sireLID: string;
  fertilizationStart: Date;
  fertilizationEnd: Date;
  pregnancyCheckDate: Date;
  expectedBornDate: Date;
  pregnancyState: number;
  animalId: number;
  pregnancyCheck: PregnancyCheckEnum;
  fertilization: FertilizationEnum;
  animal: AnimalType;
};

export type GetArtificialInsemenationsType = {
  items: ArtificialInsemenationType[];
};

export type AddArtificialInsemenationType = {
  aIDiaryId?: number;
  animalId: number;
  sireLID: string;
  fertilizationStart: Date;
  fertilizationEnd: Date;
  pregnancyCheckDate: Date;
  expectedBornDate: Date;
  pregnancyState: number;
  pregnancyCheck: PregnancyCheckEnum;
  fertilization: FertilizationEnum;
};

export type AddArtInsemInputType = {
  aIDiaryInput: AddArtificialInsemenationType;
};

// Queries & Mutations
export const GET_ALL_ARTIFICIAL_INSEMENATIONS = gql`
  {
    aIDiary {
      items {
        aIDiaryId
        fertilization
        sireLID
        fertilizationStart
        fertilizationEnd
        pregnancyCheckDate
        pregnancyCheck
        pregnancyState
        expectedBornDate
        animal {
          animalId
          lID
        }
      }
    }
  }
`;

export const SAVE_ARTIFICIAL_INSEMENATION = gql`
  mutation AddAIDiary($aIDiaryInput: aIDiaryInput) {
    addAIDiary(aIDiaryInput: $aIDiaryInput) {
      aIDiaryId
      sireLID
      fertilizationStart
      fertilizationEnd
      pregnancyCheckDate
      expectedBornDate
      pregnancyState
      pregnancyCheck
      fertilization
      animal {
        animalId
        lID
      }
    }
  }
`;

export const EDIT_ARTIFICIAL_INSEMENATION = gql`
  mutation UpdateAIDiary($aIDiaryInput: aIDiaryInput) {
    updateAIDiary(aIDiaryInput: $aIDiaryInput) {
      aIDiaryId
      sireLID
      fertilizationStart
      fertilizationEnd
      pregnancyCheckDate
      expectedBornDate
      pregnancyState
      pregnancyCheck
      fertilization
      animal {
        animalId
        lID
      }
    }
  }
`;
