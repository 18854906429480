import { Dialog } from '@progress/kendo-react-dialogs';
import {
  Field,
  Form,
  FormElement,
} from '@progress/kendo-react-form';
import { Loader } from '@progress/kendo-react-indicators';
import { useLocalization } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';

import {
  BUY_FIRM_EIK,
  BUY_FIRM_MOL,
  BUY_FIRM_NAME,
  BUYING_FIRM,
  DEAL,
  DEAL_DATE,
  PRICE_PER_KILO,
  SELL_FIRM_EIK,
  SELL_FIRM_MOL,
  SELL_FIRM_NAME,
  SELLING_FIRM,
} from '../../languages/languages';
import ProcessorToTraderSalesService from '../../services/processorToTraderSales.service';
import {
  FormDateTimePicker,
  FormInput,
  FormNumericTextBox,
} from '../FormComponents/FormComponents';
import { requiredValidator } from '../Validators/Validators';
import { ARRIVAL_DATE } from '../../languages/bg_BG';
import ItemsDisplayGrid from './DealItemsDisplayGrid';

const ProcessorToTraderDealReadonlyModal = ({
  dealId,
  onClose,
}: {
  dealId: number;
  onClose: () => void;
}) => {
  const localizationService = useLocalization();
  const [loading, setLoading] = useState(false);
  const [dealData, setDealData] = useState<any>({});

  // const shoppingCenters = useAppSelector(selectShoppingCenters);

  useEffect(() => {
    ProcessorToTraderSalesService.getDeal(dealId)
      .then((data) => {
        data.dealDate = new Date(data.dealDate);

        if (data.arrivalDate) {
          data.arrivalDate = new Date(data.arrivalDate);
        }

        data.items.forEach((i) => {
          i.item.batchDate = new Date(i.item.batchDate);
        });

        setDealData(data);
      })
      .finally(() => setLoading(false));
  }, []);

  return (
    <Dialog
      title={localizationService.toLanguageString(DEAL, '')}
      id='DealInformationWindow'
      onClose={onClose}
    >
      {!loading && (
        <Form
          initialValues={dealData}
          key={JSON.stringify(dealData)}
          render={() => (
            <FormElement>
              <div className='sell-details-form'>
                {/* Selling Firm Information */}
                <fieldset className='k-form-fieldset'>
                  <legend className='subtitle-1 mb-s ml-xs'>
                    {localizationService.toLanguageString(SELLING_FIRM, '')}
                  </legend>
                  <Field
                    id='sellFirm.name'
                    name='sellFirm.name'
                    label={localizationService.toLanguageString(SELL_FIRM_NAME, '')}
                    component={FormInput}
                    disabled
                    value={dealData.sellFirm?.name}
                  />
                  <Field
                    id='sellFirmEIK'
                    name='sellFirm.eIK'
                    label={localizationService.toLanguageString(SELL_FIRM_EIK, '')}
                    component={FormInput}
                    disabled
                  />
                  <Field
                    id='sellFirmMOL'
                    name='sellFirm.mOL'
                    label={localizationService.toLanguageString(SELL_FIRM_MOL, '')}
                    component={FormInput}
                    disabled
                  />
                </fieldset>

                {/* Buying Firm Information */}
                <fieldset className='k-form-fieldset'>
                  <legend className='subtitle-1 mb-s ml-xs'>
                    {localizationService.toLanguageString(BUYING_FIRM, '')}
                  </legend>
                  <Field
                    id='buyFirm.name'
                    name='buyFirm.name'
                    label={localizationService.toLanguageString(BUY_FIRM_NAME, '')}
                    component={FormInput}
                    disabled
                  />
                  <Field
                    id='buyFirmEIK'
                    name='buyFirm.eIK'
                    label={localizationService.toLanguageString(BUY_FIRM_EIK, '')}
                    component={FormInput}
                    disabled
                  />
                  <Field
                    id='buyFirmMOL'
                    name='buyFirm.mOL'
                    label={localizationService.toLanguageString(BUY_FIRM_MOL, '')}
                    component={FormInput}
                    disabled
                  />
                </fieldset>
                <fieldset className='k-form-fieldset'>
                  <legend className='subtitle-1 mb-s ml-xs'>
                    Данни за сделката
                    {/* {localizationService.toLanguageString(DEAL_DATA, '')} */}
                  </legend>
                  <Field
                    key='dealDate'
                    id='dealDate'
                    name='dealDate'
                    label={localizationService.toLanguageString(DEAL_DATE, '')}
                    format='dd-MMM-yyyy HH:mm'
                    optional
                    component={FormDateTimePicker}
                    disabled
                  />
                  <Field
                    id='pricePerKilo'
                    name='pricePerKilo'
                    label={localizationService.toLanguageString(PRICE_PER_KILO, '')}
                    component={FormNumericTextBox}
                    validator={requiredValidator}
                    disabled
                  />
                </fieldset>
                <fieldset className='k-form-fieldset'>
                  <legend className='subtitle-1 mb-s ml-xs'>
                    Артикули
                    {/* {localizationService.toLanguageString(DEAL_DATA, '')} */}
                  </legend>
                  <div className='mb-l'>
                    <ItemsDisplayGrid data={dealData.items} />
                  </div>
                </fieldset>
                <fieldset className='k-form-fieldset'>
                  <legend className='subtitle-1 mb-s ml-xs'>Данни за приемане</legend>
                  <Field
                    key='arrivalDate'
                    id='arrivalDate'
                    name='arrivalDate'
                    label={localizationService.toLanguageString(ARRIVAL_DATE, '')}
                    format='dd-MMM-yyyy HH:mm'
                    optional
                    component={FormDateTimePicker}
                    validator={requiredValidator}
                    disabled
                  />
                  <Field
                    id='shoppingCenterName'
                    name='shoppingCenterName'
                    label='Насочи към търговски обект'
                    component={FormInput}
                    validator={requiredValidator}
                    disabled
                  />
                </fieldset>
              </div>
            </FormElement>
          )}
        />
      )}
      {loading && <Loader size='large' type='pulsing' className='k-center' />}
    </Dialog>
  );
};

export default ProcessorToTraderDealReadonlyModal;
