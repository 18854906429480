import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import TransportFIrmsTrucksGrid from '../../components/TransportFIrmsTrucksGrid/TransportFIrmsTrucksGrid';
import TruckForm from '../../components/TruckForm/TruckForm';
import { AddTruckType, TruckType } from '../../slices/data/TrucksSliceData';
import { getTransportFirms } from '../../slices/FirmsAddressBookSlice';
import {
  addTruck,
  editTruck,
  getAllTrucks,
  selectAllTrucks
} from '../../slices/TrucksSlice';
import GrantAccess from '../GrantAccess/GrantAccess';

const REQUIRED_ROLES: string[] = [
  'Admin',
  'Holding Owner',
  'Farm Manager',
  'Farm Veterinary Inspector'
];
const CU_ROLES: string[] = ['Admin', 'Holding Owner', 'Farm Manager'];

const TransportFirmsTrucks = () => {
  const dispatch = useAppDispatch();
  const allTrucks = useAppSelector(selectAllTrucks);

  const [addFormView, setAddFormView] = useState<boolean>(false);

  useEffect(() => {
    const awaitData = () => {
      dispatch(getAllTrucks());
    };
    awaitData();
  }, []);

  const handleAddFormView = () => {
    setAddFormView(!addFormView);
    dispatch(getTransportFirms());
  };

  const submitNewTruck = (formData: AddTruckType) => {
    dispatch(addTruck(formData));
    setAddFormView(!addFormView);
  };

  // Edit form state and functions
  const [openEditView, setOpenEditView] = useState(false);
  const [editItem, setEditItem] = useState<TruckType>(null);
  const enterEdit = (item: TruckType) => {
    setOpenEditView(true);
    setEditItem(item);
    dispatch(getTransportFirms());
  };

  const closeEditView = () => {
    setOpenEditView(false);
  };

  const submitEditEntry = async (formData: TruckType) => {
    const editedTruck: AddTruckType = {
      truckId: formData.truckId,
      comment: formData.comment,
      firmABId: formData.parentFirm.firmABId,
      truckCapacity: formData.truckCapacity,
      truckLoad: formData.truckLoad,
      truckPlate: formData.truckPlate
    };
    dispatch(editTruck(editedTruck));
    closeEditView();
  };

  return (
    <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
      <TransportFIrmsTrucksGrid
        data={allTrucks}
        addNew={handleAddFormView}
        edit={enterEdit}
      />
      <GrantAccess haveRoles='some' requiredRoles={CU_ROLES}>
        {addFormView && (
        <TruckForm
          type='add'
          close={handleAddFormView}
          submit={submitNewTruck}
        />
        )}
        {openEditView && (
        <TruckForm
          type='edit'
          close={closeEditView}
          submit={submitEditEntry}
          defaultItem={editItem}
        />
        )}
      </GrantAccess>
    </GrantAccess>
  );
};

export default TransportFirmsTrucks;
