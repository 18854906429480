import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Loader } from '@progress/kendo-react-indicators';
import { useLocalization } from '@progress/kendo-react-intl';
import React, { useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import RejectionConfirmation from '../../components/RejectionConfirmation/RejectionConfirmation';
import { DEAL, DEAL_ACCEPT, DEAL_ACCEPT_PARTIAL, DEAL_REJECT } from '../../languages/languages';
import { acceptAllAnimals, rejectAllAnimals, selectAcceptedAnimals, selectSingleDeal } from '../../slices/DealsSlice';
import DealInformationFormContainer from '../DealInformationFormContainer/DealInformationFormContainer';
import DealInformationGridContainer from '../DealInformationGridContainer/DealInformationGridContainer';
import GrantAccess from '../GrantAccess/GrantAccess';

const REQUIRED_ROLES: string[] = ['Admin', 'Farm Manager'];

type DealInformationProps = {
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
  onAccept: () => void;
  onReject: () => void;
};
const DealInformation = ({
  setVisibility,
  onAccept,
  onReject
}: DealInformationProps) => {
  const localizationService = useLocalization();
  const dispatch = useAppDispatch();

  const deal = useAppSelector(selectSingleDeal);
  const acceptedAnimals = useAppSelector(selectAcceptedAnimals);

  const [confirmDealRejection, setConfirmDealRejection] = useState<boolean>(false);

  const rejectionValidationVisibility = () => {
    setConfirmDealRejection(!confirmDealRejection);
  };

  const closeDialog = () => {
    setVisibility(false);
  };

  const handleSubmit = () => {
    const acceptedDeal = {
      saleEventID: deal.saleEventID,
      arrivalDate: deal.arrivalDate,
      saleEventStatus: 'ACCEPTED',
      animalWeights: deal.animalWeights.map((animal) =>
        ({
          animalID: animal.animalId,
          weight: animal.arrivalWeight,
          comment: animal.arrivalNote,
          dateWeight: deal.arrivalDate,
          weightType: 'ENTRYSALEWEIGHT'
        }))
    };

    dispatch(acceptAllAnimals(acceptedDeal)).then(() => {
      closeDialog();
      onAccept();
    });
  };

  // Partially accept the deal with only the selected animals
  const handlePartialSubmit = () => {
    // Filter through all of the animals in the deal
    // and only return the selected ones, with the updated data in the grid
    // const animalsToAccept = deal?.animalWeights.filter((animal) =>
    // acceptedAnimals.indexOf(animal.animalId));
    const animalsToAccept = deal?.animalWeights.filter((animal) =>
      acceptedAnimals.includes(animal.animalId));
    const acceptedDeal = {
      saleEventID: deal.saleEventID,
      arrivalDate: deal.arrivalDate,
      saleEventStatus: 'PARTIALLY',
      animalWeights: animalsToAccept.map((animal) =>
        ({
          animalID: animal.animalId,
          weight: animal.arrivalWeight,
          comment: animal.arrivalNote,
          dateWeight: deal.arrivalDate,
          // Can use a conditional operator to also update the non-selected animals
          // to have a rejected ENUM type
          weightType: 'ENTRYSALEWEIGHT'
        }))
    };
    dispatch(acceptAllAnimals(acceptedDeal)).then(() => {
      closeDialog();
      onAccept();
    });
  };

  const handleDealRejection = () => {
    setConfirmDealRejection(!confirmDealRejection);
    dispatch(
      rejectAllAnimals({
        saleEventID: deal.saleEventID,
        saleEventStatus: 'CANCELED'
      })
    ).then(() => {
      closeDialog();
      onReject();
    });
  };

  return (
    <Dialog title={localizationService.toLanguageString(DEAL, '')} onClose={closeDialog} id='DealInformationWindow'>
      {deal ? (
        <>
          {deal?.saleEventStatus === 'PENDING' && (
            <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
              <div className='deal-actions'>
                <Button onClick={handleSubmit} primary>
                  {localizationService.toLanguageString(DEAL_ACCEPT, '')}
                </Button>
                <Button
                  onClick={handlePartialSubmit}
                  primary
                  disabled={acceptedAnimals.length < 1}
                >
                  {localizationService.toLanguageString(DEAL_ACCEPT_PARTIAL, '')}
                </Button>
                <Button onClick={rejectionValidationVisibility} primary>
                  {localizationService.toLanguageString(DEAL_REJECT, '')}
                </Button>
              </div>
            </GrantAccess>
          )}
          <DealInformationFormContainer />
          <DealInformationGridContainer />
        </>
      ) : (
        <Loader size='large' type='pulsing' className='k-centered' />
      )}
      {confirmDealRejection && (
        <RejectionConfirmation
          close={rejectionValidationVisibility}
          accept={handleDealRejection}
        />
      )}
    </Dialog>
  );
};

export default DealInformation;
