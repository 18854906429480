import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import {
  Field,
  Form,
  FormElement,
  FormRenderProps
} from '@progress/kendo-react-form';
import { Loader } from '@progress/kendo-react-indicators';
import { useLocalization } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';

import {
  BUY_FIRM_EIK,
  BUY_FIRM_MOL,
  BUY_FIRM_NAME,
  BUYING_FIRM,
  CANCEL,
  DEAL,
  DEAL_DATE,
  PRICE_PER_KILO,
  SAVE,
  SELL_FIRM_EIK,
  SELL_FIRM_MOL,
  SELL_FIRM_NAME,
  SELLING_FIRM,
} from '../../languages/languages';
import ProcessorToTraderSalesService from '../../services/processorToTraderSales.service';
import {
  FormDateTimePicker,
  FormDropDownList,
  FormInput,
  FormNumericTextBox,
} from '../FormComponents/FormComponents';
import { requiredValidator } from '../Validators/Validators';
import ItemsApprovalGrid from './DealItemsApprovalGrid';
import { ISaleApproval } from '../../models/processorToTraderSaleModels';
import { ARRIVAL_DATE } from '../../languages/bg_BG';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import { selectShoppingCenters, getAllShoppingCenters } from '../../slices/ShoppingCenterSlice';
import { addSuccessMessage } from '../../slices/ErrorSlice';
import { successMessage } from '../../utils/utilities';

const ProcessorToTraderDealDisplayModal = ({
  dealId,
  onClose,
  onUpdate
}: {
  dealId: number;
  onClose: () => void;
  onUpdate: () => void;
}) => {
  const dispatch = useAppDispatch();
  const localizationService = useLocalization();
  const [loading, setLoading] = useState(false);
  const [dealData, setDealData] = useState<any>({});

  const shoppingCenters = useAppSelector(selectShoppingCenters);

  useEffect(() => {
    ProcessorToTraderSalesService.getDeal(dealId)
      .then((data) => {
        data.dealDate = new Date(data.dealDate);

        if (data.arrivalDate) {
          data.arrivalDate = new Date(data.arrivalDate);
        }

        data.items.forEach((i) => {
          i.item.batchDate = new Date(i.item.batchDate);
          i.accepted = true;
        });

        setDealData(data);
      })
      .finally(() => setLoading(false));

    const awaitData = async () => {
      await dispatch(getAllShoppingCenters());
    };
    awaitData();
  }, []);

  const accept = (id: number) => {
    const index = (dealData.items as []).findIndex((x: any) => x.item.batchCutId === id);

    if (index !== -1) {
      dealData.items[index].accepted = true;
    }

    setDealData({ ...dealData });
  };

  const reject = (id: number) => {
    const index = (dealData.items as []).findIndex((x: any) => x.item.batchCutId === id);

    if (index !== -1) {
      dealData.items[index].accepted = false;
    }

    setDealData({ ...dealData });
  };

  const sendApproval = (data: ISaleApproval) => {
    setLoading(true);
    ProcessorToTraderSalesService.approveSale(data)
      .then(() => {
        dispatch(
          addSuccessMessage({
            message: successMessage,
            id: Math.floor(Math.random() * 10),
          })
        );
        onUpdate();
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  const onSubmit = (formData: any) => {
    const data = {
      saleId: dealId,
      accepted: true,
      arrivalDate: formData.arrivalDate,
      shoppingCenterId: formData.shoppingCenter.shoppingCenterId,
      batchCutsIds: dealData.items.filter((x) => x.accepted).map((x) => x.item.batchCutId),
    } as ISaleApproval;

    sendApproval(data);
  };

  const onCancel = () => {
    const data = {
      saleId: dealId,
      accepted: false,
    } as ISaleApproval;

    sendApproval(data);
  };

  return (
    <Dialog
      title={localizationService.toLanguageString(DEAL, '')}
      id='DealInformationWindow'
      onClose={onClose}
    >
      {!loading && (
        <Form
          initialValues={dealData}
          onSubmit={onSubmit}
          key={JSON.stringify(dealData)}
          render={(formRenderProps: FormRenderProps) => (
            <FormElement>
              <div className='sell-details-form'>
                {/* Selling Firm Information */}
                <fieldset className='k-form-fieldset'>
                  <legend className='subtitle-1 mb-s ml-xs'>
                    {localizationService.toLanguageString(SELLING_FIRM, '')}
                  </legend>
                  <Field
                    id='sellFirm.name'
                    name='sellFirm.name'
                    label={localizationService.toLanguageString(SELL_FIRM_NAME, '')}
                    component={FormInput}
                    disabled
                    value={dealData.sellFirm?.name}
                  />
                  <Field
                    id='sellFirmEIK'
                    name='sellFirm.eIK'
                    label={localizationService.toLanguageString(SELL_FIRM_EIK, '')}
                    component={FormInput}
                    disabled
                  />
                  <Field
                    id='sellFirmMOL'
                    name='sellFirm.mOL'
                    label={localizationService.toLanguageString(SELL_FIRM_MOL, '')}
                    component={FormInput}
                    disabled
                  />
                </fieldset>

                {/* Buying Firm Information */}
                <fieldset className='k-form-fieldset'>
                  <legend className='subtitle-1 mb-s ml-xs'>
                    {localizationService.toLanguageString(BUYING_FIRM, '')}
                  </legend>
                  <Field
                    id='buyFirm.name'
                    name='buyFirm.name'
                    label={localizationService.toLanguageString(BUY_FIRM_NAME, '')}
                    component={FormInput}
                    disabled
                  />
                  <Field
                    id='buyFirmEIK'
                    name='buyFirm.eIK'
                    label={localizationService.toLanguageString(BUY_FIRM_EIK, '')}
                    component={FormInput}
                    disabled
                  />
                  <Field
                    id='buyFirmMOL'
                    name='buyFirm.mOL'
                    label={localizationService.toLanguageString(BUY_FIRM_MOL, '')}
                    component={FormInput}
                    disabled
                  />
                </fieldset>
                <fieldset className='k-form-fieldset'>
                  <legend className='subtitle-1 mb-s ml-xs'>
                    Данни за сделката
                    {/* {localizationService.toLanguageString(DEAL_DATA, '')} */}
                  </legend>
                  <Field
                    key='dealDate'
                    id='dealDate'
                    name='dealDate'
                    label={localizationService.toLanguageString(DEAL_DATE, '')}
                    format='dd-MMM-yyyy HH:mm'
                    optional
                    component={FormDateTimePicker}
                    disabled
                  />
                  <Field
                    id='pricePerKilo'
                    name='pricePerKilo'
                    label={localizationService.toLanguageString(PRICE_PER_KILO, '')}
                    component={FormNumericTextBox}
                    validator={requiredValidator}
                    disabled
                  />
                </fieldset>
                <fieldset className='k-form-fieldset'>
                  <legend className='subtitle-1 mb-s ml-xs'>
                    Артикули
                    {/* {localizationService.toLanguageString(DEAL_DATA, '')} */}
                  </legend>
                  <div className='mb-l'>
                    <ItemsApprovalGrid data={dealData.items} accept={accept} reject={reject} />
                  </div>
                </fieldset>
                <fieldset className='k-form-fieldset'>
                  <legend className='subtitle-1 mb-s ml-xs'>Данни за приемане</legend>
                  <Field
                    key='arrivalDate'
                    id='arrivalDate'
                    name='arrivalDate'
                    label={localizationService.toLanguageString(ARRIVAL_DATE, '')}
                    format='dd-MMM-yyyy HH:mm'
                    optional
                    component={FormDateTimePicker}
                    validator={requiredValidator}
                  />
                  <Field
                    id='shoppingCenter'
                    name='shoppingCenter'
                    label='Насочи към търговски обект'
                    component={FormDropDownList}
                    data={shoppingCenters}
                    textField='shoppingCenterName'
                    dataItemKey='shoppingCenterId'
                    validator={requiredValidator}
                  />
                </fieldset>
              </div>
              <hr />
              <Button
                name='SubmitBtn'
                type='submit'
                primary
                title='Приеми'
                disabled={!formRenderProps.valid}
              >
                {localizationService.toLanguageString(SAVE, '')}
              </Button>
              <Button
                name='cancelBtn'
                type='button'
                title='Откажи'
                className='ml-s'
                onClick={onCancel}
              >
                {localizationService.toLanguageString(CANCEL, '')}
              </Button>
            </FormElement>
          )}
        />
      )}
      {loading && <Loader size='large' type='pulsing' className='k-center' />}
    </Dialog>
  );
};

export default ProcessorToTraderDealDisplayModal;
