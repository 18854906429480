import { State } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { getter } from '@progress/kendo-react-common';
import { GridCellProps, GridDataStateChangeEvent, GridHeaderSelectionChangeEvent } from '@progress/kendo-react-grid';
import React, { useCallback, useEffect, useState } from 'react';

import { ColumnType } from '../../slices/data/AnimalColumnsSliceData';
import { IBatchAnimal, ISeuropData } from '../../slices/data/ButcheryProcessSliceData';
import SeuropModal from '../ButcheryProcess/SeuropModal';
import { CattleBreedCell } from '../CustomGridCells/CustomGridCells';
import AnimalTable from '../SelectableGrid/SelectableGrid';

const DATA_ITEM_KEY: string = 'animalId';
const idGetter = getter(DATA_ITEM_KEY);

interface AnimalGridProps {
  onSelect?: (items: IBatchAnimal[]) => void;
  animals: IBatchAnimal[];
  // butcheredDate: Date;
}

const cattleColumns: ColumnType[] = [
  {
    title: 'Труп вътрешен №',
    field: 'carcaseInternalNumber',
    show: true,
    filter: 'text',
  } as ColumnType,
  {
    title: 'Животно №',
    field: 'lID',
    show: true,
    filter: 'text',
  } as ColumnType,
  {
    title: 'Пол',
    field: 'sex',
    show: true,
    filter: 'text',
  } as ColumnType,
  {
    title: 'Възраст',
    field: 'age',
    show: true,
    filter: 'numeric',
  } as ColumnType,
  {
    title: 'Възраст клас',
    field: 'ageClass',
    show: true,
    filter: 'text',
  } as ColumnType,
  {
    title: 'Трупно тегло топло (кг)',
    field: 'carcaseWeight',
    show: true,
    filter: 'numeric',
  } as ColumnType,
  {
    title: 'Рандеман (%)',
    field: 'randeman',
    show: true,
    filter: 'numeric',
    format: '{0:##,#\\%}'
  } as ColumnType,
  {
    title: 'SEUROP',
    field: 'seuropCode',
    show: true,
    filter: 'text',
  } as ColumnType,
  {
    title: '12345',
    field: 'number',
    show: true,
    filter: 'numeric',
  } as ColumnType,
  {
    title: '=+-',
    field: 'plusMinusEquals',
    show: true,
    filter: 'text',
  } as ColumnType,
  {
    title: 'USDA',
    field: 'usdaText',
    show: true,
    filter: 'numeric',
  } as ColumnType,
] as ColumnType[];

const SeuropGrid = ({ onSelect = undefined, animals }: AnimalGridProps) => {
  const [items, setItems] = useState<IBatchAnimal[]>(animals);

  useEffect(() => {
    setItems([...animals]);
  }, []);

  useEffect(() => {
    setItems([...animals]);
  }, [animals]);

  // Grid States
  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0,
  });

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  };

  // Select animals
  // Local state for the grid's checkbox that will indicate
  // the state of the checkbox
  const [selectedState, setSelectedState] = useState<{
    [id: string]: boolean | number[];
  }>({});

  // Local state for the selected animals, that will be previewed in
  // the next step of the logic.
  // const [selectedAnimals, setSelectedAnimals] = useState<AnimalType[]>([]);
  const [allSelValue, setAllSelValue] = useState<boolean>(false);

  // Check/Uncheck row in the grid
  // Use a callback so that the function is not fully
  // rerendered every time the component re-renders.
  // It should only update if there are changes to the
  // selectedState local state
  const onSelectionChange = useCallback(async () => {}, [selectedState]);

  // Set all page elements to be checked
  const onHeaderSelectionChange = ({
    dataItems,
    syntheticEvent,
  }: GridHeaderSelectionChangeEvent) => {
    const checkboxElement: any = syntheticEvent.target;
    const { checked } = checkboxElement;
    const newSelectedState = {};
    const selAnimals: any[] = [];
    setAllSelValue(!allSelValue);
    dataItems.forEach(async (animal) => {
      newSelectedState[idGetter(animal)] = checked;
      if (newSelectedState[idGetter(animal)]) {
        selAnimals.push(animal);
      }
    });
    setSelectedState(newSelectedState);
    // setSelectedAnimals(selAnimals);
    if (onSelect) {
      onSelect(selAnimals);
    }
  };

  // Handle Menu visibility
  const handleMenuVisibility = () => {
    console.log('menu visibility changed');
  };

  const [showEdit, setShowEdit] = useState(false);
  const [editItem, seteditItem] = useState<IBatchAnimal>(null);
  const onShowEdit = (item: IBatchAnimal) => {
    seteditItem(item);
    setShowEdit(true);
  };

  const cancelEdit = () => {
    seteditItem(null);
    setShowEdit(false);
  };

  const handleEdit = (animalId: number, item: ISeuropData) => {
    const index = items.findIndex((i) => i.animalId === animalId);

    if (index !== -1) {
      items[index].seuropCode = item.seuropCode;
      items[index].number = item.number;
      items[index].plusMinusEquals = item.plusMinusEquals;
      items[index].usdaId = item.usdaId;
      items[index].usdaText = item.usdaText;

      setItems([...items]);
      onSelect(items);
    }

    cancelEdit();
  };

  const actionCell = ({ dataItem }: GridCellProps) => (
    <td className='text-center'>
      <Button
        data-testid='editButton'
        type='button'
        className='k-button k-secondary mr-l'
        icon='edit'
        look='outline'
        onClick={() => onShowEdit(dataItem)}
      />
    </td>
  );

  return (
    <>
      <AnimalTable
        data={items}
        dataState={dataState}
        dataStateChange={dataStateChange}
        handleMenuVisibility={handleMenuVisibility}
        onHeaderSelectionChange={onHeaderSelectionChange}
        onSelectionChange={onSelectionChange}
        selectedState={selectedState}
        setDataState={setDataState}
        stateColumns={cattleColumns}
        cell={CattleBreedCell}
        DATA_ITEM_KEY='animalId'
        showToolbar={false}
        showColumns={false}
        actionCell={actionCell}
        selectable={false}
      />
      {showEdit && <SeuropModal item={editItem} onSubmit={handleEdit} cancelEdit={cancelEdit} />}
    </>
  );
};

SeuropGrid.defaultProps = {
  onSelect: undefined,
};

export default SeuropGrid;
