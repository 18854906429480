import React, { useState } from 'react';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridToolbar
} from '@progress/kendo-react-grid';
import { State, process } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { useLocalization } from '@progress/kendo-react-intl';
import GridSearchBar from '../../features/GridSearchBar/GridSearchBar';
import ColumnMenu from '../ColumnMenu/ColumnMenu';
import { TruckType } from '../../slices/data/TrucksSliceData';
import { ADD_NEW } from '../../languages/languages';
import { EditCommandCell } from '../CustomGridCells/CustomGridCells';
import { useAppSelector } from '../../app/StoreHooks';
import { selectTrucksLoadingState } from '../../slices/TrucksSlice';
import GridLoaderPanel from '../GridLoaderPanel/GridLoaderPanel';
import GrantAccess from '../../features/GrantAccess/GrantAccess';

const REQUIRED_ROLES: string[] = ['Admin', 'Holding Owner', 'Farm Manager'];

type TransportFIrmsTrucksGridProps = {
  data: TruckType[];
  addNew: () => void;
  edit: (item: TruckType) => void;
};

const TransportFIrmsTrucksGrid = ({
  data,
  addNew,
  edit
}: TransportFIrmsTrucksGridProps) => {
  const localizationService = useLocalization();
  const loadingState = useAppSelector(selectTrucksLoadingState);
  // Grid States
  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0
  });

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  };

  const MyEditCommandCell = (props: GridCellProps) =>
    (
      <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
        <EditCommandCell {...props} enterEdit={edit} />
      </GrantAccess>
    );

  return (
    <>
      <Grid
        data={process(data, dataState)}
        pageable
        sortable
        {...dataState}
        onDataStateChange={dataStateChange}
      >
        <GridToolbar>
          <GridSearchBar
            filterBy='parentFirm.firmName'
            dataState={dataState}
            setDataState={setDataState}
          />
          <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
            <Button title='Add New' icon='plus' primary onClick={addNew}>
              {localizationService.toLanguageString(ADD_NEW, '')}
            </Button>
          </GrantAccess>
        </GridToolbar>
        <Column
          key='truckPlate'
          field='truckPlate'
          title='Рег. №'
          filter='text'
          columnMenu={(props) =>
            <ColumnMenu {...props} />}
        />
        <Column key='truckLoad' field='truckLoad' title='Товар' />
        <Column key='truckCapacity' field='truckCapacity' title='Капацитет' />
        <Column
          key='parentFirm.firmABName'
          field='parentFirm.firmABName'
          title='Фирма'
        />
        <Column key='comment' field='comment' title='Бележка' />
        <Column cell={MyEditCommandCell} width={120} />
      </Grid>
      {loadingState && <GridLoaderPanel />}
    </>
  );
};

export default TransportFIrmsTrucksGrid;
