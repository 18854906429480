/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/StoreHooks';
import {
  EDIT_PURCHASED_MEDICINE,
  GetPurchasedMedicineType,
  GET_PURCHASED_MEDICINE,
  PurchasedMedicineType,
  SaveMedicineInputType,
  SaveMedicineLogType,
  SAVE_PURCHASED_MEDICINE
} from './data/PurchasedMedicinesSliceData';
import {
  createAsyncThunkGeneric,
  createAsyncThunkGenericVoid
} from './GenericThunks';

type PurchasedMedicinesState = {
  medicines: PurchasedMedicineType[];
  medicinesLoading: boolean;
};

const initialState: PurchasedMedicinesState = {
  medicines: [],
  medicinesLoading: false
};

// Thunks
export const getPurchasedMedicines = createAsyncThunkGenericVoid(
  'purchasedMedicines/getPurchasedMedicines',
  async ({ client }) => {
    const response = await client.query<void, GetPurchasedMedicineType>(
      'medicineDiary',
      GET_PURCHASED_MEDICINE
    );
    return response?.items ? response?.items : [];
  }
);

export const saveMedicineLog = createAsyncThunkGeneric<
SaveMedicineLogType,
PurchasedMedicineType
>('purchasedMedicines/saveMedicineLog', async ({ client, input }) =>
  client.mutate<SaveMedicineInputType, PurchasedMedicineType>(
    'addMedicineDiary',
    SAVE_PURCHASED_MEDICINE,
    { medicineDiaryInput: input }
  ));

export const editMecicineLog = createAsyncThunkGeneric<
SaveMedicineLogType,
PurchasedMedicineType
>('purchasedMedicines/editMecicineLog', async ({ client, input }) =>
  client.mutate<SaveMedicineInputType, PurchasedMedicineType>(
    'updateMedicineDiary',
    EDIT_PURCHASED_MEDICINE,
    { medicineDiaryInput: input }
  ));

const PurchasedMedicinesSlice = createSlice({
  name: 'purchasedMedicines',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPurchasedMedicines.pending, (state) => {
        state.medicinesLoading = true;
      })
      .addCase(getPurchasedMedicines.fulfilled, (state, { payload }) => {
        state.medicinesLoading = false;
        state.medicines = payload.map((medicine) =>
          ({
            ...medicine,
            expiryDate: new Date(medicine?.expiryDate),
            purchaseDate: new Date(medicine?.purchaseDate)
          }));
      })
      .addCase(saveMedicineLog.pending, (state) => {
        state.medicinesLoading = true;
      })
      .addCase(saveMedicineLog.fulfilled, (state, { payload }) => {
        state.medicinesLoading = false;
        state.medicines.push({
          ...payload,
          expiryDate: new Date(payload?.expiryDate),
          purchaseDate: new Date(payload?.purchaseDate)
        });
      })
      .addCase(editMecicineLog.pending, (state) => {
        state.medicinesLoading = true;
      })
      .addCase(editMecicineLog.fulfilled, (state, { payload }) => {
        state.medicinesLoading = false;
        const uptdIndex = state.medicines.findIndex(
          (medicine) =>
            medicine.medicineId === payload.medicineId
        );
        state.medicines[uptdIndex] = {
          ...payload,
          expiryDate: new Date(payload?.expiryDate),
          purchaseDate: new Date(payload?.purchaseDate)
        };
      });
  }
});

// Selectors
export const selectPurchasedMedicines = (state: RootState) =>
  state.purchasedMedicines.medicines;
export const selectPurchasedMedicineLoadingState = (state: RootState) =>
  state.purchasedMedicines.medicinesLoading;

// Reducer
export default PurchasedMedicinesSlice.reducer;
