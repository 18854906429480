import { Button } from '@progress/kendo-react-buttons';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Loader } from '@progress/kendo-react-indicators';
import { useLocalization } from '@progress/kendo-react-intl';
import React, { useCallback, useEffect, useState } from 'react';

import { useAppDispatch } from '../../app/StoreHooks';
import GrantAccess from '../../features/GrantAccess/GrantAccess';
import { SAVE } from '../../languages/languages';
import { FEEDING_SYSTEM, PRODUCING_SYSTEM, UNECE_TITLE_FARMER } from '../../languages/keys/unece.keys';
import { INomenclatureResponceData, NomenclatureNames } from '../../slices/data/NomenclatureData';
import { IUneceFarmSettings } from '../../slices/data/UneceSliceData';
import { getNomenclatureValues } from '../../slices/NomenclatureSlice';
import { getFarmSettings, updateFarmSettings } from '../../slices/UneceSlice';
import { FormDropDownList } from '../FormComponents/FormComponents';

const REQUIRED_ROLES: string[] = ['Admin', 'Holding Owner'];
let initialValues = null;

const uneceFarmSettings = () => {
  const localizationService = useLocalization();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);
  const [feedingSystems, setFeedingSystems] = useState([]);
  const [productionSystems, setProductionSystems] = useState([]);

  const [model, setModel] = useState({
    feedingSystemId: undefined,
    productionSystemId: undefined,
  });

  const [defaultValues, setDefaultValues] = useState({
    feedingSystemId: null,
    productionSystemId: null,
  });

  // declare the async data fetching function
  const fetchData = useCallback(async () => {
    const { payload } = await dispatch(getFarmSettings());
    let modelData: IUneceFarmSettings = null;
    if (payload) {
      modelData = {
        feedingSystemId: payload ? (payload as IUneceFarmSettings).feedingSystemId : null,
        productionSystemId: payload ? (payload as IUneceFarmSettings).productionSystemId : null,
      };
      setModel(modelData);
    }

    const promises = [
      dispatch(getNomenclatureValues(NomenclatureNames.feedingSystem)),
      dispatch(getNomenclatureValues(NomenclatureNames.productionSystem)),
    ];

    Promise.all(promises).then((result) => {
      setFeedingSystems((result[0].payload as INomenclatureResponceData).values);
      setProductionSystems((result[1].payload as INomenclatureResponceData).values);

      if (modelData) {
        const feedingDefault = (result[0].payload as INomenclatureResponceData).values.find(
          (x) => x.id === modelData.feedingSystemId
        );
        const productionDefault = (result[1].payload as INomenclatureResponceData).values.find(
          (x) => x.id === modelData.productionSystemId
        );

        setDefaultValues({
          feedingSystemId: feedingDefault,
          productionSystemId: productionDefault,
        });

        if (!initialValues) {
          initialValues = {
            feedingSystemId: feedingDefault,
            productionSystemId: productionDefault,
          };
        }
      }

      setLoading(false);
    });
  }, []);

  useEffect(() => {
    fetchData().catch(console.log);
  }, [fetchData]);

  const onFieldChange = (e: DropDownListChangeEvent) => {
    setModel({
      ...model,
      [e.target.name]: e.target.value.id,
    });

    setDefaultValues({
      ...defaultValues,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = () => {
    setLoading(true);
    dispatch(updateFarmSettings(model)).then(() => {
      setLoading(false);
    });
  };

  return (
    <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
      <h1 className='page-title'>{localizationService.toLanguageString(UNECE_TITLE_FARMER, '')}</h1>
      {!loading && (
        <Form
          initialValues={defaultValues}
          onSubmitClick={onSubmit}
          render={(formRenderProps: FormRenderProps) => (
            <FormElement>
              <div className='mb-3'>
                <div>
                  <div className='row'>
                    <div className='col-12 col-md-4 col-xl-3 pl-s pr-s'>
                      <Field
                        id='productionSystemId'
                        name='productionSystemId'
                        label={localizationService.toLanguageString(PRODUCING_SYSTEM, '')}
                        component={FormDropDownList}
                        data={productionSystems}
                        textField='text'
                        dataItemKey='id'
                        value={defaultValues.productionSystemId}
                        onChange={onFieldChange}
                      />
                    </div>
                    <div className='col-12 col-md-4 col-xl-3 pl-s pr-s'>
                      <Field
                        id='feedingSystemId'
                        name='feedingSystemId'
                        label={localizationService.toLanguageString(FEEDING_SYSTEM, '')}
                        component={FormDropDownList}
                        data={feedingSystems}
                        textField='text'
                        dataItemKey='id'
                        onChange={onFieldChange}
                        value={defaultValues.feedingSystemId}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className='d-flex justify-content-center mb-l align-items-center'>
                <Button
                  name='submit'
                  type='submit'
                  title={localizationService.toLanguageString(SAVE, '')}
                  primary
                  iconClass='fa fa-save'
                  disabled={!formRenderProps.allowSubmit}
                >
                  {localizationService.toLanguageString(SAVE, '')}
                </Button>
              </div>
            </FormElement>
          )}
        />
      )}
      {loading && <Loader size='large' type='pulsing' className='k-centered' />}
    </GrantAccess>
  );
};

export default uneceFarmSettings;
