import React, { useState } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridToolbar
} from '@progress/kendo-react-grid';
import { useLocalization } from '@progress/kendo-react-intl';
import { State, process } from '@progress/kendo-data-query';
import GridSearchBar from '../../features/GridSearchBar/GridSearchBar';
import { UsedPesticideLogType } from '../../slices/data/UsedPesticideLogSliceData';
import ColumnMenu from '../ColumnMenu/ColumnMenu';
import { DateCell, EditCommandCell } from '../CustomGridCells/CustomGridCells';
import {
  USED_PESTICIDE_LOG_TITLE,
  DATE,
  ADD_NEW,
  USED_PESTICIDE_OPERATOR,
  USED_PESTICIDE_NAME,
  USED_PESTICIDE_ACTIVE_CHEMICAL,
  USED_PESTICIDE_NUMBER,
  USED_PESTICIDE_TREATED_CULTURE,
  USED_PESTICIDE_HECTARE_NORM,
  USED_PESTICIDE_TREATED_AREA,
  USED_PESTICIDE_TOTAL,
  USED_PESTICIDE_ENTRY_BAN_PERIOD,
  USED_PESTICIDE_MOWING_BAN_PERIOD
} from '../../languages/languages';
import { useAppSelector } from '../../app/StoreHooks';
import { selectUsedPesticideLoadingState } from '../../slices/UsedPesticideLogSlice';
import GridLoaderPanel from '../GridLoaderPanel/GridLoaderPanel';

type UsedPesticideLogGridProps = {
  data: UsedPesticideLogType[];
  addNew: () => void;
  editItem: (editItem: UsedPesticideLogType) => void;
};
const UsedPesticideLogGrid = ({ data, addNew, editItem }: UsedPesticideLogGridProps) => {
  const localizationService = useLocalization();
  const loadingState = useAppSelector(selectUsedPesticideLoadingState);
  // Grid States
  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0
  });

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  };

  const MyEditCommandCell = (props: GridCellProps) =>
    (
      <EditCommandCell {...props} enterEdit={editItem} />
    );

  return (
    <>
      <h1 className='page-title'>{localizationService.toLanguageString(USED_PESTICIDE_LOG_TITLE, '')}</h1>
      <div className='content-wrapper section'>
        <Grid
          data={process(data, dataState)}
          {...dataState}
          onDataStateChange={dataStateChange}
          pageable
          sortable
        >
          <GridToolbar>
            <GridSearchBar
              dataState={dataState}
              filterBy='operator'
              setDataState={setDataState}
            />
            <Button
              name='UsedPesticideAddNewBtn'
              onClick={addNew}
              primary
              icon='plus'
              title={localizationService.toLanguageString(ADD_NEW, '')}
            >
              {localizationService.toLanguageString(ADD_NEW, '')}
            </Button>
          </GridToolbar>
          <Column
            key='pesticideDate'
            field='pesticideDate'
            title={localizationService.toLanguageString(DATE, '')}
            filter='date'
            cell={DateCell}
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='operator'
            field='operator'
            title={localizationService.toLanguageString(
              USED_PESTICIDE_OPERATOR,
              ''
            )}
            filter='text'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='pesticideName'
            field='pesticideName'
            title={localizationService.toLanguageString(USED_PESTICIDE_NAME, '')}
            filter='text'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='pesticideActiveChemical'
            field='pesticideActiveChemical'
            title={localizationService.toLanguageString(
              USED_PESTICIDE_ACTIVE_CHEMICAL,
              ''
            )}
            filter='text'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='partNumber'
            field='partNumber'
            title={localizationService.toLanguageString(USED_PESTICIDE_NUMBER, '')}
            filter='text'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='treatedCulture'
            field='treatedCulture'
            title={localizationService.toLanguageString(
              USED_PESTICIDE_TREATED_CULTURE,
              ''
            )}
            filter='text'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='normHectare'
            field='normHectare'
            title={localizationService.toLanguageString(
              USED_PESTICIDE_HECTARE_NORM,
              ''
            )}
            filter='text'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='treatedHectare'
            field='treatedHectare'
            title={localizationService.toLanguageString(
              USED_PESTICIDE_TREATED_AREA,
              ''
            )}
            filter='numeric'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='total'
            field='total'
            title={localizationService.toLanguageString(USED_PESTICIDE_TOTAL, '')}
            filter='text'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='entryBanPeriod'
            field='entryBanPeriod'
            title={localizationService.toLanguageString(
              USED_PESTICIDE_ENTRY_BAN_PERIOD,
              ''
            )}
            filter='numeric'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='mowingBanPeriod'
            field='mowingBanPeriod'
            title={localizationService.toLanguageString(
              USED_PESTICIDE_MOWING_BAN_PERIOD,
              ''
            )}
            filter='numeric'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column cell={MyEditCommandCell} width={120} />
        </Grid>
        {loadingState && <GridLoaderPanel />}
      </div>
    </>
  );
};

export default UsedPesticideLogGrid;
