import { Button } from '@progress/kendo-react-buttons';
import { Loader } from '@progress/kendo-react-indicators';
import { useLocalization } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';
import UnfinishedBatchesGrid from '../../components/UnfinishedBatchesGrid/UnfinishedBatchesGrid';
import { CONFIRM } from '../../languages/languages';
import batchService from '../../services/batch.service';
import { IBatch } from '../../slices/data/ButcheryProcessSliceData';

const ButcheryProcessContainer = () => {
  const $t = useLocalization();
  const history = useHistory();
  const [batches, setBatches] = useState<IBatch[]>([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [loading, setLoading] = useState(true);

  const startNewBatch = (result: boolean) => {
    setShowConfirmDialog(false);
    if (result) {
      history.push('/processor/butchery-process/create');
    }
  };

  const loadBatches = () => {
    batchService
      .getUnfinishedBatches()
      .then((items) => {
        setBatches([...items]);
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    loadBatches();
  }, []);

  return (
    <div>
      <h1 className='page-title'>Кланичен процес</h1>
      <div className='mb-l'>
        <Button onClick={() => setShowConfirmDialog(true)}>Нова партида</Button>
      </div>
      <div>
        <h2>Незаскладени партиди</h2>
        <h5>Партиди с предтоящо въвеждане на вторична температура</h5>
        <div>
          { (!loading && batches.length === 0) && <div className='text-error'>Няма стартирани партиди</div>}
          {loading && <Loader size='large' type='pulsing' />}
          {(!loading && batches.length !== 0) && <UnfinishedBatchesGrid data={batches} /> }
        </div>
      </div>
      {showConfirmDialog && (
        <ConfirmDialog
          title={$t.toLanguageString(CONFIRM, '')}
          onResult={startNewBatch}
          message='Сигурни ли сте, че искате да стартирате нова партида?'
        />
      )}
    </div>
  );
};

export default ButcheryProcessContainer;
