import { GridCellProps } from '@progress/kendo-react-grid';
import React from 'react';

import { ColumnType } from '../../slices/data/AnimalColumnsSliceData';
import { CustomDealStatusCell } from '../CustomGridCells/CustomGridCells';
import NonSelectableGrid from '../SelectableGrid/NonSelectableGrid';

const colls = [
  {
    title: 'Статус',
    field: 'saleEventStatus',
    show: true,
    filter: 'text',
    cell: CustomDealStatusCell,
  },
  {
    title: 'Сделка номер',
    field: 'id',
    show: true,
    filter: 'text',
  },
  {
    title: 'Дата',
    field: 'dealDate',
    show: true,
    filter: 'date',
    format: '{0:dd.MM.yyy HH:mm}',
  },
  {
    title: 'Продавач',
    field: 'sellFirm',
    show: true,
    filter: 'text',
  },
  {
    title: 'Купувач',
    field: 'buyFirm',
    show: true,
    filter: 'text',
  },
] as ColumnType[];

const ProcessorToTraderDealsGrid = ({
  data,
  actionCell,
}: {
  data: any[];
  actionCell: (props: GridCellProps) => JSX.Element;
}) => <NonSelectableGrid data={data} stateColumns={colls} actionCell={actionCell} />;

export default ProcessorToTraderDealsGrid;
