import { gql } from '@apollo/client';

export const GetRatingQuery = gql`
  query GetRatingQuery {
    getRating {
      rating
    }
  }
`;

export type GetRatingType = {
  rating: number;
};
