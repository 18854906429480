import { Button } from '@progress/kendo-react-buttons';
import { Loader } from '@progress/kendo-react-indicators';
import { useInternationalization, useLocalization } from '@progress/kendo-react-intl';
import { Card, CardBody } from '@progress/kendo-react-layout';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { CONFIRM, FORWARD } from '../../languages/bg_BG';
import batchService from '../../services/batch.service';
import { ColumnType } from '../../slices/data/AnimalColumnsSliceData';
import { IBatch } from '../../slices/data/ButcheryProcessSliceData';
import BatchAnimalsForWarehouseGrid from '../AnimalGrid/BatchAnimalsForWarehouseGrid';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import NonSelectableGrid from '../SelectableGrid/NonSelectableGrid';

export interface ITabProps {
  batch: IBatch;
  onNext: () => void;
}

const cattleColumns: ColumnType[] = [
  {
    title: 'ВЕТИС Марка №',
    field: 'lID',
    show: true,
    filter: 'text',
  } as ColumnType,
  {
    title: 'Възраст (м)',
    field: 'age',
    show: true,
    filter: 'numeric',
  } as ColumnType,
  {
    title: 'Пол',
    field: 'sex',
    show: true,
    filter: 'text',
  } as ColumnType,
  {
    title: 'Порода',
    field: 'cattleBreed',
    show: true,
    filter: 'text',
  } as ColumnType,
  {
    title: 'Дата на пристигане',
    field: 'arrivalDate',
    show: true,
    filter: 'date',
    format: '{0:dd.MM.yyy}',
  } as ColumnType,
  {
    title: 'Ишлеме',
    field: 'processingAsService',
    show: true,
    filter: 'boolean',
    cell: null,
  } as ColumnType,
] as ColumnType[];

const WarehouseTab = ({ batch, onNext }: ITabProps) => {
  const $t = useLocalization();
  const $i = useInternationalization();
  const [showSaveMessage, setShowSaveMessage] = useState(false);
  const [showLoading, setLoading] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const saveBatch = () => {
    setLoading(true);

    batchService
      .saveBatch(batch)
      .then(() => setShowSuccess(true))
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  /**
   *
   * @param result
   */
  const onSave = (result: boolean) => {
    if (result) {
      saveBatch();
      if (!result) {
        onNext();
      }
    }
    setShowSaveMessage(false);
  };

  const hasWasteAnimals = () => batch.animals.length && batch.animals.some((x) => !x.isWaste);

  return (
    <div className='pl-l pr-l'>
      <div className='row mb-l'>
        <div className='col-12'>
          <h2>Партида:</h2>
          <h3>
            {batch.number}| {$i.formatDate(batch.date, 'dd.MM.yyyy')} |
          </h3>
        </div>
        <hr />
      </div>
      {!showLoading && !showSuccess && (
        <>
          <div className='row mb-l'>
            <div className='col-12'>
              <h4>Животни за склад:</h4>
              <BatchAnimalsForWarehouseGrid animals={batch.animals} />
            </div>
          </div>
          {hasWasteAnimals() && (
            <div className='row mb-l'>
              <div className='col-12'>
                <h4>Животни брак:</h4>
                <NonSelectableGrid data={batch.wasteAnimals} stateColumns={cattleColumns} />
              </div>
            </div>
          )}
          <div className='row'>
            <div className='col-12 d-flex justify-content-center mt-l'>
              <Button primary onClick={() => setShowSaveMessage(true)}>
                {$t.toLanguageString(FORWARD, '')}
              </Button>
            </div>
            {showSaveMessage && (
              <ConfirmDialog
                message='Сигурни ли сте, че желаете да запазите тази партида?'
                title={$t.toLanguageString(CONFIRM, '')}
                onResult={onSave}
              />
            )}
          </div>
        </>
      )}

      {showLoading && !showSuccess && <Loader size='large' type='pulsing' className='k-centered' />}

      {showSuccess && (
        <div className='row'>
          <div className='col-12 col-md-6 offset-md-3'>
            <Card>
              <CardBody className='d-flex'>
                {/* <CardTitle>Card Title</CardTitle>
                  <CardTitle>Card Subtitle</CardTitle> */}
                <div className='d-flex justify-content-center align-items-center pl-l pr-l text-success'>
                  <i className='far fa-check-circle fa-4x text-success' />
                </div>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                  <h2>
                    Успешно създадохте партида с номер <strong>{batch.number}</strong>{' '}
                  </h2>
                  <p>
                    От началния екран на кланичен процес може да въведете второ измерване на
                    температура и pH
                  </p>
                  <Link to='/processor/butchery-process' style={{ textDecoration: 'none' }}>
                    <Button look='outline'>Към кланичен процес</Button>
                  </Link>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
};

export default WarehouseTab;
