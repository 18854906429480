import { gql } from '@apollo/client';

import CreateGQLClient, { getGraphQLClient } from '../app/Client';
import { IListResult } from '../interfaces/request';
import { ISaleApproval, ISaleCreate, ISaleShortInfo } from '../models/processorToTraderSaleModels';

const client = getGraphQLClient() || CreateGQLClient();
const processorToTraderSaleCreateMutation = gql`
mutation processorToTraderSale($data: ProcessorToTraderSaleCreateModelInputType) {
  processorToTraderSale(data: $data) {
    pricePerKilo,
    sellFirmId,
    buyFirmId,
    dealDate
  }
}
`;

const getDealQuery = gql`
query processorToTaderDeal($dealId: Int){
  processorToTaderDeal(dealId: $dealId) {
    id,
    arrivalDate,
    buyFirm {
      eIK,
      mOL,
      name
    },
    dealDate,
    saleEventStatus,
    pricePerKilo,
    shoppingCenterId,
    shoppingCenterName,
    sellFirm {
      eIK,
      id,
      mOL,
      name
    },
    items {
      eventId,
      accepted,
      rejectReason
      item {
        animalAge,
        animalLId,
        animalSex,
        batchCutId,
        batchDate,
        batchId,
        batchNumber,
        cutName,
        cutNameId,
        cuttingType,
        cuttingTypeId,
        enterprice,
        enterpriceId,
        sEUROP,
        uneceCutCode,
        uSDA,
        weight
      }
    }
  }
}
`;

const processorToTaderBuyerDealsQuery = gql`
query processorToTaderBuyerDeals {
  processorToTaderBuyerDeals {
    items {
      id,
      sellFirm,
      buyFirm,
      dealDate,
      saleEventStatus
    }
  }
}
`;

const processorToTaderSellerDealsQuery = gql`
query processorToTaderSellerDeals {
  processorToTaderSellerDeals {
    items {
      id,
      sellFirm,
      buyFirm,
      dealDate,
      saleEventStatus
    }
  }
}
`;

const approvalMutation = gql`
mutation processorToTraderSaleApproval($data: ProcessorToTraderSaleApproveInputType) {
  processorToTraderSaleApproval(data: $data) {
    accepted,
    arrivalDate,
    saleId,
    shoppingCenterId,
    batchCutsIds
  }
}
`;

class ProcessorToTraderSalesService {
  createSale(data: ISaleCreate): Promise<ISaleCreate> {
    return new Promise((resolve, reject) => {
      client
        .mutate<{ data: ISaleCreate }, ISaleCreate>('processorToTraderSale', processorToTraderSaleCreateMutation, { data })
        .then((result) => {
          if (result) {
            resolve(result);
          } else {
            reject();
          }
        })
        .catch(reject);
    });
  }

  getDeal(dealId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      client
        .query<{ dealId: number }, ISaleCreate>('processorToTaderDeal', getDealQuery, { dealId })
        .then((result) => {
          if (result) {
            resolve(result);
          } else {
            reject();
          }
        })
        .catch(reject);
    });
  }

  buyerDeals(): Promise<ISaleShortInfo[]> {
    return new Promise((resolve, reject) => {
      client
        .query<null, IListResult<ISaleShortInfo>>('processorToTaderBuyerDeals', processorToTaderBuyerDealsQuery)
        .then((result) => {
          if (result) {
            resolve(result.items);
          } else {
            reject();
          }
        })
        .catch(reject);
    });
  }

  sellerDeals(): Promise<ISaleShortInfo[]> {
    return new Promise((resolve, reject) => {
      client
        .query<null, IListResult<ISaleShortInfo>>('processorToTaderSellerDeals', processorToTaderSellerDealsQuery)
        .then((result) => {
          if (result) {
            resolve(result.items);
          } else {
            reject();
          }
        })
        .catch(reject);
    });
  }

  approveSale(data: ISaleApproval): Promise<ISaleApproval> {
    return new Promise((resolve, reject) => {
      client
        .mutate<{ data: ISaleApproval }, ISaleApproval>('processorToTraderSaleApproval', approvalMutation, { data })
        .then((result) => {
          if (result) {
            resolve(result);
          } else {
            reject();
          }
        })
        .catch(reject);
    });
  }
}

export default new ProcessorToTraderSalesService();