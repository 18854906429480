import React from 'react';
import {
  Field,
  Form,
  FormElement,
  FormRenderProps
} from '@progress/kendo-react-form';
import { useLocalization } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';
import { FormDateTimePicker, FormNumericTextBox } from '../FormComponents/FormComponents';
import { StringValidator, NumberValidator } from '../Validators/Validators';
import {
  COOLING_START,
  PH_VALUE,
  SAVE,
  TEMPERATURE
} from '../../languages/languages';
import { AnimalCoolingType } from '../../slices/data/AnimalSliceData';

type AnimalCoolingFormProps = {
  submitForm: (formData: {}) => void;
  type: 'first' | 'second';
  values?: AnimalCoolingType;
};

const AnimalCoolingForm = ({ submitForm, type, values }:AnimalCoolingFormProps) => {
  const localizationService = useLocalization();

  return (
    <Form
      initialValues={values}
      onSubmit={submitForm}
      render={(formRenderProps: FormRenderProps) =>
        (
          <FormElement className='one-row-flex-form-section'>
            <Field
              key={type === 'first' ? 'coolingDateFirst' : 'coolingDateSecond'}
              id={type === 'first' ? 'coolingDateFirst' : 'coolingDateSecond'}
              name={type === 'first' ? 'coolingDateFirst' : 'coolingDateSecond'}
              label={localizationService.toLanguageString(COOLING_START, '')}
              format='dd-MM-yyyy HH:mm'
              component={FormDateTimePicker}
              disabled={type === 'first' ? values?.coolingDateFirst : values?.coolingDateSecond}
              validator={StringValidator}
            />
            <Field
              name={type === 'first' ? 'pHFirst' : 'pHSecond'}
              label={localizationService.toLanguageString(PH_VALUE, '')}
              component={FormNumericTextBox}
              disabled={type === 'first' ? values?.pHFirst : values?.pHSecond}
              placeholder='ph'
              validator={NumberValidator}
              min={5.00}
              step={0.1}
              max={7.10}
            />
            <Field
              name={type === 'first' ? 'temperatureFirst' : 'temperatureSecond'}
              label={localizationService.toLanguageString(TEMPERATURE, '')}
              component={FormNumericTextBox}
              min={-40.00}
              step={0.1}
              max={40.00}
              disabled={type === 'first' ? values?.temperatureFirst : values?.temperatureSecond}
              placeholder='°C'
              validator={NumberValidator}
            />
            <Button
              name='AnimalCoolingBtn'
              type='submit'
              primary
              icon='save'
              className='mt-m'
              title={localizationService.toLanguageString(SAVE, '')}
              disabled={!formRenderProps.allowSubmit}
            >
              {localizationService.toLanguageString(SAVE, '')}
            </Button>
          </FormElement>
        )}
    />
  );
};

AnimalCoolingForm.defaultProps = {
  values: null
};

export default AnimalCoolingForm;
