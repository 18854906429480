import React from 'react';
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
  GridCellProps,
  GridRowProps,
  GridDataStateChangeEvent,
  GridItemChangeEvent
} from '@progress/kendo-react-grid';
import { State, process } from '@progress/kendo-data-query';
import { useLocalization } from '@progress/kendo-react-intl';
import {
  AnimalInfo,
  PricePer100Kilos,
  PricePerHead,
  PricePerKilo
} from '../CustomGridCells/CustomGridCells';
import GridSearchBar from '../../features/GridSearchBar/GridSearchBar';
import ColumnMenu from '../ColumnMenu/ColumnMenu';
import { CellRender, RowRender } from '../SellAnimalsGridContainer/GridRenders';
import { SellDataType } from '../../slices/data/SellOperationSliceData';
import { SelectedAnimalType } from '../../slices/data/AnimalSliceData';
import {
  DEAL_ANIMAL_INFO, DEAL_ANIMAL_PPH, DEAL_ANIMAL_PPHK, DEAL_ANIMAL_PPK, DEAL_ANIMAL_WEIGHT
} from '../../languages/languages';

type SellAnimalsGridProps = {
  dataState: State;
  dataStateChange: (e: GridDataStateChangeEvent) => void;
  setDataState: React.Dispatch<React.SetStateAction<State>>;
  selectedAnimals: SelectedAnimalType[];
  saleData: SellDataType;
  enterEdit: (dataItem: SelectedAnimalType, field: string) => void
  editField: string;
  exitEdit: () => void;
  itemChange: ({ field, value, dataItem }: GridItemChangeEvent) => void;
};
const SellAnimalsGrid = ({
  dataState,
  setDataState,
  saleData,
  selectedAnimals,
  dataStateChange,
  editField,
  enterEdit,
  exitEdit,
  itemChange
}: SellAnimalsGridProps) => {
  const localizationService = useLocalization();
  const customCellRender: any = (
    td: React.ReactElement<HTMLTableCellElement>,
    props: GridCellProps
  ) =>
    (
      <CellRender
        defaultValue={0}
        originalProps={props}
        td={td}
        enterEdit={enterEdit}
        editField={editField}
      />
    );

  const customRowRender: any = (
    tr: React.ReactElement<HTMLTableRowElement>,
    props: GridRowProps
  ) =>
    (
      <RowRender
        originalProps={props}
        tr={tr}
        exitEdit={exitEdit}
        editField={editField}
      />
    );
  const PricePerKiloCell = (props: GridCellProps) =>
    (
      <PricePerKilo
        dealData={saleData}
        animalsData={selectedAnimals}
        {...props}
      />
    );

  const PricePerHeadCell = (props: GridCellProps) =>
    (
      <PricePerHead
        dealData={saleData}
        animalsData={selectedAnimals}
        {...props}
      />
    );

  const PricePer100KilosCell = (props: GridCellProps) =>
    (
      <PricePer100Kilos
        dealData={saleData}
        animalsData={selectedAnimals}
        {...props}
      />
    );

  return (
    <Grid
      className='pt-xl'
      sortable
      pageable
      {...dataState}
      data={process(selectedAnimals, dataState)}
      onDataStateChange={dataStateChange}
      onItemChange={itemChange}
      cellRender={customCellRender}
      rowRender={customRowRender}
      editField='inEdit'
    >
      <GridToolbar>
        <GridSearchBar
          dataState={dataState}
          setDataState={setDataState}
          filterBy='lID'
        />
      </GridToolbar>
      <Column
        editable={false}
        key='lID'
        field='selectedAnimals.lID'
        title={localizationService.toLanguageString(DEAL_ANIMAL_INFO, '')}
        filter='text'
        cell={AnimalInfo}
        columnMenu={(props) =>
          <ColumnMenu {...props} />}
      />
      <Column
        key='animalWeight'
        field='animalWeight'
        title={localizationService.toLanguageString(DEAL_ANIMAL_WEIGHT, '')}
        filter='numeric'
        columnMenu={(props) =>
          <ColumnMenu {...props} />}
      />
      <Column
        editable={false}
        key='pricePerHead'
        field='pricePerHead'
        title={localizationService.toLanguageString(DEAL_ANIMAL_PPH, '')}
        filter='numeric'
        cell={PricePerHeadCell}
        columnMenu={(props) =>
          <ColumnMenu {...props} />}
      />
      <Column
        editable={false}
        key='pricePerKilo'
        field='pricePerKilo'
        title={localizationService.toLanguageString(DEAL_ANIMAL_PPK, '')}
        filter='numeric'
        cell={PricePerKiloCell}
        columnMenu={(props) =>
          <ColumnMenu {...props} />}
      />
      <Column
        editable={false}
        key='pricePer100Kilo'
        field='pricePer100Kilo'
        title={localizationService.toLanguageString(DEAL_ANIMAL_PPHK, '')}
        filter='numeric'
        cell={PricePer100KilosCell}
        columnMenu={(props) =>
          <ColumnMenu {...props} />}
      />
    </Grid>
  );
};

export default SellAnimalsGrid;
