import { State } from '@progress/kendo-data-query';
import { getter } from '@progress/kendo-react-common';
import { GridDataStateChangeEvent, GridHeaderSelectionChangeEvent, GridSelectionChangeEvent } from '@progress/kendo-react-grid';
import React, { forwardRef, useCallback, useImperativeHandle, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import AnimalOperations from '../../features/AnimalOperations/AnimalOperations';
import AnimalPreview from '../../features/AnimalPreview/AnimalPreview';
import ColumnPickerMenu from '../../features/ColumnPickerMenu/ColumnPickerMenu';
import { selectCattleColumns } from '../../slices/AnimalColumnsSlice';
import { getAnimalsInAnimalObject, selectAllAnimals } from '../../slices/AnimalSlice';
import { AnimalType } from '../../slices/data/AnimalSliceData';
import { CattleBreedCell } from '../CustomGridCells/CustomGridCells';
import AnimalTable from '../SelectableGrid/SelectableGrid';

const DATA_ITEM_KEY: string = 'animalId';
const idGetter = getter(DATA_ITEM_KEY);

interface AnimalGridProps {
  objectId: number;
  onSelect?: (items: AnimalType[]) => void;
}

export type RefHandle = {
  reload: () => void;
};

const AnimalsInObjectGrid = forwardRef<RefHandle, AnimalGridProps>(
  ({ objectId, onSelect = undefined }: AnimalGridProps, ref) => {
    const dispatch = useAppDispatch();
    const cattles: AnimalType[] = useAppSelector(selectAllAnimals).map((dataItem: AnimalType) => ({
      selected: false,
      ...dataItem,
    }));

    const loadData = () => {
      dispatch(getAnimalsInAnimalObject({ objectId, animalType: 0 }));
    };

    const cattleColumns = useAppSelector(selectCattleColumns);

    // Grid States
    const [dataState, setDataState] = useState<State>({
      take: 10,
      skip: 0,
    });

    const dataStateChange = (e: GridDataStateChangeEvent) => {
      setDataState(e.dataState);
    };

    // Grid Columns menu and functions
    const [menuVisibility, setMenuVisibility] = useState(false);
    const handleMenuVisibility = () => {
      setMenuVisibility(true);
    };

    // Select animals
    // Local state for the grid's checkbox that will indicate
    // the state of the checkbox
    const [selectedState, setSelectedState] = useState<{
      [id: string]: boolean | number[];
    }>({});

    // Local state for the selected animals, that will be previewed in
    // the next step of the logic.
    const [selectedAnimals, setSelectedAnimals] = useState<AnimalType[]>([]);
    const [allSelValue, setAllSelValue] = useState<boolean>(false);

    // Check/Uncheck row in the grid
    // Use a callback so that the function is not fully
    // rerendered every time the component re-renders.
    // It should only update if there are changes to the
    // selectedState local state
    const onSelectionChange = useCallback(
      async ({ dataItem, syntheticEvent }: GridSelectionChangeEvent) => {
        // If anything but the checkbox in the grid is selected,
        // return the function so that the component does not crash
        if (!syntheticEvent?.target) {
          return;
        }

        // Get checked status of the checkbox cell
        const checkboxElement: any = syntheticEvent.target;
        const { checked } = checkboxElement;

        // Create a local state for the selected animals,
        // so that the checkbox element is dynamically updated
        const newSelectedState = {
          ...selectedState,
          [idGetter(dataItem)]: checked,
        };

        let updatedSelection = [];
        // If the the element is not checked, filter through
        // the local selectedAnimals state to make sure
        // only selected animals will be inserted for preview
        if (!newSelectedState[idGetter(dataItem)]) {
          updatedSelection = selectedAnimals.filter(
            (animal) => animal.animalId !== dataItem.animalId
          );

          setSelectedAnimals(updatedSelection);
        } else {
          // If the element is checked, add it to the
          // local selectedAnimals state
          updatedSelection = [...selectedAnimals, dataItem];
        }
        // Sets the selected animals for preview
        setSelectedAnimals(updatedSelection);
        if (onSelect) {
          onSelect(updatedSelection);
        }

        setSelectedState(newSelectedState);
      },
      [selectedState]
    );

    // Set all page elements to be checked
    const onHeaderSelectionChange = ({
      dataItems,
      syntheticEvent,
    }: GridHeaderSelectionChangeEvent) => {
      const checkboxElement: any = syntheticEvent.target;
      const { checked } = checkboxElement;
      const newSelectedState = {};
      const selAnimals: any[] = [];
      setAllSelValue(!allSelValue);
      dataItems.forEach(async (animal) => {
        newSelectedState[idGetter(animal)] = checked;
        if (newSelectedState[idGetter(animal)]) {
          selAnimals.push(animal);
        }
      });
      setSelectedState(newSelectedState);
      setSelectedAnimals(selAnimals);
      if (onSelect) {
        onSelect(selAnimals);
      }
    };

    // Handle show/hide selected animals preview
    const [showPreview, setShowPreview] = useState<boolean>(false);

    // const togglePreview = () => {
    //   setShowPreview(!showPreview);
    // };

    // Handle show/hide animal operations component visibility
    const [showOperations, setShowOperations] = useState<boolean>(false);

    useImperativeHandle(ref, () => ({
      reload() {
        loadData();
      },
    }));

    return (
      <>
        <AnimalTable
          data={cattles}
          dataState={dataState}
          dataStateChange={dataStateChange}
          handleMenuVisibility={handleMenuVisibility}
          onHeaderSelectionChange={onHeaderSelectionChange}
          onSelectionChange={onSelectionChange}
          selectedState={selectedState}
          setDataState={setDataState}
          stateColumns={cattleColumns}
          cell={CattleBreedCell}
          DATA_ITEM_KEY='animalId'
          showToolbar={false}
          showColumns={true}
        />
        {!menuVisibility ? null : (
          <ColumnPickerMenu
            type='cattle'
            columnSet={cattleColumns}
            setVisibility={setMenuVisibility}
          />
        )}
        {!showPreview ? null : (
          <AnimalPreview
            setOriginalSelection={setSelectedAnimals}
            setCheckboxSelection={setSelectedState}
            checkboxesState={selectedState}
            selectedAnimals={selectedAnimals}
            showPreview={showPreview}
            setShowPreview={setShowPreview}
            setShowOperations={setShowOperations}
          />
        )}
        {showOperations ? (
          <AnimalOperations setVisibility={setShowOperations} holdingType='farmer' />
        ) : null}
      </>
    );
  }
);

AnimalsInObjectGrid.defaultProps = {
  onSelect: undefined,
};

export default AnimalsInObjectGrid;
