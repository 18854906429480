import { gql } from '@apollo/client';

// Types

export type ContragentType = {
  contragentId: number;
  contragentName: string;
  contragentPhone: string;
  contragentPIN: string;
  contragentEmail: string;
  firm: ContragentsFirms;
};

export type ContragentsResponseType = {
  items: ContragentType[];
};

export type GetContragentsType = {
  items: ContragentType[];
};

export type ContragentsFirms = {
  firmABId: number;
  firmABName: string;
  firmABMOL: string;
};

export type GetContragentsFirmsType = {
  items: ContragentsFirms[];
};

export type SentContragentInformationType = {
  contragent: ContragentType;
};

export type ContragentFirmInputType = {
  firmABId: number;
};

export type ContragentsFirmsResponseType = {
  items: ContragentsFirms[];
};

export type SaveContragentType = {
  contragent: ContragentType;
};

// Queries & Mutations
export const GET_CONTRAGENTS = gql`
  query {
    contragent {
      items {
        contragentId
        contragentName
        contragentPhone
        contragentEmail
        contragentPIN
        firm {
          firmABName
        }
      }
    }
  }
`;

export const GET_CONTRAGENT_FIRMS = gql`
  query {
    addressBookFirm {
      items {
        firmABName
        firmABId
      }
    }
  }
`;

export const SAVE_CONTRAGENT = gql`
  mutation AddContragent($contragent: contragentInput) {
    addContragent(contragent: $contragent) {
      contragentId
      contragentName
      contragentPhone
      contragentPIN
      contragentEmail
      firm {
        firmABName
      }
    }
  }
`;

export const EDIT_CONTRAGENT = gql`
  mutation UpdateContragent($contragent: contragentInput) {
    updateContragent(contragent: $contragent) {
      contragentId
      contragentName
      contragentPhone
      contragentPIN
      contragentEmail
      firm {
        firmABName
      }
    }
  }
`;

export const GET_CONTRAGENT_DRIVERS = gql`
  query Contragent($firmABId: ID) {
    contragent(firmABId: $firmABId) {
      items {
        contragentId
        contragentPIN
        contragentName
      }
    }
  }
`;
