import { Loader } from '@progress/kendo-react-indicators';
import React from 'react';
import { useLocalization } from '@progress/kendo-react-intl';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import FirmForm from '../../components/FirmForm/FirmForm';
import {
  saveFirmInformation,
  selectLoadingState
} from '../../slices/FirmSlice';
import FirmGridContainer from '../FirmGridContainer/FirmGridContainer';
import { FIRMS_TAB } from '../../languages/languages';
import { FirmType } from '../../slices/data/FirmSliceData';

const FirmContainer = () => {
  // get state
  const isLoading = useAppSelector(selectLoadingState);
  const dispatch = useAppDispatch();
  // localization
  const localizationService = useLocalization();

  const handleSubmit = (formData: FirmType) => {
    dispatch(saveFirmInformation(formData));
  };

  return (
    <>
      <div className='holding-form'>
        <h3 className='subtitle-1 mb-s'>{localizationService.toLanguageString(FIRMS_TAB, '')}</h3>

        {isLoading ? (
          <Loader size='large' type='pulsing' className='k-centered' />
        ) : (
          <FirmForm onSubmit={handleSubmit} />
        )}
      </div>
      <div className='holding-grid' id='firm-table'>
        <FirmGridContainer />
      </div>
    </>
  );
};

export default FirmContainer;
