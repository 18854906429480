import React, { useState } from 'react';
import { State, process } from '@progress/kendo-data-query';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridToolbar
} from '@progress/kendo-react-grid';
import GridSearchBar from '../../features/GridSearchBar/GridSearchBar';
import ColumnMenu from '../ColumnMenu/ColumnMenu';
import { AnimalInfo } from '../CustomGridCells/CustomGridCells';
import { useAppSelector } from '../../app/StoreHooks';
import { selectSelectedAnimals } from '../../slices/AnimalSlice';

const VeterinaryInspectionGrid = () => {
  const animals = useAppSelector(selectSelectedAnimals);
  // Grid States
  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0
  });

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  };

  // Custom Cell to combine several animal properties
  // so that they are displayed in one place
  const AnimalInfoCell = (props: GridCellProps) =>
    <AnimalInfo {...props} />;

  return (
    <Grid
      {...dataState}
      data={process(animals, dataState)}
      pageable
      sortable
      onDataStateChange={dataStateChange}
    >
      <GridToolbar>
        <GridSearchBar
          dataState={dataState}
          setDataState={setDataState}
          filterBy='dealBatchNumber'
        />
      </GridToolbar>
      <Column
        editable={false}
        key='lID'
        field='lID'
        title='Информация за животното'
        filter='text'
        cell={AnimalInfoCell}
        columnMenu={(props) =>
          <ColumnMenu {...props} />}
      />
    </Grid>
  );
};

export default VeterinaryInspectionGrid;
