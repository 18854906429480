import React, { useState } from 'react';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Button } from '@progress/kendo-react-buttons';
import {
  Grid,
  GridToolbar,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridCellProps
} from '@progress/kendo-react-grid';
import { State, process } from '@progress/kendo-data-query';
import { useLocalization } from '@progress/kendo-react-intl';
import GridSearchBar from '../../features/GridSearchBar/GridSearchBar';
import {
  ADD_NEW,
  EXPORT,
  PURCHASED_MEDICINE_AMOUNT,
  PURCHASED_MEDICINE_DELIVERED_BY,
  PURCHASED_MEDICINE_EXPIRY_DATE,
  PURCHASED_MEDICINE_LOG_ENTRY_DATE,
  PURCHASED_MEDICINE_NAME,
  MEDICINE_WITHDRAW_TITLE,
  PURCHASED_MEDICINE_WITHDRAW_PERIOD_MEAT,
  PURCHASED_MEDICINE_WITHDRAW_PERIOD_MILK,
  PURCHASED_MEDICINE_BATCH_NUMBER,
  MEDICINE_CATEGORY_NAME,
  PURCHASED_MEDICINE_NOTE,
  PURCHASED_MEDICINE_LOG_TITLE
} from '../../languages/languages';
import { DateCell, EditCommandCell } from '../CustomGridCells/CustomGridCells';
import {
  medicineCategories,
  PurchasedMedicineType
} from '../../slices/data/PurchasedMedicinesSliceData';
import ColumnMenu from '../ColumnMenu/ColumnMenu';
import { useAppSelector } from '../../app/StoreHooks';
import { selectPurchasedMedicineLoadingState } from '../../slices/PurchasedMedicinesSlice';
import GridLoaderPanel from '../GridLoaderPanel/GridLoaderPanel';
import GrantAccess from '../../features/GrantAccess/GrantAccess';

const REQUIRED_ROLES: string[] = [
  'Admin',
  'Holding Owner',
  'Farm Manager',
  'Farm Veterinary Inspector'
];

type PurchasedMedicinesGridProps = {
  data: PurchasedMedicineType[];
  openForm: () => void;
  openEditForm: (editItem: PurchasedMedicineType) => void;
};

const PurchasedMedicinesGrid = ({
  data,
  openForm,
  openEditForm
}: PurchasedMedicinesGridProps) => {
  const localizationService = useLocalization();
  const loadingState = useAppSelector(selectPurchasedMedicineLoadingState);

  const exportMedicines = React.useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (exportMedicines.current !== null) {
      exportMedicines.current.save();
    }
  };

  const MedicineCategoryCell = ({ dataItem }: GridCellProps) => {
    const medicineCategory = medicineCategories.find(
      (categ) =>
        categ.value === dataItem.medicineCategory
    ).label;
    return (
      <td>{localizationService.toLanguageString(medicineCategory, '')}</td>
    );
  };

  const MyEditCommandCell = (props: GridCellProps) =>
    (
      <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
        <EditCommandCell {...props} enterEdit={openEditForm} />
      </GrantAccess>
    );

  //   Grid State
  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0
  });

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  };

  return (
    <>
      <h1 className='page-title'>{localizationService.toLanguageString(PURCHASED_MEDICINE_LOG_TITLE, '')}</h1>
      <div className='content-wrapper section'>
        <ExcelExport data={data} ref={exportMedicines}>
          <Grid
            {...dataState}
            onDataStateChange={dataStateChange}
            data={process(data, dataState)}
            sortable
            pageable
          >
            <GridToolbar>
              <GridSearchBar
                filterBy='medicineName'
                dataState={dataState}
                setDataState={setDataState}
              />
              <Button title='Add New' icon='plus' primary onClick={openForm}>
                {localizationService.toLanguageString(ADD_NEW, '')}
              </Button>
              <Button
                name='ATCattleGridExportBtn'
                type='button'
                title={localizationService.toLanguageString(EXPORT, '')}
                icon='download'
                primary
                look='outline'
                className='ml-s'
                onClick={excelExport}
              >
                {localizationService.toLanguageString(EXPORT, '')}
              </Button>
            </GridToolbar>
            <Column
              cell={DateCell}
              field='purchaseDate'
              filter='date'
              title={localizationService.toLanguageString(
                PURCHASED_MEDICINE_LOG_ENTRY_DATE,
                ''
              )}
              columnMenu={(props) =>
                <ColumnMenu {...props} />}
            />
            <Column
              field='medicineName'
              filter='text'
              title={localizationService.toLanguageString(
                PURCHASED_MEDICINE_NAME,
                ''
              )}
              columnMenu={(props) =>
                <ColumnMenu {...props} />}
            />
            <Column
              field='medicineCategory'
              filter='text'
              title={localizationService.toLanguageString(
                MEDICINE_CATEGORY_NAME,
                ''
              )}
              cell={MedicineCategoryCell}
              columnMenu={(props) =>
                <ColumnMenu {...props} />}
            />
            <Column
              field='quantity'
              filter='numeric'
              title={localizationService.toLanguageString(
                PURCHASED_MEDICINE_AMOUNT,
                ''
              )}
              columnMenu={(props) =>
                <ColumnMenu {...props} />}
            />
            <Column
              field='lotNumber'
              filter='numeric'
              title={localizationService.toLanguageString(
                PURCHASED_MEDICINE_BATCH_NUMBER,
                ''
              )}
              columnMenu={(props) =>
                <ColumnMenu {...props} />}
            />
            <Column
              field='firm.firmABName'
              filter='numeric'
              title={localizationService.toLanguageString(
                PURCHASED_MEDICINE_DELIVERED_BY,
                ''
              )}
              columnMenu={(props) =>
                <ColumnMenu {...props} />}
            />
            <Column
              title={localizationService.toLanguageString(
                MEDICINE_WITHDRAW_TITLE,
                ''
              )}
            >
              <Column
                field='withdrawalPeriodMeat'
                title={localizationService.toLanguageString(PURCHASED_MEDICINE_WITHDRAW_PERIOD_MEAT, '')}
                columnMenu={(props) =>
                  <ColumnMenu {...props} />}
              />
              <Column
                field='withdrawalPeriodMilk'
                title={localizationService.toLanguageString(
                  PURCHASED_MEDICINE_WITHDRAW_PERIOD_MILK,
                  ''
                )}
                columnMenu={(props) =>
                  <ColumnMenu {...props} />}
              />
            </Column>
            <Column
              cell={DateCell}
              field='expiryDate'
              filter='date'
              title={localizationService.toLanguageString(
                PURCHASED_MEDICINE_EXPIRY_DATE,
                ''
              )}
              columnMenu={(props) =>
                <ColumnMenu {...props} />}
            />
            <Column
              field='comment'
              title={localizationService.toLanguageString(
                PURCHASED_MEDICINE_NOTE,
                ''
              )}
            />
            <Column cell={MyEditCommandCell} width={120} />
          </Grid>
          {loadingState && <GridLoaderPanel />}
        </ExcelExport>
      </div>
    </>
  );
};

export default PurchasedMedicinesGrid;
