import { gql } from '@apollo/client';
import { TransportFirmType } from './FirmsAddressBookSliceData';

// Types
export type TruckType = {
  truckId: number;
  truckPlate: string;
  comment: string;
  truckLoad: number;
  truckCapacity: number;
  parentFirm: TransportFirmType;
};

export type GetTrucksResponseType = {
  items: TruckType[];
};

export type AddTruckType = {
  truckId?: number;
  truckPlate: string;
  comment: string;
  truckLoad: number;
  truckCapacity: number;
  firmABId: number;
};

export type AddTruckInputType = {
  truckInput: AddTruckType;
};

export type GetFirmTrucksInputType = {
  firmABId: number;
};

export type TruckAndFirmType = {
  truckId: number;
  truckPlate: string;
};

// Queries & Mutations
export const GET_ALL_TRUCKS = gql`
  {
    trucks {
      items {
        truckId
        truckPlate
        truckLoad
        truckCapacity
        comment
        parentFirm {
          firmABId
          firmABName
        }
      }
    }
  }
`;

export const GET_FIRM_TRUCKS = gql`
  query Trucks($firmABId: ID) {
    trucks(firmABId: $firmABId) {
      items {
        truckId
        truckPlate
      }
    }
  }
`;

export const ADD_TRUCK = gql`
  mutation AddTruck($truckInput: truckInput) {
    addTruck(truckInput: $truckInput) {
      truckId
      truckPlate
      truckLoad
      truckCapacity
      comment
      parentFirm {
        firmABId
        firmABName
      }
    }
  }
`;

export const EDIT_TRUCK = gql`
  mutation UpdateTruck($truckInput: truckInput) {
    updateTruck(truckInput: $truckInput) {
      truckId
      truckPlate
      truckLoad
      truckCapacity
      comment
      parentFirm {
        firmABId
        firmABName
      }
    }
  }
`;
