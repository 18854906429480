import { graphql } from 'msw';
import AUTH_CONFIG from '../../auth-variables';

const blbTrace = graphql.link(AUTH_CONFIG.apiUrl);

const firms = [
  {
    firmABId: 1,
    firmABEik: '12314',
    firmABName: 'Толев и ко.',
    firmABCity: 'Пловдив',
    firmABAddress: 'Жан Жорес 3',
    firmABMol: 'Vat-19',
  },
  {
    firmABId: 2,
    firmABEik: '40239i42i424',
    firmABName: 'Толевi',
    firmABCity: 'Пловдив',
    firmABAddress: 'Жан Жорес 3',
    firmABMol: 'Vat-19',
  },
  {
    firmABId: 4,
    firmABEik: '40239i42i424',
    firmABName: 'фирмаИмаИме',
    firmABCity: 'Пловдив',
    firmABAddress: 'Жан Жорес 3',
    firmABMol: 'Vat-19',
  },
  {
    firmABId: 5,
    firmABEik: '40239i42i424',
    firmABName: 'безИме',
    firmABCity: 'Пловдив',
    firmABAddress: 'Жан Жорес 3',
    firmABMol: 'Vat-19',
  }
];

export default [
  blbTrace.query('addressBookFirm', (req, res, ctx) =>
    res(
      ctx.data({
        firms: {
          items: firms
        }
      })
    ))
];
