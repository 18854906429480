import { Button } from '@progress/kendo-react-buttons';
import { DropDownList, DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { Form, FormElement } from '@progress/kendo-react-form';
import { Input } from '@progress/kendo-react-inputs';
import { useLocalization } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';

import { DECLINE, SAVE } from '../../languages/languages';
// import { IDropdownModel } from '../../languages/languages';
import { IDropdownModel } from '../../services/dropdown.service';
import { IBatchAnimal } from '../../slices/data/ButcheryProcessSliceData';

// import { FormNumericTextBox } from '../FormComponents/FormComponents';

const cutsCount = [
  {
    id: 1,
    text: '2/2 - половини',
  },
  {
    id: 2,
    text: '4/4 - четвъртини',
  },
] as IDropdownModel[];

export const quarterCutsType = [
  {
    id: 1,
    text: 'Стандартен',
  },
  {
    id: 2,
    text: 'Пистолет',
  },
] as IDropdownModel[];

const CutsUnece = ({
  item,
  onSave,
  onCancel
}: {
  item: IBatchAnimal;
  onSave: (d: IBatchAnimal, animalId: number) => void;
  onCancel: () => void;
}) => {
  const localizationService = useLocalization();
  const [data, setData] = useState<IBatchAnimal>();
  const [cutsCountValue, setCutsCountValue] = useState<IDropdownModel>();
  const [cuttingTypeValue, setCuttingTypeValue] = useState<IDropdownModel>();

  // const requiredValidator = (val: number) => {
  //   const result = val !== null && val !== undefined && !Number.isNaN(val) ?
  //                  '' : localizationService.toLanguageString(REQUIRED_FIELD, '');
  //   return result;
  // };

  const onSubmit = (formData: IBatchAnimal) => {
    console.log(formData);
    console.log(data);
  };

  const onSaveClick = () => {
    onSave(data, item.animalId);
  };

  useEffect(() => {
    setCutsCountValue(cutsCount[0]);
    if (data) {
      data.cuts = [
        {
          id: 5,
          cutName: 'Половина 1',
          cutNameId: 1,
          cuttingTypeId: quarterCutsType[0].id,
          cuttingTypeName: quarterCutsType[0].text,
          weight: 0,
          uneceCutCode: '1000',
        },
        {
          id: 6,
          cutName: 'Половина 2',
          cutNameId: 2,
          cuttingTypeId: quarterCutsType[0].id,
          cuttingTypeName: quarterCutsType[0].text,
          weight: 0,
          uneceCutCode: '1000',
        },
      ];
      setData({ ...data });
    }
  }, []);

  const dataInit = () => {
    const d = JSON.parse(JSON.stringify(item));

    if (d.cutsCountId) {
      const cutCountVal = cutsCount.find((x) => x.id === d.cutsCountId);
      setCutsCountValue(cutCountVal);
    } else {
      d.cutsCountId = cutsCount[0].id;
      d.cutsCountName = cutsCount[0].text;
      setCutsCountValue(cutsCount[0]);
    }

    if (d.cutsTypeId) {
      const cType = quarterCutsType.find((X) => X.id === d.cutsTypeId);
      setCuttingTypeValue(cType);
    } else {
      d.cutsTypeId = quarterCutsType[0].id;
      d.cutsTypeName = quarterCutsType[0].text;
      setCuttingTypeValue(quarterCutsType[0]);
    }

    d.cuts =
      d.cuts.length > 0
        ? [...d.cuts]
        : [
            {
              id: 5,
              cutName: 'Половина 1',
              cutNameId: 1,
              cuttingTypeId: quarterCutsType[0].id,
              cuttingTypeName: quarterCutsType[0].text,
              weight: 0,
              uneceCutCode: '1000',
            },
            {
              id: 6,
              cutName: 'Половина 2',
              cutNameId: 2,
              cuttingTypeId: quarterCutsType[0].id,
              cuttingTypeName: quarterCutsType[0].text,
              weight: 0,
              uneceCutCode: '1000',
            },
          ];
    setData({ ...d });
  };

  useEffect(() => {
    dataInit();
  }, [item]);

  const onCutsCountChange = (e: DropDownListChangeEvent) => {
    const count = e.value.id === 1 ? 2 : 4;
    data.cutsCountId = e.value.id;
    data.cutsCountName = e.value.text;
    setCutsCountValue(e.value);

    if (count === 2) {
      data.cuts = [
        {
          id: 5,
          cutName: 'Половина 1',
          cutNameId: 1,
          cuttingTypeId: quarterCutsType[0].id,
          cuttingTypeName: quarterCutsType[0].text,
          weight: 0,
          uneceCutCode: '1000',
        },
        {
          id: 6,
          cutName: 'Половина 2',
          cutNameId: 2,
          cuttingTypeId: quarterCutsType[0].id,
          cuttingTypeName: quarterCutsType[0].text,
          weight: 0,
          uneceCutCode: '1000',
        },
      ];
      setCuttingTypeValue(null);
    } else {
      data.cuts = [
        {
          id: 1,
          cutName: 'Предна 1',
          cutNameId: 1,
          cuttingTypeId: quarterCutsType[0].id,
          cuttingTypeName: quarterCutsType[0].text,
          weight: 0,
          uneceCutCode: '1060',
        },
        {
          id: 2,
          cutName: 'Предна 2',
          cutNameId: 2,
          cuttingTypeId: quarterCutsType[0].id,
          cuttingTypeName: quarterCutsType[0].text,
          weight: 0,
          uneceCutCode: '1060',
        },
        {
          id: 3,
          cutName: 'Задна 1',
          cutNameId: 3,
          cuttingTypeId: quarterCutsType[0].id,
          cuttingTypeName: quarterCutsType[0].text,
          weight: 0,
          uneceCutCode: '1010',
        },
        {
          id: 4,
          cutName: 'Задна 2',
          cutNameId: 4,
          cuttingTypeId: quarterCutsType[0].id,
          cuttingTypeName: quarterCutsType[0].text,
          weight: 0,
          uneceCutCode: '1010',
        },
      ];

      setCuttingTypeValue(quarterCutsType[0]);
      data.cutsTypeId = quarterCutsType[0].id;
    }

    setData({ ...data });
  };

  const onQuarterCutTypeCountChange = (e: DropDownListChangeEvent) => {
    setCuttingTypeValue(e.value);
    data.cutsTypeId = e.value.id;
    data.cutsTypeName = e.value.text;
    setData({ ...data });
  };

  useEffect(() => {
    if (cuttingTypeValue) {
      console.log('cutting type change');

      data.cuts.forEach((c) => {
        c.cuttingTypeName = cuttingTypeValue.text;
        c.cuttingTypeId = cuttingTypeValue.id;
        if (c.cutNameId < 3) {
          c.uneceCutCode = cuttingTypeValue.id === 1 ? '1060' : '1050';
        } else {
          c.uneceCutCode = cuttingTypeValue.id === 1 ? '1010' : '1020';
        }
      });

      setData({ ...data });
    }
  }, [cuttingTypeValue]);

  return (
    <div className='pb-l'>
      {data && (
        <Form
          onSubmit={onSubmit}
          initialValues={data}
          render={() => (
            <FormElement id='registrationForm'>
              <div className='row mb-l'>
                <div className='col-12 col-md-3 pr-s'>
                  <DropDownList
                    data={cutsCount}
                    key='id'
                    textField='text'
                    label='Половини или четвъртини'
                    value={cutsCountValue}
                    onChange={onCutsCountChange}
                  />
                </div>
                {data.cutsCountId === 2 && (
                  <div className='col-12 col-md-3 pr-s'>
                    <DropDownList
                      data={quarterCutsType}
                      key='id'
                      textField='text'
                      label='Вид на среза'
                      value={cuttingTypeValue}
                      onChange={onQuarterCutTypeCountChange}
                    />
                  </div>
                )}
              </div>
              <div className='row'>
                <div className='col-12'>
                  <table>
                    <thead>
                      <tr>
                        <th>
                          <strong>Име на сечение</strong>
                        </th>
                        <th>
                          <strong>Рязане</strong>
                        </th>
                        <th>
                          <strong>Тегло на сечение (кг)</strong>
                        </th>
                        <th>
                          <strong>UNECE код на сечение</strong>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.cuts &&
                        data.cuts.map((c, i) => (
                          <tr key={`${c.id}`}>
                            <td>{c.cutName}</td>
                            <td>{c.cuttingTypeName}</td>
                            <td>
                              <Input
                                name={c.cutName}
                                id={c.cutName}
                                inputMode='numeric'
                                value={c.weight}
                                onChange={(e) => {
                                  const w = parseFloat(e.value);
                                  data.cuts[i].weight = Number.isNaN(w) ? 0 : w;

                                  setData({ ...data });
                                }}
                              />

                              {/* <Field
                                key={`${c.id}`}
                                id={`cuts[${c.id}].weight`}
                                name={`cuts[${c.id}].weight`}
                                component={FormNumericTextBox}
                                value={c.weight}
                                inputMode='numeric'
                                validator={requiredValidator}
                                onChange={(e) => {
                                  const w = parseFloat(e.value);
                                  data.cuts[i].weight = Number.isNaN(w) ? 0 : w;

                                  setData({ ...data });
                                }}
                              /> */}
                            </td>
                            <td>{c.uneceCutCode}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <fieldset className='k-form-buttons d-flex justify-content-center'>
                <Button
                  name='FirmAdd'
                  type='submit'
                  title={localizationService.toLanguageString(SAVE, '')}
                  primary
                  // disabled={!formRenderProps.allowSubmit}
                  icon='save'
                  onClick={onSaveClick}
                >
                  {localizationService.toLanguageString(SAVE, '')}
                </Button>
                <Button
                  name='FirmCancel'
                  type='submit'
                  title={localizationService.toLanguageString(DECLINE, '')}
                  primary
                  look='outline'
                  icon='cancel'
                  onClick={onCancel}
                >
                  {localizationService.toLanguageString(DECLINE, '')}
                </Button>
              </fieldset>
            </FormElement>
          )}
        />
      )}
    </div>
  );
};

export default CutsUnece;
