enum CattleBreeds {
  AAN = 'Абердин Ангус',
  AUB = 'Обрак',
  RDC = 'Айршир',
  BBL = 'Белгийско Синьо',
  BAQ = 'Блонд Акитен',
  BFD = 'Брафорд',
  BRM = 'Брахман',
  BRG = 'Брангус',
  BSW = 'Кафяво говедо',
  CIA = 'Кианина',
  CHA = 'Шароле',
  GAS = 'Гаскон',
  GLW = 'Галоуей',
  HER = 'Херефорд',
  HLA = 'Хайленд',
  HOL = 'Холщайн',
  RED = 'Червен Холщайн',
  JER = 'Джерсей',
  LIM = 'Лимузин',
  MON = 'Монбелиард',
  MAR = 'Маркиджана',
  PAR = 'Пратенез',
  PIE = 'Пиемонтез',
  RAN = 'Червен Ангус',
  ROM = 'Романьола',
  SAL = 'Салер',
  SIM = 'Симентал',
  XXX = 'Кръстоска',
  UN = 'Неизвестна',
  WAG = 'Вагу',
  ISK = 'Искърско говедо'
}

export default CattleBreeds;
