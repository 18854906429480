import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { useLocalization } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';

import { DECLINE, UPDATE } from '../../languages/languages';
import { IBatchAnimal, ISeuropData } from '../../slices/data/ButcheryProcessSliceData';
import { RequiredValidator } from '../../utils/validators';
import { FormDropDownList } from '../FormComponents/FormComponents';

const seuropDtata = [
  {
    id: 1,
    text: 'S',
  },
  {
    id: 2,
    text: 'E',
  },
  {
    id: 3,
    text: 'U',
  },
  {
    id: 4,
    text: 'R',
  },
  {
    id: 5,
    text: 'O',
  },
  {
    id: 6,
    text: 'P',
  },
];

const oneToFiveData = [
  {
    id: 1,
    text: '1',
  },
  {
    id: 2,
    text: '2',
  },
  {
    id: 3,
    text: '3',
  },
  {
    id: 4,
    text: '4',
  },
  {
    id: 5,
    text: '5',
  },
];

const plusMinusData = [
  {
    id: 1,
    text: '+',
  },
  {
    id: 2,
    text: '-',
  },
  {
    id: 3,
    text: '=',
  },
];

const usdaGradeData = [
  {
    id: 1,
    text: 'Неопределен',
  },
  {
    id: 2,
    text: 'Prime',
  },
  {
    id: 3,
    text: 'Choice',
  },
  {
    id: 4,
    text: 'Select',
  },
];

type SeuropEditModalProps = {
  item: IBatchAnimal;
  cancelEdit: () => void;
  onSubmit: (animalId: number, formData: ISeuropData) => void;
};
const SeuropModal = ({ item, cancelEdit, onSubmit }: SeuropEditModalProps) => {
  const localizationService = useLocalization();
  const title = `Редакция на "${item.lID}"`;
  const [data, setData] = useState<any>({});

  const onFormSubmit = (formData: any) => {
    console.log(formData);
    const submitData = {
      number: formData.number.id,
      plusMinusEquals: formData.plusMinusEquals.text,
      seuropCode: formData.seurop.text,
      usdaId: formData.usda.id,
      usdaText: formData.usda.text,
    } as ISeuropData;
    console.log(submitData);

    onSubmit(item.animalId, submitData);
  };

  useEffect(() => {
    let d = {} as any;

    if (item) {
      d = {
        number: oneToFiveData.find((x) => x.id === item.number),
        plusMinusEquals: plusMinusData.find((x) => x.text === item.plusMinusEquals),
        seurop: seuropDtata.find((x) => x.text === item.seuropCode),
        usda: usdaGradeData.find((x) => x.id === item.usdaId),
      };
    }

    setData(d);
  }, [item]);

  return (
    <Dialog title={title} onClose={cancelEdit}>
      {data && (
        <Form
          onSubmit={onFormSubmit}
          initialValues={data}
          render={(formRenderProps) => (
            <FormElement id='registrationForm'>
              <div className='row'>
                <div className='col-12 pl-l pr-l'>
                  <Field
                    id='SEUROP'
                    name='seurop'
                    component={FormDropDownList}
                    label='SEUROP'
                    data={seuropDtata}
                    textField='text'
                    validator={RequiredValidator}
                  />
                </div>
                <div className='col-12 pl-l pr-l'>
                  <Field
                    id='number'
                    name='number'
                    component={FormDropDownList}
                    label='12345'
                    data={oneToFiveData}
                    textField='text'
                    validator={RequiredValidator}
                  />
                </div>
                <div className='col-12 pl-l pr-l'>
                  <Field
                    id='plusMinusEquals'
                    name='plusMinusEquals'
                    component={FormDropDownList}
                    label='=+-'
                    data={plusMinusData}
                    textField='text'
                    validator={RequiredValidator}
                  />
                </div>
                <div className='col-12 pl-l pr-l'>
                  <Field
                    id='usda'
                    name='usda'
                    component={FormDropDownList}
                    label='USDA'
                    data={usdaGradeData}
                    textField='text'
                    validator={RequiredValidator}
                  />
                </div>
              </div>
              <fieldset className='k-form-buttons'>
                <Button
                  name='submit'
                  type='submit'
                  title={localizationService.toLanguageString(UPDATE, '')}
                  primary
                  disabled={!formRenderProps.allowSubmit}
                  icon='save'
                >
                  {localizationService.toLanguageString(UPDATE, '')}
                </Button>
                <Button
                  name='FirmCancel'
                  type='button'
                  title={localizationService.toLanguageString(DECLINE, '')}
                  primary
                  look='outline'
                  onClick={cancelEdit}
                  icon='cancel'
                >
                  {localizationService.toLanguageString(DECLINE, '')}
                </Button>
              </fieldset>
            </FormElement>
          )}
        />
      )}
    </Dialog>
  );
};

export default SeuropModal;
