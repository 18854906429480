import { State } from '@progress/kendo-data-query';
import { GridCellProps, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import AnimalObjectEditForm from '../../components/AnimalObjectEditForm/AnimalObjectEditForm';
import AnimalObjectGrid from '../../components/AnimalObjectGrid/AnimalObjectGrid';
import { GridCommandsCell, GridCommandsCellPropsItem } from '../../components/CustomGridCells/CustomGridCells';
import QrCodePrintDialog from '../../components/QR/QRPrintDialog';
import { editAnimalObject, getAllAnimalObjects, selectAnimalObjects } from '../../slices/AnimalObjectSlice';
import { AnimalObjectType } from '../../slices/data/AnimalObjectSliceData';
import { selectAvailableFirms } from '../../slices/FirmSlice';
import { selectHoldingProfile } from '../../slices/HoldingSlice';

const AnimalObjectGridContainer = () => {
  const dispatch = useAppDispatch();
  const animalObjects = useAppSelector(selectAnimalObjects);
  const holding = useAppSelector(selectHoldingProfile);
  const availableFirms = useAppSelector(selectAvailableFirms);

  const [dataState, setDataState] = useState<State>({
    sort: [{ field: 'code', dir: 'asc' }],
    take: 10,
    skip: 0,
  });

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataState(event.dataState);
  };

  useEffect(() => {
    const awaitData = async () => {
      await dispatch(getAllAnimalObjects());
    };
    awaitData();
  }, []);

  // Edit form state and functions
  const [openForm, setOpenForm] = useState(false);
  const [editItem, setEditItem] = useState<AnimalObjectType>(null);
  const enterEdit = (item: any) => {
    setOpenForm(true);
    setEditItem(item);
  };

  // QR code state functions
  const [openQrGenerator, setOpenQrGenerator] = useState(false);
  const enterQR = (item: any) => {
    setOpenQrGenerator(true);
    setEditItem(item);
  };

  const AOGridCommandsCell = ({ dataItem }: GridCellProps) => {
    const btns = [
      {
        action: enterEdit,
        dataItem,
        icon: 'edit',
        look: 'outline',
      } as GridCommandsCellPropsItem,
      {
        action: enterQR,
        dataItem,
        iconClass: 'fa fa-qrcode',
        look: 'outline',
      } as GridCommandsCellPropsItem,
    ];
    return <GridCommandsCell btns={btns} />;
  };

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const handleEdit = (formData: AnimalObjectType) => {
    dispatch(editAnimalObject(formData));
    setOpenForm(false);
  };

  const onQRDialogClose = () => {
    setOpenQrGenerator(false);
  };

  return (
    <div>
      <AnimalObjectGrid
        cell={AOGridCommandsCell}
        data={animalObjects}
        dataState={dataState}
        dataStateChange={dataStateChange}
      />
      {openForm && (
        <AnimalObjectEditForm
          cancelEdit={handleCancelEdit}
          onSubmit={handleEdit}
          item={editItem}
          holding={holding}
          availableFirms={availableFirms}
        />
      )}
      {openQrGenerator && (
        <QrCodePrintDialog
          onClose={onQRDialogClose}
          item={{ title: editItem.animalObjectName, id: editItem.animalObjectId, type: 'farm' }}
        />
      )}
    </div>
  );
};

export default AnimalObjectGridContainer;
