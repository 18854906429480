import React from 'react';
import { Loader } from '@progress/kendo-react-indicators';
import { useLocalization } from '@progress/kendo-react-intl';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import {
  saveAnimalObject,
  selectLoadingState
} from '../../slices/AnimalObjectSlice';
import AnimalObjectForm from '../../components/AnimalObjectForm/AnimalObjectForm';
import AnimalObjectGridContainer from '../AnimalObjectGridContainer/AnimalObjectGridContainer';
import { ANOBJ_TAB } from '../../languages/languages';
import { selectAvailableFirms } from '../../slices/FirmSlice';
import { AnimalObjectType } from '../../slices/data/AnimalObjectSliceData';
import { selectHoldingProfile } from '../../slices/HoldingSlice';

const AnimalObject = () => {
  const isLoading = useAppSelector(selectLoadingState);
  const localizationService = useLocalization();

  const dispatch = useAppDispatch();
  const availableFirms = useAppSelector(selectAvailableFirms);
  const holding = useAppSelector(selectHoldingProfile);

  const handleSubmit = (formData: AnimalObjectType) => {
    dispatch(saveAnimalObject(formData));
  };

  return (
    <>
      <div className='holding-form'>
        <h3 className='subtitle-1 mb-s'>
          {localizationService.toLanguageString(ANOBJ_TAB, '')}
        </h3>

        {isLoading ? (
          <Loader size='large' type='pulsing' className='k-centered' />
        ) : (
          <AnimalObjectForm
            availableFirms={availableFirms}
            submitForm={handleSubmit}
            holding={holding}
          />
        )}
      </div>
      <div className='holding-grid' id='animal-object-table'>
        <AnimalObjectGridContainer />
      </div>
    </>
  );
};

export default AnimalObject;
