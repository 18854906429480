import { gql } from '@apollo/client';

export const SaleTradesCategoriesQuery = gql`
  query SaleTradeCategoriesQuery {
    saleTrades {
      id
      value
    }
  }
`;

export const SaleTradesSeriesQuery = gql`
  query SaleTradesSeriesQuery {
    series {
      seriesId: id
      seriesValue
    }
  }
`;

export type SaleTradeCategoryType = {
  id: number,
  value: string
};

export type SaleTradeSeriesType = {
  id: number,
  seriesValue: number
};
