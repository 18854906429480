import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import {
  Field,
  Form,
  FormElement,
  FormRenderProps
} from '@progress/kendo-react-form';
import { useLocalization } from '@progress/kendo-react-intl';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import {
  ADD,
  ANIMAL_HEALTH_PROBLEM,
  ANIMAL_LID,
  ANIMAL_MORTALITY,
  ANIMAL_TERAPY,
  CANCEL,
  DATE,
  NO,
  YES
} from '../../languages/languages';
import { MortalityLogInputType, MortalityLogType } from '../../slices/data/MortalityLogSliceData';
import {
  FormComboBox,
  FormDatePicker,
  FormRadioGroup,
  FormTextArea
} from '../FormComponents/FormComponents';
import { useAppSelector } from '../../app/StoreHooks';
import { selectAllAnimals } from '../../slices/AnimalSlice';

type MortalityLogFormProps = {
  item?: MortalityLogType;
  submit: (formData: MortalityLogType | MortalityLogInputType) => void;
  close: () => void;
};
const MortalityLogForm = ({ item, submit, close }: MortalityLogFormProps) => {
  const localizationService = useLocalization();
  const animals = useAppSelector(selectAllAnimals);

  const deathSelect: { value: boolean; label: string }[] = [
    { value: true, label: localizationService.toLanguageString(YES, '') },
    { value: false, label: localizationService.toLanguageString(NO, '') }
  ];

  const selectedAnimal = animals.find((animal) =>
    animal?.animalId === item?.animal?.animalId);

  return (
    <Form
      initialValues={item}
      onSubmit={submit}
      render={(formRenderProps: FormRenderProps) =>
        (
          <FormElement>
            <Field
              label={localizationService.toLanguageString(DATE, '')}
              id='eventDate'
              name='eventDate'
              format='dd-MM-yyyy'
              component={FormDatePicker}
            />
            <Field
              label={localizationService.toLanguageString(ANIMAL_LID, '')}
              id='animalId'
              name='animalId'
              component={FormComboBox}
              data={animals}
              defaultValue={selectedAnimal}
              textField='lID'
              onChange={({ value }: ComboBoxChangeEvent) => {
                if (!value) {
                  return;
                }
                formRenderProps.onChange('animalId', { value: value.animalId });
              }}
            />
            <Field
              label={localizationService.toLanguageString(ANIMAL_MORTALITY, '')}
              id='isDeath'
              name='isDeath'
              layout='horizontal'
              data={deathSelect}
              component={FormRadioGroup}
            />
            <Field
              label={localizationService.toLanguageString(
                ANIMAL_HEALTH_PROBLEM,
                ''
              )}
              id='healthProblem'
              name='healthProblem'
              component={FormTextArea}
            />
            <Field
              label={localizationService.toLanguageString(ANIMAL_TERAPY, '')}
              id='therapy'
              name='therapy'
              component={FormTextArea}
            />
            <div className='k-form-buttons'>
              <Button
                name='Add new'
                type='submit'
                title='Добави'
                primary
                disabled={!formRenderProps.allowSubmit}
                icon='save'
              >
                {localizationService.toLanguageString(ADD, '')}
              </Button>
              <Button
                name='Cancel'
                type='submit'
                title='Откажи'
                primary
                look='outline'
                icon='cancel'
                onClick={close}
              >
                {localizationService.toLanguageString(CANCEL, '')}
              </Button>
            </div>
          </FormElement>
        )}
    />
  );
};

MortalityLogForm.defaultProps = {
  item: {}
};
export default MortalityLogForm;
