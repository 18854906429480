import { graphql } from 'msw';
import AUTH_CONFIG from '../../auth-variables';

const blbTrace = graphql.link(AUTH_CONFIG.apiUrl);

const contragents = [
  {
    contragentId: 1,
    contragentAccess: false,
    contragentName: 'КонтрагентДемо1',
    contragentRole: 'ДемоРоля1',
    contragentPhone: '07881234',
    contragentEmail: 'demoemail@demo.com',
    contragentFirmId: 1
  },
  {
    contragentId: 2,
    contragentAccess: true,
    contragentName: 'КонтрагентДемо2',
    contragentRole: 'ДемоРоля2',
    contragentPhone: '(+359)8756123',
    contragentEmail: 'demoemail2@demo.com',
    contragentFirmId: 3
  },
  {
    contragentId: 3,
    contragentAccess: true,
    contragentName: 'КонтрагентДемо3',
    contragentRole: 'ДемоРоля3',
    contragentPhone: '(+359)8712345',
    contragentEmail: 'demoemail3@demo.com',
    contragentFirmId: 4
  }
];

const firms = [
  {
    firmABId: 1,
    firmABName: 'AddressBookDemoFirm1'
  },
  {
    firmABId: 2,
    firmABName: 'AddressBookDemoFirm2'
  },
  {
    firmABId: 3,
    firmABName: 'ABDemoFirm3'
  },
  {
    firmABId: 4,
    firmABName: 'ABDemoFirm4'
  }
];

export default [
  blbTrace.query('getAllContragents', (req, res, ctx) =>
    res(
      ctx.data({
        contragents: {
          items: contragents
        }
      })
    )),
  blbTrace.query('getFirms', (req, res, ctx) =>
    res(
      ctx.data({
        addressBookFirm: {
          items: firms
        }
      })
    )),
  blbTrace.mutation('editContragentMutation', (req, res, ctx) => {
    const { contragent } = req.variables;
    const updateIndex = contragents.findIndex(
      (ctrg) =>
        ctrg.contragentId === contragent.contragentId
    );
    contragents[updateIndex] = contragent;
    return res(
      ctx.delay(3000),
      ctx.data({
        editContragentMutation: {
          contragent
        }
      })
    );
  }),
  blbTrace.mutation('saveContragent', (req, res, ctx) => {
    const { contragent } = req.variables;
    contragents.push(contragent);
    return res(
      ctx.delay(3000),
      ctx.data({
        saveContragent: {
          contragent
        }
      })
    );
  })
];
