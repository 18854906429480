import { graphql } from 'msw';
import AUTH_CONFIG from '../../auth-variables';

const blbTrace = graphql.link(AUTH_CONFIG.apiUrl);

const employees = [
  {
    employeeABId: 1,
    employeeABAccess: true,
    employeeABName: 'DemoName1',
    employeeABRole: 'Фермер',
    employeeABPhone: '0893748279',
    employeeABEmail: 'demo@email.com',
    employeeABFirmId: 11
  },
  {
    employeeABId: 2,
    employeeABAccess: false,
    employeeABName: 'DemoName2',
    employeeABRole: 'Ветеринар',
    employeeABPhone: '0893324279',
    employeeABEmail: 'vetDemo@email.com',
    employeeABFirmId: 11
  },
  {
    employeeABId: 3,
    employeeABAccess: true,
    employeeABName: 'DemoName3',
    employeeABRole: 'Съветник',
    employeeABPhone: '0894123427',
    employeeABEmail: 'demoAdvisor_@email.com',
    employeeABFirmId: 11
  },
  {
    employeeABId: 4,
    employeeABAccess: true,
    employeeABName: 'DemoName1',
    employeeABRole: 'Фермер',
    employeeABPhone: '0893748279',
    employeeABEmail: 'demo@email.com',
    employeeABFirmId: 11
  },
  {
    employeeABId: 5,
    employeeABAccess: false,
    employeeABName: 'DemoName2',
    employeeABRole: 'Ветеринар',
    employeeABPhone: '0893324279',
    employeeABEmail: 'vetDemo@email.com',
    employeeABFirmId: 11
  },
  {
    employeeABId: 6,
    employeeABAccess: true,
    employeeABName: 'DemoName3',
    employeeABRole: 'Съветник',
    employeeABPhone: '0894123427',
    employeeABEmail: 'demoAdvisor_@email.com',
    employeeABFirmId: 11
  },
  {
    employeeABId: 7,
    employeeABAccess: true,
    employeeABName: 'DemoName1',
    employeeABRole: 'Фермер',
    employeeABPhone: '0893748279',
    employeeABEmail: 'demo@email.com',
    employeeABFirmId: 11
  },
  {
    employeeABId: 8,
    employeeABAccess: false,
    employeeABName: 'DemoName2',
    employeeABRole: 'Ветеринар',
    employeeABPhone: '0893324279',
    employeeABEmail: 'vetDemo@email.com',
    employeeABFirmId: 11
  },
  {
    employeeABId: 9,
    employeeABAccess: true,
    employeeABName: 'DemoName3',
    employeeABRole: 'Съветник',
    employeeABPhone: '0894123427',
    employeeABEmail: 'demoAdvisor_@email.com',
    employeeABFirmId: 11
  },
  {
    employeeABId: 10,
    employeeABAccess: true,
    employeeABName: 'DemoName1',
    employeeABRole: 'Фермер',
    employeeABPhone: '0893748279',
    employeeABEmail: 'demo@email.com',
    employeeABFirmId: 11
  },
  {
    employeeABId: 11,
    employeeABAccess: false,
    employeeABName: 'DemoName2',
    employeeABRole: 'Ветеринар',
    employeeABPhone: '0893324279',
    employeeABEmail: 'vetDemo@email.com',
    employeeABFirmId: 11
  },
  {
    employeeABId: 12,
    employeeABAccess: true,
    employeeABName: 'DemoName3',
    employeeABRole: 'Съветник',
    employeeABPhone: '0894123427',
    employeeABEmail: 'demoAdvisor_@email.com',
    employeeABFirmId: 11
  },
  {
    employeeABId: 13,
    employeeABAccess: true,
    employeeABName: 'DemoName1',
    employeeABRole: 'Фермер',
    employeeABPhone: '0893748279',
    employeeABEmail: 'demo@email.com',
    employeeABFirmId: 11
  },
  {
    employeeABId: 14,
    employeeABAccess: false,
    employeeABName: 'DemoName2',
    employeeABRole: 'Ветеринар',
    employeeABPhone: '0893324279',
    employeeABEmail: 'vetDemo@email.com',
    employeeABFirmId: 11
  },
  {
    employeeABId: 15,
    employeeABAccess: true,
    employeeABName: 'DemoName3',
    employeeABRole: 'Съветник',
    employeeABPhone: '0894123427',
    employeeABEmail: 'demoAdvisor_@email.com',
    employeeABFirmId: 11
  },
  {
    employeeABId: 16,
    employeeABAccess: true,
    employeeABName: 'DemoName1',
    employeeABRole: 'Фермер',
    employeeABPhone: '0893748279',
    employeeABEmail: 'demo@email.com',
    employeeABFirmId: 11
  },
  {
    employeeABId: 17,
    employeeABAccess: false,
    employeeABName: 'DemoName2',
    employeeABRole: 'Ветеринар',
    employeeABPhone: '0893324279',
    employeeABEmail: 'vetDemo@email.com',
    employeeABFirmId: 11
  },
  {
    employeeABId: 18,
    employeeABAccess: true,
    employeeABName: 'DemoName3',
    employeeABRole: 'Съветник',
    employeeABPhone: '0894123427',
    employeeABEmail: 'demoAdvisor_@email.com',
    employeeABFirmId: 11
  },
  {
    employeeABId: 19,
    employeeABAccess: true,
    employeeABName: 'DemoName1',
    employeeABRole: 'Фермер',
    employeeABPhone: '0893748279',
    employeeABEmail: 'demo@email.com',
    employeeABFirmId: 11
  },
  {
    employeeABId: 20,
    employeeABAccess: false,
    employeeABName: 'DemoName2',
    employeeABRole: 'Ветеринар',
    employeeABPhone: '0893324279',
    employeeABEmail: 'vetDemo@email.com',
    employeeABFirmId: 11
  },
  {
    employeeABId: 31,
    employeeABAccess: true,
    employeeABName: 'DemoName3',
    employeeABRole: 'Съветник',
    employeeABPhone: '0894123427',
    employeeABEmail: 'demoAdvisor_@email.com',
    employeeABFirmId: 11
  }
];

const firms = [{ firmId: 11, firmName: 'TestFirm1' }];

export default [
  blbTrace.query('getAllEmployees', (req, res, ctx) =>
    res(
      ctx.data({
        employees: {
          items: employees
        }
      })
    )),
  blbTrace.query('getFirms', (req, res, ctx) =>
    res(
      ctx.data({
        addressBookFirm: {
          items: firms
        }
      })
    )),
  blbTrace.mutation('editEmployeeMutation', (req, res, ctx) => {
    const { employee } = req.variables;
    const empIndex = employees.findIndex(
      (empl) =>
        empl.employeeABId === employee.employeeABId
    );
    employees[empIndex] = employee;
    return res(
      ctx.delay(3000),
      ctx.data({
        editEmployeeMutation: {
          employee
        }
      })
    );
  }),
  blbTrace.mutation('saveEmployeeMutation', (req, res, ctx) => {
    const { employee } = req.variables;
    employees.push(employee);
    return res(
      ctx.delay(5000),
      ctx.data({
        saveEmployee: {
          employee
        }
      })
    );
  })
];
