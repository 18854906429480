import { useLocalization } from '@progress/kendo-react-intl';
import React, { useState } from 'react';

import { useAppDispatch } from '../../app/StoreHooks';
import DealInformation from '../../features/DealInformationContainer/DealInformationContainer';
import DealsHistoryGridContainer from '../../features/DealsHistoryGridContainer/DealsHistoryGridContainer';
import GrantAccess from '../../features/GrantAccess/GrantAccess';
import { DEALS_TITLE } from '../../languages/languages';
import { addSuccessMessage } from '../../slices/ErrorSlice';

const REQUIRED_ROLES: string[] = ['Admin', 'Holding Owner', 'Farm Manager', 'Abattoir Manager'];

const DealsContainer = () => {
  const dispatch = useAppDispatch();
  const localizationService = useLocalization();
  const [dealInfoVisibility, setDealInfoVisibility] = useState<boolean>(false);
  const onAccept = () => {
    dispatch(
      addSuccessMessage({
        message: 'Приетите животни, бяха заприходени успешно.',
        id: Math.floor(Math.random() * 10),
      })
    );
  };

  const onReject = () => {
    dispatch(
      addSuccessMessage({
        message: 'Сделката отказана успешно.',
        id: Math.floor(Math.random() * 10),
      })
    );
  };

  return (
    <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
      <h1 className='page-title'>{localizationService.toLanguageString(DEALS_TITLE, '')}</h1>
      <div className='content-wrapper section'>
        <DealsHistoryGridContainer setVisibility={setDealInfoVisibility} />
        {dealInfoVisibility && (
          <GrantAccess haveRoles='every' requiredRoles={REQUIRED_ROLES}>
            <DealInformation
              setVisibility={setDealInfoVisibility}
              onAccept={onAccept}
              onReject={onReject}
            />
          </GrantAccess>
        )}
      </div>
    </GrantAccess>
  );
};

export default DealsContainer;
