import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import {
  Field,
  Form,
  FormElement,
  FormRenderProps
} from '@progress/kendo-react-form';
import { useLocalization } from '@progress/kendo-react-intl';
import {
  SAVE,
  CANCEL,
  CHEMICAL_TREATEMENT_ACTIVE_CHEMICAL,
  CHEMICAL_TREATEMENT_CHEMICAL,
  CHEMICAL_TREATEMENT_CONCENTRATE,
  CHEMICAL_TREATEMENT_DOSE,
  CHEMICAL_TREATEMENT_PEST,
  CHEMICAL_TREATEMENT_TECHNIQUE,
  CHEMICAL_TREATEMENT_TREATED_AREA,
  DATE
} from '../../languages/languages';
import { ChemicalTreatmentLogInputType } from '../../slices/data/ChemicalTreatmentLogSliceData';
import {
  FormDatePicker,
  FormInput,
  FormNumericTextBox
} from '../FormComponents/FormComponents';

type ChemicalTreatmentLogFormProps = {
  item?: ChemicalTreatmentLogInputType;
  submit: (formData: ChemicalTreatmentLogInputType) => void;
  close: () => void;
};
const ChemicalTreatmentLogForm = ({
  item,
  submit,
  close
}: ChemicalTreatmentLogFormProps) => {
  const localizationService = useLocalization();
  return (
    <Form
      initialValues={item}
      onSubmit={submit}
      render={(formRenderProps: FormRenderProps) =>
        (
          <FormElement>
            <Field
              id='treatmentDate'
              name='treatmentDate'
              format='dd-MM-yyyy'
              label={localizationService.toLanguageString(DATE, '')}
              component={FormDatePicker}
            />
            <Field
              id='pest'
              name='pest'
              label={localizationService.toLanguageString(
                CHEMICAL_TREATEMENT_PEST,
                ''
              )}
              component={FormInput}
            />
            <Field
              id='dose'
              name='dose'
              label={localizationService.toLanguageString(
                CHEMICAL_TREATEMENT_DOSE,
                ''
              )}
              component={FormNumericTextBox}
            />
            <Field
              id='treatedArea'
              name='treatedArea'
              label={localizationService.toLanguageString(
                CHEMICAL_TREATEMENT_TREATED_AREA,
                ''
              )}
              component={FormNumericTextBox}
            />
            <Field
              id='applicationTechnique'
              name='applicationTechnique'
              label={localizationService.toLanguageString(
                CHEMICAL_TREATEMENT_TECHNIQUE,
                ''
              )}
              component={FormInput}
            />
            <Field
              id='chemical'
              name='chemical'
              label={localizationService.toLanguageString(
                CHEMICAL_TREATEMENT_CHEMICAL,
                ''
              )}
              component={FormInput}
            />
            <Field
              id='activeChemical'
              name='activeChemical'
              label={localizationService.toLanguageString(
                CHEMICAL_TREATEMENT_ACTIVE_CHEMICAL,
                ''
              )}
              component={FormInput}
            />
            <Field
              id='concentrate'
              name='concentrate'
              label={localizationService.toLanguageString(
                CHEMICAL_TREATEMENT_CONCENTRATE,
                ''
              )}
              component={FormNumericTextBox}
            />
            <div className='k-form-buttons'>
              <Button
                name='ChemicalTreatmentAddNew'
                type='submit'
                title={localizationService.toLanguageString(SAVE, '')}
                primary
                disabled={!formRenderProps.allowSubmit}
                icon='save'
              >
                {localizationService.toLanguageString(SAVE, '')}
              </Button>
              <Button
                name='ChemicalTreatmentCancel'
                type='submit'
                title={localizationService.toLanguageString(CANCEL, '')}
                primary
                look='outline'
                icon='cancel'
                onClick={close}
              >
                {localizationService.toLanguageString(CANCEL, '')}
              </Button>
            </div>
          </FormElement>
        )}
    />
  );
};

ChemicalTreatmentLogForm.defaultProps = {
  item: {}
};

export default ChemicalTreatmentLogForm;
