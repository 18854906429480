import React, { useState } from 'react';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridToolbar
} from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { State, process } from '@progress/kendo-data-query';
import { useLocalization } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';
import {
  categories,
  PurchasedFodderType
} from '../../slices/data/FodderLogSliceData';
import {
  ADD_NEW,
  EXPORT,
  FODDER_AMOUNT_BOUGHT,
  FODDER_BATCH_NUMBER,
  FODDER_CATEGORY,
  FODDER_COURIER,
  FODDER_DATE,
  FODDER_NAME,
  FODDER_NOTE
} from '../../languages/languages';
import GridSearchBar from '../../features/GridSearchBar/GridSearchBar';
import ColumnMenu from '../ColumnMenu/ColumnMenu';
import { DateCell, EditCommandCell } from '../CustomGridCells/CustomGridCells';
import { useAppSelector } from '../../app/StoreHooks';
import { selectPurchasedFodderLoadingState } from '../../slices/FodderLogSlice';
import GridLoaderPanel from '../GridLoaderPanel/GridLoaderPanel';
import GrantAccess from '../../features/GrantAccess/GrantAccess';

const REQUIRED_ROLES: string[] = ['Admin', 'Farm Manager', 'Holding Owner'];

type ProducedFodderLogGridProps = {
  data: PurchasedFodderType[];
  openLogForm: () => void;
  openEdit: (editItem: PurchasedFodderType) => void;
};

const PurchasedFodderLogGrid = ({
  data,
  openLogForm,
  openEdit
}: ProducedFodderLogGridProps) => {
  const exportLog = React.useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (exportLog.current !== null) {
      exportLog.current.save();
    }
  };

  const localizationService = useLocalization();
  const loadingData = useAppSelector(selectPurchasedFodderLoadingState);

  // Grid States
  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0
  });

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  };

  const FodderCategoryCell = ({ dataItem }: GridCellProps) => {
    const categoryName = categories.find(
      (cat) =>
        cat.value === dataItem.feedCategory
    ).label;
    return <td>{localizationService.toLanguageString(categoryName, '')}</td>;
  };

  const MyEditCommandCell = (props: GridCellProps) =>
    (
      <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
        <EditCommandCell {...props} enterEdit={openEdit} />
      </GrantAccess>
    );

  return (
    <ExcelExport data={data} ref={exportLog}>
      <Grid
        data={process(data, dataState)}
        {...dataState}
        onDataStateChange={dataStateChange}
        pageable
        sortable
      >
        <GridToolbar>
          <GridSearchBar
            dataState={dataState}
            filterBy='feedName'
            setDataState={setDataState}
          />
          <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
            <Button
              name='PurchasedFodderAddBtn'
              type='button'
              title={localizationService.toLanguageString(ADD_NEW, '')}
              icon='plus'
              primary
              onClick={openLogForm}
            >
              {localizationService.toLanguageString(ADD_NEW, '')}
            </Button>
            <Button
              name='PurchasedFodderExportBtn'
              type='button'
              title={localizationService.toLanguageString(EXPORT, '')}
              icon='download'
              primary
              look='outline'
              onClick={excelExport}
            >
              {localizationService.toLanguageString(EXPORT, '')}
            </Button>
          </GrantAccess>
        </GridToolbar>
        <Column
          key='feedDiaryDate'
          field='feedDiaryDate'
          title={localizationService.toLanguageString(FODDER_DATE, '')}
          filter='date'
          cell={DateCell}
          columnMenu={(props) =>
            <ColumnMenu {...props} />}
        />
        <Column
          key='feedName'
          field='feedName'
          title={localizationService.toLanguageString(FODDER_NAME, '')}
          filter='text'
          columnMenu={(props) =>
            <ColumnMenu {...props} />}
        />
        <Column
          key='feedCategory'
          field='feedCategory'
          title={localizationService.toLanguageString(FODDER_CATEGORY, '')}
          filter='text'
          cell={FodderCategoryCell}
          columnMenu={(props) =>
            <ColumnMenu {...props} />}
        />
        <Column
          key='lotNumber'
          field='lotNumber'
          title={localizationService.toLanguageString(FODDER_BATCH_NUMBER, '')}
          filter='numeric'
          columnMenu={(props) =>
            <ColumnMenu {...props} />}
        />
        <Column
          key='quantity'
          field='quantity'
          title={localizationService.toLanguageString(FODDER_AMOUNT_BOUGHT, '')}
          filter='numeric'
          columnMenu={(props) =>
            <ColumnMenu {...props} />}
        />
        <Column
          key='firm.firmABName'
          field='firm.firmABName'
          title={localizationService.toLanguageString(FODDER_COURIER, '')}
          filter='text'
          columnMenu={(props) =>
            <ColumnMenu {...props} />}
        />
        <Column
          key='comment'
          field='comment'
          title={localizationService.toLanguageString(FODDER_NOTE, '')}
        />
        <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
          <Column cell={MyEditCommandCell} width={120} />
        </GrantAccess>
      </Grid>
      {loadingData && <GridLoaderPanel />}
    </ExcelExport>
  );
};

export default PurchasedFodderLogGrid;
