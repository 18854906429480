import { Button, ButtonLook } from '@progress/kendo-react-buttons';
import { GridCellProps } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useLocalization } from '@progress/kendo-react-intl';
import moment from 'moment';
import React from 'react';

import { useAppSelector } from '../../app/StoreHooks';
import CattleBreeds from '../../features/FarmAnimalTableCattle/cattleBreeds';
import SheepBreeds from '../../features/FarmAnimalTableSheep/sheepBreeds';
import { FEMALE_ABBR, MALE_ABBR } from '../../languages/keys/keys';
import { ACTIVE, DEAL_ACCEPT_STATUS, DEAL_PARTIAL_ACCEPT_STATUS, DEAL_PENDING_STATUS, DEAL_REJECT_STATUS, INACTIVE } from '../../languages/languages';
import { selectContragentAvailableFirms } from '../../slices/ContragentsSlice';
import { selectAvailableFirms } from '../../slices/FirmSlice';
import { format } from '../../utils/utilities';

// Convert the animal age from the provided date string
// to a date in months
const getAnimalAgeInMonths = (animalBirthDate) => moment().diff(moment(animalBirthDate), 'months');

type AnimalInfoCellProps = {
  dataItem: any;
  dealData: any;
  animalsData: any;
};
// Custom Cell to display the Price Per Kilo
export const PricePerKilo = ({ dealData, animalsData, dataItem }: AnimalInfoCellProps) => {
  // Get the animal weight, either from the already set input in the grid
  // or from the values provided in the SellAnimalsForm
  const animalWeight: number = dataItem?.animalWeight
    ? dataItem?.animalWeight
    : (dealData?.vehicleTotalWeight - dealData?.vehicleTaraWeight) / animalsData?.length;
  // initiate the price variable to be calculated
  // based on the SellAnimalsForm inputs
  let price: number = 0;
  if (dealData?.pricePerHead !== null) {
    price = dealData?.pricePerHead / animalWeight;
  }
  // Return the cell value, based on the above calculations
  return <td>{dealData?.pricePerKilo ? dealData?.pricePerKilo.toFixed(2) : price.toFixed(2)}</td>;
};

// Custom Cell to display the Price Per Head
export const PricePerHead = ({ dealData, animalsData, dataItem }: AnimalInfoCellProps) => {
  // Get the animal weight, either from the already set input in the grid
  // or from the values provided in the SellAnimalsForm
  const animalWeight: number = dataItem?.animalWeight
    ? dataItem?.animalWeight
    : (dealData?.vehicleTotalWeight - dealData?.vehicleTaraWeight) / animalsData?.length;

  // initiate the price variable to be calculated
  // based on the SellAnimalsForm inputs
  let price: number = 0;

  // If pricePerKilo is set in the SellAnimalsForm component
  // calculate the pricePerHead
  if (dealData?.pricePerKilo !== null) {
    price = animalWeight * dealData?.pricePerKilo;
  }

  // Return the cell value, based on the above calculations
  return <td>{dealData?.pricePerHead ? dealData?.pricePerHead.toFixed(2) : price.toFixed(2)}</td>;
};

// Custom Cell for the price per 100 kg.
export const PricePer100Kilos = ({ dealData, animalsData, dataItem }: AnimalInfoCellProps) => {
  // Get the animal weight, either from the already set input in the grid
  // or from the values provided in the SellAnimalsForm
  const animalWeight: number = dataItem?.animalWeight
    ? dataItem?.animalWeight
    : (dealData?.vehicleTotalWeight - dealData?.vehicleTaraWeight) / animalsData?.length;

  // initiate the price variable to be calculated
  // based on the SellAnimalsForm inputs
  let price: number = 0;
  if (dealData?.pricePerHead !== null) {
    price = dealData?.pricePerHead / animalWeight;
  }
  // Return the cell value, based on the above calculations and add 100
  return (
    <td>
      {dealData?.pricePerKilo
        ? (dealData?.pricePerKilo * 100).toFixed(2)
        : (price * 100).toFixed(2)}
    </td>
  );
};

// Custom Cell to combine several animal properties
// so that they are displayed in one place
export const AnimalInfo = ({ dataItem }: GridCellProps) => {
  const missing = '--';
  return (
    <td>
      {dataItem.lID ? dataItem.lID : missing}|
      {/* Uses the getAnimalAgeInMonths function, in order to convert
        the animal birth date to age in months */}
      {`${getAnimalAgeInMonths(dataItem.dateOfBirth)}м.`}|{dataItem.eID ? dataItem.eID : missing}
    </td>
  );
};

type EditCommandCellProps = {
  enterEdit?: (item: any) => void;
  dataItem?: any;
};

export const EditCommandCell = ({ enterEdit, dataItem }: EditCommandCellProps) => (
  <td className='text-center'>
    <Button
      data-testid='editButton'
      type='submit'
      className='k-button k-secondary'
      icon='edit'
      look='outline'
      onClick={() => enterEdit(dataItem)}
    />
  </td>
);

EditCommandCell.defaultProps = {
  enterEdit: null,
  dataItem: {},
};

export type GridCommandsCellProps = {
  btns: GridCommandsCellPropsItem[];
};

export type GridCommandsCellPropsItem = {
  action?: (item: any) => void;
  dataItem?: any;
  icon?: string;
  iconClass?: string;
  look?: ButtonLook;
  label?: string;
};

export const GridCommandsCell = ({ btns }: GridCommandsCellProps) => {
  const arrBtns = [...btns];
  return (
    <td className='text-center'>
      {arrBtns.map((btn) => (
        <Button
          data-testid={btn.dataItem?.id}
          type='button'
          className='k-button k-secondary mr-s'
          icon={btn.icon}
          iconClass={btn.iconClass}
          look={btn.look || 'outline'}
          onClick={() => btn.action(btn.dataItem)}
        >
          {btn.label}
        </Button>
      ))}
    </td>
  );
};

export const BooleanCell = ({ dataItem, field }: GridCellProps) => {
  const localizationService = useLocalization();
  return (
    <td className='text-center'>
      {dataItem[field] ? (
        <span className='cell-status' id='active'>
          {localizationService.toLanguageString(ACTIVE, '')}
        </span>
      ) : (
        <span className='cell-status' id='inactive'>
          {localizationService.toLanguageString(INACTIVE, '')}
        </span>
      )}
    </td>
  );
};

export const FirmCell = ({ dataItem, field }: GridCellProps) => {
  const availableFirms = useAppSelector(selectAvailableFirms);

  const firmIndex = availableFirms.find((firm) => firm.firmId === dataItem[field]);
  return <td>{firmIndex?.firmName}</td>;
};

export const ABFirmCell = ({ dataItem, field }: GridCellProps) => {
  const firms = useAppSelector(selectContragentAvailableFirms);
  const firmIndex = firms.find((firm) => firm.firmABId === dataItem[field]);
  return <td>{firmIndex?.firmABName}</td>;
};

export const CustomLinkCell = ({ dataItem, field }: GridCellProps) => (
  <td>
    <a href={dataItem[field]} target='_blank' rel='noreferrer'>
      {dataItem[field]}
    </a>
  </td>
);

export const CustomRolesCell = ({ dataItem, field }: GridCellProps) => {
  const setRoles = dataItem[field]?.map((role) => role?.name);
  return <td>{setRoles?.join(', ')}</td>;
};

export const CustomDealStatusCell = ({ dataItem, field }: GridCellProps) => {
  const localizationService = useLocalization();
  const dealStatuses = [
    { value: 'PENDING', label: DEAL_PENDING_STATUS },
    { value: 'ACCEPTED', label: DEAL_ACCEPT_STATUS },
    { value: 'PARTIALLY', label: DEAL_PARTIAL_ACCEPT_STATUS },
    { value: 'CANCELED', label: DEAL_REJECT_STATUS },
  ];
  const itemStatus = dealStatuses.find(
    (status) => status.value === dataItem[field].toUpperCase()
  ).label;
  return (
    <td className='text-center'>
      <span className='cell-status' id={dataItem[field].toLowerCase()}>
        {localizationService.toLanguageString(itemStatus, '')}
      </span>
    </td>
  );
};

export const DateOfBirthCell = ({ dataItem, field }: GridCellProps) => (
  <td>{dataItem[field] && moment(dataItem[field]).format(format)}</td>
);

export const DateCell = ({ dataItem, field }: GridCellProps) => (
  <td>{dataItem[field] && moment(dataItem[field]).format(format)}</td>
);

// Generated Cell for proper display of breeds.
export const CattleBreedCell = ({ dataItem, field }: GridCellProps) => {
  const columnBreed = dataItem[field];
  const breed = CattleBreeds[columnBreed];
  return <td>{breed}</td>;
};

export const SheepBreedCell = ({ dataItem, field }: GridCellProps) => {
  const columnBreed = dataItem[field];
  const breed = SheepBreeds[columnBreed];
  return <td>{breed}</td>;
};

export const GridTrueCheckboxCell = ({ dataItem, field }: GridCellProps) => (
  <td className='text-center'>
    <Checkbox disabled={!dataItem[field]} className='not-allowed' value={dataItem[field]} />
  </td>
);

export const GridFalseCheckboxCell = ({ dataItem, field }: GridCellProps) => (
  <td className='text-center'>
    <Checkbox disabled={dataItem[field]} className='not-allowed' value={!dataItem[field]} />
  </td>
);

export const GridAnimalSexCell = ({ dataItem, field }: GridCellProps) => {
  const localizationService = useLocalization();
  const animalSex =
    dataItem[field] === 'FEMALE'
      ? localizationService.toLanguageString(FEMALE_ABBR, '')
      : localizationService.toLanguageString(MALE_ABBR, '');

  return <td>{animalSex}</td>;
};
