import butcheryKeys from './butchgery.keys';
import movementsKeys from './movements.keys';
import publicKeys from './public.keys';
import uniceKeys from './unece.keys';
import warehouseKeys from './warehouse.keys';

export const ACTIONS = 'ACTIONS';
export const ANIMAL = 'ANIMAL';
export const FEMALE_ABBR = 'FEMALE_ABBR';
export const MALE_ABBR = 'MALE_ABBR';
export const SAVE_IMAGE = 'SAVE_IMAGE';
export const SAVE_SVG = 'SAVE_SVG';
export const LOAD = 'LOAD';
export const SHOPPING_CENTER = 'SHOPPING_CENTER';
export const STEP_FROM = 'STEPS_FROM';

export default {
  butcheryKeys,
  movementsKeys,
  uniceKeys,
  publicKeys,
  warehouseKeys,
  ACTIONS,
  ANIMAL,
  FEMALE_ABBR,
  LOAD,
  MALE_ABBR,
  SAVE_IMAGE,
  SAVE_SVG,
  SHOPPING_CENTER,
  STEP_FROM
};
