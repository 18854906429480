/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/StoreHooks';
import {
  ChemicalTreatmentInputType,
  ChemicalTreatmentLogInputType,
  ChemicalTreatmentLogType,
  EDIT_CHEMICAL_TREATMENT_ENTRY,
  GetChemicalTreatmentsLogsType,
  GET_ALL_CHEMICAL_TREATMENT_ENTRIES,
  SAVE_CHEMICAL_TREATMENT_ENTRY
} from './data/ChemicalTreatmentLogSliceData';
import { createAsyncThunkGeneric, createAsyncThunkGenericVoid } from './GenericThunks';

type ChemicalTreatmentLogStateType = {
  chemicalTreatements: ChemicalTreatmentLogType[];
  chemicalTreatementsLoading: boolean;
};

const initialState: ChemicalTreatmentLogStateType = {
  chemicalTreatements: [],
  chemicalTreatementsLoading: false
};

// Thunks
export const getAllChemicalTreatmentLogEntries = createAsyncThunkGenericVoid(
  'chemicalTreatementsLog/getAllChemicalTreatmentLogEntries',
  async ({ client }) => {
    const response = await client.query<void, GetChemicalTreatmentsLogsType>(
      'chemicalTreatmentDiary',
      GET_ALL_CHEMICAL_TREATMENT_ENTRIES
    );
    return response?.items ? response?.items : [];
  }
);

export const saveChemicalTreatmentLogEntry = createAsyncThunkGeneric<
ChemicalTreatmentLogInputType,
ChemicalTreatmentLogType
>(
  'chemicalTreatementsLog/saveChemicalTreatmentLogEntry',
  async ({ client, input }) =>
    client.mutate<ChemicalTreatmentInputType, ChemicalTreatmentLogType>(
      'addChemicalTreatmentDiary',
      SAVE_CHEMICAL_TREATMENT_ENTRY,
      { chemicalTreatmentDiaryInput: input }
    )
);

export const editChemicalTreatmentLogEntry = createAsyncThunkGeneric<
ChemicalTreatmentLogInputType,
ChemicalTreatmentLogType
>(
  'chemicalTreatementsLog/editChemicalTreatmentLogEntry',
  async ({ client, input }) =>
    client.mutate<ChemicalTreatmentInputType, ChemicalTreatmentLogType>(
      'updateChemicalTreatmentDiary',
      EDIT_CHEMICAL_TREATMENT_ENTRY,
      { chemicalTreatmentDiaryInput: input }
    )
);

export const ChemicalTreatmentLogSlice = createSlice({
  name: 'chemicalTreatementsLog',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllChemicalTreatmentLogEntries.pending, (state) => {
        state.chemicalTreatementsLoading = true;
      })
      .addCase(
        getAllChemicalTreatmentLogEntries.fulfilled,
        (state, { payload }) => {
          state.chemicalTreatementsLoading = false;
          state.chemicalTreatements = payload.map((item) =>
            ({
              ...item,
              treatmentDate: new Date(item?.treatmentDate)
            }));
        }
      )
      .addCase(saveChemicalTreatmentLogEntry.pending, (state) => {
        state.chemicalTreatementsLoading = true;
      })
      .addCase(saveChemicalTreatmentLogEntry.fulfilled, (state, { payload }) => {
        state.chemicalTreatementsLoading = false;
        state.chemicalTreatements = [...state.chemicalTreatements, {
          ...payload,
          treatmentDate: new Date(payload?.treatmentDate)
        }];
      })
      .addCase(editChemicalTreatmentLogEntry.pending, (state) => {
        state.chemicalTreatementsLoading = true;
      })
      .addCase(editChemicalTreatmentLogEntry.fulfilled, (state, { payload }) => {
        state.chemicalTreatementsLoading = false;
        const updIndx = state.chemicalTreatements.findIndex((item) =>
          item.chemicalTreatmentDiaryId === payload.chemicalTreatmentDiaryId);
        state.chemicalTreatements[updIndx] = {
          ...payload,
          treatmentDate: new Date(payload?.treatmentDate)
        };
      });
  }
});

// Selectors
export const selectAllChemicalTreatmentsLogEntries = (state: RootState) =>
  state.chemicalTreatementsLog.chemicalTreatements;
export const selectChemicalTreatmentLoadingState = (state: RootState) =>
  state.chemicalTreatementsLog.chemicalTreatementsLoading;

// Reducer
export default ChemicalTreatmentLogSlice.reducer;
