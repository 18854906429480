import React, { useState } from 'react';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridToolbar
} from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { State, process } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { useLocalization } from '@progress/kendo-react-intl';
import { categories, SelfProducedFodderType } from '../../slices/data/FodderLogSliceData';
import GridSearchBar from '../../features/GridSearchBar/GridSearchBar';
import ColumnMenu from '../ColumnMenu/ColumnMenu';
import { DateCell, EditCommandCell } from '../CustomGridCells/CustomGridCells';
import {
  ADD_NEW,
  EXPORT,
  FODDER_AMOUNT_PRODUCED,
  FODDER_CATEGORY,
  FODDER_DATE,
  FODDER_NAME,
  FODDER_NOTE,
} from '../../languages/languages';
import { useAppSelector } from '../../app/StoreHooks';
import { selectProducedFodderLoadingState } from '../../slices/FodderLogSlice';
import GridLoaderPanel from '../GridLoaderPanel/GridLoaderPanel';
import GrantAccess from '../../features/GrantAccess/GrantAccess';

const REQUIRED_ROLES: string[] = ['Admin', 'Holding Owner', 'Farm Manager'];

type ProducedFodderLogGridProps = {
  data: SelfProducedFodderType[];
  openLogForm: () => void;
  openEditLogForm: (editItem: SelfProducedFodderType) => void;
};
const ProducedFodderLogGrid = ({
  data,
  openLogForm,
  openEditLogForm
}: ProducedFodderLogGridProps) => {
  const exportLog = React.useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (exportLog.current !== null) {
      exportLog.current.save();
    }
  };

  // Grid States
  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0
  });

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  };

  const localizationService = useLocalization();
  const loadingState = useAppSelector(selectProducedFodderLoadingState);

  const FodderCategoryCell = ({ dataItem }:GridCellProps) => {
    const categoryName = categories.find((cat) =>
      cat.value === dataItem.feedCategory).label;
    return (
      <td>
        {localizationService.toLanguageString(categoryName, '')}
      </td>
    );
  };

  const MyEditCommandCell = (props: GridCellProps) =>
    (
      <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
        <EditCommandCell {...props} enterEdit={openEditLogForm} />
      </GrantAccess>
    );

  return (
    <ExcelExport data={data} ref={exportLog}>
      <Grid
        data={process(data, dataState)}
        {...dataState}
        onDataStateChange={dataStateChange}
        pageable
        sortable
      >
        <GridToolbar>
          <GridSearchBar
            dataState={dataState}
            filterBy='feedName'
            setDataState={setDataState}
          />
          <GrantAccess requiredRoles={REQUIRED_ROLES} haveRoles='some'>
            <Button
              name='ProducedFodderAddBtn'
              type='button'
              title={localizationService.toLanguageString(ADD_NEW, '')}
              icon='plus'
              primary
              onClick={openLogForm}
            >
              {localizationService.toLanguageString(ADD_NEW, '')}
            </Button>
          </GrantAccess>
          <Button
            name='ProducedFodderExportBtn'
            type='button'
            title={localizationService.toLanguageString(EXPORT, '')}
            icon='download'
            primary
            look='outline'
            onClick={excelExport}
          >
            {localizationService.toLanguageString(EXPORT, '')}
          </Button>
        </GridToolbar>
        <Column
          key='feedDiaryDate'
          field='feedDiaryDate'
          title={localizationService.toLanguageString(FODDER_DATE, '')}
          filter='date'
          cell={DateCell}
          columnMenu={(props) =>
            <ColumnMenu {...props} />}
        />
        <Column
          key='feedName'
          field='feedName'
          title={localizationService.toLanguageString(FODDER_NAME, '')}
          filter='text'
          columnMenu={(props) =>
            <ColumnMenu {...props} />}
        />
        <Column
          key='feedCategory'
          field='feedCategory'
          title={localizationService.toLanguageString(FODDER_CATEGORY, '')}
          filter='text'
          cell={FodderCategoryCell}
          columnMenu={(props) =>
            <ColumnMenu {...props} />}
        />
        <Column
          key='quantity'
          field='quantity'
          title={localizationService.toLanguageString(FODDER_AMOUNT_PRODUCED, '')}
          filter='numeric'
          columnMenu={(props) =>
            <ColumnMenu {...props} />}
        />
        <Column
          key='comment'
          field='comment'
          title={localizationService.toLanguageString(FODDER_NOTE, '')}
        />
        <GrantAccess requiredRoles={REQUIRED_ROLES} haveRoles='some'>
          <Column cell={MyEditCommandCell} width={120} />
        </GrantAccess>
      </Grid>
      {loadingState && <GridLoaderPanel />}
    </ExcelExport>
  );
};

export default ProducedFodderLogGrid;
