import React from 'react';
import { CircularGauge } from '@progress/kendo-react-gauges';

interface CircularGaugeContainerProps {
  value: number
  color: string
}

const centerRenderer = (currentValue) =>
  (
    <h2>
      {currentValue}
      %
    </h2>
  );

export default function ProgressBarContainer({ value, color }: CircularGaugeContainerProps) {
  return (<CircularGauge value={value} centerRender={centerRenderer} color={color} />
  );
}
