/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/StoreHooks';
import {
  EDIT_SHOPPING_CENTER,
  GET_SHOPPING_CENTERS,
  SAVE_SHOPPING_CENTER,
  ShoppingCenterInputType,
  ShoppingCenterResponseType,
  ShoppingCenterType
} from './data/ShoppingCenterSliceData';
import {
  createAsyncThunkGeneric,
  createAsyncThunkGenericVoid
} from './GenericThunks';

type ShoppingCenterState = {
  shoppingCenters: ShoppingCenterType[];
  isLoading: boolean;
};

const initialState: ShoppingCenterState = {
  shoppingCenters: [],
  isLoading: false
};

// Async Thunks
export const getAllShoppingCenters = createAsyncThunkGenericVoid(
  'shoppingCenter/getAllShoppingCenters',
  async ({ client }) => {
    const response = await client.query<void, ShoppingCenterResponseType>(
      'shoppingCenter',
      GET_SHOPPING_CENTERS
    );
    return response?.items ? response?.items : [];
  }
);

export const saveShoppingCenter = createAsyncThunkGeneric<
ShoppingCenterType,
ShoppingCenterType
>('holding/saveShoppingCenter', async ({ client, input }) => {
  input.active = true;
  return client.mutate<ShoppingCenterInputType, ShoppingCenterType>(
    'addShoppingCenter',
    SAVE_SHOPPING_CENTER,
    { shoppingCenter: input }
  );
});

export const editShoppingCenter = createAsyncThunkGeneric<
ShoppingCenterType,
ShoppingCenterType
>(
  'holding/editShoppingCenter',
  async ({ client, input }) =>
    client.mutate<ShoppingCenterInputType, ShoppingCenterType>(
      'updateShoppingCenter',
      EDIT_SHOPPING_CENTER,
      { shoppingCenter: input }
    )
);

const ShoppingCenterSlice = createSlice({
  name: 'shoppingCenter',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllShoppingCenters.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllShoppingCenters.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.shoppingCenters = payload;
      })
      .addCase(editShoppingCenter.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editShoppingCenter.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const updated = state.shoppingCenters.findIndex(
          (shoppingCenter) =>
            shoppingCenter.shoppingCenterId === payload.shoppingCenterId
        );
        state.shoppingCenters[updated] = payload;
      })
      .addCase(saveShoppingCenter.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveShoppingCenter.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.shoppingCenters.push(payload);
      });
  }
});

// Export actions

// Export selectors
export const selectShoppingCenters = (state: RootState) =>
  state.shoppingCenter.shoppingCenters;
export const selectShopingCanterLoading = (state: RootState) =>
  state.shoppingCenter.isLoading;

// Export reducer
export default ShoppingCenterSlice.reducer;
