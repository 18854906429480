import { gql } from '@apollo/client';
// Types
export type ChemicalTreatmentLogType = {
  chemicalTreatmentDiaryId: number;
  treatmentDate: Date;
  pest: string;
  dose: number;
  chemical: string;
  activeChemical: string;
  concentrate: number;
  treatedArea: number;
  applicationTechnique: string;
};

export type GetChemicalTreatmentsLogsType = {
  items: ChemicalTreatmentLogType[];
};

export type ChemicalTreatmentLogInputType = {
  chemicalTreatmentDiaryId?: number;
  treatmentDate: Date;
  dose: number;
  concentrate: number;
  treatedArea: number;
  pest: string;
  chemical: string;
  activeChemical: string;
  applicationTechnique: string;
};

export type ChemicalTreatmentInputType = {
  chemicalTreatmentDiaryInput: ChemicalTreatmentLogInputType;
};

// Queries & Mutations
export const GET_ALL_CHEMICAL_TREATMENT_ENTRIES = gql`
  {
    chemicalTreatmentDiary {
      items {
        chemicalTreatmentDiaryId
        treatmentDate
        pest
        dose
        chemical
        activeChemical
        concentrate
        treatedArea
        applicationTechnique
      }
    }
  }
`;

export const SAVE_CHEMICAL_TREATMENT_ENTRY = gql`
  mutation AddChemicalTreatmentDiary(
    $chemicalTreatmentDiaryInput: chemicalTreatmentDiaryInput
  ) {
    addChemicalTreatmentDiary(
      chemicalTreatmentDiaryInput: $chemicalTreatmentDiaryInput
    ) {
      chemicalTreatmentDiaryId
      treatmentDate
      pest
      dose
      chemical
      activeChemical
      concentrate
      treatedArea
      applicationTechnique
    }
  }
`;

export const EDIT_CHEMICAL_TREATMENT_ENTRY = gql`
  mutation UpdateChemicalTreatmentDiary(
    $chemicalTreatmentDiaryInput: chemicalTreatmentDiaryInput
  ) {
    updateChemicalTreatmentDiary(
      chemicalTreatmentDiaryInput: $chemicalTreatmentDiaryInput
    ) {
      chemicalTreatmentDiaryId
      treatmentDate
      pest
      dose
      chemical
      activeChemical
      concentrate
      treatedArea
      applicationTechnique
    }
  }
`;
