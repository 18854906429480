import React from 'react';
import {
  Form,
  Field,
  FormElement,
  FormRenderProps
} from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { useLocalization } from '@progress/kendo-react-intl';
import {
  EnterpriseType,
  EntTypes
} from '../../slices/data/EnterpriseSliceData';
import {
  FormComboBox,
  FormInput
} from '../FormComponents/FormComponents';
import {
  NumberValidator,
  StringValidator
} from '../Validators/Validators';
import {
  ADDRESS_PLACEHOLDER,
  ENT_ADDRESS,
  ENT_NAME,
  ENT_NUMBER,
  ENT_OWNER,
  ENT_TYPE,
  ENT_TYPE_PLACEHOLDER,
  OWNER_PLACEHOLDER,
  SUBMIT_FORM,
} from '../../languages/languages';
import { AvailableFirmsType } from '../../slices/data/FirmSliceData';

type EnterpriseFormProps = {
  submitForm: (formData: EnterpriseType) => void;
  availableFirms: AvailableFirmsType[];
};

const EnterpriseForm = ({ submitForm, availableFirms }:EnterpriseFormProps) => {
  const localizationService = useLocalization();

  return (
    <Form
      onSubmit={submitForm}
      render={(formRenderProps: FormRenderProps) =>
        (
          <FormElement>
            <fieldset className='k-form-fieldset'>
              <Field
                name='enterpriseName'
                component={FormInput}
                label={localizationService.toLanguageString(ENT_NAME, '')}
                placeholder={localizationService.toLanguageString(ENT_NAME, '')}
                validator={StringValidator}
              />

              <Field
                name='enterpriseNumber'
                component={FormInput}
                label={localizationService.toLanguageString(ENT_NUMBER, '')}
                placeholder={localizationService.toLanguageString(
                  ENT_NUMBER,
                  ''
                )}
                validator={NumberValidator}
              />

              <Field
                onChange={({ value }: ComboBoxChangeEvent) => {
                  if (value === null) {
                    return;
                  }
                  formRenderProps.onChange('enterpriseType', {
                    value: value.type
                  });
                }}
                placeholder={localizationService.toLanguageString(
                  ENT_TYPE_PLACEHOLDER,
                  ''
                )}
                name='enterpriseType'
                component={FormComboBox}
                data={EntTypes}
                textField='label'
                label={localizationService.toLanguageString(ENT_TYPE, '')}
                validator={StringValidator}
              />
              <Field
                onChange={({ value }: ComboBoxChangeEvent) => {
                  if (value === null) {
                    return;
                  }
                  formRenderProps.onChange('firmId', {
                    value: value.firmId
                  });
                }}
                placeholder={localizationService.toLanguageString(
                  OWNER_PLACEHOLDER,
                  ''
                )}
                id='firmName'
                name='firmId'
                label={localizationService.toLanguageString(ENT_OWNER, '')}
                component={FormComboBox}
                textField='firmName'
                data={availableFirms}
                validator={StringValidator}
              />

              <Field
                name='enterpriseAddress'
                component={FormInput}
                label={localizationService.toLanguageString(ENT_ADDRESS, '')}
                placeholder={localizationService.toLanguageString(
                  ADDRESS_PLACEHOLDER,
                  ''
                )}
                validator={StringValidator}
              />
            </fieldset>
            <fieldset className='k-form-buttons d-flex justify-content-end mb-l'>
              <Button
                name='EnterpriseFormAdd'
                type='submit'
                title={localizationService.toLanguageString(SUBMIT_FORM, '')}
                primary
                icon='save'
                disabled={!formRenderProps.allowSubmit}
              >
                {localizationService.toLanguageString(SUBMIT_FORM, '')}
              </Button>
            </fieldset>
          </FormElement>
        )}
    />
  );
};

export default EnterpriseForm;
