import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { GridCellProps } from '@progress/kendo-react-grid';
import { useLocalization } from '@progress/kendo-react-intl';
import React, { useEffect, useRef, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import DropdownMenu from '../../components/DropdownMenu/DropdownMenu';
import TraderWarehouseGrid from '../../components/TraderWarehouseGrid/TraderWarehouseGrid';
import TraderWarehouseItemDisplay from '../../components/TraderWarehouseItem/Display';
import TraderWarehouseItemUpdate from '../../components/TraderWarehouseItem/Edit';
import TraderWarehouseItemUnregister from '../../components/TraderWarehouseItem/Unregister';
// import TraderWarehouseItemUpdate from '../../components/TraderWarehouseItem/Edit';
import { SHOPPING_CENTER } from '../../languages/keys/keys';
import { WAREHOUSE_TITLE } from '../../languages/keys/warehouse.keys';
import { ShoppingCenterType } from '../../slices/data/ShoppingCenterSliceData';
import { ITraderWarehouseGridItem } from '../../slices/data/WarehouseSliceData';
import { getAllShoppingCenters, selectShoppingCenters } from '../../slices/ShoppingCenterSlice';

const TraderWarehouseContainer = () => {
  const $t = useLocalization();
  const [shoppingCenter, setShoppingCenter] = useState<ShoppingCenterType>();
  const [selectedAnimals, setSelectedAnimals] = useState([]);
  const [showItemEdit, setShowItemEdit] = useState(false);
  const [showItemRemove, setShowItemRemove] = useState(false);
  const [showItemDisplay, setShowItemDisplay] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState<number>();
  const gridRef = useRef(null);

  const dispatch = useAppDispatch();
  const shoppingCenters = useAppSelector(selectShoppingCenters);

  useEffect(() => {
    const awaitData = async () => {
      await dispatch(getAllShoppingCenters());
    };
    awaitData();
  }, []);

  useEffect(() => {
    setSelectedAnimals([]);
  }, [shoppingCenter]);

  const onSelect = (data) => {
    setSelectedAnimals(data);
  };

  const editItem = (data: ITraderWarehouseGridItem) => {
    setSelectedItemId(data.batchCutId);
    setShowItemEdit(true);
  };

  const reloadGridData = () => {
    gridRef.current.reload();
  };

  const gridActions = () => {
    console.log('grid actions');

    return (
      <>
        {selectedAnimals.length > 0 && (
          <Button iconClass='k-icon fas fa-hand-holding-usd' primary look='outline'>
            Продажба
          </Button>
        )}
      </>
    );
  };

  const actionCell = ({ dataItem }: GridCellProps) => (
    <td className='text-center'>
      <DropdownMenu>
        <div className='mt-s mb-s ml-s mr-s'>
          <Button
            iconClass='fa fa-pencil'
            onClick={() => editItem(dataItem)}
            look='outline'
            className='mr-s mt-s'
          />
          <Button
            iconClass='fa fa-info'
            onClick={() => {
              setSelectedItemId(dataItem.batchCutId);
              setShowItemDisplay(true);
            }}
            look='outline'
            className='mr-s k-secondary mt-s'
          />
          <Button
            iconClass='fa fa-trash'
            onClick={() => {
              setSelectedItemId(dataItem.batchCutId);
              setShowItemRemove(true);
            }}
            look='outline'
            className='mr-s mt-s k-error'
          />
          <Button
            iconClass='fa fa-print'
            look='outline'
            className='mr-s mt-s k-primary'
          />
        </div>
      </DropdownMenu>
    </td>
  );

  return (
    <div>
      <h1 className='page-title'>{$t.toLanguageString(WAREHOUSE_TITLE, '')}</h1>
      <div className='row'>
        <div className='col-12'>
          <div>{$t.toLanguageString(SHOPPING_CENTER, '')}</div>
          <DropDownList
            data={shoppingCenters}
            key='shoppingCenterId'
            textField='shoppingCenterName'
            onChange={(e) => setShoppingCenter(e.value)}
          />
        </div>
      </div>
      <hr />
      {!shoppingCenter && (
        <div className='text-error'>
          <i className='fa fa-exclamation-circle' /> &nbsp; Изберете търговски обект
        </div>
      )}
      {shoppingCenter && (
        <TraderWarehouseGrid
          ref={gridRef}
          shoppingCenterId={shoppingCenter.shoppingCenterId}
          onSelect={onSelect}
          actions={gridActions}
          actionCell={actionCell}
        />
      )}

      {showItemDisplay && (
        <TraderWarehouseItemDisplay
          batchCutId={selectedItemId}
          onClose={() => setShowItemDisplay(false)}
        />
      )}

      {showItemEdit && (
        <TraderWarehouseItemUpdate
          batchCutId={selectedItemId}
          onClose={() => setShowItemEdit(false)}
          onUpdate={() => {
            reloadGridData();
            setShowItemEdit(false);
          }}
        />
      )}

      {showItemRemove && (
        <TraderWarehouseItemUnregister
          batchCutId={selectedItemId}
          onClose={() => setShowItemRemove(false)}
          onUpdated={() => {
            reloadGridData();
            setShowItemRemove(false);
          }}
        />
      )}
    </div>
  );
};

export default TraderWarehouseContainer;
