import { gql } from '@apollo/client';
import { DAMAGE_LEVEL_LOW } from '../../languages/en_US';
import {
  DAMAGE_LEVEL_HIGH,
  DAMAGE_LEVEL_MEDIUM,
  DAMAGE_LEVEL_TOTAL,
  UNKNOWN_CLASS
} from '../../languages/languages';

// types
export enum PestDamageLevelEnum {
  UNKNOWN = 'UNKNOWN',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  HIGH = 'HIGH',
  TOTAL = 'TOTAL'
}

export const pestDamageLevel: { value: PestDamageLevelEnum; label: string }[] = [
  { value: PestDamageLevelEnum.UNKNOWN, label: UNKNOWN_CLASS },
  { value: PestDamageLevelEnum.LOW, label: DAMAGE_LEVEL_LOW },
  { value: PestDamageLevelEnum.MEDIUM, label: DAMAGE_LEVEL_MEDIUM },
  { value: PestDamageLevelEnum.HIGH, label: DAMAGE_LEVEL_HIGH },
  { value: PestDamageLevelEnum.TOTAL, label: DAMAGE_LEVEL_TOTAL }
];

export type PestControlLogType = {
  pestControlDiaryId: number;
  controlDate: Date;
  pest: string;
  damagedCulture: string;
  usedChemical: string;
  chemicalType: string;
  meteorology: string;
  applicationMetod: string;
  damageLevel: PestDamageLevelEnum;
};

export type GetAllPestControlLogsType = {
  items: PestControlLogType[];
};

export type PestControlLogInputType = {
  pestControlDiaryId?: number;
  controlDate: Date;
  pest: string;
  damagedCulture: string;
  usedChemical: string;
  chemicalType: string;
  meteorology: string;
  applicationMetod: string;
  damageLevel: PestDamageLevelEnum;
};

export type SavePestControlMutationType = {
  pestControlDiaryInputType: PestControlLogInputType;
};
// Queries & Mutations
export const GET_ALL_PEST_CONTROL_LOGS = gql`
  {
    pestControlDiary {
      items {
        pestControlDiaryId
        controlDate
        pest
        damagedCulture
        usedChemical
        chemicalType
        meteorology
        applicationMetod
        damageLevel
      }
    }
  }
`;

export const SAVE_PEST_CONTROL_LOG_ENTRY = gql`
  mutation AddPestControlDiaryType(
    $pestControlDiaryInputType: pestControlDiaryInputType
  ) {
    addPestControlDiaryType(
      pestControlDiaryInputType: $pestControlDiaryInputType
    ) {
      pestControlDiaryId
      controlDate
      pest
      damagedCulture
      usedChemical
      chemicalType
      meteorology
      applicationMetod
      damageLevel
    }
  }
`;

export const EDIT_PEST_CONTROL_LOG_ENTRY = gql`
  mutation UpdatePestControlDiaryType(
    $pestControlDiaryInputType: pestControlDiaryInputType
  ) {
    updatePestControlDiaryType(
      pestControlDiaryInputType: $pestControlDiaryInputType
    ) {
      pestControlDiaryId
      controlDate
      pest
      damagedCulture
      usedChemical
      chemicalType
      meteorology
      applicationMetod
      damageLevel
    }
  }
`;
