/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../app/StoreHooks';
import { AnimalButcherType, AnimalCoolingType, AnimalToGradeType, AnimalType, BUTCHER_ANIMALS, ButcheredAnimalsType, FIRST_COOLING, FirstCoolingInputType, FirstCoolingType, GET_ANIMAL_COOLING, GET_ANIMALS, GET_ANIMALS_IN_BUTCHERY, GET_ANIMALS_IN_OBJECT, GET_BUTCHERED_ANIMALS, GET_GRADED_CARCASSES, GET_INSPECTED_CARCASSES, GetAnimalCoolingInputType, GetAnimalCoolingReponseType, GetAnimalsResponseType, GetButcheredAnimalsResponseType, GRADE_ANIMAL, GradeInputType, INSPECT_ANIMALS, InspectedAnimalType, KilledAnimalsInputType, SECOND_COOLING, SecondCoolingInputType, SecondCoolingType, SelectedAnimalType, SlaughterInformationType, VetInspectionInputType } from './data/AnimalSliceData';
import { createAsyncThunkGeneric, createAsyncThunkGenericVoid } from './GenericThunks';

type AnimalsStateType = {
  animals: AnimalType[];
  selectedAnimals: SelectedAnimalType[];
  animalsToButcher: SlaughterInformationType;
  animalsIsLoading: boolean;
  butcheredAnimals: ButcheredAnimalsType[];
  vetInspectedAnimals: AnimalType[];
  vetInspectionLoading: boolean;
  gradedAnimals: AnimalType[];
  animalToGrade: AnimalToGradeType;
  animalGradeLoading: boolean;
  animalCooling: AnimalCoolingType;
  animalCoolingLoading: boolean;
};

const initialState: AnimalsStateType = {
  animals: [],
  selectedAnimals: [],
  animalsToButcher: {
    matadorID: null,
    dateWeight: null,
    animals: []
  },
  butcheredAnimals: [],
  animalsIsLoading: false,
  vetInspectedAnimals: [],
  vetInspectionLoading: false,
  gradedAnimals: [],
  animalToGrade: {
    sEUROPDate: null,
    sEUROPClass: '',
    muscleClass: null,
    ossifiScore: null,
    meatColor: null,
    carcassYield: null,
    animalId: null,
    userId: null,
    ageClass: '',
    fatColor: '',
    teethFormula: '',
    carcassQuality: ''
  },
  animalGradeLoading: false,
  animalCooling: null,
  animalCoolingLoading: false
};
export const getAnimalsInFarm = createAsyncThunkGenericVoid(
  'animals/getAnimalsInFarm',
  async ({ client }) => {
    const response = await client.query<void, GetAnimalsResponseType>(
      'animals',
      GET_ANIMALS
    );
    return response?.items ? response?.items : [];
  }
);

export const getAnimalsInAnimalObject = createAsyncThunkGeneric<
  { objectId: number, animalType: number },
  GetAnimalsResponseType
>(
  'animalsInObject',
  async ({ client, input }) => client.query<
    { objectId: number, animalType: number },
    GetAnimalsResponseType>(
      'animalsInObject',
      GET_ANIMALS_IN_OBJECT,
      input
    )
);

export const getAnimalsInButchery = createAsyncThunkGenericVoid(
  'animals/getAnimalsInButchery',
  async ({ client }) => {
    const response = await client.query<void, GetAnimalsResponseType>(
      'animals',
      GET_ANIMALS_IN_BUTCHERY
    );
    return response?.items ? response?.items : [];
  }
);

export const getButcheredAnimals = createAsyncThunkGenericVoid(
  'animals/getButcheredAnimals',
  async ({ client }) => {
    const response = await client.query<void, GetButcheredAnimalsResponseType>(
      'animals',
      GET_BUTCHERED_ANIMALS
    );
    return response?.items ? response?.items : [];
  }
);

export const getInspectedCarcasses = createAsyncThunkGenericVoid(
  'animals/getInspectedCarcasses',
  async ({ client }) => {
    const response = await client.query<void, GetAnimalsResponseType>(
      'animals',
      GET_INSPECTED_CARCASSES
    );
    return response?.items ? response?.items : [];
  }
);

export const getGradedCarcasses = createAsyncThunkGenericVoid(
  'animals/getGradedCarcasses',
  async ({ client }) => {
    const response = await client.query<void, GetAnimalsResponseType>(
      'animals',
      GET_GRADED_CARCASSES
    );
    return response?.items ? response?.items : [];
  }
);

export const vetInspection = createAsyncThunkGeneric<
  InspectedAnimalType[],
  InspectedAnimalType[]
>('animals/vetInspection', async ({ client, input }) =>
  client.mutate<VetInspectionInputType, InspectedAnimalType[]>(
    'addVetInspection',
    INSPECT_ANIMALS,
    { vetInspectionInput: input }
  ));

export const butcherAnimals = createAsyncThunkGeneric<
  AnimalButcherType[],
  ButcheredAnimalsType[]
>('animals/butcherAnimals', async ({ client, input }) =>
  client.mutate<KilledAnimalsInputType, ButcheredAnimalsType[]>(
    'addKillData',
    BUTCHER_ANIMALS,
    { animalWeightInput: input }
  ));

export const gradeAnimal = createAsyncThunkGeneric<
  AnimalToGradeType,
  AnimalToGradeType
>('animals/gradeAnimal', async ({ client, input }) =>
  client.mutate<GradeInputType, AnimalToGradeType>('addSEUROP', GRADE_ANIMAL, {
    sEUROPInput: input
  }));

export const getAnimalCooling = createAsyncThunkGeneric<
  number,
  AnimalCoolingType
>('animals/getAnimalCooling', async ({ client, input }) => {
  const response = await client.query<
    GetAnimalCoolingInputType,
    GetAnimalCoolingReponseType
  >('animal', GET_ANIMAL_COOLING, { id: input });
  return response?.cooling || null;
});

export const animalFirstCooling = createAsyncThunkGeneric<
  FirstCoolingType,
  AnimalCoolingType
>('animals/animalFirstCooling', async ({ client, input }) =>
  client.mutate<FirstCoolingInputType, AnimalCoolingType>(
    'addCooling',
    FIRST_COOLING,
    { coolingInput: input }
  ));

export const animalSecondCooling = createAsyncThunkGeneric<
  SecondCoolingType,
  AnimalCoolingType
>('animals/animalSecondCooling', async ({ client, input }) =>
  client.mutate<SecondCoolingInputType, AnimalCoolingType>(
    'updateCooling',
    SECOND_COOLING,
    { coolingInput: input }
  ));

export const animalSlice = createSlice({
  name: 'animals',
  initialState,
  reducers: {
    setSelectedAnimals: (state, { payload }) => {
      state.selectedAnimals = payload;
    },
    setMatador: (state, { payload }) => {
      state.animalsToButcher.matadorID = payload;
    },
    setButcheredAnimals: (state, { payload }) => {
      state.animalsToButcher.animals = payload;
    },
    setButcherDate: (state, { payload }) => {
      state.animalsToButcher.dateWeight = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAnimalsInFarm.pending, (state) => {
        state.animalsIsLoading = true;
      })
      .addCase(getAnimalsInFarm.fulfilled, (state, { payload }) => {
        state.animalsIsLoading = false;
        state.animals = payload;
      })
      .addCase(getAnimalsInAnimalObject.pending, (state) => {
        state.animalsIsLoading = true;
      })
      .addCase(getAnimalsInAnimalObject.fulfilled, (state, { payload }) => {
        state.animalsIsLoading = false;
        state.animals = payload?.items ? payload?.items : [];
      })
      .addCase(getAnimalsInButchery.pending, (state) => {
        state.animalsIsLoading = true;
      })
      .addCase(getAnimalsInButchery.fulfilled, (state, { payload }) => {
        state.animalsIsLoading = false;
        state.animals = payload;
      })
      .addCase(getButcheredAnimals.pending, (state) => {
        state.animalsIsLoading = true;
      })
      .addCase(getButcheredAnimals.fulfilled, (state, { payload }) => {
        state.animalsIsLoading = false;
        state.butcheredAnimals = payload;
      })
      .addCase(getInspectedCarcasses.pending, (state) => {
        state.vetInspectionLoading = true;
      })
      .addCase(getInspectedCarcasses.fulfilled, (state, { payload }) => {
        state.vetInspectionLoading = false;
        state.vetInspectedAnimals = payload;
      })
      .addCase(getGradedCarcasses.pending, (state) => {
        state.animalCoolingLoading = true;
      })
      .addCase(getGradedCarcasses.fulfilled, (state, { payload }) => {
        state.animalCoolingLoading = false;
        state.gradedAnimals = payload;
      })
      .addCase(vetInspection.pending, (state) => {
        state.vetInspectionLoading = true;
      })
      .addCase(vetInspection.fulfilled, (state) => {
        state.vetInspectionLoading = true;
      })
      .addCase(butcherAnimals.pending, (state) => {
        state.animalsIsLoading = true;
      })
      .addCase(butcherAnimals.fulfilled, (state, { payload }) => {
        state.animalsIsLoading = false;
        state.butcheredAnimals = payload;
      })
      .addCase(gradeAnimal.pending, (state) => {
        state.animalGradeLoading = true;
      })
      .addCase(gradeAnimal.fulfilled, (state) => {
        state.animalGradeLoading = false;
      })
      .addCase(getAnimalCooling.pending, (state) => {
        state.animalCoolingLoading = true;
      })
      .addCase(getAnimalCooling.fulfilled, (state, { payload }) => {
        state.animalCoolingLoading = false;
        if (payload) {
          state.animalCooling = {
            ...payload,
            coolingDateFirst: new Date(payload?.coolingDateFirst),
            coolingDateSecond: payload?.coolingDateSecond
              ? new Date(payload?.coolingDateSecond)
              : null
          };
        } else {
          state.animalCooling = payload;
        }
      })
      .addCase(animalFirstCooling.pending, (state) => {
        state.animalCoolingLoading = true;
      })
      .addCase(animalFirstCooling.fulfilled, (state, { payload }) => {
        state.animalCoolingLoading = false;
        state.animalCooling = {
          ...payload,
          coolingDateFirst: new Date(payload?.coolingDateFirst)
        };
      })
      .addCase(animalSecondCooling.pending, (state) => {
        state.animalCoolingLoading = true;
      })
      .addCase(animalSecondCooling.fulfilled, (state, { payload }) => {
        state.animalCoolingLoading = false;
        state.animalCooling = {
          ...payload,
          coolingDateFirst: new Date(payload?.coolingDateFirst),
          coolingDateSecond: new Date(payload?.coolingDateSecond)
        };
      });
  }
});

// Actions
export const {
  setSelectedAnimals,
  setMatador,
  setButcheredAnimals,
  setButcherDate
} = animalSlice.actions;

// Export Selectors
export const selectAllAnimals = (state: RootState) =>
  state.animals.animals;
export const selectedVetInspectedAnimals = (state: RootState) =>
  state.animals.vetInspectedAnimals;
export const selectSelectedAnimals = (state: RootState) =>
  state.animals.selectedAnimals;
export const selectAnimalsLoading = (state: RootState) =>
  state.animals.animalsIsLoading;
export const selectAnimalsToButcher = (state: RootState) =>
  state.animals.animalsToButcher;
export const selectedButcheredAnimals = (state: RootState) =>
  state.animals.butcheredAnimals;
export const selectCooledAnimal = (state: RootState) =>
  state.animals.animalCooling;
export const selectCoolingLoading = (state: RootState) =>
  state.animals.animalCoolingLoading;
export const selectGradedAnimals = (state: RootState) =>
  state.animals.gradedAnimals;
// Export Reducer
export default animalSlice.reducer;
