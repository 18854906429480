import React from 'react';
import { State } from '@progress/kendo-data-query';
import { useLocalization } from '@progress/kendo-react-intl';
import { SEARCH_BAR } from '../../languages/languages';

type GridSearchBarProps = {
  filterBy: string;
  setDataState: React.Dispatch<React.SetStateAction<State>>;
  dataState: State;
  operator?: string;
};

const GridSearchBar = ({
  setDataState,
  dataState,
  filterBy,
  operator
}: GridSearchBarProps) => {
  // Init localization
  const localizationService = useLocalization();

  //   Search function that updates the grid filter state
  // Filter logic
  const onSearch = async ({ target }) => {
    setDataState({
      ...dataState,
      filter: {
        logic: 'and',
        filters: [
          {
            field: filterBy,
            operator,
            value: target.value
          }
        ]
      }
    });
  };

  return (
    <div className='k-searchbar'>
      <input
        type='text'
        placeholder={localizationService.toLanguageString(SEARCH_BAR, '')}
        onChange={onSearch}
      />
    </div>
  );
};

GridSearchBar.defaultProps = {
  operator: 'contains'
};

export default GridSearchBar;
