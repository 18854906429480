import { GET_DROPDOWN_VALUES, INomenclatureResponceData, NomenclatureNames } from './data/NomenclatureData';
import { createAsyncThunkGeneric } from './GenericThunks';

type NomenclatureParams = {
  nomenclatureName: NomenclatureNames;
};

// Async Thunks
export const getNomenclatureValues = createAsyncThunkGeneric<
  NomenclatureNames,
  INomenclatureResponceData>(
    'monemclatures',
    ({ client, input }) =>
      client.query<NomenclatureParams, INomenclatureResponceData>(
        'nomenclature',
        GET_DROPDOWN_VALUES,
        {
          nomenclatureName: input
        }
      )
  );

export default {};