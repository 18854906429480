/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/StoreHooks';
import {
  EDIT_USED_PESTICIDE_ENTRY,
  GetAllUsedPesticideEntries,
  GET_ALL_USED_PESTICIDE_ENTRIES,
  SAVE_USED_PESTICIDE_ENTRY,
  UsedPesticideInputType,
  UsedPesticideLogType
} from './data/UsedPesticideLogSliceData';
import {
  createAsyncThunkGeneric,
  createAsyncThunkGenericVoid
} from './GenericThunks';

type UsedPesticideLogSliceState = {
  usedPesticideLogs: UsedPesticideLogType[];
  usedPesticideLogsLoading: boolean;
};

const initialState: UsedPesticideLogSliceState = {
  usedPesticideLogs: [],
  usedPesticideLogsLoading: false
};

// Thunks
export const getAllUsedPesticideEntries = createAsyncThunkGenericVoid(
  'usedPesticideLogs/getAllUsedPesticideEntries',
  async ({ client }) => {
    const response = await client.query<void, GetAllUsedPesticideEntries>(
      'usedPesticideDiary',
      GET_ALL_USED_PESTICIDE_ENTRIES
    );
    return response?.items ? response?.items : [];
  }
);

export const saveUsedPesticideEntry = createAsyncThunkGeneric<
UsedPesticideLogType,
UsedPesticideLogType
>('usedPesticideLogs/saveUsedPesticideEntry', async ({ client, input }) =>
  client.mutate<UsedPesticideInputType, UsedPesticideLogType>(
    'addUsedPesticideDiary',
    SAVE_USED_PESTICIDE_ENTRY,
    { usedPesticideDiaryInput: input }
  ));

export const editUsedPesticideEntry = createAsyncThunkGeneric<
UsedPesticideLogType,
UsedPesticideLogType
>('usedPesticideLogs/editUsedPesticideEntry',
  async ({ client, input }) =>
    client.mutate<UsedPesticideInputType, UsedPesticideLogType>(
      'updateUsedPesticideDiary',
      EDIT_USED_PESTICIDE_ENTRY,
      { usedPesticideDiaryInput: input }
    ));

export const UsedPesticideLogSlice = createSlice({
  name: 'usedPesticideLogs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllUsedPesticideEntries.pending, (state) => {
        state.usedPesticideLogsLoading = true;
      })
      .addCase(getAllUsedPesticideEntries.fulfilled, (state, { payload }) => {
        state.usedPesticideLogsLoading = false;
        state.usedPesticideLogs = payload.map((item) =>
          ({
            ...item,
            pesticideDate: new Date(item?.pesticideDate)
          }));
      })
      .addCase(saveUsedPesticideEntry.pending, (state) => {
        state.usedPesticideLogsLoading = true;
      })
      .addCase(saveUsedPesticideEntry.fulfilled, (state, { payload }) => {
        state.usedPesticideLogsLoading = false;
        state.usedPesticideLogs = [...state.usedPesticideLogs, {
          ...payload,
          pesticideDate: new Date(payload?.pesticideDate)
        }];
      })
      .addCase(editUsedPesticideEntry.pending, (state) => {
        state.usedPesticideLogsLoading = true;
      })
      .addCase(editUsedPesticideEntry.fulfilled, (state, { payload }) => {
        state.usedPesticideLogsLoading = false;
        const updIndx = state.usedPesticideLogs.findIndex((item) =>
          item.usedPesticideId === payload.usedPesticideId);
        state.usedPesticideLogs[updIndx] = {
          ...payload,
          pesticideDate: new Date(payload?.pesticideDate)
        };
      });
  }
});

export const selectAllUsedPesticideLogEntries = (state: RootState) =>
  state.usedPesticideLog.usedPesticideLogs;
export const selectUsedPesticideLoadingState = (state: RootState) =>
  state.usedPesticideLog.usedPesticideLogsLoading;
// Reducer
export default UsedPesticideLogSlice.reducer;
