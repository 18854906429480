import { Button } from '@progress/kendo-react-buttons';
import { DatePicker, DatePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { useLocalization } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';

import { CONFIRM, FORWARD } from '../../languages/languages';
import { IBatch, IBatchAnimal } from '../../slices/data/ButcheryProcessSliceData';
import AnimalsForButcheryWeightsGrid from '../AnimalGrid/AnimalsForButcheryWeightsGrid';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';

const dateFormat = 'dd-MM-yyyy';

export interface IBatchTabProps {
  batch: IBatch;
  onNext: () => void;
}

const BatchTab = ({ batch, onNext }: IBatchTabProps) => {
  const $t = useLocalization();
  const [selectedAnimals, setSelectedAnimals] = useState<IBatchAnimal[]>([]);
  const [butcheredDate, setButcheredDate] = useState(new Date());

  const onButcheredDateChanged = (ev: DatePickerChangeEvent) => {
    setButcheredDate(ev.value);
  };

  useEffect(() => {
    batch.animals.forEach((animal) => {
      animal.butcheredDate = butcheredDate;
    });
    setSelectedAnimals(batch.animals);
  }, [batch]);

  const setAnimals = (items: IBatchAnimal[]) => {
    setSelectedAnimals(items);
  };

  const saveWeights = () => {
    batch.animals = selectedAnimals.filter((x) => !x.isWaste);
    batch.wasteAnimals = selectedAnimals.filter((x) => x.isWaste);
  };

  const [showSaveMessage, setShowSaveMessage] = useState(false);

  const onSave = (result: boolean) => {
    if (result) {
      saveWeights();
      onNext();
    }
    setShowSaveMessage(false);
  };

  const hasActiveAnimals = () => selectedAnimals.some((a) => !a.isWaste);

  const hasAnimalsWithoutWeight = () => selectedAnimals.some((a) => !a.weight && !a.isWaste);

  const disableContinue = () => hasAnimalsWithoutWeight() || !hasActiveAnimals();

  return (
    <div className='pl-l pr-l'>
      <div className='row mb-l'>
        <div className='col-12'>
          <h2>Създаване на кланична партида - попълване на живи тегла</h2>
          <p>
            Изберете само животните, които ще бъдат заклани. Aко има умрели по транспорта, системата
            ще ви предложи отписване.
          </p>
        </div>
        <hr />
      </div>
      <div className='row'>
        <div className='col-12 col-md-4'>
          <div>2-Дата на калне</div>{' '}
          <div>
            <DatePicker
              onChange={onButcheredDateChanged}
              format={dateFormat}
              value={butcheredDate}
            />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className='col-12 pt-l'>
          {selectedAnimals.length && (
            <AnimalsForButcheryWeightsGrid
              animals={selectedAnimals}
              butcheredDate={butcheredDate}
              onSelect={setAnimals}
            />
          )}
        </div>
        {hasAnimalsWithoutWeight() && (
          <div className='text-center text-error w-100 pt-l'>
            <i className='fas fa-exclamation-circle' /> &nbsp; За да продължите напред, трябва за
            всички жовотни, които не са маркирани като брак, да се въведе тегло!
          </div>
        )}
        {!hasActiveAnimals() && (
          <div className='text-center text-error w-100 pt-l'>
            <i className='fas fa-exclamation-circle' /> &nbsp; Трябва да има поне едно жовотно, което да не е маркирано като брак!
          </div>
        )}
        <div className='col-12 d-flex justify-content-center mt-l'>
          <Button primary disabled={disableContinue()} onClick={() => setShowSaveMessage(true)}>
            {$t.toLanguageString(FORWARD, '')}
          </Button>
        </div>
      </div>
      {showSaveMessage && (
        <ConfirmDialog
          message='Всички животни маркирани като "Брак" ще бъдат отписани от системата. Желаете ли да продължите?'
          title={$t.toLanguageString(CONFIRM, '')}
          onResult={onSave}
        />
      )}
    </div>
  );
};

export default BatchTab;
