import { gql } from '@apollo/client';
// Types
export type PastureLogType = {
  pastureDiaryId?: number;
  animalsCount: number;
  groupName: string;
  pastureName: string;
  dateIn: Date;
  dateOut: Date;
  daysIn: number;
  pastureSize: number;
  grassHeightIn: number;
  grassHeightOut: number;
};

export type GetPastureLogsType = {
  items: PastureLogType[];
};

export type SavePastureType = {
  pastureDiaryInput: PastureLogType;
};

// Queries & Mutations
export const GET_ALL_PASTURE_LOGS = gql`
  {
    pastureDiary {
      items {
        pastureDiaryId
        animalsCount
        groupName
        pastureName
        dateIn
        dateOut
        daysIn
        pastureSize
        grassHeightIn
        grassHeightOut
      }
    }
  }
`;

export const SAVE_PASTURE_LOG = gql`
  mutation AddPastureDiary($pastureDiaryInput: pastureDiaryInput) {
    addPastureDiary(pastureDiaryInput: $pastureDiaryInput) {
      pastureDiaryId
      animalsCount
      groupName
      pastureName
      dateIn
      dateOut
      daysIn
      pastureSize
      grassHeightIn
      grassHeightOut
    }
  }
`;

export const EDIT_PASTURE_LOG = gql`
  mutation UpdatePastureDiary($pastureDiaryInput: pastureDiaryInput) {
    updatePastureDiary(pastureDiaryInput: $pastureDiaryInput) {
      pastureDiaryId
      animalsCount
      groupName
      pastureName
      dateIn
      dateOut
      daysIn
      pastureSize
      grassHeightIn
      grassHeightOut
    }
  }
`;
