import React from 'react';
import {
  GridColumnMenuFilter,
  GridColumnMenuProps,
} from '@progress/kendo-react-grid';

const ColumnMenu = (props: GridColumnMenuProps) =>
  (
    <div>
      <GridColumnMenuFilter {...props} expanded />
    </div>
  );

export default ColumnMenu;
