import { useAppSelector } from '../app/StoreHooks';
import { selectHoldingProfile } from '../slices/HoldingSlice';
import { MainMenuType, selectHeaderMenu } from '../slices/MenuSlice';
import { selectUser } from '../slices/UserSlice';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FARMER_ROLES: string[] = [
  'Farm Employee',
  'Farmer',
  'Farm Manager',
  'Farm Veterinary Inspector'
];
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PROCESSOR_ROLES: string[] = [
  'Abattoir Employee',
  'Abattoir Manager',
  'Abattoir Processing Manager',
  'Abattoir Receiver',
  'Abattoir SEUROP Inspector',
  'Abattoir Speditor',
  'Abattoir Tech',
  'Abattoir Veterinary Inspector',
  'Abattoir Worker',
];

const useFilteredMenu = () => {
  const holding = useAppSelector(selectHoldingProfile);
  const { roles } = useAppSelector(selectUser);
  const headerMenu = useAppSelector(selectHeaderMenu);
  let filteredMenus: MainMenuType[] = [];

  const farmerPermissionGranted = FARMER_ROLES.some((role) =>
    roles.includes(role));
  const processorPermissionGranted = PROCESSOR_ROLES.some((role) =>
    roles.includes(role));
  const isAdmin = roles.includes('Admin');

  if ((holding?.isFeedlot || holding?.isFarm) && (farmerPermissionGranted || isAdmin)) {
    const farmMenus = headerMenu.filter((menu) =>
      menu.id === 'farmer');
    filteredMenus = [...filteredMenus, ...farmMenus];
  }
  if ((holding?.isAbattoir || holding?.isButchery) && (processorPermissionGranted || isAdmin)) {
    const processMenus = headerMenu.filter((menu) =>
      menu.id === 'processor');
    filteredMenus = [...filteredMenus, ...processMenus];
  }

  if (holding?.isShop && (processorPermissionGranted || isAdmin)) {
    const traderMenus = headerMenu.filter((menu) =>
      menu.id === 'trader');
    filteredMenus = [...filteredMenus, ...traderMenus];
  }

  return filteredMenus;
};

export default useFilteredMenu;
