import { gql } from '@apollo/client';

// Types
export type HoldingProfile = {
  holdingId?: number;
  holdingName: string;
  isFarm: boolean;
  isFeedlot: boolean;
  isAbattoir: boolean;
  isButchery: boolean;
  isShop: boolean;
  cattle: boolean;
  sheep: boolean;
};

// Queries & Mutations
export const GET_HOLDING = gql`
  query {
    holding {
      cattle
      holdingId
      holdingName
      isAbattoir
      isButchery
      isFarm
      isFeedlot
      isShop
      sheep
    }
  }
`;

export const SAVE_HOLDING = gql`
  mutation updateHolding($holding: holdingInput) {
    updateHolding(holding: $holding) {
      cattle
      holdingId
      holdingName
      isAbattoir
      isButchery
      isFarm
      isFeedlot
      isShop
      sheep
    }
  }
`;
