import React from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { useLocalization } from '@progress/kendo-react-intl';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import {
  AddressBookFirmInputType, FirmsAddressBookType, FirmTypes, FType
} from '../../slices/data/FirmsAddressBookSliceData';
import { FormComboBox, FormInput } from '../FormComponents/FormComponents';
import { StringValidator } from '../Validators/Validators';
import {
  ADD,
  ADDRESS_BOOK_FIRM_TYPE,
  CANCEL,
  EIK,
  FIRM_ADDRESS,
  FIRM_CITY,
  FIRM_COUNTRY,
  FIRM_MOL,
  FIRM_NAME
} from '../../languages/languages';

type AddAddressBookFirmProps = {
  item?: FirmsAddressBookType;
  cancelAdd: () => void;
  onSubmit: (formData: AddressBookFirmInputType) => void;
  setType: (value: string) => void;
};

const AddAddressBookFirm = ({
  item,
  cancelAdd,
  onSubmit,
  setType
}: AddAddressBookFirmProps) => {
  const localizationService = useLocalization();

  const selectedFirmType: FType = item.firmType ? (FirmTypes.find((type) =>
    (
      type.value === item.firmType[0]?.addresBookFirmType
    ))) : null;

  const setFirmType = ({ value }: ComboBoxChangeEvent) => {
    setType(value.value);
  };
  return (
    <Dialog title='Добави новa фирма' onClose={cancelAdd}>
      <Form
        initialValues={item}
        onSubmit={onSubmit}
        render={(formRenderProps) =>
          (
            <FormElement>
              <fieldset className='k-form-fieldset'>
                <Field
                  name='firmABEIK'
                  component={FormInput}
                  label={localizationService.toLanguageString(EIK, '')}
                  placeholder={localizationService.toLanguageString(EIK, '')}
                  validator={StringValidator}
                />
                <Field
                  name='firmABName'
                  component={FormInput}
                  label={localizationService.toLanguageString(FIRM_NAME, '')}
                  placeholder={localizationService.toLanguageString(
                    FIRM_NAME,
                    ''
                  )}
                  validator={StringValidator}
                />
                <Field
                  name='firmABCountry'
                  component={FormInput}
                  label={localizationService.toLanguageString(FIRM_COUNTRY, '')}
                  placeholder={localizationService.toLanguageString(
                    FIRM_COUNTRY,
                    ''
                  )}
                  validator={StringValidator}
                />
                <Field
                  name='firmABCity'
                  component={FormInput}
                  label={localizationService.toLanguageString(FIRM_CITY, '')}
                  placeholder={localizationService.toLanguageString(
                    FIRM_CITY,
                    ''
                  )}
                  validator={StringValidator}
                />
                <Field
                  name='firmABAddress'
                  component={FormInput}
                  label={localizationService.toLanguageString(FIRM_ADDRESS, '')}
                  placeholder={localizationService.toLanguageString(
                    FIRM_ADDRESS,
                    ''
                  )}
                  validator={StringValidator}
                />
                <Field
                  name='firmABMOL'
                  component={FormInput}
                  label={localizationService.toLanguageString(FIRM_MOL, '')}
                  placeholder={localizationService.toLanguageString(FIRM_MOL, '')}
                  validator={StringValidator}
                />
                <Field
                  name='addresBookFirmType'
                  component={FormComboBox}
                  label={localizationService.toLanguageString(ADDRESS_BOOK_FIRM_TYPE, '')}
                  placeholder='Тип на фирмата'
                  validator={StringValidator}
                  data={FirmTypes}
                  defaultValue={selectedFirmType}
                  textField='label'
                  onChange={setFirmType}
                />
              </fieldset>
              <div className='k-form-buttons'>
                <Button
                  name='Add new'
                  type='submit'
                  title='Добави'
                  primary
                  disabled={!formRenderProps.allowSubmit}
                  icon='save'
                >
                  {localizationService.toLanguageString(ADD, '')}
                </Button>
                <Button
                  name='Cancel'
                  type='submit'
                  title='Откажи'
                  primary
                  look='outline'
                  disabled={!formRenderProps.allowSubmit}
                  icon='cancel'
                  onClick={cancelAdd}
                >
                  {localizationService.toLanguageString(CANCEL, '')}
                </Button>
              </div>
            </FormElement>
          )}
      />
    </Dialog>
  );
};

AddAddressBookFirm.defaultProps = {
  item: {}
};
export default AddAddressBookFirm;
