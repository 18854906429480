export const BATCH_HISTORY = 'BATCH_HISTORY';
export const BATCH_HISTORY_SUBTEXT = 'BATCH_HISTORY_SUBTEXT';
export const BULLETIN = 'BULLETIN';
export const ENTERPRISE_MAIN_SUBTITLE = 'ENTERPRISE_MAIN_SUBTITLE';
export const FARM_MAIN_SUBTITLE = 'FARM_MAIN_SUBTITLE';
export const HERD_INVENTORY = 'HERD_INVENTORY';
export const LOCATION = 'LOCATION';
export const OTHER_HOLDING_OBJECTS = 'OTHER_HOLDING_OBJECTS';
export const PAGE_MAIN_TITLE = 'PAGE_MAIN_TITLE';
export const PREVIEW = 'PREVIEW';
export const QUАNTITIES = 'QUONTITIES';
export const QUАNTITIES_SUBTEXT = 'QUONTITIES_SUBTEXT';
export const QUАNTITIES_SUBTEXT_SMALL = 'QUONTITIES_SUBTEXT_SMALL';
export const SHOP_MAIN_SUBTITLE = 'SHOP_MAIN_SUBTITLE';
export const SOCIAL_MEDIA_SHARE = 'SOCIAL_MEDIA_SHARE';
export const SUBSCRIBE = 'SUBSCRIBE';
export const SUBSCRIBE_FOR_MONTHLY = 'SUBSCRIBE_FOR_MONTHLY';
export const SUBSCRIBE_NOW = 'SUBSCRIBE_NOW';
export const SUBSCRIBE_TEXT = 'SUBSCRIBE_TEXT';
export const TAGS = 'TAGS';

export default {
  BATCH_HISTORY,
  BATCH_HISTORY_SUBTEXT,
  BULLETIN,
  ENTERPRISE_MAIN_SUBTITLE,
  FARM_MAIN_SUBTITLE,
  HERD_INVENTORY,
  LOCATION,
  OTHER_HOLDING_OBJECTS,
  PAGE_MAIN_TITLE,
  PREVIEW,
  QUАNTITIES,
  QUАNTITIES_SUBTEXT,
  QUАNTITIES_SUBTEXT_SMALL,
  SHOP_MAIN_SUBTITLE,
  SOCIAL_MEDIA_SHARE,
  SUBSCRIBE,
  SUBSCRIBE_FOR_MONTHLY,
  SUBSCRIBE_NOW,
  SUBSCRIBE_TEXT,
  TAGS
};
