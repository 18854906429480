import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { useLocalization } from '@progress/kendo-react-intl';
import {
  BULLETIN,
  SUBSCRIBE,
  SUBSCRIBE_FOR_MONTHLY,
  SUBSCRIBE_NOW,
  SUBSCRIBE_TEXT,
} from '../../languages/keys/public.keys';
import { ReactComponent as ReactLogo } from '../../images/BLB_logo_bg.svg';

const BulletinSubsciption = () => {
  const $t = useLocalization();
  return (
    <>
      <div className='row pl-l pr-l mb-l pt-l'>
        <div className='col-6 col-md-3 offset-md-3'>
          <div style={{ width: '150px', float: 'right' }} className='pr-s'>
            <ReactLogo />
          </div>
        </div>
        <div className='col-6 col-md-3'>
          <div className=''>{$t.toLanguageString(SUBSCRIBE_FOR_MONTHLY, '')}</div>
          <div className='h4 mb-l' style={{ color: '#f35a26' }}>
            {$t.toLanguageString(BULLETIN, '')}
          </div>
          <div className='text-small'>
            <p>
              {$t.toLanguageString(SUBSCRIBE_TEXT, '')}
              <br />
              <h6>{$t.toLanguageString(SUBSCRIBE_NOW, '')}</h6>
            </p>
          </div>
        </div>
      </div>
      <div className='row pl-l pr-l mb-l'>
        <div className='col-md-6 offset-md-3 '>
          <div>
            <div className='d-flex mb-3'>
              <input
                type='text'
                className='form-control mr-s'
                placeholder='Електронна поща'
                aria-describedby='button-addon2'
                style={{ flex: 1 }}
              />
              <Button type='button'>{$t.toLanguageString(SUBSCRIBE, '')}</Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BulletinSubsciption;
