/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../app/StoreHooks';
import { GET_ENTERPRICE_SETTINGS, GET_FARM_SETTINGS, IUneceEnterpriceSettings, IUneceFarmSettings, UneceEnterpriceSettingsInput, UneceEnterpriceSettingsResponse, UneceFarmSettingsInput, UneceFarmSettingsResponse, UPDATE_ENTERPRICE_SETTINGS, UPDATE_FARM_SETTINGS } from './data/UneceSliceData';
import { createAsyncThunkGeneric, createAsyncThunkGenericVoid } from './GenericThunks';

interface UneceSettingsState {
  isLoading: boolean;
  farmSettings: IUneceFarmSettings,
  enterpriceSettings: IUneceEnterpriceSettings
}

const initialState: UneceSettingsState = {
  isLoading: false,
  farmSettings: {
    feedingSystemId: null,
    productionSystemId: null
  },
  enterpriceSettings: {
    bovineQualitySystemId: null,
    conformityAssessmentId: null,
    fatThicknessId: null,
    packingCodesId: null,
    postSlaughterSystemId: null,
    refrigerationId: null,
    slaughterSystemId: null,
    weightRangingId: null
  }
};

// Async Thunks
export const updateFarmSettings = createAsyncThunkGeneric<IUneceFarmSettings, any>(
  'updateUneceFarmSettings',
  ({ client, input }) =>
    client.mutate<UneceFarmSettingsInput, any>(
      'updateUneceFarmSettings',
      UPDATE_FARM_SETTINGS,
      {
        settingsInput: {
          ...input
        }
      }
    )
);

export const updateEnterpriceSettings = createAsyncThunkGeneric<IUneceEnterpriceSettings, any>(
  'updateEnterpriceSettings',
  ({ client, input }) =>
    client.mutate<UneceEnterpriceSettingsInput, any>(
      'updateUneceEnterpriceSettings',
      UPDATE_ENTERPRICE_SETTINGS,
      {
        settingsInput: {
          ...input
        }
      }
    )
);

export const getFarmSettings = createAsyncThunkGenericVoid(
  'holding/unecefarmsettings',
  ({ client }) =>
    client.query<void, UneceFarmSettingsResponse>(
      'uneceFarmSettings',
      GET_FARM_SETTINGS
    )
);

export const getEnterpriceSettings = createAsyncThunkGenericVoid(
  'holding/uneceEnterpriceSettings',
  ({ client }) =>
    client.query<void, UneceEnterpriceSettingsResponse>(
      'uneceEnterpriceSettings',
      GET_ENTERPRICE_SETTINGS
    )
);

// Slice
const UneceSettingsSlice = createSlice({
  name: 'uneceSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(updateFarmSettings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateFarmSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.farmSettings = payload.updateUneceFarmSettings;
      })
      .addCase(getFarmSettings.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFarmSettings.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.farmSettings = payload.uneceFarmSettings;
      });
  }
});

// Selectors
export const selectLoading = (state: RootState) =>
  state.movement.isLoading;

// Export Reducer
export default UneceSettingsSlice.reducer;
