import React from 'react';
import { useLocalization } from '@progress/kendo-react-intl';
import { State, process } from '@progress/kendo-data-query';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent
} from '@progress/kendo-react-grid';
import { ShoppingCenterType } from '../../slices/data/ShoppingCenterSliceData';
import {
  SHOP_NAME, SHOP_NUMBER, SHOP_TYPE, SHOP_ADDRESS, STATUS, SHOP_SMALL_SHOP, SHOP_MTS
} from '../../languages/languages';
import ColumnMenu from '../ColumnMenu/ColumnMenu';
import { BooleanCell } from '../FormComponents/FormComponents';
import { useAppSelector } from '../../app/StoreHooks';
import { selectShopingCanterLoading } from '../../slices/ShoppingCenterSlice';
import GridLoaderPanel from '../GridLoaderPanel/GridLoaderPanel';

type ShoppingCenterGridProps = {
  dataState: State;
  dataStateChange: (event: GridDataStateChangeEvent) => void;
  data: ShoppingCenterType[];
  cell: (props: GridCellProps) => any;
};
const ShoppingCenterGrid = ({
  data, dataState, dataStateChange, cell
}:ShoppingCenterGridProps) => {
  const localizationService = useLocalization();
  const loadingState = useAppSelector(selectShopingCanterLoading);

  const typeData: { label: string; type: string }[] = [
    { label: localizationService.toLanguageString(SHOP_SMALL_SHOP, ''), type: 'SMALL_SHOP' },
    { label: localizationService.toLanguageString(SHOP_MTS, ''), type: 'MODERN_TRADE_SHOP' }
  ];

  const TypeCell = ({ dataItem }: GridCellProps) =>
    (
      <td>
        {typeData.find((type) =>
          type.type === dataItem.shoppingCenterType).label}
      </td>
    );
  return (
    <>
      <Grid
        {...dataState}
        onDataStateChange={dataStateChange}
        sortable
        pageable
        data={process(data, dataState)}
      >
        <Column
          field='active'
          title={localizationService.toLanguageString(STATUS, '')}
          cell={BooleanCell}
          filter='boolean'
          columnMenu={ColumnMenu}
          width={150}
        />
        <Column
          field='shoppingCenterName'
          filter='text'
          columnMenu={ColumnMenu}
          title={localizationService.toLanguageString(SHOP_NAME, '')}
        />
        <Column
          field='shoppingCenterNumber'
          filter='numeric'
          columnMenu={ColumnMenu}
          title={localizationService.toLanguageString(SHOP_NUMBER, '')}
        />
        <Column
          field='shoppingCenterType'
          filter='text'
          cell={TypeCell}
          columnMenu={ColumnMenu}
          title={localizationService.toLanguageString(SHOP_TYPE, '')}
        />
        <Column
          field='shoppingCenterAddress'
          filter='text'
          columnMenu={ColumnMenu}
          title={localizationService.toLanguageString(SHOP_ADDRESS, '')}
        />
        <Column cell={cell} width={120} />
      </Grid>
      {loadingState && <GridLoaderPanel />}
    </>
  );
};

export default ShoppingCenterGrid;
