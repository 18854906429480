import { gql } from '@apollo/client';
import {
  UNKNOWN_CATEGORY,
  WETFEED_CATEGORY,
  WATER_CATEGORY,
  OTHER_CATEGORY,
  LONGFIBER_CATEGORY,
  CONCENTRATE_CATEGORY,
  ROOTS_CATEGORY,
  SOFTFIBER_CATEGORY,
  VITAMINS_CATEGORY,
  LIQUIDS_CATEGORY
} from '../../languages/languages';

export enum FeedDiaryTypeEnum {
  BOUGHT = 'BOUGHT',
  SELFMADE = 'SELFMADE'
}

export enum FeedCategoryEnum {
  UNKNOWN = 'UNKNOWN',
  WETFEED = 'WETFEED',
  WATER = 'WATER',
  OTHER = 'OTHER',
  LONGFIBER = 'LONGFIBER',
  CONCENTRATE = 'CONCENTRATE',
  ROOTS = 'ROOTS',
  SOFTFIBER = 'SOFTFIBER',
  VITAMINS = 'VITAMINS',
  LIQUIDS = 'LIQUIDS'
}

export const categories = [
  { value: FeedCategoryEnum.UNKNOWN, label: UNKNOWN_CATEGORY },
  { value: FeedCategoryEnum.WETFEED, label: WETFEED_CATEGORY },
  { value: FeedCategoryEnum.WATER, label: WATER_CATEGORY },
  { value: FeedCategoryEnum.OTHER, label: OTHER_CATEGORY },
  { value: FeedCategoryEnum.LONGFIBER, label: LONGFIBER_CATEGORY },
  { value: FeedCategoryEnum.CONCENTRATE, label: CONCENTRATE_CATEGORY },
  { value: FeedCategoryEnum.ROOTS, label: ROOTS_CATEGORY },
  { value: FeedCategoryEnum.SOFTFIBER, label: SOFTFIBER_CATEGORY },
  { value: FeedCategoryEnum.VITAMINS, label: VITAMINS_CATEGORY },
  { value: FeedCategoryEnum.LIQUIDS, label: LIQUIDS_CATEGORY }
];

export type FodderSupplierFirmType = {
  firmABId: number;
  firmABName: string;
};

export type SelfProducedFodderType = {
  feedDiaryId: number;
  feedDiaryDate: Date;
  feedName: string;
  quantity: number;
  comment: string;
  feedCategory: FeedCategoryEnum;
};

export type GetSelfProducedFodderType = {
  items: SelfProducedFodderType[];
};

export type PurchasedFodderType = {
  feedDiaryId: number;
  feedDiaryDate: Date;
  feedName: string;
  lotNumber: string;
  quantity: number;
  comment: string;
  feedCategory: FeedCategoryEnum;
  firm: FodderSupplierFirmType;
};

export type GetPurchasedFodderType = {
  items: PurchasedFodderType[];
};

export type SaveFodderType = {
  feedDiaryId?: number;
  feedDiaryDate: Date;
  feedName: string;
  lotNumber?: string;
  comment: string;
  quantity: number;
  firmABId?: number;
  feedCategory: FeedCategoryEnum;
  feedDiaryType: FeedDiaryTypeEnum;
};

export type SavePurchasedFodderInputType = {
  feedDiaryInput: SaveFodderType;
};

export type GetFodderSupplierFirmsType = {
  items: {
    firm: FodderSupplierFirmType;
  }[];
};

export const GET_PRODUCED_FODDER = gql`
  {
    feedDiarySelfMade {
      items {
        feedDiaryId
        feedCategory
        feedDiaryDate
        feedName
        quantity
        comment
      }
    }
  }
`;

export const SAVE_PRODUCED_FODDER = gql`
  mutation AddFeedDiary($feedDiaryInput: feedDiaryInput) {
    addFeedDiary(feedDiaryInput: $feedDiaryInput) {
      feedDiaryId
      feedDiaryDate
      feedName
      quantity
      comment
      feedCategory
      feedDiaryType
    }
  }
`;

export const EDIT_PRODUCED_FODDER = gql`
  mutation ($feedDiaryInput: feedDiaryInput) {
    updateFeedDiary(feedDiaryInput: $feedDiaryInput) {
      feedDiaryId
      feedCategory
      feedDiaryDate
      feedName
      quantity
      comment
    }
  }
`;

export const GET_PURCHASED_FODDER = gql`
  {
    feedDiaryBought {
      items {
        feedDiaryId
        feedDiaryDate
        feedName
        lotNumber
        quantity
        comment
        feedCategory
        firm {
          firmABId
          firmABName
        }
      }
    }
  }
`;

export const SAVE_PURCHASED_FODDER = gql`
  mutation AddFeedDiary($feedDiaryInput: feedDiaryInput) {
    addFeedDiary(feedDiaryInput: $feedDiaryInput) {
      feedDiaryId
      feedDiaryDate
      feedName
      lotNumber
      quantity
      comment
      feedCategory
      feedDiaryType
      firm {
        firmABId
        firmABName
      }
    }
  }
`;

export const EDIT_PURCHASED_FODDER = gql`
  mutation UpdateFeedDiary($feedDiaryInput: feedDiaryInput) {
    updateFeedDiary(feedDiaryInput: $feedDiaryInput) {
      feedDiaryId
      feedDiaryDate
      feedName
      lotNumber
      quantity
      comment
      feedCategory
      feedDiaryType
      firm {
        firmABId
        firmABName
      }
    }
  }
`;

export const GET_SUPPLIER_FIRMS = gql`
  {
    typeOfFirm(type: SUPPLIER) {
      items {
        firm {
          firmABId
          firmABName
        }
      }
    }
  }
`;
