import React, { useCallback, useEffect } from 'react';
import { Form, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { Stepper } from '@progress/kendo-react-layout';
import { useLocalization } from '@progress/kendo-react-intl';
import { useAppDispatch } from '../../app/StoreHooks';
import { getFirmNameAndID, getExternalFirms } from '../../slices/FirmSlice';
import DealAndTrasportDetails from '../../components/SellAnimalsFormComponents/DealAndTransportDetails';
import AnimalWeight from '../../components/SellAnimalsFormComponents/AnimalWeight';
import PricingDetails from '../../components/SellAnimalsFormComponents/PricingDetails';
import {
  PREVIOUS, REVISE, NEXT, START, WEIGTHS, PRICING
} from '../../languages/languages';
import { getTransportFirms } from '../../slices/FirmsAddressBookSlice';

const stepPages = [DealAndTrasportDetails, AnimalWeight, PricingDetails];

type SellAnimalsFormProps = {
  setVisibility: React.Dispatch<boolean>;
};

const SellAnimalsForm = ({
  setVisibility
}: SellAnimalsFormProps) => {
  const localizationService = useLocalization();
  const dispatch = useAppDispatch();

  // On load get the available firms for the current holding
  // and all firms in the trace system, so that they can be used in the
  // DealAndTransportDetails component
  useEffect(() => {
    const awaitData = () => {
      dispatch(getFirmNameAndID());
      dispatch(getExternalFirms());
      dispatch(getTransportFirms());
    };
    awaitData();
  }, []);

  const [step, setStep] = React.useState(0);
  const [formState, setFormState] = React.useState({});
  const [steps, setSteps] = React.useState([
    { label: localizationService.toLanguageString(START, ''), isValid: undefined },
    { label: localizationService.toLanguageString(WEIGTHS, ''), isValid: undefined },
    { label: localizationService.toLanguageString(PRICING, ''), isValid: undefined }
  ]);

  const lastStepIndex = steps.length - 1;
  const isLastStep = lastStepIndex === step;
  const isPreviousStepsValid = steps
    .slice(0, step)
    .findIndex((currentStep) =>
      currentStep.isValid === false) === -1;

  const onStepSubmit = useCallback(
    (event) => {
      const { isValid, values } = event;

      const currentSteps = steps.map((currentStep, index) =>
        ({
          ...currentStep,
          isValid: index === step ? isValid : currentStep.isValid
        }));

      setSteps(currentSteps);
      setStep(() =>
        Math.min(step + 1, lastStepIndex));
      setFormState(values);

      // If the multi step form is on the last step
      // and all previous steps are valid, it will open the SellPreview component
      if (isLastStep && isPreviousStepsValid && isValid) {
        setVisibility(false);
      }
    },
    [
      step,
      steps,
      setSteps,
      setStep,
      setFormState,
      isLastStep,
      isPreviousStepsValid
    ]
  );

  const onPrevClick = useCallback(
    (event) => {
      event.preventDefault();
      setStep(() =>
        Math.max(step - 1, 0));
    },
    [step, setStep]
  );

  const StepPage = stepPages[step];

  return (
    <div>
      <Stepper value={step} items={steps} />
      <Form
        initialValues={formState}
        onSubmitClick={onStepSubmit}
        render={(formRenderProps) =>
          (
            <FormElement>
              {stepPages[step] === DealAndTrasportDetails ? (
                <StepPage formProps={formRenderProps} />
              ) : (
                <StepPage />
              )}

              <hr />
              <div className='k-form-buttons align-items-center'>
                <span className='subtitle-1 nowrap' style={{ whiteSpace: 'nowrap' }}>
                  Стъпка
                  {' '}
                  {step + 1}
                  {' '}
                  от 3
                </span>

                {step !== 0 ? (
                  <Button
                    name='SellPreviousBtn'
                    type='button'
                    title={localizationService.toLanguageString(PREVIOUS, '')}
                    primary
                    look='outline'
                    icon='undo'
                    onClick={onPrevClick}
                  >
                    {localizationService.toLanguageString(PREVIOUS, '')}
                  </Button>
                ) : undefined}
                <Button
                  name='SellNextBtn'
                  type='button'
                  title={isLastStep ? localizationService.toLanguageString(REVISE, '') : localizationService.toLanguageString(NEXT, '')}
                  icon={isLastStep ? 'zoom' : 'redo'}
                  primary
                  disabled={isLastStep ? !isPreviousStepsValid : false}
                  onClick={formRenderProps.onSubmit}
                >
                  {isLastStep ? localizationService.toLanguageString(REVISE, '') : localizationService.toLanguageString(NEXT, '')}
                </Button>
              </div>
              <hr />
            </FormElement>
          )}
      />
    </div>
  );
};

export default SellAnimalsForm;
