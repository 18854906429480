/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/StoreHooks';
import { BLBSError, SuccessMessageType } from './data/ErrorSliceData';

type ErrorState = {
  errors: BLBSError[];
  successMessage: SuccessMessageType[];
};

const initialState: ErrorState = {
  errors: [],
  successMessage: []
};

export const ErrorSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    addError: (state, action) => {
      state.errors = [...(state.errors || []), action.payload];
    },
    setErrors: (state, { payload }) => {
      state.errors = payload;
    },
    addSuccessMessage: (state, { payload }) => {
      state.successMessage.push(payload);
    },
    removeSuccessMessage: (state) => {
      state.successMessage = [];
    }
  },
  extraReducers: {}
});

export const {
  setErrors, addError, addSuccessMessage, removeSuccessMessage
} = ErrorSlice.actions;
export const selectError = (state: RootState) =>
  state.errors;

export default ErrorSlice.reducer;
