import { gql } from '@apollo/client';

// Types
export type UserType = {
  nickname: string;
  name: string;
  picture: string;
  updated_at: Date;
  email: string;
  email_verified: boolean;
  sub: string;
  org_id: string;
  permissions: string[];
  roles: string[];
};

export const AllUserRoles: string[] = [
  'Abattoir Employee',
  'Abattoir Manager',
  'Abattoir Processing Manager',
  'Abattoir Receiver',
  'Abattoir SEUROP Inspector',
  'Abattoir Speditor',
  'Abattoir Tech',
  'Abattoir Veterinary Inspector',
  'Abattoir Worker',
  'Admin',
  'Analyzer',
  'Candidate',
  'End Consumer ',
  'Farm Employee',
  'Farmer',
  'Farm Manager',
  'Farm Veterinary Inspector',
  'Holding Employee',
  'Holding Owner',
  'Processing Employee',
  'Processing Inspector',
  'Processing Manager',
  'Processing Receiver',
  'Processing Speditor',
  'Processing Tech',
  'Processing worker',
  'Registered User ',
  'Trader',
  'Trader worker',
  'Veterinary Inspector',
  'Аuditor'
];

export type UserRoleType = {
  id: string;
  name: string;
};

export type UserRoleMultiSelectType = {
  value: string;
  label: string
};

export type GetUserRolesType = {
  items: UserRoleType[]
};

// Queries & Mutations
export const GET_ALL_USER_ROLES = gql`
{
userRoles {
  items {
    id
    name
  }
}
}`;
