import React from 'react';
import { useLocalization } from '@progress/kendo-react-intl';
import { State, process } from '@progress/kendo-data-query';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent
} from '@progress/kendo-react-grid';
import ColumnMenu from '../ColumnMenu/ColumnMenu';
import { BooleanCell, FirmCell } from '../FormComponents/FormComponents';
import {
  ENT_ADDRESS, ENT_NAME, ENT_NUMBER, ENT_OWNER, ENT_TYPE, STATUS
} from '../../languages/languages';
import { EnterpriseType, EntTypes } from '../../slices/data/EnterpriseSliceData';
import { useAppSelector } from '../../app/StoreHooks';
import { selectEnterpriseLoading } from '../../slices/EnterpriseSlice';
import GridLoaderPanel from '../GridLoaderPanel/GridLoaderPanel';

type EnterpriseGridProps = {
  dataState: State;
  dataStateChange: (event: GridDataStateChangeEvent) => void;
  data: EnterpriseType[];
  cell: (props: GridCellProps) => any;
};

const EnterpriseGrid = ({
  data, dataState, dataStateChange, cell
}: EnterpriseGridProps) => {
  const TypeCell = ({ dataItem }: GridCellProps) =>
    (
      <td>
        {EntTypes.find((type) =>
          type.type === dataItem.enterpriseType).label}
      </td>
    );

  const localizationService = useLocalization();
  const loadingState = useAppSelector(selectEnterpriseLoading);
  return (
    <>
      <Grid
        {...dataState}
        onDataStateChange={dataStateChange}
        sortable
        pageable
        data={process(data, dataState)}
      >
        <Column
          field='active'
          title={localizationService.toLanguageString(STATUS, '')}
          cell={BooleanCell}
          filter='boolean'
          columnMenu={ColumnMenu}
          width={150}
        />
        <Column
          field='enterpriseName'
          filter='text'
          columnMenu={ColumnMenu}
          title={localizationService.toLanguageString(ENT_NAME, '')}
        />
        <Column
          field='enterpriseNumber'
          filter='numeric'
          columnMenu={ColumnMenu}
          title={localizationService.toLanguageString(ENT_NUMBER, '')}
        />
        <Column
          field='enterpriseType'
          cell={TypeCell}
          title={localizationService.toLanguageString(ENT_TYPE, '')}
          filter='text'
          columnMenu={ColumnMenu}
        />
        <Column
          field='firmId'
          filter='text'
          title={localizationService.toLanguageString(ENT_OWNER, '')}
          cell={FirmCell}
          columnMenu={ColumnMenu}
        />
        <Column
          field='enterpriseAddress'
          filter='text'
          columnMenu={ColumnMenu}
          title={localizationService.toLanguageString(ENT_ADDRESS, '')}
        />
        <Column cell={cell} width={120} />
      </Grid>
      {loadingState && <GridLoaderPanel />}
    </>
  );
};

export default EnterpriseGrid;
