import { gql } from '@apollo/client';

export interface INomenclatureData {
  id: number;
  code: string;
  text: string;
  textEn: string;
}

export interface INomenclatureResponceData {
  values: INomenclatureData[];
}

export enum NomenclatureNames {
  bovineQualitySystem = 'BovineQualitySystem',
  cattleColor = 'CattleColor',
  conformityAssessment = 'ConformityAssessment',
  fatThickness = 'FatThickness',
  feedingSystem = 'FeedingSystem',
  packingCodes = 'PackingCodes',
  postSlaughterSystem = 'PostSlaughterSystem',
  productionSystem = 'ProductionSystem',
  refrigeration = 'Refrigeration',
  slaughterSystem = 'SlaughterSystem',
  unregisterReason = 'UnregisterReason',
  weightRanging = 'WeightRanging',
}

export const GET_DROPDOWN_VALUES = gql`
  query nomenclature($nomenclatureName: String) {
    nomenclature(nomenclatureName: $nomenclatureName){
      values {
        id,
        code,
        text,
        textEn
      }
    }
  }
`;