import { gql } from '@apollo/client';

import CreateGQLClient, { getGraphQLClient } from '../app/Client';
import { IListResult } from '../interfaces/request';

const client = getGraphQLClient() || CreateGQLClient();
const enterpriceListQuery = gql`
query enterprise($active: Boolean, $firmId: Int) {
  enterprise(active:$active, firmId: $firmId) {
    items {
      enterpriseId,
      enterpriseName,
      enterpriseNumber,
    }
  }
}
`;

interface IEnterpriseRawData {
  enterpriseId: number;
  enterpriseName: string;
  enterpriseNumber: string;
}

export interface IDropdownModel {
  id: number;
  text: string;
  info: string;
}

class DropdownService {
  getEnterprices(active = true, firmId: number | undefined = undefined): Promise<IDropdownModel[]> {
    return new Promise((resolve, reject) => {
      client
        .query<{ active: boolean, firmId: number }, IListResult<IEnterpriseRawData>>('enterprise', enterpriceListQuery, { active, firmId })
        .then((data) => {
          if (data.items && data.items.length) {
            resolve(data.items.map((item) => ({
              id: item.enterpriseId,
              text: item.enterpriseName,
              info: item.enterpriseNumber
            } as IDropdownModel)));
          } else {
            reject();
          }
        })
        .catch(reject);
    });
  }
}

export default new DropdownService();