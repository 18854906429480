import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import {
  getAllAddressBookFirms,
  selectFirmsAddressBook,
  saveAddressBookFirm,
  updateAddressBookFirm
} from '../../slices/FirmsAddressBookSlice';
import {
  AddressBookFirmInputType, AddressBookFirmTypeEnum, FirmsAddressBookType, FirmType
} from '../../slices/data/FirmsAddressBookSliceData';
import AddAddressBookFirm from '../../components/AddAddressBookFirm/AddAddressBookFirm';
import AddressBookFirmGrid from '../../components/AddressBookFirmGrid/AddressBookFirmGrid';
import GrantAccess from '../GrantAccess/GrantAccess';

const REQUIRED_ROLES: string[] = ['Admin', 'Holding Owner', 'Farm Manager', 'Farm Veterinary Inspector'];
const CU_ROLES: string[] = ['Admin', 'Holding Owner', 'Farm Manager'];

const AddressBookFirmsContainer = () => {
  const dispatch = useAppDispatch();
  const addressBookFirms = useAppSelector(selectFirmsAddressBook);

  useEffect(() => {
    const awaitData = async () => {
      await dispatch(getAllAddressBookFirms());
    };
    awaitData();
  }, []);

  // Edit form state and functions
  const [openForm, setOpenForm] = useState(false);
  const [editItem, setEditItem] = useState<FirmsAddressBookType>(null);
  const enterEdit = (item: FirmsAddressBookType) => {
    setOpenForm(true);
    setEditItem(item);
  };

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  // Add New Firm state and functions
  const [openAddForm, setOpenAddForm] = useState(false);

  const [firmType, setFirmType] = useState<FirmType[]>([]);
  const setTypeOfFirm = (value: AddressBookFirmTypeEnum) => {
    setFirmType([...firmType, {
      addresBookFirmType: value
    }]);
  };

  const submitNewFirm = (formData: AddressBookFirmInputType) => {
    dispatch(saveAddressBookFirm({ ...formData, addresBookFirmType: firmType }));
    setOpenAddForm(false);
  };

  const handleEdit = (formData: AddressBookFirmInputType) => {
    const editedFirm = {
      addresBookFirmType: firmType,
      firmABAddress: formData.firmABAddress,
      firmABCity: formData.firmABCity,
      firmABCountry: formData.firmABCountry,
      firmABEIK: formData.firmABEIK,
      firmABId: formData.firmABId,
      firmABMOL: formData.firmABMOL,
      firmABName: formData.firmABName
    };
    dispatch(updateAddressBookFirm(editedFirm));
    setOpenForm(false);
  };

  const handleCancelAdd = () => {
    setOpenAddForm(false);
  };

  const addNew = () => {
    setOpenAddForm(true);
  };

  return (
    <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
      <AddressBookFirmGrid
        addFirm={addNew}
        data={addressBookFirms}
        enterEdit={enterEdit}
      />
      <GrantAccess haveRoles='some' requiredRoles={CU_ROLES}>
        {openForm && (
        <AddAddressBookFirm
          cancelAdd={handleCancelEdit}
          onSubmit={handleEdit}
          item={editItem}
          setType={setTypeOfFirm}
        />
        )}
        {openAddForm && (
        <AddAddressBookFirm
          cancelAdd={handleCancelAdd}
          onSubmit={submitNewFirm}
          setType={setTypeOfFirm}
        />
        )}
      </GrantAccess>
    </GrantAccess>
  );
};

export default AddressBookFirmsContainer;
