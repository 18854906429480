import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement, FormSubmitClickEvent } from '@progress/kendo-react-form';
import { useLocalization } from '@progress/kendo-react-intl';
// import { useEffect, useState } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import { BACKWORD, FORWARD } from '../../languages/languages';
import { getAnimalObjectsByFirm, selectAnimalObjects } from '../../slices/AnimalObjectSlice';
import { AvailableFirmsType } from '../../slices/data/FirmSliceData';
import { getActiveFirmNameAndID, selectAvailableFirms } from '../../slices/FirmSlice';
// import { FormDropDownList } from '../../slices/data/AnimalObjectSliceData';
import { FormDropDownList } from '../FormComponents/FormComponents';

// import { NumberValidator, StringValidator } from '../Validators/Validators';

type OwnershipProps = {
  onNext: (data: any) => void;
  onPrev: () => void;
  model: any;
};

const RegistrationOwnershipData = ({ onNext, onPrev, model }: OwnershipProps) => {
  const localizationService = useLocalization();
  const dispatch = useAppDispatch();
  const animalObjects = useAppSelector(selectAnimalObjects);
  const firms = useAppSelector(selectAvailableFirms);
  const [firm, setFirm] = useState<AvailableFirmsType>();
  const onFirmChange = (e) => {
    setFirm(e.value);
  };

  const onSubmit = (e: FormSubmitClickEvent) => {
    onNext(e.values);
  };

  useEffect(() => {
    if (firm) {
      const awaitData = async () => {
        await dispatch(getAnimalObjectsByFirm({ firmId: firm.firmId, active: true }));
      };
      awaitData();
    }
  }, [firm]);

  useEffect(() => {
    const awaitData = async () => {
      await dispatch(getActiveFirmNameAndID());
    };
    awaitData();
  }, []);

  return (
    <Form
      initialValues={model}
      onSubmitClick={onSubmit}
      render={() => (
        <FormElement>
          <div className='mb-3'>
            <div>
              <div className='mb-l'>
                <div className='h4'>Собственост и локация</div>
                <p>
                  Фирмите на холдинга се добавят от &nbsp;
                  <a href='/holding-profile' target='_blank' className='text-primary'>
                    профил на холдинга
                  </a>
                  , животновъдните обекти принадлежат на фирми. Една фирма може да има повече от
                  един обект.
                </p>
              </div>
              <div className='row'>
                <div className='col-12 col-md-4 col-xl-3 pl-s pr-s'>
                  <Field
                    id='firmId'
                    name='firmId'
                    label='Фирма'
                    component={FormDropDownList}
                    data={firms}
                    textField='firmName'
                    dataItemKey='firmId'
                    onChange={onFirmChange}
                    value={firm}
                  />
                </div>
                <div className='col-12 col-md-4 col-xl-3 pl-s pr-s'>
                  <Field
                    id='animalObjectId'
                    name='animalObjectId'
                    label='Ж.О.'
                    component={FormDropDownList}
                    data={animalObjects.map((x) => ({
                      animalObjectId: x.animalObjectId,
                      animalObjectName: `${x.animalObjectName} (${x.animalObjectNumber})`,
                    }))}
                    textField='animalObjectName'
                    dataItemKey='animalObjectId'
                  />
                </div>
              </div>
            </div>
          </div>
          <hr />
          <div className='d-flex justify-content-center mb-l align-items-center'>
            <div className='mr-l' style={{ height: 'fit-content' }}>
              Стъпка 2 от 3
            </div>
            <Button
              name='Back'
              type='button'
              title={localizationService.toLanguageString(BACKWORD, '')}
              primary
              iconClass='fa fa-reply'
              onClick={onPrev}
              className='mr-l'
            >
              {localizationService.toLanguageString(BACKWORD, '')}
            </Button>
            <Button
              name='OwnershipDataAdd'
              type='submit'
              title={localizationService.toLanguageString(FORWARD, '')}
              primary
              iconClass='fa fa-share'
            >
              {localizationService.toLanguageString(FORWARD, '')}
            </Button>
          </div>
          <hr />
        </FormElement>
      )}
    />
  );
};

export default RegistrationOwnershipData;
