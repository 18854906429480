import React, { useEffect } from 'react';
import { GridCellProps, GridDataStateChangeEvent } from '@progress/kendo-react-grid';
import { State } from '@progress/kendo-data-query';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import { GridCommandsCell, GridCommandsCellPropsItem } from '../../components/CustomGridCells/CustomGridCells';
import {
  editShoppingCenter,
  getAllShoppingCenters,
  selectShoppingCenters,
} from '../../slices/ShoppingCenterSlice';
import { ShoppingCenterType } from '../../slices/data/ShoppingCenterSliceData';
import ShoppingCenterGrid from '../../components/ShoppingCenterGrid/ShoppingCenterGrid';
import ShoppingCenterEditForm from '../../components/ShoppingCenterEditForm/ShoppingCenterEditForm';
import QrCodePrintDialog from '../../components/QR/QRPrintDialog';

const ShoppingCenterGridContainer = () => {
  const dispatch = useAppDispatch();
  const shoppingCenters = useAppSelector(selectShoppingCenters);

  const [dataState, setDataState] = React.useState<State>({
    sort: [{ field: 'code', dir: 'asc' }],
    take: 10,
    skip: 0,
  });

  // const data: { label: string; type: string }[] = [
  //   {
  //     label: localizationService.toLanguageString(SHOP_SMALL_SHOP, ''),
  //     type: 'SMALL_SHOP'
  //   },
  //   {
  //     label: localizationService.toLanguageString(SHOP_MTS, ''),
  //     type: 'MODERN_TRADE_SHOP'
  //   }
  // ];

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataState(event.dataState);
  };

  useEffect(() => {
    const awaitData = async () => {
      await dispatch(getAllShoppingCenters());
    };
    awaitData();
  }, []);

  // Edit form state and functions
  const [openForm, setOpenForm] = React.useState(false);
  const [editItem, setEditItem] = React.useState<ShoppingCenterType>(null);
  const enterEdit = (item: ShoppingCenterType) => {
    setOpenForm(true);
    setEditItem(item);
  };

  // QR code state functions
  const [openQrGenerator, setOpenQrGenerator] = React.useState(false);
  const enterQR = (item: any) => {
    setOpenQrGenerator(true);
    setEditItem(item);
  };

  const AOGridCommandsCell = ({ dataItem }: GridCellProps) => {
    const btns = [
      {
        action: enterEdit,
        dataItem,
        icon: 'edit',
        look: 'outline',
      } as GridCommandsCellPropsItem,
      {
        action: enterQR,
        dataItem,
        iconClass: 'fa fa-qrcode',
        look: 'outline',
      } as GridCommandsCellPropsItem,
    ];
    return <GridCommandsCell btns={btns} />;
  };

  const onQRDialogClose = () => {
    setOpenQrGenerator(false);
  };

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const handleEdit = (formData: ShoppingCenterType) => {
    dispatch(editShoppingCenter(formData));
    setOpenForm(false);
  };

  return (
    <div>
      <ShoppingCenterGrid
        cell={AOGridCommandsCell}
        data={shoppingCenters}
        dataState={dataState}
        dataStateChange={dataStateChange}
      />
      {openForm && (
        <ShoppingCenterEditForm
          cancelEdit={handleCancelEdit}
          onSubmit={handleEdit}
          item={editItem}
        />
      )}
      {openQrGenerator && (
        <QrCodePrintDialog
          onClose={onQRDialogClose}
          item={{ title: editItem.shoppingCenterName, id: editItem.shoppingCenterId, type: 'shoppingcenter' }}
        />
      )}
    </div>
  );
};

export default ShoppingCenterGridContainer;
