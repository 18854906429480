/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../app/StoreHooks';
import { AvailableFirmsType, FirmAvailabilityResponseType, FirmResponseType, FirmType, GET_ALL_TRACE_FIRMS, GET_AVAILABLE_FIRMS, GET_FIRMS, GetFirmInputType, SAVE_FIRM, SaveFirmInputType, UPDATE_FIRM } from './data/FirmSliceData';
import { createAsyncThunkGeneric, createAsyncThunkGenericVoid } from './GenericThunks';

type FirmsState = {
  firms: FirmType[];
  activeHoldingFirms: AvailableFirmsType[];
  allAvailableFirms: AvailableFirmsType[];
  isLoading: boolean;
};

const initialState: FirmsState = {
  firms: [],
  activeHoldingFirms: [],
  allAvailableFirms: [],
  isLoading: false
};

// Async thunks
export const getFirmInformation = createAsyncThunkGenericVoid(
  'firms/getFirmInformation',
  async ({ client }) => {
    const response = await client.query<void, FirmResponseType>(
      'firms',
      GET_FIRMS
    );
    return response?.items ? response?.items : [];
  }
);

export const getFirmNameAndID = createAsyncThunkGenericVoid(
  'firms/getFirmNameAndID',
  async ({ client }) => {
    const response = await client.query<void, FirmAvailabilityResponseType>(
      'firms',
      GET_AVAILABLE_FIRMS
    );
    return response?.items ? response?.items : [];
  },
);

export const getActiveFirmNameAndID = createAsyncThunkGenericVoid(
  'firms/getActiveFirmNameAndID',
  async ({ client }) => {
    const response = await client.query<GetFirmInputType, FirmAvailabilityResponseType>(
      'firms',
      GET_AVAILABLE_FIRMS,
      {
        active: true
      }
    );
    return response?.items ? response?.items : [];
  }
);

export const getExternalFirms = createAsyncThunkGenericVoid(
  'firms/getExternalFirms',
  async ({ client }) => {
    const response = await client.query<void, FirmAvailabilityResponseType>(
      'allFirmsInTrace',
      GET_ALL_TRACE_FIRMS
    );
    return response?.items ? response?.items : [];
  }
);

export const saveFirmInformation = createAsyncThunkGeneric<FirmType, FirmType>('firms/saveFirmInformation', async ({ client, input }) => {
  input.active = true;
  return client.mutate<SaveFirmInputType, FirmType>(
    'addFirm',
    SAVE_FIRM,
    { firm: input }
  );
});

export const updateInformation = createAsyncThunkGeneric<
  FirmType,
  FirmType
>(
  'firms/updateInformation',
  async ({ client, input }) =>
    client.mutate<SaveFirmInputType, FirmType>(
      'updateFirm',
      UPDATE_FIRM,
      { firm: input }
    )
);

// Slice
export const FirmsSlice = createSlice({
  name: 'firms',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFirmInformation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFirmInformation.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.firms = payload;
      })
      .addCase(saveFirmInformation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveFirmInformation.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.firms.push(payload);
      })
      .addCase(updateInformation.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateInformation.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const updated = state.firms.findIndex(
          (firm) =>
            firm.firmId === payload.firmId
        );
        state.firms[updated] = payload;
      })
      .addCase(getFirmNameAndID.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFirmNameAndID.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.activeHoldingFirms = payload;
      })
      .addCase(getActiveFirmNameAndID.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getActiveFirmNameAndID.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.activeHoldingFirms = payload;
      })
      .addCase(getExternalFirms.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getExternalFirms.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.allAvailableFirms = payload;
      });
  }
});

// Export actions

// Export selectors
export const selectFirms = (state: RootState) =>
  state.firms.firms;
export const selectLoadingState = (state: RootState) =>
  state.firms.isLoading;
export const selectAvailableFirms = (state: RootState) =>
  state.firms.activeHoldingFirms;
export const selectAllTraceFirms = (state: RootState) =>
  state.firms.allAvailableFirms;

// Export reducer
export default FirmsSlice.reducer;
