import React, { useState, useEffect, useRef } from 'react';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import {
  selectAllContragents,
  getAllContragents,
  getAvailableABLFirms,
  editContragent,
  saveContragentForm,
  selectContragentAvailableFirms
} from '../../slices/ContragentsSlice';
import { ContragentType } from '../../slices/data/ContragentsSliceData';
import EditContragentForm from '../../components/EditContragentForm/EditContragentForm';
import AddContragentForm from '../../components/AddContragentForm/AddContragentForm';
import ContragentsGrid from '../../components/ContragentsGrid/ContragentsGrid';
import GrantAccess from '../GrantAccess/GrantAccess';

const REQUIRED_ROLES: string[] = [
  'Admin',
  'Holding Owner',
  'Farm Manager',
  'Farm Veterinary Inspector'
];
const CU_ROLES: string[] = ['Admin', 'Holding Owner', 'Farm Manager'];

const AdressBookContragentsContainer = () => {
  // Export functions & state
  const exlExp = useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (exlExp.current !== null) {
      exlExp.current.save();
    }
  };

  const dispatch = useAppDispatch();
  const availableFirms = useAppSelector(selectContragentAvailableFirms);

  useEffect(() => {
    const awaitData = () => {
      dispatch(getAllContragents());
      dispatch(getAvailableABLFirms());
    };
    awaitData();
  }, []);

  const contragents = useAppSelector(selectAllContragents);

  // Edit form state and functions
  const [openForm, setOpenForm] = useState(false);
  const [editItem, setEditItem] = useState<ContragentType>(null);
  const enterEdit = (item: ContragentType) => {
    setOpenForm(true);
    setEditItem(item);
  };

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const handleEdit = (formData: ContragentType) => {
    dispatch(editContragent(formData));
    setOpenForm(false);
  };

  // Add New Contragent state and functions
  const [openAddForm, setOpenAddForm] = useState(false);

  const submitNewFirm = (formData: ContragentType) => {
    dispatch(saveContragentForm(formData));
    setOpenAddForm(false);
  };

  const handleCancelAdd = () => {
    setOpenAddForm(false);
  };

  const addNew = () => {
    setOpenAddForm(true);
  };

  return (
    <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
      <ContragentsGrid
        addContragent={addNew}
        exportExl={excelExport}
        data={contragents}
        enterEdit={enterEdit}
      />
      <GrantAccess haveRoles='some' requiredRoles={CU_ROLES}>
        {openForm && (
        <EditContragentForm
          cancelEdit={handleCancelEdit}
          onSubmit={handleEdit}
          firms={availableFirms}
          item={editItem}
        />
        )}
        {openAddForm && (
        <AddContragentForm
          cancelAdd={handleCancelAdd}
          firms={availableFirms}
          onSubmit={submitNewFirm}
        />
        )}
      </GrantAccess>
    </GrantAccess>
  );
};

export default AdressBookContragentsContainer;
