import React from 'react';
import { Rating } from '@progress/kendo-react-inputs';

interface RatingContainerProps {
  rating: number
}
const RatingContainer = ({ rating }: RatingContainerProps) =>
  (
    <div>
      <Rating defaultValue={rating} readonly />
    </div>
  );

export default RatingContainer;
