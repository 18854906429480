import React from 'react';
import { useLocalization } from '@progress/kendo-react-intl';
import { Form, FormElement, Field } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import {
  FormDateTimePicker,
  FormInput,
  FormNumericTextBox
} from '../../components/FormComponents/FormComponents';
import SellAnimalsGridContainer from '../../components/SellAnimalsGridContainer/SellAnimalsGridContainer';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import { selectSalelData, sendSaleData } from '../../slices/SellOperationSlice';
import {
  SELL_FIRM_NAME,
  SELL_FIRM_EIK,
  SELL_FIRM_MOL,
  BUY_FIRM_EIK,
  BUY_FIRM_MOL,
  BUY_FIRM_NAME,
  SALE_INFO,
  PRICE_PER_KILO,
  PRICE_PER_ANIMAL,
  VEHICLE_WEIGHT,
  EMPTY_VEHICLE_WEIGHT,
  ANIMAL_WEIGHT,
  DEAL_PRICING,
  TRANSPORTATION_SERIAL_NUMBER,
  TRANSPORTATION_VEHICLE_REG,
  TRANSPORTATION_DRIVER,
  TRANSPORTATION_DATE,
  FIRM_MOL,
  FIRM_NAME,
  TRANSPORTATION_INFO,
  DEAL_DATE,
  SEND,
  CANCEL,
  EDIT,
  TRANSPORTATION_DRIVER_PIN
} from '../../languages/languages';
import { WeightedAnimalsType } from '../../slices/data/SellOperationSliceData';
import { selectSelectedAnimals } from '../../slices/AnimalSlice';
import { selectAllTrucks } from '../../slices/TrucksSlice';
import { selectAllContragents } from '../../slices/ContragentsSlice';

type SellPreviewProps = {
  close: () => void;
};

const SellPreview = ({ close }:SellPreviewProps) => {
  const localizationService = useLocalization();

  const dispatch = useAppDispatch();

  const saleData = useAppSelector(selectSalelData);
  const selectedAnimals = useAppSelector(selectSelectedAnimals);
  const trucks = useAppSelector(selectAllTrucks);
  const drivers = useAppSelector(selectAllContragents);

  const selectedTruck = () =>
    (
      trucks.find((truck) =>
        truck.truckId === saleData?.truckId?.truckId)
    );

  const selectedDriver = () =>
    (
      drivers.find((driver) =>
        driver.contragentId === saleData?.contragentId?.contragentId)
    );

  const handleSubmit = () => {
    const weightedAnimals: WeightedAnimalsType[] = selectedAnimals.map(
      (animal) =>
        ({
          animalID: animal.animalId,
          weight: animal.animalWeight,
          dateWeight: saleData.dealDate,
          weightType: 'EXITSALEWEIGHT'
        })
    );
    dispatch(
      sendSaleData({
        sellFirmID: saleData.sellFirmID,
        buyFirmID: saleData.buyFirmID,
        dealDate: saleData.dealDate,
        transportDate: saleData.transportDate,
        waybillNumber: saleData.waybillNumber,
        pricePerKilo: saleData.pricePerKilo,
        pricePerHead: saleData.pricePerHead,
        vehicleTaraWeight: saleData.vehicleTaraWeight,
        vehicleTotalWeight: saleData.vehicleTotalWeight,
        averageAnimalWeight: saleData.averageAnimalWeight,
        animalWeights: weightedAnimals,
        truckId: saleData.truckId.truckId,
        contragentId: saleData.contragentId.contragentId
      })
    );
    close();
  };

  return (
    <div className='sell-details-form'>
      <Form
        onSubmit={handleSubmit}
        initialValues={saleData}
        render={() =>
          (
            <FormElement>
              {/* Information about the deal participants and contractors */}
              <legend className='subtitle-1'>
                {localizationService.toLanguageString(SALE_INFO, '')}
              </legend>
              <fieldset className='k-form-fieldset'>
                <Field
                  id='sellFirmName'
                  name='sellFirmName'
                  label={localizationService.toLanguageString(SELL_FIRM_NAME, '')}
                  component={FormInput}
                  disabled
                />
                <Field
                  id='sellFirmEIK'
                  name='sellFirmEIK'
                  label={localizationService.toLanguageString(SELL_FIRM_EIK, '')}
                  component={FormInput}
                  disabled
                />
                <Field
                  id='sellFirmMOL'
                  name='sellFirmMOL'
                  label={localizationService.toLanguageString(SELL_FIRM_MOL, '')}
                  component={FormInput}
                  disabled
                />
                <Field
                  id='buyFirmName'
                  name='buyFirmName'
                  label={localizationService.toLanguageString(BUY_FIRM_NAME, '')}
                  component={FormInput}
                  disabled
                />
              </fieldset>
              <fieldset className='k-form-fieldset'>
                <Field
                  id='buyFirmEIK'
                  name='buyFirmEIK'
                  label={localizationService.toLanguageString(BUY_FIRM_EIK, '')}
                  component={FormInput}
                  disabled
                />
                <Field
                  id='buyFirmMOL'
                  name='buyFirmMOL'
                  label={localizationService.toLanguageString(BUY_FIRM_MOL, '')}
                  component={FormInput}
                  disabled
                />
                <Field
                  key='dealDate'
                  id='dealDate'
                  name='dealDate'
                  label={localizationService.toLanguageString(DEAL_DATE, '')}
                  format='dd-MM-yyyy HH:mm'
                  optional
                  component={FormDateTimePicker}
                  disabled
                />
              </fieldset>

              {/* Transportation details */}
              <legend className='subtitle-1'>
                {localizationService.toLanguageString(TRANSPORTATION_INFO, '')}
              </legend>
              <fieldset className='k-form-fieldset'>
                <Field
                  key='transportFirmName'
                  id='transportFirmName'
                  name='transportFirmName.firmABName'
                  label={localizationService.toLanguageString(FIRM_NAME, '')}
                  component={FormInput}
                  disabled
                />
                <Field
                  key='transportFirmName'
                  id='transportFirmName'
                  name='transportFirmName.firmABMOL'
                  label={localizationService.toLanguageString(FIRM_MOL, '')}
                  component={FormInput}
                  disabled
                />
                <Field
                  key='transportDate'
                  id='transportDate'
                  name='transportDate'
                  label={localizationService.toLanguageString(
                    TRANSPORTATION_DATE,
                    ''
                  )}
                  format='dd-MMM-yyyy HH:mm'
                  component={FormDateTimePicker}
                  disabled
                />
                <Field
                  key='contragentId'
                  id='contragentId'
                  name='contragentId.contragentName'
                  defaultValue={selectedDriver}
                  label={localizationService.toLanguageString(
                    TRANSPORTATION_DRIVER,
                    ''
                  )}
                  component={FormInput}
                  disabled
                />
              </fieldset>
              <fieldset className='k-form-fieldset'>
                <Field
                  key='contragentId'
                  id='contragentId'
                  name='contragentId.contragentPIN'
                  label={localizationService.toLanguageString(
                    TRANSPORTATION_DRIVER_PIN,
                    ''
                  )}
                  component={FormInput}
                  disabled
                />
                <Field
                  key='truckId'
                  id='truckId'
                  name='truckId.truckPlate'
                  defaultValue={selectedTruck}
                  label={localizationService.toLanguageString(
                    TRANSPORTATION_VEHICLE_REG,
                    ''
                  )}
                  component={FormInput}
                  disabled
                />
                <Field
                  key='waybillNumber'
                  id='waybillNumber'
                  name='waybillNumber'
                  label={localizationService.toLanguageString(
                    TRANSPORTATION_SERIAL_NUMBER,
                    ''
                  )}
                  component={FormInput}
                  disabled
                />
              </fieldset>

              {/* Animal weighting fields */}
              <legend className='subtitle-1'>
                {localizationService.toLanguageString(ANIMAL_WEIGHT, '')}
              </legend>
              <fieldset className='k-form-fieldset'>
                <Field
                  key='vehicleTaraWeight'
                  id='vehicleTaraWeight'
                  name='vehicleTaraWeight'
                  label={localizationService.toLanguageString(
                    EMPTY_VEHICLE_WEIGHT,
                    ''
                  )}
                  component={FormNumericTextBox}
                  disabled
                />
                <Field
                  key='vehicleTotalWeight'
                  id='vehicleTotalWeight'
                  name='vehicleTotalWeight'
                  label={localizationService.toLanguageString(VEHICLE_WEIGHT, '')}
                  component={FormNumericTextBox}
                  disabled
                />
              </fieldset>

              {/* Information about the deal prices */}
              <legend className='subtitle-1'>
                {localizationService.toLanguageString(DEAL_PRICING, '')}
              </legend>
              <fieldset className='k-form-fieldset'>
                <Field
                  key='pricePerHead'
                  id='pricePerHead'
                  name='pricePerHead'
                  label={localizationService.toLanguageString(
                    PRICE_PER_ANIMAL,
                    ''
                  )}
                  component={FormNumericTextBox}
                  disabled
                />
                <Field
                  key='pricePerKilo'
                  id='pricePerKilo'
                  name='pricePerKilo'
                  label={localizationService.toLanguageString(PRICE_PER_KILO, '')}
                  placeholder='лв./кг.'
                  component={FormNumericTextBox}
                  disabled
                />
              </fieldset>
            </FormElement>
          )}
      />
      <SellAnimalsGridContainer />
      <div className='k-columnmenu-actions'>
        <Button
          name='SellPreviewSend'
          type='button'
          title={localizationService.toLanguageString(SEND, '')}
          primary
          icon='save'
          onClick={handleSubmit}
        >
          {localizationService.toLanguageString(SEND, '')}
        </Button>
        <Button
          name='SellPreviewEdit'
          type='button'
          title={localizationService.toLanguageString(EDIT, '')}
          primary
          look='outline'
          icon='edit'
        >
          {localizationService.toLanguageString(EDIT, '')}
        </Button>
        <Button
          name='SellPreviewCancel'
          type='button'
          title={localizationService.toLanguageString(CANCEL, '')}
          primary
          look='outline'
          icon='cancel'
          onClick={close}
        >
          {localizationService.toLanguageString(CANCEL, '')}
        </Button>
      </div>
    </div>
  );
};

export default SellPreview;
