import React from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import {
  Form,
  Field,
  FormElement,
  FormRenderProps
} from '@progress/kendo-react-form';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { useLocalization } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';
import { AnimalObjectType } from '../../slices/data/AnimalObjectSliceData';
import {
  FormInput,
  FormNumericTextBox,
  FormComboBox,
  FormRadioGroup,
  FormSwitch
} from '../FormComponents/FormComponents';
import { StringValidator, NumberValidator } from '../Validators/Validators';
import { AvailableFirmsType } from '../../slices/data/FirmSliceData';
import {
  ADDRESS_PLACEHOLDER,
  AMOUNT_PLACEHOLDER,
  STATUS,
  AO_ADDRESS,
  AO_BACREAGE,
  AO_BRANCH,
  AO_BUILDINGS,
  AO_CAPACITY,
  AO_CATTLE,
  AO_CITY,
  AO_NAME,
  AO_NUMBER,
  AO_OWNER,
  AO_PACREAGE,
  AO_PASTURES,
  AO_SHEEP,
  AO_TRACES,
  CITY_PLACEHODLDER,
  DECLINE,
  OWNER_PLACEHOLDER,
  UPDATE
} from '../../languages/languages';
import { HoldingProfile } from '../../slices/data/HoldingSliceData';

type EditFormProps = {
  item: AnimalObjectType;
  cancelEdit: () => void;
  onSubmit: (formData: AnimalObjectType) => void;
  holding: HoldingProfile;
  availableFirms: AvailableFirmsType[];
};

const AnimalObjectEditForm = ({
  item,
  cancelEdit,
  onSubmit,
  holding,
  availableFirms
}: EditFormProps) => {
  const localizationService = useLocalization();

  const data: { label: string; value: string }[] = [
    {
      label: localizationService.toLanguageString(AO_CATTLE, ''),
      value: 'CATTLE'
    },
    {
      label: localizationService.toLanguageString(AO_SHEEP, ''),
      value: 'SHEEP'
    }
  ];

  const selectedFirm = availableFirms.find(
    (firm) =>
      firm.firmId === item.firmId
  );

  return (
    <Dialog title={item.animalObjectName} onClose={cancelEdit}>
      <Form
        onSubmit={onSubmit}
        initialValues={item}
        render={(formRenderProps: FormRenderProps) =>
          (
            <FormElement id='animaObjectDialogGrid' className='two-column-grid'>
              <fieldset className='k-form-fieldset'>
                <div className='k-form-field switch-form-field'>
                  <Field
                    name='active'
                    component={FormSwitch}
                    label={localizationService.toLanguageString(STATUS, '')}
                    value={item.active}
                  />
                </div>
                <Field
                  name='animalObjectName'
                  component={FormInput}
                  label={localizationService.toLanguageString(AO_NAME, '')}
                  placeholder={localizationService.toLanguageString(AO_NAME, '')}
                  validator={StringValidator}
                />

                <Field
                  name='animalObjectNumber'
                  component={FormInput}
                  label={localizationService.toLanguageString(AO_NUMBER, '')}
                  placeholder={localizationService.toLanguageString(
                    AO_NUMBER,
                    ''
                  )}
                  validator={StringValidator}
                />

                <Field
                  name='animalObjectNumberTraces'
                  component={FormInput}
                  label={localizationService.toLanguageString(AO_TRACES, '')}
                  placeholder={localizationService.toLanguageString(
                    AO_TRACES,
                    ''
                  )}
                  validator={StringValidator}
                />

                <Field
                  name='animalObjectCity'
                  component={FormInput}
                  label={localizationService.toLanguageString(AO_CITY, '')}
                  placeholder={localizationService.toLanguageString(
                    CITY_PLACEHODLDER,
                    ''
                  )}
                  validator={StringValidator}
                />

                <Field
                  name='animalObjectAddress'
                  component={FormInput}
                  label={localizationService.toLanguageString(AO_ADDRESS, '')}
                  placeholder={localizationService.toLanguageString(
                    ADDRESS_PLACEHOLDER,
                    ''
                  )}
                  validator={StringValidator}
                />
                <Field
                  name='animalObjectCapacity'
                  component={FormNumericTextBox}
                  label={localizationService.toLanguageString(AO_CAPACITY, '')}
                  format='n0'
                  placeholder={localizationService.toLanguageString(
                    AMOUNT_PLACEHOLDER,
                    ''
                  )}
                  validator={NumberValidator}
                />
              </fieldset>
              <fieldset className='k-form-fieldset'>
                <Field
                  name='animalObjectBuildings'
                  component={FormNumericTextBox}
                  label={localizationService.toLanguageString(AO_BUILDINGS, '')}
                  placeholder={localizationService.toLanguageString(
                    AMOUNT_PLACEHOLDER,
                    ''
                  )}
                  validator={NumberValidator}
                />

                <Field
                  name='animalObjectBuildingsAcreage'
                  component={FormNumericTextBox}
                  label={localizationService.toLanguageString(AO_BACREAGE, '')}
                  placeholder='m2'
                  validator={StringValidator}
                />

                <Field
                  name='animalObjectPastures'
                  component={FormNumericTextBox}
                  label={localizationService.toLanguageString(AO_PASTURES, '')}
                  placeholder={localizationService.toLanguageString(
                    AMOUNT_PLACEHOLDER,
                    ''
                  )}
                  validator={StringValidator}
                />

                <Field
                  name='animalObjectPasturesAcreage'
                  component={FormNumericTextBox}
                  label={localizationService.toLanguageString(AO_PACREAGE, '')}
                  placeholder='ha'
                  validator={StringValidator}
                />

                <Field
                  onChange={({ value }: ComboBoxChangeEvent) => {
                    if (value === null) {
                      return;
                    }
                    formRenderProps.onChange('firmId', {
                      value: value.firmId
                    });
                  }}
                  placeholder={localizationService.toLanguageString(AO_OWNER, '')}
                  id='firmName'
                  name='firmId'
                  label={localizationService.toLanguageString(
                    OWNER_PLACEHOLDER,
                    ''
                  )}
                  component={FormComboBox}
                  textField='firmName'
                  defaultValue={selectedFirm}
                  data={availableFirms}
                  validator={StringValidator}
                />
                {!holding?.cattle && !holding?.sheep && (
                <Field
                  id='animalObjectBranch'
                  name='animalObjectBranch'
                  label={localizationService.toLanguageString(AO_BRANCH, '')}
                  layout='horizontal'
                  component={FormRadioGroup}
                  data={data}
                  className='pt-m'
                />
                )}
              </fieldset>
              <fieldset className='k-form-buttons'>
                <Button
                  name='AnimalObjectAdd'
                  type='submit'
                  primary
                  disabled={!formRenderProps.allowSubmit}
                  icon='save'
                >
                  {localizationService.toLanguageString(UPDATE, '')}
                </Button>
                <Button
                  name='AnimalObjectCancel'
                  type='submit'
                  primary
                  look='outline'
                  onClick={cancelEdit}
                  icon='cancel'
                >
                  {localizationService.toLanguageString(DECLINE, '')}
                </Button>
              </fieldset>
            </FormElement>
          )}
      />
    </Dialog>
  );
};

export default AnimalObjectEditForm;
