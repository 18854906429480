import React from 'react';
import { State, process } from '@progress/kendo-data-query';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridToolbar
} from '@progress/kendo-react-grid';
import { useLocalization } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import {
  DEAL_CREATOR,
  DEAL_DATE,
  DEAL_NUMBER,
  DEAL_STATUS,
  EXPORT
} from '../../languages/languages';
import GridSearchBar from '../../features/GridSearchBar/GridSearchBar';
import ColumnMenu from '../ColumnMenu/ColumnMenu';
import {
  CustomDealStatusCell,
  DateCell
} from '../CustomGridCells/CustomGridCells';
import { DealHistoryType } from '../../slices/data/DealsSliceData';
import { useAppSelector } from '../../app/StoreHooks';
import { selectDealsLoadingState } from '../../slices/DealsSlice';
import GridLoaderPanel from '../GridLoaderPanel/GridLoaderPanel';
import GrantAccess from '../../features/GrantAccess/GrantAccess';

const REQUIRED_ROLES: string[] = ['Admin', 'Farm Manager'];

type DealsHistoryGridProps = {
  data: DealHistoryType[];
  dataState: State;
  setDataState: React.Dispatch<React.SetStateAction<State>>;
  dataStateChange: (e: GridDataStateChangeEvent) => void;
  excelExport: () => void;
  showDeal: (id: number) => void
};
const DealsHistoryGrid = ({
  data,
  dataState,
  setDataState,
  dataStateChange,
  excelExport,
  showDeal
}: DealsHistoryGridProps) => {
  const localizationService = useLocalization();
  const loadingState = useAppSelector(selectDealsLoadingState);
  const exclExport = React.useRef<ExcelExport | null>(null);

  const DealInformationCell = ({ dataItem }: GridCellProps) =>
    (
      <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
        <td className='text-center'>
          <Button
            data-testid='editButton'
            type='submit'
            className='k-button k-secondary'
            icon='info'
            look='outline'
            onClick={() =>
              showDeal(dataItem.saleEventID)}
          />
        </td>
      </GrantAccess>
    );

  return (
    <ExcelExport data={data} ref={exclExport}>
      <Grid
        {...dataState}
        data={process(data, dataState)}
        pageable
        sortable
        onDataStateChange={dataStateChange}
      >
        <GridToolbar>
          <GridSearchBar
            dataState={dataState}
            setDataState={setDataState}
            filterBy='dealBatchNumber'
          />
          <Button
            title='Export Excel'
            primary
            look='outline'
            icon='download'
            onClick={excelExport}
          >
            {localizationService.toLanguageString(EXPORT, '')}
          </Button>
        </GridToolbar>
        <Column
          field='saleEventStatus'
          title={localizationService.toLanguageString(DEAL_STATUS, '')}
          cell={CustomDealStatusCell}
          columnMenu={ColumnMenu}
          width={250}
        />
        <Column
          field='saleEventID'
          title={localizationService.toLanguageString(DEAL_NUMBER, '')}
          filter='text'
          columnMenu={ColumnMenu}
        />
        <Column
          field='sellFirm.firmName'
          title={localizationService.toLanguageString(DEAL_CREATOR, '')}
          filter='text'
          columnMenu={ColumnMenu}
        />
        <Column
          field='dealDate'
          title={localizationService.toLanguageString(DEAL_DATE, '')}
          cell={DateCell}
          filter='date'
          format='{0: dd-MM-yyyy}'
          columnMenu={ColumnMenu}
        />
        <Column cell={DealInformationCell} width={120} />
      </Grid>
      {loadingState && <GridLoaderPanel />}
    </ExcelExport>
  );
};

export default DealsHistoryGrid;
