import { Dialog } from '@progress/kendo-react-dialogs';
import React, { useEffect, useState } from 'react';
import { useLocalization } from '@progress/kendo-react-intl';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import ChemicalTreatmentLogForm from '../../components/ChemicalTreatmentLogForm/ChemicalTreatmentLogForm';
import ChemicalTreatmentLogGrid from '../../components/ChemicalTreatmentLogGrid/ChemicalTreatmentLogGrid';
import {
  editChemicalTreatmentLogEntry,
  getAllChemicalTreatmentLogEntries,
  saveChemicalTreatmentLogEntry,
  selectAllChemicalTreatmentsLogEntries
} from '../../slices/ChemicalTreatmentLogSlice';
import { ADD_NEW, EDIT } from '../../languages/languages';
import { ChemicalTreatmentLogInputType } from '../../slices/data/ChemicalTreatmentLogSliceData';

const ChemicalTreatmentLogContainer = () => {
  const dispatch = useAppDispatch();
  const chemicalTreatements = useAppSelector(
    selectAllChemicalTreatmentsLogEntries
  );
  const localizationService = useLocalization();

  useEffect(() => {
    const loadData = () => {
      dispatch(getAllChemicalTreatmentLogEntries());
    };
    loadData();
  }, []);

  const [addNewView, setAddNewView] = useState<boolean>(false);

  const openAddNewView = () => {
    setAddNewView(true);
  };

  const closeAddNewView = () => {
    setAddNewView(false);
  };

  const submitNewLogEntry = (formData: ChemicalTreatmentLogInputType) => {
    dispatch(saveChemicalTreatmentLogEntry(formData));
    closeAddNewView();
  };

  // Edit form state and functions
  const [editEntryView, setEditEntryView] = useState(false);
  const [editItem, setEditItem] = useState<ChemicalTreatmentLogInputType>(null);
  const enterEdit = (item: ChemicalTreatmentLogInputType) => {
    setEditEntryView(true);
    setEditItem(item);
  };

  const closeEditEntryView = () => {
    setEditEntryView(false);
  };

  const submitEditEntry = (formData: ChemicalTreatmentLogInputType) => {
    dispatch(editChemicalTreatmentLogEntry(formData));
    closeEditEntryView();
  };

  return (
    <>
      <ChemicalTreatmentLogGrid
        data={chemicalTreatements}
        addNew={openAddNewView}
        enterEdit={enterEdit}
      />
      {addNewView && (
        <Dialog title={localizationService.toLanguageString(ADD_NEW, '')} onClose={closeAddNewView}>
          <ChemicalTreatmentLogForm
            close={closeAddNewView}
            submit={submitNewLogEntry}
          />
        </Dialog>
      )}
      {editEntryView && (
        <Dialog title={localizationService.toLanguageString(EDIT, '')} onClose={closeAddNewView}>
          <ChemicalTreatmentLogForm
            item={editItem}
            close={closeEditEntryView}
            submit={submitEditEntry}
          />
        </Dialog>
      )}
    </>
  );
};

export default ChemicalTreatmentLogContainer;
