import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import React, { useEffect, useRef, useState } from 'react';

import EnterpriseWarehouseGrid from '../../components/EnterpriseWarehouse/EnterpriseWarehouseGrid';
import ProcessorToTraderDealModal from '../../components/ProcessorToTraderDeal/ProcessorToTraderDeal';
import dropdownService, { IDropdownModel } from '../../services/dropdown.service';

const EnterpriseWarehouseContainer = () => {
  const [enterprises, setEnterprises] = useState<IDropdownModel[]>([]);
  const [enterprise, setEnterprise] = useState<IDropdownModel>();
  const [selectedAnimals, setSelectedAnimals] = useState([]);
  const [showSaleModal, setShowSaleModal] = useState(false);
  const gridRef = useRef(null);

  useEffect(() => {
    dropdownService
      .getEnterprices()
      .then((items) => {
        items.forEach((item: IDropdownModel) => {
          item.text = `${item.text}(${item.info})`;
        });
        setEnterprises(items);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    setSelectedAnimals([]);
  }, [enterprise]);

  const onSelect = (data) => {
    setSelectedAnimals(data);
  };

  const closeSaleModal = () => {
    setShowSaleModal(false);
  };

  const onSaleCreated = () => {
    // this is needed to put the function in the next callstack
    // so the reload method can be call
    setTimeout(() => {
      if (gridRef.current) {
        gridRef.current.reload();
      }
    }, 0);

    closeSaleModal();
  };

  const gridActions = () => {
    console.log('grid actions');

    return (
      <>
        {selectedAnimals.length > 0 && (
          <Button
            iconClass='k-icon fas fa-hand-holding-usd'
            primary
            look='outline'
            onClick={() => setShowSaleModal(true)}
          >
            Продажба
          </Button>
        )}
      </>
    );
  };

  return (
    <div>
      <h1 className='page-title'>Склад</h1>
      <div className='row'>
        <div className='col-12'>
          <div>Кланица</div>
          <DropDownList
            data={enterprises}
            key='id'
            textField='text'
            onChange={(e) => setEnterprise(e.value)}
          />
        </div>
      </div>
      <hr />
      {!enterprise && (
        <div className='text-error'>
          <i className='fa fa-exclamation-circle' /> &nbsp; Изберете кланица
        </div>
      )}
      {enterprise && (
        <EnterpriseWarehouseGrid
          ref={(el) => {
            gridRef.current = el;
          }}
          enterpriseId={enterprise.id}
          onSelect={onSelect}
          actions={gridActions}
        />
      )}
      {showSaleModal && (
        <ProcessorToTraderDealModal
          warehouseItems={selectedAnimals}
          onCreated={onSaleCreated}
          onCancel={closeSaleModal}
        />
      )}
    </div>
  );
};

export default EnterpriseWarehouseContainer;
