import React from 'react';
import {
  TileLayout,
  TileLayoutRepositionEvent
} from '@progress/kendo-react-layout';
import { useLocalization } from '@progress/kendo-react-intl';
import CircularGaugeContainer from '../CircularGaugeContainer/CircularGaugeContainer';
import { useLocalStorage } from '../../utils/custom-hooks';
import SaleTradeChart from '../../features/SaleTradeChart/SaleTradeChart';
import ProfileRating from '../../features/ProfileRating/ProfileRating';
import ProgressBarContainer from '../ProgressBarContainer/ProgressBarContainer';
import HistoryChart from '../../features/HistoryChart/HistoryChart';
import {
  DASHBOARD_TITLE,
  PLACEHOLDER_TITLE,
  SALE_TRADE_CHART_TITLE,
  PROFILE_STATUS_TITLE,
  PROFILE_COMPLETION_TITLE,
  PROFILE_RATING_TITLE,
  SEUROP_TITLE,
  HISTORY_CHART_TITLE,
  ANIMAL_ACCEPTANCE_TITLE,
  STATUS_CONTENT,
  WIDGET_DESCRIPTION_PLACEHOLDER
} from '../../languages/languages';
import AnimalAcceptance from '../../features/AnimalAcceptance/AnimalAcceptance';
import GrantAccess from '../../features/GrantAccess/GrantAccess';

const REQUIRED_ROLES: string[] = [
  'Admin',
  'Holding Owner',
  'Farm Manager',
  'Farm Veterinary Inspector',
  'Abattoir Manager',
  'Abattoir Tech',
  'Abattoir Veterinary Inspector',
  'Veterinary Inspector'
];

const Dashboard = () => {
  const defaultLayout = [
    { col: 1, colSpan: 2, rowSpan: 1 },
    { col: 3, colSpan: 2, rowSpan: 1 },
    { col: 5, colSpan: 2, rowSpan: 1 },
    { col: 5, colSpan: 2, rowSpan: 2 },
    { col: 1, colSpan: 4, rowSpan: 2 },
    { col: 1, colSpan: 2, rowSpan: 1 },
    { col: 3, colSpan: 2, rowSpan: 1 },
    { col: 5, colSpan: 2, rowSpan: 1 }
  ];
  const localizationService = useLocalization();
  const [data, setData] = useLocalStorage('layout', defaultLayout);

  const tiles = [
    {
      body: (
        <div className='card-container'>
          <h5 className='k-card-title'>
            {localizationService.toLanguageString(PROFILE_STATUS_TITLE, '')}
          </h5>
          <p>{localizationService.toLanguageString(STATUS_CONTENT, '')}</p>
        </div>
      ),
      className: 'status-card no-header align-center'
    },
    {
      body: (
        <div className='card-container'>
          <h5 className='k-card-title'>
            {localizationService.toLanguageString(PROFILE_RATING_TITLE, '')}
          </h5>
          <ProfileRating />
        </div>
      ),
      className: 'rating-card no-header align-center'
    },

    {
      header: localizationService.toLanguageString(
        PROFILE_COMPLETION_TITLE,
        ''
      ),
      body: <CircularGaugeContainer value={15} color='#ee3641' />,
      className: 'two-column-card'
    },
    {
      header: localizationService.toLanguageString(SALE_TRADE_CHART_TITLE, ''),
      body: <SaleTradeChart />
    },
    {
      header: localizationService.toLanguageString(HISTORY_CHART_TITLE, ''),
      body: <HistoryChart />
    },
    {
      header: localizationService.toLanguageString(SEUROP_TITLE, ''),
      body: <CircularGaugeContainer value={67} color='#37B400' />,
      className: 'two-column-card pie-card green'
    },
    {
      body: (
        <div className='card-container d-flex align-items-center justify-content-center h-100'>
          <span className='icon-wrap mr-xl'>
            <i className='fas fa-info' />
          </span>
          <div className='content'>
            <h5 className='k-card-title small'>
              {localizationService.toLanguageString(
                ANIMAL_ACCEPTANCE_TITLE,
                ''
              )}
            </h5>
            <p className='mb-l'>
              {localizationService.toLanguageString(
                WIDGET_DESCRIPTION_PLACEHOLDER,
                ''
              )}
            </p>
            <AnimalAcceptance />
          </div>
        </div>
      ),
      className: 'no-header progressbar-card yellow'
    },
    {
      body: (
        <div className='card-container d-flex align-items-center justify-content-center h-100'>
          <span className='icon-wrap mr-xl'>
            <i className='fas fa-info' />
          </span>
          <div className='content'>
            <h5 className='k-card-title small'>
              {localizationService.toLanguageString(PLACEHOLDER_TITLE, '')}
            </h5>
            <p className='mb-l'>
              {localizationService.toLanguageString(
                WIDGET_DESCRIPTION_PLACEHOLDER,
                ''
              )}
            </p>
            <ProgressBarContainer value={98} />
          </div>
        </div>
      ),
      className: 'no-header progressbar-card blue'
    }
  ];

  const handleReposition = (e: TileLayoutRepositionEvent) => {
    setData(e.value);
  };

  return (
    <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
      <h1 className='page-title'>
        {localizationService.toLanguageString(DASHBOARD_TITLE, '')}
      </h1>
      <TileLayout
        columns={6}
        rowHeight={255}
        positions={data}
        gap={{ rows: 10, columns: 10 }}
        items={tiles}
        onReposition={handleReposition}
      />
    </GrantAccess>
  );
};

export default Dashboard;
