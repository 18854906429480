import { Button } from '@progress/kendo-react-buttons';
import { useInternationalization, useLocalization } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';

import { CONFIRM, FORWARD } from '../../languages/languages';
import { IBatch, IBatchAnimal } from '../../slices/data/ButcheryProcessSliceData';
import BatchAnimalsWeightGrid from '../AnimalGrid/BatchAnimalsWeightGrid';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';

export interface IBatchTabProps {
  batch: IBatch;
  onNext: () => void;
}

const CarcasesWeightsTab = ({ batch, onNext }: IBatchTabProps) => {
  const $t = useLocalization();
  const $i = useInternationalization();
  const [selectedAnimals, setSelectedAnimals] = useState<IBatchAnimal[]>([]);

  useEffect(() => {
    const { animals } = batch;
    let carcaseNumber = batch.enterpriseCarcaseNextNumber;
    animals.forEach((a, i) => {
      a.carcaseInternalNumber = i + 1;
      a.carcaseNumberTrace = `${batch.enterpriseNumber}-${carcaseNumber++}`;
    });

    setSelectedAnimals(batch.animals);
  }, [batch]);

  const setAnimals = (items: IBatchAnimal[]) => {
    setSelectedAnimals(items);
  };

  const saveWeights = () => {
    batch.animals = selectedAnimals;
  };

  const [showSaveMessage, setShowSaveMessage] = useState(false);

  const onSave = (result: boolean) => {
    if (result) {
      saveWeights();
      onNext();
    }
    setShowSaveMessage(false);
  };

  const invalidLength = () => selectedAnimals.some((a) => a.cuts.length === 0);
  const invalidWeights = () =>
    selectedAnimals.some((a) => a.cuts.some((c) => !c.weight || c.weight === 0));

  const disableContinue = () => invalidLength() || invalidWeights();

  return (
    <div className='pl-l pr-l'>
      <div className='row mb-l'>
        <div className='col-12'>
          <h2>Разпад и трупни тегла</h2>
          <div>
            Партида:
            <h3>
              {batch.number}| {$i.formatDate(batch.date, 'dd.MM.yyyy')} |
            </h3>
          </div>
        </div>
        <hr />
      </div>
      <div className='row'>
        <div className='col-12'>
          {selectedAnimals.length && (
            <BatchAnimalsWeightGrid animals={selectedAnimals} onChange={setAnimals} />
          )}
        </div>
      </div>
      {disableContinue() && (
        <div className='text-center text-error w-100 pt-l'>
          <i className='fas fa-exclamation-circle' /> &nbsp; Всички животни трябва да имат въведени
          трупно тегло и разпад!
        </div>
      )}
      <div className='col-12 d-flex justify-content-center mt-l'>
        <Button disabled={disableContinue()} primary onClick={() => setShowSaveMessage(true)}>
          {$t.toLanguageString(FORWARD, '')}
        </Button>
      </div>
      {showSaveMessage && (
        <ConfirmDialog
          message='Желаете ли да продължите?'
          title={$t.toLanguageString(CONFIRM, '')}
          onResult={onSave}
        />
      )}
    </div>
  );
};

export default CarcasesWeightsTab;
