import React, { useState } from 'react';
import {
  Grid,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridToolbar,
  GridCellProps,
  GridRowProps,
  GridItemChangeEvent
} from '@progress/kendo-react-grid';
import { State, process } from '@progress/kendo-data-query';
import {
  ButcheredAnimalsType
} from '../../slices/data/AnimalSliceData';
import GridSearchBar from '../../features/GridSearchBar/GridSearchBar';
import { CellRender, RowRender } from '../SellAnimalsGridContainer/GridRenders';
import ColumnMenu from '../ColumnMenu/ColumnMenu';

type KillDataGridProps = {
  animals: ButcheredAnimalsType[];
  setAnimalData: (animals: any[]) => void;
};

const KillDataGrid = ({
  animals,
  setAnimalData
}: KillDataGridProps) => {
  // Grid States
  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0
  });

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  };

  // In-Cell editing functions & cells
  const [editField, setEditField] = useState<string>(undefined);

  const enterEdit = (dataItem: ButcheredAnimalsType, field: string) => {
    const newData = animals.map((item) =>
      ({
        ...item,
        inEdit: item.animalId === dataItem.animalId ? field : undefined
      }));

    setAnimalData(newData);
    setEditField(field);
  };

  const exitEdit = () => {
    const newData = animals.map((item) =>
      ({
        ...item,
        inEdit: undefined
      }));

    setAnimalData(newData);
    setEditField(undefined);
  };

  const itemChange = ({ field, value, dataItem }: GridItemChangeEvent) => {
    const selectedField = field || '';
    const newData = animals.map((item) => {
      if (item.animalId === dataItem.animalId) {
        return {
          ...item,
          [selectedField]:
            selectedField === 'carcassWeight' ? Number(value) : value
        };
      }
      return item;
    });
    setAnimalData(newData);
  };

  const customCellRender: any = (
    td: React.ReactElement<HTMLTableCellElement>,
    props: GridCellProps
  ) =>
    (
      <CellRender
        defaultValue=''
        originalProps={props}
        td={td}
        enterEdit={enterEdit}
        editField={editField}
      />
    );

  const customRowRender: any = (
    tr: React.ReactElement<HTMLTableRowElement>,
    props: GridRowProps
  ) =>
    (
      <RowRender
        originalProps={props}
        tr={tr}
        exitEdit={exitEdit}
        editField={editField}
      />
    );

  return (
    <Grid
      style={{ paddingTop: '50px' }}
      sortable
      pageable
      {...dataState}
      data={process(animals, dataState)}
      onDataStateChange={dataStateChange}
      onItemChange={itemChange}
      cellRender={customCellRender}
      rowRender={customRowRender}
      editField='inEdit'
    >
      <GridToolbar>
        <GridSearchBar
          dataState={dataState}
          setDataState={setDataState}
          filterBy='lID'
        />
      </GridToolbar>
      <Column
        editable={false}
        key='lID'
        field='lID'
        title='Информация за животното'
        filter='text'
        columnMenu={(props) =>
          <ColumnMenu {...props} />}
      />
      <Column
        editable={false}
        key='animalWeight'
        field='animalWeight'
        title='Килограми'
        filter='numeric'
        columnMenu={(props) =>
          <ColumnMenu {...props} />}
      />
      <Column
        key='carcassWeight'
        field='carcassWeight'
        title='Трупно тегло'
        filter='numeric'
        columnMenu={(props) =>
          <ColumnMenu {...props} />}
      />
      <Column key='carcassNote' field='carcassNote' title='Бележка' />
    </Grid>
  );
};

export default KillDataGrid;
