import React, { FC } from 'react';

import { useAppSelector } from '../../app/StoreHooks';
import { selectUser } from '../../slices/UserSlice';

type GrantAccessProps = {
  requiredRoles: string[];
  haveRoles: 'every' | 'some';
};

const GrantAccess: FC<GrantAccessProps> = ({
  requiredRoles,
  children,
  haveRoles
}) => {
  const { roles } = useAppSelector(selectUser);
  const isAdmin = roles.includes('Admin');
  const permissionGranted = haveRoles === 'some'
    ? requiredRoles.some((perm) =>
      roles.includes(perm))
    : requiredRoles.every((perm) =>
      roles.includes(perm));

  if (!permissionGranted && !isAdmin) {
    return <></>;
  }
  return <>{children}</>;
};

export default GrantAccess;
