import { REQUIRED_FIELD } from '../languages/languages';
import translations from '../translation-main';

export const RequiredValidator = (value: any) => {
  if (value === null || value === undefined) {
    return translations.bg_BG[REQUIRED_FIELD];
  }

  return '';
};

export default {
  RequiredValidator
};