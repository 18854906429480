import React from 'react';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { useLocalization } from '@progress/kendo-react-intl';
import {
  FormInput,
  FormComboBox,
  FormMaskedTextBox
} from '../FormComponents/FormComponents';
import {
  StringValidator,
  emailValidator,
  NumberValidator
} from '../Validators/Validators';
import { ContragentsFirms, ContragentType } from '../../slices/data/ContragentsSliceData';
import {
  ADD,
  ADDRESS_BOOK_EMAIL,
  ADDRESS_BOOK_FIRM,
  ADDRESS_BOOK_NAME,
  ADDRESS_BOOK_PHONE,
  CANCEL,
  TRANSPORTATION_DRIVER_PIN
} from '../../languages/languages';

type AddAddressBookFirmProps = {
  cancelAdd: () => void;
  onSubmit: (formData: ContragentType) => void;
  firms: ContragentsFirms[];
};

const AddContragentForm = ({
  cancelAdd,
  onSubmit,
  firms
}: AddAddressBookFirmProps) => {
  const localizationService = useLocalization();

  return (
    <Dialog title='Добави нов контрагент' onClose={cancelAdd}>
      <Form
        onSubmit={onSubmit}
        render={(formRenderProps) =>
          (
            <FormElement>
              <fieldset className='k-form-fieldset'>
                <Field
                  name='contragentName'
                  component={FormInput}
                  label={localizationService.toLanguageString(
                    ADDRESS_BOOK_NAME,
                    ''
                  )}
                  placeholder='Име и фамилия'
                  validator={StringValidator}
                />
                <Field
                  name='contragentPhone'
                  component={FormMaskedTextBox}
                  label={localizationService.toLanguageString(
                    ADDRESS_BOOK_PHONE,
                    ''
                  )}
                  placeholder='+(359) 899 123 456'
                  mask='+359 000 000 000'
                  validator={NumberValidator}
                />
                <Field
                  name='contragentEmail'
                  component={FormInput}
                  label={localizationService.toLanguageString(
                    ADDRESS_BOOK_EMAIL,
                    ''
                  )}
                  placeholder='xxx@xx.xx'
                  validator={emailValidator}
                />
                <Field
                  name='contragentPIN'
                  component={FormInput}
                  label={localizationService.toLanguageString(
                    TRANSPORTATION_DRIVER_PIN,
                    ''
                  )}
                  placeholder='ЕГН'
                  validator={StringValidator}
                />
                <Field
                  onChange={({ value }: ComboBoxChangeEvent) => {
                    if (value === null) {
                      return;
                    }
                    formRenderProps.onChange('firmABId', {
                      value: value.firmABId
                    });
                  }}
                  placeholder='Изберете фирма...'
                  id='firmABName'
                  name='firmABId'
                  label={localizationService.toLanguageString(
                    ADDRESS_BOOK_FIRM,
                    ''
                  )}
                  component={FormComboBox}
                  textField='firmABName'
                  data={firms}
                  validator={StringValidator}
                />
              </fieldset>
              <div className='k-form-buttons'>
                <Button
                  name='Add new'
                  type='submit'
                  title='Добави'
                  primary
                  disabled={!formRenderProps.allowSubmit}
                  icon='save'
                >
                  {localizationService.toLanguageString(ADD, '')}
                </Button>
                <Button
                  name='Cancel'
                  type='submit'
                  title='Откажи'
                  primary
                  look='outline'
                  icon='cancel'
                  onClick={cancelAdd}
                >
                  {localizationService.toLanguageString(CANCEL, '')}
                </Button>
              </div>
            </FormElement>
          )}
      />
    </Dialog>
  );
};

export default AddContragentForm;
