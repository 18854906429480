import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Loader } from '@progress/kendo-react-indicators';
import { useLocalization } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import { BUY_FIRM_EIK, BUY_FIRM_MOL, BUY_FIRM_NAME, BUYING_FIRM, CANCEL, DEAL_DATE, PRICE_PER_KILO, SAVE, SELL_FIRM_EIK, SELL_FIRM_MOL, SELL_FIRM_NAME, SELLING_FIRM } from '../../languages/languages';
import ProcessorToTraderSalesService from '../../services/processorToTraderSales.service';
import { ColumnType } from '../../slices/data/AnimalColumnsSliceData';
import { getExternalFirms, getFirmNameAndID, selectAllTraceFirms, selectAvailableFirms } from '../../slices/FirmSlice';
import { FormComboBox, FormDateTimePicker, FormInput, FormNumericTextBox } from '../FormComponents/FormComponents';
import NonSelectableGrid from '../SelectableGrid/NonSelectableGrid';
import { requiredValidator } from '../Validators/Validators';

const columns = [
  {
    title: 'Партида №',
    field: 'batchNumber',
    show: true,
    filter: 'text',
  },
  {
    title: 'Партида дата',
    field: 'batchDate',
    show: true,
    filter: 'date',
    format: '{0:dd.MM.yyy}',
  },
  {
    title: 'Животно №',
    field: 'animalLId',
    show: true,
    filter: 'text',
  },
  // {
  //   title: 'Възраст',
  //   field: 'animalAge',
  //   show: true,
  //   filter: 'numeric',
  // },
  // {
  //   title: 'Пол',
  //   field: 'animalSex',
  //   show: true,
  //   filter: 'text',
  // },
  {
    title: 'SEUROP',
    field: 'sEUROP',
    show: true,
    filter: 'text',
  },
  {
    title: 'USDA',
    field: 'uSDA',
    show: true,
    filter: 'text',
  },
  {
    title: 'Вид на среза',
    field: 'cuttingType',
    show: true,
    filter: 'text',
  },
  {
    title: 'Сечение',
    field: 'cutName',
    show: true,
    filter: 'text',
  },
  {
    title: 'Тегло',
    field: 'weight',
    show: true,
    filter: 'text',
    format: '{0} кг.',
  }
] as ColumnType[];

const ProcessorToTraderDealModal = ({
  warehouseItems,
  onCreated,
  onCancel,
}: {
  warehouseItems: any[];
  onCreated: () => void;
  onCancel: () => void;
}) => {
  const localizationService = useLocalization();
  const dispatch = useAppDispatch();
  const [dealData, setDealData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    dispatch(getFirmNameAndID());
    dispatch(getExternalFirms());
  }, []);

  // Redux block
  // Selectors
  const holdingFirms = useAppSelector(selectAvailableFirms);
  const allTraceFirms = useAppSelector(selectAllTraceFirms);

  // On change, updates the input fields
  // in the form for the Sell Firm Name, EIK and MOL
  const handleSellFirmChange = (e: ComboBoxChangeEvent, formProps: FormRenderProps) => {
    const { value } = e;
    if (value === null) {
      return;
    }

    // Update Sell Firm Name, EIK, MOL and ID
    // using the built-in FormRenderProps
    formProps.onChange('sellFirmName', {
      value: value.firmName,
    });
    formProps.onChange('sellFirmEIK', {
      value: value.firmEIK,
    });
    formProps.onChange('sellFirmMOL', {
      value: value.firmMOL,
    });
    formProps.onChange('sellFirmId', {
      value: value.firmId,
    });
  };

  // On change, updates the input fields
  // in the form for the Buy Firm Name, EIK and MOL
  const handleBuyFirmChange = (e: ComboBoxChangeEvent, formProps: FormRenderProps) => {
    const { value } = e;
    if (value === null) {
      return;
    }

    // Update Buy Firm Name, EIK, MOL and ID
    // using the built-in FormRenderProps
    formProps.onChange('buyFirmName', {
      value: value.firmName,
    });
    formProps.onChange('buyFirmEIK', {
      value: value.firmEIK,
    });
    formProps.onChange('buyFirmMOL', {
      value: value.firmMOL,
    });
    formProps.onChange('buyFirmId', {
      value: value.firmId,
    });
  };

  // Handle all other Input changes, that will be required for the deal
  const handleInputOnChange = ({ target, value }) => {
    console.log('input change: ', target, value);
    setDealData({ ...dealData, [target.name]: value });
  };

  const onSubmit = (data) => {
    setLoading(true);
    console.log('Form data: ', data);
    const dealObject = {
      dealDate: data.values.dealDate,
      pricePerKilo: data.values.pricePerKilo,
      buyFirmId: data.values.buyFirmId,
      sellFirmId: data.values.sellFirmId,
      batchCutsIds: warehouseItems.map((item) => item.batchCutId),
    };

    console.log('Deals data: ', dealObject);
    ProcessorToTraderSalesService.createSale(dealObject)
      .then(onCreated)
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  return (
    <Dialog>
      {!loading && (
        <Form
          initialValues={dealData}
          onSubmitClick={onSubmit}
          render={(formRenderProps: FormRenderProps) => (
            <FormElement>
              <h3>Нова сделка</h3>
              <hr />
              <div className='sell-details-form'>
                {/* Selling Firm Information */}
                <fieldset className='k-form-fieldset'>
                  <legend className='subtitle-1 mb-s ml-xs'>
                    {localizationService.toLanguageString(SELLING_FIRM, '')}
                  </legend>
                  <Field
                    component={FormComboBox}
                    label={localizationService.toLanguageString(SELLING_FIRM, '')}
                    data={holdingFirms}
                    textField='firmName'
                    name='sellFirmID'
                    dataItemKey='firmId'
                    validator={requiredValidator}
                    onChange={(e) => handleSellFirmChange(e, formRenderProps)}
                  />
                  <Field
                    id='sellFirmName'
                    name='sellFirmName'
                    label={localizationService.toLanguageString(SELL_FIRM_NAME, '')}
                    component={FormInput}
                    disabled
                  />
                  <Field
                    id='sellFirmEIK'
                    name='sellFirmEIK'
                    label={localizationService.toLanguageString(SELL_FIRM_EIK, '')}
                    component={FormInput}
                    disabled
                  />
                  <Field
                    id='sellFirmMOL'
                    name='sellFirmMOL'
                    label={localizationService.toLanguageString(SELL_FIRM_MOL, '')}
                    component={FormInput}
                    disabled
                  />
                </fieldset>

                {/* Buying Firm Information */}
                <fieldset className='k-form-fieldset'>
                  <legend className='subtitle-1 mb-s ml-xs'>
                    {localizationService.toLanguageString(BUYING_FIRM, '')}
                  </legend>
                  <Field
                    component={FormComboBox}
                    label={localizationService.toLanguageString(BUYING_FIRM, '')}
                    data={allTraceFirms}
                    textField='firmName'
                    name='buyFirmID'
                    dataItemKey='firmId'
                    validator={requiredValidator}
                    onChange={(e) => handleBuyFirmChange(e, formRenderProps)}
                  />
                  <Field
                    id='buyFirmName'
                    name='buyFirmName'
                    label={localizationService.toLanguageString(BUY_FIRM_NAME, '')}
                    component={FormInput}
                    disabled
                  />
                  <Field
                    id='buyFirmEIK'
                    name='buyFirmEIK'
                    label={localizationService.toLanguageString(BUY_FIRM_EIK, '')}
                    component={FormInput}
                    disabled
                  />
                  <Field
                    id='buyFirmMOL'
                    name='buyFirmMOL'
                    label={localizationService.toLanguageString(BUY_FIRM_MOL, '')}
                    component={FormInput}
                    disabled
                  />
                </fieldset>
                <fieldset className='k-form-fieldset'>
                  <legend className='subtitle-1 mb-s ml-xs'>
                    Данни за сделката
                    {/* {localizationService.toLanguageString(DEAL_DATA, '')} */}
                  </legend>
                  <Field
                    key='dealDate'
                    id='dealDate'
                    name='dealDate'
                    label={localizationService.toLanguageString(DEAL_DATE, '')}
                    format='dd-MMM-yyyy HH:mm'
                    optional
                    component={FormDateTimePicker}
                    validator={requiredValidator}
                    onChange={handleInputOnChange}
                  />
                  <Field
                    id='pricePerKilo'
                    name='pricePerKilo'
                    label={localizationService.toLanguageString(PRICE_PER_KILO, '')}
                    component={FormNumericTextBox}
                    validator={requiredValidator}
                    onChange={handleInputOnChange}
                  />
                </fieldset>
                <fieldset className='k-form-fieldset'>
                  <legend className='subtitle-1 mb-s ml-xs'>
                    Артикули
                    {/* {localizationService.toLanguageString(DEAL_DATA, '')} */}
                  </legend>
                  <div>
                    <NonSelectableGrid data={warehouseItems} stateColumns={columns} />
                  </div>
                </fieldset>
              </div>
              <hr />
              <Button
                name='SubmitBtn'
                type='submit'
                title={localizationService.toLanguageString(SAVE, '')}
                primary
                disabled={!formRenderProps.valid}
              >
                {localizationService.toLanguageString(SAVE, '')}
              </Button>
              <Button
                name='cancelBtn'
                type='button'
                title={localizationService.toLanguageString(CANCEL, '')}
                className='ml-s'
                onClick={onCancel}
              >
                {localizationService.toLanguageString(CANCEL, '')}
              </Button>
            </FormElement>
          )}
        />
      )}
      {loading && <Loader size='large' type='pulsing' className='k-center' />}
    </Dialog>
  );
};

export default ProcessorToTraderDealModal;
