import React, { useState } from 'react';
import { TabStripSelectEventArguments, } from '@progress/kendo-react-layout';
import { useAppSelector } from '../../app/StoreHooks';
import { selectHoldingProfile } from '../../slices/HoldingSlice';
import HoldingStructureTable from '../../components/HoldingStructureTables/HoldingStructureTable';

const HoldingStructureContainer = () => {
  const [selected, setSelected] = useState(0);
  const handleSelect = (ev: TabStripSelectEventArguments) => {
    setSelected(ev.selected);
  };

  const holding = useAppSelector(selectHoldingProfile);

  return (
    <HoldingStructureTable data={holding} changeTab={handleSelect} selectedTab={selected} />
  );
};

export default HoldingStructureContainer;
