import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { useLocalization } from '@progress/kendo-react-intl';
import React from 'react';

import { DECLINE, UPDATE } from '../../languages/languages';
import { IBatchAnimal } from '../../slices/data/ButcheryProcessSliceData';
import { FormInput, FormNumericTextBox } from '../FormComponents/FormComponents';

type AnimaWeightEditModalProps = {
  item: IBatchAnimal;
  cancelEdit: () => void;
  onSubmit: (formData: IBatchAnimal) => void;
};
const BatchAnimalWeightsEditModal = ({ item, cancelEdit, onSubmit }: AnimaWeightEditModalProps) => {
  const localizationService = useLocalization();
  const title = `Редакция на "${item.lID}"`;

  return (
    <Dialog title={title} onClose={cancelEdit}>
      <Form
        onSubmit={onSubmit}
        initialValues={item}
        render={(formRenderProps) => (
          <FormElement id='registrationForm'>
            <div className='row'>
              <div className='col-12 pl-l pr-l'>
                <Field
                  id='carcaseInternalNumber'
                  name='carcaseInternalNumber'
                  component={FormInput}
                  label='Труп вътрешен №'
                  value={item.carcaseInternalNumber}
                />
              </div>
              <div className='col-12 pl-l pr-l'>
                <Field
                  id='carcaseWeight'
                  name='carcaseWeight'
                  component={FormNumericTextBox}
                  label='Трупно тегло топло'
                  value={item.carcaseWeight}
                />
              </div>
            </div>
            <fieldset className='k-form-buttons'>
              <Button
                name='FirmAdd'
                type='submit'
                title={localizationService.toLanguageString(UPDATE, '')}
                primary
                disabled={!formRenderProps.allowSubmit}
                icon='save'
              >
                {localizationService.toLanguageString(UPDATE, '')}
              </Button>
              <Button
                name='FirmCancel'
                type='submit'
                title={localizationService.toLanguageString(DECLINE, '')}
                primary
                look='outline'
                onClick={cancelEdit}
                icon='cancel'
              >
                {localizationService.toLanguageString(DECLINE, '')}
              </Button>
            </fieldset>
          </FormElement>
        )}
      />
    </Dialog>
  );
};

export default BatchAnimalWeightsEditModal;
