import React, { useEffect, useState } from 'react';
import { Loader } from '@progress/kendo-react-indicators';
import { GetRatingQuery, GetRatingType } from './data/RatingData';
import RatingContainer from '../../components/RatingContainer/RatingContainer';
import { useGraphQlClient } from '../../utils/custom-hooks';
import { GraphQLClient } from '../../app/Client';

const ProfileRating = () => {
  const client: GraphQLClient = useGraphQlClient();
  const [rating, setRating] = useState<number>(0);

  useEffect(() => {
    const loadRating = async () => {
      const getRating = await client.query<void, GetRatingType>(
        'getRating',
        GetRatingQuery
      );
      setRating(getRating?.rating);
    };
    loadRating();
  }, []);

  return rating ? (
    <RatingContainer rating={rating} />
  ) : (
    <Loader size='large' type='pulsing' className='k-centered' />
  );
};

export default ProfileRating;
