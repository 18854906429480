const account = [
  {
    text: 'Профил на холдинга',
    icon: 'user'
  },
  {
    text: 'Настройки',
    icon: 'cog'
  },
  {
    text: 'Изход',
    icon: 'logout'
  }
];

export default account;
