import React, { useEffect, useState } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';
import { useLocalization } from '@progress/kendo-react-intl';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import {
  selectOriginalColumns,
  setCattleColumns,
  setOriginalColumns,
  setSheepColumns
} from '../../slices/AnimalColumnsSlice';
import { ColumnType } from '../../slices/data/AnimalColumnsSliceData';
import { UPDATE, CANCEL } from '../../languages/languages';

type ColumnPickerMenuProps = {
  type: string;
  columnSet: ColumnType[];
  setVisibility: any;
};

const ColumnPickerMenu = ({
  type,
  columnSet,
  setVisibility
}: ColumnPickerMenuProps) => {
  const localizationService = useLocalization();

  const [columns, setColumns] = useState<ColumnType[]>(columnSet);

  const dispatch = useAppDispatch();
  const originalColumns = useAppSelector(selectOriginalColumns);

  useEffect(() => {
    const setData = () => {
      dispatch(setOriginalColumns(columnSet));
    };
    setData();
  }, []);

  const onToggleColumn = (id: number) => {
    const newColumns = columns.map((column, idx) =>
      (idx === id ? { ...column, show: !column.show } : column));
    setColumns(newColumns);
  };

  const onClose = () => {
    setVisibility(false);
  };

  const onReset = () => {
    if (type === 'cattle') {
      dispatch(setCattleColumns(originalColumns));
    } else {
      dispatch(setSheepColumns(originalColumns));
    }
    setVisibility(false);
  };

  const onSubmit = () => {
    if (type === 'cattle') {
      dispatch(setCattleColumns(columns));
    } else {
      dispatch(setSheepColumns(columns));
    }
    setVisibility(false);
  };

  const oneVisibleColumn = columns.filter((c) =>
    c.show).length === 1;

  return (
    <Dialog title='Добавяне / премахване на колони' onClose={onClose}>
      <div className='k-column-list-wrapper'>
        <form onSubmit={onSubmit} onReset={onReset}>
          <div className='k-column-list column-content'>
            {columns.map((column, idx) =>
              (
                <div key={column.field} className='k-column-list-item'>
                  <span>
                    <input
                      id={`column-visiblity-show-${idx}`}
                      className='k-checkbox'
                      type='checkbox'
                      readOnly
                      disabled={column.show && oneVisibleColumn}
                      checked={column.show}
                      onClick={() => {
                        onToggleColumn(idx);
                      }}
                    />
                    <label
                      htmlFor={`column-visiblity-show-${idx}`}
                      className='k-checkbox-label'
                      style={{ userSelect: 'none' }}
                    >
                      {column.title}
                    </label>
                  </span>
                </div>
              ))}
          </div>
          <div className='k-columnmenu-actions'>
            <Button
              name='ColumnPickerAdd'
              type='button'
              title={localizationService.toLanguageString(UPDATE, '')}
              primary
              icon='save'
              onClick={onSubmit}
              className='flex grow-0'
            >
              {localizationService.toLanguageString(UPDATE, '')}
            </Button>
            <Button
              name='ColumnPickerCancel'
              type='button'
              title={localizationService.toLanguageString(CANCEL, '')}
              primary
              look='outline'
              icon='cancel'
              onClick={onReset}
              className='flex grow-0'
            >
              {localizationService.toLanguageString(CANCEL, '')}
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
  );
};

export default ColumnPickerMenu;
