import { gql } from '@apollo/client';
import { AnimalType } from './AnimalSliceData';
import { ContragentType } from './ContragentsSliceData';
import { AvailableFirmsType } from './FirmSliceData';
import { TruckAndFirmType } from './TrucksSliceData';

export type SingleDealId = {
  id: number;
};

export type DealHistoryType = {
  saleEventID: number;
  dealDate: Date;
  dealCreator: string;
  saleEventStatus: string;
};

export type DealDataType = {
  saleEvent: {
    saleEventID: number;
    dealDate: Date;
    dealCreator: string;
    saleEventStatus: string;
  };
};

export type DealsHistoryResponseType = {
  items: DealDataType[];
};

export type ReceivedAnimalsType = {
  arrivalWeight?: number;
  comment: string;
  arrivalNote: string;
  weightType?: string;
} & AnimalType;

export type DealInformationType = {
  saleEventID: number;
  dealBatchNumber: number;
  dealDate: Date;
  saleEventStatus: string;
  sellFirm: AvailableFirmsType;
  buyFirm: AvailableFirmsType;
  transportDate: Date;
  truck: TruckAndFirmType;
  contragent: ContragentType;
  waybillNumber: string;
  vehicleTaraWeight: number;
  vehicleTotalWeight: number;
  averageAnimalWeight: number;
  pricePerKilo: number;
  pricePerHead: number;
  arrivalDate: Date;
  animalWeights: {
    weight: number;
    comment: string;
    animal: AnimalType;
  }[];
};

export type SingleDealType = {
  saleEventID: number;
  dealBatchNumber: number;
  dealDate: Date;
  saleEventStatus: string;
  sellFirm: AvailableFirmsType;
  buyFirm: AvailableFirmsType;
  transportDate: Date;
  truck: TruckAndFirmType;
  contragent: ContragentType;
  waybillNumber: string;
  vehicleTaraWeight: number;
  vehicleTotalWeight: number;
  averageAnimalWeight: number;
  pricePerKilo: number;
  pricePerHead: number;
  arrivalDate: Date;
  animalWeights: ReceivedAnimalsType[];
};

type AcceptedAnimalsType = {
  animalID: number;
  weight: number;
  comment: string;
  dateWeight: Date;
  weightType: string;
};

export type DealActionType = {
  saleEventID: number;
  saleEventStatus?: string;
  arrivalDate: Date;
  animalWeights: AcceptedAnimalsType[];
};

export type DealActionInputType = {
  saleInput: DealActionType;
};

export type RejectedOfferType = {
  saleEventID: number;
  saleEventStatus: string;
};

export type RejectedOfferInputType = {
  saleInput: RejectedOfferType;
};

// Queries & Mutations
export const GET_DEALS_HISTORY = gql`
  {
    holdingEvents {
      items {
        saleEvent {
          saleEventID
          sellFirm {
            firmName
          }
          dealDate
          saleEventStatus
        }
      }
    }
  }
`;

export const GET_DEAL_INFO = gql`
  query GetSaleEvent($id: ID!) {
    saleEvent(id: $id) {
      saleEventID
      transportDate
      saleEventStatus
      waybillNumber
      truck {
        truckId
        truckPlate
      }
      contragent {
        contragentName
        contragentPIN
        firm {
          firmABName
          firmABMOL
        }
      }
      vehicleTaraWeight
      vehicleTotalWeight
      pricePerHead
      pricePerKilo
      animalWeights {
        weight
        comment
        animal {
          animalId
          lID
          dateOfBirth
        }
      }
      sellFirm {
        firmName
        firmEIK
        firmMOL
      }
      buyFirm {
        firmName
        firmEIK
        firmMOL
      }
    }
  }
`;

export const UPDATE_SALE_EVENT = gql`
  mutation UpdateSaleEvent($saleInput: saleInput) {
    updateSaleEvent(saleInput: $saleInput) {
      saleEventID
      saleEventStatus
    }
  }
`;
