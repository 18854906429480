import React from 'react';
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartValueAxis,
  ChartValueAxisItem
} from '@progress/kendo-react-charts';

interface BarChartContainerProps {
  firstSeries: number[];
  secondSeries: number[];
  categories: string[];
}

const BarChartContainer = ({
  firstSeries,
  secondSeries,
  categories
}: BarChartContainerProps) =>
  (
    <Chart>
      <ChartCategoryAxis>
        <ChartCategoryAxisItem categories={categories} startAngle={45} />
      </ChartCategoryAxis>
      <ChartValueAxis>
        <ChartValueAxisItem majorUnit={50} max={500} labels={{ step: 2 }} />
      </ChartValueAxis>
      <ChartSeries>
        <ChartSeriesItem
          type='column'
          gap={2}
          spacing={0.25}
          data={firstSeries}
        />
        <ChartSeriesItem type='column' data={secondSeries} />
      </ChartSeries>
    </Chart>
  );

export default BarChartContainer;
