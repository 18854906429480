import React from 'react';

import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartLegend,
} from '@progress/kendo-react-charts';

import 'hammerjs';

const categories = ['U+', 'U=', 'U-', 'R+', 'R=', 'R-', 'O+', 'O=', 'O-', 'P+'];
const series = [
  {
    name: 'Крави',
    color: 'red',
    data: [150, 130, 200, 90, 160, 110, 180, 190, 250, 200],
  },
  {
    name: 'Млади',
    color: 'blue',
    data: [80, 110, 180, 200, 140, 160, 110, 200, 220, 240],
  },
];

const ChartContainer = () => (
  <Chart style={{ height: 350 }}>
    <ChartTitle text='Постигнат трупен клас и трупни тегла' />
    <ChartLegend position='top' orientation='horizontal' />
    <ChartCategoryAxis>
      <ChartCategoryAxisItem categories={categories} startAngle={45} />
    </ChartCategoryAxis>
    <ChartSeries>
      {series.map((item) => (
        <ChartSeriesItem
          key={item.name}
          type='line'
          tooltip={{ visible: true }}
          data={item.data}
          name={item.name}
          color={item.color}
        />
      ))}
    </ChartSeries>
  </Chart>
);

export default ChartContainer;
