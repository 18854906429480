import React, { useState, useEffect } from 'react';
import { Loader } from '@progress/kendo-react-indicators';
import ProgressBarContainer from '../../components/ProgressBarContainer/ProgressBarContainer';
import { useGraphQlClient } from '../../utils/custom-hooks';
import {
  GetAnimalAcceptanceQuery,
  GetAnimalAcceptanceType
} from './data/AnimalAcceptanceData';

const AnimalAcceptance = () => {
  const client = useGraphQlClient();
  const [acceptanceRate, setAcceptanceRate] = useState<number>(null);

  useEffect(() => {
    const loadRate = async () => {
      const getacptRate = await client.query<void, GetAnimalAcceptanceType>(
        'getAnimalAcceptance',
        GetAnimalAcceptanceQuery
      );
      setAcceptanceRate(getacptRate?.rating);
    };
    loadRate();
  }, []);

  // const completionPercent = `${acceptanceRate}%`;
  const completionPercent = 45;

  return acceptanceRate ? (
    <ProgressBarContainer value={Number(completionPercent)} />
  ) : (
    <Loader size='large' type='pulsing' className='k-centered' />
  );
};

export default AnimalAcceptance;
