import React, { useState, useEffect } from 'react';
import { Loader } from '@progress/kendo-react-indicators';
import { useGraphQlClient } from '../../utils/custom-hooks';
import {
  HistoryChartCategoriesQuery,
  HistoryChartCategoryType,
  HistoryChartDataQuery,
  HistoryChartDataType
} from './data/HistoryChartData';
import AreaChartContainer from '../../components/AreaChartContainer/AreaChartContainer';

const HistoryChart = () => {
  const client = useGraphQlClient();
  const [categories, setCategories] = React.useState<string[]>([]);
  const [data, setData] = useState<number[]>(null);

  useEffect(() => {
    const loadData = async () => {
      const categ = await client.query<void, HistoryChartCategoryType[]>(
        'historyCategories',
        HistoryChartCategoriesQuery
      );
      const historyData = await client.query<void, HistoryChartDataType[]>(
        'historyData',
        HistoryChartDataQuery
      );
      const newData = historyData || [];
      setCategories(categ?.map((it) =>
        it.value));
      setData(newData?.map((element) =>
        element.dataValue));
    };
    loadData();
  }, []);
  return data ? (
    <AreaChartContainer categories={categories} data={data} />
  ) : (
    <Loader size='large' type='pulsing' className='k-centered' />
  );
};

export default HistoryChart;
