import { graphql } from 'msw';
import AUTH_CONFIG from '../../auth-variables';

const blbTrace = graphql.link(AUTH_CONFIG.apiUrl);

type MatadorsType = {
  matadorID: number;
  matadorName: string;
};
const matadors: MatadorsType[] = [
  { matadorID: Math.floor(Math.random() * 100), matadorName: 'Matador name 1' },
  { matadorID: Math.floor(Math.random() * 100), matadorName: 'Matador name 2' },
  { matadorID: Math.floor(Math.random() * 100), matadorName: 'Matador name 3' },
  { matadorID: Math.floor(Math.random() * 100), matadorName: 'Matador name 4' },
  { matadorID: Math.floor(Math.random() * 100), matadorName: 'Matador name 5' },
  { matadorID: Math.floor(Math.random() * 100), matadorName: 'Matador name 6' },
  { matadorID: Math.floor(Math.random() * 100), matadorName: 'Matador name 12' },
  { matadorID: Math.floor(Math.random() * 100), matadorName: 'Matador name 13' },
  { matadorID: Math.floor(Math.random() * 100), matadorName: 'Matador name 41' },
  { matadorID: Math.floor(Math.random() * 100), matadorName: 'Matador name 15' },
];

export default [
  blbTrace.query('GetMatadors', (_, res, ctx) =>
    res(
      ctx.data({
        availableMatadors: {
          matadors
        }
      })
    ))
];
