import { Button } from '@progress/kendo-react-buttons';
import { DropDownListChangeEvent } from '@progress/kendo-react-dropdowns';
import { Field, Form, FormElement, FormRenderProps } from '@progress/kendo-react-form';
import { Loader } from '@progress/kendo-react-indicators';
import { useLocalization } from '@progress/kendo-react-intl';
import React, { useCallback, useEffect, useState } from 'react';

import { useAppDispatch } from '../../app/StoreHooks';
import GrantAccess from '../../features/GrantAccess/GrantAccess';
import { SAVE } from '../../languages/languages';
import { BOVINE_QUALITY_SYSTEM, CONFORMITY_ASSESSMENT, FAT_THICKNESS, PACKING_CODES, POST_SLAUGHTER_SYSTEM, REFRIGERATION, SLAUGHTER_SYSTEM, UNECE_TITLE_PROCESSOR, WEIGHT_RANGING } from '../../languages/keys/unece.keys';
import { INomenclatureData, INomenclatureResponceData, NomenclatureNames } from '../../slices/data/NomenclatureData';
import { IUneceEnterpriceSettings, IUneceEnterpriceSettingsValues } from '../../slices/data/UneceSliceData';
import { getNomenclatureValues } from '../../slices/NomenclatureSlice';
import { getEnterpriceSettings, updateEnterpriceSettings } from '../../slices/UneceSlice';
import { FormDropDownList } from '../FormComponents/FormComponents';
import UneceCode from './UneceCode';

const REQUIRED_ROLES: string[] = ['Admin', 'Holding Owner'];

const uneceFarmSettings = () => {
  const localizationService = useLocalization();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);

  const [bovineQualitySystems, setBovineQualitySystems] = useState([]);
  const [conformityAssessments, setConformityAssessments] = useState([]);
  const [fatThicknesses, setFatThicknesses] = useState([]);
  const [packingCodes, setPackingCodes] = useState([]);
  const [postSlaughterSystems, setPostSlaughterSystems] = useState([]);
  const [refrigerations, setRefrigerations] = useState([]);
  const [slaughterSystems, setSlaughterSystems] = useState([]);
  const [weightRangings, setWeightRangings] = useState([]);

  const [model, setModel] = useState({} as IUneceEnterpriceSettings);
  const [defaultValues, setDefaultValues] = useState({} as IUneceEnterpriceSettingsValues);

  const getDefaultValue = (arr: INomenclatureData[], val: any) => arr.find((x) => x.id === val);

  // declare the async data fetching function
  const fetchData = useCallback(async () => {
    const { payload } = await dispatch(getEnterpriceSettings());
    let modelData: IUneceEnterpriceSettings = null;
    if (payload) {
      modelData = { ...(payload as IUneceEnterpriceSettings) };
      setModel(modelData);
    }
    const promises = [
      dispatch(getNomenclatureValues(NomenclatureNames.bovineQualitySystem)),
      dispatch(getNomenclatureValues(NomenclatureNames.conformityAssessment)),
      dispatch(getNomenclatureValues(NomenclatureNames.fatThickness)),
      dispatch(getNomenclatureValues(NomenclatureNames.packingCodes)),
      dispatch(getNomenclatureValues(NomenclatureNames.postSlaughterSystem)),
      dispatch(getNomenclatureValues(NomenclatureNames.refrigeration)),
      dispatch(getNomenclatureValues(NomenclatureNames.slaughterSystem)),
      dispatch(getNomenclatureValues(NomenclatureNames.weightRanging)),
    ];

    Promise.all(promises).then((result) => {
      setBovineQualitySystems((result[0].payload as INomenclatureResponceData)?.values);
      setConformityAssessments((result[1].payload as INomenclatureResponceData)?.values);
      setFatThicknesses((result[2].payload as INomenclatureResponceData)?.values);
      setPackingCodes((result[3].payload as INomenclatureResponceData)?.values);
      setPostSlaughterSystems((result[4].payload as INomenclatureResponceData)?.values);
      setRefrigerations((result[5].payload as INomenclatureResponceData)?.values);
      setSlaughterSystems((result[6].payload as INomenclatureResponceData)?.values);
      setWeightRangings((result[7].payload as INomenclatureResponceData)?.values);

      if (modelData) {
        const defaults = {} as any;

        defaults.bovineQualitySystemId = getDefaultValue(
          (result[0].payload as INomenclatureResponceData).values,
          modelData.bovineQualitySystemId
        );

        defaults.conformityAssessmentId = getDefaultValue(
          (result[1].payload as INomenclatureResponceData).values,
          modelData.conformityAssessmentId
        );

        defaults.fatThicknessId = getDefaultValue(
          (result[2].payload as INomenclatureResponceData).values,
          modelData.fatThicknessId
        );

        defaults.packingCodesId = getDefaultValue(
          (result[3].payload as INomenclatureResponceData).values,
          modelData.packingCodesId
        );

        defaults.postSlaughterSystemId = getDefaultValue(
          (result[4].payload as INomenclatureResponceData).values,
          modelData.postSlaughterSystemId
        );

        defaults.refrigerationId = getDefaultValue(
          (result[5].payload as INomenclatureResponceData).values,
          modelData.refrigerationId
        );

        defaults.slaughterSystemId = getDefaultValue(
          (result[6].payload as INomenclatureResponceData).values,
          modelData.slaughterSystemId
        );

        defaults.weightRangingId = getDefaultValue(
          (result[7].payload as INomenclatureResponceData).values,
          modelData.weightRangingId
        );

        setDefaultValues(defaults);
      }

      setLoading(false);
    });
  }, []);

  // the useEffect is only there to call `fetchData` at the right time
  useEffect(() => {
    fetchData()
      // make sure to catch any error
      .catch(console.error);
  }, [fetchData]);

  const onSubmit = () => {
    setLoading(true);

    const data = {
      bovineQualitySystemId: model.bovineQualitySystemId,
      conformityAssessmentId: model.conformityAssessmentId,
      fatThicknessId: model.fatThicknessId,
      packingCodesId: model.packingCodesId,
      postSlaughterSystemId: model.postSlaughterSystemId,
      refrigerationId: model.refrigerationId,
      slaughterSystemId: model.slaughterSystemId,
      weightRangingId: model.weightRangingId,
    } as IUneceEnterpriceSettings;

    dispatch(updateEnterpriceSettings(data)).then(() => {
      setLoading(false);
    });
  };

  const onFieldChange = (e: DropDownListChangeEvent) => {
    setModel({
      ...model,
      [e.target.name]: e.target.value.id,
    });

    setDefaultValues({
      ...defaultValues,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
      <h1 className='page-title'>
        {localizationService.toLanguageString(UNECE_TITLE_PROCESSOR, '')}
      </h1>
      {!loading && (
        <div>
          <Form
            initialValues={defaultValues}
            onSubmitClick={onSubmit}
            render={(formRenderProps: FormRenderProps) => (
              <FormElement>
                <div className='mb-l'>
                  <div className='row mb-l'>
                    <div className='col-12 col-md-4 col-xl-3 pl-s pr-s'>
                      <Field
                        id='refrigerationId'
                        name='refrigerationId'
                        label={localizationService.toLanguageString(REFRIGERATION, '')}
                        component={FormDropDownList}
                        data={refrigerations}
                        textField='text'
                        dataItemKey='id'
                        onChange={onFieldChange}
                      />
                    </div>
                    <div className='col-12 col-md-4 col-xl-3 pl-s pr-s'>
                      <Field
                        id='slaughterSystemId'
                        name='slaughterSystemId'
                        label={localizationService.toLanguageString(SLAUGHTER_SYSTEM, '')}
                        component={FormDropDownList}
                        data={slaughterSystems}
                        textField='text'
                        dataItemKey='id'
                        onChange={onFieldChange}
                      />
                    </div>
                    <div className='col-12 col-md-4 col-xl-3 pl-s pr-s'>
                      <Field
                        id='postSlaughterSystemId'
                        name='postSlaughterSystemId'
                        label={localizationService.toLanguageString(POST_SLAUGHTER_SYSTEM, '')}
                        component={FormDropDownList}
                        data={postSlaughterSystems}
                        textField='text'
                        dataItemKey='id'
                        onChange={onFieldChange}
                      />
                    </div>
                    <div className='col-12 col-md-4 col-xl-3 pl-s pr-s'>
                      <Field
                        id='fatThicknessId'
                        name='fatThicknessId'
                        label={localizationService.toLanguageString(FAT_THICKNESS, '')}
                        component={FormDropDownList}
                        data={fatThicknesses}
                        textField='text'
                        dataItemKey='id'
                        onChange={onFieldChange}
                      />
                    </div>
                    <div className='col-12 col-md-4 col-xl-3 pl-s pr-s'>
                      <Field
                        id='bovineQualitySystemId'
                        name='bovineQualitySystemId'
                        label={localizationService.toLanguageString(BOVINE_QUALITY_SYSTEM, '')}
                        component={FormDropDownList}
                        data={bovineQualitySystems}
                        textField='text'
                        dataItemKey='id'
                        onChange={onFieldChange}
                      />
                    </div>
                    <div className='col-12 col-md-4 col-xl-3 pl-s pr-s'>
                      <Field
                        id='weightRangingId'
                        name='weightRangingId'
                        label={localizationService.toLanguageString(WEIGHT_RANGING, '')}
                        component={FormDropDownList}
                        data={weightRangings}
                        textField='text'
                        dataItemKey='id'
                        onChange={onFieldChange}
                      />
                    </div>
                    <div className='col-12 col-md-4 col-xl-3 pl-s pr-s'>
                      <Field
                        id='packingCodesId'
                        name='packingCodesId'
                        label={localizationService.toLanguageString(PACKING_CODES, '')}
                        component={FormDropDownList}
                        data={packingCodes}
                        textField='text'
                        dataItemKey='id'
                        onChange={onFieldChange}
                      />
                    </div>
                    <div className='col-12 col-md-4 col-xl-3 pl-s pr-s'>
                      <Field
                        id='conformityAssessmentId'
                        name='conformityAssessmentId'
                        label={localizationService.toLanguageString(CONFORMITY_ASSESSMENT, '')}
                        component={FormDropDownList}
                        data={conformityAssessments}
                        textField='text'
                        dataItemKey='id'
                        onChange={onFieldChange}
                      />
                    </div>
                  </div>
                  <div>
                    <UneceCode values={defaultValues} />
                  </div>
                </div>
                <hr />
                <div className='d-flex justify-content-center mb-l align-items-center'>
                  <Button
                    name='submit'
                    type='submit'
                    title={localizationService.toLanguageString(SAVE, '')}
                    primary
                    iconClass='fa fa-save'
                    disabled={!formRenderProps.allowSubmit}
                  >
                    {localizationService.toLanguageString(SAVE, '')}
                  </Button>
                </div>
              </FormElement>
            )}
          />
        </div>
      )}
      {loading && <Loader size='large' type='pulsing' className='k-centered' />}
    </GrantAccess>
  );
};

export default uneceFarmSettings;
