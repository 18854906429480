import { graphql } from 'msw';
import AUTH_CONFIG from '../../auth-variables';

const blbTrace = graphql.link(AUTH_CONFIG.apiUrl);

export default [
  // Handle information for the Dashboard tables
  blbTrace.query('SaleTradeCategoriesQuery', (req, res, ctx) =>
    res(
      ctx.delay(1500),
      // ctx.errors([
      //   {
      //     message: 'Error 1',
      //     locations: [
      //       {
      //         line: 8,
      //         column: 12,
      //       },
      //     ],
      //   },
      // ]),
      ctx.data({
        saleTrades: [
          {
            id: 123,
            value: '01/21'
          },
          {
            id: 345,
            value: '02/21'
          },
          {
            id: 346,
            value: '03/21'
          },
          {
            id: 347,
            value: '04/21'
          },
          {
            id: 349,
            value: '05/21'
          },
          {
            id: 359,
            value: '06/21'
          }
        ]
      })
    )),
  blbTrace.query('SaleTradesSeriesQuery', (req, res, ctx) =>
    res(
      ctx.delay(3000),
      ctx.data({
        series: [
          [
            { seriesId: 1, seriesValue: 100 },
            { seriesId: 2, seriesValue: 123 },
            { seriesId: 3, seriesValue: 234 },
            { seriesId: 4, seriesValue: 343 }
          ],
          [
            { seriesId: 5, seriesValue: 120 },
            { seriesId: 6, seriesValue: 67 },
            { seriesId: 7, seriesValue: 231 },
            { seriesId: 8, seriesValue: 196 }
          ]
        ]
      })
    // ctx.errors([
    //   {
    //     message: 'Error retrieving series data',
    //     locations: [
    //       {
    //         line: 8,
    //         column: 12,
    //       },
    //     ],
    //   },
    // ]),
    )),
  blbTrace.query('HistoryChartCategoriesQuery', (req, res, ctx) =>
    res(
      ctx.delay(3000),
      ctx.data({
        historyCategories: [
          { id: 987, value: '06/2020' },
          { id: 876, value: '07/2020' },
          { id: 786, value: '08/2020' },
          { id: 867, value: '09/2020' },
          { id: 765, value: '10/2020' },
          { id: 654, value: '11/2020' },
          { id: 543, value: '12/2020' },
          { id: 432, value: '01/2021' },
          { id: 321, value: '02/2021' }
        ]
      })
    )),
  blbTrace.query('HistoryChartDataQuery', (req, res, ctx) =>
    res(
      ctx.delay(1500),
      ctx.data({
        historyData: [
          { dataId: 1, dataValue: 123 },
          { dataId: 2, dataValue: 276 },
          { dataId: 3, dataValue: 310 },
          { dataId: 4, dataValue: 212 },
          { dataId: 5, dataValue: 240 },
          { dataId: 6, dataValue: 156 },
          { dataId: 7, dataValue: 98 }
        ]
      })
    )),
  blbTrace.query('GetRatingQuery', (req, res, ctx) =>
    res(
      ctx.delay(1000),
      ctx.data({
        getRating: { rating: 4 }
      })
    )),
  blbTrace.query('GetAnimalAcceptanceQuery', (req, res, ctx) =>
    res(
      ctx.delay(2500),
      ctx.data({
        getAnimalAcceptance: { rating: 25 }
      })
    ))
];
