import React from 'react';

import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartLegend,
} from '@progress/kendo-react-charts';

import 'hammerjs';

const currentYear = new Date().getFullYear();
const categories = [currentYear - 2, currentYear - 1, currentYear];
const series = [
  {
    name: 'Крави',
    color: 'red',
    data: [150, 130, 200],
  },
  {
    name: 'Млади',
    color: 'blue',
    data: [80, 110, 180],
  },
];

const ChartContainer = () => (
  <Chart style={{ height: 350 }}>
    <ChartTitle text='Класове животни и количества в брой' />
    <ChartLegend position='top' orientation='horizontal' />
    <ChartCategoryAxis>
      <ChartCategoryAxisItem categories={categories} startAngle={45} />
    </ChartCategoryAxis>
    <ChartSeries>
      {series.map((item) => (
        <ChartSeriesItem
          key={item.name}
          type='line'
          tooltip={{ visible: true }}
          data={item.data}
          name={item.name}
          color={item.color}
        />
      ))}
    </ChartSeries>
  </Chart>
);

export default ChartContainer;
