import React, { useState } from 'react';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridToolbar
} from '@progress/kendo-react-grid';
import { useLocalization } from '@progress/kendo-react-intl';
import { State, process } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import {
  ArtificialInsemenationType,
  fertilizationVariants,
  pregnancyChecks
} from '../../slices/data/ArtificialInseminationSliceData';
import GridSearchBar from '../../features/GridSearchBar/GridSearchBar';
import ColumnMenu from '../ColumnMenu/ColumnMenu';
import {
  ADD_NEW,
  COW_LID,
  EXPECTED_DUE_DATE,
  FERTILIZATION_END,
  FERTILIZATION_START,
  FERTILIZATION_TYPE,
  PREGNANCY_STAGE,
  PREGNANCY_TEST_DATE,
  SIRE_LID,
  TEST_RESULT,
  ARTIFICIAL_INSEMENATION
} from '../../languages/languages';
import { DateCell, EditCommandCell } from '../CustomGridCells/CustomGridCells';
import { selectArtificialInsementaionLoading } from '../../slices/ArtificialInseminationSlice';
import { useAppSelector } from '../../app/StoreHooks';
import GridLoaderPanel from '../GridLoaderPanel/GridLoaderPanel';
import GrantAccess from '../../features/GrantAccess/GrantAccess';

const REQUIRED_ROLES: string[] = ['Admin', 'Farm Manager', 'Farm Veterinary Inspector'];

type ArtificialInseminationGridProps = {
  data: ArtificialInsemenationType[];
  enterEdit: (item: ArtificialInsemenationType) => void;
  addNew: () => void;
};

const ArtificialInseminationGrid = ({
  data,
  enterEdit,
  addNew
}: ArtificialInseminationGridProps) => {
  const localizationService = useLocalization();
  const loadingState = useAppSelector(selectArtificialInsementaionLoading);

  const FertilizationVariantCell = ({ dataItem }: GridCellProps) => {
    const fertType = fertilizationVariants.find(
      (fert) =>
        fert.value === dataItem.fertilization
    );
    return <td>{localizationService.toLanguageString(fertType.label, '')}</td>;
  };

  const pregnancyResultCell = ({ dataItem }: GridCellProps) => {
    const pregnancyResult = pregnancyChecks.find(
      (pregn) =>
        pregn.value === dataItem.pregnancyCheck
    );
    return (
      <td>{localizationService.toLanguageString(pregnancyResult.label, '')}</td>
    );
  };

  const MyEditCommandCell = (props: GridCellProps) =>
    (
      <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
        <EditCommandCell {...props} enterEdit={enterEdit} />
      </GrantAccess>
    );

  const EntryNumberCell = ({ dataItem }: GridCellProps) =>
    (
      <td>{data.indexOf(dataItem) + 1}</td>
    );

  // Grid States
  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0
  });

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  };

  return (
    <>

      <h1 className='page-title'>{localizationService.toLanguageString(ARTIFICIAL_INSEMENATION, '')}</h1>

      <div className='content-wrapper section'>
        <Grid
          {...dataState}
          data={process(data, dataState)}
          pageable
          sortable
          onDataStateChange={dataStateChange}
        >
          <GridToolbar>
            <GridSearchBar
              dataState={dataState}
              setDataState={setDataState}
              filterBy='animal.lID'
            />
            <Button
              name='AddAILogEntry'
              type='button'
              title={localizationService.toLanguageString(ADD_NEW, '')}
              icon='plus'
              primary
              onClick={addNew}
            >
              {localizationService.toLanguageString(ADD_NEW, '')}
            </Button>
          </GridToolbar>
          <Column title='№' cell={EntryNumberCell} />
          <Column
            key='animal.lID'
            field='animal.lID'
            title={localizationService.toLanguageString(COW_LID, '')}
            filter='text'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='fertilization'
            field='fertilization'
            title={localizationService.toLanguageString(FERTILIZATION_TYPE, '')}
            filter='text'
            cell={FertilizationVariantCell}
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='sireLID'
            field='sireLID'
            title={localizationService.toLanguageString(SIRE_LID, '')}
            filter='text'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='fertilizationStart'
            field='fertilizationStart'
            cell={DateCell}
            title={localizationService.toLanguageString(FERTILIZATION_START, '')}
            filter='text'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='fertilizationEnd'
            field='fertilizationEnd'
            cell={DateCell}
            title={localizationService.toLanguageString(FERTILIZATION_END, '')}
            filter='text'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='pregnancyCheckDate'
            field='pregnancyCheckDate'
            title={localizationService.toLanguageString(PREGNANCY_TEST_DATE, '')}
            filter='text'
            cell={DateCell}
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='pregnancyCheck'
            field='pregnancyCheck'
            title={localizationService.toLanguageString(TEST_RESULT, '')}
            filter='text'
            cell={pregnancyResultCell}
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='pregnancyState'
            field='pregnancyState'
            title={localizationService.toLanguageString(PREGNANCY_STAGE, '')}
            filter='text'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='expectedBornDate'
            field='expectedBornDate'
            cell={DateCell}
            title={localizationService.toLanguageString(EXPECTED_DUE_DATE, '')}
            filter='text'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column cell={MyEditCommandCell} width={120} />
        </Grid>
        {loadingState && <GridLoaderPanel />}
      </div>
    </>
  );
};

export default ArtificialInseminationGrid;
