/* eslint-disable react/prop-types */
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridCellProps, GridColumn, GridSortChangeEvent } from '@progress/kendo-react-grid';
import { Loader } from '@progress/kendo-react-indicators';
import { useLocalization } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';

import { useAppDispatch } from '../../app/StoreHooks';
import { BACKWORD, CATTLE_TYPE, SAVE, SHEEP_TYPE } from '../../languages/languages';
import { AnimalSexEnum, AnimalTypeEnum } from '../../slices/data/AnimalSliceData';
import { IAnimalRegistrationGridData, IRegistrationData } from '../../slices/data/MovementSliceData';
import { createRegistrations } from '../../slices/MovementSlice';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import RegistrationEditForm from './RegistrationEditForm';

interface ComponentProps {
  onNext: (data: any) => void;
  onPrev: () => void;
  regData: IRegistrationData;
}

const RegistrationTableData = ({
  onNext,
  onPrev,
  regData
}: ComponentProps) => {
  const localizationService = useLocalization();
  const dispatch = useAppDispatch();
  const animals: IAnimalRegistrationGridData[] = [];
  const [items, setItems] = useState<IAnimalRegistrationGridData[]>([]);

  useEffect(() => {
    if (regData.animalsCount > 0) {
      const DOB = new Date();
      DOB.setMonth(DOB.getMonth() - (regData.age || 0));

      for (let i = 0; i < regData.animalsCount; i += 1) {
        const idNumber = regData.prefix ? `${(regData.prefix as any).prefix}${parseInt(regData.idNumber || '0', 10) + i}` : (parseInt(regData.idNumber || '0', 10) + i).toString();
        animals.push({
          animalId: i,
          acquisitionDate: regData.acquisitionDate,
          acquisitionType: regData.acquisitionType,
          animalObjectId: regData.animalObjectId,
          firmId: regData.firmId,
          lID: idNumber,
          damLID: '',
          type: localizationService.toLanguageString(regData.animalsType === AnimalTypeEnum.CATTLE ? CATTLE_TYPE : SHEEP_TYPE, ''),
          sex: regData.gender as AnimalSexEnum,
          breed: regData.animalsType === AnimalTypeEnum.CATTLE
            ? regData.cattleBreed : regData.sheepBreed,
          color: regData.color,
          animalType: regData.animalsType as AnimalTypeEnum,
          active: true,
          cattleBreed: regData.cattleBreed,
          dateOfBirth: DOB,
          eID: '',
          comment: '',
          passportNumber: '',
          sheepBreed: regData.sheepBreed,
          modified: false,
          isCastrated: false
        });
      }
    }
    setItems(animals);
  }, []);

  // Edit form state and functions
  const [openForm, setOpenForm] = React.useState(false);
  const [editItem, setEditItem] = React.useState<IAnimalRegistrationGridData>(null);
  const [itemToRemove, setItemToRemove] = React.useState<IAnimalRegistrationGridData>(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [showSaveConfirm, setShowSaveConfirm] = useState(false);
  const [deleteMessage, setDeleteMessage] = useState<string>('');
  const [saveMessage, setSaveMessage] = useState<string>('');
  const [showLoading, setShowLoading] = useState(false);

  const enterEdit = (item: IAnimalRegistrationGridData) => {
    setOpenForm(true);
    setEditItem(item);
  };

  const handleCancelEdit = () => {
    setOpenForm(false);
    setEditItem(null);
  };

  const handleEdit = (formData: IAnimalRegistrationGridData) => {
    const index = items.findIndex((x) => x.animalId === formData.animalId);

    if (index !== -1) {
      formData.modified = true;
      items[index] = formData;
    }

    setOpenForm(false);
  };

  const onDelete = (item: IAnimalRegistrationGridData) => {
    setDeleteMessage(`Потвърждавате ли премахването животно ${item.lID}?`);
    setItemToRemove(item);
    setShowDeleteConfirm(true);
  };

  const handleDelete = (confirmed: boolean) => {
    if (confirmed) {
      const index = items.findIndex((x) => x.animalId === itemToRemove.animalId);

      if (index !== -1) {
        items.splice(index, 1);
      }
    }

    setItemToRemove(null);
    setShowDeleteConfirm(false);
  };

  const onSaveClick = () => {
    setSaveMessage(`Потвърждавате ли записа на ${items.length} животни ?`);
    setShowSaveConfirm(true);
  };

  const handleSave = (confirmed: boolean) => {
    setShowSaveConfirm(false);

    if (confirmed) {
      // TODO Perform save then call next
      setShowLoading(true);
      const data = [];
      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        data.push({
          acquisitionDate: item.acquisitionDate,
          acquisitionType: (item.acquisitionType as any).value,
          animalObjectId: (item.animalObjectId as any).animalObjectId,
          lID: item.lID,
          sex: (item.sex as any).value,
          // color: (item.color as any).id,
          animalType: item.animalType,
          active: true,
          cattleBreed: item.cattleBreed ? (item.cattleBreed as any).value : null,
          dateOfBirth: item.dateOfBirth,
          damLID: item.damLID,
          comment: null,
          passportNumber: null,
          sheepBreed: item.sheepBreed ? (item.sheepBreed as any).value : null,
          isCastrated: item.isCastrated,
          cattleColorId: item.animalType === AnimalTypeEnum.CATTLE ? (item.color as any).id : null,
          sheepColorId: item.animalType === AnimalTypeEnum.SHEEP ? (item.color as any).id : null,
        });
      }

      dispatch(createRegistrations(data)).then((response) => {
        console.log('SUCCESS', response);
        setShowLoading(false);
        onNext(items);
      })
        .catch((err) => console.log('ERROR: ', err));
    }
  };

  const ActionsCell = ({ dataItem }: GridCellProps) =>
  (
    <td className='text-center'>
      <Button
        data-testid='editButton'
        type='button'
        className='k-button k-secondary mr-l'
        icon='edit'
        look='outline'
        onClick={() => {
          enterEdit(dataItem);
        }}
      />
      <Button
        data-testid='deleteButton'
        type='button'
        className='k-button k-button-error'
        icon='delete'
        look='outline'
        onClick={() => {
          onDelete(dataItem);
        }}
      />
    </td>
  );

  const firstCell = (props: GridCellProps) => (
    <td className='text-center'>
      <div style={{ position: 'absolute' }}>
        {props.dataItem.modified && <i className='fa fa-check' style={{ left: '0' }} />}
        <span style={{ margin: '0 auto' }}>{props.dataItem.lID}</span>
      </div>
    </td>
  );

  const [sort, setSort] = React.useState<SortDescriptor[]>([]);

  return (
    <div>
      <h3>
        Заприхождаване на &nbsp;
        <u className='text-primary'>{items.length}</u>
        &nbsp; животни
      </h3>
      <div>
        {!showLoading && (
          <Grid
            data={orderBy(items, sort)}
            pageable
            sortable
            sort={sort}
            onSortChange={(e: GridSortChangeEvent) => {
              setSort(e.sort);
            }}
          >
            <GridColumn cell={firstCell} field='lID' title='Ветис ID' />
            <GridColumn field='type' title='Вид' />
            <GridColumn field='sex.label' title='Пол' />
            <GridColumn field='breed.label' title='Порода' />
            <GridColumn field='color.text' title='Цвят' />
            <GridColumn field='dateOfBirth' title='Дата на раждане' format='{0: dd-MM-yyyy}' />
            <GridColumn cell={ActionsCell} />
          </Grid>
        )}
        {showLoading && (
          <Loader size='large' type='pulsing' className='k-centered' />
        )}
      </div>
      <hr />
      <div className='d-flex justify-content-center mb-l align-items-center'>
        <div className='mr-l' style={{ height: 'fit-content' }}>
          Стъпка 3 от 3
        </div>
        <Button
          name='Back'
          type='button'
          title={localizationService.toLanguageString(BACKWORD, '')}
          primary
          iconClass='fa fa-reply'
          onClick={onPrev}
          className='mr-l'
          disabled={showLoading}
        >
          {localizationService.toLanguageString(BACKWORD, '')}
        </Button>
        <Button
          name='OwnershipDataAdd'
          type='submit'
          title={localizationService.toLanguageString(SAVE, '')}
          primary
          iconClass='fa fa-save'
          onClick={onSaveClick}
          disabled={showLoading}
        >
          {localizationService.toLanguageString(SAVE, '')}
        </Button>
      </div>
      <hr />
      {openForm
        && (
          <RegistrationEditForm
            cancelEdit={handleCancelEdit}
            onSubmit={handleEdit}
            item={editItem}
          />
        )}

      {showDeleteConfirm
        && (
          <ConfirmDialog onResult={handleDelete} title='Потвърждение' message={deleteMessage} />
        )}

      {showSaveConfirm
        && (
          <ConfirmDialog onResult={handleSave} title='Потвърждение' message={saveMessage} />
        )}
    </div>
  );
};

export default RegistrationTableData;
