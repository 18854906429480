/* eslint-disable */

import React, { useContext, useState, useEffect } from 'react';
import { singletonHook } from 'react-singleton-hook';
import { Auth0Context } from '../features/Auth/Auth0ContextWrapper';
import createClient, { GraphQLClient } from '../app/Client';
import { useAppDispatch } from '../app/StoreHooks';

export const useAuth0 = () => useContext(Auth0Context);

const useCurrentUserImpl = () => {
  const dispatch = useAppDispatch();
  return createClient(dispatch);
};

export const useGraphQlClient = singletonHook<GraphQLClient>(
  null,
  useCurrentUserImpl
);

type ReturnType<T> = [
  T | undefined,
  React.Dispatch<React.SetStateAction<T | undefined>>
];

export const useLocalStorage = <T>(
  key: string,
  initialValue?: T
): ReturnType<T> => {
  const [state, setState] = useState<T | undefined>(() => {
    if (!initialValue) return;

    try {
      const value = localStorage.getItem(key);
      return value ? JSON.parse(value) : initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  useEffect(() => {
    if (state) {
      try {
        localStorage.setItem(key, JSON.stringify(state));
      } catch (error) {
        console.log(error);
      }
    }
  }, [state, key]);

  return [state, setState];
};
