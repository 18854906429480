import React, { useRef, useState } from 'react';
import { State, process } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridToolbar
} from '@progress/kendo-react-grid';
import { useLocalization } from '@progress/kendo-react-intl';
import { GridPDFExport } from '@progress/kendo-react-pdf';
import GridSearchBar from '../../features/GridSearchBar/GridSearchBar';
import {
  ADD_NEW,
  CHEMICAL_TREATMENT_LOG_TITLE,
  CHEMICAL_TREATEMENT_ACTIVE_CHEMICAL,
  CHEMICAL_TREATEMENT_CHEMICAL,
  CHEMICAL_TREATEMENT_CONCENTRATE,
  CHEMICAL_TREATEMENT_DOSE,
  CHEMICAL_TREATEMENT_PEST,
  CHEMICAL_TREATEMENT_TECHNIQUE,
  CHEMICAL_TREATEMENT_TREATED_AREA,
  DATE,
  EXPORT
} from '../../languages/languages';
import {
  ChemicalTreatmentLogInputType,
  ChemicalTreatmentLogType
} from '../../slices/data/ChemicalTreatmentLogSliceData';
import ColumnMenu from '../ColumnMenu/ColumnMenu';
import { DateCell, EditCommandCell } from '../CustomGridCells/CustomGridCells';
import styles from './style';
import { useAppSelector } from '../../app/StoreHooks';
import { selectChemicalTreatmentLoadingState } from '../../slices/ChemicalTreatmentLogSlice';
import GridLoaderPanel from '../GridLoaderPanel/GridLoaderPanel';

const PageTemplate = () =>
  (
    <div>
      <p>Година: </p>
      <p>Община: </p>
      <p>Населено място: </p>
      <p>ЗП: </p>
      <p>ОД. Земеделие: </p>
      <p>Адрес: </p>
      <p>ЕГН / ЕИК: </p>
      <p>ЕКАТЕ: </p>
      <p>Град: </p>
    </div>
  );

type ChemicalTreatmentLogGridProps = {
  data: ChemicalTreatmentLogType[];
  addNew: () => void;
  enterEdit: (item: ChemicalTreatmentLogInputType) => void;
};
const ChemicalTreatmentLogGrid = ({
  data,
  addNew,
  enterEdit
}: ChemicalTreatmentLogGridProps) => {
  const gridPDFExport = useRef(null);
  const exportPDF = () => {
    if (gridPDFExport !== undefined) {
      gridPDFExport.current.save(data);
    }
  };

  const localizationService = useLocalization();
  const loadingState = useAppSelector(selectChemicalTreatmentLoadingState);

  //   Grid State
  const [dataState, setDataState] = useState<State>({
    sort: [{ field: 'code', dir: 'asc' }],
    take: 10,
    skip: 0
  });

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataState(event.dataState);
  };

  const MyEditCommandCell = (props: GridCellProps) =>
    (
      <EditCommandCell {...props} enterEdit={enterEdit} />
    );

  const grid = (
    <>
      <h1 className='page-title'>{localizationService.toLanguageString(CHEMICAL_TREATMENT_LOG_TITLE, '')}</h1>
      <div className='content-wrapper section'>
        <Grid
          {...dataState}
          data={process(data, dataState)}
          pageable
          sortable
          onDataStateChange={dataStateChange}
        >
          <GridToolbar>
            <GridSearchBar
              filterBy='pest'
              dataState={dataState}
              setDataState={setDataState}
            />
            <Button
              title={localizationService.toLanguageString(ADD_NEW, '')}
              icon='plus'
              primary
              onClick={addNew}
            >
              {localizationService.toLanguageString(ADD_NEW, '')}
            </Button>
            <Button
              title='Export Excel'
              icon='download'
              look='outline'
              onClick={exportPDF}
            >
              {localizationService.toLanguageString(EXPORT, '')}
            </Button>
          </GridToolbar>
          <Column
            key='treatmentDate'
            field='treatmentDate'
            title={localizationService.toLanguageString(DATE, '')}
            filter='date'
            cell={DateCell}
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='pest'
            field='pest'
            title={localizationService.toLanguageString(
              CHEMICAL_TREATEMENT_PEST,
              ''
            )}
            filter='text'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='dose'
            field='dose'
            title={localizationService.toLanguageString(
              CHEMICAL_TREATEMENT_DOSE,
              ''
            )}
            filter='numeric'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='treatedArea'
            field='treatedArea'
            title={localizationService.toLanguageString(
              CHEMICAL_TREATEMENT_TREATED_AREA,
              ''
            )}
            filter='numeric'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='applicationTechnique'
            field='applicationTechnique'
            title={localizationService.toLanguageString(
              CHEMICAL_TREATEMENT_TECHNIQUE,
              ''
            )}
            filter='text'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='chemical'
            field='chemical'
            title={localizationService.toLanguageString(
              CHEMICAL_TREATEMENT_CHEMICAL,
              ''
            )}
            filter='text'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='activeChemical'
            field='activeChemical'
            title={localizationService.toLanguageString(
              CHEMICAL_TREATEMENT_ACTIVE_CHEMICAL,
              ''
            )}
            filter='text'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='concentrate'
            field='concentrate'
            title={localizationService.toLanguageString(
              CHEMICAL_TREATEMENT_CONCENTRATE,
              ''
            )}
            filter='numeric'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column cell={MyEditCommandCell} width={120} />
        </Grid>
        {loadingState && <GridLoaderPanel />}
      </div>
    </>
  );

  return (
    <>
      {grid}
      <GridPDFExport
        pageTemplate={PageTemplate}
        paperSize='A4'
        margin='1cm'
        landscape
        ref={gridPDFExport}
      >
        {/* {grid} */}
      </GridPDFExport>
      <style>{styles}</style>
    </>
  );
};

export default ChemicalTreatmentLogGrid;
