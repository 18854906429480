import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import {
  Form,
  FormRenderProps,
  FormElement,
  Field
} from '@progress/kendo-react-form';
import { useLocalization } from '@progress/kendo-react-intl';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import {
  GRADED_ANIMAL_IDENTIFICATOR,
  INSPECTION_DATE,
  INSPECTOR,
  AGE_CLASS,
  MUSCLE_CLASS,
  MUSCLE_CLASSIFICATION,
  FAT_CLASS,
  FAT_COLOR,
  OSSIFI_SCORE,
  TEETH_FORMULA,
  MEAT_COLOR,
  CARCASS_YIELD,
  CARCASS_QUALITY,
  SAVE,
  CLEAR_FORM,
  CHOOSE_DROPDOWN_OPTION,
  ANIMAL_SELECTION_PLACEHOLDER
} from '../../languages/languages';
import { EmployeesABType } from '../../slices/data/EmployeesSliceData';
import { AnimalType } from '../../slices/data/AnimalSliceData';
import {
  FormComboBox,
  FormDateTimePicker,
  FormRadioGroup
} from '../FormComponents/FormComponents';
import { StringValidator } from '../Validators/Validators';
import {
  ageClass,
  carcassQuality,
  carcassYield,
  fatClass,
  fatColor,
  meatColor,
  seuropMuscleClass,
  muscleClassification,
  ossifiScore,
  teethFormula,
  muscleClass
} from './RadioButtonInformation';

type CarcassQualityGradingFormProps = {
  initValues: {};
  animals: AnimalType[];
  users: EmployeesABType[];
  handleSubmit: (formData: any) => void;
};

const CarcassQualityGradingForm = ({
  initValues,
  animals,
  users,
  handleSubmit
}: CarcassQualityGradingFormProps) => {
  const localizationService = useLocalization();

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initValues}
      render={(formRenderProps: FormRenderProps) =>
        (
          <FormElement>
            <div className='one-row-flex-form-section pb-m'>
              <Field
                name='animalId'
                component={FormComboBox}
                data={animals}
                textField='lID'
                label={localizationService.toLanguageString(
                  GRADED_ANIMAL_IDENTIFICATOR,
                  ''
                )}
                onChange={({ value }: ComboBoxChangeEvent) => {
                  if (value === null) {
                    return;
                  }
                  formRenderProps.onChange('animalId', { value: value.animalId });
                }}
                placeholder={localizationService.toLanguageString(ANIMAL_SELECTION_PLACEHOLDER, '')}
                validator={StringValidator}
              />
              <Field
                name='inspectionDate'
                format='dd-MMM-yyyy HH:mm'
                component={FormDateTimePicker}
                label={localizationService.toLanguageString(INSPECTION_DATE, '')}
                validator={StringValidator}
              />
              <Field
                name='inspector'
                component={FormComboBox}
                data={users}
                textField='userName'
                onChange={({ value }: ComboBoxChangeEvent) => {
                  if (value === null) {
                    return;
                  }
                  formRenderProps.onChange('inspector', { value: value.userId });
                }}
                label={localizationService.toLanguageString(INSPECTOR, '')}
                validator={StringValidator}
                placeholder={localizationService.toLanguageString(CHOOSE_DROPDOWN_OPTION, '')}
              />
            </div>
            <PanelBar className='mb-l accordion-container'>
              <PanelBarItem expanded title='Europe Grading'>
                <Field
                  name='ageClass'
                  layout='horizontal'
                  component={FormRadioGroup}
                  data={ageClass.map((cl) =>
                    ({
                      label: localizationService.toLanguageString(cl.label, ''),
                      value: cl.value,
                      title: cl.title
                    }))}
                  textField='label'
                  label={localizationService.toLanguageString(AGE_CLASS, '')}
                  validator={StringValidator}
                />
                <legend className='k-form-legend mt-m'>SEUROP Class</legend>
                <Field
                  name='seuropMuscleClass'
                  layout='horizontal'
                  component={FormRadioGroup}
                  data={seuropMuscleClass}
                  textField='label'
                  label={localizationService.toLanguageString(MUSCLE_CLASS, '')}
                  validator={StringValidator}
                />
                <Field
                  name='muscleClassification'
                  layout='horizontal'
                  component={FormRadioGroup}
                  data={muscleClassification}
                  textField='label'
                  label={localizationService.toLanguageString(
                    MUSCLE_CLASSIFICATION,
                    ''
                  )}
                  validator={StringValidator}
                />
                <Field
                  name='fatClass'
                  layout='horizontal'
                  component={FormRadioGroup}
                  data={fatClass}
                  textField='label'
                  label={localizationService.toLanguageString(FAT_CLASS, '')}
                  validator={StringValidator}
                />
                <Field
                  name='muscleClass'
                  layout='horizontal'
                  component={FormRadioGroup}
                  data={muscleClass}
                  textField='label'
                  label={localizationService.toLanguageString(MUSCLE_CLASS, '')}
                  validator={StringValidator}
                />
              </PanelBarItem>
              <PanelBarItem title='USDA Grading'>
                <Field
                  name='fatColor'
                  layout='horizontal'
                  component={FormRadioGroup}
                  data={fatColor.map((fcl) =>
                    ({
                      label: localizationService.toLanguageString(fcl.label, ''),
                      value: fcl.value
                    }))}
                  textField='label'
                  label={localizationService.toLanguageString(FAT_COLOR, '')}
                  validator={StringValidator}
                />
                <Field
                  name='ossifiScore'
                  layout='horizontal'
                  component={FormRadioGroup}
                  data={ossifiScore}
                  textField='label'
                  label={localizationService.toLanguageString(OSSIFI_SCORE, '')}
                  validator={StringValidator}
                />
                <Field
                  name='teethFormula'
                  layout='horizontal'
                  component={FormRadioGroup}
                  data={teethFormula.map((tform) =>
                    ({
                      label: typeof tform.label === 'string' ? localizationService.toLanguageString(tform.label, '') : tform.label,
                      value: tform.value
                    }))}
                  textField='label'
                  label={localizationService.toLanguageString(TEETH_FORMULA, '')}
                  validator={StringValidator}
                />
                <Field
                  name='meatColor'
                  layout='horizontal'
                  component={FormRadioGroup}
                  data={meatColor}
                  textField='label'
                  label={localizationService.toLanguageString(MEAT_COLOR, '')}
                  validator={StringValidator}
                />
                <Field
                  name='carcassYield'
                  layout='horizontal'
                  component={FormRadioGroup}
                  data={carcassYield}
                  textField='label'
                  label={localizationService.toLanguageString(CARCASS_YIELD, '')}
                  validator={StringValidator}
                />
                <Field
                  name='carcassQuality'
                  layout='horizontal'
                  component={FormRadioGroup}
                  data={carcassQuality.map((carqual) =>
                    ({
                      label: localizationService.toLanguageString(carqual.label, ''),
                      value: carqual.value
                    }))}
                  textField='label'
                  label={localizationService.toLanguageString(
                    CARCASS_QUALITY,
                    ''
                  )}
                  validator={StringValidator}
                />
              </PanelBarItem>
            </PanelBar>
            <div className='k-form-buttons justify-content-end'>
              <Button
                name='submit'
                type='submit'
                primary
                icon='save'
                disabled={!formRenderProps.allowSubmit}
              >
                {localizationService.toLanguageString(SAVE, '')}
              </Button>
              <Button
                name='clear'
                type='reset'
                primary
                look='outline'
                icon='cancel'
                onClick={formRenderProps.onFormReset}
              >
                {localizationService.toLanguageString(CLEAR_FORM, '')}
              </Button>
            </div>
          </FormElement>
        )}
    />
  );
};

export default CarcassQualityGradingForm;
