/* eslint-disable */

import { createAsyncThunk } from '@reduxjs/toolkit';

import createGQLClient, { getGraphQLClient, GraphQLClient } from '../app/Client';
import { AppDispatch } from '../app/StoreHooks';

type ActionPayloadGraphQLClient = {
  client: GraphQLClient;
  dispatch: AppDispatch;
};

type ActionPayload<TInput> = {
  input: TInput;
} & ActionPayloadGraphQLClient;

type Func<TInput, TResult> = (
  actionPayLoad: ActionPayload<TInput>
) => Promise<TResult>;
type Action<TResult> = (
  actionPayLoad: ActionPayloadGraphQLClient
) => Promise<TResult>;

export const createAsyncThunkGeneric = <TInput, TResult>(
  prefix:string,
  action: Func<TInput, TResult>
) =>
    createAsyncThunk(
      prefix,
      async (input: TInput, { rejectWithValue, dispatch, getState }) => {
        try {
          const client = getGraphQLClient() || createGQLClient(dispatch);
          const payload = {
            input,
            client,
            dispatch
          };
          return await action(payload);
        } catch (ex) {
          rejectWithValue(ex);
        }
      }
    );

export const createAsyncThunkGenericVoid = <TResult>(
  prefix:string,
  action: Action<TResult>
) =>
    createAsyncThunk(prefix, async (_, { rejectWithValue, dispatch, getState }) => {
      try {
        const client = getGraphQLClient() || createGQLClient(dispatch);
        const payload = {
          client,
          dispatch
        };
        return await action(payload);
      } catch (ex) {
        rejectWithValue(ex);
      }
    });
