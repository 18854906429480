import { graphql } from 'msw';
import AUTH_CONFIG from '../../auth-variables';

const blbTrace = graphql.link(AUTH_CONFIG.apiUrl);

function randomDate(start, end) {
  return new Date(
    start.getTime() + Math.random() * (end.getTime() - start.getTime())
  );
}

enum StatusEnums {
  ACCEPTED = 'Accepted',
  REJECTED = 'Rejected',
  PENDING = 'Pending'
}

const dealsHistory = [
  {
    dealID: 1,
    dealBatchNumber: Math.floor(Math.random() * (10000 - 1) + 10000),
    dealDate: randomDate(new Date(2012, 0, 1), new Date()),
    dealStatus: StatusEnums.PENDING,
    dealCreator: 'Stoyko'
  },
  {
    dealID: Math.floor(Math.random() * (99 - 1) + 99),
    dealBatchNumber: Math.floor(Math.random() * (10000 - 1) + 10000),
    dealDate: randomDate(new Date(2012, 0, 1), new Date()),
    dealStatus: StatusEnums.ACCEPTED,
    dealCreator: 'Stoyko'
  },
  {
    dealID: Math.floor(Math.random() * (99 - 1) + 99),
    dealBatchNumber: Math.floor(Math.random() * (10000 - 1) + 10000),
    dealDate: randomDate(new Date(2012, 0, 1), new Date()),
    dealStatus: StatusEnums.PENDING,
    dealCreator: 'Stoyko'
  },
  {
    dealID: Math.floor(Math.random() * (99 - 1) + 99),
    dealBatchNumber: Math.floor(Math.random() * (10000 - 1) + 10000),
    dealDate: randomDate(new Date(2012, 0, 1), new Date()),
    dealStatus: StatusEnums.REJECTED,
    dealCreator: 'Stoyko'
  },
  {
    dealID: Math.floor(Math.random() * (99 - 1) + 99),
    dealBatchNumber: Math.floor(Math.random() * (10000 - 1) + 10000),
    dealDate: randomDate(new Date(2012, 0, 1), new Date()),
    dealStatus: StatusEnums.ACCEPTED,
    dealCreator: 'Stoyko'
  },
  {
    dealID: Math.floor(Math.random() * (99 - 1) + 99),
    dealBatchNumber: Math.floor(Math.random() * (10000 - 1) + 10000),
    dealDate: randomDate(new Date(2012, 0, 1), new Date()),
    dealStatus: StatusEnums.REJECTED,
    dealCreator: 'Stoyko'
  },
  {
    dealID: Math.floor(Math.random() * (99 - 1) + 99),
    dealBatchNumber: Math.floor(Math.random() * (10000 - 1) + 10000),
    dealDate: randomDate(new Date(2012, 0, 1), new Date()),
    dealStatus: StatusEnums.PENDING,
    dealCreator: 'Stoyko'
  },
  {
    dealID: Math.floor(Math.random() * (99 - 1) + 99),
    dealBatchNumber: Math.floor(Math.random() * (10000 - 1) + 10000),
    dealDate: randomDate(new Date(2012, 0, 1), new Date()),
    dealStatus: StatusEnums.ACCEPTED,
    dealCreator: 'Stoyko'
  },
  {
    dealID: Math.floor(Math.random() * (99 - 1) + 99),
    dealBatchNumber: Math.floor(Math.random() * (10000 - 1) + 10000),
    dealDate: randomDate(new Date(2012, 0, 1), new Date()),
    dealStatus: StatusEnums.PENDING,
    dealCreator: 'Stoyko'
  },
  {
    dealID: Math.floor(Math.random() * (99 - 1) + 99),
    dealBatchNumber: Math.floor(Math.random() * (10000 - 1) + 10000),
    dealDate: randomDate(new Date(2012, 0, 1), new Date()),
    dealStatus: StatusEnums.REJECTED,
    dealCreator: 'Stoyko'
  },
  {
    dealID: Math.floor(Math.random() * (99 - 1) + 99),
    dealBatchNumber: Math.floor(Math.random() * (10000 - 1) + 10000),
    dealDate: randomDate(new Date(2012, 0, 1), new Date()),
    dealStatus: StatusEnums.REJECTED,
    dealCreator: 'Stoyko'
  },
  {
    dealID: Math.floor(Math.random() * (99 - 1) + 99),
    dealBatchNumber: Math.floor(Math.random() * (10000 - 1) + 10000),
    dealDate: randomDate(new Date(2012, 0, 1), new Date()),
    dealStatus: StatusEnums.ACCEPTED,
    dealCreator: 'Stoyko'
  }
];

const allDeals = [
  {
    dealID: 1,
    dealBatchNumber: Math.floor(Math.random() * (10000 - 1) + 10000),
    dealDate: new Date(),
    dealStatus: StatusEnums.PENDING,
    sellFirm: {
      firmEIK: '94719578123',
      firmMOL: 'Stoyko',
      firmName: 'TestFirm1'
    },
    buyFirm: {
      firmEIK: '2423423423',
      firmMOL: 'асд',
      firmName: 'ас'
    },
    transportDate: randomDate(new Date(2012, 0, 1), new Date()),
    transportFirmName: 'DemoFirmTransport Name 1',
    transportFirmEIK: '10239490123',
    transportDriver: 'DemoTransportDriver Name 1',
    transportDriverPIN: 7509139402,
    transportVehicleLicense: 'PB 0312 TC',
    waybillNumber: 'BG921034md',
    vehicleTaraWeight: Math.floor(Math.random() * (1000 - 0 + 1)) + 0,
    vehicleTotalWeight: Math.floor(Math.random() * (1000 - 0 + 1)) + 0,
    averageAnimalWeight: Math.floor(Math.random() * (1000 - 0 + 1)) + 0,
    pricePerKilo: Math.floor(Math.random() * (1000 - 0 + 1)) + 0,
    pricePerHead: null,
    arrivalDate: null,
    animals: [
      {
        animalId: 1,
        lID: 'FR4004387287',
        eID: null,
        dateOfBirth: '2018-06-08T00:00:00',
        comment: '',
        passportNumber: '',
        cattleBreed: 'HER',
        animalWeight: Math.floor(Math.random() * (1000 - 0 + 1)) + 0
      },
      {
        animalId: 2,
        lID: 'FR4004387288',
        eID: null,
        dateOfBirth: '2018-06-26T00:00:00',
        comment: '',
        passportNumber: '',
        cattleBreed: 'HER',
        animalWeight: Math.floor(Math.random() * (1000 - 0 + 1)) + 0
      },
      {
        animalId: 3,
        lID: 'FR4004387289',
        eID: null,
        dateOfBirth: '2018-06-17T00:00:00',
        comment: '',
        passportNumber: '',
        cattleBreed: 'ANN',
        animalWeight: Math.floor(Math.random() * (1000 - 0 + 1)) + 0
      }
    ]
  }
];

export default [
  // Get deals history information
  blbTrace.query('GetDealsHistory', (_, res, ctx) =>
    res(
      ctx.data({
        dealsHistory: {
          items: dealsHistory
        }
      })
    )),

  // Get a specific deal
  blbTrace.query('GetSingleDeal', (req, res, ctx) => {
    const { id } = req.variables;
    const singleDeal = allDeals.find((deal) =>
      deal.dealID === id);
    return res(
      ctx.data({
        dealData: {
          deal: singleDeal
        }
      })
    );
  })
];
