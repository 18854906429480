/* eslint-disable */

import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/StoreHooks';

type SupportedLanguages = 'en' | 'bg';

type LanguageState = {
  currentLanguage: SupportedLanguages;
};

const initialLanguageState: LanguageState = {
  currentLanguage: 'bg'
};

export const LanguageSlice = createSlice({
  name: 'language',
  initialState: initialLanguageState,
  reducers: {
    setLanguage: (state, { payload }) => {
      state.currentLanguage = payload;
    }
  }
});

// Export actions
export const { setLanguage } = LanguageSlice.actions;

// Export Selectors
export const selectLanguage = (state: RootState) =>
  state.language;

// Export Reducer
export default LanguageSlice.reducer;
