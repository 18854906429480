import { graphql } from 'msw';
import AUTH_CONFIG from '../../auth-variables';
import { PurchasedMedicineType } from '../../slices/data/PurchasedMedicinesSliceData';

const purchasedMedicines: PurchasedMedicineType[] = [];

const blbTrace = graphql.link(AUTH_CONFIG.apiUrl);

export default [
  blbTrace.query('GetPurchasedMedicines', (_, res, ctx) =>
    res(
      ctx.data({
        getPurchasedMedicines: purchasedMedicines
      })
    ))
];
