import React from 'react';
import {
  Form,
  Field,
  FormElement,
  FormRenderProps
} from '@progress/kendo-react-form';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { useLocalization } from '@progress/kendo-react-intl';
import {
  FormComboBox,
  FormInput
} from '../FormComponents/FormComponents';
import { ShoppingCenterType } from '../../slices/data/ShoppingCenterSliceData';
import {
  NumberValidator,
  StringValidator
} from '../Validators/Validators';
import {
  ADDRESS_PLACEHOLDER,
  OWNER_PLACEHOLDER,
  SHOP_ADDRESS,
  SHOP_DEFAULT_VALUE,
  SHOP_MTS,
  SHOP_NAME,
  SHOP_NUMBER,
  SHOP_OWNER,
  SHOP_SMALL_SHOP,
  SHOP_TYPE,
  SUBMIT_FORM
} from '../../languages/languages';
import { AvailableFirmsType } from '../../slices/data/FirmSliceData';

type ShoppingCenterFormProps = {
  submitForm: (formData: ShoppingCenterType) => void;
  availableFirms: AvailableFirmsType[];
};

const ShoppingCenterForm = ({ submitForm, availableFirms }:ShoppingCenterFormProps) => {
  const localizationService = useLocalization();
  const data: { label: string; type: string }[] = [
    { label: localizationService.toLanguageString(SHOP_SMALL_SHOP, ''), type: 'SMALL_SHOP' },
    { label: localizationService.toLanguageString(SHOP_MTS, ''), type: 'MODERN_TRADE_SHOP' }
  ];

  return (
    <div className='form'>
      <Form
        onSubmit={submitForm}
        render={(formRenderProps: FormRenderProps) =>
          (
            <FormElement>
              <fieldset>
                <Field
                  name='shoppingCenterName'
                  component={FormInput}
                  label={localizationService.toLanguageString(SHOP_NAME, '')}
                  placeholder={localizationService.toLanguageString(SHOP_NAME, '')}
                  validator={StringValidator}
                />

                <Field
                  name='shoppingCenterNumber'
                  component={FormInput}
                  label={localizationService.toLanguageString(SHOP_NUMBER, '')}
                  placeholder={localizationService.toLanguageString(
                    SHOP_NUMBER,
                    ''
                  )}
                  validator={NumberValidator}
                />

                <Field
                  onChange={({ value }: ComboBoxChangeEvent) => {
                    if (value === null) {
                      return;
                    }
                    formRenderProps.onChange('shoppingCenterType', {
                      value: value.type
                    });
                  }}
                  style={{ zIndex: 1003 }}
                  name='shoppingCenterType'
                  component={FormComboBox}
                  data={data}
                  textField='label'
                  label={localizationService.toLanguageString(SHOP_TYPE, '')}
                  placeholder={localizationService.toLanguageString(
                    SHOP_DEFAULT_VALUE,
                    ''
                  )}
                  validator={StringValidator}
                />

                <Field
                  onChange={({ value }: ComboBoxChangeEvent) => {
                    if (value === null) {
                      return;
                    }
                    formRenderProps.onChange('firmId', {
                      value: value.firmId
                    });
                  }}
                  placeholder={localizationService.toLanguageString(
                    OWNER_PLACEHOLDER,
                    ''
                  )}
                  id='firmName'
                  name='firmId'
                  label={localizationService.toLanguageString(SHOP_OWNER, '')}
                  component={FormComboBox}
                  textField='firmName'
                  data={availableFirms}
                  validator={StringValidator}
                />

                <Field
                  name='shoppingCenterAddress'
                  component={FormInput}
                  label={localizationService.toLanguageString(SHOP_ADDRESS, '')}
                  placeholder={localizationService.toLanguageString(
                    ADDRESS_PLACEHOLDER,
                    ''
                  )}
                  validator={StringValidator}
                />
              </fieldset>
              <div className='k-form-buttons d-flex justify-content-end mb-l'>
                <Button
                  name='ShoppingCenterFormAdd'
                  type='submit'
                  title={localizationService.toLanguageString(SUBMIT_FORM, '')}
                  primary
                  icon='save'
                  disabled={!formRenderProps.allowSubmit}
                >
                  {localizationService.toLanguageString(SUBMIT_FORM, '')}
                </Button>
              </div>
            </FormElement>
          )}
      />
    </div>
  );
};

export default ShoppingCenterForm;
