import React from 'react';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent
} from '@progress/kendo-react-grid';
import { State, process } from '@progress/kendo-data-query';
import { useLocalization } from '@progress/kendo-react-intl';
import { TrademarkType } from '../../slices/data/TrademarkSliceData';
import {
  STATUS,
  TM_NAME,
  TM_REG,
  TM_LINK,
  ENT_OWNER,
  TM_IMAGE
} from '../../languages/languages';
import ColumnMenu from '../ColumnMenu/ColumnMenu';
import {
  BooleanCell,
  CustomLinkCell,
  FirmCell
} from '../FormComponents/FormComponents';
import { useAppSelector } from '../../app/StoreHooks';
import { selectTrademarksLoading } from '../../slices/TrademarkSlice';
import GridLoaderPanel from '../GridLoaderPanel/GridLoaderPanel';

type TrademarkGridProps = {
  dataState: State;
  dataStateChange: (event: GridDataStateChangeEvent) => void;
  data: TrademarkType[];
  cell: (props: GridCellProps) => any;
};
const TrademarkGrid = ({
  cell,
  data,
  dataState,
  dataStateChange
}: TrademarkGridProps) => {
  const localizationService = useLocalization();
  const loadingState = useAppSelector(selectTrademarksLoading);
  return (
    <>
      <Grid
        {...dataState}
        onDataStateChange={dataStateChange}
        sortable
        pageable
        data={process(data, dataState)}
      >
        <Column
          field='active'
          title={localizationService.toLanguageString(STATUS, '')}
          cell={BooleanCell}
          width={150}
        />
        <Column
          field='tradeMarkName'
          filter='text'
          columnMenu={ColumnMenu}
          title={localizationService.toLanguageString(TM_NAME, '')}
        />
        <Column
          field='tradeMarkNumber'
          filter='text'
          columnMenu={ColumnMenu}
          title={localizationService.toLanguageString(TM_REG, '')}
        />
        <Column
          field='tradeMarkLink'
          filter='text'
          cell={CustomLinkCell}
          columnMenu={ColumnMenu}
          title={localizationService.toLanguageString(TM_LINK, '')}
        />
        <Column
          field='firmId'
          filter='text'
          title={localizationService.toLanguageString(ENT_OWNER, '')}
          cell={FirmCell}
          columnMenu={ColumnMenu}
        />
        <Column
          field='tradeMarkFile'
          filter='numeric'
          title={localizationService.toLanguageString(TM_IMAGE, '')}
        />
        <Column cell={cell} width={150} />
      </Grid>
      {loadingState && <GridLoaderPanel />}
    </>
  );
};

export default TrademarkGrid;
