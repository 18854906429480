import * as React from 'react';
import './style.css';
import { Dialog } from '@progress/kendo-react-dialogs';
import {
  Form,
  Field,
  FormElement,
  FormRenderProps
} from '@progress/kendo-react-form';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { useLocalization } from '@progress/kendo-react-intl';
import {
  FormInput,
  FormSwitch,
  FormComboBox
} from '../FormComponents/FormComponents';
import { ShoppingCenterType } from '../../slices/data/ShoppingCenterSliceData';
import { useAppSelector } from '../../app/StoreHooks';
import { StringValidator } from '../Validators/Validators';
import { selectAvailableFirms } from '../../slices/FirmSlice';
import {
  DECLINE,
  ENT_TYPE_PLACEHOLDER,
  OWNER_PLACEHOLDER,
  SHOP_ADDRESS,
  SHOP_MTS,
  SHOP_NAME,
  SHOP_NUMBER,
  SHOP_OWNER,
  SHOP_SMALL_SHOP,
  SHOP_TYPE,
  STATUS,
  UPDATE
} from '../../languages/languages';

type EditFormProps = {
  item: ShoppingCenterType;
  cancelEdit: () => void;
  onSubmit: (formData: ShoppingCenterType) => void;
};
const ShoppingCenterEditForm = ({
  item,
  cancelEdit,
  onSubmit
}: EditFormProps) => {
  const localizationService = useLocalization();
  const availableFirms = useAppSelector(selectAvailableFirms);

  const selectedFirm = availableFirms.find(
    (firm) =>
      firm.firmId === item.firmId
  );

  const data: { label: string; type: string }[] = [
    {
      label: localizationService.toLanguageString(SHOP_SMALL_SHOP, ''),
      type: 'SMALL_SHOP'
    },
    {
      label: localizationService.toLanguageString(SHOP_MTS, ''),
      type: 'MODERN_TRADE_SHOP'
    }
  ];

  const selectedType = data.find(
    (type) =>
      type.type === item.shoppingCenterType
  );

  return (
    <Dialog title={item.shoppingCenterName} onClose={cancelEdit}>
      <Form
        onSubmit={onSubmit}
        initialValues={item}
        render={(formRenderProps: FormRenderProps) =>
          (
            <FormElement>
              <Field
                name='active'
                component={FormSwitch}
                label={localizationService.toLanguageString(STATUS, '')}
                value={item.active}
              />
              <Field
                name='shoppingCenterName'
                component={FormInput}
                label={localizationService.toLanguageString(SHOP_NAME, '')}
              />

              <Field
                name='shoppingCenterNumber'
                component={FormInput}
                label={localizationService.toLanguageString(SHOP_NUMBER, '')}
              />

              <Field
                style={{ zIndex: 1003 }}
                name='shoppingCenterType'
                component={FormComboBox}
                data={data}
                label={localizationService.toLanguageString(SHOP_TYPE, '')}
                onChange={({ value }: ComboBoxChangeEvent) => {
                  if (value === null) {
                    return;
                  }
                  formRenderProps.onChange('shoppingCenterType', {
                    value: value.type
                  });
                }}
                defaultValue={selectedType}
                placeholder={localizationService.toLanguageString(
                  ENT_TYPE_PLACEHOLDER,
                  ''
                )}
                textField='label'
                validator={StringValidator}
              />

              <Field
                onChange={({ value }: ComboBoxChangeEvent) => {
                  if (value === null) {
                    return;
                  }
                  formRenderProps.onChange('firmId', {
                    value: value.firmId
                  });
                }}
                placeholder={localizationService.toLanguageString(
                  OWNER_PLACEHOLDER,
                  ''
                )}
                id='firmName'
                name='firmId'
                label={localizationService.toLanguageString(SHOP_OWNER, '')}
                component={FormComboBox}
                textField='firmName'
                defaultValue={selectedFirm}
                data={availableFirms}
                validator={StringValidator}
              />

              <Field
                name='shoppingCenterAddress'
                component={FormInput}
                label={localizationService.toLanguageString(SHOP_ADDRESS, '')}
              />

              <div className='k-form-buttons'>
                <button
                  type='submit'
                  className='k-button k-primary'
                  disabled={!formRenderProps.allowSubmit}
                >
                  {localizationService.toLanguageString(UPDATE, '')}
                </button>
                <button type='submit' className='k-button' onClick={cancelEdit}>
                  {localizationService.toLanguageString(DECLINE, '')}
                </button>
              </div>
            </FormElement>
          )}
      />
    </Dialog>
  );
};

export default ShoppingCenterEditForm;
