import { Button } from '@progress/kendo-react-buttons';
import { Field, Form, FormElement, FormSubmitClickEvent } from '@progress/kendo-react-form';
import { useLocalization } from '@progress/kendo-react-intl';
// import { useEffect, useState } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';

import { useAppDispatch } from '../../app/StoreHooks';
import CattleBreeds from '../../features/FarmAnimalTableCattle/cattleBreeds';
import SheepBreeds from '../../features/FarmAnimalTableSheep/sheepBreeds';
import { CATTLE_TYPE, FEMALE, FORWARD, MALE, SHEEP_TYPE } from '../../languages/languages';
import { AnimalSexEnum, AnimalTypeEnum } from '../../slices/data/AnimalSliceData';
import { IRegistrationData } from '../../slices/data/MovementSliceData';
import { INomenclatureData, INomenclatureResponceData, NomenclatureNames } from '../../slices/data/NomenclatureData';
import { getNomenclatureValues } from '../../slices/NomenclatureSlice';
import { FormDatePicker, FormDropDownList, FormInput, FormRadioGroup } from '../FormComponents/FormComponents';

// import { NumberValidator, StringValidator } from '../Validators/Validators';

const RegistrationGroupData = (
  { onNext, model }: { onNext: (value: any) => void, model: IRegistrationData }
) => {
  const dispatch = useAppDispatch();
  const cattleBreeds = [];
  const sheepBreeds = [];
  const localizationService = useLocalization();
  const [animalsType, setAnimalType] = useState(AnimalTypeEnum.CATTLE);
  const [modelValue, setModelValue] = useState({ ...model });

  const onAnimalsTypeChange = (e: any) => {
    setAnimalType(e.value);
    setModelValue({
      ...modelValue,
      animalsType: e.value
    });
  };

  const animalTypes = [
    {
      value: AnimalTypeEnum.CATTLE,
      label: localizationService.toLanguageString(CATTLE_TYPE, '')
    },
    {
      value: AnimalTypeEnum.SHEEP,
      label: localizationService.toLanguageString(SHEEP_TYPE, '')
    }
  ];

  const animalSex = [
    {
      value: AnimalSexEnum.FEMALE,
      label: localizationService.toLanguageString(FEMALE, '')
    },
    {
      value: AnimalSexEnum.MALE,
      label: localizationService.toLanguageString(MALE, '')
    }
  ];

  const acquisitionTypes = [
    {
      value: 'unkown',
      label: 'Неустановено'
    },
    {
      value: 'purchase',
      label: 'Покупка'
    },
    {
      value: 'purchase|trace',
      label: 'Покупка | Trace |'
    },
    {
      value: 'ownProduction',
      label: 'Собствено производство'
    }
  ];

  const idPrefixes = [
    {
      countryEn: 'Bulgaria',
      country: 'България',
      prefix: 'BG',
      priority: 0
    },
    {
      countryEn: 'France',
      country: 'Франция',
      prefix: 'FR',
      priority: 1
    },
    {
      countryEn: 'Germany',
      country: 'Германия',
      prefix: 'DE',
      priority: 1
    },
    {
      countryEn: 'Ireland',
      country: 'Ирландия',
      prefix: 'IE',
      priority: 1
    },
    {
      countryEn: 'Romania',
      country: 'Румъния',
      prefix: 'RO',
      priority: 1
    },
    {
      countryEn: 'United Kingdom',
      country: 'Обединено кралство',
      prefix: 'UK',
      priority: 1
    },
    {
      countryEn: 'Czechia',
      country: 'Чехия',
      prefix: 'CZ',
      priority: 2
    },
    {
      countryEn: 'Denmark',
      country: 'Дания',
      prefix: 'DK',
      priority: 2
    },
    {
      countryEn: 'Austria',
      country: 'Австрия',
      prefix: 'AT',
      priority: 2
    },
    {
      countryEn: 'Belgium',
      country: 'Белгия',
      prefix: 'BE',
      priority: 2
    },
    {
      countryEn: 'Cyprus',
      country: 'Кипър',
      prefix: 'CY',
      priority: 2
    },
    {
      countryEn: 'Estonia',
      country: 'Естония',
      prefix: 'EE',
      priority: 2
    },
    {
      countryEn: 'Finland',
      country: 'Финландия',
      prefix: 'FI',
      priority: 2
    },
    {
      countryEn: 'Greece',
      country: 'Гърция',
      prefix: 'EL',
      priority: 2
    },
    {
      countryEn: 'Hungary',
      country: 'Унгария',
      prefix: 'HU',
      priority: 2
    },
    {
      countryEn: 'Italy',
      country: 'Италия',
      prefix: 'IT',
      priority: 2
    },
    {
      countryEn: 'Latvia',
      country: 'Латвия',
      prefix: 'LV',
      priority: 2
    },
    {
      countryEn: 'Lithuania',
      country: 'Литва',
      prefix: 'LT',
      priority: 2
    },
    {
      countryEn: 'Luxembourg',
      country: 'Люксембург',
      prefix: 'LU',
      priority: 2
    },
    {
      countryEn: 'Malta',
      country: 'Малта',
      prefix: 'MT',
      priority: 2
    },
    {
      countryEn: 'Netherlands',
      country: 'Нидерландия',
      prefix: 'NL',
      priority: 2
    },
    {
      countryEn: 'Poland',
      country: 'Полша',
      prefix: 'PL',
      priority: 2
    },
    {
      countryEn: 'Portugal',
      country: 'Португалия',
      prefix: 'PT',
      priority: 2
    },
    {
      countryEn: 'Slovakia',
      country: 'Словакия',
      prefix: 'SK',
      priority: 2
    },
    {
      countryEn: 'Slovenia',
      country: 'Словения',
      prefix: 'SI',
      priority: 2
    },
    {
      countryEn: 'Spain',
      country: 'Испания',
      prefix: 'ES',
      priority: 2
    },
    {
      countryEn: 'Sweden',
      country: 'Швеция',
      prefix: 'SE',
      priority: 2
    }
  ];

  Object.keys(CattleBreeds).forEach((val) => {
    cattleBreeds.push({
      value: val,
      label: CattleBreeds[val]
    });
  });

  Object.keys(SheepBreeds).forEach((val) => {
    sheepBreeds.push({
      value: val,
      label: SheepBreeds[val]
    });
  });

  const [colors, setColors] = useState([] as INomenclatureData[]);

  useEffect(() => {
    dispatch(getNomenclatureValues(NomenclatureNames.cattleColor)).then((response) => {
      setColors((response.payload as INomenclatureResponceData).values);
    });
  }, []);

  const onSubmitClick = (data: FormSubmitClickEvent) => {
    onNext(data.values);
  };

  const onChange = (e: Event) => {
    console.log('Change:', e);
  };

  return (
    <Form
      initialValues={model}
      onSubmitClick={onSubmitClick}
      render={() =>
      (
        <FormElement>
          <div className='mb-3'>
            <div>
              <div className='h4'>Вид животни</div>
            </div>
            <Field
              id='animalsType'
              name='animalsType'
              label='Вид животни'
              component={FormRadioGroup}
              format='n0'
              onChange={onAnimalsTypeChange}
              layout='horizontal'
              data={animalTypes}
            />
          </div>
          <div>
            <div>
              <div className='h4'>Данни за групово вписване</div>
              <p>
                Въведете данни за групата, която вписвате. При вписване на над 10 животни
                с различни белези, направете еднородни групи за удобство!
              </p>
            </div>
            <fieldset className='k-form-fieldset row'>
              <div className='col-12 col-md-4 col-xl-2 pl-s pr-s'>
                <Field
                  id='animalsCount'
                  name='animalsCount'
                  label='Брой животни'
                  component={FormInput}
                  format='n0'
                  onChange={onChange}
                />
              </div>
              <div className='col-12 col-md-4 col-xl-2 pl-s pr-s'>
                <Field
                  id='age'
                  name='age'
                  label='Възраст'
                  component={FormInput}
                  onChange={onChange}
                />
              </div>
              <div className='col-12 col-md-4 col-xl-2 pl-s pr-s'>
                <Field
                  id='gender'
                  name='gender'
                  label='Пол на животните'
                  component={FormDropDownList}
                  data={animalSex}
                  onChange={onChange}
                  textField='label'
                  dataItemKey='value'
                />
              </div>
              <div className='col-12 col-md-4 col-xl-2 pl-s pr-s'>
                <Field
                  id='acquisitionDate'
                  name='acquisitionDate'
                  label='Дата на придобиване'
                  component={FormDatePicker}
                  onChange={onChange}
                  format='dd-MM-yyyy'
                />
              </div>
              <div className='col-12 col-md-4 col-xl-2 pl-s pr-s'>
                <Field
                  id='acquisitionType'
                  name='acquisitionType'
                  label='Вид придобиване'
                  component={FormDropDownList}
                  data={acquisitionTypes}
                  onChange={onChange}
                  textField='label'
                  dataItemKey='value'
                />
              </div>
            </fieldset>
          </div>
          <div>
            <div>
              <div className='h4'>Данни за идентификация</div>
              <p>
                За ваше удобство, въведете най-малкия номер от последователни такива
              </p>
            </div>
            <fieldset className='k-form-fieldset row'>
              <div className='col-12 col-md-4 col-xl-2 pl-s pr-s'>
                <Field
                  id='prefix'
                  name='prefix'
                  label='Префикс'
                  component={FormDropDownList}
                  data={idPrefixes}
                  onChange={onChange}
                  textField='prefix'
                  dataItemKey='prefix'
                />
              </div>
              <div className='col-12 col-md-4 col-xl-2 pl-s pr-s'>
                <Field
                  id='idNumber'
                  name='idNumber'
                  label='ID Номер'
                  component={FormInput}
                  format='n0'
                  onChange={onChange}
                />
              </div>
            </fieldset>
          </div>
          <div>
            <div>
              <div className='h4'>Данни за порода и фенотип</div>
              <p>
                Посочете преобладаваща порода и цвят
              </p>
            </div>
            <fieldset className='k-form-fieldset row'>
              <div className='col-12 col-md-4 col-xl-2 pl-s pr-s'>
                {animalsType === AnimalTypeEnum.CATTLE
                  ? (
                    <Field
                      id='cattleBreed'
                      name='cattleBreed'
                      label='Порода говедо'
                      component={FormDropDownList}
                      data={cattleBreeds}
                      textField='label'
                      dataItemKey='value'
                    />
                  )
                  : (
                    <Field
                      id='sheepBreed'
                      name='sheepBreed'
                      label='Порода овце'
                      component={FormDropDownList}
                      data={sheepBreeds}
                      textField='label'
                      dataItemKey='value'
                    />
                  )}
              </div>
              <div className='col-12 col-md-4 col-xl-2 pl-s pr-s'>
                <Field
                  id='color'
                  name='color'
                  label='Цвят'
                  component={FormDropDownList}
                  data={colors}
                  onChange={onChange}
                  textField='text'
                  dataItemKey='id'
                />
              </div>
            </fieldset>
          </div>
          <hr />
          <div className='d-flex justify-content-center mb-l align-items-center'>
            <div className='mr-l' style={{ height: 'fit-content' }}>
              Стъпка 1 от 3
            </div>
            <Button
              name='RegistrationGroupDataAdd'
              type='submit'
              title={localizationService.toLanguageString(FORWARD, '')}
              primary
              iconClass='fa fa-share'
            >
              {localizationService.toLanguageString(FORWARD, '')}
            </Button>
          </div>
          <hr />
        </FormElement>
      )}
    />
  );
};

export default RegistrationGroupData;
