import React from 'react';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import VeterinaryInspectionForm from '../../components/VeterinaryInspectionForm/VeterinaryInspectionForm';
import { selectSelectedAnimals, vetInspection } from '../../slices/AnimalSlice';
import { InspectedAnimalType } from '../../slices/data/AnimalSliceData';

type VeterinaryInspectionContainerProps = {
  close: () => void;
};

const VeterinaryInspectionContainer = ({
  close
}: VeterinaryInspectionContainerProps) => {
  const dispatch = useAppDispatch();
  const selectedAnimals = useAppSelector(selectSelectedAnimals);

  const onSubmit = (data: InspectedAnimalType) => {
    const animalObj = selectedAnimals.map((animal) =>
      ({
        inspectorName: data?.inspectorName,
        inspectionNote: data?.inspectionNote,
        inspectionDate: data?.inspectionDate,
        animalId: animal.animalId,
        inspectionResult: data?.inspectionResult
      }));
    dispatch(vetInspection(animalObj));
    close();
  };

  return (
    <>
      <VeterinaryInspectionForm setInspectionData={onSubmit} />
    </>
  );
};

export default VeterinaryInspectionContainer;
