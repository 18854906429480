/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/StoreHooks';
import {
  EDIT_PEST_CONTROL_LOG_ENTRY,
  GetAllPestControlLogsType,
  GET_ALL_PEST_CONTROL_LOGS,
  PestControlLogInputType,
  PestControlLogType,
  SavePestControlMutationType,
  SAVE_PEST_CONTROL_LOG_ENTRY
} from './data/PestControlSliceData';
import {
  createAsyncThunkGeneric,
  createAsyncThunkGenericVoid
} from './GenericThunks';

type PestControlLogStateType = {
  pestControlLogs: PestControlLogType[];
  pestControlLogsLoading: boolean;
};

const initialState: PestControlLogStateType = {
  pestControlLogs: [],
  pestControlLogsLoading: false
};

// Thunks
export const getAllPestControlLogs = createAsyncThunkGenericVoid(
  'pestControlLog/getAllPestControlLogs',
  async ({ client }) => {
    const response = await client.query<void, GetAllPestControlLogsType>(
      'pestControlDiary',
      GET_ALL_PEST_CONTROL_LOGS
    );
    return response?.items ? response?.items : [];
  }
);

export const savePestControlLogEntry = createAsyncThunkGeneric<
PestControlLogInputType,
PestControlLogType
>('pestControlLog/savePestControlLogEntry', async ({ client, input }) =>
  client.mutate<SavePestControlMutationType, PestControlLogType>(
    'addPestControlDiaryType',
    SAVE_PEST_CONTROL_LOG_ENTRY,
    { pestControlDiaryInputType: input }
  ));

export const editPestControlLogEntry = createAsyncThunkGeneric<
PestControlLogInputType,
PestControlLogType
>('pestControlLog/editPestControlLogEntry', async ({ client, input }) =>
  client.mutate<SavePestControlMutationType, PestControlLogType>(
    'updatePestControlDiaryType',
    EDIT_PEST_CONTROL_LOG_ENTRY,
    { pestControlDiaryInputType: input }
  ));

export const PestControlLogSlice = createSlice({
  name: 'pestControlLog',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllPestControlLogs.pending, (state) => {
        state.pestControlLogsLoading = true;
      })
      .addCase(getAllPestControlLogs.fulfilled, (state, { payload }) => {
        state.pestControlLogsLoading = false;
        state.pestControlLogs = payload.map((item) =>
          ({
            ...item,
            controlDate: new Date(item?.controlDate)
          }));
      })
      .addCase(savePestControlLogEntry.pending, (state) => {
        state.pestControlLogsLoading = true;
      })
      .addCase(savePestControlLogEntry.fulfilled, (state, { payload }) => {
        state.pestControlLogsLoading = false;
        state.pestControlLogs = [
          ...state.pestControlLogs,
          {
            ...payload,
            controlDate: new Date(payload?.controlDate)
          }
        ];
      })
      .addCase(editPestControlLogEntry.pending, (state) => {
        state.pestControlLogsLoading = true;
      })
      .addCase(editPestControlLogEntry.fulfilled, (state, { payload }) => {
        state.pestControlLogsLoading = false;
        const updInx = state.pestControlLogs.findIndex((item) =>
          item.pestControlDiaryId === payload.pestControlDiaryId);
        state.pestControlLogs[updInx] = {
          ...payload,
          controlDate: new Date(payload?.controlDate)
        };
      });
  }
});

// Selectors
export const selectPestControlLogs = (state: RootState) =>
  state.pestControlLog.pestControlLogs;
export const selectPestControlLoadingState = (state: RootState) =>
  state.pestControlLog.pestControlLogsLoading;

// Reducer
export default PestControlLogSlice.reducer;
