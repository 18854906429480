import React from 'react';
import { State, process } from '@progress/kendo-data-query';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent
} from '@progress/kendo-react-grid';
import { useLocalization } from '@progress/kendo-react-intl';
import { useAppSelector } from '../../app/StoreHooks';
import {
  STATUS,
  AO_NAME,
  AO_NUMBER,
  AO_TRACES,
  AO_CITY,
  AO_ADDRESS
} from '../../languages/languages';
import ColumnMenu from '../ColumnMenu/ColumnMenu';
import { BooleanCell } from '../FormComponents/FormComponents';
import { AnimalObjectType } from '../../slices/data/AnimalObjectSliceData';
import { selectLoadingState } from '../../slices/AnimalObjectSlice';
import GridLoaderPanel from '../GridLoaderPanel/GridLoaderPanel';

type AnimalObjectGridProps = {
  dataState: State;
  dataStateChange: (event: GridDataStateChangeEvent) => void;
  data: AnimalObjectType[];
  cell: (props: GridCellProps) => any;
};

const AnimalObjectGrid = ({
  dataState,
  dataStateChange,
  data,
  cell
}: AnimalObjectGridProps) => {
  const localizationService = useLocalization();
  const loadingState = useAppSelector(selectLoadingState);
  return (
    <>
      <Grid
        {...dataState}
        onDataStateChange={dataStateChange}
        sortable
        pageable
        data={process(data, dataState)}
      >
        <Column
          field='active'
          title={localizationService.toLanguageString(STATUS, '')}
          cell={BooleanCell}
          filter='boolean'
          columnMenu={ColumnMenu}
          width={150}
        />
        <Column
          field='animalObjectName'
          filter='text'
          columnMenu={ColumnMenu}
          title={localizationService.toLanguageString(AO_NAME, '')}
        />
        <Column
          field='animalObjectNumber'
          filter='numeric'
          columnMenu={ColumnMenu}
          title={localizationService.toLanguageString(AO_NUMBER, '')}
        />
        <Column
          field='animalObjectNumberTraces'
          columnMenu={ColumnMenu}
          filter='numeric'
          title={localizationService.toLanguageString(AO_TRACES, '')}
        />
        <Column
          field='animalObjectCity'
          filter='text'
          columnMenu={ColumnMenu}
          title={localizationService.toLanguageString(AO_CITY, '')}
        />
        <Column
          field='animalObjectAddress'
          filter='text'
          columnMenu={ColumnMenu}
          title={localizationService.toLanguageString(AO_ADDRESS, '')}
        />
        <Column cell={cell} width={120} />
      </Grid>
      {loadingState && <GridLoaderPanel />}
    </>
  );
};

export default AnimalObjectGrid;
