// TODO Put this in .env files
export default {
  domain: 'blbtrace.eu.auth0.com',
  clientId: 'CaM29QReXCfOR22Nou2dFlLaBfWTA3Wv',
  callbackUrl:
    process.env.NODE_ENV === 'production'
      ? 'https://trace.rodeo.bg/dashboard'
      : 'http://localhost:3000/dashboard',
  audience: 'https://localhost:44385/graphql',
  apiUrl: process.env.NODE_ENV === 'production'
    ? 'https://apitrace.rodeo.bg/graphql'
    : 'https://localhost:44385/graphql',
};
