import { Button } from '@progress/kendo-react-buttons';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import {
  Field,
  Form,
  FormElement,
  FormRenderProps
} from '@progress/kendo-react-form';
import { useLocalization } from '@progress/kendo-react-intl';
import React from 'react';
import { useAppSelector } from '../../app/StoreHooks';
import {
  SAVE,
  CANCEL,
  DAYS,
  MEDICINE_CATEGORY_NAME,
  PURCHASED_MEDICINE_AMOUNT,
  PURCHASED_MEDICINE_BATCH_NUMBER,
  PURCHASED_MEDICINE_DELIVERED_BY,
  PURCHASED_MEDICINE_EXPIRY_DATE,
  PURCHASED_MEDICINE_LOG_ENTRY_DATE,
  PURCHASED_MEDICINE_NAME,
  PURCHASED_MEDICINE_NOTE,
  PURCHASED_MEDICINE_WITHDRAW_PERIOD_MEAT,
  PURCHASED_MEDICINE_WITHDRAW_PERIOD_MILK
} from '../../languages/languages';
import {
  medicineCategories,
  PurchasedMedicineType,
  SaveMedicineLogType
} from '../../slices/data/PurchasedMedicinesSliceData';
import { selectSupplierFirms } from '../../slices/FirmsAddressBookSlice';
import {
  FormComboBox,
  FormDatePicker,
  FormInput,
  FormNumericTextBox,
  FormTextArea
} from '../FormComponents/FormComponents';

type PurchasedMedicineFormProps = {
  close: () => void;
  editItem?: PurchasedMedicineType;
  submitForm: (formData: PurchasedMedicineType | SaveMedicineLogType) => void;
};

const PurchasedMedicineForm = ({
  close,
  editItem,
  submitForm
}: PurchasedMedicineFormProps) => {
  const supplierFirms = useAppSelector(selectSupplierFirms);
  const localizationService = useLocalization();

  const selectedSupplierFirm = supplierFirms.find(
    (firm) =>
      firm?.firmABId === editItem?.firm?.firmABId
  );

  const selectedCategory = medicineCategories.find(
    (categ) =>
      categ?.value === editItem?.medicineCategory
  );

  return (
    <Form
      initialValues={editItem}
      onSubmit={submitForm}
      render={(formRenderProps: FormRenderProps) =>
        (
          <FormElement className='two-column-grid'>
            <fieldset className='k-form-fieldset'>
              <Field
                name='purchaseDate'
                component={FormDatePicker}
                format='dd-MM-yyyy'
                label={localizationService.toLanguageString(
                  PURCHASED_MEDICINE_LOG_ENTRY_DATE,
                  ''
                )}
              />
              <Field
                name='medicineName'
                component={FormInput}
                label={localizationService.toLanguageString(
                  PURCHASED_MEDICINE_NAME,
                  ''
                )}
              />
              <Field
                name='medicineCategory'
                component={FormComboBox}
                data={medicineCategories.map((category) =>
                  ({
                    label: localizationService.toLanguageString(category?.label, ''),
                    value: category?.value
                  }))}
                defaultValue={{
                  label: localizationService.toLanguageString(selectedCategory?.label, ''),
                  value: selectedCategory?.value
                }}
                textField='label'
                label={localizationService.toLanguageString(
                  MEDICINE_CATEGORY_NAME,
                  ''
                )}
                onChange={({ value }: ComboBoxChangeEvent) => {
                  if (value === null) {
                    return;
                  }
                  formRenderProps.onChange('medicineCategory', {
                    value: value.value
                  });
                }}
              />
              <Field
                name='quantity'
                component={FormNumericTextBox}
                label={localizationService.toLanguageString(
                  PURCHASED_MEDICINE_AMOUNT,
                  ''
                )}
              />
              <Field
                name='lotNumber'
                component={FormInput}
                label={localizationService.toLanguageString(
                  PURCHASED_MEDICINE_BATCH_NUMBER,
                  ''
                )}
              />
              <Field
                name='firmABId'
                data={supplierFirms}
                defaultValue={selectedSupplierFirm}
                textField='firmABName'
                component={FormComboBox}
                label={localizationService.toLanguageString(
                  PURCHASED_MEDICINE_DELIVERED_BY,
                  ''
                )}
                onChange={({ value }: ComboBoxChangeEvent) => {
                  if (value === null) {
                    return;
                  }
                  formRenderProps.onChange('firmABId', { value: value.firmABId });
                }}
              />
            </fieldset>
            <fieldset className='k-form-fieldset'>
              <Field
                name='withdrawalPeriodMeat'
                placeholder={localizationService.toLanguageString(DAYS, '')}
                component={FormNumericTextBox}
                label={localizationService.toLanguageString(
                  PURCHASED_MEDICINE_WITHDRAW_PERIOD_MEAT,
                  ''
                )}
              />
              <Field
                name='withdrawalPeriodMilk'
                placeholder={localizationService.toLanguageString(DAYS, '')}
                component={FormNumericTextBox}
                label={localizationService.toLanguageString(
                  PURCHASED_MEDICINE_WITHDRAW_PERIOD_MILK,
                  ''
                )}
              />
              <Field
                name='expiryDate'
                component={FormDatePicker}
                format='dd-MM-yyyy'
                label={localizationService.toLanguageString(
                  PURCHASED_MEDICINE_EXPIRY_DATE,
                  ''
                )}
              />
              <Field
                name='comment'
                component={FormTextArea}
                label={localizationService.toLanguageString(
                  PURCHASED_MEDICINE_NOTE,
                  ''
                )}
              />
            </fieldset>
            <div className='k-form-buttons'>
              <Button
                name='Add new'
                type='submit'
                title={localizationService.toLanguageString(SAVE, '')}
                primary
                disabled={!formRenderProps.allowSubmit}
                icon='save'
              >
                {localizationService.toLanguageString(SAVE, '')}
              </Button>
              <Button
                name='Cancel'
                type='submit'
                title='Откажи'
                primary
                look='outline'
                icon='cancel'
                onClick={close}
              >
                {localizationService.toLanguageString(CANCEL, '')}
              </Button>
            </div>
          </FormElement>
        )}
    />
  );
};

PurchasedMedicineForm.defaultProps = {
  editItem: {}
};

export default PurchasedMedicineForm;
