import { State } from '@progress/kendo-data-query';
import { getter } from '@progress/kendo-react-common';
import { GridDataStateChangeEvent, GridHeaderSelectionChangeEvent, GridSelectionChangeEvent } from '@progress/kendo-react-grid';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';

import butcheryService from '../../services/butchery.service';
import { ColumnType } from '../../slices/data/AnimalColumnsSliceData';
import { AnimalType } from '../../slices/data/AnimalSliceData';
import { AnimalInButcheryGridModel } from '../../slices/data/ButcheryProcessSliceData';
import { CattleBreedCell } from '../CustomGridCells/CustomGridCells';
import AnimalTable from '../SelectableGrid/SelectableGrid';

const DATA_ITEM_KEY: string = 'animalId';
const idGetter = getter(DATA_ITEM_KEY);

interface AnimalGridProps {
  onSelect?: (items: AnimalInButcheryGridModel[]) => void;
  enterpriseId: number;
}

export type RefHandle = {
  reload: () => void;
};

const cattleColumns: ColumnType[] = [
  {
    title: 'ВЕТИС Марка №',
    field: 'lID',
    show: true,
    filter: 'text',
  } as ColumnType,
  {
    title: 'Възраст (м)',
    field: 'age',
    show: true,
    filter: 'numeric',
  } as ColumnType,
  {
    title: 'Пол',
    field: 'sex',
    show: true,
    filter: 'text',
  } as ColumnType,
  {
    title: 'Порода',
    field: 'cattleBreed',
    show: true,
    filter: 'text',
  } as ColumnType,
  {
    title: 'Дата на пристигане',
    field: 'arrivalDate',
    show: true,
    filter: 'date',
    format: '{0:dd.MM.yyy}',
  } as ColumnType,
  {
    title: 'Ишлеме',
    field: 'processingAsService',
    show: true,
    filter: 'boolean',
    cell: null,
  } as ColumnType,
] as ColumnType[];

const AnimalsForButcheryGrid = forwardRef<RefHandle, AnimalGridProps>(
  ({ onSelect = undefined, enterpriseId }: AnimalGridProps, ref) => {
    const [animals, setAnimals] = useState<AnimalInButcheryGridModel[]>([]);
    const loadData = () => {
      butcheryService.getAnimmalsInButcheryObject(enterpriseId).then((items) => {
        setAnimals(items.map((item) => new AnimalInButcheryGridModel(item)));
      });
    };

    // Grid States
    const [dataState, setDataState] = useState<State>({
      take: 10,
      skip: 0,
    });

    const dataStateChange = (e: GridDataStateChangeEvent) => {
      setDataState(e.dataState);
    };

    // Select animals
    // Local state for the grid's checkbox that will indicate
    // the state of the checkbox
    const [selectedState, setSelectedState] = useState<{
      [id: string]: boolean | number[];
    }>({});

    // Local state for the selected animals, that will be previewed in
    // the next step of the logic.
    const [selectedAnimals, setSelectedAnimals] = useState<AnimalType[]>([]);
    const [allSelValue, setAllSelValue] = useState<boolean>(false);

    // Check/Uncheck row in the grid
    // Use a callback so that the function is not fully
    // rerendered every time the component re-renders.
    // It should only update if there are changes to the
    // selectedState local state
    const onSelectionChange = useCallback(
      async ({ dataItem, syntheticEvent }: GridSelectionChangeEvent) => {
        // If anything but the checkbox in the grid is selected,
        // return the function so that the component does not crash
        if (!syntheticEvent?.target) {
          return;
        }

        // Get checked status of the checkbox cell
        const checkboxElement: any = syntheticEvent.target;
        const { checked } = checkboxElement;

        // Create a local state for the selected animals,
        // so that the checkbox element is dynamically updated
        const newSelectedState = {
          ...selectedState,
          [idGetter(dataItem)]: checked,
        };

        let updatedSelection = [];
        // If the the element is not checked, filter through
        // the local selectedAnimals state to make sure
        // only selected animals will be inserted for preview
        if (!newSelectedState[idGetter(dataItem)]) {
          updatedSelection = selectedAnimals.filter(
            (animal) => animal.animalId !== dataItem.animalId
          );

          setSelectedAnimals(updatedSelection);
        } else {
          // If the element is checked, add it to the
          // local selectedAnimals state
          updatedSelection = [...selectedAnimals, dataItem];
        }
        // Sets the selected animals for preview
        setSelectedAnimals(updatedSelection);
        if (onSelect) {
          onSelect(updatedSelection);
        }

        setSelectedState(newSelectedState);
      },
      [selectedState]
    );

    // Set all page elements to be checked
    const onHeaderSelectionChange = ({
      dataItems,
      syntheticEvent,
    }: GridHeaderSelectionChangeEvent) => {
      const checkboxElement: any = syntheticEvent.target;
      const { checked } = checkboxElement;
      const newSelectedState = {};
      const selAnimals: any[] = [];
      setAllSelValue(!allSelValue);
      dataItems.forEach(async (animal) => {
        newSelectedState[idGetter(animal)] = checked;
        if (newSelectedState[idGetter(animal)]) {
          selAnimals.push(animal);
        }
      });
      setSelectedState(newSelectedState);
      setSelectedAnimals(selAnimals);
      if (onSelect) {
        onSelect(selAnimals);
      }
    };

    // Handle Menu visibility
    const handleMenuVisibility = () => {
      console.log('menu visibility changed');
    };

    // const togglePreview = () => {
    //   setShowPreview(!showPreview);
    // };

    useImperativeHandle(ref, () => ({
      reload() {
        loadData();
      },
    }));

    useEffect(() => {
      loadData();
    }, []);

    useEffect(() => {
      setSelectedState({});
      setSelectedAnimals([]);
      onSelect([]);
      loadData();
    }, [enterpriseId]);

    return (
      <>
        <AnimalTable
          data={animals}
          dataState={dataState}
          dataStateChange={dataStateChange}
          handleMenuVisibility={handleMenuVisibility}
          onHeaderSelectionChange={onHeaderSelectionChange}
          onSelectionChange={onSelectionChange}
          selectedState={selectedState}
          setDataState={setDataState}
          stateColumns={cattleColumns}
          cell={CattleBreedCell}
          DATA_ITEM_KEY='animalId'
          showToolbar={false}
          showColumns={false}
        />
      </>
    );
  }
);

AnimalsForButcheryGrid.defaultProps = {
  onSelect: undefined,
};

export default AnimalsForButcheryGrid;
