import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import {
  Field,
  Form,
  FormElement,
  FormRenderProps
} from '@progress/kendo-react-form';
import { useLocalization } from '@progress/kendo-react-intl';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { categories, SelfProducedFodderType } from '../../slices/data/FodderLogSliceData';
import {
  FormComboBox,
  FormDatePicker,
  FormInput,
  FormNumericTextBox,
  FormTextArea
} from '../FormComponents/FormComponents';
import {
  CANCEL,
  FODDER_AMOUNT_PRODUCED,
  FODDER_CATEGORY,
  FODDER_DATE,
  FODDER_NAME,
  FODDER_NOTE,
  SAVE
} from '../../languages/languages';
import { StringValidator } from '../Validators/Validators';

type ProducedFodderFormProps = {
  submit: (formData: SelfProducedFodderType) => void;
  editItem?: SelfProducedFodderType;
  close: () => void;
};
const ProducedFodderForm = ({ submit, close, editItem }: ProducedFodderFormProps) => {
  const localizationService = useLocalization();

  const selectedCategory = categories.find((categ) =>
    categ?.value === editItem?.feedCategory);

  return (
    <Form
      initialValues={editItem}
      onSubmit={submit}
      render={(formRenderProps: FormRenderProps) =>
        (
          <FormElement id='fodderForm'>
            <Field
              name='feedDiaryDate'
              format='MM-yyyy'
              component={FormDatePicker}
              label={localizationService.toLanguageString(FODDER_DATE, '')}
              validator={StringValidator}
            />
            <Field
              name='feedName'
              component={FormInput}
              label={localizationService.toLanguageString(FODDER_NAME, '')}
              validator={StringValidator}
            />
            <Field
              name='feedCategory'
              component={FormComboBox}
              data={categories.map((category) =>
                ({
                  label: localizationService.toLanguageString(category?.label, ''),
                  value: category?.value
                }))}
              defaultValue={{
                label: localizationService.toLanguageString(selectedCategory?.label, ''),
                value: selectedCategory?.value
              }}
              textField='label'
              label={localizationService.toLanguageString(
                FODDER_CATEGORY,
                ''
              )}
              onChange={({ value }: ComboBoxChangeEvent) => {
                if (value === null) {
                  return;
                }
                formRenderProps.onChange('feedCategory', { value: value.value });
              }}
              validator={StringValidator}
            />
            <Field
              name='feedQuantity'
              component={FormNumericTextBox}
              label={localizationService.toLanguageString(
                FODDER_AMOUNT_PRODUCED,
                ''
              )}
              validator={StringValidator}
            />
            <Field
              name='feedComment'
              component={FormTextArea}
              label={localizationService.toLanguageString(FODDER_NOTE, '')}
            />
            <div className='k-form-buttons'>
              <Button
                name='ProducedFodderSend'
                type='submit'
                primary
                icon='save'
                title={localizationService.toLanguageString(SAVE, '')}
                disabled={!formRenderProps.allowSubmit}
              >
                {localizationService.toLanguageString(SAVE, '')}
              </Button>
              <Button
                name='ProducedFodderCancel'
                type='submit'
                primary
                look='outline'
                icon='cancel'
                title={localizationService.toLanguageString(CANCEL, '')}
                onClick={close}
              >
                {localizationService.toLanguageString(CANCEL, '')}
              </Button>
            </div>
          </FormElement>
        )}
    />
  );
};

ProducedFodderForm.defaultProps = {
  editItem: {}
};

export default ProducedFodderForm;
