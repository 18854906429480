import { gql } from '@apollo/client';
import {
  ANITIINFLAMMATORY_CATEGORY,
  ANTIBIOTIC_CATEGORY,
  ANTIPARASITIC_CATEGORY,
  CALCIUM_CATEGORY,
  HORMONAL_CATEGORY,
  OINTMENT_CATEGORY,
  OTHER_CATEGORY,
  PROBIOTIC_CATEGORY,
  SUPPLIMENT_CATEGORY,
  VACCINE_CATEGORY,
  VITAMIN_CATEGORY
} from '../../languages/languages';
import { FirmsAddressBookType } from './FirmsAddressBookSliceData';

// Types
enum MedicineCategoryEnum {
  ANTIBIOTIC = 'ANTIBIOTIC',
  VACCINE = 'VACCINE',
  VITAMIN = 'VITAMIN',
  OTHER = 'OTHER',
  CALCIUM = 'CALCIUM',
  PROBIOTIC = 'PROBIOTIC',
  ANITIINFLAMMATORY = 'ANITIINFLAMMATORY',
  ANTIPARASITIC = 'ANTIPARASITIC',
  OINTMENT = 'OINTMENT',
  HORMONAL = 'HORMONAL',
  SUPPLIMENT = 'SUPPLIMENT'
}

export const medicineCategories = [
  { value: MedicineCategoryEnum.ANTIBIOTIC, label: ANTIBIOTIC_CATEGORY },
  { value: MedicineCategoryEnum.VACCINE, label: VACCINE_CATEGORY },
  { value: MedicineCategoryEnum.VITAMIN, label: VITAMIN_CATEGORY },
  { value: MedicineCategoryEnum.OTHER, label: OTHER_CATEGORY },
  { value: MedicineCategoryEnum.CALCIUM, label: CALCIUM_CATEGORY },
  { value: MedicineCategoryEnum.PROBIOTIC, label: PROBIOTIC_CATEGORY },
  {
    value: MedicineCategoryEnum.ANITIINFLAMMATORY,
    label: ANITIINFLAMMATORY_CATEGORY
  },
  { value: MedicineCategoryEnum.ANTIPARASITIC, label: ANTIPARASITIC_CATEGORY },
  { value: MedicineCategoryEnum.OINTMENT, label: OINTMENT_CATEGORY },
  { value: MedicineCategoryEnum.HORMONAL, label: HORMONAL_CATEGORY },
  { value: MedicineCategoryEnum.SUPPLIMENT, label: SUPPLIMENT_CATEGORY }
];

export type PurchasedMedicineType = {
  medicineId: number;
  purchaseDate: Date;
  medicineName: string;
  quantity: number;
  lotNumber: string;
  withdrawalPeriodMeat: number;
  withdrawalPeriodMilk: number;
  expiryDate: Date;
  comment: string;
  medicineCategory: MedicineCategoryEnum;
  firm: FirmsAddressBookType;
};

export type GetPurchasedMedicineType = {
  items: PurchasedMedicineType[];
};

export type SaveMedicineLogType = {
  medicineId?: number;
  purchaseDate: Date;
  medicineName: string;
  quantity: number;
  lotNumber: string;
  withdrawalPeriodMeat: number;
  withdrawalPeriodMilk: number;
  expiryDate: Date;
  comment: string;
  medicineCategory: MedicineCategoryEnum;
  firmABId: number;
};

export type SaveMedicineInputType = {
  medicineDiaryInput: SaveMedicineLogType;
};

// Queries & Mutations
export const GET_PURCHASED_MEDICINE = gql`
  {
    medicineDiary {
      items {
        medicineId
        purchaseDate
        medicineName
        quantity
        lotNumber
        withdrawalPeriodMeat
        withdrawalPeriodMilk
        expiryDate
        comment
        medicineCategory
        firm {
          firmABId
          firmABName
        }
      }
    }
  }
`;

export const SAVE_PURCHASED_MEDICINE = gql`
  mutation AddMedicineDiary($medicineDiaryInput: medicineDiaryInput) {
    addMedicineDiary(medicineDiaryInput: $medicineDiaryInput) {
      medicineId
      purchaseDate
      medicineName
      quantity
      lotNumber
      withdrawalPeriodMeat
      withdrawalPeriodMilk
      expiryDate
      comment
      medicineCategory
      firm {
        firmABId
        firmABName
      }
    }
  }
`;

export const EDIT_PURCHASED_MEDICINE = gql`
  mutation UpdateMedicineDiary($medicineDiaryInput: medicineDiaryInput) {
    updateMedicineDiary(medicineDiaryInput: $medicineDiaryInput) {
      medicineId
      purchaseDate
      medicineName
      quantity
      lotNumber
      withdrawalPeriodMeat
      withdrawalPeriodMilk
      expiryDate
      comment
      medicineCategory
      firm {
        firmABId
        firmABName
      }
    }
  }
`;
