import { gql } from '@apollo/client';

// Types
export type EnterpriseType = {
  enterpriseId: number;
  enterpriseName: string;
  enterpriseNumber: number;
  enterpriseType: string;
  firmId: number;
  enterpriseAddress: string;
  active: boolean;
};

export type EnterpriseResponseType = {
  items: EnterpriseType[];
};

export type EnterpriseInputType = {
  enterprise: EnterpriseType;
};

export const EntTypes = [
  { label: 'Кланница', type: 'BUTCHERY' },
  { label: 'Транжорна', type: 'ABATTOIR' },
  { label: 'Кланница с Транжорна', type: 'ABATTOIR_AND_BUTCHERY' }
];

// GQL Queries and Mutations
export const GET_ENTERPRISES = gql`
  query {
    enterprise {
      items {
        enterpriseId
        enterpriseName
        enterpriseNumber
        enterpriseAddress
        firmId
        active
        enterpriseType
      }
    }
  }
`;

export const SAVE_ENTERPRISE = gql`
  mutation addEnterprise($enterprise: enterpriseInput) {
    addEnterprise(enterprise: $enterprise) {
      enterpriseId
      enterpriseName
      enterpriseNumber
      enterpriseAddress
      firmId
      active
      enterpriseType
    }
  }
`;

export const EDIT_ENTERPRISE = gql`
  mutation updateEnterprise($enterprise: enterpriseInput) {
    updateEnterprise(enterprise: $enterprise) {
      enterpriseId
      enterpriseName
      enterpriseNumber
      enterpriseAddress
      firmId
      active
      enterpriseType
    }
  }
`;
