import React, { useCallback, useState } from 'react';
import {
  getSelectedState,
  GridDataStateChangeEvent,
  GridHeaderSelectionChangeEvent,
  GridItemChangeEvent,
  GridSelectionChangeEvent
} from '@progress/kendo-react-grid';
import { State, getter } from '@progress/kendo-data-query';
import { ReceivedAnimalsType } from '../../slices/data/DealsSliceData';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import {
  selectAcceptedAnimals,
  selectSingleDeal,
  setAcceptedAnimals,
  setReceivedAnimals
} from '../../slices/DealsSlice';
import DealInformationGrid from '../../components/DealInformationGrid/DealInformationGrid';

// Variables for animal selection in the grid
const DATA_ITEM_KEY: string = 'animalId';
const idGetter = getter(DATA_ITEM_KEY);

const DealInformationGridContainer = () => {
  const dispatch = useAppDispatch();

  const deal = useAppSelector(selectSingleDeal);
  const acceptedAnimals = useAppSelector(selectAcceptedAnimals);
  // Grid States
  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0
  });

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  };

  // In-Cell editing functions & cells
  const [editField, setEditField] = useState<string>(undefined);

  const enterEdit = (dataItem: ReceivedAnimalsType, field: string) => {
    const newData = deal?.animalWeights?.map((item) =>
      ({
        ...item,
        inEdit: item?.animalId === dataItem?.animalId ? field : undefined
      }));
    setReceivedAnimals(newData);
    setEditField(field);
  };

  const exitEdit = () => {
    const newData = deal?.animalWeights?.map((item) =>
      ({
        ...item,
        inEdit: undefined
      }));
    dispatch(setReceivedAnimals(newData));
    setEditField(undefined);
  };

  const itemChange = ({ field, value, dataItem }: GridItemChangeEvent) => {
    const selectedField = field || '';
    const newData = deal?.animalWeights?.map((item) => {
      if (item?.animalId === dataItem?.animalId) {
        return {
          ...item,
          [selectedField]:
            selectedField === 'arrivalWeight' ? Number(value) : value
        };
      }
      return item;
    });
    dispatch(setReceivedAnimals(newData));
  };

  // Animal Selection for partial acceptance
  const [selectedState, setSelectedState] = React.useState<{
    [id: string]: boolean | number[];
  }>({});

  // Check/Uncheck row in the grid
  // Use a callback so that the function is not fully
  // rerendered every time the component re-renders.
  // It should only update if there are changes to the
  // selectedState local state
  const onSelectionChange = useCallback(
    (event: GridSelectionChangeEvent) => {
      // If anything but the checkbox in the grid is selected,
      // return the function so that the component does not crash
      if (!event.syntheticEvent?.target) {
        return;
      }

      const newSelectedState = getSelectedState({
        event,
        selectedState,
        dataItemKey: DATA_ITEM_KEY
      });

      // If the the element is not checked, filter through
      // the local selectedAnimals state to make sure
      // only selected animals will be inserted for preview
      if (!newSelectedState[idGetter(event.dataItem)]) {
        const updatedState = acceptedAnimals.filter(
          (id) =>
            id !== event.dataItem.animalId
        );
        // Sets the selected animals for preview
        dispatch(setAcceptedAnimals(updatedState));
      } else {
        // If the element is checked, add it to the
        // local selectedAnimals state
        dispatch(
          setAcceptedAnimals([...acceptedAnimals, event.dataItem.animalId])
        );
      }

      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  const onHeaderSelectionChange = useCallback(
    ({ syntheticEvent, dataItems }: GridHeaderSelectionChangeEvent) => {
      const checkboxElement: any = syntheticEvent.target;
      const { checked } = checkboxElement;
      const newSelectedState = {};

      dataItems.forEach((item) => {
        newSelectedState[idGetter(item)] = checked;
      });
      setSelectedState(newSelectedState);
    },
    []
  );

  return (
    <DealInformationGrid
      deal={deal}
      editField={editField}
      enterEdit={enterEdit}
      exitEdit={exitEdit}
      itemChange={itemChange}
      dataState={dataState}
      dataStateChange={dataStateChange}
      onHeaderSelectionChange={onHeaderSelectionChange}
      onSelectionChange={onSelectionChange}
      selectedState={selectedState}
      setDataState={setDataState}
    />
  );
};

export default DealInformationGridContainer;
