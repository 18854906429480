import { Dialog } from '@progress/kendo-react-dialogs';
import React, { useEffect, useState } from 'react';
import { useLocalization } from '@progress/kendo-react-intl';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import ArtificialInseminationForm from '../../components/ArtificialInseminationForm/ArtificialInseminationForm';
import ArtificialInseminationGrid from '../../components/ArtificialInseminationGrid/ArtificialInseminationGrid';
import { getAnimalsInFarm } from '../../slices/AnimalSlice';
import {
  editArtificialInsemenation,
  getAllArtificialInsementaions,
  saveArtificialInsemenation,
  selectAllArtificialInsementaions
} from '../../slices/ArtificialInseminationSlice';
import {
  AddArtificialInsemenationType,
  ArtificialInsemenationType,
  PregnancyCheckEnum
} from '../../slices/data/ArtificialInseminationSliceData';
import { ADD_NEW, EDIT } from '../../languages/en_US';
import GrantAccess from '../GrantAccess/GrantAccess';

const REQUIRED_ROLES: string[] = ['Admin', 'Farm Manager', 'Holding Owner', 'Farm Veterinary Inspector'];
const UPDATE_ROLES: string[] = ['Admin', 'Farm Manager', 'Farm Veterinary Inspector'];

const ArtificialInseminationContainer = () => {
  const localizationService = useLocalization();
  const dispatch = useAppDispatch();
  const aInseminations = useAppSelector(selectAllArtificialInsementaions);

  useEffect(() => {
    const loadData = async () => {
      dispatch(getAllArtificialInsementaions());
    };
    loadData();
  }, []);

  const [addLogEntryView, setAddLogEntryView] = useState<boolean>(false);

  const openAddEntryView = () => {
    setAddLogEntryView(true);
    dispatch(getAnimalsInFarm());
  };

  const closeAddEntryView = () => {
    setAddLogEntryView(false);
  };

  const submitNewEntry = (formData: ArtificialInsemenationType) => {
    const insemenationData: AddArtificialInsemenationType = {
      animalId: formData.animalId,
      expectedBornDate:
        formData.pregnancyCheck === PregnancyCheckEnum.YES ? new Date(
          new Date().setDate(formData.fertilizationStart.getDate() + 242)
        ) : null,
      fertilization: formData.fertilization,
      fertilizationEnd: formData.fertilizationEnd,
      fertilizationStart: formData.fertilizationStart,
      pregnancyCheck: formData.pregnancyCheck,
      pregnancyCheckDate: formData.pregnancyCheckDate,
      pregnancyState: formData.pregnancyState,
      sireLID: formData.sireLID
    };
    dispatch(saveArtificialInsemenation(insemenationData));
    closeAddEntryView();
  };

  const [editLogEntryView, setLogEditEntryView] = useState<boolean>(false);

  const [editItem, setEditItem] = useState<ArtificialInsemenationType>(null);
  const enterEdit = (item: ArtificialInsemenationType) => {
    setLogEditEntryView(true);
    setEditItem(item);
    dispatch(getAnimalsInFarm());
  };

  const closeEditView = () => {
    setLogEditEntryView(false);
  };

  const submitEditedEntry = (formData: ArtificialInsemenationType) => {
    const editedItem: AddArtificialInsemenationType = {
      aIDiaryId: formData.aIDiaryId,
      animalId: formData.animalId,
      expectedBornDate:
        formData.pregnancyCheck === PregnancyCheckEnum.YES ? new Date(
          new Date().setDate(formData.fertilizationStart.getDate() + 242)
        ) : null,
      fertilization: formData.fertilization,
      fertilizationEnd: formData.fertilizationEnd,
      fertilizationStart: formData.fertilizationStart,
      pregnancyCheck: formData.pregnancyCheck,
      pregnancyCheckDate: formData.pregnancyCheckDate,
      pregnancyState: formData.pregnancyState,
      sireLID: formData.sireLID
    };
    dispatch(editArtificialInsemenation(editedItem));
    closeEditView();
  };

  return (
    <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
      <ArtificialInseminationGrid
        addNew={openAddEntryView}
        data={aInseminations}
        enterEdit={enterEdit}
      />
      <GrantAccess haveRoles='some' requiredRoles={UPDATE_ROLES}>
        {addLogEntryView && (
        <Dialog onClose={closeAddEntryView} title={localizationService.toLanguageString(ADD_NEW, '')}>
          <ArtificialInseminationForm
            submit={submitNewEntry}
            close={closeAddEntryView}
          />
        </Dialog>
        )}
        {editLogEntryView && (
        <Dialog onClose={closeEditView} title={localizationService.toLanguageString(EDIT, '')}>
          <ArtificialInseminationForm
            submit={submitEditedEntry}
            close={closeEditView}
            item={editItem}
          />
        </Dialog>
        )}
      </GrantAccess>
    </GrantAccess>
  );
};

export default ArtificialInseminationContainer;
