/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/StoreHooks';
import {
  EnterpriseInputType,
  EnterpriseResponseType,
  EnterpriseType,
  GET_ENTERPRISES,
  EDIT_ENTERPRISE,
  SAVE_ENTERPRISE
} from './data/EnterpriseSliceData';
import {
  createAsyncThunkGeneric,
  createAsyncThunkGenericVoid
} from './GenericThunks';

type EnterpriseState = {
  enterprises: EnterpriseType[];
  isLoading: boolean;
};

const initialState: EnterpriseState = {
  enterprises: [],
  isLoading: false
};

// Async Thunks
export const getAllEnterprises = createAsyncThunkGenericVoid(
  'enterprises/getAllEnterprises',
  async ({ client }) => {
    const response = await client.query<void, EnterpriseResponseType>(
      'enterprise',
      GET_ENTERPRISES
    );
    return response?.items ? response?.items : [];
  }
);

export const saveEnterprise = createAsyncThunkGeneric<
EnterpriseType,
EnterpriseType
>('enterprises/saveEnterprise', async ({ client, input }) => {
  input.active = true;
  return client.mutate<EnterpriseInputType, EnterpriseType>(
    'addEnterprise',
    SAVE_ENTERPRISE,
    { enterprise: input }
  );
});

export const editEnterprise = createAsyncThunkGeneric<
EnterpriseType,
EnterpriseType
>(
  'enterprises/editEnterprise',
  async ({ client, input }) =>
    client.mutate<EnterpriseInputType, EnterpriseType>(
      'updateEnterprise',
      EDIT_ENTERPRISE,
      { enterprise: input }
    )
);

// Slice
const EnterpriseSlice = createSlice({
  name: 'enterprises',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllEnterprises.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllEnterprises.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.enterprises = payload;
      })
      .addCase(editEnterprise.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editEnterprise.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const updated = state.enterprises.findIndex(
          (enterprise) =>
            enterprise.enterpriseId === payload.enterpriseId
        );
        state.enterprises[updated] = payload;
      })
      .addCase(saveEnterprise.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveEnterprise.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.enterprises.push(payload);
      });
  }
});

// Export actions

// Export selectors
export const selectEnterprises = (state: RootState) =>
  state.enterprises.enterprises;
export const selectEnterpriseLoading = (state: RootState) =>
  state.enterprises.isLoading;

// Export reducer
export default EnterpriseSlice.reducer;
