import React from 'react';
import { useLocalization } from '@progress/kendo-react-intl';
import { Form, FormElement, Field } from '@progress/kendo-react-form';
import { DateTimePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import {
  DateField,
  FormDateTimePicker,
  FormInput,
  FormNumericTextBox
} from '../FormComponents/FormComponents';
import {
  SALE_INFO,
  SELL_FIRM_NAME,
  SELL_FIRM_EIK,
  SELL_FIRM_MOL,
  BUY_FIRM_NAME,
  BUY_FIRM_EIK,
  BUY_FIRM_MOL,
  DEAL_DATE,
  TRANSPORTATION_DATE,
  TRANSPORTATION_INFO,
  FIRM_NAME,
  FIRM_MOL,
  TRANSPORTATION_DRIVER,
  TRANSPORTATION_DRIVER_PIN,
  TRANSPORTATION_VEHICLE_REG,
  TRANSPORTATION_SERIAL_NUMBER,
  ANIMAL_WEIGHT,
  EMPTY_VEHICLE_WEIGHT,
  VEHICLE_WEIGHT,
  PRICE_PER_ANIMAL,
  PRICE_PER_KILO,
  ARRIVAL_DATE,
  DEAL_PRICING,
  DEAL_ANIMAL_PPH,
  DEAL_ANIMAL_PPK
} from '../../languages/languages';
// import { SingleDealType } from '../../slices/data/DealsSliceData';

type DealInformationFormProps = {
  data: any;
  handleDateUpdate: ({ value }: DateTimePickerChangeEvent) => void;
};
const DealInformationForm = ({
  data,
  handleDateUpdate
}: DealInformationFormProps) => {
  const localizationService = useLocalization();

  return (
    <Form
      initialValues={data}
      render={() =>
        (
          <FormElement id='dealInformationDialogGrid'>
            {/* Information about the deal participants and contractors */}
            <legend className='subtitle-1'>
              {localizationService.toLanguageString(SALE_INFO, '')}
            </legend>
            <fieldset className='k-form-fieldset'>
              <Field
                id='sellFirm.firmName'
                name='sellFirm.firmName'
                label={localizationService.toLanguageString(SELL_FIRM_NAME, '')}
                component={FormInput}
                disabled
              />
              <Field
                id='sellFirm.firmEIK'
                name='sellFirm.firmEIK'
                label={localizationService.toLanguageString(SELL_FIRM_EIK, '')}
                component={FormInput}
                disabled
              />
              <Field
                id='sellFirm.firmMOL'
                name='sellFirm.firmMOL'
                label={localizationService.toLanguageString(SELL_FIRM_MOL, '')}
                component={FormInput}
                disabled
              />
              <Field
                id='buyFirm.firmName'
                name='buyFirm.firmName'
                label={localizationService.toLanguageString(BUY_FIRM_NAME, '')}
                component={FormInput}
                disabled
              />
              <Field
                id='buyFirm.firmEIK'
                name='buyFirm.firmEIK'
                label={localizationService.toLanguageString(BUY_FIRM_EIK, '')}
                component={FormInput}
                disabled
              />
              <Field
                id='buyFirm.firmMOL'
                name='buyFirm.firmMOL'
                label={localizationService.toLanguageString(BUY_FIRM_MOL, '')}
                component={FormInput}
                disabled
              />
              <Field
                key='dealDate'
                id='dealDate'
                name='dealDate'
                label={localizationService.toLanguageString(DEAL_DATE, '')}
                component={DateField}
                disabled
              />
            </fieldset>

            {/* Transportation details */}
            <legend className='subtitle-1'>
              {localizationService.toLanguageString(TRANSPORTATION_INFO, '')}
            </legend>
            <fieldset className='k-form-fieldset'>
              <Field
                key='contragent.firm.firmABName'
                id='contragent.firm.firmABName'
                name='contragent.firm.firmABName'
                label={localizationService.toLanguageString(FIRM_NAME, '')}
                component={FormInput}
                disabled
              />
              <Field
                key='contragent.firm.firmABMOL'
                id='contragent.firm.firmABMOL'
                name='contragent.firm.firmABMOL'
                label={localizationService.toLanguageString(FIRM_MOL, '')}
                component={FormInput}
                disabled
              />
              <Field
                key='transportDate'
                id='transportDate'
                name='transportDate'
                label={localizationService.toLanguageString(
                  TRANSPORTATION_DATE,
                  ''
                )}
                component={DateField}
                disabled
              />
              <Field
                key='contragent.contragentName'
                id='contragent.contragentName'
                name='contragent.contragentName'
                label={localizationService.toLanguageString(
                  TRANSPORTATION_DRIVER,
                  ''
                )}
                component={FormInput}
                disabled
              />
              <Field
                key='contragent.contragentPIN'
                id='contragent.contragentPIN'
                name='contragent.contragentPIN'
                label={localizationService.toLanguageString(
                  TRANSPORTATION_DRIVER_PIN,
                  ''
                )}
                component={FormInput}
                disabled
              />
              <Field
                key='truck.truckPlate'
                id='truck.truckPlate'
                name='truck.truckPlate'
                label={localizationService.toLanguageString(
                  TRANSPORTATION_VEHICLE_REG,
                  ''
                )}
                component={FormInput}
                disabled
              />
              <Field
                key='waybillNumber'
                id='waybillNumber'
                name='waybillNumber'
                label={localizationService.toLanguageString(
                  TRANSPORTATION_SERIAL_NUMBER,
                  ''
                )}
                component={FormInput}
                disabled
              />
            </fieldset>

            {/* Animal weighting fields */}
            <legend className='subtitle-1'>
              {localizationService.toLanguageString(ANIMAL_WEIGHT, '')}
            </legend>
            <fieldset className='k-form-fieldset'>
              <Field
                key='vehicleTaraWeight'
                id='vehicleTaraWeight'
                name='vehicleTaraWeight'
                label={localizationService.toLanguageString(
                  EMPTY_VEHICLE_WEIGHT,
                  ''
                )}
                component={FormNumericTextBox}
                disabled
              />
              <Field
                key='vehicleTotalWeight'
                id='vehicleTotalWeight'
                name='vehicleTotalWeight'
                label={localizationService.toLanguageString(VEHICLE_WEIGHT, '')}
                component={FormNumericTextBox}
                disabled
              />
            </fieldset>

            {/* Information about the deal prices */}
            <legend className='subtitle-1'>
              {localizationService.toLanguageString(DEAL_PRICING, '')}
            </legend>
            <fieldset className='k-form-fieldset'>
              <Field
                key='pricePerHead'
                id='pricePerHead'
                name='pricePerHead'
                label={localizationService.toLanguageString(PRICE_PER_ANIMAL, '')}
                title={localizationService.toLanguageString(DEAL_ANIMAL_PPH, '')}
                component={FormNumericTextBox}
                disabled
              />
              <Field
                key='pricePerKilo'
                id='pricePerKilo'
                name='pricePerKilo'
                label={localizationService.toLanguageString(PRICE_PER_KILO, '')}
                title={localizationService.toLanguageString(DEAL_ANIMAL_PPK, '')}
                component={FormNumericTextBox}
                disabled
              />
              <Field
                key='arrivalDate'
                id='arrivalDate'
                name='arrivalDate'
                label={localizationService.toLanguageString(ARRIVAL_DATE, '')}
                format='dd-MMM-yyyy HH:mm'
                component={FormDateTimePicker}
                onChange={handleDateUpdate}
              />
            </fieldset>
          </FormElement>
        )}
    />
  );
};

export default DealInformationForm;
