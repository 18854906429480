import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

interface RouteWithLayoutRouteProps extends RouteProps {
  component: React.ComponentType;
  layout?: React.ComponentType
}
const RouteWithLayout = ({ layout, component, ...rest }: RouteWithLayoutRouteProps) =>
  (
    <Route
      {...rest}
      render={(props) => {
        const finalProps: any = { ...props, ...rest };
        return React.createElement(layout, finalProps, React.createElement(component, finalProps));
      }}
    />
  );

RouteWithLayout.defaultProps = {
  layout: (() =>
    (<></>))
};

export default RouteWithLayout;
