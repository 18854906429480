import React, {
  Dispatch, SetStateAction, useCallback, useState
} from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import {
  Grid,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridHeaderSelectionChangeEvent,
  GridSelectionChangeEvent,
  GridToolbar
} from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { process, State } from '@progress/kendo-data-query';
import { getter } from '@progress/kendo-react-common';
import { useLocalization } from '@progress/kendo-react-intl';
import GridSearchBar from '../GridSearchBar/GridSearchBar';
import ColumnMenu from '../../components/ColumnMenu/ColumnMenu';
import ColumnPickerMenu from '../ColumnPickerMenu/ColumnPickerMenu';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import { AnimalType } from '../../slices/data/AnimalSliceData';
import {
  ACCEPT, ADD_MORE, COLUMNS, REMOVE, CANCEL, SELECTED_ANIMALS_PREVIEW
} from '../../languages/languages';
import { setSelectedAnimals } from '../../slices/AnimalSlice';
import { CattleBreedCell, DateOfBirthCell } from '../../components/CustomGridCells/CustomGridCells';
import { selectCattleColumns } from '../../slices/AnimalColumnsSlice';

type SetStateType = SetStateAction<boolean>;

type AnimalOperationsProps = {
  selectedAnimals: AnimalType[];
  setOriginalSelection: Dispatch<SetStateAction<AnimalType[]>>;
  setCheckboxSelection: React.Dispatch<
  React.SetStateAction<{ [id: string]: boolean | number[] }>
  >;
  checkboxesState: { [id: string]: boolean | number[] };
  showPreview: boolean;
  setShowPreview: Dispatch<SetStateType>;
  setShowOperations: Dispatch<SetStateType>;
};

const DATA_ITEM_KEY: string = 'animalId';
const SELECTED_FIELD: string = 'selected';
const idGetter = getter(DATA_ITEM_KEY);

const AnimalPreview = ({
  selectedAnimals,
  setOriginalSelection,
  setCheckboxSelection,
  checkboxesState,
  showPreview,
  setShowPreview,
  setShowOperations
}: AnimalOperationsProps) => {
  const dispatch = useAppDispatch();

  const cattleColumns = useAppSelector(selectCattleColumns);

  const localizationService = useLocalization();

  // Grid States
  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0
  });
  const [data, setData] = useState<AnimalType[]>(selectedAnimals);

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  };

  // Grid Columns menu and functions
  const [menuVisibility, setMenuVisibility] = useState(false);
  const handleMenuVisibility = () => {
    setMenuVisibility(true);
  };

  // Select animals
  // Local state for the grid's checkbox that will indicate
  // the state of the checkbox
  const [selectedState, setSelectedState] = useState<{
    [id: string]: boolean | number[];
  }>({});

  // Check/Uncheck row in the grid
  // Use a callback so that the function is not fully
  // rerendered every time the component re-renders.
  // It should only update if there are changes to the
  // selectedState local state
  const onSelectionChange = useCallback(
    async ({ dataItem, syntheticEvent }: GridSelectionChangeEvent) => {
      // If anything but the checkbox in the grid is selected,
      // return the function so that the component does not crash
      if (!syntheticEvent?.target) {
        return;
      }

      // Get checked status of the checkbox cell
      const checkboxElement: any = syntheticEvent.target;
      const { checked } = checkboxElement;

      // Update the grid element's status to checked
      const newSelectedState = {
        ...selectedState,
        [idGetter(dataItem)]: checked
      };

      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  const onHeaderSelectionChange = useCallback(
    (event: GridHeaderSelectionChangeEvent) => {
      const checkboxElement: any = event.syntheticEvent.target;
      const { checked } = checkboxElement;
      const newSelectedState = {};

      event.dataItems.forEach((item) => {
        newSelectedState[idGetter(item)] = checked;
      });
      setSelectedState(newSelectedState);
    },
    []
  );

  //   Remove selected animals
  const removeSelection = () => {
    const animalsLeft = [];

    data.forEach((animal) => {
      if (!selectedState[animal.animalId]) {
        animalsLeft.push(animal);
      }
    });

    // Construct a new object with false values for all selected elements
    // that will be passed to the original selection
    // for the checkboxes to be deselected
    const convertedObj = {};

    Object.keys(selectedState).forEach((key) => { convertedObj[key] = false; });

    setData(animalsLeft);
    setOriginalSelection(animalsLeft);
    setCheckboxSelection({ ...checkboxesState, ...convertedObj });
  };

  const handleAccept = () => {
    dispatch(setSelectedAnimals(data));
    setShowPreview(!showPreview);
    setShowOperations(true);
  };
  //   Close dialog
  const handleClose = () => {
    setShowPreview(!showPreview);
  };

  return (
    <Dialog title={localizationService.toLanguageString(SELECTED_ANIMALS_PREVIEW, '')} onClose={handleClose}>
      <Grid
        sortable
        pageable
        {...dataState}
        data={process(
          data.map((item) =>
            ({
              ...item,
              [SELECTED_FIELD]: selectedState[idGetter(item)]
            })),
          dataState
        )}
        onDataStateChange={dataStateChange}
        dataItemKey={DATA_ITEM_KEY}
        selectedField={SELECTED_FIELD}
        selectable={{
          enabled: true,
          drag: false,
          cell: false,
          mode: 'multiple'
        }}
        onSelectionChange={onSelectionChange}
        onHeaderSelectionChange={onHeaderSelectionChange}
        style={{ maxWidth: '1200px' }}
      >
        <GridToolbar>
          <div className='toolbar-section'>
            <GridSearchBar
              dataState={dataState}
              setDataState={setDataState}
              filterBy='dealBatchNumber'
            />
            <Button
              name='AnimalPreviewColumns'
              type='button'
              title={localizationService.toLanguageString(COLUMNS, '')}
              primary
              onClick={handleMenuVisibility}
              icon='align-justify'
              className='ml-s'
            >
              {localizationService.toLanguageString(COLUMNS, '')}
            </Button>
          </div>
          <div className='toolbar-section ml-auto'>
            <Button
              name='AnimalPreviewAdd'
              type='button'
              title={localizationService.toLanguageString(ADD_MORE, '')}
              primary
              icon='plus'
              onClick={handleClose}
            >
              {localizationService.toLanguageString(ADD_MORE, '')}
            </Button>
            <Button
              name='AnimalPreviewRemove'
              type='button'
              title={localizationService.toLanguageString(REMOVE, '')}
              primary
              look='outline'
              icon='delete'
              onClick={removeSelection}
              className='ml-s'
            >
              {localizationService.toLanguageString(REMOVE, '')}
            </Button>
          </div>
        </GridToolbar>
        <Column
          field={SELECTED_FIELD}
          headerSelectionValue={
            data.findIndex((item) =>
              !selectedState[idGetter(item)]) === -1
          }
        />
        {cattleColumns.map((column) => {
          if (column.show && column.field === 'sheepBreed') {
            return (
              <Column
                key={column.field}
                field={column.field}
                title={column.title}
                filter={column.filter}
                format={column.format}
                cell={CattleBreedCell}
                columnMenu={(props) =>
                  <ColumnMenu {...props} />}
              />
            );
          }
          if (column.show && column.field === 'dateOfBirth') {
            return (
              <Column
                key={column.field}
                field={column.field}
                title={column.title}
                filter={column.filter}
                format={column.format}
                cell={DateOfBirthCell}
                columnMenu={(props) =>
                  <ColumnMenu {...props} />}
              />
            );
          }
          if (column.show) {
            return (
              <Column
                key={column.field}
                field={column.field}
                title={column.title}
                filter={column.filter}
                format={column.format}
                columnMenu={(props) =>
                  <ColumnMenu {...props} />}
              />
            );
          }
          return null;
        })}
      </Grid>
      <div className='k-columnmenu-actions'>
        <Button
          name='AnimalPreviewAccept'
          type='button'
          title={localizationService.toLanguageString(ACCEPT, '')}
          primary
          icon='save'
          onClick={handleAccept}
        >
          {localizationService.toLanguageString(ACCEPT, '')}
        </Button>
        <Button
          name='AnimalPreviewCancel'
          type='button'
          title={localizationService.toLanguageString(CANCEL, '')}
          primary
          look='outline'
          icon='cancel'
          onClick={handleClose}
        >
          {localizationService.toLanguageString(CANCEL, '')}
        </Button>
      </div>
      {!menuVisibility ? null : (
        <ColumnPickerMenu
          type='cattle'
          columnSet={cattleColumns}
          setVisibility={setMenuVisibility}
        />
      )}
    </Dialog>
  );
};

export default AnimalPreview;
