import { gql } from '@apollo/client';
import { AnimalType } from './AnimalSliceData';

// Types
export type MortalityLogType = {
  animalHealthEventId: number;
  eventDate: Date;
  animal: AnimalType;
  isDeath: boolean;
  healthProblem: string;
  therapy: string;
};

export type GetMortalityLogs = {
  items: MortalityLogType[];
};

export type MortalityLogInputType = {
  animalHealthEventId?: number;
  healthProblem: string;
  therapy: string;
  eventDate: Date;
  isDeath: boolean;
  animalId: number;
};

export type SaveMortalityLogType = {
  animalHealthEventInput: MortalityLogInputType;
};

// Queries & Mutations
export const GET_ALL_MORTALITY_LOGS = gql`
  {
    animalHealthEvent {
      items {
        animalHealthEventId
        healthProblem
        therapy
        eventDate
        isDeath
        animal {
          animalId
          lID
        }
      }
    }
  }
`;

export const SAVE_MORTALITY_LOG = gql`
  mutation AddAnimalHealthEvent(
    $animalHealthEventInput: animalHealthEventInput
  ) {
    addAnimalHealthEvent(animalHealthEventInput: $animalHealthEventInput) {
      animalHealthEventId
      healthProblem
      therapy
      eventDate
      isDeath
      animal {
        animalId
        lID
      }
    }
  }
`;

export const EDIT_MORTALITY_LOG = gql`
  mutation UpdateAnimalHealthEvent(
    $animalHealthEventInput: animalHealthEventInput
  ) {
    updateAnimalHealthEvent(animalHealthEventInput: $animalHealthEventInput) {
      animalHealthEventId
      healthProblem
      therapy
      eventDate
      isDeath
      animal {
        animalId
        lID
      }
    }
  }
`;
