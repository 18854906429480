import createAuth0Client, { Auth0Client, Auth0ClientOptions } from '@auth0/auth0-spa-js';
/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';

import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import { selectIsAuthenticated, selectLoading, selectPopupOpen, selectUser, setAuthentication, setLoading, setPopupOpen, setUser } from '../../slices/UserSlice';

export interface ContextValue {
  isAuthenticated?: boolean;
  user?: any;
  loading?: boolean;
  popupOpen?: boolean;
  loginWithPopup?: () => void;
  handleRedirectCallback?: () => void;
  getIdTokenClaims?: (...p: any) => void;
  loginWithRedirect?: (...p: any) => void;
  getTokenSilently?: (...p: any) => void;
  getTokensWithPopup?: (...p: any) => void;
  logout?: (...p: any) => void;
}

interface Auth0ProviderProps extends Auth0ClientOptions {
  onRedirectCallback?: (appState: any) => void;
  children: React.ReactNode;
  initOptions?: Auth0ClientOptions;
}

const DEFAULT_REDIRECT_CALLBACK = (appState: any) => window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext<ContextValue>({});
export const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}: Auth0ProviderProps) => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(selectUser);

  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const [cookies, _, removeCookie] = useCookies();

  const loading = useAppSelector(selectLoading);

  const popupOpen = useAppSelector(selectPopupOpen);

  const [auth0Client, setAuth0] = useState<Auth0Client>();
  const [initComplete, setInitComplete] = useState<boolean>(false);

  useEffect(() => {
    const initAuth0 = async () => {
      try {
        const auth0FromHook = await createAuth0Client({...initOptions, authorizeTimeoutInSeconds: 0.5 });
        setAuth0(auth0FromHook);
        if (window.location.search.includes('code=')) {
          const { appState } = await auth0FromHook.handleRedirectCallback();
          onRedirectCallback(appState);
        }
        // Store configuration of the above
        const isAuthenticated = await auth0FromHook.isAuthenticated();
        dispatch(setAuthentication(isAuthenticated));

        if (isAuthenticated) {
          const user = await auth0FromHook.getUser();
          const { permissions } = await getDecodedToken(auth0FromHook);

          dispatch(setUser({
            ...user,
            permissions: permissions || [],
          }));
        }

        dispatch(setLoading(false));
        setInitComplete(true);
      } catch(_) {
        const cookieKey = Object.keys(cookies);
        cookieKey.map(key => removeCookie(key))
        initAuth0();
        window.localStorage.removeItem('theme');
      }

    };
    if (auth0Client === undefined) {
      initAuth0();
    }
  }, []);

  const getDecodedToken = async (client: Auth0Client) => {
    const jwtToken = await client?.getTokenSilently();
    const jwtData = jwtToken.split('.')?.[1];
    localStorage.setItem('access_token', jwtToken);
    const decodedJwtJsonData = window.atob(jwtData);
    const decodedJwtData = JSON.parse(decodedJwtJsonData);
    return decodedJwtData;
  };

  const loginWithPopup = async () => {
    dispatch(setPopupOpen(true));
    try {
      await auth0Client.loginWithPopup();
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(setPopupOpen(false));
    }

    const user = await auth0Client.getUser();
    dispatch(setUser(user));
    dispatch(setAuthentication(true));
  };

  const handleRedirectCallback = async () => {
    dispatch(setLoading(true));

    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    dispatch(setLoading(false));
    dispatch(setAuthentication(true));
    dispatch(setUser(user));
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p: any) => auth0Client?.getIdTokenClaims(...p),
        loginWithRedirect: (...p: any) => auth0Client?.loginWithRedirect(...p),
        getTokenSilently: (...p: any) => auth0Client?.getTokenSilently(...p),
        getTokensWithPopup: (...p: any) => auth0Client?.getTokenWithPopup(...p),
        logout: (...p: any) => auth0Client?.logout(...p),
      }}
    >
      {initComplete && children}
    </Auth0Context.Provider>
  );
};
