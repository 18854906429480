import React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import NonSelectableGrid from '../SelectableGrid/NonSelectableGrid';
import { ColumnType } from '../../slices/data/AnimalColumnsSliceData';

const ItemsApprovalGrid = ({
  data,
  accept,
  reject,
}: {
  data: [];
  accept: (id: number) => void;
  reject: (id: number) => void;
}) => {
  const columns = [
    {
      title: 'Статус',
      field: 'accepted',
      show: true,
      filterable: false,
      width: '100 px',
      cell: ({ dataItem }: GridCellProps) => {
        let iconClass = 'fa fa-';
        if (dataItem.accepted) {
          iconClass += 'thumbs-up text-success';
        } else {
          iconClass += 'thumbs-down text-error';
        }
        return (
          <td className='text-center'>
            <i className={iconClass} />
          </td>
        );
      },
    },
    {
      title: 'Партида №',
      field: 'item.batchNumber',
      show: true,
      filter: 'text',
    },
    {
      title: 'Партида дата',
      field: 'item.batchDate',
      show: true,
      filter: 'date',
      format: '{0:dd.MM.yyy}',
    },
    {
      title: 'Животно №',
      field: 'item.animalLId',
      show: true,
      filter: 'text',
    },
    {
      title: 'SEUROP',
      field: 'item.sEUROP',
      show: true,
      filter: 'text',
    },
    {
      title: 'USDA',
      field: 'item.uSDA',
      show: true,
      filter: 'text',
    },
    {
      title: 'Вид на среза',
      field: 'item.cuttingType',
      show: true,
      filter: 'text',
    },
    {
      title: 'Сечение',
      field: 'item.cutName',
      show: true,
      filter: 'text',
    },
    {
      title: 'Тегло',
      field: 'item.weight',
      show: true,
      filter: 'text',
      format: '{0} кг.',
    },
  ] as ColumnType[];

  const actionCell = ({ dataItem }: GridCellProps) => {
    console.log(dataItem);
    return (
      <td className='text-center'>
        {!dataItem.accepted && (
          <Button
            className='k-secondary'
            type='button'
            title='Приеми'
            look='outline'
            onClick={() => {
              accept(dataItem.item.batchCutId);
            }}
          >
            Приеми
          </Button>
        )}
        {dataItem.accepted && (
          <Button
            className='k-error'
            type='button'
            title='Откажи'
            look='outline'
            onClick={() => {
              reject(dataItem.item.batchCutId);
            }}
          >
            Откажи
          </Button>
        )}
      </td>
    );
  };

  return <NonSelectableGrid data={data} stateColumns={columns} actionCell={actionCell} />;
};

export default ItemsApprovalGrid;
