import { getter } from '@progress/kendo-react-common';
import { GridDataStateChangeEvent, GridSelectionChangeEvent, GridHeaderSelectionChangeEvent } from '@progress/kendo-react-grid';
import { State } from 'history';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocalization } from '@progress/kendo-react-intl';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import AnimalTable from '../../components/AnimalTable/AnimalTable';
import { CattleBreedCell } from '../../components/CustomGridCells/CustomGridCells';
import { selectCattleColumns } from '../../slices/AnimalColumnsSlice';
import {
  getButcheredAnimals,
  selectedButcheredAnimals
} from '../../slices/AnimalSlice';
import { BUTCHERED_ANIMALS_TABLE } from '../../languages/languages';
import { AnimalType } from '../../slices/data/AnimalSliceData';
import AnimalOperations from '../AnimalOperations/AnimalOperations';
import AnimalPreview from '../AnimalPreview/AnimalPreview';
import ColumnPickerMenu from '../ColumnPickerMenu/ColumnPickerMenu';

const DATA_ITEM_KEY: string = 'animalId';
const idGetter = getter(DATA_ITEM_KEY);

const ButcheredAnimalsTableContainer = () => {
  const dispatch = useAppDispatch();
  const butcheredAnimals = useAppSelector(selectedButcheredAnimals);
  const localizationService = useLocalization();

  useEffect(() => {
    const awaitData = () => {
      dispatch(getButcheredAnimals());
    };
    awaitData();
  }, []);

  const cattleColumns = useAppSelector(selectCattleColumns);

  // Grid States
  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0
  });

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  };

  // Grid Columns menu and functions
  const [menuVisibility, setMenuVisibility] = useState(false);
  const handleMenuVisibility = () => {
    setMenuVisibility(true);
  };

  // Select animals
  // Local state for the grid's checkbox that will indicate
  // the state of the checkbox
  const [selectedState, setSelectedState] = useState<{
    [id: string]: boolean | number[];
  }>({});

  // Local state for the selected animals, that will be previewed in
  // the next step of the logic.
  const [selectedAnimals, setSelectedAnimals] = useState<AnimalType[]>([]);
  const [allSelValue, setAllSelValue] = useState<boolean>(false);

  // Check/Uncheck row in the grid
  // Use a callback so that the function is not fully
  // rerendered every time the component re-renders.
  // It should only update if there are changes to the
  // selectedState local state
  const onSelectionChange = useCallback(
    async ({ dataItem, syntheticEvent }: GridSelectionChangeEvent) => {
      // If anything but the checkbox in the grid is selected,
      // return the function so that the component does not crash
      if (!syntheticEvent?.target) {
        return;
      }

      // Get checked status of the checkbox cell
      const checkboxElement: any = syntheticEvent.target;
      const { checked } = checkboxElement;

      // Create a local state for the selected animals,
      // so that the checkbox element is dynamically updated
      const newSelectedState = {
        ...selectedState,
        [idGetter(dataItem)]: checked
      };

      // If the the element is not checked, filter through
      // the local selectedAnimals state to make sure
      // only selected animals will be inserted for preview
      if (!newSelectedState[idGetter(dataItem)]) {
        const updatedState = selectedAnimals.filter(
          (animal) =>
            animal.animalId !== dataItem.animalId
        );
        // Sets the selected animals for preview
        setSelectedAnimals(updatedState);
      } else {
        // If the element is checked, add it to the
        // local selectedAnimals state
        setSelectedAnimals([...selectedAnimals, dataItem]);
      }
      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  // Set all page elements to be checked
  const onHeaderSelectionChange = ({
    dataItems,
    syntheticEvent
  }: GridHeaderSelectionChangeEvent) => {
    const checkboxElement: any = syntheticEvent.target;
    const { checked } = checkboxElement;
    const newSelectedState = {};
    const selAnimals: any[] = [];
    setAllSelValue(!allSelValue);
    dataItems.forEach(async (animal) => {
      newSelectedState[idGetter(animal)] = checked;
      if (newSelectedState[idGetter(animal)]) {
        selAnimals.push(animal);
      }
    });
    setSelectedState(newSelectedState);
    setSelectedAnimals(selAnimals);
  };

  // Handle show/hide selected animals preview
  const [showPreview, setShowPreview] = useState<boolean>(false);

  const togglePreview = () => {
    setShowPreview(!showPreview);
  };

  // Handle show/hide animal operations component visibility
  const [showOperations, setShowOperations] = useState<boolean>(false);

  return (
    <>
      <h1 className='page-title'>{localizationService.toLanguageString(BUTCHERED_ANIMALS_TABLE, '')}</h1>
      <div className='content-wrapper section'>
        <AnimalTable
          data={butcheredAnimals}
          dataState={dataState}
          dataStateChange={dataStateChange}
          handleMenuVisibility={handleMenuVisibility}
          onHeaderSelectionChange={onHeaderSelectionChange}
          onSelectionChange={onSelectionChange}
          selectedState={selectedState}
          setDataState={setDataState}
          stateColumns={cattleColumns}
          togglePreview={togglePreview}
          cell={CattleBreedCell}
        />
        {!menuVisibility ? null : (
          <ColumnPickerMenu
            type='cattle'
            columnSet={cattleColumns}
            setVisibility={setMenuVisibility}
          />
        )}
        {!showPreview ? null : (
          <AnimalPreview
            setOriginalSelection={setSelectedAnimals}
            setCheckboxSelection={setSelectedState}
            checkboxesState={selectedState}
            selectedAnimals={selectedAnimals}
            showPreview={showPreview}
            setShowPreview={setShowPreview}
            setShowOperations={setShowOperations}
          />
        )}
        {showOperations ? (
          <AnimalOperations setVisibility={setShowOperations} holdingType='processor' />
        ) : null}
      </div>
    </>
  );
};

export default ButcheredAnimalsTableContainer;
