import { Button } from '@progress/kendo-react-buttons';
import { MultiSelectChangeEvent } from '@progress/kendo-react-dropdowns';
import {
  Field, Form, FormElement,
  FormRenderProps
} from '@progress/kendo-react-form';
import { useLocalization } from '@progress/kendo-react-intl';
import React from 'react';
import { useAppSelector } from '../../app/StoreHooks';
import {
  ADD, ADDRESS_BOOK_EMAIL, ADDRESS_BOOK_NAME, ADDRESS_BOOK_ROLE, CANCEL
} from '../../languages/languages';
import { EmployeesABType, SaveEmployeeABType } from '../../slices/data/EmployeesSliceData';
import { UserRoleMultiSelectType } from '../../slices/data/UserSliceData';
import { selectUserRoles } from '../../slices/UserSlice';
import { FormInput, FormMultiSelect } from '../FormComponents/FormComponents';
import { emailValidator, StringValidator } from '../Validators/Validators';

type EmployeeFormProps = {
  cancel: () => void;
  item?: EmployeesABType;
  selectedRoles?: UserRoleMultiSelectType[];
  onSubmit: (formData: SaveEmployeeABType) => void;
  setUserRoles: (event: MultiSelectChangeEvent) => void;
};

const EmployeeForm = ({
  cancel, onSubmit, setUserRoles, item, selectedRoles
}: EmployeeFormProps) => {
  const localizationService = useLocalization();
  const mappedRoles: UserRoleMultiSelectType[] = useAppSelector(selectUserRoles).map((role) =>
    ({
      value: role.id,
      label: role.name
    }));

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={item}
      render={(formRenderProps: FormRenderProps) =>
        (
          <FormElement>
            <fieldset className='k-form-fieldset'>
              <Field
                name='userName'
                component={FormInput}
                label={localizationService.toLanguageString(ADDRESS_BOOK_NAME, '')}
                placeholder={localizationService.toLanguageString(ADDRESS_BOOK_NAME, '')}
                validator={StringValidator}
              />
              <Field
                name='email'
                component={FormInput}
                label={localizationService.toLanguageString(ADDRESS_BOOK_EMAIL, '')}
                placeholder='xxx@xx.xx'
                validator={emailValidator}
              />
              <Field
                label={localizationService.toLanguageString(
                  ADDRESS_BOOK_ROLE,
                  ''
                )}
                id='role'
                name='role'
                textField='label'
                data={mappedRoles}
                defaultValue={selectedRoles}
                onChange={setUserRoles}
                component={FormMultiSelect}
              />
            </fieldset>
            <div className='k-form-buttons'>
              <Button
                name='Add new'
                type='submit'
                title='Добави'
                primary
                disabled={!formRenderProps.allowSubmit}
                icon='save'
              >
                {localizationService.toLanguageString(ADD, '')}
              </Button>
              <Button
                name='Cancel'
                type='submit'
                title='Откажи'
                primary
                look='outline'
                icon='cancel'
                onClick={cancel}
              >
                {localizationService.toLanguageString(CANCEL, '')}
              </Button>
            </div>
          </FormElement>
        )}
    />
  );
};

EmployeeForm.defaultProps = {
  item: {},
  selectedRoles: []
};
export default EmployeeForm;
