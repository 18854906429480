import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Loader } from '@progress/kendo-react-indicators';
// import { useState } from '@progress/kendo-react-indicators';
// import moment from 'moment';
import React, { useState } from 'react';

import warehouseService from '../../services/warehouse.service';

// import warehouseService from '../../services/warehouse.service';
// import { ITraderWarehouseGridItem } from '../../slices/data/WarehouseSliceData';

const TraderWarehouseItemUnregister = ({
  batchCutId,
  onClose,
  onUpdated,
}: {
  batchCutId: number;
  onClose: () => void;
  onUpdated: () => void;
}) => {
  const [loading, setLoading] = useState(false);

  const unregister = () => {
    setLoading(true);
    warehouseService
      .unregisterTraderWarehouseItem(batchCutId)
      .then(() => onUpdated())
      .finally(() => setLoading(false));
  };

  return (
    <Dialog title='Отписване на складов артикул' onClose={onClose} minWidth={600}>
      {/* {!loading && item && (
        <div>
          <div className='row'>
            <div className='col-12 col-md-4 '>
              <div className='mb-s fw-bold'>Партида №</div>
              <div>{item.batchNumber}</div>
            </div>
            <div className='col-12 col-md-4 '>
              <div className='mb-s fw-bold'>Партида дата</div>
              <div>{moment(item.batchDate).format('DD.MM.YYYY')}</div>
            </div>
            <div className='col-12 col-md-4 '>
              <div className='mb-s fw-bold'>Преработвател</div>
              <div>{item.enterprice}</div>
            </div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-12 col-md-4 '>
              <div className='mb-s fw-bold'>Животно №</div>
              <div>{item.animalLId}</div>
            </div>
            <div className='col-12 col-md-4 '>
              <div className='mb-s fw-bold'>Възраст</div>
              <div>{item.animalAge}</div>
            </div>
            <div className='col-12 col-md-4 '>
              <div className='mb-s fw-bold'>Пол</div>
              <div>{item.animalSex === 'MALE' ? 'М' : 'Ж'}</div>
            </div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-12 col-md-4 '>
              <div className='mb-s fw-bold'>SEUROP</div>
              <div>{item.sEUROP}</div>
            </div>
            <div className='col-12 col-md-4 '>
              <div className='mb-s fw-bold'>USDA</div>
              <div>{item.uSDA}</div>
            </div>
            <div className='col-12 col-md-4'>
              <div className='mb-s fw-bold'>Вид на среза</div>
              <div>{item.cuttingType}</div>
            </div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-12 col-md-4 '>
              <div className='mb-s fw-bold'>Сечение</div>
              <div>{item.cutName}</div>
            </div>
            <div className='col-12 col-md-4 '>
              <div className='mb-s fw-bold'>Срок на годност</div>
              <div>{moment(item.expirationDate).format('DD.MM.YYYY')}</div>
            </div>
            <div className='col-12 col-md-3 '>
              <div className='mb-s fw-bold'>Общо тегло (кг.)</div>
              <div>{item.weight}</div>
            </div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-12 col-md-4 '>
              <div className='mb-s fw-bold'>Продадено тегло (кг.)</div>
              <div>{item.soldWeight}</div>
            </div>
            <div className='col-12 col-md-4 '>
              <div className='mb-s fw-bold'>Брак (кг.)</div>
              <div>{item.wasteWeight}</div>
            </div>
            <div className='col-12 col-md-4 '>
              <div className='mb-s fw-bold'>Оставащо тегло (кг.)</div>
              <div>{item.remainingWeight}</div>
            </div>
          </div>
        </div>
      )} */}
      <h3 className='k-error'>Сигурни ли сте че искате да отпишете този артикул ?</h3>
      {loading && <Loader size='large' type='pulsing' className='k-center' />}
      {!loading && (
        <div>
          <Button primary className='mr-s' onClick={unregister}>
            Потвърди
          </Button>
          <Button onClick={onClose}>Откажи</Button>
        </div>
      )}
    </Dialog>
  );
};

export default TraderWarehouseItemUnregister;
