import { State, process } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridToolbar
} from '@progress/kendo-react-grid';
import { useLocalization } from '@progress/kendo-react-intl';
import React, { useState } from 'react';
import { useAppSelector } from '../../app/StoreHooks';
import GrantAccess from '../../features/GrantAccess/GrantAccess';
import GridSearchBar from '../../features/GridSearchBar/GridSearchBar';
import {
  MORTALITY_LOG_TITLE,
  ADD_NEW,
  ANIMAL_HEALTH_PROBLEM,
  ANIMAL_LID,
  ANIMAL_MORTALITY,
  ANIMAL_TERAPY,
  DATE,
  NO,
  YES
} from '../../languages/languages';
import { MortalityLogType } from '../../slices/data/MortalityLogSliceData';
import { selectMortalityLogLoadingState } from '../../slices/MortalityLogSlice';
import ColumnMenu from '../ColumnMenu/ColumnMenu';
import {
  DateCell, EditCommandCell, GridFalseCheckboxCell, GridTrueCheckboxCell
} from '../CustomGridCells/CustomGridCells';
import GridLoaderPanel from '../GridLoaderPanel/GridLoaderPanel';

const REQUIRED_ROLES: string[] = [
  'Admin',
  'Holding Owner',
  'Farm Manager',
  'Farm Veterinary Inspector'
];
const CREATE_ROLES: string[] = [
  'Admin',
  'Farm Manager',
  'Farm Veterinary Inspector'
];

type MortalityLogGridProps = {
  data: MortalityLogType[];
  addNew: () => void;
  enterEdit: (item: MortalityLogType) => void;
};

const MortalityLogGrid = ({
  data,
  addNew,
  enterEdit
}: MortalityLogGridProps) => {
  const localizationService = useLocalization();
  const loadingState = useAppSelector(selectMortalityLogLoadingState);
  // Grid States
  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0
  });

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  };

  const MyEditCommandCell = (props: GridCellProps) =>
    (
      <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
        <EditCommandCell {...props} enterEdit={enterEdit} />
      </GrantAccess>
    );

  const EntryNumberCell = ({ dataItem }: GridCellProps) =>
    (
      <td>{data.indexOf(dataItem) + 1}</td>
    );

  return (
    <>
      <h1 className='page-title'>{localizationService.toLanguageString(MORTALITY_LOG_TITLE, '')}</h1>
      <div className='content-wrapper section'>
        <Grid
          {...dataState}
          pageable
          sortable
          data={process(data, dataState)}
          onDataStateChange={dataStateChange}
        >
          <GridToolbar>
            <GridSearchBar
              filterBy='lID'
              dataState={dataState}
              setDataState={setDataState}
            />
            <GrantAccess haveRoles='some' requiredRoles={CREATE_ROLES}>
              <Button
                name='MortalityAddNew'
                title={localizationService.toLanguageString(ADD_NEW, '')}
                icon='plus'
                primary
                onClick={addNew}
              >
                {localizationService.toLanguageString(ADD_NEW, '')}
              </Button>
            </GrantAccess>
          </GridToolbar>
          <Column title='№' width={40} field='№' cell={EntryNumberCell} />
          <Column
            title={localizationService.toLanguageString(DATE, '')}
            field='eventDate'
            filter='date'
            cell={DateCell}
            columnMenu={ColumnMenu}
          />
          <Column
            title={localizationService.toLanguageString(ANIMAL_LID, '')}
            field='animal.lID'
            filter='date'
            columnMenu={ColumnMenu}
          />
          <Column
            title={localizationService.toLanguageString(ANIMAL_MORTALITY, '')}
            field='isDeath'
          >
            <Column field='isDeath' width={80} title={localizationService.toLanguageString(YES, '')} cell={GridTrueCheckboxCell} />
            <Column field='isDeath' width={80} title={localizationService.toLanguageString(NO, '')} cell={GridFalseCheckboxCell} />
          </Column>
          <Column
            title={localizationService.toLanguageString(ANIMAL_HEALTH_PROBLEM, '')}
            field='healthProblem'
            filter='date'
            columnMenu={ColumnMenu}
          />
          <Column
            title={localizationService.toLanguageString(ANIMAL_TERAPY, '')}
            field='therapy'
            filter='date'
            columnMenu={ColumnMenu}
          />
          <GrantAccess haveRoles='some' requiredRoles={[...REQUIRED_ROLES, 'Holding Owner']}>
            <Column cell={MyEditCommandCell} width={120} />
          </GrantAccess>
        </Grid>
        {loadingState && <GridLoaderPanel />}
      </div>
    </>
  );
};

export default MortalityLogGrid;
