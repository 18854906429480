import React, { useState } from 'react';
import { useLocalization } from '@progress/kendo-react-intl';
import {
  TabStripSelectEventArguments,
  TabStrip,
  TabStripTab
} from '@progress/kendo-react-layout';
import FarmAnimalTableCattle from '../../features/FarmAnimalTableCattle/FarmAnimalTableCattle';
import FarmAnimalTableSheep from '../../features/FarmAnimalTableSheep/FarmAnimalTableSheep';
import {
  ANIMALS_IN_FARM_TABLE_TITLE,
  CATTLE_TAB,
  SHEEP_TAB
} from '../../languages/languages';
import GrantAccess from '../../features/GrantAccess/GrantAccess';

const REQUIRED_ROLES: string[] = [
  'Admin',
  'Farm Manager',
  'Holding Owner',
  'Farm Veterinary Inspector',
  'Abattoir Veterinary Inspector'
];

const FarmAnimalTableContainer = () => {
  // Localization
  const localizationService = useLocalization();

  const [selected, setSelected] = useState(0);
  const handleSelect = (ev: TabStripSelectEventArguments) => {
    setSelected(ev.selected);
  };

  return (
    <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
      <h1 className='page-title'>
        {localizationService.toLanguageString(ANIMALS_IN_FARM_TABLE_TITLE, '')}
      </h1>
      <div className='content-wrapper section' id='animalTableSection'>
        <TabStrip selected={selected} onSelect={handleSelect}>
          <TabStripTab
            title={localizationService.toLanguageString(CATTLE_TAB, '')}
          >
            <FarmAnimalTableCattle />
          </TabStripTab>
          <TabStripTab
            title={localizationService.toLanguageString(SHEEP_TAB, '')}
          >
            <FarmAnimalTableSheep />
          </TabStripTab>
        </TabStrip>
      </div>
    </GrantAccess>
  );
};

export default FarmAnimalTableContainer;
