import { gql } from '@apollo/client';

import CreateGQLClient, { getGraphQLClient } from '../app/Client';
import { IListResult } from '../interfaces/request';
import { IBatch, IBatchCreate, IPhT } from '../slices/data/ButcheryProcessSliceData';

const client = getGraphQLClient() || CreateGQLClient();

const batchMutation = gql`
mutation createBatch($batch: batchInput, $wasteAnimals: [Int]){
  createBatch(batch: $batch, wasteAnimals: $wasteAnimals) {
    id,
    number,
    number
  }
}
`;

const secondPhTMutation = gql`
mutation secondPhTMeasure($data: SecondPhtMeasureInput){
  secondPhTMeasure(data: $data) {
    batchId
  }
}
`;

const batchesQuery = gql`
query batches {
  batches {
    items {
      id,
      number,
      date,
      finished
    }
  }
}
`;

const unfinishedBatchesQuery = gql`
query unfinishedBatches {
  unfinishedBatches {
    items {
      id,
      number,
      date,
      enterprise,
      animalsCount
    }
  }
}
`;

const batchQuery = gql`
query batch($id: Int){
  batch(id: $id) {
    id,
    number,
    date,
    batchAnimals {
      animalId,
      weight,
      carcaseWeight,
      carcaseInternalNumber,
      animal {
        lID,
        sex,
        cattleBreed,
      },
      firstPhT {
        date,
        temp,
        ph
      }
    },
    enterprise {
      enterpriseId,
      enterpriseName,
      enterpriseNumber
    }
  }
}
`;

interface ISaveData {
  batch: IBatchCreate,
  wasteAnimals: number[]
}

interface ISecondTempMeasurement {
  batchId: number,
  phTMeasures: IPhT[]
}

class BatchService {
  saveBatch(batchData: IBatch): Promise<IBatch> {
    const saveData: ISaveData = {
      batch: {
        date: batchData.date,
        enterpriseId: batchData.enterpriseId,
        number: batchData.number,
        batchAnimals: batchData.animals.map((a) => ({
          age: a.age,
          ageClass: a.ageClass,
          animalId: a.animalId,
          arrivalDate: a.arrivalDate,
          butcheredDate: a.butcheredDate,
          carcaseInternalNumber: parseInt(a.carcaseInternalNumber.toString(), 10),
          carcaseNumberTrace: a.carcaseNumberTrace,
          carcaseWeight: a.carcaseWeight,
          carcaseWeightCold: a.carcaseWeightCold,
          cuts: a.cuts.map((cut) => ({
            cutNameId: cut.cutNameId,
            cutName: cut.cutName,
            cuttingTypeId: cut.cuttingTypeId,
            cuttingTypeName: cut.cuttingTypeName,
            weight: cut.weight,
            uneceCutCode: cut.uneceCutCode
          })),
          cutsCountId: a.cutsCountId,
          cutsCountName: a.cutsCountName,
          cutsTypeId: a.cutsTypeId,
          cutsTypeName: a.cutsTypeName,
          firstPhT: {
            date: a.firstPhT.date,
            ph: a.firstPhT.ph,
            temp: a.firstPhT.temp
          },
          isWaste: false,
          number: a.number,
          plusMinusEquals: a.plusMinusEquals,
          processingAsService: a.processingAsService || false,
          randeman: a.randeman,
          seuropCode: a.seuropCode,
          uneceCode: a.uneceCode,
          usdaId: a.usdaId,
          usdaText: a.usdaText,
          weight: a.weight,
        }))
      },

      wasteAnimals: batchData.wasteAnimals.map((a) => a.animalId)
    };

    return new Promise((resolve, reject) => {
      client
        .mutate<ISaveData, IBatch>('createBatch', batchMutation, saveData)
        .then((data) => {
          if (data) {
            resolve(data);
          } else {
            reject();
          }
        })
        .catch(reject);
    });
  }

  getBatch(id: number): Promise<IBatch> {
    return new Promise((resolve, reject) => {
      client
        .query<{ id: number }, any>('batch', batchQuery, { id })
        .then((data) => {
          if (data) {
            const result = {
              animals: data.batchAnimals,
              date: new Date(data.date),
              number: data.number,
              id: data.id,
              enterpriseId: data.enterprise.enterpriseId,
              enterpriseNumber: `${data.enterprise.enterpriseName}(${data.enterprise.enterpriseNumber})`
            } as IBatch;
            resolve(result);
          } else {
            reject();
          }
        })
        .catch(reject);
    });
  }

  getBatches(): Promise<IBatch[]> {
    return new Promise((resolve, reject) => {
      client
        .query<null, IBatch[]>('batches', batchesQuery)
        .then((data) => {
          if (data) {
            resolve(data);
          } else {
            reject();
          }
        })
        .catch(reject);
    });
  }

  getUnfinishedBatches(): Promise<IBatch[]> {
    return new Promise((resolve, reject) => {
      client
        .query<null, IListResult<IBatch>>('unfinishedBatches', unfinishedBatchesQuery)
        .then((data) => {
          if (data) {
            resolve(data.items);
          } else {
            reject();
          }
        })
        .catch(reject);
    });
  }

  addSecondTempMeasure(inputData: ISecondTempMeasurement): Promise<ISecondTempMeasurement> {
    return new Promise((resolve, reject) => {
      client
        .mutate<{ data: ISecondTempMeasurement }, ISecondTempMeasurement>('secondPhTMeasure', secondPhTMutation, { data: inputData })
        .then((data) => {
          if (data) {
            resolve(data);
          } else {
            reject();
          }
        })
        .catch(reject);
    });
  }
}

export default new BatchService();