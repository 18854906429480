import { gql } from '@apollo/client';

export const HistoryChartCategoriesQuery = gql`
  query HistoryChartCategoriesQuery {
    historyCategories {
      id
      value
    }
  }
`;

export const HistoryChartDataQuery = gql`
  query HistoryChartDataQuery {
    historyData {
      dataId
      dataValue
    }
  }
`;

export type HistoryChartCategoryType = {
  id: number;
  value: string;
};

export type HistoryChartDataType = {
  dataId: number;
  dataValue: number;
};
