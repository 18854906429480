import { useLocalization } from '@progress/kendo-react-intl';
import React, { useState } from 'react';
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab
} from '@progress/kendo-react-layout';
import GrantAccess from '../../features/GrantAccess/GrantAccess';
import ProducedFodderLogGridContainer from '../../features/ProducedFodderLogGridContainer/ProducedFodderLogGridContainer';
import PurchasedFodderLogGridContainer from '../../features/PurchasedFodderLogGridContainer/PurchasedFodderLogGridContainer';
import { PRODUCED_FODDER_TITLE, PURCHASED_FODDER_TITLE, FODDER_LOG_TITLE } from '../../languages/languages';

const REQUIRED_ROLES: string[] = ['Admin', 'Farm Manager', 'Holding Owner', 'Farm Veterinary Inspector'];

const FodderLogs = () => {
  const localizationService = useLocalization();

  const [selected, setSelected] = useState(0);
  const handleSelect = (ev: TabStripSelectEventArguments) => {
    setSelected(ev.selected);
  };

  return (
    <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
      <h1 className='page-title'>{localizationService.toLanguageString(FODDER_LOG_TITLE, '')}</h1>
      <div className='content-wrapper section'>
        <TabStrip selected={selected} onSelect={handleSelect}>
          <TabStripTab
            title={localizationService.toLanguageString(
              PRODUCED_FODDER_TITLE,
              ''
            )}
          >
            <ProducedFodderLogGridContainer />
          </TabStripTab>
          <TabStripTab
            title={localizationService.toLanguageString(
              PURCHASED_FODDER_TITLE,
              ''
            )}
          >
            <PurchasedFodderLogGridContainer />
          </TabStripTab>
        </TabStrip>
      </div>
    </GrantAccess>
  );
};

export default FodderLogs;
