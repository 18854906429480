import { gql } from '@apollo/client';

export const GetAnimalAcceptanceQuery = gql`
  query GetAnimalAcceptanceQuery {
    getAnimalAcceptance {
      rating
    }
  }
`;

export type GetAnimalAcceptanceType = {
  rating: number;
};
