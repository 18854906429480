import React from 'react';

import { Field, FormRenderProps } from '@progress/kendo-react-form';
import { useLocalization } from '@progress/kendo-react-intl';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { requiredValidator, NumberValidator } from '../Validators/Validators';
import {
  FormComboBox,
  FormDateTimePicker,
  FormInput
} from '../FormComponents/FormComponents';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import {
  selectAvailableFirms,
  selectAllTraceFirms
} from '../../slices/FirmSlice';
import {
  SALE_INFO,
  SELLING_FIRM,
  SELL_FIRM_NAME,
  SELL_FIRM_EIK,
  SELL_FIRM_MOL,
  BUYING_FIRM,
  BUY_FIRM_NAME,
  BUY_FIRM_EIK,
  BUY_FIRM_MOL
} from '../../languages/en_US';
import { setSaleData } from '../../slices/SellOperationSlice';
import {
  getContragentFirmDrivers,
  selectAllContragents
} from '../../slices/ContragentsSlice';
import { getFirmTrucks, selectAllTrucks } from '../../slices/TrucksSlice';
import { selectTransportFirms } from '../../slices/FirmsAddressBookSlice';
import {
  DEAL_DATE,
  FIRMS_DETAILS,
  FIRM_MOL,
  FIRM_NAME,
  TRANSPORTATION_DATE,
  TRANSPORTATION_DRIVER,
  TRANSPORTATION_DRIVER_PIN,
  TRANSPORTATION_SERIAL_NUMBER,
  TRANSPORTATION_VEHICLE_REG,
  TRANSPORT_DETAILS
} from '../../languages/languages';

type DealAndTransportationProps = {
  formProps?: FormRenderProps;
};
const DealAndTransportation = ({ formProps }: DealAndTransportationProps) => {
  // Localization for labels
  const localizationService = useLocalization();

  // Redux block
  // Selectors
  const holdingFirms = useAppSelector(selectAvailableFirms);
  const allTraceFirms = useAppSelector(selectAllTraceFirms);
  const transportFirms = useAppSelector(selectTransportFirms);
  const contragentDrivers = useAppSelector(selectAllContragents);
  const availableTrucks = useAppSelector(selectAllTrucks);
  // init dispatch
  const dispatch = useAppDispatch();

  // On change, updates the input fields
  // in the form for the Sell Firm Name, EIK and MOL
  const handleSelLFirmChange = ({ value, target }: ComboBoxChangeEvent) => {
    if (value === null) {
      return;
    }
    // Save the sellFirmID to the redux state
    dispatch(setSaleData([target.name, value.firmId]));
    dispatch(setSaleData(['sellFirmName', value.firmName]));
    dispatch(setSaleData(['sellFirmEIK', value.firmEIK]));
    dispatch(setSaleData(['sellFirmMOL', value.firmMOL]));

    // Update Sell Firm Name, EIK, MOL and ID
    // using the built-in FormRenderProps
    formProps.onChange('sellFirmName', {
      value: value.firmName
    });
    formProps.onChange('sellFirmEIK', {
      value: value.firmEIK
    });
    formProps.onChange('sellFirmMOL', {
      value: value.firmMOL
    });
    formProps.onChange('sellFirmID', {
      value: value.firmId
    });
  };

  // On change, updates the input fields
  // in the form for the Buy Firm Name, EIK and MOL
  const handleBuyFirmChange = ({ value, target }: ComboBoxChangeEvent) => {
    if (value === null) {
      return;
    }
    // Save the buyFirmID to the redux state
    // by getting the field name and updating it in the state
    dispatch(setSaleData([target.name, value.firmId]));
    dispatch(setSaleData(['buyFirmName', value.firmName]));
    dispatch(setSaleData(['buyFirmEIK', value.firmName]));
    dispatch(setSaleData(['buyFirmMOL', value.firmName]));

    // Update Buy Firm Name, EIK, MOL and ID
    // using the built-in FormRenderProps
    formProps.onChange('buyFirmName', {
      value: value.firmName
    });
    formProps.onChange('buyFirmEIK', {
      value: value.firmEIK
    });
    formProps.onChange('buyFirmMOL', {
      value: value.firmMOL
    });
    formProps.onChange('buyFirmId', {
      value: value.firmId
    });
  };

  // Handle all other Input changes, that will be required for the deal
  const handleInputOnChange = ({ target, value }) => {
    // Get the Field name and value and set them
    // in the redux state
    dispatch(setSaleData([target.name, value]));
  };

  const handleTransportFirm = ({ target, value }: ComboBoxChangeEvent) => {
    dispatch(getContragentFirmDrivers(value.firmABId));
    dispatch(getFirmTrucks(value.firmABId));
    dispatch(setSaleData([target.name, value]));

    formProps.onChange('firmABMOL', {
      value: value.firmABMOL
    });
  };

  const handleTruckChange = ({ target, value }: ComboBoxChangeEvent) => {
    dispatch(setSaleData([target.name, value]));
  };

  const handleDriverChange = ({ target, value }: ComboBoxChangeEvent) => {
    dispatch(setSaleData([target.name, value]));

    formProps.onChange('contragentPIN', {
      value: value.contragentPIN
    });
  };

  return (
    <div className='sell-details-form'>
      <h3>{localizationService.toLanguageString(FIRMS_DETAILS, '')}</h3>

      {/* Selling Firm Information */}
      <fieldset className='k-form-fieldset'>
        <legend className='subtitle-1 mb-s ml-xs'>
          {localizationService.toLanguageString(SALE_INFO, '')}
        </legend>
        <Field
          component={FormComboBox}
          label={localizationService.toLanguageString(SELLING_FIRM, '')}
          data={holdingFirms}
          textField='firmName'
          name='sellFirmID'
          dataItemKey='firmId'
          onChange={handleSelLFirmChange}
        />
        <Field
          id='sellFirmName'
          name='sellFirmName'
          label={localizationService.toLanguageString(SELL_FIRM_NAME, '')}
          component={FormInput}
          disabled
        />
        <Field
          id='sellFirmEIK'
          name='sellFirmEIK'
          label={localizationService.toLanguageString(SELL_FIRM_EIK, '')}
          component={FormInput}
          disabled
        />
        <Field
          id='sellFirmMOL'
          name='sellFirmMOL'
          label={localizationService.toLanguageString(SELL_FIRM_MOL, '')}
          component={FormInput}
          disabled
        />
      </fieldset>

      {/* Buying Firm Information */}
      <fieldset className='k-form-fieldset'>
        <legend className='subtitle-1 mb-s ml-xs'>
          {localizationService.toLanguageString(BUYING_FIRM, '')}
        </legend>
        <Field
          component={FormComboBox}
          label={localizationService.toLanguageString(BUYING_FIRM, '')}
          data={allTraceFirms}
          textField='firmName'
          name='buyFirmID'
          dataItemKey='firmId'
          onChange={handleBuyFirmChange}
        />
        <Field
          id='buyFirmName'
          name='buyFirmName'
          label={localizationService.toLanguageString(BUY_FIRM_NAME, '')}
          component={FormInput}
          disabled
        />
        <Field
          id='buyFirmEIK'
          name='buyFirmEIK'
          label={localizationService.toLanguageString(BUY_FIRM_EIK, '')}
          component={FormInput}
          disabled
        />
        <Field
          id='buyFirmMOL'
          name='buyFirmMOL'
          label={localizationService.toLanguageString(BUY_FIRM_MOL, '')}
          component={FormInput}
          disabled
        />
        <Field
          key='dealDate'
          id='dealDate'
          name='dealDate'
          label={localizationService.toLanguageString(DEAL_DATE, '')}
          format='dd-MMM-yyyy HH:mm'
          optional
          component={FormDateTimePicker}
          validator={requiredValidator}
          onChange={handleInputOnChange}
        />
      </fieldset>

      {/* Transportation details */}
      <h3 className='mt-m'>
        {localizationService.toLanguageString(TRANSPORT_DETAILS, '')}
      </h3>
      <fieldset className='k-form-fieldset'>
        <Field
          key='transportFirmName'
          id='transportFirmName'
          name='transportFirmName'
          label={localizationService.toLanguageString(FIRM_NAME, '')}
          component={FormComboBox}
          data={transportFirms}
          textField='firmABName'
          validator={requiredValidator}
          onChange={handleTransportFirm}
        />
        <Field
          key='firmABMOL'
          id='firmABMOL'
          name='firmABMOL'
          label={localizationService.toLanguageString(FIRM_MOL, '')}
          disabled
          component={FormInput}
          validator={requiredValidator}
          onChange={handleInputOnChange}
        />
        <Field
          key='transportDate'
          id='transportDate'
          name='transportDate'
          label={localizationService.toLanguageString(TRANSPORTATION_DATE, '')}
          format='dd-MMM-yyyy HH:mm'
          component={FormDateTimePicker}
          validator={requiredValidator}
          onChange={handleInputOnChange}
        />
        <Field
          key='contragentId'
          id='contragentId'
          name='contragentId'
          label={localizationService.toLanguageString(
            TRANSPORTATION_DRIVER,
            ''
          )}
          component={FormComboBox}
          data={contragentDrivers}
          textField='contragentName'
          validator={requiredValidator}
          onChange={handleDriverChange}
        />
      </fieldset>
      <fieldset className='k-form-fieldset'>
        <Field
          key='contragentPIN'
          id='contragentPIN'
          name='contragentPIN'
          label={localizationService.toLanguageString(
            TRANSPORTATION_DRIVER_PIN,
            ''
          )}
          disabled
          component={FormInput}
          validator={NumberValidator}
          onChange={handleInputOnChange}
        />
        <Field
          key='truckId'
          id='truckId'
          name='truckId'
          label={localizationService.toLanguageString(
            TRANSPORTATION_VEHICLE_REG,
            ''
          )}
          component={FormComboBox}
          data={availableTrucks}
          textField='truckPlate'
          validator={requiredValidator}
          onChange={handleTruckChange}
        />
        <Field
          key='waybillNumber'
          id='waybillNumber'
          name='waybillNumber'
          label={localizationService.toLanguageString(
            TRANSPORTATION_SERIAL_NUMBER,
            ''
          )}
          component={FormInput}
          validator={requiredValidator}
          onChange={handleInputOnChange}
        />
      </fieldset>
    </div>
  );
};

DealAndTransportation.defaultProps = {
  formProps: null
};

export default DealAndTransportation;
