import { State } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { getter } from '@progress/kendo-react-common';
import { GridCellProps, GridDataStateChangeEvent, GridDetailRowProps, GridHeaderSelectionChangeEvent, GridSelectionChangeEvent } from '@progress/kendo-react-grid';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';

import { useAppDispatch } from '../../app/StoreHooks';
// import { getAnimalsInButchery } from '../../slices/AnimalColumnsSlice';
import { getAnimalsInButchery } from '../../slices/AnimalSlice';
import { ColumnType } from '../../slices/data/AnimalColumnsSliceData';
import { AnimalType } from '../../slices/data/AnimalSliceData';
import { IBatchAnimal, IBatchCarcase } from '../../slices/data/ButcheryProcessSliceData';
import BatchAnimalWeightsEditModal from '../ButcheryProcess/BatchAnimalWeightsEditModal';
import CutsUnece from '../ButcheryProcess/CutsUnece';
import { CattleBreedCell } from '../CustomGridCells/CustomGridCells';
import AnimalTable from '../SelectableGrid/SelectableGrid';

const DATA_ITEM_KEY: string = 'animalId';
const idGetter = getter(DATA_ITEM_KEY);

interface AnimalGridProps {
  onChange?: (items: IBatchAnimal[]) => void;
  animals: IBatchAnimal[];
}

export type RefHandle = {
  reload: () => void;
};

const cattleColumns: ColumnType[] = [
  {
    title: 'Труп вътрешен №',
    field: 'carcaseInternalNumber',
    show: true,
    filter: 'text',
  } as ColumnType,
  {
    title: 'Животно №',
    field: 'lID',
    show: true,
    filter: 'text',
  } as ColumnType,
  {
    title: 'Труп № Trace',
    field: 'carcaseNumberTrace',
    show: true,
    filter: 'text',
  } as ColumnType,
  {
    title: 'Възраст',
    field: 'age',
    show: true,
    filter: 'numeric',
  } as ColumnType,
  {
    title: 'Пол',
    field: 'sex',
    show: true,
    filter: 'text',
  } as ColumnType,
  {
    title: 'Разпад',
    field: 'cutsCountName',
    show: true,
    filter: 'text',
  } as ColumnType,
  {
    title: 'Трупно тегло топло (кг)',
    field: 'carcaseWeight',
    show: true,
    filter: 'numeric',
  } as ColumnType,
  {
    title: 'Трупно тегло охладено (кг)',
    field: 'carcaseWeightCold',
    show: true,
    filter: 'numeric',
  } as ColumnType,
  {
    title: 'Рандеман % ЖТ -ТТТ ()',
    field: 'randeman',
    show: true,
    filter: 'numeric',
    format: '{0:##,#\\%}',
  } as ColumnType,
] as ColumnType[];

const BatchAnimalsWeightGrid = forwardRef<RefHandle, AnimalGridProps>(
  ({ onChange, animals }: AnimalGridProps, ref) => {
    const dispatch = useAppDispatch();
    const [items, setItems] = useState<IBatchAnimal[]>(animals);

    const loadData = () => {
      dispatch(getAnimalsInButchery());
    };

    // Grid States
    const [dataState, setDataState] = useState<State>({
      take: 10,
      skip: 0,
    });

    const dataStateChange = (e: GridDataStateChangeEvent) => {
      setDataState(e.dataState);
    };

    // Select animals
    // Local state for the grid's checkbox that will indicate
    // the state of the checkbox
    const [selectedState, setSelectedState] = useState<{
      [id: string]: boolean | number[];
    }>({});

    // Local state for the selected animals, that will be previewed in
    // the next step of the logic.
    const [selectedAnimals, setSelectedAnimals] = useState<AnimalType[]>([]);
    const [allSelValue, setAllSelValue] = useState<boolean>(false);

    // Check/Uncheck row in the grid
    // Use a callback so that the function is not fully
    // rerendered every time the component re-renders.
    // It should only update if there are changes to the
    // selectedState local state
    const onSelectionChange = useCallback(
      async ({ dataItem, syntheticEvent }: GridSelectionChangeEvent) => {
        // If anything but the checkbox in the grid is selected,
        // return the function so that the component does not crash
        if (!syntheticEvent?.target) {
          return;
        }

        // Get checked status of the checkbox cell
        const checkboxElement: any = syntheticEvent.target;
        const { checked } = checkboxElement;

        // Create a local state for the selected animals,
        // so that the checkbox element is dynamically updated
        const newSelectedState = {
          ...selectedState,
          [idGetter(dataItem)]: checked,
        };

        let updatedSelection = [];
        // If the the element is not checked, filter through
        // the local selectedAnimals state to make sure
        // only selected animals will be inserted for preview
        if (!newSelectedState[idGetter(dataItem)]) {
          updatedSelection = selectedAnimals.filter(
            (animal) => animal.animalId !== dataItem.animalId
          );

          setSelectedAnimals(updatedSelection);
        } else {
          // If the element is checked, add it to the
          // local selectedAnimals state
          updatedSelection = [...selectedAnimals, dataItem];
        }
        // Sets the selected animals for preview
        setSelectedAnimals(updatedSelection);
        // if (onSelect) {
        //   onSelect(updatedSelection);
        // }

        setSelectedState(newSelectedState);
      },
      [selectedState]
    );

    // Set all page elements to be checked
    const onHeaderSelectionChange = ({
      dataItems,
      syntheticEvent,
    }: GridHeaderSelectionChangeEvent) => {
      const checkboxElement: any = syntheticEvent.target;
      const { checked } = checkboxElement;
      const newSelectedState = {};
      const selAnimals: any[] = [];
      setAllSelValue(!allSelValue);
      dataItems.forEach(async (animal) => {
        newSelectedState[idGetter(animal)] = checked;
        if (newSelectedState[idGetter(animal)]) {
          selAnimals.push(animal);
        }
      });
      setSelectedState(newSelectedState);
      setSelectedAnimals(selAnimals);
      // if (onSelect) {
      //   onSelect(selAnimals);
      // }
    };

    // Handle Menu visibility
    const handleMenuVisibility = () => {
      console.log('menu visibility changed');
    };

    useImperativeHandle(ref, () => ({
      reload() {
        loadData();
      },
    }));

    useEffect(() => {
      setItems(animals);
    }, []);

    const [showEdit, setShowEdit] = useState(false);
    const [editItem, seteditItem] = useState<IBatchAnimal>(null);
    const onShowEdit = (item: IBatchAnimal) => {
      seteditItem(item);
      setShowEdit(true);
    };

    const cancelEdit = () => {
      seteditItem(null);
      setShowEdit(false);
    };

    const handleEdit = (item: IBatchAnimal) => {
      const index = items.findIndex((i) => i.animalId === item.animalId);

      if (index !== -1) {
        item.carcaseWeightCold = parseFloat((item.carcaseWeight * 0.985).toFixed(2));
        item.randeman = item.carcaseWeightCold / (item.weight / 100);
        items[index] = item;
        setItems([...items]);
        onChange(items);
      }

      cancelEdit();
    };

    const actionCell = ({ dataItem }: GridCellProps) => (
      <td className='text-center'>
        <Button
          data-testid='editButton'
          type='button'
          className='k-button k-secondary mr-l'
          icon='edit'
          look='outline'
          onClick={() => onShowEdit(dataItem)}
        />
      </td>
    );

    const onCutsChange = (carcase: IBatchCarcase, animalId: number) => {
      const index = items.findIndex((i) => i.animalId === animalId);
      if (index !== -1) {
        items[index].cutsCountId = carcase.cutsCountId;
        items[index].cutsCountName = carcase.cutsCountName;
        items[index].cutsTypeId = carcase.cutsTypeId;
        items[index].cutsTypeName = carcase.cutsTypeName;
        items[index].cuts = carcase.cuts;

        (items[index] as any).expanded = false;
        setItems([...items]);
        onChange(items);
      }
    };

    const DetailComponent = (props: GridDetailRowProps) => {
      const { dataItem } = props;
      const onCancel = () => {
        const index = items.findIndex((i) => i.animalId === dataItem.animalId);
        if (index !== -1) {
          (items[index] as any).expanded = false;
          setItems([...items]);
          onChange(items);
        }
      };
      return (
        <div>
          <CutsUnece item={dataItem} onSave={onCutsChange} onCancel={onCancel} />
        </div>
      );
    };

    return (
      <>
        <AnimalTable
          data={items}
          dataState={dataState}
          dataStateChange={dataStateChange}
          handleMenuVisibility={handleMenuVisibility}
          onHeaderSelectionChange={onHeaderSelectionChange}
          onSelectionChange={onSelectionChange}
          selectedState={selectedState}
          setDataState={setDataState}
          stateColumns={cattleColumns}
          cell={CattleBreedCell}
          DATA_ITEM_KEY='animalId'
          showToolbar={false}
          showColumns={false}
          actionCell={actionCell}
          detail={DetailComponent}
          selectable={false}
        />
        {showEdit && (
          <BatchAnimalWeightsEditModal
            item={editItem}
            onSubmit={handleEdit}
            cancelEdit={cancelEdit}
          />
        )}
      </>
    );
  }
);

BatchAnimalsWeightGrid.defaultProps = {
  onChange: undefined,
};

export default BatchAnimalsWeightGrid;
