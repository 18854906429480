import { Dialog } from '@progress/kendo-react-dialogs';
import { useLocalization } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import MortalityLogForm from '../../components/MortalityLogForm/MortalityLogForm';
import MortalityLogGrid from '../../components/MortalityLogGrid/MortalityLogGrid';
import { ADD_NEW, EDIT } from '../../languages/languages';
import { getAnimalsInFarm } from '../../slices/AnimalSlice';
import {
  MortalityLogInputType,
  MortalityLogType
} from '../../slices/data/MortalityLogSliceData';
import {
  editMortalityLog,
  getAllMortalityLogs,
  saveMortalityLog,
  selectAllMortalityLogEntries
} from '../../slices/MortalityLogSlice';
import GrantAccess from '../GrantAccess/GrantAccess';

const REQUIRED_ROLES: string[] = [
  'Admin',
  'Holding Owner',
  'Farm Manager',
  'Farm Veterinary Inspector'
];
const CREATE_ROLES: string[] = [
  'Admin',
  'Farm Manager',
  'Farm Veterinary Inspector'
];

const MortalityLogContainer = () => {
  const localizationService = useLocalization();
  const dispatch = useAppDispatch();
  const mortalityLogs = useAppSelector(selectAllMortalityLogEntries);

  useEffect(() => {
    const loadData = () => {
      dispatch(getAllMortalityLogs());
    };
    loadData();
  }, []);

  const [addEntryView, setAddEntryView] = useState(false);

  const openAddEntryView = () => {
    setAddEntryView(true);
    dispatch(getAnimalsInFarm());
  };

  const closeAddEntryView = () => {
    setAddEntryView(false);
  };

  const submitNewEntry = (formData: MortalityLogInputType) => {
    dispatch(saveMortalityLog(formData));
    closeAddEntryView();
  };

  // Edit form state and functions
  const [editEntryView, setEditEntryView] = useState(false);
  const [editItem, setEditItem] = useState<MortalityLogType>(null);
  const enterEdit = (item: MortalityLogType) => {
    setEditEntryView(true);
    setEditItem(item);
    dispatch(getAnimalsInFarm());
  };

  const closeEditEntryView = () => {
    setEditEntryView(false);
  };

  const submitEditEntry = (formData: MortalityLogType) => {
    const inputItem: MortalityLogInputType = {
      animalId: formData.animalHealthEventId,
      eventDate: formData.eventDate,
      healthProblem: formData.healthProblem,
      isDeath: formData.isDeath,
      therapy: formData.therapy,
      animalHealthEventId: formData.animalHealthEventId
    };
    dispatch(editMortalityLog(inputItem));
    closeEditEntryView();
  };

  return (
    <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
      <MortalityLogGrid
        data={mortalityLogs}
        addNew={openAddEntryView}
        enterEdit={enterEdit}
      />
      <GrantAccess haveRoles='some' requiredRoles={CREATE_ROLES}>
        {addEntryView && (
        <Dialog
          title={localizationService.toLanguageString(ADD_NEW, '')}
          onClose={closeAddEntryView}
        >
          <MortalityLogForm close={closeAddEntryView} submit={submitNewEntry} />
        </Dialog>
        )}
      </GrantAccess>
      {editEntryView && (
        <Dialog
          title={localizationService.toLanguageString(EDIT, '')}
          onClose={closeEditEntryView}
        >
          <MortalityLogForm
            item={editItem}
            close={closeEditEntryView}
            submit={submitEditEntry}
          />
        </Dialog>
      )}
    </GrantAccess>
  );
};

export default MortalityLogContainer;
