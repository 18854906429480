import { Button } from '@progress/kendo-react-buttons';
import { GridCellProps } from '@progress/kendo-react-grid';
import { useLocalization } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';

import ProcessorToTraderDealDisplayModal from '../../components/ProcessorToTraderDeal/ProcessorToTraderDealDisplay';
import ProcessorToTraderDealsGrid from '../../components/ProcessorToTraderDealsGrid/ProcessorToTraderDealsGrid';
import { DEALS_TITLE } from '../../languages/languages';
import { ISaleShortInfo } from '../../models/processorToTraderSaleModels';
import processorToTraderSalesService from '../../services/processorToTraderSales.service';
import ProcessorToTraderDealReadonlyModal from '../../components/ProcessorToTraderDeal/ProcessorToTraderDealReadonly';

const TraderDealsContainer = () => {
  const $t = useLocalization();
  const [data, setData] = useState<ISaleShortInfo[]>([]);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [showReadonlyModal, setShowReadonlyModal] = useState(false);
  const [selectedDealId, setSelectedDealId] = useState<number>();

  const loadData = () => {
    processorToTraderSalesService.buyerDeals().then((result) => {
      if (result) {
        setData(result);
      }
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  const actionCell = ({ dataItem }: GridCellProps) => (
    <td className='text-center'>
      <Button
        data-testid='editButton'
        type='submit'
        className='k-button k-secondary'
        icon='info'
        look='outline'
        onClick={() => {
          setSelectedDealId(dataItem.id);

          if (dataItem.saleEventStatus === 'pending') {
            setShowInfoModal(true);
          } else {
            setShowReadonlyModal(true);
          }
        }}
      />
    </td>
  );

  return (
    <div>
      <h1 className='page-title'>{$t.toLanguageString(DEALS_TITLE, '')}</h1>
      <div className='row'>
        <div className='col-12'>
          <ProcessorToTraderDealsGrid data={data} actionCell={actionCell} />
        </div>
      </div>
      {showInfoModal && (
        <ProcessorToTraderDealDisplayModal
          dealId={selectedDealId}
          onClose={() => setShowInfoModal(false)}
          onUpdate={() => {
            loadData();
            setShowInfoModal(false);
          }}
        />
      )}

      {showReadonlyModal && (
        <ProcessorToTraderDealReadonlyModal
          dealId={selectedDealId}
          onClose={() => setShowReadonlyModal(false)}
        />
      )}
    </div>
  );
};

export default TraderDealsContainer;
