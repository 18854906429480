import { gql } from '@apollo/client';

// Types
export enum AddressBookFirmTypeEnum {
  UNKWON = 'Unknown',
  FARM = 'Farm',
  SLAUGHTER = 'Slaughter',
  VET = 'Vet',
  TRANSPORT = 'Tansport',
  SUPPLIER = 'Supplier',
  OTHER = 'Other'
}

export type FirmType = {
  addresBookFirmType: AddressBookFirmTypeEnum;
  addresBookFirmTypeID?: number;
};

export type AddressBookFirmInputType = {
  firmABId: number;
  firmABEIK: string;
  firmABName: string;
  firmABCity: string;
  firmABCountry: string;
  firmABAddress: string;
  firmABMOL: string;
  addresBookFirmType: FirmType[];
};

export type FirmsAddressBookType = {
  firmABId?: number;
  firmABEIK: string;
  firmABName: string;
  firmABCity: string;
  firmABCountry: string;
  firmABAddress: string;
  firmABMOL: string;
  firmType: FirmType[];
};

export type GetAllFirmsAddressBookResponseType = {
  items: FirmsAddressBookType[];
};

export type SaveAddressBookFirmType = {
  addressBookFirm: AddressBookFirmInputType;
};

export type FType = { value: string; label: string };
export const FirmTypes: FType[] = [
  { value: 'UNKWON', label: 'Unknown' },
  { value: 'FARM', label: 'Farm' },
  { value: 'SLAUGHTER', label: 'Slaughter' },
  { value: 'VET', label: 'Vet' },
  { value: 'TRANSPORT', label: 'Transport' },
  { value: 'SUPPLIER', label: 'Supplier' },
  { value: 'OTHER', label: 'Other' }
];

export type TransportFirmType = {
  firmABId: number;
  firmABName: string;
  firmABMOL: string;
};

export type GetTransportFirmsType = {
  items: {
    firm: TransportFirmType }[]
};

export type GetAllFirmsResponseType = {
  items: FirmsAddressBookType[];
};

export type SupplierFirmType = {
  firmABId: number;
  firmABName: string;
  firmABMOL: string;
};

export type GetSupplierFirmsType = {
  items: {
    firm: SupplierFirmType }[]
};

// Queries & Mutation
export const GET_ADDRBOOK_FIRMS = gql`
  query {
    addressBookFirm {
      items {
        firmABId
        firmABEIK
        firmABMOL
        firmABName
        firmABCity
        firmABCountry
        firmABAddress
        firmType {
          addresBookFirmTypeID
          addresBookFirmType
        }
      }
    }
  }
`;

export const SAVE_ADDRBOOK_FIRM = gql`
  mutation addAddressBookFirm($addressBookFirm: firmABInput) {
    addAddressBookFirm(addressBookFirm: $addressBookFirm) {
      firmABId
      firmABEIK
      firmABMOL
      firmABName
      firmABCity
      firmABCountry
      firmABAddress
      firmType {
        addresBookFirmTypeID
        addresBookFirmType
      }
    }
  }
`;

export const EDIT_ADDRBOOK_FIRM = gql`
  mutation updateAddressBookFirm($addressBookFirm: firmABInput) {
    updateAddressBookFirm(addressBookFirm: $addressBookFirm) {
      firmABId
      firmABEIK
      firmABName
      firmABCountry
      firmABCity
      firmABAddress
      firmABMOL
      firmType {
        addresBookFirmTypeID
        addresBookFirmType
      }
    }
  }
`;

export const GET_TRANSPORT_FIRMS = gql`
  {
    typeOfFirm(type: TRANSPORT) {
      items {
        firm {
          firmABId
          firmABName
          firmABMOL
        }
      }
    }
  }
`;

export const GET_SUPPLIER_FIRMS = gql`
{
  typeOfFirm(type: SUPPLIER) {
    items {
      firm {
        firmABId
        firmABName
        firmABMOL
      }
    }
  }
}
`;
