import { Dialog } from '@progress/kendo-react-dialogs';
import { Loader } from '@progress/kendo-react-indicators';
import moment from 'moment';
import React, { useEffect, useState } from 'react';

import warehouseService from '../../services/warehouse.service';
import { ITraderWarehouseGridItem } from '../../slices/data/WarehouseSliceData';

const TraderWarehouseItemDisplay = ({
  batchCutId,
  onClose,
}: {
  batchCutId: number;
  onClose: () => void;
}) => {
  const [item, setItem] = useState<ITraderWarehouseGridItem>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    warehouseService
      .getTraderWarehouseItem(batchCutId)
      .then((data) => {
        setItem(data);
      })
      .finally(() => setLoading(false));
  }, [batchCutId]);

  return (
    <Dialog title='Складов артикул' onClose={onClose} minWidth={600}>
      {loading && <Loader size='large' type='pulsing' className='k-center' />}
      {!loading && item && (
        <div>
          <div className='row'>
            <div className='col-12 col-md-4 mb-m'>
              <div className='mb-s fw-bold'>Партида №</div>
              <div>{item.batchNumber}</div>
            </div>
            <div className='col-12 col-md-4 mb-m'>
              <div className='mb-s fw-bold'>Партида дата</div>
              <div>{moment(item.batchDate).format('DD.MM.YYYY')}</div>
            </div>
            <div className='col-12 col-md-4 mb-m'>
              <div className='mb-s fw-bold'>Преработвател</div>
              <div>{item.enterprice}</div>
            </div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-12 col-md-4 mb-m'>
              <div className='mb-s fw-bold'>Животно №</div>
              <div>{item.animalLId}</div>
            </div>
            <div className='col-12 col-md-4 mb-m'>
              <div className='mb-s fw-bold'>Възраст</div>
              <div>{item.animalAge}</div>
            </div>
            <div className='col-12 col-md-4 mb-m'>
              <div className='mb-s fw-bold'>Пол</div>
              <div>{item.animalSex === 'MALE' ? 'М' : 'Ж'}</div>
            </div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-12 col-md-4 mb-m'>
              <div className='mb-s fw-bold'>SEUROP</div>
              <div>{item.sEUROP}</div>
            </div>
            <div className='col-12 col-md-4 mb-m'>
              <div className='mb-s fw-bold'>USDA</div>
              <div>{item.uSDA}</div>
            </div>
            <div className='col-12 col-md-4 mb-m'>
              <div className='mb-s fw-bold'>Вид на среза</div>
              <div>{item.cuttingType}</div>
            </div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-12 col-md-4 mb-m'>
              <div className='mb-s fw-bold'>Сечение</div>
              <div>{item.cutName}</div>
            </div>
            <div className='col-12 col-md-4 mb-m'>
              <div className='mb-s fw-bold'>Срок на годност</div>
              <div>{moment(item.expirationDate).format('DD.MM.YYYY')}</div>
            </div>
            <div className='col-12 col-md-3 mb-m'>
              <div className='mb-s fw-bold'>Общо тегло (кг.)</div>
              <div>{item.weight}</div>
            </div>
          </div>
          <hr />
          <div className='row'>
            <div className='col-12 col-md-4 mb-m'>
              <div className='mb-s fw-bold'>Продадено тегло (кг.)</div>
              <div>{item.soldWeight}</div>
            </div>
            <div className='col-12 col-md-4 mb-m'>
              <div className='mb-s fw-bold'>Брак (кг.)</div>
              <div>{item.wasteWeight}</div>
            </div>
            <div className='col-12 col-md-4 mb-m'>
              <div className='mb-s fw-bold'>Оставащо тегло (кг.)</div>
              <div>{item.remainingWeight}</div>
            </div>
          </div>
        </div>
      )}
    </Dialog>
  );
};

export default TraderWarehouseItemDisplay;
