import { getter } from '@progress/kendo-react-common';
import { GridDataStateChangeEvent, GridHeaderSelectionChangeEvent, GridSelectionChangeEvent } from '@progress/kendo-react-grid';
import { Loader } from '@progress/kendo-react-indicators';
import { State } from 'history';
import React, { useCallback, useEffect, useState } from 'react';

import warehouseService from '../../services/warehouse.service';
import { ColumnType } from '../../slices/data/AnimalColumnsSliceData';
import { AnimalType } from '../../slices/data/AnimalSliceData';
import { IWarehouseGridItem } from '../../slices/data/WarehouseSliceData';
import SelectableGrid from '../SelectableGrid/SelectableGrid';

const DATA_ITEM_KEY: string = 'batchCutId';
const idGetter = getter(DATA_ITEM_KEY);

const columns = [
  {
    title: 'Партида №',
    field: 'batchNumber',
    show: true,
    filter: 'text',
  },
  {
    title: 'Партида дата',
    field: 'batchDate',
    show: true,
    filter: 'date',
    format: '{0:dd.MM.yyy}',
  },
  {
    title: 'Животно №',
    field: 'animalLId',
    show: true,
    filter: 'text',
  },
  {
    title: 'Възраст',
    field: 'animalAge',
    show: true,
    filter: 'numeric',
  },
  {
    title: 'Пол',
    field: 'animalSex',
    show: true,
    filter: 'text',
  },
  {
    title: 'SEUROP',
    field: 'sEUROP',
    show: true,
    filter: 'text',
  },
  {
    title: 'USDA',
    field: 'uSDA',
    show: true,
    filter: 'text',
  },
  {
    title: 'Вид на среза',
    field: 'cuttingType',
    show: true,
    filter: 'text',
  },
  {
    title: 'Сечение',
    field: 'cutName',
    show: true,
    filter: 'text',
  },
  {
    title: 'Тегло',
    field: 'weight',
    show: true,
    filter: 'text',
    format: '{0} кг.',
  },
  {
    title: 'Фирма преработвател',
    field: 'processingAsServiceFirm',
    show: true,
    filter: 'text',
  },
] as ColumnType[];

const FarmarVirtualWarehouseGrid = ({
  firmId,
  onSelect,
  actions,
}: {
  firmId: number;
  onSelect: (selection: any[]) => void;
  actions: () => JSX.Element;
}) => {
  const [gridData, setGridData] = useState<IWarehouseGridItem[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    warehouseService
      .getProcessingAsServiceAnimalsForFarmer(firmId)
      .then((data) => {
        if (data) {
          data.forEach((item) => {
            item.batchDate = new Date(item.batchDate);
          });
          setGridData(data);
        } else {
          setGridData([]);
        }
      })
      .finally(() => setLoading(false));
  }, [firmId]);

  // Grid States
  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0,
  });

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  };

  // Select animals
  // Local state for the grid's checkbox that will indicate
  // the state of the checkbox
  const [selectedState, setSelectedState] = useState<{
    [id: string]: boolean | number[];
  }>({});

  // Local state for the selected animals, that will be previewed in
  // the next step of the logic.
  const [selectedAnimals, setSelectedAnimals] = useState<AnimalType[]>([]);
  const [allSelValue, setAllSelValue] = useState<boolean>(false);

  // Check/Uncheck row in the grid
  // Use a callback so that the function is not fully
  // rerendered every time the component re-renders.
  // It should only update if there are changes to the
  // selectedState local state
  const onSelectionChange = useCallback(
    async ({ dataItem, syntheticEvent }: GridSelectionChangeEvent) => {
      // If anything but the checkbox in the grid is selected,
      // return the function so that the component does not crash
      if (!syntheticEvent?.target) {
        return;
      }

      // Get checked status of the checkbox cell
      const checkboxElement: any = syntheticEvent.target;
      const { checked } = checkboxElement;

      // Create a local state for the selected animals,
      // so that the checkbox element is dynamically updated
      const newSelectedState = {
        ...selectedState,
        [idGetter(dataItem)]: checked,
      };

      let updatedSelection = [];
      // If the the element is not checked, filter through
      // the local selectedAnimals state to make sure
      // only selected animals will be inserted for preview
      if (!newSelectedState[idGetter(dataItem)]) {
        updatedSelection = selectedAnimals.filter(
          (animal) => animal.animalId !== dataItem.animalId
        );

        setSelectedAnimals(updatedSelection);
      } else {
        // If the element is checked, add it to the
        // local selectedAnimals state
        updatedSelection = [...selectedAnimals, dataItem];
      }
      // Sets the selected animals for preview
      setSelectedAnimals(updatedSelection);
      if (onSelect) {
        onSelect(updatedSelection);
      }

      setSelectedState(newSelectedState);
    },
    [selectedState]
  );

  // Set all page elements to be checked
  const onHeaderSelectionChange = ({
    dataItems,
    syntheticEvent,
  }: GridHeaderSelectionChangeEvent) => {
    const checkboxElement: any = syntheticEvent.target;
    const { checked } = checkboxElement;
    const newSelectedState = {};
    const selAnimals: any[] = [];
    setAllSelValue(!allSelValue);
    dataItems.forEach(async (animal) => {
      newSelectedState[idGetter(animal)] = checked;
      if (newSelectedState[idGetter(animal)]) {
        selAnimals.push(animal);
      }
    });
    setSelectedState(newSelectedState);
    setSelectedAnimals(selAnimals);
    if (onSelect) {
      onSelect(selAnimals);
    }
  };

  // Handle Menu visibility
  const handleMenuVisibility = () => {
    console.log('menu visibility changed');
  };

  return (
    <div>
      {!loading && (
        <SelectableGrid
          data={gridData}
          stateColumns={columns}
          DATA_ITEM_KEY={DATA_ITEM_KEY}
          dataState={dataState}
          dataStateChange={dataStateChange}
          handleMenuVisibility={handleMenuVisibility}
          onHeaderSelectionChange={onHeaderSelectionChange}
          onSelectionChange={onSelectionChange}
          selectedState={selectedState}
          setDataState={setDataState}
          showColumns={false}
          actions={actions}
        />
      )}
      {loading && <Loader size='large' type='pulsing' />}
    </div>
  );
};

export default FarmarVirtualWarehouseGrid;
