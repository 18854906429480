import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import {
  Field,
  Form,
  FormElement,
  FormRenderProps
} from '@progress/kendo-react-form';
import { useLocalization } from '@progress/kendo-react-intl';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { ADD, CANCEL } from '../../languages/languages';
import { AddTruckType, TruckType } from '../../slices/data/TrucksSliceData';
import {
  FormComboBox,
  FormInput,
  FormNumericTextBox
} from '../FormComponents/FormComponents';
import { StringValidator } from '../Validators/Validators';
import { useAppSelector } from '../../app/StoreHooks';
import { selectTransportFirms } from '../../slices/FirmsAddressBookSlice';

type TruckFormProps = {
  close: () => void;
  submit: (formData: AddTruckType | TruckType) => void;
  defaultItem?: TruckType;
  type: 'add' | 'edit';
};
const TruckForm = ({
  close, submit, defaultItem, type
}: TruckFormProps) => {
  const firms = useAppSelector(selectTransportFirms);
  const localizationService = useLocalization();

  const selectedFirm = firms.find(
    (firm) =>
      firm?.firmABId === defaultItem?.parentFirm?.firmABId
  );

  return (
    <Dialog title='Добави нов камион' onClose={close}>
      <Form
        onSubmit={submit}
        initialValues={defaultItem}
        render={(formRenderProps: FormRenderProps) =>
          (
            <FormElement>
              <Field
                name='truckPlate'
                component={FormInput}
                label='Рег №'
                placeholder='Рег №'
                validator={StringValidator}
              />
              <Field
                name='truckLoad'
                component={FormNumericTextBox}
                label='Товар'
                placeholder='Товар'
                validator={StringValidator}
              />
              <Field
                name='truckCapacity'
                component={FormNumericTextBox}
                label='Капацитет'
                placeholder='Капацитет'
                validator={StringValidator}
              />
              <Field
                name={type === 'add' ? 'firmABId' : 'parentFirm'}
                component={FormComboBox}
                data={firms}
                defaultValue={selectedFirm}
                textField='firmABName'
                label='Фирма'
                placeholder='Фирма'
                validator={StringValidator}
                onChange={({ value }: ComboBoxChangeEvent) => {
                  if (value === null) {
                    return;
                  }
                  formRenderProps.onChange('firmABId', { value: value.firmABId });
                }}
              />
              <Field
                name='comment'
                component={FormInput}
                label='Бележка'
                placeholder='Бележка'
              />
              <div className='k-form-buttons'>
                <Button
                  name='Add new'
                  type='submit'
                  title='Добави'
                  primary
                  disabled={!formRenderProps.allowSubmit}
                  icon='save'
                >
                  {localizationService.toLanguageString(ADD, '')}
                </Button>
                <Button
                  name='Cancel'
                  type='submit'
                  title='Откажи'
                  primary
                  look='outline'
                  icon='cancel'
                  onClick={close}
                >
                  {localizationService.toLanguageString(CANCEL, '')}
                </Button>
              </div>
            </FormElement>
          )}
      />
    </Dialog>
  );
};

TruckForm.defaultProps = {
  defaultItem: {}
};

export default TruckForm;
