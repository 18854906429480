import React from 'react';
import { useLocalization } from '@progress/kendo-react-intl';
import { Loader } from '@progress/kendo-react-indicators';
import { useAppSelector } from '../../app/StoreHooks';
import GeneralInfoHoldingProfile from '../GeneralInfoHoldingProfile/GeneralInfoHoldingProfile';
import {
  selectHoldingProfile,
  selectLoading
} from '../../slices/HoldingSlice';
import { HOLDING_PROFILE } from '../../languages/languages';
import { holdingCreationNotification } from '../../utils/utilities';
import HoldingStructureContainer from '../HoldingStructureContainer/HoldingStructureContainer';

const HoldingProfileContainer = () => {
  const localizationService = useLocalization();

  const holding = useAppSelector(selectHoldingProfile);
  const holdProfileLoading = useAppSelector(selectLoading);

  return (
    <div>
      <h1 className='page-title'>
        {localizationService.toLanguageString(HOLDING_PROFILE, '')}
      </h1>
      {holdProfileLoading ? (
        <Loader size='large' type='pulsing' className='k-centered' />
      ) : (
        <div className='content-wrapper section'>
          <div id='general-information'>
            <GeneralInfoHoldingProfile data={holding} />
          </div>
          {holding?.holdingName.length < 1 ? (
            <div id='holdingPlaceholder'>
              <div id='infoBoxMessage'>
                <div className='icon'>
                  <i className='fas fa-info' />
                </div>
                <div className='txt'>
                  <h3>{holdingCreationNotification}</h3>
                </div>
              </div>
            </div>
          ) : (
            <HoldingStructureContainer />
          )}
        </div>
      )}
    </div>
  );
};

export default HoldingProfileContainer;
