import { gql } from '@apollo/client';

import CreateGQLClient, { getGraphQLClient } from '../app/Client';
import { IListResult } from '../interfaces/request';
import { IAnimalInButchery } from '../slices/data/ButcheryProcessSliceData';

const client = getGraphQLClient() || CreateGQLClient();
const animalsInButcheryQuery = gql`
query animalsInButchery($enterpriseId: Int) {
  animalsInButchery(enterpriseId: $enterpriseId) {
    items {
      animalId,
      arrivalDate,
      animalType,
      cattleBreed,
      sheepBreed,
      dateOfBirth,
      isCastrated,
      lID,
      sex
    }
  }
}
`;

class ButcheryService {
  getAnimmalsInButcheryObject(enterpriseId: number): Promise<IAnimalInButchery[]> {
    return new Promise((resolve, reject) => {
      client
        .query<{ enterpriseId: number }, IListResult<IAnimalInButchery>>('animalsInButchery', animalsInButcheryQuery, { enterpriseId })
        .then((data) => {
          resolve(data.items || []);
        })
        .catch(reject);
    });
  }
}

export default new ButcheryService();