/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/StoreHooks';
import {
  EDIT_MORTALITY_LOG,
  GetMortalityLogs,
  GET_ALL_MORTALITY_LOGS,
  MortalityLogInputType,
  MortalityLogType,
  SaveMortalityLogType,
  SAVE_MORTALITY_LOG
} from './data/MortalityLogSliceData';
import {
  createAsyncThunkGeneric,
  createAsyncThunkGenericVoid
} from './GenericThunks';

type MortalityLogStateType = {
  mortalityLog: MortalityLogType[];
  mortalityLogLoading: boolean;
};

const initialState: MortalityLogStateType = {
  mortalityLog: [],
  mortalityLogLoading: false
};

// Thunks
export const getAllMortalityLogs = createAsyncThunkGenericVoid(
  'mortalityLog/getAllMortalityLogs',
  async ({ client }) => {
    const response = await client.query<void, GetMortalityLogs>(
      'animalHealthEvent',
      GET_ALL_MORTALITY_LOGS
    );
    return response?.items ? response?.items : [];
  }
);

export const saveMortalityLog = createAsyncThunkGeneric<
MortalityLogInputType,
MortalityLogType
>('mortalityLog/saveMortalityLog', async ({ client, input }) =>
  client.mutate<SaveMortalityLogType, MortalityLogType>(
    'addAnimalHealthEvent',
    SAVE_MORTALITY_LOG,
    { animalHealthEventInput: input }
  ));

export const editMortalityLog = createAsyncThunkGeneric<
MortalityLogInputType,
MortalityLogType
>('mortalityLog/editMortalityLog', async ({ client, input }) =>
  client.mutate<SaveMortalityLogType, MortalityLogType>(
    'updateAnimalHealthEvent',
    EDIT_MORTALITY_LOG,
    { animalHealthEventInput: input }
  ));

export const MortalityLogSlice = createSlice({
  name: 'mortalityLog',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllMortalityLogs.pending, (state) => {
        state.mortalityLogLoading = true;
      })
      .addCase(getAllMortalityLogs.fulfilled, (state, { payload }) => {
        state.mortalityLogLoading = false;
        state.mortalityLog = payload.map((item) =>
          ({
            ...item,
            eventDate: new Date(item?.eventDate)
          }));
      })
      .addCase(saveMortalityLog.pending, (state) => {
        state.mortalityLogLoading = true;
      })
      .addCase(saveMortalityLog.fulfilled, (state, { payload }) => {
        state.mortalityLogLoading = false;
        state.mortalityLog = [
          ...state.mortalityLog,
          {
            ...payload,
            eventDate: payload?.eventDate
          }
        ];
      })
      .addCase(editMortalityLog.pending, (state) => {
        state.mortalityLogLoading = true;
      })
      .addCase(editMortalityLog.fulfilled, (state, { payload }) => {
        state.mortalityLogLoading = false;
        const updIndx = state.mortalityLog.findIndex((item) =>
          item.animalHealthEventId === payload?.animalHealthEventId);
        state.mortalityLog[updIndx] = {
          ...payload,
          eventDate: payload?.eventDate
        };
      });
  }
});

// Selectors
export const selectAllMortalityLogEntries = (state: RootState) =>
  state.mortalityLog.mortalityLog;
export const selectMortalityLogLoadingState = (state: RootState) =>
  state.mortalityLog.mortalityLogLoading;

// Reducers
export default MortalityLogSlice.reducer;
