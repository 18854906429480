import { Button } from '@progress/kendo-react-buttons';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import {
  Field,
  Form,
  FormElement,
  FormRenderProps
} from '@progress/kendo-react-form';
import { useLocalization } from '@progress/kendo-react-intl';
import React from 'react';
import { useAppSelector } from '../../app/StoreHooks';
import {
  SAVE,
  CANCEL,
  FODDER_DATE,
  FODDER_NAME,
  FODDER_BATCH_NUMBER,
  FODDER_AMOUNT_BOUGHT,
  FODDER_COURIER,
  FODDER_NOTE,
  FODDER_CATEGORY
} from '../../languages/languages';
import {
  categories,
  PurchasedFodderType,
  SaveFodderType
} from '../../slices/data/FodderLogSliceData';
import { selectSupplierFirms } from '../../slices/FodderLogSlice';
import {
  FormComboBox,
  FormDatePicker,
  FormInput,
  FormNumericTextBox
} from '../FormComponents/FormComponents';

type PurchasedFodderFormProps = {
  close: () => void;
  submitForm: (formData: SaveFodderType | PurchasedFodderType) => void;
  editItem?: PurchasedFodderType;
};

const PurchasedFodderForm = ({
  close,
  submitForm,
  editItem
}: PurchasedFodderFormProps) => {
  const firms = useAppSelector(selectSupplierFirms);
  const localizationService = useLocalization();

  const selectedCategory = categories.find(
    (categ) =>
      categ?.value === editItem?.feedCategory
  );

  const selectedFirm = firms.find((firm) =>
    firm?.firmABId === editItem?.firm?.firmABId);

  return (
    <Form
      initialValues={editItem}
      onSubmit={submitForm}
      render={(formRenderProps: FormRenderProps) =>
        (
          <FormElement id='fodderForm'>
            <Field
              name='feedDiaryDate'
              format='MMMM yyyy'
              component={FormDatePicker}
              label={localizationService.toLanguageString(FODDER_DATE, '')}
            />
            <Field
              name='feedName'
              component={FormInput}
              label={localizationService.toLanguageString(FODDER_NAME, '')}
            />
            <Field
              name='lotNumber'
              component={FormInput}
              label={localizationService.toLanguageString(
                FODDER_BATCH_NUMBER,
                ''
              )}
            />
            <Field
              name='feedCategory'
              component={FormComboBox}
              data={categories.map((category) =>
                ({
                  label: localizationService.toLanguageString(category?.label, ''),
                  value: category?.value
                }))}
              defaultValue={{
                label: localizationService.toLanguageString(selectedCategory?.label, ''),
                value: selectedCategory?.value
              }}
              textField='label'
              label={localizationService.toLanguageString(FODDER_CATEGORY, '')}
              onChange={({ value }: ComboBoxChangeEvent) => {
                if (value === null) {
                  return;
                }
                formRenderProps.onChange('feedCategory', {
                  value: value?.value
                });
              }}
            />
            <Field
              name='quantity'
              component={FormNumericTextBox}
              label={localizationService.toLanguageString(
                FODDER_AMOUNT_BOUGHT,
                ''
              )}
            />
            <Field
              name='firmABId'
              component={FormComboBox}
              data={firms}
              defaultValue={selectedFirm}
              textField='firmABName'
              label={localizationService.toLanguageString(FODDER_COURIER, '')}
              onChange={({ value }: ComboBoxChangeEvent) => {
                if (value === null) {
                  return;
                }
                formRenderProps.onChange('firmABId', { value: value?.firmABId });
              }}
            />
            <Field
              name='comment'
              component={FormInput}
              label={localizationService.toLanguageString(FODDER_NOTE, '')}
            />
            <div className='k-form-buttons'>
              <Button
                name='PurchasedFodderSend'
                type='submit'
                primary
                icon='save'
                title={localizationService.toLanguageString(SAVE, '')}
                disabled={!formRenderProps.allowSubmit}
              >
                {localizationService.toLanguageString(SAVE, '')}
              </Button>
              <Button
                onClick={close}
                name='PurchasedFodderCancel'
                type='submit'
                primary
                look='outline'
                icon='cancel'
                title={localizationService.toLanguageString(CANCEL, '')}
              >
                {localizationService.toLanguageString(CANCEL, '')}
              </Button>
            </div>
          </FormElement>
        )}
    />
  );
};

PurchasedFodderForm.defaultProps = {
  editItem: {}
};

export default PurchasedFodderForm;
