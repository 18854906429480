/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../app/StoreHooks';
import { IAnimalRegistrationData, SAVE_REGISTRATIONS, SaveRegistrationData, UNREGISTER, UnregisterInput, UnregisterInputData } from './data/MovementSliceData';
import { createAsyncThunkGeneric } from './GenericThunks';

interface MovementsState {
  isLoading: boolean;
}

const initialState: MovementsState = {
  isLoading: false
};

// Async Thunks
export const createRegistrations = createAsyncThunkGeneric<
  Array<IAnimalRegistrationData>,
  Array<IAnimalRegistrationData>>(
    'movements/saveRegistrations',
    async ({ client, input }) =>
      client.query<SaveRegistrationData, Array<IAnimalRegistrationData>>(
        'registration',
        SAVE_REGISTRATIONS,
        {
          registrationEventInput:
          {
            animals: input
          }
        }
      )
  );

export const unregister = createAsyncThunkGeneric<
  UnregisterInputData,
  UnregisterInput>(
    'movements/unregisterAnimals',
    ({ client, input }) =>
      client.query<UnregisterInput, UnregisterInput>(
        'unregisterAnimals',
        UNREGISTER,
        {
          unregisterEventInput: input
        }
      )
  );

// Slice
const MovementSlice = createSlice({
  name: 'movements',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(createRegistrations.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createRegistrations.fulfilled, (state) => {
        state.isLoading = false;
      });
  }
});

// Selectors
export const selectLoading = (state: RootState) =>
  state.movement.isLoading;

// Export Reducer
export default MovementSlice.reducer;
