import * as React from 'react';
import { ProgressBar, ProgressBarProps } from '@progress/kendo-react-progressbars';

interface ProgressBarContainerProps {
  value: number;
}

const ProgressBarContainer = ({ value }: ProgressBarContainerProps) =>
  (
    <ProgressBar
      value={value}
      label={(props: ProgressBarProps) =>
        (
          <strong>
            {props.value}
            %
          </strong>
        )}
    />
  );

export default ProgressBarContainer;
