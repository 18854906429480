import Keys, { ANIMAL, FEMALE_ABBR, MALE_ABBR, SAVE_IMAGE, SAVE_SVG, STEP_FROM } from './keys/keys';
import { ANIMALS, ANIMALS_SELECTION, APPLY_FOR_ALL_ANIMALS, CHOSEN, NO_ANIMAL_OBJECT_SELECTED, NO_REASON_ANIMALS_MSG, NO_RETURN_MSG, PROCESS_FORWARD_CONFIRM, REASON, UNREGISTER, UNREGISTER_CHRONOLOGY_MSG, UNREGISTER_CONFIRM_MSG, UNREGISTER_NEW, UNREGISTER_REASON, UNREGISTER_SUCCESS_MSG, UNREGISTER_TITLE } from './keys/movements.keys';

// VALIDATORS
export const REQUIRED_FIELD = 'RequiredField';

// Notifications
export const OTHER_EVENT = 'OtherEvent';
export const BUY_EVENT = 'BuyEvent';

// Grid variables
export const ADD = 'Add';
export const ADD_NEW = 'AddNew';
export const EDIT_SUBMISSION = 'EditSubmission';
export const ADD_NEW_COLUMN = 'AddNewColumn';
export const COLUMNS = 'Columns';
export const ACTIVE = 'Active';
export const INACTIVE = 'Inactive';
export const EXPORT = 'Export';
export const REMOVE = 'Remove';
export const ADD_MORE = 'AddMore';
export const ACCEPT = 'Accept';

// General
export const REVISE = 'Revise';
export const PREVIOUS = 'Previous';
export const NEXT = 'Next';
export const YES = 'Yes';
export const NO = 'No';
export const DAYS = 'Days';

// Dashborad variables
export const WIDGET_DESCRIPTION_PLACEHOLDER = 'WidgetDescriptionPlaceholder';
export const SALE_TRADE_CHART_TITLE = 'SaleTradeChartTitle';
export const PROFILE_STATUS_TITLE = 'ProfileStatusTitle';
export const PROFILE_RATING_TITLE = 'ProfileRatingTitle';
export const PROFILE_COMPLETION_TITLE = 'ProfileCompletionTitle';
export const HISTORY_CHART_TITLE = 'HistoryChartTitle';
export const SEUROP_TITLE = 'SEUROPTITLE';
export const ANIMAL_ACCEPTANCE_TITLE = 'AnimalAcceptanceTitle';
export const PLACEHOLDER_TITLE = 'PlaceholderTitle';
export const STATUS_CONTENT = 'StatusContent';

// Drawer variables
export const DASHBOARD_TITLE = 'DashboardTitle';
export const LOGS_TITLE = 'LogsTitle';
export const AMBULATORY_LOGS_TITLE = 'AmbulatoryLogsTitle';
export const ARTIFICIAL_INSEMENATION = 'ArtificialInsemenation';
export const PASTURE_LOG_TITLE = 'PastureLogTitle';
export const MORTALITY_LOG_TITLE = 'MortalityLogTitle';
export const CHEMICAL_TREATMENT_LOG_TITLE = 'ChemicalTreatementLogTitle';
export const PEST_CONTROL_LOG_TITLE = 'PestControlLogTitile';
export const USED_PESTICIDE_LOG_TITLE = 'UsedPesticideLogTitle';
export const ANIMALS_IN_FARM_TABLE_TITLE = 'AnimalsInFarmTableTitle';
export const ANIMALS_IN_BUTCHERY_TABLE_TITLE = 'AnimalsInButcheryTableTitle';
export const BUTCHERED_ANIMALS_TABLE = 'ButcheredAnimalsTable';
export const DEALS_TITLE = 'DealsTitle';
export const ADDRESS_BOOK_TITLE = 'AddressBookTitle';
export const HOLDING_PROFILE_TITLE = 'HoldingProfileTitle';
export const LINK_PLACEHOLDER_TITLE = 'LinkPlaceHolderTitle';
export const MOVEMENTS_TITLE = 'MovementsTitle';
export const MOVEMENTS_REGISTRATION_TITLE = 'MovementsRegistrationTitle';
export const MOVEMENTS_UNREGISTRATION_TITLE = 'MovementsUnregistrationTitle';
export const MOVEMENTS_SALE_TITLE = 'MovementsSaleTitle';

// AppBar container
export const SEARCH_BAR = 'SearchBar';
export const FERMER_TITLE = 'FermerTitle';
export const PROCESSOR_TITLE = 'ProcessorTitle';
export const TRADER_TITLE = 'TraderTitle';
export const CANDIDATE_TITLE = 'CandidateTitle';

// Holding Profile
export const HOLDING_PROFILE = 'HoldingProfile';
export const FIRMS_TAB = 'FirmsTab';
export const ANOBJ_TAB = 'AnimalObjectTab';
export const ENTERPRISE_TAB = 'EnterpriseTab';
export const SHOP_TAB = 'ShopTab';
export const TRADEMARK_TAB = 'TrademarkTab';

// General Info Holding Profile
export const HOLDING_NAME = 'HoldingName';
export const HOLDING_STRUCTURE = 'HoldingStructure';
export const HOLDING_SAVE = 'HoldingSave';
export const HOLDING_DECLINE = 'HoldingDecline';
export const IS_FARM = 'isFarm';
export const IS_FEEDLOT = 'isFeedlot';
export const IS_BUTCHERY = 'isButchery';
export const IS_ABATTOIR = 'isAbattoir';
export const IS_SHOP = 'isShop';
export const CATTLE = 'cattle';
export const SHEEP = 'sheep';
export const GENERAL_INFO = 'GeneralInfo';

// General Forms
export const STATUS = 'Stats';
export const SUBMIT_FORM = 'SubmitForm';
export const CLEAR_FORM = 'ClearForm';
export const UPDATE = 'Update';
export const DECLINE = 'Decline';
export const SEND = 'Send';
export const SAVE = 'Save';
export const EDIT = 'Edit';
export const CANCEL = 'Cancel';
export const CONFIRM = 'Confirm';
export const ADDRESS_PLACEHOLDER = 'AddressPlaceholder';
export const AMOUNT_PLACEHOLDER = 'AmountPlaceholder';
export const CITY_PLACEHODLDER = 'CityPlaceholder';
export const OWNER_PLACEHOLDER = 'OwnerPlaceholder';
export const DATE = 'Date';
export const CHOOSE_DROPDOWN_OPTION = 'ChooseDropdownOption';

// Firm Tab
export const EIK = 'EIK';
export const FIRM_NAME = 'FirmName';
export const FIRM_COUNTRY = 'FirmCountry';
export const FIRM_CITY = 'FirMCity';
export const FIRM_ADDRESS = 'FirmAddress';
export const FIRM_MOL = 'FirmMol';

// Animal Object Form
export const AO_STATUS = 'AnimalObjectStatus';
export const AO_NAME = 'AnimalObjectName';
export const AO_NUMBER = 'AnimalObjectNumber';
export const AO_TRACES = 'AnimalObjectTraces';
export const AO_CITY = 'AnimalObjectCity';
export const AO_ADDRESS = 'AnimalObjectAddress';
export const AO_CAPACITY = 'AnimalObjectCapacity';
export const AO_BUILDINGS = 'AnimalObjectBuildings';
export const AO_BACREAGE = 'AnimalObjectBuildingsAcreage';
export const AO_PASTURES = 'AnimalObjectPastures';
export const AO_PACREAGE = 'AnimalObjectPasturesAcreage';
export const AO_OWNER = 'AnimalObjectOwner';
export const AO_BRANCH = 'AnimalObjectBranch';
export const AO_CATTLE = 'AnimalObjectCattle';
export const AO_SHEEP = 'AnimalObject';

// Enterprise Form
export const ENT_NAME = 'EnterpriseName';
export const ENT_NUMBER = 'EnterpriseNumber';
export const ENT_TYPE = 'EnterpriseType';
export const ENT_TYPE_PLACEHOLDER = 'EnterpriseTypePlaceholder';
export const ENT_OWNER = 'EnterpriseOwner';
export const ENT_ADDRESS = 'EnterpriseAddress';

// Shops Form
export const SHOP_NAME = 'ShopName';
export const SHOP_NUMBER = 'ShopNumber';
export const SHOP_TYPE = 'ShopType';
export const SHOP_OWNER = 'ShopOwner';
export const SHOP_ADDRESS = 'ShopAddress';
export const SHOP_DEFAULT_VALUE = 'ShopDefaultValue';
export const SHOP_SMALL_SHOP = 'ShopSmallShop';
export const SHOP_MTS = 'ShopModernTradingShop';

// Trademark Form
export const TM_NAME = 'TrademarkName';
export const TM_NAME_PLACEHOLDER = 'TrademarkNamePlaceholder';
export const TM_REG = 'TrademarkRegistration';
export const TM_LINK = 'TrademarkLink';
export const TM_LINK_PLACEHOLDER = 'TrademarkLinkPlaceholder';
export const TM_IMAGE = 'TrademarkImage';

// Trademark Grid
export const TMG_STATUS = 'TrademarkGridName';
export const TMG_NAME = 'TrademarkGridName';
export const TMG_REG = 'TrademarkGridRegistrationNumber';
export const TMG_LINK = 'TrademarkGridLink';
export const TMG_IMAGE = 'TrademarkGridImage';

// Animal Preview
export const SELECTED_ANIMALS_PREVIEW = 'SelectedAnimalsPreview';

// Sell Animals Component
// Steps
export const START = 'Start';
export const WEIGTHS = 'Weights';
export const PRICING = 'Pricing';
// Deal details
export const FIRMS_DETAILS = 'FirmsDetails';
export const TRANSPORT_DETAILS = 'TransportDetails';
export const SALE_INFO = 'SaleInfo';
export const SELLING_FIRM = 'SellingFirm';
export const SELL_FIRM_NAME = 'SellFirmName';
export const SELL_FIRM_EIK = 'SellFirmEIK';
export const SELL_FIRM_MOL = 'SellFirmMOL';
export const BUYING_FIRM = 'BuyingFirm';
export const BUY_FIRM_NAME = 'BuyFirmName';
export const BUY_FIRM_EIK = 'BuyFirmEIK';
export const BUY_FIRM_MOL = 'BuyFirmMOL';
export const QUANTITY_INFO = 'QuantityInfo';
export const VEHICLE_WEIGHT = 'VehicleWeight';
export const EMPTY_VEHICLE_WEIGHT = 'EmptyVehicleWeight';
export const VEHICLE_WEIGHT_PLUS_ANIMALS = 'VehicleWeightPlusAnimals';
export const ANIMAL_WEIGHT = 'AnimalWeight';
export const AVERAGE_WEIGHT = 'AverageWeight';
export const INDIVIDUAL_WEIGHT = 'IndividualWeight';
export const ANIMAL_NET_WEIGHT = 'AnimalNetWeight';
export const AVERAGE_GROUP_NET_WEIGHT = 'AverageGroupNetWeight';
export const AVERAGE_ANIMAL_NET_WEIGHT = 'AverageAnimalNetWeight';
export const FINANCIAL_INFO = 'FinancialInfo';
export const PRICE_PER_KILO = 'PricePerKilo';
export const PRICE_PER_ANIMAL = 'PricePerAnimal';
export const GROUP_TOTAL_PRICE = 'GroupTotalPrice';
export const AVERAGE_ITEM_PRICE = 'AverageItemPrice';
export const TRANSPORTATION_INFO = 'TransportationInfo';
export const TRANSPORTATION_DRIVER = 'TransportationDriver';
export const TRANSPORTATION_DRIVER_PIN = 'TransportationDriverPin';
export const TRANSPORTATION_VEHICLE_REG = 'TransportationVehicleReg';
export const TRANSPORTATION_DATE = 'TransportationDate';
export const TRANSPORTATION_SERIAL_NUMBER = 'TransportationSerialNumber';
export const ARRIVAL_DATE = 'ArrivalDate';
export const DEAL_PRICING = 'DealPricing';
export const ENTER_PRICE_PER_HEAD = 'EnterPricePerHead';
export const ENTER_PRICE_PER_ANIMAL = 'EnterPricePerAnimal';

// Select Animal Opearations
export const ANIMAL_OPERATIONS = 'AnimalOperations';
export const SELECT_OPERATION = 'SelectOperation';
export const SELL_OPERATION = 'SellOperation';
export const VETERINARY_INSPECTION = 'VeterinaryInspection';
export const VETERINARY_INSPECTOR = 'VeterinaryInspector';

// Address Book
export const ADDRESS_BOOK_CONTRAGENTS = 'AddressBookContragents';
export const ADDRESS_BOOK_EMPLOYEES = 'AddressBookEmployees';
export const ADDRESS_BOOK_TRUCKS = 'AddressBookTrucks';
export const ADDRESS_BOOK_FIRMS = 'AddressBookFirms';
export const ADDRESS_BOOK_NAME = 'AddressBookName';
export const ADDRESS_BOOK_ROLE = 'AddressBookRole';
export const ADDRESS_BOOK_PHONE = 'AddressBookPhone';
export const ADDRESS_BOOK_EMAIL = 'AddressBookEmail';
export const ADDRESS_BOOK_STATUS = 'AddressBookStatus';
export const ADDRESS_BOOK_FIRM = 'AddressBookFIrm';
export const ADDRESS_BOOK_FIRM_TYPE = 'AddressBookFirmType';
export const ADD_NEW_EMPLOYEE = 'AddNewEmployee';
export const EDIT_EMPLOYEE = 'EditEmployee';

// Animal Table
export const CATTLE_TAB = 'CattleTab';
export const SHEEP_TAB = 'SheepTab';
export const ANIMAL_ENTRY = 'Animal Entry';
export const ANIMAL_EXIT = 'AnimalExit';

// Deals
export const DEAL = 'Deal';
export const DEAL_ACCEPT_STATUS = 'DealAcceptStatus';
export const DEAL_REJECT_STATUS = 'DealRejectStatus';
export const DEAL_PARTIAL_ACCEPT_STATUS = 'DealPartialAcceptStatus';
export const DEAL_PENDING_STATUS = 'DealPendingStatus';
export const DEAL_ACCEPT = 'DealAccept';
export const DEAL_ACCEPT_PARTIAL = 'DealAcceptPartial';
export const DEAL_REJECT = 'DealReject';
export const DEAL_STATUS = 'DealStatus';
export const DEAL_NUMBER = 'DealNumber';
export const DEAL_CREATOR = 'DealCreator';
export const DEAL_DATE = 'DealDate';
// DealGrid
export const DEAL_ANIMAL_INFO = 'DealAnimalInfo';
export const DEAL_ANIMAL_WEIGHT = 'DealAnimalWeight';
export const DEAL_ANIMAL_PPH = 'DealAnimalPPH';
export const DEAL_ANIMAL_PPK = 'DealAnimalPPK';
export const DEAL_ANIMAL_PPHK = 'DealAnimalPPHK';
export const DEAL_ANIMAL_ARRIVAL_WEIGHT = 'DealAnimalArrivalWeight';
export const DEAL_ANIMAL_NOTE = 'DealAnimalNote';

// Kill Data
export const KILL_DATA_OPERATION = 'KillDataOption';
export const KILL_DATA_MATADOR = 'KillDataMatador';

// Carcass Quality Grading
export const ANIMAL_SELECTION_PLACEHOLDER = 'AnimalSelectionPlaceholder';
export const CARCASS_QUALITY_GRADE = 'CarcassQualityGrade';
export const GRADED_ANIMAL_IDENTIFICATOR = 'GradedAnimalIdentificator';
export const INSPECTION_DATE = 'InspectionDate';
export const INSPECTOR = 'Inspector';
export const AGE_CLASS = 'AgeClass';
export const UNKNOWN_CLASS = 'UnknownClass';
export const Z_CLASS = 'ZClass';
export const Z_CLASS_TITLE = 'ZClassTitle';
export const A_CLASS = 'AClass';
export const A_CLASS_TITLE = 'AClassTitle';
export const B_CLASS = 'BClass';
export const B_CLASS_TITLE = 'BClassTitle';
export const C_CLASS = 'CClass';
export const C_CLASS_TITLE = 'CClassTitle';
export const D_CLASS = 'DClass';
export const D_CLASS_TITLE = 'DClassTitle';
export const E_CLASS = 'EClass';
export const E_CLASS_TITLE = 'EClassTitle';
export const MUSCLE_CLASS = 'MuscleClass';
export const MUSCLE_CLASSIFICATION = 'MuscleClassification';
export const FAT_CLASS = 'FatClass';
export const FAT_COLOR = 'FatColor';
export const OSSIFI_SCORE = 'OssifiScore';
export const TEETH_FORMULA = 'TeethFormula';
export const MEAT_COLOR = 'MeatColor';
export const CARCASS_YIELD = 'CarcassYield';
export const CARCASS_QUALITY = 'CarcassQuality';
export const FAT_WHITE = 'FatWhite';
export const FAT_CREAMY_WHITE = 'FatCreamyWhite';
export const FAT_SLIGHTLY_YELLOW = 'FatSlightlyYellow';
export const FAT_MODERATELY_YELLOW = 'FatModeratelyYellow';
export const FAT_YELLOW = 'FatYellow';
export const TEETH_NONE = 'TeethNone';
export const CARCASS_QUALITY_PRIME = 'CarcassQualityPrime';
export const CARCASS_QUALITY_CHOICE = 'CarcassQualityChoice';
export const CARCASS_QUALITY_SELECT = 'CarcassQualitySelect';
export const CARCASS_QUALITY_STANDARD = 'CarcassQualityStandart';
export const CARCASS_QUALITY_COMMERCIAL = 'CarcassQualityCommercial';
export const CARCASS_QUALITY_UTILITY = 'CarcassQualityUtility';
export const CARCASS_QUALITY_CUTTER = 'CarcassQualityCutter';
export const CARCASS_QUALITY_CANNER = 'CarcassQualityCanner';

// AnimalCooling
export const ANIMAL_COOLING = 'AnimalCooling';
export const ANIMAL_COOLING_TITLE = 'AnimalCoolingTitle';
export const FIRST_CHECK = 'FirstCheck';
export const SECOND_CHECK = 'SecondCheck';
export const COOLING_START = 'CoolingStart';
export const PH_VALUE = 'CoolingPHValue';
export const TEMPERATURE = 'CoolingTemperature';

// Logs
// Artificial Insemination Log
export const COW_LID = 'CowLID';
export const FERTILIZATION_TYPE = 'FertilizationType';
export const SIRE_LID = 'SireLID';
export const FERTILIZATION_START = 'FertilizationStart';
export const FERTILIZATION_END = 'FertilizationEnd';
export const PREGNANCY_TEST_DATE = 'PregnancyTestDate';
export const TEST_RESULT = 'TestResult';
export const PREGNANCY_STAGE = 'PregnancyStage';
export const EXPECTED_DUE_DATE = 'ExpectedDueDate';
export const PREGNANCY_RESULT_YES = 'PregnancyResultYes';
export const PREGNANCY_RESULT_NO = 'PregnancyResultNo';
export const PREGNANCY_RESULT_UNID = 'PregnancyResultUnid';
export const FERTILIZATION_NATURALLY = 'FertilizationNaturally';
export const FERTILIZATION_ARTIFICIALLY = 'FertilizationArtificially';
// PastureLog
export const ANIMAL_COUNT = 'AnimalCount';
export const HERD_GROUP = 'HerdGroup';
export const PASTURE_NAME = 'PastureName';
export const PASTURE_DATE_OF_ENTRY = 'PastureDateOfEntry';
export const PASTURE_DATE_OF_EXIT = 'PastureDateOfExit';
export const DAYS_SPENT_IN_PASTURE = 'DatesSpentInPasture';
export const PASTURE_ACREAGE = 'PastureAcreage';
export const PASTURE_GRASS_HEIGHT_IN = 'PastureGrassHeightIn';
export const PASTURE_GRASS_HEIGHT_OUT = 'PastureGrassHeightOut';
// PurchasedMedicine
export const PURCHASED_MEDICINE_LOG_TITLE = 'PurchasedMedicineLogTitle';
export const PURCHASED_MEDICINE_LOG_ENTRY_DATE = 'PurchasedMedicineLogEntryDate';
export const PURCHASED_MEDICINE_NAME = 'PurchasedMedicineName';
export const MEDICINE_CATEGORY_NAME = 'MedicineCategoryName';
export const PURCHASED_MEDICINE_AMOUNT = 'PurchasedMedicineAmount';
export const PURCHASED_MEDICINE_DELIVERED_BY = 'PurchasedMedicineDeliveredBy';
export const MEDICINE_WITHDRAW_TITLE = 'MedicineWithdrawTitle';
export const PURCHASED_MEDICINE_WITHDRAW_PERIOD_MILK = 'PurchasedMedicineWithdrawPeriodMilk';
export const PURCHASED_MEDICINE_WITHDRAW_PERIOD_MEAT = 'PurchasedMedicineWithdrawPeriodMeat';
export const PURCHASED_MEDICINE_BATCH_NUMBER = 'PurchasedMedicineBatchNumber';
export const PURCHASED_MEDICINE_EXPIRY_DATE = 'PurchasedMedicineExpiryDate';
export const PURCHASED_MEDICINE_NOTE = 'PurchasedMedicineNote';
// Fodder Log
export const PURCHASED_FODDER_TITLE = 'PurchasedFodderTitle';
export const PRODUCED_FODDER_TITLE = 'ProducedFodderTitle';
export const FODDER_LOG_TITLE = 'FodderLogTitle';
export const FODDER_DATE = 'Fodder Date';
export const FODDER_NAME = 'FodderName';
export const FODDER_CATEGORY = 'FodderCategory';
export const FODDER_BATCH_NUMBER = 'FodderBatchNumber';
export const FODDER_AMOUNT_PRODUCED = 'FodderAmountProduced';
export const FODDER_AMOUNT_BOUGHT = 'FodderAmountBought';
export const FODDER_COURIER = 'FodderCourier';
export const FODDER_NOTE = 'FodderNote';
export const FODDER_SELF_PRODUCED = 'FodderSelfProduced';
export const FODDER_TYPE = 'FodderType';
// FodderCategories
export const UNKNOWN_CATEGORY = 'UnknownCategory';
export const WETFEED_CATEGORY = 'WetfeedCategory';
export const WATER_CATEGORY = 'WaterCategory';
export const OTHER_CATEGORY = 'OtherCategory';
export const LONGFIBER_CATEGORY = 'LongfiberCategory';
export const CONCENTRATE_CATEGORY = 'ConcentrateCategory';
export const ROOTS_CATEGORY = 'RootsCategory';
export const SOFTFIBER_CATEGORY = 'SoftfiberCategory';
export const VITAMINS_CATEGORY = 'VitaminsCategory';
export const LIQUIDS_CATEGORY = 'LiquidsCategory';

// PurchasedMedicine categories
export const ANTIBIOTIC_CATEGORY = 'AntibioticCategory';
export const VACCINE_CATEGORY = 'VaccineCategory';
export const VITAMIN_CATEGORY = 'VitaminCategory';
export const CALCIUM_CATEGORY = 'CalciumCategory';
export const PROBIOTIC_CATEGORY = 'ProbioticCategory';
export const ANITIINFLAMMATORY_CATEGORY = 'AntiinflammatoryCategory';
export const ANTIPARASITIC_CATEGORY = 'AntiparasiticCategory';
export const OINTMENT_CATEGORY = 'OintmentCategory';
export const HORMONAL_CATEGORY = 'HormonalCategory';
export const SUPPLIMENT_CATEGORY = 'SupplimentCategory';

// Mortality log
export const ANIMAL_MORTALITY = 'AnimalMortality';
export const ANIMAL_HEALTH_PROBLEM = 'AnimalHealthProblem';
export const ANIMAL_TERAPY = 'AnimalTerapy';
// Animal Information
export const MALE = 'Male';
export const FEMALE = 'Female';
export const CATTLE_TYPE = 'CattleType';
export const SHEEP_TYPE = 'Sheeptype';
export const ANIMAL_SEX = 'AnimalSex';
export const ANIMAL_BREED = 'AnimalBreed';
export const ANIMAL_AGE = 'AgeInMonths';
export const ANIMAL_LID = 'AnimalLID';

// Ambulatory Log
export const AMBULATORY_NUMBER = 'AmbulatoryNumber';
export const AMBULATORY_DATE = 'AmbulatoryDate';
export const AMBULATORY_ANIM_OBJ = 'AmbulatoryAnimalObj';
export const ANIMAL_TYPE = 'AnimalType';
export const AMBULATORY_CLINICAL_DATA = 'AmbulatoryClinicalData';
export const AMBULATORY_DIAGNOSTIC_TEST = 'AmbulatoryDiagnosticTest';
export const AMBULATORY_DIAGNOSIS = 'AmbulatoryDiagnosis';
export const AMBULATORY_MEDICINES = 'AmbulatoryMedicines';
export const AMBULATORY_DOSE = 'AmbulatoryDose';
export const AMBULATORY_WITHDRAWAL_PERIOD = 'AmbulatoryWithdrawalPeriod';
export const AMBULATORY_DIAGNOSIS_OUT = 'AmbulatoryDiagnosisOout';
export const AMBULATORY_NOTE = 'AmbulatoryNote';

// Chemical Treatement Log
export const CHEMICAL_TREATEMENT_PEST = 'ChemicalTreatementPest';
export const CHEMICAL_TREATEMENT_DOSE = 'ChemicalTreatementDose';
export const CHEMICAL_TREATEMENT_TREATED_AREA = 'ChemicalTreatementTreatedArea';
export const CHEMICAL_TREATEMENT_TECHNIQUE = 'ChemicalTreatementTechnique';
export const CHEMICAL_TREATEMENT_CHEMICAL = 'ChemicalTreatementChemical';
export const CHEMICAL_TREATEMENT_ACTIVE_CHEMICAL = 'ChemicalTreatementActiveChemical';
export const CHEMICAL_TREATEMENT_CONCENTRATE = 'ChemicalTreatementConcentrate';
// Pest Control Log
export const PEST = 'Pest';
export const PEST_DAMAGE_LEVEL = 'PestDamageLevel';
export const DAMAGED_CULTURES = 'DamagedCultures';
export const USED_CHEMICALS = 'UsedChemicals';
export const CHEMICAL_TYPE = 'ChemicalType';
export const METEOROLOGY = 'Meteorology';
export const APPLICATION_METHOD = 'ApplicationMethod';
// Damage Levels
export const DAMAGE_LEVEL_LOW = 'DamageLevelLow';
export const DAMAGE_LEVEL_MEDIUM = 'DamageLevelMedium';
export const DAMAGE_LEVEL_HIGH = 'DamageLevelHight';
export const DAMAGE_LEVEL_TOTAL = 'DamageLevelTotal';

// Used Pesticide Log
export const USED_PESTICIDE_OPERATOR = 'UsedPesticideOperator';
export const USED_PESTICIDE_NAME = 'UsedPesticideName';
export const USED_PESTICIDE_ACTIVE_CHEMICAL = 'UsedPesticideActiveChemical';
export const USED_PESTICIDE_NUMBER = 'UsedPesticideNumber';
export const USED_PESTICIDE_TREATED_CULTURE = 'UsedPesticideTreatedCulture';
export const USED_PESTICIDE_HECTARE_NORM = 'UsedPesticideHectareNorm';
export const USED_PESTICIDE_TREATED_AREA = 'UsedPesticideTreatedArea';
export const USED_PESTICIDE_TOTAL = 'UsedPesticideTotal';
export const USED_PESTICIDE_ENTRY_BAN_PERIOD = 'UsedPesticideEntryBanPeriod';
export const USED_PESTICIDE_MOWING_BAN_PERIOD = 'UsedPesticideMowingBanPeriod';

// Stepper
export const FORWARD = 'Forward';
export const BACKWORD = 'Backword';
export const STEPFROMTOTAL = 'StepFromTotal';

export default {
  // Validators
  [REQUIRED_FIELD]: 'Това поле е задължително',

  // Notifications
  [OTHER_EVENT]: 'Друго известие',
  [BUY_EVENT]: 'Изпратена е нова заявка за закупуване на животни',

  // Grid variables
  [ADD]: 'Добави',
  [ADD_NEW]: 'Добави нов запис',
  [EDIT_SUBMISSION]: 'Обнови запис',
  [ADD_NEW_COLUMN]: 'Добави новa колона',
  [COLUMNS]: 'Колони',
  [ACTIVE]: 'Активен',
  [INACTIVE]: 'Неактивен',
  [EXPORT]: 'Свали',
  [REMOVE]: 'Премахни',
  [ADD_MORE]: 'Добави Още',
  [ACCEPT]: 'Приеми',

  // General
  [REVISE]: 'Прегледай',
  [PREVIOUS]: 'Назад',
  [NEXT]: 'Напред',
  [YES]: 'Да',
  [NO]: 'Не',
  [DAYS]: 'Дни',
  [Keys.LOAD]: 'Зареди',

  // Dashboard component titles
  [WIDGET_DESCRIPTION_PLACEHOLDER]: 'Описание на уиджета',
  [SALE_TRADE_CHART_TITLE]: 'Покупко Продажба',
  [PROFILE_STATUS_TITLE]: 'Статус',
  [PROFILE_RATING_TITLE]: 'Рейтинг',
  [PROFILE_COMPLETION_TITLE]: 'Плътност на профила',
  [HISTORY_CHART_TITLE]: 'История',
  [SEUROP_TITLE]: 'Оценки S.E.U.R.O.P',
  [ANIMAL_ACCEPTANCE_TITLE]: 'Прием животни',
  [PLACEHOLDER_TITLE]: 'Заглавие',

  // Dashboard component contents
  [STATUS_CONTENT]: 'Вашият профил е в процес на обработка',

  // Drawer component titles
  [DASHBOARD_TITLE]: 'Табло',
  [LOGS_TITLE]: 'Дневници',
  [AMBULATORY_LOGS_TITLE]: 'Амбулаторни дневници',
  [ARTIFICIAL_INSEMENATION]: 'Случни планове и изкуствено заплождане',
  [PASTURE_LOG_TITLE]: 'Пасища',
  [MORTALITY_LOG_TITLE]: 'Смъртност и важни здравни събития',
  [CHEMICAL_TREATMENT_LOG_TITLE]: 'Химически обработки',
  [PEST_CONTROL_LOG_TITLE]: 'Контрол на вредителите',
  [USED_PESTICIDE_LOG_TITLE]: 'Използвани пестициди',
  [ANIMALS_IN_FARM_TABLE_TITLE]: 'Таблица животни',
  [ANIMALS_IN_BUTCHERY_TABLE_TITLE]: 'Животни в кланницата',
  [BUTCHERED_ANIMALS_TABLE]: 'Таблица със заклани животни',
  [DEALS_TITLE]: 'Сделки',
  [ADDRESS_BOOK_TITLE]: 'Адресна книга',
  [HOLDING_PROFILE_TITLE]: 'Профил на Холдинга',
  [LINK_PLACEHOLDER_TITLE]: 'Линк',
  [MOVEMENTS_TITLE]: 'Движения',
  [MOVEMENTS_REGISTRATION_TITLE]: 'Вписване',
  [MOVEMENTS_UNREGISTRATION_TITLE]: 'Отписване',
  [MOVEMENTS_SALE_TITLE]: 'Продажба',

  // AppBar component titles
  [SEARCH_BAR]: 'Търси...',
  [FERMER_TITLE]: 'Зона Фермер',
  [PROCESSOR_TITLE]: 'Зона Преработвател',
  [TRADER_TITLE]: 'Зона Търговец',
  [CANDIDATE_TITLE]: 'Кандидат',

  // HOLDING PROFILE
  [HOLDING_PROFILE]: 'Профил на Холдинга',
  [FIRMS_TAB]: 'Фирми',
  [ANOBJ_TAB]: 'Ж.О',
  [ENTERPRISE_TAB]: 'Предприятия',
  [SHOP_TAB]: 'Търговски обекти',
  [TRADEMARK_TAB]: 'Търговски марки',

  // General Info Holding Profile
  [HOLDING_NAME]: 'Име на холдинга',
  [HOLDING_STRUCTURE]: 'Структура на холдинга',
  [HOLDING_SAVE]: 'Запази',
  [HOLDING_DECLINE]: 'Откажи',
  [IS_FARM]: 'Ферма',
  [IS_FEEDLOT]: 'Угоителен център',
  [IS_BUTCHERY]: 'Кланница',
  [IS_ABATTOIR]: 'Транжорна',
  [IS_SHOP]: 'Тръговски обект',
  [CATTLE]: 'Говедовъдство',
  [SHEEP]: 'Овцевъдство',
  [GENERAL_INFO]: 'Общи данни',

  // General Forms
  [STATUS]: 'Статус',
  [SUBMIT_FORM]: 'Добави Нов Запис',
  [CLEAR_FORM]: 'Изчисти',
  [UPDATE]: 'Обнови',
  [DECLINE]: 'Отмени',
  [SEND]: 'Изпрати',
  [SAVE]: 'Запази',
  [EDIT]: 'Коригирай',
  [CANCEL]: 'Откажи',
  [CONFIRM]: 'Потвърди',
  [ADDRESS_PLACEHOLDER]: 'ул., бул., ет., ап.',
  [AMOUNT_PLACEHOLDER]: 'брой',
  [CITY_PLACEHODLDER]: 'град/село',
  [OWNER_PLACEHOLDER]: 'ЮЛ, ФЛ',
  [DATE]: 'Дата',
  [CHOOSE_DROPDOWN_OPTION]: 'Моля, изберете опция...',

  // Firm
  [EIK]: 'ЕИК/ЕГН',
  [FIRM_NAME]: 'Име на фирмата',
  [FIRM_COUNTRY]: 'Държава',
  [FIRM_CITY]: 'Населено Място',
  [FIRM_ADDRESS]: 'Адрес',
  [FIRM_MOL]: 'МОЛ',

  // Animal Object Form
  [AO_STATUS]: 'Статус',
  [AO_NAME]: 'Име на обекта',
  [AO_NUMBER]: '№ на ж.о.',
  [AO_TRACES]: '№ TRACES',
  [AO_CITY]: 'Населено място',
  [AO_ADDRESS]: 'Адрес',
  [AO_CAPACITY]: 'Капацитет / бр.',
  [AO_BUILDINGS]: 'Сгради / бр.',
  [AO_BACREAGE]: 'Площ сгради / м2',
  [AO_PASTURES]: 'Пасища / бр.',
  [AO_PACREAGE]: 'Площ пасища / ha',
  [AO_OWNER]: 'Собственик',
  [AO_BRANCH]: 'Направление на обекта?',
  [AO_CATTLE]: 'Говеда',
  [AO_SHEEP]: 'Овце',

  // Enterprise Form
  [ENT_NAME]: 'Обект',
  [ENT_NUMBER]: 'Номер',
  [ENT_TYPE]: 'Тип предприятие',
  [ENT_OWNER]: 'Собственик',
  [ENT_ADDRESS]: 'Населено място',
  [ENT_TYPE_PLACEHOLDER]: 'Изберете типа предприятие',

  // Shop Form
  [SHOP_NAME]: 'Име на обекта',
  [SHOP_NUMBER]: 'Номер',
  [SHOP_TYPE]: 'Тип търговски обект',
  [SHOP_OWNER]: 'Собственик',
  [SHOP_ADDRESS]: 'Населено място и адрес',
  [SHOP_DEFAULT_VALUE]: 'Изберете тип обект',
  [SHOP_SMALL_SHOP]: 'Малък магазин',
  [SHOP_MTS]: 'Обект от модерната търговия',

  // Trademark Form
  [TM_NAME]: 'Име',
  [TM_NAME_PLACEHOLDER]: 'Име на търговската марка',
  [TM_REG]: 'Регистрационен номер',
  [TM_LINK]: 'Линк',
  [TM_LINK_PLACEHOLDER]: 'Линк на търг. марка',
  [TM_IMAGE]: 'Изображение',

  // Animal Table
  [CATTLE_TAB]: 'Говеда',
  [SHEEP_TAB]: 'Овце',
  [ANIMAL_ENTRY]: 'Вход',
  [ANIMAL_EXIT]: 'Операции',

  // Animal Preview
  [SELECTED_ANIMALS_PREVIEW]: 'Преглед на избраните животни',

  // SellAnimals
  // Steps
  [START]: 'Начало',
  [WEIGTHS]: 'Тегла',
  [PRICING]: 'Цени',
  // Deal details
  [FIRMS_DETAILS]: 'Данни за фирмите',
  [TRANSPORT_DETAILS]: 'Данни за транспорта',
  [SALE_INFO]: 'Информация за сделката',
  [SELL_FIRM_NAME]: 'Име на продаващата фирма',
  [SELL_FIRM_EIK]: 'ЕИК на продаващата фирма',
  [SELL_FIRM_MOL]: 'МОЛ на продаващата фирма',
  [SELLING_FIRM]: 'Продаваща фирма',
  [BUYING_FIRM]: 'Фирма купувач',
  [BUY_FIRM_NAME]: 'Име на фирмата купувач',
  [BUY_FIRM_EIK]: 'ЕИК на фирмата купувач',
  [BUY_FIRM_MOL]: 'МОЛ на фирмата купувач',
  [QUANTITY_INFO]: 'Количествени Данни',
  [VEHICLE_WEIGHT]: 'Тегло бруто | МПС',
  [EMPTY_VEHICLE_WEIGHT]: 'Тара | МПС',
  [VEHICLE_WEIGHT_PLUS_ANIMALS]: 'Тегло бруто | МПС + животни',
  [ANIMAL_WEIGHT]: 'Теглене на животни',
  [AVERAGE_WEIGHT]: 'Теглене с МПС',
  [INDIVIDUAL_WEIGHT]: 'Индивидуално теглене',
  [ANIMAL_NET_WEIGHT]: 'Тегло нето | Животни',
  [AVERAGE_ANIMAL_NET_WEIGHT]: 'Средно тегло нето животно',
  [FINANCIAL_INFO]: 'Групови данни | Финансови данни',
  [PRICE_PER_KILO]: 'Цена на кг./жт',
  [PRICE_PER_ANIMAL]: 'Цена на глава животно',
  [GROUP_TOTAL_PRICE]: 'Обща цена на групата',
  [AVERAGE_ITEM_PRICE]: 'Средна цена за брой',
  [TRANSPORTATION_INFO]: 'Групови данни | Транспортни данни',
  [TRANSPORTATION_DRIVER]: 'Превозвач',
  [TRANSPORTATION_DRIVER_PIN]: 'ЕГН',
  [TRANSPORTATION_VEHICLE_REG]: 'Рег. номер на МПС',
  [TRANSPORTATION_DATE]: 'Дата на товарене',
  [TRANSPORTATION_SERIAL_NUMBER]: 'Сериен номер на товара',
  [ARRIVAL_DATE]: 'Дата на пристигане',
  [DEAL_PRICING]: 'Цени и ценообразуване',
  [ENTER_PRICE_PER_HEAD]: 'Въведи цена на глава',
  [ENTER_PRICE_PER_ANIMAL]: 'Въведи цена на кг./жт.',

  // Select Animal Operations
  [ANIMAL_OPERATIONS]: 'Операции с животни',
  [SELECT_OPERATION]: 'Изберете операция',
  [SELL_OPERATION]: 'Продажба',
  [VETERINARY_INSPECTION]: 'Ветеринарна инспекция',
  [VETERINARY_INSPECTOR]: 'Ветеринарен инспектор',

  // Address Book
  [ADDRESS_BOOK_CONTRAGENTS]: 'Контрагенти',
  [ADDRESS_BOOK_EMPLOYEES]: 'Служители',
  [ADDRESS_BOOK_FIRMS]: 'Фирми',
  [ADDRESS_BOOK_TRUCKS]: 'Камиони',
  [ADDRESS_BOOK_NAME]: 'Име и фамилия',
  [ADDRESS_BOOK_PHONE]: 'Телефонен номер',
  [ADDRESS_BOOK_ROLE]: 'Роля',
  [ADDRESS_BOOK_EMAIL]: 'Имейл',
  [ADDRESS_BOOK_STATUS]: 'TRACE Статус',
  [ADDRESS_BOOK_FIRM]: 'Фирма',
  [ADDRESS_BOOK_FIRM_TYPE]: 'Тип на фирмата',
  [ADD_NEW_EMPLOYEE]: 'Добави нов служител',
  [EDIT_EMPLOYEE]: 'Редактирай записан служител',

  // Deals
  [DEAL]: 'Сделка',
  [DEAL_ACCEPT_STATUS]: 'Приета',
  [DEAL_REJECT_STATUS]: 'Отказана',
  [DEAL_PARTIAL_ACCEPT_STATUS]: 'Частично приета',
  [DEAL_PENDING_STATUS]: 'Изчакване',
  [DEAL_ACCEPT]: 'Приеми всички',
  [DEAL_ACCEPT_PARTIAL]: 'Приеми само избраните',
  [DEAL_REJECT]: 'Откажи',
  [DEAL_STATUS]: 'Статус на сделката',
  [DEAL_NUMBER]: '№ на сделката',
  [DEAL_CREATOR]: 'Име на продавача',
  [DEAL_DATE]: 'Дата на сделката',
  // DealGrid
  [DEAL_ANIMAL_INFO]: 'Информация за животното',
  [DEAL_ANIMAL_WEIGHT]: 'Килограми',
  [DEAL_ANIMAL_PPH]: 'Лв. бр',
  [DEAL_ANIMAL_PPK]: 'Лв./кг.',
  [DEAL_ANIMAL_PPHK]: 'лв./100кг.',
  [DEAL_ANIMAL_ARRIVAL_WEIGHT]: 'Килограми при пристигане',
  [DEAL_ANIMAL_NOTE]: 'Бележка',

  // Kill Data
  [KILL_DATA_OPERATION]: 'Клане',
  [KILL_DATA_MATADOR]: 'Колач',

  // Carcass Quality Grading
  [ANIMAL_SELECTION_PLACEHOLDER]: 'Моля, изберете животно...',
  [CARCASS_QUALITY_GRADE]: 'Трупна Качествена Оценка',
  [GRADED_ANIMAL_IDENTIFICATOR]: 'Идентификатор на избраното животно',
  [INSPECTION_DATE]: 'Дата на инспекцията',
  [INSPECTOR]: 'Инспектор',
  [AGE_CLASS]: 'Възрастов клас',
  [UNKNOWN_CLASS]: 'Неизвестен',
  [Z_CLASS]: 'Z (Млади говеда)',
  [Z_CLASS_TITLE]: 'Трупове на животни на възраст между 8 месеца и 12 месеца',
  [A_CLASS]: 'А (Млади бикове)',
  [A_CLASS_TITLE]: 'Трупове на некастрирани мъжки животни на възраст между 12 и 24 месеца',
  [B_CLASS]: 'B (Бикове)',
  [B_CLASS_TITLE]: 'Трупове на некастрирани мъжки животни на възраст над 24 месеца',
  [C_CLASS]: 'C (Кастрати)',
  [C_CLASS_TITLE]: 'Трупове на кастрирани мъжки животни на възраст над 12 месеца',
  [D_CLASS]: 'D (Крави)',
  [D_CLASS_TITLE]: 'Трупове на женски животни, които са се отелвали',
  [E_CLASS]: 'E (Юници)',
  [E_CLASS_TITLE]: 'Трупове на други женски животни на възраст над 12 месеца',
  [MUSCLE_CLASS]: 'Мускулатурен клас',
  [MUSCLE_CLASSIFICATION]: 'Мускулатура корекция',
  [FAT_CLASS]: 'Мастен Клас',
  [FAT_COLOR]: 'Цвят на мас',
  [OSSIFI_SCORE]: 'Ossifi Score',
  [TEETH_FORMULA]: 'Зъбна формула',
  [MEAT_COLOR]: 'Цвят на месото',
  [CARCASS_YIELD]: 'Трупен Добив',
  [CARCASS_QUALITY]: 'Качество на трупното месо',
  [FAT_WHITE]: 'Бяла',
  [FAT_CREAMY_WHITE]: 'Кремаво бяла',
  [FAT_SLIGHTLY_YELLOW]: 'Леко жълта',
  [FAT_MODERATELY_YELLOW]: 'Умерено жълта',
  [FAT_YELLOW]: 'Жълта',
  [TEETH_NONE]: 'Няма',
  [CARCASS_QUALITY_PRIME]: 'Първокласно',
  [CARCASS_QUALITY_CHOICE]: 'Най - отбрано',
  [CARCASS_QUALITY_SELECT]: 'Отбрано',
  [CARCASS_QUALITY_STANDARD]: 'Стандартно',
  [CARCASS_QUALITY_COMMERCIAL]: 'Търговско',
  [CARCASS_QUALITY_UTILITY]: 'Полезно',
  [CARCASS_QUALITY_CUTTER]: 'За рязане',
  [CARCASS_QUALITY_CANNER]: 'За консервиране',

  // Animal Cooling
  [ANIMAL_COOLING]: 'Охлаждане на месото',
  [ANIMAL_COOLING_TITLE]: 'Процедура по охлаждането',
  [FIRST_CHECK]: 'Първа проверка',
  [SECOND_CHECK]: 'Втора проверка',
  [COOLING_START]: 'Старт на охлаждането',
  [PH_VALUE]: 'Стойност на pH',
  [TEMPERATURE]: 'Температура на месото',

  // Logs
  // Artificial Insemination
  [COW_LID]: 'Ушна марка крава',
  [FERTILIZATION_TYPE]: 'Вид заплождане',
  [SIRE_LID]: 'Ушна марка бик',
  [FERTILIZATION_START]: 'Заплождане начало',
  [FERTILIZATION_END]: 'Заплождане край',
  [PREGNANCY_TEST_DATE]: 'Тест бременност дата',
  [TEST_RESULT]: 'Резултат тест',
  [PREGNANCY_STAGE]: 'Стадий бременност',
  [EXPECTED_DUE_DATE]: 'Очаквана дата отелване',
  [PREGNANCY_RESULT_YES]: 'Да',
  [PREGNANCY_RESULT_NO]: 'Не',
  [PREGNANCY_RESULT_UNID]: 'Неизвестен',
  [FERTILIZATION_NATURALLY]: 'Естествено',
  [FERTILIZATION_ARTIFICIALLY]: 'Изкуствено',
  // Pasture Log
  [ANIMAL_COUNT]: 'Брой животни',
  [HERD_GROUP]: 'Стадо група',
  [PASTURE_NAME]: 'Име на пасище / падок',
  [PASTURE_DATE_OF_ENTRY]: 'Дата на влизане в падока',
  [PASTURE_DATE_OF_EXIT]: 'Дата на излизане от падока',
  [DAYS_SPENT_IN_PASTURE]: 'Дни в падока / пасището',
  [PASTURE_ACREAGE]: 'Площ на пасището / падока',
  [PASTURE_GRASS_HEIGHT_IN]: 'Височина на тревата в началото',
  [PASTURE_GRASS_HEIGHT_OUT]: 'Височина на тревата в края',
  // Purchased medicine log
  [PURCHASED_MEDICINE_LOG_TITLE]: 'Дневник на закупените медикаменти',
  [PURCHASED_MEDICINE_LOG_ENTRY_DATE]: 'Дата на покупка на медикамента',
  [PURCHASED_MEDICINE_NAME]: 'Наимненование на медикамента',
  [MEDICINE_CATEGORY_NAME]: 'Категория',
  [PURCHASED_MEDICINE_AMOUNT]: 'Закупено количество медикамент',
  [PURCHASED_MEDICINE_DELIVERED_BY]: 'Наименование, адрес на доставчика',
  [MEDICINE_WITHDRAW_TITLE]: 'Карентен срок дни',
  [PURCHASED_MEDICINE_WITHDRAW_PERIOD_MEAT]: 'Месо',
  [PURCHASED_MEDICINE_WITHDRAW_PERIOD_MILK]: 'Мляко',
  [PURCHASED_MEDICINE_BATCH_NUMBER]: 'Партида на медикамента',
  [PURCHASED_MEDICINE_EXPIRY_DATE]: 'Срок на годност на медикамента',
  [PURCHASED_MEDICINE_NOTE]: 'Бележки (напр. БАБХ лиценз)',
  // Fodder Log
  [FODDER_DATE]: 'Дата',
  [PURCHASED_FODDER_TITLE]: 'Закупени фуражи',
  [PRODUCED_FODDER_TITLE]: 'Произведени фуражи',
  [FODDER_LOG_TITLE]: 'Дневник на фуража',
  [FODDER_NAME]: 'Име на продукта / описание (фураж, субпродукт и др.)',
  [FODDER_CATEGORY]: 'Категория',
  [FODDER_BATCH_NUMBER]: 'Партида или Фактура',
  [FODDER_AMOUNT_PRODUCED]: 'Произведено количество',
  [FODDER_AMOUNT_BOUGHT]: 'Закупено количество',
  [FODDER_COURIER]: 'Доставчик (наименование и адрес)',
  [FODDER_NOTE]: 'Бележка',
  [FODDER_SELF_PRODUCED]: 'Собствено производство',
  [FODDER_TYPE]: 'Направление',
  // FodderCategories
  [UNKNOWN_CATEGORY]: 'Неизвестнa',
  [WETFEED_CATEGORY]: 'Влажни фуражи',
  [WATER_CATEGORY]: 'Вода',
  [OTHER_CATEGORY]: 'Други',
  [LONGFIBER_CATEGORY]: 'Дълги фибри (Люцерна, Сено)',
  [CONCENTRATE_CATEGORY]: 'Концентрати (Премикс, Зърно)',
  [ROOTS_CATEGORY]: 'Кореноплодни',
  [SOFTFIBER_CATEGORY]: 'Меки фибри (Сенаж, Силаж)',
  [VITAMINS_CATEGORY]: 'Минерали/Витнамини',
  [LIQUIDS_CATEGORY]: 'Течни (Меласа)',
  // Medicine categories
  [UNKNOWN_CATEGORY]: 'Неизвестна',
  [ANTIBIOTIC_CATEGORY]: 'Антибиотици',
  [VACCINE_CATEGORY]: 'Ваксини',
  [VITAMIN_CATEGORY]: 'Витамини и Минерали',
  [OTHER_CATEGORY]: 'Неизвестни',
  [CALCIUM_CATEGORY]: 'Калциеви препарати',
  [PROBIOTIC_CATEGORY]: 'Пробиотици',
  [ANITIINFLAMMATORY_CATEGORY]: 'Противовъзпалителни',
  [ANTIPARASITIC_CATEGORY]: 'Противопаразитни',
  [OINTMENT_CATEGORY]: 'Унгвенти',
  [HORMONAL_CATEGORY]: 'Хормонални препарати',
  [SUPPLIMENT_CATEGORY]: 'Хранителни добавки',

  // Mortality Log
  [ANIMAL_MORTALITY]: 'Смъртност',
  [ANIMAL_HEALTH_PROBLEM]: 'Установен здравен проблем',
  [ANIMAL_TERAPY]: 'Терапия, Манипулация',
  // Animal Information
  [MALE]: 'Мъжко',
  [MALE_ABBR]: 'М',
  [FEMALE]: 'Женско',
  [FEMALE_ABBR]: 'Ж',
  [CATTLE_TYPE]: 'Говедо',
  [SHEEP_TYPE]: 'Овца',
  [ANIMAL_SEX]: 'Пол',
  [ANIMAL_BREED]: 'Порода',
  [ANIMAL_AGE]: 'Възраст в месеци',
  [ANIMAL_LID]: 'Ушна марка',

  // Ambulatory Log
  [AMBULATORY_NUMBER]: 'Амб. №',
  [AMBULATORY_DATE]: 'Дата',
  [AMBULATORY_ANIM_OBJ]: 'Име и адрес на Ж.О',
  [ANIMAL_TYPE]: 'Вид животно',
  [AMBULATORY_CLINICAL_DATA]: 'Клинични данни',
  [AMBULATORY_DIAGNOSTIC_TEST]: 'Диагностично изследване',
  [AMBULATORY_DIAGNOSIS]: 'Диагноза',
  [AMBULATORY_MEDICINES]: 'Проведено лечение',
  [AMBULATORY_DOSE]: 'Количество/доза',
  [AMBULATORY_WITHDRAWAL_PERIOD]: 'Указание за карентен срок',
  [AMBULATORY_DIAGNOSIS_OUT]: 'Изход от болестта',
  [AMBULATORY_NOTE]: 'Бележка',

  // Chemical Treatement Log
  [CHEMICAL_TREATEMENT_PEST]: 'Вредител',
  [CHEMICAL_TREATEMENT_DOSE]: 'Доза на ДКА Конц. %',
  [CHEMICAL_TREATEMENT_TREATED_AREA]: 'Третирани площи',
  [CHEMICAL_TREATEMENT_TECHNIQUE]: 'Техника за приложение',
  [CHEMICAL_TREATEMENT_CHEMICAL]: 'Препарат',
  [CHEMICAL_TREATEMENT_ACTIVE_CHEMICAL]: 'Активно вещество',
  [CHEMICAL_TREATEMENT_CONCENTRATE]: 'Конц. %',
  // Pest Control Log
  [PEST]: 'Наблюдаван вредител',
  [PEST_DAMAGE_LEVEL]: 'Ниво на вреди',
  [DAMAGED_CULTURES]: 'Пострадали култури',
  [USED_CHEMICALS]: 'Използван препарат',
  [CHEMICAL_TYPE]: 'Вид препарат',
  [METEOROLOGY]: 'Метеорология',
  [APPLICATION_METHOD]: 'Начин на прилагане',
  [DAMAGE_LEVEL_LOW]: 'Ниско',
  [DAMAGE_LEVEL_MEDIUM]: 'Средно',
  [DAMAGE_LEVEL_HIGH]: 'Високо',
  [DAMAGE_LEVEL_TOTAL]: 'Тотал',

  // Used Pestecide Log
  [USED_PESTICIDE_OPERATOR]: 'Оператор',
  [USED_PESTICIDE_NAME]: 'Име на препарата',
  [USED_PESTICIDE_ACTIVE_CHEMICAL]: 'Акт. вещество',
  [USED_PESTICIDE_NUMBER]: 'Парт. №',
  [USED_PESTICIDE_TREATED_CULTURE]: 'Третирана култура',
  [USED_PESTICIDE_HECTARE_NORM]: 'Норма приложение на Ха.',
  [USED_PESTICIDE_TREATED_AREA]: 'Третирана площ Ха.',
  [USED_PESTICIDE_TOTAL]: 'Общо количество',
  [USED_PESTICIDE_ENTRY_BAN_PERIOD]: 'Период забрана за влизане',
  [USED_PESTICIDE_MOWING_BAN_PERIOD]: 'Период забрана за паша / косене',

  // Stepper
  [FORWARD]: 'Напред',
  [BACKWORD]: 'Назад',
  [STEPFROMTOTAL]: 'Стъпка от ',
  [STEP_FROM]: 'Стъпка {0} от {1}',

  // UNECE Settings
  [Keys.uniceKeys.FEEDING_SYSTEM]: 'Система на хранене',
  [Keys.uniceKeys.PRODUCING_SYSTEM]: 'Производствена система',
  [Keys.uniceKeys.UNECE_TITLE_FARMER]: 'Настройки UNECE - Ферма',
  [Keys.uniceKeys.UNECE_MENU_TITLE]: 'UNECE Настройки',
  [Keys.uniceKeys.UNECE_TITLE_PROCESSOR]: 'Настройки UNECE - Предприятие',
  [Keys.uniceKeys.BOVINE_QUALITY_SYSTEM]: '11 - Стандарт за качество говеждо',
  [Keys.uniceKeys.CONFORMITY_ASSESSMENT]: '14 - Система за окачествяване',
  [Keys.uniceKeys.FAT_THICKNESS]: '10 - Лой дебелина',
  [Keys.uniceKeys.PACKING_CODES]: '13 - Система опаковане',
  [Keys.uniceKeys.POST_SLAUGHTER_SYSTEM]: '9 - След-кланична система',
  [Keys.uniceKeys.REFRIGERATION]: '4 - Система охлаждане',
  [Keys.uniceKeys.SLAUGHTER_SYSTEM]: '8 - Кланична система',
  [Keys.uniceKeys.WEIGHT_RANGING]: '12 - Теглови диапазон',

  // QR
  [SAVE_IMAGE]: 'Свали изображение',
  [SAVE_SVG]: 'Свали векторна графика',

  // Unregister animals
  [ANIMAL]: 'Животно',
  [ANIMALS]: 'Животни',
  [ANIMALS_SELECTION]: 'Избор на животни',
  [APPLY_FOR_ALL_ANIMALS]: 'Приложи за всички животни',
  [CHOSEN]: 'Избрани са {0} животни.',
  [NO_ANIMAL_OBJECT_SELECTED]: 'Няма избран животновъден обект',
  [NO_REASON_ANIMALS_MSG]: 'Има животни без причина за отписване. Изберете причина за да продължите напред.',
  [NO_RETURN_MSG]: 'Повече няма да можете да се върнете на тази стъпка!',
  [PROCESS_FORWARD_CONFIRM]: 'Сигурни ли сте, че искате да продължите процеса?',
  [REASON]: 'Причина',
  [UNREGISTER]: 'Отпиши',
  [UNREGISTER_CHRONOLOGY_MSG]: 'Хронология на отписваните животни може да видите в таблото на приложението',
  [UNREGISTER_CONFIRM_MSG]: 'Сигурни ли сте, че искате да отпишете тези {0} животни?',
  [UNREGISTER_NEW]: 'Ново отписване',
  [UNREGISTER_REASON]: 'Причина за отписване',
  [UNREGISTER_SUCCESS_MSG]: 'Успешно отписахте групата от животни',
  [UNREGISTER_TITLE]: 'Отписване',

  // Butchery
  [Keys.butcheryKeys.BUTCHERY_PROCESS_TITLE]: 'Клане',

  // Public data
  [Keys.publicKeys.BATCH_HISTORY]: 'История входящи партиди',
  [Keys.publicKeys.BATCH_HISTORY_SUBTEXT]: 'Зареждане на месо от сертифицирани предприятия и ферми по стандарта на blbbg.org и търгувано по системата',
  [Keys.publicKeys.BULLETIN]: 'БЮЛЕТИН',
  [Keys.publicKeys.ENTERPRISE_MAIN_SUBTITLE]: 'Профил на сертифицирано предприятие',
  [Keys.publicKeys.FARM_MAIN_SUBTITLE]: 'Профил на сертифицирана ферма',
  [Keys.publicKeys.HERD_INVENTORY]: 'Стадна инвентаризация',
  [Keys.publicKeys.LOCATION]: 'Локация',
  [Keys.publicKeys.OTHER_HOLDING_OBJECTS]: 'Други обекти в холдинга',
  [Keys.publicKeys.PAGE_MAIN_TITLE]: 'БОРД ПО ГОВЕЖДО И АГНЕШКО МЕСО',
  [Keys.publicKeys.PREVIEW]: 'Прегледай',
  [Keys.publicKeys.QUАNTITIES]: 'Количества',
  [Keys.publicKeys.QUАNTITIES_SUBTEXT]: 'Трупно месо закупено по системата TRACE',
  [Keys.publicKeys.QUАNTITIES_SUBTEXT_SMALL]: 'Сечения на едро закупени през текущата година в кг.',
  [Keys.publicKeys.SHOP_MAIN_SUBTITLE]: 'Профил на сертифициран търговски обект',
  [Keys.publicKeys.SOCIAL_MEDIA_SHARE]: 'Сподели в социалните мрежи',
  [Keys.publicKeys.SUBSCRIBE]: 'Абонирай се',
  [Keys.publicKeys.SUBSCRIBE_FOR_MONTHLY]: 'АБОНИРАЙ СЕ ЗА МЕСЕЧНИЯ',
  [Keys.publicKeys.SUBSCRIBE_NOW]: 'Абонирай се сега!',
  [Keys.publicKeys.SUBSCRIBE_TEXT]: 'Абонирай се за месечния ни бюлетин с публикации и новини от света на фермерството и месопреработването.',
  [Keys.publicKeys.TAGS]: 'Тагове',

  // Warehouse
  [Keys.warehouseKeys.WAREHOUSE_TITLE]: 'Склад',
  [Keys.warehouseKeys.VIRTUAL_WAREHOUSE_TITLE]: 'Виртуален склад',
  [Keys.ACTIONS]: 'Действия',

  // Trader
  [Keys.SHOPPING_CENTER]: 'Търговски обект',

  grid: {
    noRecords: 'Няма резултати.',
    pagerFirstPage: 'Първа страница.',
    pagerPreviousPage: 'Предишна страница',
    pagerNextPage: 'Следваща страница',
    pagerLastPage: 'Последна страница',
    pagerPage: 'Страница',
    pagerOf: 'от',
    pagerTotalPages: '{0}',
    pagerItems: 'резултата',
    pagerInfo: '{0} - {1} от {2} резултата',
    pagerItemsPerPage: 'резултата на страница',
    filterEqOperator: 'Съдържа',
    filterNotEqOperator: 'No es igual a',
    filterIsNullOperator: 'Няма',
    filterIsNotNullOperator: 'Има',
    filterIsEmptyOperator: 'Е празно',
    filterIsNotEmptyOperator: 'Не е празно',
    filterStartsWithOperator: 'Започва с',
    filterContainsOperator: 'Съдържа',
    filterNotContainsOperator: 'Не Съдържа',
    filterEndsWithOperator: 'Савършва с',
    filterGteOperator: 'Е по - голямо или равно на',
    filterGtOperator: 'Е по - голямо от',
    filterLteOperator: 'Е по - малко или равно на',
    filterLtOperator: 'Е по - малко от',
    filterAfterOrEqualOperator: 'Е след или равно на',
    filterAfterOperator: 'Е след',
    filterBeforeOperator: 'Е преди',
    filterBeforeOrEqualOperator: 'Е преди или равно на',
    filterSubmitButton: 'Филтрирай',
    filterClearButton: 'Изчисти',
    filterAndLogic: 'Да',
    filterOrLogic: 'Не',
    filterTitle: 'Филтър',
    sortAscending: 'Сортирай възходящо',
    sortDescending: 'Сортирай низходящо'
  },
  datepicker: {
    toggleCalendar: 'Отвори календар'
  },
  calendar: {
    today: 'Днес'
  },
  dateinput: {
    increment: 'Увличи',
    decrement: 'Намали'
  },
  datetimepicker: {
    date: 'Дата',
    time: 'Час',
    cancel: 'Откажи',
    set: 'Запази'
  }
};
