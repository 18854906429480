/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../app/StoreHooks';
import { AnimalObjectResponseType, AnimalObjectType, EDIT_ANIMAL_OBJECT, GET_ANIMAL_OBJECTS, GetAnimalObjectByFirmParams, SAVE_ANIMAL_OBJECT, SaveAnimalObjectType, GET_ACTIVE_ANIMAL_OBJECTS_BY_FIRM } from './data/AnimalObjectSliceData';
import { createAsyncThunkGeneric, createAsyncThunkGenericVoid } from './GenericThunks';

type AnimalObjectState = {
  animalObjects: AnimalObjectType[];
  isLoading: boolean;
};

const initialState: AnimalObjectState = {
  animalObjects: [],
  isLoading: false
};

// Async thunks
export const getAllAnimalObjects = createAsyncThunkGenericVoid(
  'animalObjects/getAllAnimalObjects',
  async ({ client }) => {
    const response = await client.query<void, AnimalObjectResponseType>(
      'animalObjects',
      GET_ANIMAL_OBJECTS
    );
    return response?.items ? response?.items : [];
  }
);

export const getAnimalObjectsByFirm = createAsyncThunkGeneric<
GetAnimalObjectByFirmParams,
AnimalObjectResponseType
>(
  'animalObjects/getAnimalObjectsByFirm',
  async ({ client, input }) =>
    client.query<GetAnimalObjectByFirmParams, AnimalObjectResponseType>(
      'animalObjectsByFirm',
      GET_ACTIVE_ANIMAL_OBJECTS_BY_FIRM,
      {
        firmId: input.firmId,
        active: input.active || false
      }
    )
);

export const editAnimalObject = createAsyncThunkGeneric<
AnimalObjectType,
AnimalObjectType
>(
  'animalObjects/editAnimalObject',
  async ({ client, input }) =>
    client.mutate<SaveAnimalObjectType, AnimalObjectType>(
      'updateAnimalObject',
      EDIT_ANIMAL_OBJECT,
      { animalObject: input }
    )
);

export const saveAnimalObject = createAsyncThunkGeneric<
AnimalObjectType,
AnimalObjectType
>('animalObjects/saveAnimalObject', async ({ client, input }) => {
  input.active = true;
  return client.mutate<SaveAnimalObjectType, AnimalObjectType>(
    'addAnimalObject',
    SAVE_ANIMAL_OBJECT,
    { animalObject: input }
  );
});

const AnimalObjectSlice = createSlice({
  name: 'animalObjects',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllAnimalObjects.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllAnimalObjects.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.animalObjects = payload;
      })
      .addCase(getAnimalObjectsByFirm.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAnimalObjectsByFirm.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.animalObjects = payload ? payload.items : [];
      })
      .addCase(editAnimalObject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editAnimalObject.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const updated = state.animalObjects.findIndex(
          (animalObject) =>
            animalObject.animalObjectId === payload?.animalObjectId
        );
        state.animalObjects[updated] = payload;
      })
      .addCase(saveAnimalObject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveAnimalObject.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.animalObjects.push(payload);
      });
  }
});

// Export actions

// Export selectors
export const selectAnimalObjects = (state: RootState) =>
  state.animalObjects.animalObjects;
export const selectLoadingState = (state: RootState) =>
  state.animalObjects.isLoading;

// Export reducer
export default AnimalObjectSlice.reducer;
