import { Button } from '@progress/kendo-react-buttons';
import { useLocalization } from '@progress/kendo-react-intl';
import { Card, CardBody, Stepper } from '@progress/kendo-react-layout';
import React, { useState } from 'react';

import GrantAccess from '../../features/GrantAccess/GrantAccess';
import {
  ANIMALS,
  REASON,
  UNREGISTER,
  UNREGISTER_CHRONOLOGY_MSG,
  UNREGISTER_NEW,
  UNREGISTER_SUCCESS_MSG,
  UNREGISTER_TITLE,
} from '../../languages/keys/movements.keys';
import { UnregisterAnimal } from '../../slices/data/MovementSliceData';
import AnimalsUnregisterReasons from './AnimalsUnregisterReasons';
import UnregisterAnimalSelection from './UnregisterAnimalSelection';
import UnregisterConfirmation from './UnregisterConfirmation';

// TODO кои допълнителни роли трябва да се добавят
const REQUIRED_ROLES: string[] = ['Admin', 'Holding Owner', 'Farm Manager'];

const MovementContainer = () => {
  const localizationService = useLocalization();
  const [value, setValue] = useState(0);
  const [regData, setRegData] = useState<UnregisterAnimal[]>([]);
  const [showSuccess, setShowSuccess] = useState(false);
  const [animalObjectId, setAnimalObjectId] = useState<number>();

  const items = [
    {
      label: localizationService.toLanguageString(ANIMALS, ''),
    },
    {
      label: localizationService.toLanguageString(REASON, ''),
    },
    {
      label: localizationService.toLanguageString(UNREGISTER, ''),
    },
  ];

  const onToSecond = (animals: [], objectId: number) => {
    setRegData(animals);
    setAnimalObjectId(objectId);
    setValue(value + 1);
  };

  const onToThird = (animals: []) => {
    setRegData(animals);
    setValue(value + 1);
  };

  const onPrev = () => {
    setValue(value - 1);
  };

  const onSuccess = () => {
    setShowSuccess(true);
    onPrev();
  };

  const startNewRegistration = () => {
    console.log(regData);
    setValue(0);
    setShowSuccess(false);
  };

  return (
    <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
      <h1 className='page-title'>{localizationService.toLanguageString(UNREGISTER_TITLE, '')}</h1>
      {!showSuccess && (
        <div>
          <div className='content-wrapper section'>
            <Stepper value={value} items={items} />
          </div>
          <div>
            {value === 0 && <UnregisterAnimalSelection onNext={onToSecond} />}
            {value === 1 && <AnimalsUnregisterReasons onNext={onToThird} animals={regData} />}
            {value === 2 && (
              <UnregisterConfirmation
                onNext={onSuccess}
                animals={regData}
                onBack={onPrev}
                animalObjectId={animalObjectId}
              />
            )}
          </div>
        </div>
      )}
      {showSuccess && (
        <div className='row'>
          <div className='col-12 col-md-6 offset-md-3'>
            <Card>
              <CardBody className='d-flex'>
                <div className='d-flex justify-content-center align-items-center pl-l pr-l text-success'>
                  <i className='far fa-check-circle fa-4x text-success' />
                </div>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                  <h2>{localizationService.toLanguageString(UNREGISTER_SUCCESS_MSG, '')}</h2>
                  <p>{localizationService.toLanguageString(UNREGISTER_CHRONOLOGY_MSG, '')}</p>
                  <Button look='outline' onClick={startNewRegistration}>
                    {localizationService.toLanguageString(UNREGISTER_NEW, '')}
                  </Button>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      )}
    </GrantAccess>
  );
};

export default MovementContainer;
