/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/StoreHooks';
import {
  SelfProducedFodderType,
  PurchasedFodderType,
  GET_PRODUCED_FODDER,
  GET_PURCHASED_FODDER,
  SavePurchasedFodderInputType,
  GetPurchasedFodderType,
  SAVE_PURCHASED_FODDER,
  GetSelfProducedFodderType,
  GetFodderSupplierFirmsType,
  GET_SUPPLIER_FIRMS,
  FodderSupplierFirmType,
  SaveFodderType,
  SAVE_PRODUCED_FODDER,
  EDIT_PRODUCED_FODDER,
  EDIT_PURCHASED_FODDER
} from './data/FodderLogSliceData';
import {
  createAsyncThunkGeneric,
  createAsyncThunkGenericVoid
} from './GenericThunks';

type FodderLogStateType = {
  selfProducedFodder: SelfProducedFodderType[];
  productedFodderLoading: boolean;
  purchasedFodder: PurchasedFodderType[];
  purchasedFodderLoading: boolean;
  saveFodderLoading: boolean;
  supplierFirms: FodderSupplierFirmType[];
  supplierFirmsLoading: boolean;
};

const initialState: FodderLogStateType = {
  selfProducedFodder: [],
  productedFodderLoading: false,
  purchasedFodder: [],
  purchasedFodderLoading: false,
  saveFodderLoading: false,
  supplierFirms: [],
  supplierFirmsLoading: false
};

export const getProducedFodder = createAsyncThunkGenericVoid(
  'fodderLogs/getSelfProducedFodder',
  async ({ client }) => {
    const response = await client.query<void, GetSelfProducedFodderType>(
      'feedDiarySelfMade',
      GET_PRODUCED_FODDER
    );
    return response?.items ? response?.items : [];
  }
);

export const saveProducedFodder = createAsyncThunkGeneric<
SaveFodderType,
SelfProducedFodderType
>('fodderLogs/saveProducedFodder', async ({ client, input }) =>
  client.mutate<SavePurchasedFodderInputType, SelfProducedFodderType>(
    'addFeedDiary',
    SAVE_PRODUCED_FODDER,
    { feedDiaryInput: input }
  ));

export const editProducedFodder = createAsyncThunkGeneric<
SaveFodderType,
SelfProducedFodderType
>('fodderLogs/editProducedFodder', async ({ client, input }) =>
  client.mutate<SavePurchasedFodderInputType, SelfProducedFodderType>(
    'updateFeedDiary',
    EDIT_PRODUCED_FODDER,
    { feedDiaryInput: input }
  ));

export const getPurchasedFodder = createAsyncThunkGenericVoid(
  'fodderLogs/getPurchasedFodder',
  async ({ client }) => {
    const response = await client.query<void, GetPurchasedFodderType>(
      'feedDiaryBought',
      GET_PURCHASED_FODDER
    );
    return response?.items ? response?.items : [];
  }
);

export const savePurchasedFodder = createAsyncThunkGeneric<
SaveFodderType,
PurchasedFodderType
>('fodderLogs/savePurchasedFodder', async ({ client, input }) =>
  client.mutate<SavePurchasedFodderInputType, PurchasedFodderType>(
    'addFeedDiary',
    SAVE_PURCHASED_FODDER,
    { feedDiaryInput: input }
  ));

export const editPurchasedFodder = createAsyncThunkGeneric<
SaveFodderType,
PurchasedFodderType
>('fodderLogs/editPurchasedFodder', async ({ client, input }) =>
  client.mutate<SavePurchasedFodderInputType, PurchasedFodderType>(
    'updateFeedDiary',
    EDIT_PURCHASED_FODDER,
    { feedDiaryInput: input }
  ));

export const getSupplierFirms = createAsyncThunkGenericVoid(
  'fodderLogs/getSupplierFirms',
  async ({ client }) => {
    const response = await client.query<void, GetFodderSupplierFirmsType>(
      'typeOfFirm',
      GET_SUPPLIER_FIRMS
    );
    return response?.items ? response?.items : [];
  }
);

const FodderLogSlice = createSlice({
  name: 'fodderLogs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getProducedFodder.pending, (state) => {
        state.productedFodderLoading = true;
      })
      .addCase(getProducedFodder.fulfilled, (state, { payload }) => {
        state.productedFodderLoading = false;
        state.selfProducedFodder = payload.map((fodder) =>
          ({
            ...fodder,
            feedDiaryDate: new Date(fodder.feedDiaryDate)
          }));
      })
      .addCase(saveProducedFodder.pending, (state) => {
        state.saveFodderLoading = true;
      })
      .addCase(saveProducedFodder.fulfilled, (state, { payload }) => {
        state.saveFodderLoading = false;
        state.selfProducedFodder.push({
          ...payload,
          feedDiaryDate: new Date(payload?.feedDiaryDate)
        });
      })
      .addCase(editProducedFodder.pending, (state) => {
        state.productedFodderLoading = true;
      })
      .addCase(editProducedFodder.fulfilled, (state, { payload }) => {
        state.productedFodderLoading = true;
        const updIndex = state.selfProducedFodder.findIndex((fodder) =>
          fodder.feedDiaryId === payload?.feedDiaryId);
        state.selfProducedFodder[updIndex] = {
          ...payload,
          feedDiaryDate: new Date(payload?.feedDiaryDate)
        };
      })
      .addCase(getPurchasedFodder.pending, (state) => {
        state.purchasedFodderLoading = true;
      })
      .addCase(getPurchasedFodder.fulfilled, (state, { payload }) => {
        state.purchasedFodderLoading = false;
        state.purchasedFodder = payload.map((fodder) =>
          ({
            ...fodder,
            feedDiaryDate: new Date(fodder.feedDiaryDate)
          }));
      })
      .addCase(savePurchasedFodder.pending, (state) => {
        state.saveFodderLoading = true;
      })
      .addCase(savePurchasedFodder.fulfilled, (state, { payload }) => {
        state.saveFodderLoading = true;
        state.purchasedFodder.push({
          ...payload,
          feedDiaryDate: new Date(payload?.feedDiaryDate)
        });
      })
      .addCase(editPurchasedFodder.pending, (state) => {
        state.purchasedFodderLoading = true;
      })
      .addCase(editPurchasedFodder.fulfilled, (state, { payload }) => {
        state.purchasedFodderLoading = false;
        const updIndex = state.purchasedFodder.findIndex((fodder) =>
          fodder.feedDiaryId === payload?.feedDiaryId);
        state.purchasedFodder[updIndex] = {
          ...payload,
          feedDiaryDate: new Date(payload?.feedDiaryDate)
        };
      })
      .addCase(getSupplierFirms.pending, (state) => {
        state.supplierFirmsLoading = true;
      })
      .addCase(getSupplierFirms.fulfilled, (state, { payload }) => {
        state.supplierFirmsLoading = false;
        state.supplierFirms = payload.map(({ firm }) =>
          (
            {
              firmABId: firm.firmABId,
              firmABName: firm.firmABName
            }
          ));
      });
  }
});

// Selectors
export const selectSelfProducedFodder = (state: RootState) =>
  state.fodderLog.selfProducedFodder;
export const selectProducedFodderLoadingState = (state: RootState) =>
  state.fodderLog.productedFodderLoading;
export const selectPurchasedFodder = (state: RootState) =>
  state.fodderLog.purchasedFodder;
export const selectPurchasedFodderLoadingState = (state: RootState) =>
  state.fodderLog.purchasedFodderLoading;
export const selectSupplierFirms = (state: RootState) =>
  state.fodderLog.supplierFirms;

// Reducer
export default FodderLogSlice.reducer;
