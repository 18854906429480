/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/StoreHooks';
import {
  ContragentType,
  ContragentsFirms,
  ContragentsResponseType,
  GET_CONTRAGENTS,
  ContragentsFirmsResponseType,
  GET_CONTRAGENT_FIRMS,
  SentContragentInformationType,
  SAVE_CONTRAGENT,
  ContragentFirmInputType,
  GetContragentsType,
  GET_CONTRAGENT_DRIVERS,
  EDIT_CONTRAGENT
} from './data/ContragentsSliceData';
import {
  createAsyncThunkGeneric,
  createAsyncThunkGenericVoid
} from './GenericThunks';

type ABContragentsState = {
  contragents: ContragentType[];
  availableABFirms: ContragentsFirms[];
  contragentsLoading: boolean;
};

const initialState: ABContragentsState = {
  contragents: [],
  availableABFirms: [],
  contragentsLoading: false
};

// Async thunks
export const getAllContragents = createAsyncThunkGenericVoid(
  'contragents/getAllContragents',
  async ({ client }) => {
    const response = await client.query<void, ContragentsResponseType>(
      'contragent',
      GET_CONTRAGENTS
    );
    return response?.items ? response?.items : [];
  }
);

export const getAvailableABLFirms = createAsyncThunkGenericVoid(
  'contragents/getAvailableABLFirms',
  async ({ client }) => {
    const response = await client.query<void, ContragentsFirmsResponseType>(
      'addressBookFirm',
      GET_CONTRAGENT_FIRMS
    );
    return response?.items ? response?.items : [];
  }
);

export const saveContragentForm = createAsyncThunkGeneric<
ContragentType,
ContragentType
>('contragents/saveContragent', async ({ client, input }) =>
  client.mutate<SentContragentInformationType, ContragentType>(
    'addContragent',
    SAVE_CONTRAGENT,
    { contragent: input }
  ));

export const editContragent = createAsyncThunkGeneric<
ContragentType,
ContragentType
>('contragents/editContragent', async ({ client, input }) => {
  // eslint-disable-next-line @typescript-eslint/dot-notation
  delete input['firm'];
  return client.mutate<SentContragentInformationType, ContragentType>(
    'updateContragent',
    EDIT_CONTRAGENT,
    { contragent: input }
  );
});

export const getContragentFirmDrivers = createAsyncThunkGeneric<
number,
ContragentType[]
>('contragents/getContragentDriver', async ({ client, input }) => {
  const response = await client.query<
  ContragentFirmInputType,
  GetContragentsType
  >('contragent', GET_CONTRAGENT_DRIVERS, { firmABId: input });
  return response?.items;
});

// Slice
export const ABContragentsSlice = createSlice({
  name: 'contragents',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllContragents.pending, (state) => {
        state.contragentsLoading = true;
      })
      .addCase(getAllContragents.fulfilled, (state, { payload }) => {
        state.contragentsLoading = false;
        state.contragents = payload;
      })
      .addCase(getAvailableABLFirms.pending, (state) => {
        state.contragentsLoading = true;
      })
      .addCase(getAvailableABLFirms.fulfilled, (state, { payload }) => {
        state.contragentsLoading = false;
        state.availableABFirms = payload;
      })
      .addCase(saveContragentForm.pending, (state) => {
        state.contragentsLoading = true;
      })
      .addCase(saveContragentForm.fulfilled, (state, { payload }) => {
        state.contragentsLoading = false;
        state.contragents.push(payload);
      })
      .addCase(editContragent.pending, (state) => {
        state.contragentsLoading = true;
      })
      .addCase(editContragent.fulfilled, (state, { payload }) => {
        state.contragentsLoading = false;
        const updated = state.contragents.findIndex(
          (contragent) =>
            contragent.contragentId === payload?.contragentId
        );
        state.contragents[updated] = payload;
      })
      .addCase(getContragentFirmDrivers.pending, (state) => {
        state.contragentsLoading = true;
      })
      .addCase(getContragentFirmDrivers.fulfilled, (state, { payload }) => {
        state.contragentsLoading = false;
        // console.log(payload);
        state.contragents = payload;
      });
  }
});

// Selectors
export const selectAllContragents = (state: RootState) =>
  state.contragents.contragents;
export const selectContragentsLoading = (state: RootState) =>
  state.contragents.contragentsLoading;
export const selectContragentAvailableFirms = (state: RootState) =>
  state.contragents.availableABFirms;
// Export reducer
export default ABContragentsSlice.reducer;
