/* eslint-disable */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/StoreHooks';

// Define the type for the initial state
type SearchState = {
  searchLibrary: string[];
  filteredSearch: string[];
};

const initialState: SearchState = {
  searchLibrary: ['Информация', 'Ваксини', 'Животни'],
  filteredSearch: []
};

// Async Thunks

export const SearchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    filterSearch: (state, { payload }: PayloadAction<string>) => {
      state.filteredSearch = payload
        ? state.searchLibrary.filter((el) =>
          el.toLowerCase().includes(payload.toLowerCase()))
        : undefined;
    }
  },
  extraReducers: {}
});

// Export actions
export const { filterSearch } = SearchSlice.actions;

// Selectors
export const selectSearchLibrary = (state: RootState) =>
  state.search.searchLibrary;
export const selectFilteredSearch = (state: RootState) =>
  state.search.filteredSearch;

// Export the Slice
export default SearchSlice.reducer;
