import { gql } from '@apollo/client';
import { AnimalObjectType } from './AnimalObjectSliceData';
import { AnimalSexEnum, AnimalType, AnimalTypeEnum } from './AnimalSliceData';
import { PurchasedMedicineType } from './PurchasedMedicinesSliceData';

// Types

type AmbulatoryLogAnimalType = {
  animalId: number;
  lID: string;
  cattleBreed: string;
  animalType: AnimalTypeEnum;
  sex: AnimalSexEnum;
  dateOfBirth: Date;
};

export type AmbulatoryLogMedicineType = {
  medicineId: number;
  medicineName: string;
};

type AmbulatoryLogAnimalObjectType = {
  animalObjectId: number;
  animalObjectName: string;
};

export type ReceivedAmbulatoryLogType = {
  ambulatoryDiaryId: number;
  ambulatoryNumber: string;
  ambulatoryDate: Date;
  clinicalData: string;
  diagnosticTest: string;
  diagnosis: string;
  withdrawalPeriod: string;
  dose: string;
  diagnosisOut: string;
  comment: string;
  animalId: number;
  animal: {
    animalObjects: AnimalObjectType;
  } & AnimalType;
  medicine: PurchasedMedicineType[];
  animalObjects: AnimalObjectType;
};

export type AmbulatoryLogType = {
  ambulatoryDiaryId: number;
  ambulatoryNumber: string;
  ambulatoryDate: Date;
  clinicalData: string;
  diagnosticTest: string;
  diagnosis: string;
  withdrawalPeriod: string;
  dose: string;
  diagnosisOut: string;
  comment: string;
  animalId: number;
  animal: AmbulatoryLogAnimalType;
  medicine: AmbulatoryLogMedicineType[];
  animalObject: AmbulatoryLogAnimalObjectType;
};

export type GetAmbulatoryLogType = {
  items: ReceivedAmbulatoryLogType[];
};

export type AmbulatoryInputType = {
  ambulatoryDiaryId?: number;
  ambulatoryDate: Date;
  animalId: number;
  ambulatoryNumber: string;
  clinicalData: string;
  diagnosticTest: string;
  diagnosis: string;
  withdrawalPeriod: string;
  dose: string;
  diagnosisOut: string;
  comment: string;
  abulatoryMedcine: {
    medicineId: number,
    ambulatoryDiaryId?: number
  }[];
};

export type AmbulatoryMutationInputType = {
  ambulatoryDiaryInput: AmbulatoryInputType;
};
// Queries & Mutations
export const GET_ALL_AMBULATORY_LOG_ENTRIES = gql`
  {
    ambulatoryDiary {
      items {
        ambulatoryDiaryId
        ambulatoryNumber
        ambulatoryDate
        clinicalData
        diagnosticTest
        diagnosis
        withdrawalPeriod
        dose
        diagnosisOut
        comment
        animal {
          animalId
          lID
          cattleBreed
          animalType
          sex
          dateOfBirth
          animalObjects {
            animalObjectId
            animalObjectName
          }
        }
        medicine {
          medicineId
          medicineName
        }
      }
    }
  }
`;

export const SAVE_AMBULATORY_LOG = gql`
  mutation AddAmbulatoryDiary($ambulatoryDiaryInput: ambulatoryDiaryInput) {
    addAmbulatoryDiary(ambulatoryDiaryInput: $ambulatoryDiaryInput) {
      ambulatoryDiaryId
      ambulatoryNumber
      ambulatoryDate
      clinicalData
      diagnosticTest
      diagnosis
      withdrawalPeriod
      dose
      diagnosisOut
      comment
      animal {
        animalId
        lID
        cattleBreed
        animalType
        sex
        dateOfBirth
        animalObjects {
          animalObjectId
          animalObjectName
        }
      }
      medicine {
        medicineId
        medicineName
      }
    }
  }
`;

export const EDIT_AMBULATORY_LOG = gql`
mutation UpdateAmbulatoryDiary($ambulatoryDiaryInput: ambulatoryDiaryInput) {
  updateAmbulatoryDiary(ambulatoryDiaryInput: $ambulatoryDiaryInput) {
    ambulatoryDiaryId
    ambulatoryNumber
    ambulatoryDate
    clinicalData
    diagnosticTest
    diagnosis
    withdrawalPeriod
    dose
    diagnosisOut
    comment
    animal {
      animalId
      lID
      cattleBreed
      animalType
      sex
      dateOfBirth
      animalObjects {
        animalObjectId
        animalObjectName
      }
    }
    medicine {
      medicineId
      medicineName
    }
  }
}
`;
