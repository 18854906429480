import { Popup } from '@progress/kendo-react-popup';
import React from 'react';

const DropdownMenu = ({ children }: { children: JSX.Element }) => {
  const anchor = React.useRef<any>();
  const [show, setShow] = React.useState(false);
  const contentRef = React.createRef<HTMLDivElement>();
  // const blurTimeoutRef = React.createRef();
  let timeOut;

  React.useEffect(() => {
    setShow(false);
  }, []);

  const onClick = () => {
    setShow(!show);
  };

  const onOpen = () => {
    (contentRef.current as HTMLElement).focus();
  };

  const onFocus = () => {
    // the user is still inside the content
    clearTimeout(timeOut);
  };

  const onBlurTimeout = () => {
    // the user is now outside the popup
    setShow(false);
  };

  const onBlur = () => {
    clearTimeout(timeOut);

    timeOut = setTimeout(onBlurTimeout, 200);
  };

  return (
    <div>
      <span
        className='fa fa-ellipsis-v pointer'
        style={{ width: '2em' }}
        onClick={onClick}
        onKeyDown={onClick}
        ref={anchor}
        role='menu'
        tabIndex={0}
      />
      <Popup anchor={anchor.current} show={show} popupClass='popup-content' onOpen={onOpen}>
        <div ref={contentRef} tabIndex={0} onFocus={onFocus} onBlur={onBlur}>
          {children}
        </div>
      </Popup>
    </div>
  );
};

export default DropdownMenu;
