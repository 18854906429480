import React from 'react';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { useLocalization } from '@progress/kendo-react-intl';
import { FirmType } from '../../slices/data/FirmSliceData';
import { FormInput } from '../FormComponents/FormComponents';
import { StringValidator, NumberValidator } from '../Validators/Validators';
import {
  EIK,
  FIRM_ADDRESS,
  FIRM_CITY,
  FIRM_COUNTRY,
  FIRM_MOL,
  FIRM_NAME,
  SUBMIT_FORM,
} from '../../languages/languages';

type FirmFormPops = {
  onSubmit: (formData: FirmType) => void;
};

const FirmForm = ({
  onSubmit
}:FirmFormPops) => {
  const localizationService = useLocalization();

  return (
    <Form
      onSubmit={onSubmit}
      render={(formRenderProps) =>
        (
          <FormElement>
            <fieldset className='k-form-fieldset'>
              <Field
                name='firmEIK'
                component={FormInput}
                label={localizationService.toLanguageString(EIK, '')}
                validator={NumberValidator}
                placeholder={localizationService.toLanguageString(EIK, '')}
              />

              <Field
                name='firmName'
                component={FormInput}
                label={localizationService.toLanguageString(FIRM_NAME, '')}
                required
                placeholder={localizationService.toLanguageString(FIRM_NAME, '')}
                validator={StringValidator}
              />
              <Field
                name='firmCountry'
                component={FormInput}
                label={localizationService.toLanguageString(FIRM_COUNTRY, '')}
                required
                placeholder={localizationService.toLanguageString(
                  FIRM_COUNTRY,
                  ''
                )}
                validator={StringValidator}
              />
              <Field
                name='firmCity'
                component={FormInput}
                label={localizationService.toLanguageString(FIRM_CITY, '')}
                required
                placeholder={localizationService.toLanguageString(FIRM_CITY, '')}
                validator={StringValidator}
              />

              <Field
                name='firmAddress'
                component={FormInput}
                label={localizationService.toLanguageString(FIRM_ADDRESS, '')}
                placeholder={localizationService.toLanguageString(
                  FIRM_ADDRESS,
                  ''
                )}
                validator={StringValidator}
              />

              <Field
                name='firmMOL'
                component={FormInput}
                label={localizationService.toLanguageString(FIRM_MOL, '')}
                placeholder={localizationService.toLanguageString(FIRM_MOL, '')}
                validator={StringValidator}
              />
            </fieldset>
            <fieldset className='k-form-buttons d-flex justify-content-end mb-l'>
              <Button
                name='FirmFormAdd'
                type='submit'
                title={localizationService.toLanguageString(SUBMIT_FORM, '')}
                primary
                icon='save'
                disabled={!formRenderProps.allowSubmit}
              >
                {localizationService.toLanguageString(SUBMIT_FORM, '')}
              </Button>
            </fieldset>
          </FormElement>
        )}
    />
  );
};

export default FirmForm;
