import React, { useEffect, useState } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { useLocalization } from '@progress/kendo-react-intl';
import {
  FeedDiaryTypeEnum,
  PurchasedFodderType,
  SaveFodderType
} from '../../slices/data/FodderLogSliceData';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import {
  editPurchasedFodder,
  getPurchasedFodder,
  getSupplierFirms,
  savePurchasedFodder,
  selectPurchasedFodder
} from '../../slices/FodderLogSlice';
import PurchasedFodderLogGrid from '../../components/PurchasedFodderLogGrid/PurchasedFodderLogGrid';
import PurchasedFodderForm from '../../components/PurchasedFodderForm/PurchasedFodderForm';
import { ADD_NEW, EDIT } from '../../languages/languages';
import GrantAccess from '../GrantAccess/GrantAccess';

const REQUIRED_ROLES: string[] = ['Admin', 'Farm Manager', 'Holding Owner', 'Farm Veterinary Inspector'];

const PurchasedFodderLogGridContainer = () => {
  const localizationService = useLocalization();
  const dispatch = useAppDispatch();
  const purchasedFodder = useAppSelector(selectPurchasedFodder);

  const [showFodderForm, setShowFodderForm] = useState<boolean>(false);

  useEffect(() => {
    const awaitData = () => {
      dispatch(getPurchasedFodder());
    };
    awaitData();
  }, []);

  const openFodderForm = () => {
    setShowFodderForm(true);
    dispatch(getSupplierFirms());
  };

  const closeFodderForm = () => {
    setShowFodderForm(false);
  };

  const submitLog = (formData: SaveFodderType) => {
    dispatch(
      savePurchasedFodder({
        ...formData,
        feedDiaryType: FeedDiaryTypeEnum.BOUGHT
      })
    );
    closeFodderForm();
  };

  const [editFormView, setEditFormView] = useState<boolean>(false);
  const [fodderEditItem, setFodderEditItem] = useState<PurchasedFodderType>(null);

  const openEditForm = (editItem: PurchasedFodderType) => {
    dispatch(getSupplierFirms());
    setFodderEditItem(editItem);
    setEditFormView(true);
  };

  const closeEditForm = () => {
    setEditFormView(false);
  };

  const submitEditedFodder = (formData: PurchasedFodderType) => {
    const updtFodder: SaveFodderType = {
      feedDiaryId: formData.feedDiaryId,
      comment: formData.comment,
      feedCategory: formData.feedCategory,
      feedDiaryDate: formData.feedDiaryDate,
      feedDiaryType: FeedDiaryTypeEnum.BOUGHT,
      quantity: formData.quantity,
      feedName: formData.feedName,
      firmABId: formData.firm.firmABId,
      lotNumber: formData.lotNumber
    };
    dispatch(editPurchasedFodder(updtFodder));
    closeEditForm();
  };

  return (
    <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
      <PurchasedFodderLogGrid
        openLogForm={openFodderForm}
        data={purchasedFodder}
        openEdit={openEditForm}
      />
      <GrantAccess haveRoles='some' requiredRoles={['Admin', 'Farm Manager', 'Holding Owner']}>
        {showFodderForm && (
        <Dialog
          title={localizationService.toLanguageString(ADD_NEW, '')}
          onClose={closeFodderForm}
        >
          <PurchasedFodderForm close={closeFodderForm} submitForm={submitLog} />
        </Dialog>
        )}
        {editFormView && (
        <Dialog title={localizationService.toLanguageString(EDIT, '')} onClose={closeEditForm}>
          <PurchasedFodderForm
            editItem={fodderEditItem}
            close={closeEditForm}
            submitForm={submitEditedFodder}
          />
        </Dialog>
        )}
      </GrantAccess>
    </GrantAccess>
  );
};

export default PurchasedFodderLogGridContainer;
