import React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import NonSelectableGrid from '../SelectableGrid/NonSelectableGrid';
import { ColumnType } from '../../slices/data/AnimalColumnsSliceData';

const ItemsDisplayGrid = ({
  data
}: {
  data: [];
}) => {
  const columns = [
    {
      title: 'Статус',
      field: 'accepted',
      show: true,
      filterable: false,
      width: '100 px',
      cell: ({ dataItem }: GridCellProps) => {
        let iconClass = 'fa fa-';
        if (dataItem.accepted) {
          iconClass += 'thumbs-up text-success';
        } else {
          iconClass += 'thumbs-down text-error';
        }
        return (
          <td className='text-center'>
            <i className={iconClass} />
          </td>
        );
      },
    },
    {
      title: 'Партида №',
      field: 'item.batchNumber',
      show: true,
      filter: 'text',
    },
    {
      title: 'Партида дата',
      field: 'item.batchDate',
      show: true,
      filter: 'date',
      format: '{0:dd.MM.yyy}',
    },
    {
      title: 'Животно №',
      field: 'item.animalLId',
      show: true,
      filter: 'text',
    },
    {
      title: 'SEUROP',
      field: 'item.sEUROP',
      show: true,
      filter: 'text',
    },
    {
      title: 'USDA',
      field: 'item.uSDA',
      show: true,
      filter: 'text',
    },
    {
      title: 'Вид на среза',
      field: 'item.cuttingType',
      show: true,
      filter: 'text',
    },
    {
      title: 'Сечение',
      field: 'item.cutName',
      show: true,
      filter: 'text',
    },
    {
      title: 'Тегло',
      field: 'item.weight',
      show: true,
      filter: 'text',
      format: '{0} кг.',
    },
  ] as ColumnType[];

  return <NonSelectableGrid data={data} stateColumns={columns} />;
};

export default ItemsDisplayGrid;
