import React, { useState } from 'react';
import {
  GridDataStateChangeEvent,
  GridItemChangeEvent
} from '@progress/kendo-react-grid';
import { State } from '@progress/kendo-data-query';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import { selectSalelData, setSaleData } from '../../slices/SellOperationSlice';
import {
  selectSelectedAnimals,
  setSelectedAnimals
} from '../../slices/AnimalSlice';
import { SelectedAnimalType } from '../../slices/data/AnimalSliceData';
import SellAnimalsGrid from '../SellAnimalsGrid/SellAnimalsGrid';

const SellAnimalsGridContainer = () => {
  // Redux block
  // Init dispatch
  const dispatch = useAppDispatch();

  // Selectors
  const saleData = useAppSelector(selectSalelData);
  const selectedAnimals = useAppSelector(selectSelectedAnimals);

  // End redux block

  // Grid States
  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0
  });

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  };

  //   Inline Editing functions
  const [editField, setEditField] = useState<string>('animalWeight');

  const enterEdit = (dataItem: SelectedAnimalType, field: string) => {
    const newData = selectedAnimals.map((item) =>
      ({
        ...item,
        inEdit: item.animalId === dataItem.animalId ? field : 'animalWeight'
      }));
    dispatch(setSelectedAnimals(newData));
    setEditField(field);
  };

  const exitEdit = () => {
    const newData = selectedAnimals.map((item) =>
      ({
        ...item,
        inEdit: undefined
      }));

    dispatch(setSelectedAnimals(newData));
    setEditField('animalWeight');
  };

  const itemChange = ({ field, value, dataItem }: GridItemChangeEvent) => {
    const selectedField = field || '';
    const newData = selectedAnimals.map((item) => {
      if (item.animalId === dataItem.animalId) {
        return {
          ...item,
          [selectedField]:
            selectedField === 'animalWeight' ? Number(value) : value
        };
      }
      return item;
    });
    let averageWeight: number = 0;
    newData.forEach((animal) => {
      averageWeight += animal.animalWeight;
    });
    dispatch(setSelectedAnimals(newData));
    dispatch(setSaleData(['averageWeight', averageWeight]));
  };

  return (
    <SellAnimalsGrid
      itemChange={itemChange}
      exitEdit={exitEdit}
      enterEdit={enterEdit}
      editField={editField}
      dataState={dataState}
      dataStateChange={dataStateChange}
      saleData={saleData}
      selectedAnimals={selectedAnimals}
      setDataState={setDataState}
    />
  );
};

export default SellAnimalsGridContainer;
