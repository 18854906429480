import React from 'react';
import {
  Form,
  Field,
  FormElement,
  FormRenderProps
} from '@progress/kendo-react-form';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { useLocalization } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';
import {
  FormComboBox,
  FormInput,
  FormNumericTextBox,
  FormRadioGroup
} from '../FormComponents/FormComponents';
import {
  NumberValidator,
  StringValidator
} from '../Validators/Validators';
import {
  ADDRESS_PLACEHOLDER,
  AMOUNT_PLACEHOLDER,
  AO_ADDRESS,
  AO_BACREAGE,
  AO_BRANCH,
  AO_BUILDINGS,
  AO_CAPACITY,
  AO_CATTLE,
  AO_CITY,
  AO_NAME,
  AO_NUMBER,
  AO_OWNER,
  AO_PACREAGE,
  AO_PASTURES,
  AO_SHEEP,
  AO_TRACES,
  CITY_PLACEHODLDER,
  OWNER_PLACEHOLDER,
  SUBMIT_FORM
} from '../../languages/languages';
import { AnimalObjectType } from '../../slices/data/AnimalObjectSliceData';
import { AvailableFirmsType } from '../../slices/data/FirmSliceData';
import { HoldingProfile } from '../../slices/data/HoldingSliceData';

type AnimalObjectFormProps = {
  submitForm: (formData: AnimalObjectType) => void;
  availableFirms: AvailableFirmsType[];
  holding: HoldingProfile
};

const AnimalObjectForm = ({ submitForm, availableFirms, holding }:AnimalObjectFormProps) => {
  const localizationService = useLocalization();

  const data: { label: string; value: string }[] = [
    {
      label: localizationService.toLanguageString(AO_CATTLE, ''),
      value: 'CATTLE'
    },
    {
      label: localizationService.toLanguageString(AO_SHEEP, ''),
      value: 'SHEEP'
    }
  ];

  return (
    <Form
      onSubmit={submitForm}
      render={(formRenderProps: FormRenderProps) =>
        (
          <FormElement>
            <fieldset className='k-form-fieldset'>
              <Field
                name='animalObjectName'
                component={FormInput}
                label={localizationService.toLanguageString(AO_NAME, '')}
                placeholder={localizationService.toLanguageString(AO_NAME, '')}
                validator={StringValidator}
              />

              <Field
                name='animalObjectNumber'
                component={FormInput}
                label={localizationService.toLanguageString(AO_NUMBER, '')}
                placeholder={localizationService.toLanguageString(
                  AO_NUMBER,
                  ''
                )}
                validator={StringValidator}
              />

              <Field
                name='animalObjectNumberTraces'
                component={FormInput}
                label={localizationService.toLanguageString(AO_TRACES, '')}
                placeholder={localizationService.toLanguageString(
                  AO_TRACES,
                  ''
                )}
                validator={StringValidator}
              />

              <Field
                name='animalObjectCity'
                component={FormInput}
                label={localizationService.toLanguageString(AO_CITY, '')}
                placeholder={localizationService.toLanguageString(
                  CITY_PLACEHODLDER,
                  ''
                )}
                validator={StringValidator}
              />

              <Field
                name='animalObjectAddress'
                component={FormInput}
                label={localizationService.toLanguageString(AO_ADDRESS, '')}
                placeholder={localizationService.toLanguageString(
                  ADDRESS_PLACEHOLDER,
                  ''
                )}
                validator={StringValidator}
              />
            </fieldset>
            <fieldset className='k-form-fieldset'>
              <Field
                name='animalObjectCapacity'
                component={FormNumericTextBox}
                label={localizationService.toLanguageString(AO_CAPACITY, '')}
                format='n0'
                placeholder={localizationService.toLanguageString(
                  AMOUNT_PLACEHOLDER,
                  ''
                )}
                validator={NumberValidator}
              />

              <Field
                name='animalObjectBuildings'
                component={FormNumericTextBox}
                label={localizationService.toLanguageString(AO_BUILDINGS, '')}
                format='n0'
                placeholder={localizationService.toLanguageString(
                  AMOUNT_PLACEHOLDER,
                  ''
                )}
                validator={NumberValidator}
              />

              <Field
                name='animalObjectBuildingsAcreage'
                component={FormNumericTextBox}
                label={localizationService.toLanguageString(AO_BACREAGE, '')}
                placeholder='m2'
                validator={StringValidator}
              />

              <Field
                name='animalObjectPastures'
                component={FormNumericTextBox}
                label={localizationService.toLanguageString(AO_PASTURES, '')}
                format='n0'
                placeholder={localizationService.toLanguageString(
                  AMOUNT_PLACEHOLDER,
                  ''
                )}
                validator={StringValidator}
              />

              <Field
                name='animalObjectPasturesAcreage'
                component={FormNumericTextBox}
                label={localizationService.toLanguageString(AO_PACREAGE, '')}
                placeholder='ha'
                validator={StringValidator}
              />

              <Field
                onChange={({ value }: ComboBoxChangeEvent) => {
                  if (value === null) {
                    return;
                  }
                  formRenderProps.onChange('firmId', { value: value.firmId });
                }}
                placeholder={localizationService.toLanguageString(
                  OWNER_PLACEHOLDER,
                  ''
                )}
                id='firmName'
                name='firmId'
                label={localizationService.toLanguageString(AO_OWNER, '')}
                component={FormComboBox}
                textField='firmName'
                data={availableFirms}
                validator={StringValidator}
              />
              {(!holding?.cattle && !holding?.sheep) && (
                <Field
                  id='animalObjectBranch'
                  name='animalObjectBranch'
                  label={localizationService.toLanguageString(AO_BRANCH, '')}
                  layout='horizontal'
                  component={FormRadioGroup}
                  data={data}
                  className='pt-m'
                />
              )}
            </fieldset>
            <fieldset className='k-form-buttons d-flex justify-content-end mb-l'>
              <Button
                name='AnimalObjectFormAdd'
                type='submit'
                title={localizationService.toLanguageString(SUBMIT_FORM, '')}
                primary
                icon='save'
                disabled={!formRenderProps.allowSubmit}
              >
                {localizationService.toLanguageString(SUBMIT_FORM, '')}
              </Button>
            </fieldset>
          </FormElement>
        )}
    />
  );
};

export default AnimalObjectForm;
