import { gql } from '@apollo/client';

import CreateGQLClient, { getGraphQLClient } from '../app/Client';
import { IListResult } from '../interfaces/request';
import { ITraderWarehouseItemHistory } from '../models/warehouse';
import { ITraderWarehouseGridItem, IWarehouseGridItem } from '../slices/data/WarehouseSliceData';

const client = getGraphQLClient() || CreateGQLClient();
const stocksInEnterpriseWarehouseQuery = gql`
query stocksInEnterpriseWarehouse($active: Boolean, $enterpriseId: Int) {
  stocksInEnterpriseWarehouse(active: $active, enterpriseId: $enterpriseId) {
    items {
      active,
      animalAge,
      animalLId,
      animalSex,
      batchCutId,
      batchDate,
      batchId,
      batchNumber,
      cutName,
      cutNameId,
      cuttingType,
      cuttingTypeId,
      enterprice,
      enterpriceId,
      holding,
      holdingId,
      processingAsService,
      sEUROP,
      uneceCutCode,
      uSDA,
      weight
    }
  }
}
`;

const stocksInVirtualWarehouseQuery = gql`
query stocksInVirtualWarehouse($firmId: Int) {
  stocksInVirtualWarehouse(firmId: $firmId) {
    items {
      active,
      animalAge,
      animalLId,
      animalSex,
      batchCutId,
      batchDate,
      batchId,
      batchNumber,
      cutName,
      cutNameId,
      cuttingType,
      cuttingTypeId,
      enterprice,
      enterpriceId,
      holding,
      holdingId,
      sEUROP,
      uneceCutCode,
      uSDA,
      weight,
      processingAsService,
      processingAsServiceFirm
    }
  }
}
`;

const stocksInShoppingCenterWarehouseQuery = gql`
query stocksInTraderWarehouse($shoppingCenterId: Int) {
  stocksInTraderWarehouse(shoppingCenterId: $shoppingCenterId) {
    items {
      active,
      animalAge,
      animalAgeClass,
      animalBreed,
      animalLId,
      animalSex,
      batchCutId,
      batchDate,
      batchId,
      batchNumber,
      cutName,
      cutNameId,
      cuttingType,
      cuttingTypeId,
      enterprice,
      enterpriceId,
      expirationDate,
      sEUROP,
      uneceCutCode,
      uSDA,
      weight,
      soldWeight,
      wasteWeight,
      remainingWeight,
      isWaste,
      expirationDate
    }
  }
}
`;

const traderWarehouseItemQuery = gql`
query traderWarehouseItem($batchCutId: Int) {
  traderWarehouseItem(batchCutId: $batchCutId) {
    animalAge,
    animalLId,
    animalSex,
    batchCutId,
    batchDate,
    batchNumber,
    cutName,
    cuttingType,
    enterprice,
    sEUROP,
    uSDA,weight,
    soldWeight,
    wasteWeight,
    remainingWeight,
    isWaste,
    expirationDate
  }
}`;

const traderWarehouseItemUnregisteredMutation = gql`
mutation traderWarehouseItemUnregister($batchCutId: Int) {
  traderWarehouseItemUnregister(batchCutId: $batchCutId) {
    batchCutId,
    active
  }
}`;

const traderWarehouseItemHistoryMutation = gql`
mutation traderWarehouseItemHistory($data: TraderWarehouseItemHistoryInputType) {
  traderWarehouseItemHistory(data: $data) {
    batchCutId,
    eventDate,
    remainingQuantity,
    soldQuantity,
    wasteQuantity
  }
}
`;

class WarehouseService {
  getAnimmalsInButcheryObject(enterpriseId: number): Promise<IWarehouseGridItem[]> {
    return new Promise((resolve, reject) => {
      client
        .query<{ enterpriseId: number }, IListResult<IWarehouseGridItem>>('stocksInEnterpriseWarehouse', stocksInEnterpriseWarehouseQuery, { enterpriseId })
        .then((data) => {
          resolve(data.items || []);
        })
        .catch(reject);
    });
  }

  getProcessingAsServiceAnimalsForFarmer(firmId: number): Promise<IWarehouseGridItem[]> {
    return new Promise((resolve, reject) => {
      client
        .query<{ firmId: number }, IListResult<IWarehouseGridItem>>('stocksInVirtualWarehouse', stocksInVirtualWarehouseQuery, { firmId })
        .then((data) => {
          resolve(data.items || []);
        })
        .catch(reject);
    });
  }

  getWarehouseItemsInShoppingCenter(shoppingCenterId: number): Promise<ITraderWarehouseGridItem[]> {
    return new Promise((resolve, reject) => {
      client
        .query<{ shoppingCenterId: number }, IListResult<ITraderWarehouseGridItem>>('stocksInTraderWarehouse', stocksInShoppingCenterWarehouseQuery, { shoppingCenterId })
        .then((data) => {
          if (data) {
            resolve(data.items || []);
          } else {
            resolve([]);
          }
        })
        .catch(reject);
    });
  }

  getTraderWarehouseItem(batchCutId: number): Promise<ITraderWarehouseGridItem> {
    return new Promise((resolve, reject) => {
      client
        .query<{ batchCutId: number }, ITraderWarehouseGridItem>('traderWarehouseItem', traderWarehouseItemQuery, { batchCutId })
        .then((data) => {
          if (data) {
            resolve(data);
          } else {
            reject();
          }
        })
        .catch(reject);
    });
  }

  unregisterTraderWarehouseItem(batchCutId: number): Promise<ITraderWarehouseGridItem> {
    return new Promise((resolve, reject) => {
      client
        .mutate<{ batchCutId: number }, ITraderWarehouseGridItem>('traderWarehouseItemUnregister', traderWarehouseItemUnregisteredMutation, { batchCutId })
        .then((data) => {
          if (data) {
            resolve(data);
          } else {
            reject();
          }
        })
        .catch(reject);
    });
  }

  createTraderWarehouseItemHistory(data: ITraderWarehouseItemHistory) {
    return new Promise<ITraderWarehouseItemHistory>((resolve, reject) => {
      client
        .mutate<{ data: ITraderWarehouseItemHistory }, ITraderWarehouseItemHistory>(
          'traderWarehouseItemHistory',
          traderWarehouseItemHistoryMutation,
          { data }
        )
        .then((result) => {
          if (result) {
            resolve(result);
          } else {
            reject();
          }
        })
        .catch(reject);
    });
  }
}

export default new WarehouseService();