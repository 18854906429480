export const ANIMALS = 'ANIMALS';
export const ANIMALS_SELECTION = 'ANIMALS_SELECTION';
export const APPLY_FOR_ALL_ANIMALS = 'APPLY_FOR_ALL_ANIMALS';
export const CHOSEN = 'CHOSEN';
export const NO_ANIMAL_OBJECT_SELECTED = 'NO_ANIMAL_OBJECT_SELECTED';
export const NO_REASON_ANIMALS_MSG = 'NO_REASON_ANIMALS_MSG';
export const NO_RETURN_MSG = 'NO_RETURN_MSG';
export const PROCESS_FORWARD_CONFIRM = 'PROCESS_FORWARD_CONFIRM';
export const REASON = 'REASON';
export const UNREGISTER = 'UNREGISTER';
export const UNREGISTER_CHRONOLOGY_MSG = 'UUNREGISTER_SUCCESS_MSG';
export const UNREGISTER_CONFIRM_MSG = 'UNREGISTER_CONFIRM_MSG';
export const UNREGISTER_NEW = 'UNREGISTER_NEW';
export const UNREGISTER_REASON = 'UNREGISTER_REASON';
export const UNREGISTER_SUCCESS_MSG = 'UNREGISTER_SUCCESS_MSG';
export const UNREGISTER_TITLE = 'UNREGISTER_TITLE';

export default {
  UNREGISTER,
  UNREGISTER_TITLE
};