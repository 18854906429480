import React, { useEffect } from 'react';
import {
  GridCellProps,
  GridDataStateChangeEvent
} from '@progress/kendo-react-grid';
import { State } from '@progress/kendo-data-query';
import { FirmType } from '../../slices/data/FirmSliceData';
import {
  getFirmInformation,
  selectFirms,
  updateInformation
} from '../../slices/FirmSlice';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import { EditCommandCell } from '../../components/CustomGridCells/CustomGridCells';
import FirmEditForm from '../../components/FirmEditForm/FirmEditForm';
import FirmGrid from '../../components/FirmGrid/FirmGrid';

const initialDataState: State = {
  sort: [{ field: 'code', dir: 'asc' }],
  take: 10,
  skip: 0
};

const FirmGridContainer = () => {
  const dispatch = useAppDispatch();
  const firms = useAppSelector(selectFirms);

  const [dataState, setDataState] = React.useState<State>(initialDataState);

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataState(event.dataState);
  };

  useEffect(() => {
    const awaitData = async () => {
      await dispatch(getFirmInformation());
    };
    awaitData();
  }, []);

  // Edit form state and functions
  const [openForm, setOpenForm] = React.useState(false);
  const [editItem, setEditItem] = React.useState<FirmType>(null);
  const enterEdit = (item: any) => {
    setOpenForm(true);
    setEditItem(item);
  };

  const MyEditCommandCell = (props: GridCellProps) =>
    (
      <EditCommandCell {...props} enterEdit={enterEdit} />
    );

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const handleEdit = (formData: FirmType) => {
    dispatch(updateInformation(formData));
    setOpenForm(false);
  };

  return (
    <div>
      <FirmGrid
        cell={MyEditCommandCell}
        data={firms}
        dataState={dataState}
        dataStateChange={dataStateChange}
      />
      {openForm && (
        <FirmEditForm
          cancelEdit={handleCancelEdit}
          onSubmit={handleEdit}
          item={editItem}
        />
      )}
    </div>
  );
};

export default FirmGridContainer;
