import React from 'react';
import {
  Form,
  Field,
  FormElement,
} from '@progress/kendo-react-form';
import { useLocalization } from '@progress/kendo-react-intl';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import { StringValidator, NumberValidator } from '../Validators/Validators';
import {
  FormComboBox,
  FormDateTimePicker,
  FormInput,
  FormTextArea
} from '../FormComponents/FormComponents';
import {
  CANCEL, INSPECTION_DATE, SEND, VETERINARY_INSPECTOR
} from '../../languages/languages';
import { InspectedAnimalType, InspectionResultEnum } from '../../slices/data/AnimalSliceData';
import VeterinaryInspectionGrid from '../VeterinaryInspectionGrid/VeterinaryInspectionGrid';

const data: { label: string; value: string }[] = [
  {
    label: 'OK',
    value: InspectionResultEnum.OKAY
  },
  {
    label: 'Задръж',
    value: InspectionResultEnum.HOLD
  },
  {
    label: 'Бан',
    value: InspectionResultEnum.BAN
  }
];

type VeterinaryInspectionFormProp = {
  setInspectionData: React.Dispatch<React.SetStateAction<{}>>;
};

const VeterinaryInspectionForm = ({
  setInspectionData
}: VeterinaryInspectionFormProp) => {
  const setData = (formData: InspectedAnimalType) => {
    setInspectionData(formData);
  };

  const localizationService = useLocalization();
  return (
    <div className='sell-details-form'>
      <Form
        onSubmit={setData}
        render={(formRenderProps) =>
          (
            <FormElement>
              <fieldset className='k-form-fieldset'>
                <Field
                  name='inspectionDate'
                  component={FormDateTimePicker}
                  label={localizationService.toLanguageString(INSPECTION_DATE, '')}
                  validator={NumberValidator}
                />
                <Field
                  name='inspectorName'
                  component={FormInput}
                  label={localizationService.toLanguageString(VETERINARY_INSPECTOR, '')}
                  validator={StringValidator}
                  placeholder=''
                />
                <Field
                  name='inspectionResult'
                  component={FormComboBox}
                  data={data}
                  textField='label'
                  onChange={({ value }: ComboBoxChangeEvent) => {
                    if (value === null) {
                      return;
                    }

                    formRenderProps.onChange('inspectionResult', {
                      value: value.value
                    });
                  }}
                  label='Резултат от инспекцията'
                  validator={StringValidator}
                />
              </fieldset>
              <fieldset className='k-form-fieldset'>
                <Field
                  name='inspectionNote'
                  component={FormTextArea}
                  label='Описание на следствието от инспекцията'
                  validator={StringValidator}
                  placeholder=''
                />
              </fieldset>
              <VeterinaryInspectionGrid />
              <div className='k-columnmenu-actions'>
                <Button
                  name='VeterinaryInspectionSend'
                  type='submit'
                  primary
                  icon='hyperlink-open-sm'
                  title={localizationService.toLanguageString(SEND, '')}
                  disabled={!formRenderProps.allowSubmit}
                >
                  {localizationService.toLanguageString(SEND, '')}
                </Button>
                <Button
                  name='VeterinaryInspectionCancel'
                  type='submit'
                  primary
                  look='outline'
                  icon='cancel'
                  title={localizationService.toLanguageString(CANCEL, '')}
                >
                  {localizationService.toLanguageString(CANCEL, '')}
                </Button>
              </div>
            </FormElement>
          )}
      />
    </div>
  );
};

export default VeterinaryInspectionForm;
