import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { useLocalization } from '@progress/kendo-react-intl';
import React from 'react';

import { DECLINE, UPDATE } from '../../languages/languages';
import { IBatchAnimal } from '../../slices/data/ButcheryProcessSliceData';
import { FormNumericTextBox, FormSwitch } from '../FormComponents/FormComponents';

type AnimaWeightEditModalProps = {
  item: IBatchAnimal;
  cancelEdit: () => void;
  onSubmit: (formData: IBatchAnimal) => void;
};
const AnimaWeightEditModal = ({ item, cancelEdit, onSubmit }: AnimaWeightEditModalProps) => {
  const localizationService = useLocalization();
  const title = `Редакция на "${item.lID}"`;
  const weightValidator = (val: number) => {
    if (Number.isNaN(val) || val < 0 || val > 2000) {
      return 'Въведете валидно тегло';
    }

    return '';
  };

  return (
    <Dialog title={title} onClose={cancelEdit}>
      <Form
        onSubmit={onSubmit}
        initialValues={item}
        render={(formRenderProps) => (
          <FormElement id='registrationForm'>
            <div className='row'>
              <div className='col-12 pl-l pr-l'>
                <Field
                  id='weight'
                  name='weight'
                  component={FormNumericTextBox}
                  label='Живо тегло'
                  value={item.weight}
                  validator={weightValidator}
                />
              </div>
              <div className='col-12 pl-l pr-l'>
                <Field
                  id='processingAsService'
                  name='processingAsService'
                  component={FormSwitch}
                  label='Ишлеме'
                  value={item.processingAsService}
                />
              </div>
              <div className='col-12 pl-l pr-l'>
                <Field
                  id='isWaste'
                  name='isWaste'
                  component={FormSwitch}
                  label='Брак'
                  value={item.isWaste}
                />
              </div>
            </div>
            <fieldset className='k-form-buttons'>
              <Button
                name='FirmAdd'
                type='submit'
                title={localizationService.toLanguageString(UPDATE, '')}
                primary
                disabled={!formRenderProps.allowSubmit}
                icon='save'
              >
                {localizationService.toLanguageString(UPDATE, '')}
              </Button>
              <Button
                name='FirmCancel'
                type='submit'
                title={localizationService.toLanguageString(DECLINE, '')}
                primary
                look='outline'
                onClick={cancelEdit}
                icon='cancel'
              >
                {localizationService.toLanguageString(DECLINE, '')}
              </Button>
            </fieldset>
          </FormElement>
        )}
      />
    </Dialog>
  );
};

export default AnimaWeightEditModal;
