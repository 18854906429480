import { process, State } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { getter } from '@progress/kendo-react-common';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { GridColumn as Column, Grid, GridCellProps, GridDataStateChangeEvent, GridDetailRowProps, GridExpandChangeEvent, GridHeaderSelectionChangeEvent, GridSelectionChangeEvent, GridToolbar } from '@progress/kendo-react-grid';
import { GridSelectableSettings } from '@progress/kendo-react-grid/dist/npm/interfaces/GridSelectableSettings';
import { useLocalization } from '@progress/kendo-react-intl';
import calculateSize from 'calculate-size';
import React, { useEffect, useState } from 'react';

import { useAppSelector } from '../../app/StoreHooks';
import GridSearchBar from '../../features/GridSearchBar/GridSearchBar';
import { COLUMNS, EXPORT } from '../../languages/languages';
import { selectAnimalsLoading } from '../../slices/AnimalSlice';
import { ColumnType } from '../../slices/data/AnimalColumnsSliceData';
import ColumnMenu from '../ColumnMenu/ColumnMenu';
import { GridAnimalSexCell, GridTrueCheckboxCell } from '../CustomGridCells/CustomGridCells';
import GridLoaderPanel from '../GridLoaderPanel/GridLoaderPanel';

type SelectableGridPops = {
  data: any[];
  dataState: State;
  selectedState: { [id: string]: boolean | number[] };
  dataStateChange: (e: GridDataStateChangeEvent) => void;
  onSelectionChange: ({ dataItem, syntheticEvent }: GridSelectionChangeEvent) => void;
  onHeaderSelectionChange: (event: GridHeaderSelectionChangeEvent) => void;
  setDataState: React.Dispatch<React.SetStateAction<State>>;
  handleMenuVisibility: () => void;
  stateColumns: ColumnType[];
  DATA_ITEM_KEY: string;
  showToolbar?: boolean;
  showColumns?: boolean;
  actions?: () => JSX.Element;
  cell?: ({ dataItem }: GridCellProps) => JSX.Element;
  actionCell?: ({ dataItem }: GridCellProps) => JSX.Element;
  detail?: (props: GridDetailRowProps) => JSX.Element;
  selectable?: boolean;
  multiselect?: boolean;
  showSearch?: boolean;
};

const SelectableGrid = ({
  actions,
  data,
  dataState,
  selectedState,
  dataStateChange,
  onSelectionChange,
  onHeaderSelectionChange,
  setDataState,
  handleMenuVisibility,
  stateColumns,
  cell,
  showToolbar = false,
  showColumns = true,
  DATA_ITEM_KEY,
  actionCell,
  detail = undefined,
  selectable,
  multiselect,
  showSearch,
}: SelectableGridPops) => {
  const localizationService = useLocalization();
  const loadingState = useAppSelector(selectAnimalsLoading);
  const SELECTED_FIELD = selectable ? 'selected' : undefined;
  const idGetter = getter(DATA_ITEM_KEY);
  const [items, setItems] = useState<any[]>([]);
  const exportRef = React.useRef<ExcelExport | null>(null);
  const gridRef = React.useRef<any>();

  useEffect(() => {
    console.log('data changed');

    if (detail) {
      setItems(data.map((item) => ({ ...item, expanded: item.expanded || false })));
    } else {
      setItems([...data]);
    }
  }, [data]);

  const selectableSettings: GridSelectableSettings = selectable
    ? {
        enabled: true,
        drag: false,
        cell: false,
        mode: multiselect ? 'multiple' : 'single',
      }
    : undefined;

  const expandChange = (event: GridExpandChangeEvent) => {
    const isExpanded =
      event.dataItem.expanded === undefined ? event.dataItem.aggregates : event.dataItem.expanded;
    items[event.dataIndex].expanded = !isExpanded;

    setItems([...items]);
  };

  const calculateWidth = (field) => {
    let maxWidth = 0;
    items.forEach((item) => {
      const size = calculateSize(item[field], {
        font: 'Arial',
        fontSize: '16px',
      }); // pass the font properties based on the application
      if (size.width > maxWidth) {
        maxWidth = size.width;
      }
    });
    return maxWidth;
  };

  const excelExport = () => {
    if (exportRef.current !== null) {
      // pass the products, instead the paginated data in the state.
      exportRef.current.save(items, gridRef.current.columns);
    }
  };

  return (
    <>
      <ExcelExport ref={exportRef} />
      <Grid
        sortable
        pageable
        {...dataState}
        data={process(
          items.map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
          })),
          dataState
        )}
        onDataStateChange={dataStateChange}
        dataItemKey={DATA_ITEM_KEY}
        selectedField={SELECTED_FIELD}
        selectable={selectableSettings}
        onSelectionChange={onSelectionChange}
        onHeaderSelectionChange={onHeaderSelectionChange}
        detail={detail}
        expandField='expanded'
        onExpandChange={expandChange}
        resizable
        ref={gridRef}
      >
        {(showColumns || showSearch || showToolbar) && (
          <GridToolbar>
            <div className='toolbar-section'>
              {showSearch && (
                <GridSearchBar dataState={dataState} filterBy='lID' setDataState={setDataState} />
              )}
              {showColumns && (
                <Button
                  name='AnimalTableColumns'
                  type='button'
                  title={localizationService.toLanguageString(COLUMNS, '')}
                  primary
                  onClick={handleMenuVisibility}
                  icon='align-justify'
                  className='ml-s'
                >
                  {localizationService.toLanguageString(COLUMNS, '')}
                </Button>
              )}
            </div>
            {showToolbar && (
              <div className='toolbar-section ml-auto'>
                {actions()}
                <Button
                  name='ATCattleGridExportBtn'
                  type='button'
                  title={localizationService.toLanguageString(EXPORT, '')}
                  icon='download'
                  primary
                  look='outline'
                  className='ml-s'
                  onClick={excelExport}
                >
                  {localizationService.toLanguageString(EXPORT, '')}
                </Button>
              </div>
            )}
          </GridToolbar>
        )}
        {selectable && (
          <Column
            field={SELECTED_FIELD}
            headerSelectionValue={data.findIndex((item) => !selectedState[idGetter(item)]) === -1}
            headerClassName='text-center'
          />
        )}
        {actionCell && (
          <Column width={80} cell={actionCell} />
        )}
        {stateColumns.map((column) => {
          if (
            column.show &&
            ['breed', 'sheepBreed', 'cattleBreed'].some((val) => val === column.field)
          ) {
            return (
              <Column
                key={column.field}
                field={column.field}
                title={column.title}
                filter={column.filter}
                format={column.format}
                cell={cell}
                columnMenu={(props) => <ColumnMenu {...props} />}
              />
            );
          }
          // if (column.show && column.filter === 'date') {
          //   return (
          //     <Column
          //       key={column.field}
          //       field={column.field}
          //       title={column.title}
          //       filter={column.filter}
          //       format={column.format}
          //       cell={DateOfBirthCell}
          //       columnMenu={(props) => <ColumnMenu {...props} />}
          //     />
          //   );
          // }
          if (column.show && (column.field === 'sex' || column.field === 'animalSex')) {
            return (
              <Column
                key={column.field}
                field={column.field}
                title={column.title}
                filter={column.filter}
                format={column.format}
                cell={GridAnimalSexCell}
                columnMenu={(props) => <ColumnMenu {...props} />}
                width={calculateWidth(column.field)}
              />
            );
          }
          if (column.show && column.filter === 'boolean') {
            return (
              <Column
                key={column.field}
                field={column.field}
                title={column.title}
                filter={column.filter}
                format={column.format}
                cell={GridTrueCheckboxCell}
                columnMenu={(props) => <ColumnMenu {...props} />}
              />
            );
          }
          if (column.show) {
            return (
              <Column
                key={column.field}
                field={column.field}
                title={column.title}
                filter={column.filter}
                format={column.format}
                width={column.width}
                columnMenu={(props) => <ColumnMenu {...props} />}
              />
            );
          }
          return null;
        })}
      </Grid>
      {loadingState && <GridLoaderPanel />}
    </>
  );
};

SelectableGrid.defaultProps = {
  actions: undefined,
  actionCell: undefined,
  cell: undefined,
  detail: undefined,
  selectable: true,
  multiselect: true,
  showSearch: true,
  showToolbar: true,
  showColumns: true,
};

export default SelectableGrid;
