import React, { Dispatch, useState } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { ComboBox, ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { useLocalization } from '@progress/kendo-react-intl';
import {
  SELL_OPERATION, SELECT_OPERATION, VETERINARY_INSPECTION, KILL_DATA_OPERATION, ANIMAL_OPERATIONS
} from '../../languages/languages';
import SellAnimals from '../../components/SellAnimals/SellAnimals';
import KillDataContainer from '../KillData/KillDataContainer';
import SellPreview from '../SellPreview/SellPreview';
import VeterinaryInspectionContainer from '../VeterinaryInspectionContainer/VeterinaryInspectionContainer';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import { selectSelectedAnimals, setButcheredAnimals } from '../../slices/AnimalSlice';

const operations: { value: string, type: string }[] = [
  { value: SELL_OPERATION, type: 'farmer' },
  { value: VETERINARY_INSPECTION, type: 'processor' },
  { value: KILL_DATA_OPERATION, type: 'processor' }
];

type AnimalOperationsProps = {
  setVisibility: Dispatch<React.SetStateAction<boolean>>;
  holdingType: 'farmer' | 'processor';
};

const AnimalOperations = ({
  setVisibility, holdingType
}: AnimalOperationsProps) => {
  // Redux block
  const dispatch = useAppDispatch();
  const selectedAnimals = useAppSelector(selectSelectedAnimals);
  // End redux block
  // Local State
  const [operation, setOperation] = useState<string>('');

  const localizationService = useLocalization();

  const availableOperations = operations.filter((oper) =>
    (
      oper.type === holdingType
    )).map((opr) =>
    ({
      value: localizationService.toLanguageString(opr.value, ''),
      type: opr.type
    }));

  //   Handle ComboBox change
  const selectOperation = ({ value }: ComboBoxChangeEvent) => {
    setOperation(value.value);
    if (value === localizationService.toLanguageString(KILL_DATA_OPERATION, '')) {
      dispatch(setButcheredAnimals(selectedAnimals));
    }
  };

  const handleClose = () => {
    setVisibility(false);
  };

  // Configure with local state to hide the multi step form and
  // show the SellPreview component with the already filled content
  const [preview, setPreview] = useState<boolean>(false);
  const showPreview = () => {
    setPreview(true);
    setOperation('');
  };

  const renderElement = () => {
    switch (operation) {
      case localizationService.toLanguageString(SELL_OPERATION, ''):
        return <SellAnimals setVisibility={showPreview} />;
      case localizationService.toLanguageString(KILL_DATA_OPERATION, ''):
        return <KillDataContainer close={handleClose} />;
      case localizationService.toLanguageString(VETERINARY_INSPECTION, ''):
        return <VeterinaryInspectionContainer close={handleClose} />;
      default:
        return null;
    }
  };

  return (
    <>
      <Dialog title={localizationService.toLanguageString(ANIMAL_OPERATIONS, '')} onClose={handleClose} id='entryAnimalTableDialog'>
        <form onReset={handleClose} className='mb-xl'>
          <label htmlFor='AnimalOperationsOption'>
            {localizationService.toLanguageString(SELECT_OPERATION, '')}
            :
          </label>
          <ComboBox
            name='AnimalOperationsOption'
            data={availableOperations}
            onChange={selectOperation}
            textField='value'
            placeholder={localizationService.toLanguageString(
              SELECT_OPERATION,
              ''
            )}
            className='ml-s'
          />
        </form>
        <div className='selected-operations-container'>
          {renderElement()}
        </div>
        {preview && <SellPreview close={handleClose} />}
      </Dialog>
    </>
  );
};

export default AnimalOperations;
