import { Button } from '@progress/kendo-react-buttons';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Loader } from '@progress/kendo-react-indicators';
import { useLocalization } from '@progress/kendo-react-intl';
import { format } from '@telerik/kendo-intl';
import React, { useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import { STEP_FROM } from '../../languages/keys/keys';
import { BACKWORD, CONFIRM } from '../../languages/languages';
import { UNREGISTER, UNREGISTER_CONFIRM_MSG, UNREGISTER_REASON, UNREGISTER_TITLE } from '../../languages/keys/movements.keys';
import { selectCattleColumns } from '../../slices/AnimalColumnsSlice';
import { UnregisterAnimal, UnregisterInputData } from '../../slices/data/MovementSliceData';
import { unregister } from '../../slices/MovementSlice';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import { DateOfBirthCell, GridAnimalSexCell } from '../CustomGridCells/CustomGridCells';

const columsFields = ['lID', 'dateOfBirth', 'sex', 'cattleBreed'];

const UnregisterConfirmation = ({
  animals,
  animalObjectId,
  onNext,
  onBack,
}: {
  animals: UnregisterAnimal[];
  animalObjectId: number;
  onNext: (animals: UnregisterAnimal[]) => void;
  onBack: () => void;
}) => {
  const dispatch = useAppDispatch();
  const [items] = useState([...animals] as UnregisterAnimal[]);
  const localizationService = useLocalization();
  const [showConfirm, setShowConfirm] = useState(false);
  const cattleColumns = useAppSelector(selectCattleColumns).filter((x) =>
    columsFields.some((f) => x.field === f)
  );
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = () => {
    setIsLoading(true);
    const data = {
      animalObjectId,
      unregisterEventAnimals: items.map((x) => ({ animalId: x.animalId, reasonId: x.reason.id })),
    } as UnregisterInputData;

    dispatch(unregister(data)).then((response) => {
      if (response.payload) {
        onNext(items);
      }
    });
  };

  const onConfirm = (confirm: boolean) => {
    if (confirm) {
      onSubmit();
    }

    setShowConfirm(false);
  };

  const gridCellByField = (cellField: string) => {
    if (cellField === 'dateOfBirth') {
      return DateOfBirthCell;
    }

    if (cellField === 'sex') {
      return GridAnimalSexCell;
    }

    return null;
  };

  return (
    <div>
      <h2>{localizationService.toLanguageString(UNREGISTER_TITLE, '')}</h2>
      <div>
        {!isLoading && (
          <Grid pageable sortable data={items}>
            {cattleColumns.map((column) => (
              <GridColumn
                key={column.field}
                field={column.field}
                title={column.title}
                filter={column.filter}
                format={column.format}
                cell={gridCellByField(column.field)}
              />
            ))}
            <GridColumn
              key='reason'
              field='reason.text'
              title={localizationService.toLanguageString(UNREGISTER_REASON, '')}
            />
          </Grid>
        )}
        {isLoading && <Loader size='large' type='pulsing' className='k-centered' />}
      </div>
      <hr />
      <div className='d-flex justify-content-center mb-l align-items-center'>
        <div className='mr-l' style={{ height: 'fit-content' }}>
          {format(localizationService.toLanguageString(STEP_FROM, ''), [3, 3])}
        </div>
        <Button
          name='OwnershipDataAdd'
          type='button'
          title={localizationService.toLanguageString(BACKWORD, '')}
          primary
          iconClass='fa fa-reply'
          className='mr-l'
          onClick={onBack}
          disabled={isLoading}
        >
          {localizationService.toLanguageString(BACKWORD, '')}
        </Button>
        <Button
          name='OwnershipDataAdd'
          type='button'
          title={localizationService.toLanguageString(UNREGISTER, '')}
          primary
          iconClass='fa fa-save'
          onClick={() => setShowConfirm(true)}
          disabled={isLoading}
        >
          {localizationService.toLanguageString(UNREGISTER, '')}
        </Button>
      </div>
      <hr />
      {showConfirm && (
        <ConfirmDialog
          title={localizationService.toLanguageString(CONFIRM, '')}
          message={format(localizationService.toLanguageString(UNREGISTER_CONFIRM_MSG, ''), [items.length])}
          onResult={onConfirm}
        />
      )}
    </div>
  );
};

export default UnregisterConfirmation;
