import { graphql } from 'msw';
import AUTH_CONFIG from '../../auth-variables';

const blbTrace = graphql.link(AUTH_CONFIG.apiUrl);

const shoppingCenters = [
  {
    shoppingCenterId: 1,
    shoppingCenterName: 'Test2',
    shoppingCenterNumber: 1293612783,
    shoppingCenterType: 'Малък магазин',
    firmId: 1,
    shoppingCenterAddress: 'тестова улица 2',
    isActive: true
  }
];

export default [
  blbTrace.query('getAllShoppingCentersQuery', (req, res, ctx) =>
    res(
      ctx.delay(3000),
      ctx.data({
        getShoppingCenters: {
          shoppingCenters
        }
      })
    )),
  blbTrace.mutation('editShoppingCenterMutation', (req, res, ctx) => {
    const { id, input } = req.variables;
    const editObj = shoppingCenters.findIndex((shoppingCenter) =>
      shoppingCenter.shoppingCenterId === id);
    shoppingCenters[editObj] = input;
    return res(
      ctx.delay(3000),
      ctx.data({
        editInformation: {
          input
        }
      })
    );
  }),
  blbTrace.mutation('saveShoppingCenterMutation', (req, res, ctx) => {
    const { input } = req.variables;
    input.isActive = true;
    input.shoppingCenterId = shoppingCenters.length + 1;
    shoppingCenters.push(input);
    return res(
      ctx.delay(3000),
      ctx.data({
        saveInformation: {
          input
        }
      })
    );
  })
];
