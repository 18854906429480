import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import {
  Field, Form, FormElement, FormRenderProps
} from '@progress/kendo-react-form';
import { useLocalization } from '@progress/kendo-react-intl';
import { useAppSelector } from '../../app/StoreHooks';
import {
  SAVE,
  CANCEL,
  COW_LID,
  FERTILIZATION_END,
  FERTILIZATION_START,
  FERTILIZATION_TYPE,
  PREGNANCY_STAGE,
  PREGNANCY_TEST_DATE,
  SIRE_LID,
  TEST_RESULT,
} from '../../languages/languages';
import { selectAllAnimals } from '../../slices/AnimalSlice';
import { AnimalTypeEnum, AnimalSexEnum } from '../../slices/data/AnimalSliceData';
import {
  ArtificialInsemenationType,
  fertilizationVariants,
  pregnancyChecks
} from '../../slices/data/ArtificialInseminationSliceData';
import {
  FormComboBox,
  FormDatePicker,
  FormInput,
  FormNumericTextBox
} from '../FormComponents/FormComponents';

type ArtificialInseminationFormProps = {
  item?: ArtificialInsemenationType;
  submit: (formData: ArtificialInsemenationType) => void;
  close: () => void;
};

const ArtificialInseminationForm = ({
  item,
  submit,
  close
}: ArtificialInseminationFormProps) => {
  const localizationService = useLocalization();

  const animals = useAppSelector(selectAllAnimals).filter((animal) =>
    (
      animal.animalType === AnimalTypeEnum.CATTLE && animal.sex === AnimalSexEnum.FEMALE
    ));

  const fertalizationOptions = fertilizationVariants.map((option) =>
    ({
      value: option.value,
      label: localizationService.toLanguageString(option.label, '')
    }));

  const pregnancyTypes = pregnancyChecks.map((check) =>
    ({
      value: check.value,
      label: localizationService.toLanguageString(check.label, '')
    }));

  const selectedFertilizationType = fertalizationOptions.find((option) =>
    option.value === item?.fertilization);

  const selectedAnimal = animals.find((animal) =>
    animal.animalId === item?.animal?.animalId);

  const selectedTestResult = pregnancyTypes.find((type) =>
    type.value === item?.pregnancyCheck);

  return (
    <Form
      initialValues={item}
      onSubmit={submit}
      render={(formRenderProps: FormRenderProps) =>
        (
          <FormElement>
            <Field
              name='animalId'
              label={localizationService.toLanguageString(COW_LID, '')}
              component={FormComboBox}
              data={animals}
              textField='lID'
              defaultValue={selectedAnimal}
              onChange={({ value }: ComboBoxChangeEvent) => {
                if (value === null) {
                  return;
                }
                formRenderProps.onChange('animalId', { value: value.animalId });
              }}
            />
            <Field
              name='fertilization'
              label={localizationService.toLanguageString(FERTILIZATION_TYPE, '')}
              component={FormComboBox}
              data={fertalizationOptions}
              textField='label'
              defaultValue={selectedFertilizationType}
              onChange={({ value }: ComboBoxChangeEvent) => {
                if (value === null) {
                  return;
                }
                formRenderProps.onChange('fertilization', { value: value.value });
              }}
            />
            <Field
              name='sireLID'
              label={localizationService.toLanguageString(SIRE_LID, '')}
              component={FormInput}
            />
            <Field
              name='fertilizationStart'
              format='dd-MMM-yyyy'
              label={localizationService.toLanguageString(
                FERTILIZATION_START,
                ''
              )}
              component={FormDatePicker}
            />
            <Field
              name='fertilizationEnd'
              format='dd-MMM-yyyy'
              label={localizationService.toLanguageString(FERTILIZATION_END, '')}
              component={FormDatePicker}
            />
            <Field
              name='pregnancyCheckDate'
              format='dd-MMM-yyyy'
              label={localizationService.toLanguageString(PREGNANCY_TEST_DATE, '')}
              component={FormDatePicker}
            />
            <Field
              name='pregnancyCheck'
              label={localizationService.toLanguageString(TEST_RESULT, '')}
              component={FormComboBox}
              data={pregnancyTypes}
              defaultValue={selectedTestResult}
              textField='label'
              onChange={({ value }: ComboBoxChangeEvent) => {
                if (value === null) {
                  return;
                }
                formRenderProps.onChange('pregnancyCheck', { value: value.value });
              }}
            />
            <Field
              name='pregnancyState'
              label={localizationService.toLanguageString(PREGNANCY_STAGE, '')}
              component={FormNumericTextBox}
              format='0'
            />
            <div className='k-form-buttons'>
              <Button
                name='ArtificialInseminationSave'
                type='submit'
                title={localizationService.toLanguageString(SAVE, '')}
                primary
                disabled={!formRenderProps.allowSubmit}
                icon='save'
              >
                {localizationService.toLanguageString(SAVE, '')}
              </Button>
              <Button
                name='ArtificialInseminationCancel'
                type='submit'
                title={localizationService.toLanguageString(CANCEL, '')}
                primary
                look='outline'
                icon='cancel'
                onClick={close}
              >
                {localizationService.toLanguageString(CANCEL, '')}
              </Button>
            </div>
          </FormElement>
        )}
    />
  );
};

ArtificialInseminationForm.defaultProps = {
  item: {}
};

export default ArtificialInseminationForm;
