import React, { useEffect, useState } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { useLocalization } from '@progress/kendo-react-intl';
import { MultiSelectChangeEvent } from '@progress/kendo-react-dropdowns';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import AmbulatoryLogForm from '../../components/AmbulatoryLogForm/AmbulatoryLogForm';
import AmbulatoryLogGrid from '../../components/AmbulatoryLogGrid/AmbulatoryLogGrid';
import { ADD_NEW, EDIT } from '../../languages/languages';
import {
  editAmbulatoryLog,
  getAllAmbulatoryLogs,
  saveAmbulatoryLog,
  selectAllAmbulatoryLogs
} from '../../slices/AmbulatoryLogSlice';
import { getAnimalsInFarm } from '../../slices/AnimalSlice';
import {
  AmbulatoryInputType,
  AmbulatoryLogMedicineType,
  AmbulatoryLogType
} from '../../slices/data/AmbulatoryLogSliceData';
import { getPurchasedMedicines } from '../../slices/PurchasedMedicinesSlice';
import GrantAccess from '../GrantAccess/GrantAccess';

const REQUIRED_ROLES: string[] = [
  'Admin',
  'Holding Owner',
  'Farm Manager',
  'Farm Veterinary Inspector'
];

const AmbulatoryLogContainer = () => {
  const localizationService = useLocalization();
  const dispatch = useAppDispatch();
  const ambulatoryLogs = useAppSelector(selectAllAmbulatoryLogs);

  useEffect(() => {
    const loadData = async () => {
      dispatch(getAllAmbulatoryLogs());
    };
    loadData();
  }, []);

  const [selectedMedicines, setSelectedMedicines] = useState<
  AmbulatoryLogMedicineType[]
  >([]);

  const handleMedecineSelection = ({ target }: MultiSelectChangeEvent) => {
    setSelectedMedicines(target.value);
  };

  const [addEntryView, setAddEntryView] = useState(false);

  const openAddEntryView = () => {
    setAddEntryView(true);
    dispatch(getAnimalsInFarm());
    dispatch(getPurchasedMedicines());
  };

  const closeAddEntryView = () => {
    setAddEntryView(false);
  };

  const submitNewEntry = (formData: AmbulatoryInputType) => {
    const finalMedicines = selectedMedicines.map((medicine) =>
      ({
        medicineId: medicine.medicineId
      }));
    const inputData: AmbulatoryInputType = {
      abulatoryMedcine: finalMedicines,
      ambulatoryDate: formData?.ambulatoryDate,
      ambulatoryNumber: formData?.ambulatoryNumber,
      clinicalData: formData?.clinicalData,
      comment: formData?.comment,
      diagnosis: formData?.diagnosis,
      diagnosisOut: formData?.diagnosisOut,
      diagnosticTest: formData?.diagnosticTest,
      dose: formData?.dose,
      withdrawalPeriod: formData?.withdrawalPeriod,
      animalId: formData?.animalId
    };
    dispatch(saveAmbulatoryLog(inputData));
    closeAddEntryView();
    setSelectedMedicines([]);
  };

  const [editEntryView, setEditEntryView] = useState(false);
  const [editItem, setEditItem] = useState<AmbulatoryLogType>(null);
  const openEditEntryView = (item: AmbulatoryLogType) => {
    setEditEntryView(true);
    setEditItem(item);
    dispatch(getAnimalsInFarm());
    dispatch(getPurchasedMedicines());
    setSelectedMedicines(item.medicine);
  };

  const closeEditEntryView = () => {
    setEditEntryView(false);
  };

  const submitEditEntry = (formData: AmbulatoryLogType) => {
    const finalMedicines = selectedMedicines.map((medicine) =>
      ({
        medicineId: medicine.medicineId,
        ambulatoryDiaryId: formData?.ambulatoryDiaryId
      }));
    const inputData: AmbulatoryInputType = {
      ambulatoryDiaryId: formData?.ambulatoryDiaryId,
      abulatoryMedcine: finalMedicines,
      ambulatoryDate: formData?.ambulatoryDate,
      ambulatoryNumber: formData?.ambulatoryNumber,
      clinicalData: formData?.clinicalData,
      comment: formData?.comment,
      diagnosis: formData?.diagnosis,
      diagnosisOut: formData?.diagnosisOut,
      diagnosticTest: formData?.diagnosticTest,
      dose: formData?.dose,
      withdrawalPeriod: formData?.withdrawalPeriod,
      animalId: formData?.animalId
    };

    dispatch(editAmbulatoryLog(inputData));
    closeEditEntryView();
    setSelectedMedicines([]);
  };

  return (
    <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
      <AmbulatoryLogGrid
        data={ambulatoryLogs}
        addNew={openAddEntryView}
        editEntry={openEditEntryView}
      />
      {addEntryView && (
        <Dialog
          title={localizationService.toLanguageString(ADD_NEW, '')}
          onClose={closeAddEntryView}
        >
          <AmbulatoryLogForm
            selectedMedicines={selectedMedicines}
            setSelectedMedicines={handleMedecineSelection}
            submit={submitNewEntry}
            close={closeAddEntryView}
          />
        </Dialog>
      )}
      {editEntryView && (
        <Dialog
          title={localizationService.toLanguageString(EDIT, '')}
          onClose={closeEditEntryView}
        >
          <AmbulatoryLogForm
            item={editItem}
            selectedMedicines={selectedMedicines}
            setSelectedMedicines={handleMedecineSelection}
            submit={submitEditEntry}
            close={closeEditEntryView}
          />
        </Dialog>
      )}
    </GrantAccess>
  );
};

export default AmbulatoryLogContainer;
