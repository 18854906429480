import React from 'react';
import { DateTimePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import { selectSingleDeal, setDealData } from '../../slices/DealsSlice';
import DealInformationForm from '../../components/DealInformationForm/DealInformationForm';

const DealInformationFormContainer = () => {
  const dispatch = useAppDispatch();
  const deal = useAppSelector(selectSingleDeal);

  const handleDateUpdate = ({ value }: DateTimePickerChangeEvent) => {
    dispatch(setDealData(value));
  };

  return (
    <DealInformationForm data={deal} handleDateUpdate={handleDateUpdate} />
  );
};

export default DealInformationFormContainer;
