import { Button } from '@progress/kendo-react-buttons';
import { GridCellProps } from '@progress/kendo-react-grid';
import { Loader } from '@progress/kendo-react-indicators';
import { useLocalization } from '@progress/kendo-react-intl';
import { Card, CardBody } from '@progress/kendo-react-layout';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';

import PhTModal from '../../components/ButcheryProcess/PhTModal';
import ConfirmDialog from '../../components/ConfirmDialog/ConfirmDialog';
import NonSelectableGrid from '../../components/SelectableGrid/NonSelectableGrid';
import { IdParam } from '../../interfaces/request';
import { CONFIRM } from '../../languages/languages';
import batchService from '../../services/batch.service';
import { ColumnType } from '../../slices/data/AnimalColumnsSliceData';
import { IBatch, IBatchAnimal, IPhT } from '../../slices/data/ButcheryProcessSliceData';

const gridColumns: ColumnType[] = [
  {
    title: 'Труп вътрешен №',
    field: 'carcaseInternalNumber',
    show: true,
    filter: 'text',
  },
  {
    title: 'Животно №',
    field: 'animal.lID',
    show: true,
    filter: 'text',
  },
  {
    title: 'Пол',
    field: 'sex',
    show: true,
    filter: 'text',
  },
  {
    title: 'Дата вход в склад',
    field: 'firstPhT.date',
    show: true,
    filter: 'date',
    format: '{0:dd.MM.yyy HH:mm}',
  },
  {
    title: 'Начална t (℃)',
    field: 'firstPhT.temp',
    show: true,
    filter: 'numeric',
  },
  {
    title: 'Начално pH',
    field: 'firstPhT.ph',
    show: true,
    filter: 'numeric',
  },
  {
    title: 'Дата на второ измерване',
    field: 'secondPhT.date',
    show: true,
    filter: 'date',
    format: '{0:dd.MM.yyy HH:mm}',
  },
  {
    title: 'Вторична t (℃)',
    field: 'secondPhT.temp',
    show: true,
    filter: 'numeric',
  },
  {
    title: 'Вторично pH',
    field: 'secondPhT.ph',
    show: true,
    filter: 'numeric',
  },
] as ColumnType[];

const ButcherySecondarytempMeasurement = () => {
  const $t = useLocalization();
  const { id } = useParams<IdParam>();
  const [data, setData] = useState<IBatch>();
  const [loading, setLoading] = useState(true);
  const [showSuccess, setShowSuccess] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [editItem, seteditItem] = useState<IBatchAnimal>(null);
  const [showConfirm, setShowConfirm] = useState(false);

  useEffect(() => {
    const batchId = parseInt(id, 10);
    batchService
      .getBatch(batchId)
      .then((batch) => {
        if (batch) {
          batch.animals.forEach((animal) => {
            // FIXME fix grid column, not the data
            animal.firstPhT.date = new Date(animal.firstPhT.date);
          });

          setData(batch);
        }
      })
      .finally(() => setLoading(false));
  }, []);

  const isSaveDisabled = () => {
    let invalid = false;
    invalid = data.animals.some(
      (a) => !a.secondPhT || !a.secondPhT.date || !a.secondPhT.ph || !a.secondPhT.temp
    );
    return invalid;
  };

  const onConfirm = (result: boolean) => {
    if (result) {
      setLoading(true);
      const secondMeasures = {
        batchId: data.id,
        phTMeasures: data.animals.map((batchAnimal) => ({
          ...batchAnimal.secondPhT,
          batchAnimalId: batchAnimal.animalId,
        })),
      };

      batchService
        .addSecondTempMeasure(secondMeasures)
        .then(() => setShowSuccess(true))
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    }

    setShowConfirm(false);
  };

  const onShowEdit = (item: IBatchAnimal) => {
    if (!item.secondPhT) {
      item.secondPhT = {} as IPhT;
    }

    seteditItem(item);
    setShowEdit(true);
  };

  const cancelEdit = () => {
    seteditItem(null);
    setShowEdit(false);
  };

  const handleEdit = (animalId: number, item: IPhT, applyForAll: boolean) => {
    const index = data.animals.findIndex((i) => i.animalId === animalId);

    if (index !== -1) {
      data.animals[index].secondPhT = { ...item };

      if (applyForAll) {
        data.animals.forEach((i) => {
          if (i.secondPhT) {
            i.firstPhT.date = item.date;
          } else {
            i.secondPhT = { date: item.date } as IPhT;
          }
        });
      }
    }

    cancelEdit();
  };

  const actionCell = ({ dataItem }: GridCellProps) => (
    <td className='text-center'>
      <Button
        data-testid='editButton'
        type='button'
        className='k-button k-secondary mr-l'
        icon='edit'
        look='outline'
        onClick={() => onShowEdit(dataItem)}
      />
    </td>
  );

  return (
    <div>
      <h1 className='page-title'>Вторично измерване на температура и pH</h1>
      {!showSuccess && (
        <div className='d-flex justify-content-center'>
          {!loading && data && (
            <div>
              <NonSelectableGrid
                data={data.animals}
                stateColumns={gridColumns}
                actionCell={actionCell}
              />
              <div className='d-flex justify-content-center mt-l'>
                <Button
                  className='mr-l'
                  primary
                  onClick={() => setShowConfirm(true)}
                  disabled={isSaveDisabled()}
                >
                  запиши и изпрати към склад
                </Button>
                <Link to='/processor/butchery-process' style={{ textDecoration: 'none' }}>
                  <Button>Назад</Button>
                </Link>
              </div>
            </div>
          )}
          {loading && <Loader size='large' type='pulsing' />}
        </div>
      )}
      {showEdit && (
        <PhTModal
          item={editItem}
          onSubmit={handleEdit}
          cancelEdit={cancelEdit}
          isSecondMeasure={true}
          batchNumber={data.number}
        />
      )}

      {showConfirm && (
        <ConfirmDialog
          message={`Сигурни ли сте , че искате да приключите кланичния процес за партида № ${data.number}`}
          title={$t.toLanguageString(CONFIRM, '')}
          onResult={onConfirm}
        />
      )}

      {showSuccess && (
        <div className='row'>
          <div className='col-12 col-md-6 offset-md-3'>
            <Card>
              <CardBody className='d-flex'>
                {/* <CardTitle>Card Title</CardTitle>
                  <CardTitle>Card Subtitle</CardTitle> */}
                <div className='d-flex justify-content-center align-items-center pl-l pr-l text-success'>
                  <i className='far fa-check-circle fa-4x text-success' />
                </div>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                  <h2>Успешно въведохте измерените температури </h2>
                  <p>Разпада от животните в партидата вече е видима в склада</p>
                  <Link to='/processor/butchery-process' style={{ textDecoration: 'none' }}>
                    <Button look='outline'>Към кланичен процес</Button>
                  </Link>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      )}
    </div>
  );
};

export default ButcherySecondarytempMeasurement;
