/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/StoreHooks';
import {
  AddTruckInputType,
  AddTruckType,
  ADD_TRUCK,
  EDIT_TRUCK,
  GetFirmTrucksInputType,
  GetTrucksResponseType,
  GET_ALL_TRUCKS,
  GET_FIRM_TRUCKS,
  TruckType
} from './data/TrucksSliceData';
import {
  createAsyncThunkGeneric,
  createAsyncThunkGenericVoid
} from './GenericThunks';

type TrucksSliceState = {
  trucks: TruckType[];
  trucksLoading: boolean;
};

const initialState: TrucksSliceState = {
  trucks: [],
  trucksLoading: false
};

// Async thunks
export const getAllTrucks = createAsyncThunkGenericVoid(
  'trucks/getAllTrucks',
  async ({ client }) => {
    const response = await client.query<void, GetTrucksResponseType>(
      'trucks',
      GET_ALL_TRUCKS
    );
    return response?.items ? response?.items : [];
  }
);

export const getFirmTrucks = createAsyncThunkGeneric<
number,
GetTrucksResponseType
>('trucks/getFirmTrucks', async ({ client, input }) => {
  const response = await client.query<
  GetFirmTrucksInputType,
  GetTrucksResponseType
  >('trucks', GET_FIRM_TRUCKS, { firmABId: input });
  return response;
});

export const addTruck = createAsyncThunkGeneric<AddTruckType, TruckType>(
  'trucks/addTruck',
  async ({ client, input }) =>
    client.mutate<AddTruckInputType, TruckType>('addTruck', ADD_TRUCK, {
      truckInput: input
    })
);

export const editTruck = createAsyncThunkGeneric<AddTruckType, TruckType>(
  'trucks/editTruck',
  async ({ client, input }) =>
    client.mutate<AddTruckInputType, TruckType>('updateTruck', EDIT_TRUCK, {
      truckInput: input
    })
);

export const TrucksSlice = createSlice({
  name: 'trucks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllTrucks.pending, (state) => {
        state.trucksLoading = true;
      })
      .addCase(getAllTrucks.fulfilled, (state, { payload }) => {
        state.trucksLoading = false;
        state.trucks = payload;
      })
      .addCase(getFirmTrucks.pending, (state) => {
        state.trucksLoading = true;
      })
      .addCase(getFirmTrucks.fulfilled, (state, { payload }) => {
        state.trucksLoading = false;
        state.trucks = payload.items ? payload.items : [];
      })
      .addCase(addTruck.pending, (state) => {
        state.trucksLoading = true;
      })
      .addCase(addTruck.fulfilled, (state, { payload }) => {
        state.trucksLoading = false;
        state.trucks = [...state.trucks, payload];
      })
      .addCase(editTruck.pending, (state) => {
        state.trucksLoading = true;
      })
      .addCase(editTruck.fulfilled, (state, { payload }) => {
        state.trucksLoading = false;
        const updIndex = state.trucks.findIndex((truck) =>
          truck.truckId === payload.truckId);
        state.trucks[updIndex] = payload;
      });
  }
});

// Selectors
export const selectAllTrucks = (state: RootState) =>
  state.trucks.trucks;
export const selectTrucksLoadingState = (state: RootState) =>
  state.trucks.trucksLoading;

// Reducers
export default TrucksSlice.reducer;
