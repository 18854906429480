import { State } from '@progress/kendo-data-query';
import { getter } from '@progress/kendo-react-common';
import { GridDataStateChangeEvent, GridHeaderSelectionChangeEvent } from '@progress/kendo-react-grid';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';

import { ColumnType } from '../../slices/data/AnimalColumnsSliceData';
import { IBatchAnimal } from '../../slices/data/ButcheryProcessSliceData';
import { CattleBreedCell } from '../CustomGridCells/CustomGridCells';
import AnimalTable from '../SelectableGrid/SelectableGrid';

const DATA_ITEM_KEY: string = 'animalId';
const idGetter = getter(DATA_ITEM_KEY);

interface AnimalGridProps {
  animals: IBatchAnimal[];
}

export type RefHandle = {
  reload: () => void;
};

const cattleColumns: ColumnType[] = [
  {
    title: 'Труп №',
    field: 'carcaseInternalNumber',
    show: true,
    filter: 'text',
  } as ColumnType,
  {
    title: 'ВЕТИС №',
    field: 'lID',
    show: true,
    filter: 'text',
  } as ColumnType,
  {
    title: 'Възраст (м)',
    field: 'age',
    show: true,
    filter: 'numeric',
  } as ColumnType,
  {
    title: 'Пол',
    field: 'sex',
    show: true,
    filter: 'text',
  } as ColumnType,
  {
    title: 'Порода',
    field: 'cattleBreed',
    show: true,
    filter: 'text',
  } as ColumnType,
  {
    title: 'Ишлеме',
    field: 'processingAsService',
    show: true,
    filter: 'boolean',
    cell: null,
  } as ColumnType,
  {
    title: 'Живо тегло (кг)',
    field: 'weight',
    show: true,
    filter: 'numeric',
  } as ColumnType,
  {
    title: 'Разпад',
    field: 'carcase.cutsCountName',
    show: true,
    filter: 'text',
  } as ColumnType,
  {
    title: 'Трупно тегло топло (кг)',
    field: 'carcaseWeight',
    show: true,
    filter: 'numeric',
  } as ColumnType,
  {
    title: 'Трупно тегло охладено (кг)',
    field: 'carcaseWeightCold',
    show: true,
    filter: 'numeric',
  } as ColumnType,
  {
    title: 'Начална t (℃)',
    field: 'firstPhT.temp',
    show: true,
    filter: 'numeric',
  } as ColumnType,
  {
    title: 'Начално pH',
    field: 'firstPhT.ph',
    show: true,
    filter: 'numeric',
  } as ColumnType,
] as ColumnType[];

const BatchAnimalsForWarehouseGrid = forwardRef<RefHandle, AnimalGridProps>(
  ({ animals }: AnimalGridProps) => {
    const [items, setItems] = useState<IBatchAnimal[]>(animals);

    useEffect(() => {
      setItems(animals);
    }, [animals]);

    // Grid States
    const [dataState, setDataState] = useState<State>({
      take: 10,
      skip: 0,
    });

    const dataStateChange = (e: GridDataStateChangeEvent) => {
      setDataState(e.dataState);
    };

    // Select animals
    // Local state for the grid's checkbox that will indicate
    // the state of the checkbox
    const [selectedState, setSelectedState] = useState<{
      [id: string]: boolean | number[];
    }>({});

    // Local state for the selected animals, that will be previewed in
    // the next step of the logic.
    // const [selectedAnimals, setSelectedAnimals] = useState<AnimalType[]>([]);
    const [allSelValue, setAllSelValue] = useState<boolean>(false);

    // Check/Uncheck row in the grid
    // Use a callback so that the function is not fully
    // rerendered every time the component re-renders.
    // It should only update if there are changes to the
    // selectedState local state
    const onSelectionChange = useCallback(
      async () => {
      },
      [selectedState]
    );

    // Set all page elements to be checked
    const onHeaderSelectionChange = ({
      dataItems,
      syntheticEvent,
    }: GridHeaderSelectionChangeEvent) => {
      const checkboxElement: any = syntheticEvent.target;
      const { checked } = checkboxElement;
      const newSelectedState = {};
      const selAnimals: any[] = [];
      setAllSelValue(!allSelValue);
      dataItems.forEach(async (animal) => {
        newSelectedState[idGetter(animal)] = checked;
        if (newSelectedState[idGetter(animal)]) {
          selAnimals.push(animal);
        }
      });
      setSelectedState(newSelectedState);
      // setSelectedAnimals(selAnimals);
    };

    // Handle Menu visibility
    const handleMenuVisibility = () => {
      console.log('menu visibility changed');
    };

    return (
      <>
        <AnimalTable
          data={items}
          dataState={dataState}
          dataStateChange={dataStateChange}
          handleMenuVisibility={handleMenuVisibility}
          onHeaderSelectionChange={onHeaderSelectionChange}
          onSelectionChange={onSelectionChange}
          selectedState={selectedState}
          setDataState={setDataState}
          stateColumns={cattleColumns}
          cell={CattleBreedCell}
          DATA_ITEM_KEY='animalId'
          showToolbar={false}
          showColumns={false}
          showSearch={false}
          selectable={false}
        />
      </>
    );
  }
);

export default BatchAnimalsForWarehouseGrid;
