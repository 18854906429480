/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/StoreHooks';
import {
  EDIT_TRADEMARK,
  GET_TRADEMARKS,
  SAVE_TRADEMARK,
  TrademarkInputType,
  TrademarksResponseType,
  TrademarkType
} from './data/TrademarkSliceData';
import {
  createAsyncThunkGeneric,
  createAsyncThunkGenericVoid
} from './GenericThunks';

type TrademarksState = {
  trademarks: TrademarkType[];
  isLoading: boolean;
};

const initialState: TrademarksState = {
  trademarks: [],
  isLoading: false
};

// Async thunks
export const getAllTrademarks = createAsyncThunkGenericVoid(
  'trademarks/getAllTrademarks',
  async ({ client }) => {
    const response = await client.query<void, TrademarksResponseType>(
      'tradeMark',
      GET_TRADEMARKS
    );
    return response?.items ? response?.items : [];
  }
);

export const saveTrademark = createAsyncThunkGeneric<
TrademarkType,
TrademarkType
>('trademarks/saveTrademark', async ({ client, input }) => {
  input.active = true;
  return client.mutate<TrademarkInputType, TrademarkType>(
    'addTradeMark',
    SAVE_TRADEMARK,
    { tradeMark: input }
  );
});

export const editTrademark = createAsyncThunkGeneric<
TrademarkType,
TrademarkType
>('trademarks/editTrademark', async ({ client, input }) =>
  client.mutate<TrademarkInputType, TrademarkType>(
    'updateTradeMark',
    EDIT_TRADEMARK,
    { tradeMark: input }
  ));
// slice
const TrademarksSlice = createSlice({
  name: 'trademarks',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllTrademarks.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllTrademarks.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.trademarks = payload;
      })
      .addCase(saveTrademark.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(saveTrademark.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.trademarks.push(payload);
      })
      .addCase(editTrademark.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editTrademark.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        const updated = state.trademarks.findIndex(
          (enterprise) =>
            enterprise.tradeMarkId === payload.tradeMarkId
        );
        state.trademarks[updated] = payload;
      });
  }
});

// Export selectors
export const selectTrademarks = (state: RootState) =>
  state.trademarks.trademarks;
export const selectTrademarksLoading = (state: RootState) =>
  state.trademarks.isLoading;

// Export reducer
export default TrademarksSlice.reducer;
