import { gql } from '@apollo/client';
import { ContragentType } from './ContragentsSliceData';
import { TruckType } from './TrucksSliceData';

// Types
export type SellDataType = {
  sellFirmID: number;
  buyFirmID: number;
  dealDate: Date;
  transportDate: Date;
  waybillNumber: string;
  vehicleTaraWeight: number;
  vehicleTotalWeight: number;
  averageAnimalWeight: number;
  pricePerKilo: number;
  pricePerHead: number;
  truckId: TruckType;
  contragentId: ContragentType;
};

export type WeightedAnimalsType = {
  id?: number;
  weight?: number;
};

export type SaleDataType = {
  sellFirmID: number;
  buyFirmID: number;
  dealDate: Date;
  transportDate: Date;
  waybillNumber: string;
  vehicleTaraWeight: number;
  vehicleTotalWeight: number;
  averageAnimalWeight: number;
  pricePerKilo: number;
  pricePerHead: number;
  truckId: number;
  contragentId: number;
  animalWeights: WeightedAnimalsType[];
};

export type SaleDataInput = {
  saleInput: SaleDataType;
};

// Queries & Mutations
export const SAVE_SALE = gql`
  mutation AddSale($saleInput: saleInput) {
    addSaleEvent(saleInput: $saleInput) {
      saleEventID
    }
  }
`;
