/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/StoreHooks';
import {
  AddressBookEmployeesFirmsResponseType,
  AddressBookEmployeesFirmsType,
  AddressBookEmployeesResponseType,
  AvailableMatadorsType,
  EditEmployeeType,
  EmployeesABType,
  EDIT_EMPLOYEE,
  GET_EMPLOYEES,
  GET_HOLDING_FIRMS,
  SAVE_EMPLOYEE,
  SaveEmployeeInputType,
  SaveEmployeeABType,
} from './data/EmployeesSliceData';
import {
  createAsyncThunkGeneric,
  createAsyncThunkGenericVoid
} from './GenericThunks';

type AddressBookEmployeesType = {
  employees: EmployeesABType[];
  availableMatadors: AvailableMatadorsType[];
  matadorsLoading: boolean;
  addressBookEmployeesLoading: boolean;
  addressBookEmployeesFirms: AddressBookEmployeesFirmsType[];
};

const initialState: AddressBookEmployeesType = {
  employees: [],
  availableMatadors: [],
  matadorsLoading: false,
  addressBookEmployeesFirms: [],
  addressBookEmployeesLoading: false
};

// Thunks
export const getAllABEmployees = createAsyncThunkGenericVoid(
  'addressBookEmployees/getAllABEmployees',
  async ({ client }) => {
    const response = await client.query<void, AddressBookEmployeesResponseType>(
      'users',
      GET_EMPLOYEES
    );
    return response?.items ? response?.items : [];
  }
);

export const getHoldingProfileFirms = createAsyncThunkGenericVoid(
  'addressBookEmployees/getHoldingProfileFirms',
  async ({ client }) => {
    const response = await client.query<
    void,
    AddressBookEmployeesFirmsResponseType
    >('addressBookFirm', GET_HOLDING_FIRMS);
    return response?.items ? response?.items : [];
  }
);

export const editEmployee = createAsyncThunkGeneric<
EmployeesABType,
EditEmployeeType
>(
  'addressBookEmployees/editEmployee',
  async ({ client, input }) =>
    client.mutate<EditEmployeeType, EditEmployeeType>(
      'editEmployeeMutation',
      EDIT_EMPLOYEE,
      { employee: input }
    )
);

export const saveEmployee = createAsyncThunkGeneric<
SaveEmployeeABType,
EmployeesABType
>('addressBookEmployees/saveEmployee', async ({ client, input }) =>
  client.mutate<SaveEmployeeInputType, EmployeesABType>(
    'addUser',
    SAVE_EMPLOYEE,
    { userInput: input }
  ));

// Slice
export const AddressBookEmployeesSlice = createSlice({
  name: 'addressBookEmployees',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllABEmployees.pending, (state) => {
        state.addressBookEmployeesLoading = true;
      })
      .addCase(getAllABEmployees.fulfilled, (state, { payload }) => {
        state.addressBookEmployeesLoading = false;
        state.employees = payload;
      })
      .addCase(getHoldingProfileFirms.pending, (state) => {
        state.addressBookEmployeesLoading = true;
      })
      .addCase(getHoldingProfileFirms.fulfilled, (state, { payload }) => {
        state.addressBookEmployeesLoading = false;
        state.addressBookEmployeesFirms = payload;
      })
      .addCase(editEmployee.pending, (state) => {
        state.addressBookEmployeesLoading = true;
      })
      .addCase(editEmployee.fulfilled, (state, { payload }) => {
        state.addressBookEmployeesLoading = false;
        const empIndx = state.employees.findIndex(
          (empl) =>
            empl.userId === payload?.employee.userId
        );
        state.employees[empIndx] = payload?.employee;
      })
      .addCase(saveEmployee.pending, (state) => {
        state.addressBookEmployeesLoading = true;
      })
      .addCase(saveEmployee.fulfilled, (state, { payload }) => {
        state.addressBookEmployeesLoading = false;
        state.employees = [...state.employees, payload];
      });
  }
});

// Selectors
export const selectAddressBookEmployees = (state: RootState) =>
  state.addressBookEmployees.employees;
export const selectAddressBookFirms = (state: RootState) =>
  state.addressBookEmployees.addressBookEmployeesFirms;
export const selectAddressBookEmployeesLoading = (state: RootState) =>
  state.addressBookEmployees.addressBookEmployeesLoading;
export const selectAvailableMatadors = (state: RootState) =>
  state.addressBookEmployees.availableMatadors;
export const selectMatadorsLoadingState = (state: RootState) =>
  state.addressBookEmployees.matadorsLoading;
// Export reducer
export default AddressBookEmployeesSlice.reducer;
