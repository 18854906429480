import { Button } from '@progress/kendo-react-buttons';
import { useLocalization } from '@progress/kendo-react-intl';
import { Card, CardBody, Stepper } from '@progress/kendo-react-layout';
import React, { useState } from 'react';

import GrantAccess from '../../features/GrantAccess/GrantAccess';
import { MOVEMENTS_REGISTRATION_TITLE } from '../../languages/languages';
import { AnimalTypeEnum } from '../../slices/data/AnimalSliceData';
import { IRegistrationData } from '../../slices/data/MovementSliceData';
import RegistrationData from '../RegistrationGroupData/RegistrationGroupData';
import RegistrationOwnershipData from '../RegistrationOwnershipData/RegistrationOwnershipData';
import RegistrationTableData from '../RegistrationTableData/RegistrationTableData';

// TODO кои допълнителни роли трябва да се добавят
const REQUIRED_ROLES: string[] = ['Admin', 'Holding Owner', 'Farm Manager'];

const items = [
  {
    label: 'Групови данни',
  },
  {
    label: 'Собственост',
  },
  {
    label: 'Таблица',
  }
];

const MovementContainer = () => {
  const localizationService = useLocalization();
  const [value, setValue] = useState(0);
  const [regData, setRegData] = useState({
    animalsType: AnimalTypeEnum.CATTLE
  } as IRegistrationData);
  const [showSuccess, setShowSuccess] = useState(false);

  const onToSecond = (data: IRegistrationData) => {
    setRegData({
      ...regData,
      ...data
    });

    setValue(value + 1);
  };

  const onToThird = (data: IRegistrationData) => {
    setRegData({
      ...regData,
      firmId: data.firmId,
      animalObjectId: data.animalObjectId
    });

    setValue(value + 1);
  };

  const onPrev = () => {
    setValue(value - 1);
  };

  const onSuccess = () => {
    setShowSuccess(true);
  };

  const startNewRegistration = () => {
    setRegData({
      animalsType: AnimalTypeEnum.CATTLE
    } as IRegistrationData);
    setValue(0);
    setShowSuccess(false);
  };

  return (
    <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
      <h1 className='page-title'>{localizationService.toLanguageString(MOVEMENTS_REGISTRATION_TITLE, '')}</h1>
      {!showSuccess
        && (
          <div>
            <div className='content-wrapper section'>
              <Stepper value={value} items={items} />
            </div>
            <div>
              {value === 0 && <RegistrationData onNext={onToSecond} model={regData} />}
              {value === 1
                && <RegistrationOwnershipData onNext={onToThird} onPrev={onPrev} model={regData} />}
              {value === 2
                && <RegistrationTableData onNext={onSuccess} onPrev={onPrev} regData={regData} />}
            </div>
          </div>
        )}
      {
        showSuccess
        && (
          <div className='row'>
            <div className='col-12 col-md-6 offset-md-3'>
              <Card>
                <CardBody className='d-flex'>
                  {/* <CardTitle>Card Title</CardTitle>
                  <CardTitle>Card Subtitle</CardTitle> */}
                  <div className='d-flex justify-content-center align-items-center pl-l pr-l text-success'>
                    <i className='far fa-check-circle fa-4x text-success' />
                  </div>
                  <div className='d-flex flex-column justify-content-center align-items-center'>
                    <h2>Успешно вписахте групата от животни</h2>
                    <p>
                      Хронология на вписаните животни може да видите в таблото на приложението
                    </p>
                    <Button look='outline' onClick={startNewRegistration}>Ново вписване</Button>
                  </div>
                </CardBody>
              </Card>
            </div>
          </div>
        )
      }
    </GrantAccess>
  );
};

export default MovementContainer;
