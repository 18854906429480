import React, { useState } from 'react';
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab
} from '@progress/kendo-react-layout';
import { useLocalization } from '@progress/kendo-react-intl';
import AddressBookFirmsContainer from '../../features/AddressBookFirmsContainer/AddressBookFirmsContainer';
import AddressBookEmployeesContainer from '../../features/AddressBookEmployeesContainer/AddressBookEmployeesContainer';
import {
  ADDRESS_BOOK_TITLE,
  ADDRESS_BOOK_CONTRAGENTS,
  ADDRESS_BOOK_EMPLOYEES,
  ADDRESS_BOOK_FIRMS,
  ADDRESS_BOOK_TRUCKS
} from '../../languages/languages';
import TransportFirmsTrucks from '../../features/TransportFirmsTrucks/TransportFirmsTrucks';
import AdressBookContragentsContainer from '../../features/AddressBookContragentsContainer/AddressBookContragentsContainer';
import GrantAccess from '../../features/GrantAccess/GrantAccess';

const REQUIRED_ROLES: string[] = [
  'Admin',
  'Holding Owner',
  'Abattoir Manager',
  'Farm Manager',
  'Farm Veterinary Inspector'
];

const AddressBook = () => {
  const localizationService = useLocalization();

  const [selected, setSelected] = useState(0);
  const handleSelect = (ev: TabStripSelectEventArguments) => {
    setSelected(ev.selected);
  };

  return (
    <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
      <h1 className='page-title'>
        {localizationService.toLanguageString(ADDRESS_BOOK_TITLE, '')}
      </h1>
      <div className='content-wrapper section'>
        <TabStrip selected={selected} onSelect={handleSelect}>
          <TabStripTab
            title={localizationService.toLanguageString(
              ADDRESS_BOOK_CONTRAGENTS,
              ''
            )}
          >
            <AdressBookContragentsContainer />
          </TabStripTab>
          <TabStripTab
            title={localizationService.toLanguageString(
              ADDRESS_BOOK_EMPLOYEES,
              ''
            )}
          >
            <AddressBookEmployeesContainer />
          </TabStripTab>
          <TabStripTab
            title={localizationService.toLanguageString(ADDRESS_BOOK_FIRMS, '')}
          >
            <AddressBookFirmsContainer />
          </TabStripTab>
          <TabStripTab
            title={localizationService.toLanguageString(
              ADDRESS_BOOK_TRUCKS,
              ''
            )}
          >
            <TransportFirmsTrucks />
          </TabStripTab>
        </TabStrip>
      </div>
    </GrantAccess>
  );
};

export default AddressBook;
