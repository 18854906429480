/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../app/StoreHooks';
import { SaleDataInput, SaleDataType, SAVE_SALE, SellDataType } from './data/SellOperationSliceData';
import { createAsyncThunkGeneric } from './GenericThunks';

type SellOperationSliceType = {
  saleData: SellDataType;
  sellOperationIsLoading: boolean;
};

// Async Thunks
export const sendSaleData = createAsyncThunkGeneric<SaleDataType, SaleDataType>(
  'sellOperation/sendSaleData',
  async ({ client, input }) =>
    client.mutate<SaleDataInput, SaleDataType>(
      'saleEventID',
      SAVE_SALE,
      { saleInput: input }
    )
);

const initialState: SellOperationSliceType = {
  saleData: {
    sellFirmID: null,
    buyFirmID: null,
    dealDate: new Date(2022, 10, 10), // TODO Date in state in bad practice
    transportDate: new Date(), // TODO Date in state in bad practice
    waybillNumber: '',
    pricePerKilo: null,
    pricePerHead: null,
    vehicleTaraWeight: null,
    vehicleTotalWeight: null,
    averageAnimalWeight: null,
    truckId: null,
    contragentId: null,
  },
  sellOperationIsLoading: false
};

export const sellOperationSlice = createSlice({
  name: 'sellOperation',
  initialState,
  reducers: {
    setSaleData: (state, { payload }) => {
      const [value, data] = payload;
      state.saleData[value] = data;
    }
  }
});

// Export Actions
export const { setSaleData } = sellOperationSlice.actions;

// Export selectors
export const selectSalelData = (state: RootState) =>
  state.sellOperation.saleData;
// Export Reducer
export default sellOperationSlice.reducer;
