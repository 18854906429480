import React from 'react';
import { Loader } from '@progress/kendo-react-indicators';
import { useLocalization } from '@progress/kendo-react-intl';
import ShoppingCenterGridContainer from '../ShoppingCenterGridContainer/ShoppingCenterGridContainer';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import { saveShoppingCenter, selectShopingCanterLoading } from '../../slices/ShoppingCenterSlice';
import ShoppingCenterForm from '../../components/ShoppingCenterForm/ShoppingCenterForm';
import { SHOP_TAB } from '../../languages/languages';
import { ShoppingCenterType } from '../../slices/data/ShoppingCenterSliceData';
import { selectAvailableFirms } from '../../slices/FirmSlice';

const EnterpriseContainer = () => {
  const localizationService = useLocalization();
  const isLoading = useAppSelector(selectShopingCanterLoading);

  const dispatch = useAppDispatch();
  const availableFirms = useAppSelector(selectAvailableFirms);

  const handleSubmit = (formData: ShoppingCenterType) => {
    dispatch(saveShoppingCenter(formData));
  };

  return (
    <>
      <div className='holding-form'>
        <h3 className='subtitle-1 mb-s'>{localizationService.toLanguageString(SHOP_TAB, '')}</h3>

        {isLoading ? (
          <Loader size='large' type='pulsing' className='k-centered' />
        ) : (
          <ShoppingCenterForm availableFirms={availableFirms} submitForm={handleSubmit} />
        )}
      </div>
      <div className='holding-grid' id='shopping-center-table' />
      <ShoppingCenterGridContainer />
    </>
  );
};

export default EnterpriseContainer;
