/**
 * Summary.
 * Calculates age class from animmal's age in mopnths
 *
 * @param age Animal's age in months
 * @param gender Animal's gender
 * @param isCastrate Is animal castrated
 */
export const ageClass = (age: number, gender: string, isCastrate: boolean): 'A' | 'B' | 'C' | 'D' | 'E' | 'Z' => {
  if (age >= 8 && age < 12) {
    return 'Z';
  }

  if (gender === 'MALE') {
    if (isCastrate) {
      return 'C';
    }

    if (age >= 12 && age < 24) {
      return 'A';
    }

    return 'B';
  }

  if (gender === 'FEMALE') {
    // TODO: Трябва да се добави проверка за крави и юници
    return 'D';
  }

  throw Error('Invalid parameters');
};

export default {
  ageClass
};