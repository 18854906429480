import { gql } from '@apollo/client';
import { INomenclatureData } from './NomenclatureData';

export interface IUneceFarmSettings {
  productionSystemId: number;
  feedingSystemId: number;
}

export interface IUneceEnterpriceSettings {
  bovineQualitySystemId: number,
  conformityAssessmentId: number,
  fatThicknessId: number,
  packingCodesId: number,
  postSlaughterSystemId: number,
  refrigerationId: number,
  slaughterSystemId: number,
  weightRangingId: number
}

export interface IUneceEnterpriceSettingsValues {
  bovineQualitySystemId: INomenclatureData,
  conformityAssessmentId: INomenclatureData,
  fatThicknessId: INomenclatureData,
  packingCodesId: INomenclatureData,
  postSlaughterSystemId: INomenclatureData,
  refrigerationId: INomenclatureData,
  slaughterSystemId: INomenclatureData,
  weightRangingId: INomenclatureData
}

export interface UneceFarmSettingsResponse {
  uneceFarmSettings: IUneceFarmSettings;
}

export interface UneceFarmSettingsInput {
  settingsInput: IUneceFarmSettings;
}

export interface UneceEnterpriceSettingsInput {
  settingsInput: IUneceEnterpriceSettings;
}

export interface UneceEnterpriceSettingsResponse{
  uneceEnterpriceSettings: IUneceEnterpriceSettings;
}

export const GET_FARM_SETTINGS = gql`
  {
    uneceFarmSettings {
      holdingId,
      feedingSystemId,
      productionSystemId,
      updatedOn,
      updatedBy,
      createdOn,
      createdBy
    }
  }
`;

export const UPDATE_FARM_SETTINGS = gql`
  mutation updateUneceFarmSettings($settingsInput: uneceFarmSettingsInput){
    updateUneceFarmSettings(settingsInput: $settingsInput) {
      holdingId,
      feedingSystemId,
      productionSystemId,
      updatedOn,
      updatedBy
    }
  }
`;

export const GET_ENTERPRICE_SETTINGS = gql`
  {
    uneceEnterpriceSettings {
      holdingId,
      bovineQualitySystemId,
      conformityAssessmentId,
      fatThicknessId,
      packingCodesId,
      postSlaughterSystemId,
      refrigerationId,
      slaughterSystemId,
      weightRangingId
    }
  }
`;

export const UPDATE_ENTERPRICE_SETTINGS = gql`
  mutation updateUneceEnterpriceSettings($settingsInput: uneceEnterpriceSettingsInput){
    updateUneceEnterpriceSettings(settingsInput: $settingsInput) {
      holdingId,
      bovineQualitySystemId,
      conformityAssessmentId,
      fatThicknessId,
      packingCodesId,
      postSlaughterSystemId,
      refrigerationId,
      slaughterSystemId,
      weightRangingId
    }
  }
`;