import React, { useEffect, useState } from 'react';
import {
  GridDataStateChangeEvent,
} from '@progress/kendo-react-grid';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { State } from '@progress/kendo-data-query';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import {
  getDealsHistory,
  getSingleDealInfo,
  selectDealsHistory
} from '../../slices/DealsSlice';
import DealsHistoryGrid from '../../components/DealsHistoryGrid/DealsHistoryGrid';

type DealsHistoryGridProps = {
  setVisibility: React.Dispatch<React.SetStateAction<boolean>>;
};

const DealsHistoryGridContainer = ({
  setVisibility
}: DealsHistoryGridProps) => {
  const exclExport = React.useRef<ExcelExport | null>(null);
  const excelExport = () => {
    if (exclExport.current !== null) {
      exclExport.current.save();
    }
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    const awaitData = async () => {
      dispatch(getDealsHistory());
    };
    awaitData();
  }, []);
  const dealsHistory = useAppSelector(selectDealsHistory);

  // Grid States
  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0
  });

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  };

  //   Show the DealInformation component and pass the appropriate deal details
  // via the deal ID
  const showDeal = (id: number) => {
    setVisibility(true);
    dispatch(getSingleDealInfo(id));
  };

  return (
    <DealsHistoryGrid
      data={dealsHistory}
      dataState={dataState}
      dataStateChange={dataStateChange}
      setDataState={setDataState}
      excelExport={excelExport}
      showDeal={showDeal}
    />
  );
};

export default DealsHistoryGridContainer;
