import React from 'react';
import { useLocalization } from '@progress/kendo-react-intl';
import { Form, FormElement, Field } from '@progress/kendo-react-form';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { DateTimePickerChangeEvent } from '@progress/kendo-react-dateinputs';
import { FormComboBox, FormDateTimePicker } from '../FormComponents/FormComponents';
import { DATE, KILL_DATA_MATADOR } from '../../languages/languages';
import { EmployeesABType } from '../../slices/data/EmployeesSliceData';

type KillDataFormProps = {
  setMatador: (id: number) => void;
  matadors: EmployeesABType[];
  setDate: (date: Date) => void;
};

const KillDataForm = ({ setMatador, matadors, setDate }: KillDataFormProps) => {
  const localizationService = useLocalization();

  const selectMatador = ({ value }: ComboBoxChangeEvent) => {
    setMatador(value?.userId);
  };

  const selectDate = ({ value }: DateTimePickerChangeEvent) => {
    setDate(value);
  };

  return (
    <div className='sell-details-form'>
      <Form
        render={() =>
          (
            <FormElement>
              <fieldset className='k-form-fieldset'>
                <Field
                  id='matadorID'
                  name='matadorID'
                  label={localizationService.toLanguageString(KILL_DATA_MATADOR, '')}
                  component={FormComboBox}
                  data={matadors}
                  onChange={selectMatador}
                  textField='userName'
                />
                <Field
                  id='dateWeight'
                  name='dateWeight'
                  label={localizationService.toLanguageString(DATE, '')}
                  component={FormDateTimePicker}
                  onChange={selectDate}
                />
              </fieldset>
            </FormElement>
          )}
      />
    </div>
  );
};

export default KillDataForm;
