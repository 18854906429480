import { useLocalization } from '@progress/kendo-react-intl';
import React from 'react';

import GrantAccess from '../../features/GrantAccess/GrantAccess';
import { MOVEMENTS_SALE_TITLE } from '../../languages/languages';

// TODO кои допълнителни роли трябва да се добавят
const REQUIRED_ROLES: string[] = ['Admin', 'Holding Owner', 'Farm Manager'];

const MovementSaleContainer = () => {
  const localizationService = useLocalization();

  return (
    <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
      <h1 className='page-title'>{localizationService.toLanguageString(MOVEMENTS_SALE_TITLE, '')}</h1>
    </GrantAccess>
  );
};

export default MovementSaleContainer;
