import { graphql } from 'msw';
import AUTH_CONFIG from '../../auth-variables';

const blbTrace = graphql.link(AUTH_CONFIG.apiUrl);

const trademarks = [];

export default [
  blbTrace.query('getAllTrademarksQuery', (req, res, ctx) =>
    res(
      ctx.data({
        getTrademarks: {
          trademarks
        }
      })
    )),
  blbTrace.mutation('saveTrademarkMutation', (req, res, ctx) => {
    const { input } = req.variables;
    input.isActive = true;
    trademarks.push(input);
    return res(
      ctx.delay(3000),
      ctx.data({
        saveInformation: {
          input
        }
      })
    );
  })
];
