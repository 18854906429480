import React from 'react';
import { useLocalization } from '@progress/kendo-react-intl';
import { Loader } from '@progress/kendo-react-indicators';
import EnterpriseForm from '../EnterpriseForm/EnterpriseForm';
import EnterpriseGrid from '../../features/EnterpriseGridContainer/EnterpriseGridContainer';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import { saveEnterprise, selectEnterpriseLoading } from '../../slices/EnterpriseSlice';
import { ENTERPRISE_TAB } from '../../languages/languages';
import { selectAvailableFirms } from '../../slices/FirmSlice';
import { EnterpriseType } from '../../slices/data/EnterpriseSliceData';

const EnterpriseContainer = () => {
  const dispatch = useAppDispatch();
  const availableFirms = useAppSelector(selectAvailableFirms);
  const isLoading = useAppSelector(selectEnterpriseLoading);
  const localizationService = useLocalization();

  const handleSubmit = async (formData: EnterpriseType) => {
    await dispatch(saveEnterprise(formData));
  };

  return (
    <>
      <div className='holding-form'>
        <h3 className='subtitle-1 mb-s'>{localizationService.toLanguageString(ENTERPRISE_TAB, '')}</h3>

        {isLoading ? (
          <Loader size='large' type='pulsing' className='k-centered' />
        ) : (
          <EnterpriseForm submitForm={handleSubmit} availableFirms={availableFirms} />
        )}
      </div>
      <div className='holding-grid'>
        <EnterpriseGrid />
      </div>
    </>
  );
};

export default EnterpriseContainer;
