import React, { useCallback, useEffect, useState } from 'react';
import {
  GridDataStateChangeEvent,
  GridHeaderSelectionChangeEvent,
  GridSelectionChangeEvent,
} from '@progress/kendo-react-grid';
import { getter } from '@progress/kendo-react-common';
import { State } from '@progress/kendo-data-query';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import ColumnPickerMenu from '../ColumnPickerMenu/ColumnPickerMenu';
import { getAnimalsInFarm, selectAllAnimals } from '../../slices/AnimalSlice';
import AnimalPreview from '../AnimalPreview/AnimalPreview';
import { AnimalTypeEnum, AnimalType } from '../../slices/data/AnimalSliceData';
import AnimalOperations from '../AnimalOperations/AnimalOperations';
import AnimalTable from '../../components/AnimalTable/AnimalTable';
import { SheepBreedCell } from '../../components/CustomGridCells/CustomGridCells';
import { selectSheepColumns } from '../../slices/AnimalColumnsSlice';

const DATA_ITEM_KEY: string = 'animalId';
const idGetter = getter(DATA_ITEM_KEY);

const AnimalTableSheep = () => {
  const dispatch = useAppDispatch();
  const sheepColumns = useAppSelector(selectSheepColumns);
  const sheeps = useAppSelector(selectAllAnimals).filter(
    (animal) =>
      animal.animalType === AnimalTypeEnum.SHEEP
  );

  useEffect(() => {
    const awaitData = () => {
      dispatch(getAnimalsInFarm());
    };
    awaitData();
  }, []);

  // Grid States
  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0
  });
  const [menuVisibility, setMenuVisibility] = useState(false);

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  };

  const handleMenuVisibility = () => {
    setMenuVisibility(true);
  };

  // Select animals
  const [selectedState, setSelectedState] = useState<{
    [id: string]: boolean | number[];
  }>({});

  const [selectedAnimals, setSelectedAnimals] = useState<AnimalType[]>([]);

  const onSelectionChange = useCallback(
    ({ dataItem, syntheticEvent }: GridSelectionChangeEvent) => {
      const checkboxElement: any = syntheticEvent.target;
      const { checked } = checkboxElement;
      const newSelectedState = {
        ...selectedState,
        [idGetter(dataItem)]: checked
      };

      if (!newSelectedState[idGetter(dataItem)]) {
        const updatedState = selectedAnimals.filter(
          (animal) =>
            animal.animalId !== dataItem.animalId
        );
        setSelectedAnimals(updatedState);
      } else {
        setSelectedAnimals([...selectedAnimals, dataItem]);
        setSelectedState(newSelectedState);
      }
    },
    [selectedState]
  );

  const onHeaderSelectionChange = useCallback(
    (event: GridHeaderSelectionChangeEvent) => {
      const checkboxElement: any = event.syntheticEvent.target;
      const { checked } = checkboxElement;
      const newSelectedState = {};

      event.dataItems.forEach((item) => {
        newSelectedState[idGetter(item)] = checked;
      });
      setSelectedState(newSelectedState);
    },
    []
  );

  // Handle show/hide selected animals preview
  const [showPreview, setShowPreview] = useState<boolean>(false);

  const togglePreview = () => {
    setShowPreview(!showPreview);
  };

  // Handle show/hide animal operations component visibility
  const [showOperations, setShowOperations] = useState<boolean>(false);

  return (
    <>
      <AnimalTable
        data={sheeps}
        dataState={dataState}
        dataStateChange={dataStateChange}
        handleMenuVisibility={handleMenuVisibility}
        onHeaderSelectionChange={onHeaderSelectionChange}
        onSelectionChange={onSelectionChange}
        selectedState={selectedState}
        setDataState={setDataState}
        stateColumns={sheepColumns}
        togglePreview={togglePreview}
        cell={SheepBreedCell}
      />
      {!menuVisibility ? null : (
        <ColumnPickerMenu
          type='sheep'
          columnSet={sheepColumns}
          setVisibility={setMenuVisibility}
        />
      )}
      {!showPreview ? null : (
        <AnimalPreview
          setOriginalSelection={setSelectedAnimals}
          setCheckboxSelection={setSelectedState}
          checkboxesState={selectedState}
          selectedAnimals={selectedAnimals}
          showPreview={showPreview}
          setShowPreview={setShowPreview}
          setShowOperations={setShowOperations}
        />
      )}
      {!showOperations ? null : (
        <AnimalOperations setVisibility={setShowOperations} holdingType='farmer' />
      )}
    </>
  );
};

export default AnimalTableSheep;
