import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { Grid, GridCellProps, GridColumn } from '@progress/kendo-react-grid';
import { Checkbox } from '@progress/kendo-react-inputs';
import { useLocalization } from '@progress/kendo-react-intl';
import { format } from '@telerik/kendo-intl';
import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import { ANIMAL, STEP_FROM } from '../../languages/keys/keys';
import { CANCEL, FORWARD, SAVE } from '../../languages/languages';
import { APPLY_FOR_ALL_ANIMALS, NO_REASON_ANIMALS_MSG, UNREGISTER_REASON } from '../../languages/keys/movements.keys';
import { selectCattleColumns } from '../../slices/AnimalColumnsSlice';
import { UnregisterAnimal } from '../../slices/data/MovementSliceData';
import {
  INomenclatureData,
  INomenclatureResponceData,
  NomenclatureNames,
} from '../../slices/data/NomenclatureData';
import { getNomenclatureValues } from '../../slices/NomenclatureSlice';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';
import { DateOfBirthCell, GridAnimalSexCell } from '../CustomGridCells/CustomGridCells';

const columsFields = ['lID', 'dateOfBirth', 'sex', 'cattleBreed'];

const AnimalsUnregisterReasons = ({
  animals,
  onNext,
}: {
  animals: UnregisterAnimal[];
  onNext: (animals: UnregisterAnimal[]) => void;
}) => {
  const dispatch = useAppDispatch();
  const [items, setItems] = useState([...animals] as UnregisterAnimal[]);
  const localizationService = useLocalization();
  // Edit form state and functions
  const [showAddReason, setShowAddReason] = React.useState(false);
  const [editItem, setEditItem] = React.useState<UnregisterAnimal>(null);
  const [itemToRemove, setItemToRemove] = React.useState<UnregisterAnimal>(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [reason, setReason] = useState<INomenclatureData>();
  const [addForAll, setAddForAll] = useState<boolean>();
  const [deleteMessage, setDeleteMessage] = useState<string>('');
  const [reasons, setReasons] = useState<INomenclatureData[]>([]);
  const hasItemsWithoutReason = items.some((x) => !x.reason);

  const cattleColumns = useAppSelector(selectCattleColumns).filter((x) =>
    columsFields.some((f) => x.field === f)
  );

  const onSubmit = () => {
    onNext(items);
  };

  const onDelete = (item: UnregisterAnimal) => {
    setDeleteMessage(`Потвърждавате ли премахването животно ${item.lID}?`);
    setItemToRemove(item);
    setShowDeleteConfirm(true);
  };

  const handleDelete = (confirmed: boolean) => {
    if (confirmed) {
      const index = items.findIndex((x) => x.animalId === itemToRemove.animalId);

      if (index !== -1) {
        items.splice(index, 1);
        setItems(items);
      }
    }

    setItemToRemove(null);
    setShowDeleteConfirm(false);
  };

  const onAddReason = () => {
    if (addForAll) {
      items.forEach((a) => {
        a.reason = reason;
      });
    } else {
      editItem.reason = reason;
    }

    setAddForAll(false);
    setReason(null);
    setShowAddReason(false);
  };

  useEffect(() => {
    dispatch(getNomenclatureValues(NomenclatureNames.unregisterReason)).then(({ payload }) => {
      setReasons((payload as INomenclatureResponceData).values);
    });
  }, []);

  const ActionsCell = ({ dataItem }: GridCellProps) => (
    <td className='text-center'>
      <Button
        data-testid='editButton'
        type='button'
        className='k-button k-secondary mr-l'
        icon='edit'
        look='outline'
        onClick={() => {
          setEditItem(dataItem);
          setShowAddReason(true);
        }}
      />
      <Button
        data-testid='deleteButton'
        type='button'
        className='k-button k-button-error'
        icon='delete'
        look='outline'
        onClick={() => {
          onDelete(dataItem);
        }}
      />
    </td>
  );

  const gridCellByField = (cellField: string) => {
    if (cellField === 'dateOfBirth') {
      return DateOfBirthCell;
    }

    if (cellField === 'sex') {
      return GridAnimalSexCell;
    }

    return null;
  };

  return (
    <div>
      <h2>{localizationService.toLanguageString(UNREGISTER_REASON, '')}</h2>
      <div>
        {hasItemsWithoutReason && (
          <div className='text-error pt-s pb-s'>
            <i className='fas fa-exclamation-circle mr-s' />
            {localizationService.toLanguageString(NO_REASON_ANIMALS_MSG, '')}
          </div>
        )}
        <Grid pageable sortable data={items}>
          {cattleColumns.map((column) => (
            <GridColumn
              key={column.field}
              field={column.field}
              title={column.title}
              filter={column.filter}
              format={column.format}
              cell={gridCellByField(column.field)}
            />
          ))}
          <GridColumn
            key='reason'
            field='reason.text'
            title={localizationService.toLanguageString(UNREGISTER_REASON, '')}
          />
          <GridColumn cell={ActionsCell} />
        </Grid>
      </div>
      <hr />
      <div className='d-flex justify-content-center mb-l align-items-center'>
        <div className='mr-l' style={{ height: 'fit-content' }}>
          {format(localizationService.toLanguageString(STEP_FROM, ''), [2, 3])}
        </div>
        <Button
          name='OwnershipDataAdd'
          type='button'
          title={localizationService.toLanguageString(FORWARD, '')}
          primary
          iconClass='fa fa-share'
          onClick={onSubmit}
          disabled={hasItemsWithoutReason}
        >
          {localizationService.toLanguageString(FORWARD, '')}
        </Button>
      </div>
      <hr />
      {showDeleteConfirm && (
        <ConfirmDialog onResult={handleDelete} title='Потвърждение' message={deleteMessage} />
      )}

      {showAddReason && (
        <Dialog
          title={`${localizationService.toLanguageString(ANIMAL, '')} ${editItem.lID}`}
          onClose={() => {
            setShowAddReason(false);
          }}
        >
          <div>
            <div>{localizationService.toLanguageString(UNREGISTER_REASON, '')}?</div>
            <div className='mb-l'>
              <DropDownList
                data={reasons}
                textField='text'
                dataItemKey='id'
                onChange={(e) => {
                  setReason(e.value);
                }}
                defaultValue={editItem.reason}
              />
            </div>
            <div className='mb-l'>
              <Checkbox
                label={localizationService.toLanguageString(APPLY_FOR_ALL_ANIMALS, '')}
                onChange={(e) => {
                  setAddForAll(e.value);
                }}
              />
            </div>
            <DialogActionsBar>
              <Button
                primary
                className='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base'
                onClick={onAddReason}
              >
                {localizationService.toLanguageString(SAVE, '')}
              </Button>
              <Button
                className='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base'
                onClick={() => {
                  setShowAddReason(false);
                }}
                look='outline'
              >
                {localizationService.toLanguageString(CANCEL, '')}
              </Button>
            </DialogActionsBar>
          </div>
        </Dialog>
      )}
    </div>
  );
};

export default AnimalsUnregisterReasons;
