/* eslint-disable */

import { ApolloClient, createHttpLink, DocumentNode, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import nodeFetch from 'node-fetch';
import { v4 as uuid } from 'uuid';

import AUTH_CONFIG from '../auth-variables';
import { BLBSError } from '../slices/data/ErrorSliceData';
import { addError, addSuccessMessage } from '../slices/ErrorSlice';
import { successMessage } from '../utils/utilities';
import { AppDispatch } from './StoreHooks';

const apolloFetch = (() => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return fetch;
    case 'test':
      return nodeFetch;
    default:
      return fetch;
  }
})();

type Query = <QV, RT>(
  name: string,
  query: DocumentNode,
  variables?: QV
) => Promise<RT>;
type Mutate = <MV, RT>(
  name: string,
  mutation: DocumentNode,
  variables?: MV
) => Promise<RT>;

const httpLink = createHttpLink({
  uri: AUTH_CONFIG.apiUrl,
  fetch: apolloFetch
});

const authLink = setContext(({ variables }, { headers }) => {
  const token = localStorage.getItem('access_token');
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token || ''}`
    }
  };
});

export type GraphQLClient = {
  query: Query;
  mutate: Mutate;
};

let client: GraphQLClient = null;
export const createGQLClient = (dispatch?: AppDispatch): GraphQLClient => {
  if (client === null) {
    const authLink = setContext(({ variables }, { headers }) => {
      const token = localStorage.getItem('access_token');
      return {
        headers: {
          ...headers,
          authorization: `Bearer ${token || ''}`
        }
      };
    });

    const cache = new InMemoryCache({
      addTypename: false,
      resultCaching: false
    });
    const apolloClient = new ApolloClient({
      cache,
      link: authLink.concat(httpLink),
      defaultOptions: {
        watchQuery: {
          fetchPolicy: 'no-cache'
        },
        mutate: {
          fetchPolicy: 'no-cache'
        }
      }
    });

    const query: Query = (name, query, variables) =>
      apolloClient
        .query({
          query,
          variables,
          fetchPolicy: 'no-cache'
        })
        .then(({ data }) => data[name])
        .catch((error) => {
          const e = new Error(error);
          const blbError: BLBSError = {
            message: e.message,
            stackError: e.stack,
            id: uuid()
          };
          dispatch?.(addError(blbError));
          return null;
        });

    const mutate: Mutate = (name, mutation, variables) =>
      apolloClient
        .mutate({
          mutation,
          variables
        })
        .then(({ data }) => {
          dispatch?.(
            addSuccessMessage({
              message: successMessage,
              id: Math.floor(Math.random() * 10)
            })
          );
          return data[name];
        })
        .catch((error) => {
          const e = new Error(error);
          const blbError: BLBSError = {
            message: e.message,
            stackError: e.stack,
            id: uuid()
          };
          dispatch?.(addError(blbError));
          //TODO Тук може да е по-добре да се хвърли грешка
          return null;
        });

    client = { query, mutate };

    return client;
  }
  return client;
};
export default createGQLClient;

export const getGraphQLClient = () => client;
