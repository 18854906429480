import React from 'react';

const Logs: React.FC = ({ children }) => {
  const logComponents = (
    <>
      <p> </p>
    </>
  );

  return <div>{children || logComponents}</div>;
};

export default Logs;
