import { gql } from '@apollo/client';

import CreateGQLClient, { getGraphQLClient } from '../app/Client';

const client = getGraphQLClient() || CreateGQLClient();
const publicFarmDataQuery = gql`
  query farmPublicData($id: Int) {
    farmPublicData(id: $id){
      breeds,
      buildings,
      holding,
      location,
      memberFrom,
      motherCows,
      name,
      number,
      otherAnimals,
      otherObjects {
        id,
        type,
        typeName,
        settlement
      },
      pastures,
      pasturesArea,
      rating,
      tags
    }
  }
`;

const publicEnterpriseDataQuery = gql`
  query enterprisePublicData($id: Int) {
    enterprisePublicData(id: $id){
      address,
      beefProcessing,
      brand,
      lambProcessing,
      lastAnimalDelivery,
      holding,
      products,
      location,
      memberFrom,
      name,
      number,
      otherObjects {
        id,
        type,
        typeName,
        settlement
      },
      rating,
      tags
    }
  }
`;

const publicShopDataQuery = gql`
  query shopPublicData($id: Int) {
    shopPublicData(id: $id){
      address,
      availableBeef,
      brand,
      availableLamb,
      lastMeetDelivery,
      holding,
      location,
      memberFrom,
      name,
      number,
      otherObjects {
        id,
        type,
        typeName,
        settlement
      },
      rating,
      tags
    }
  }
`;

export interface FarmPublicData {
  id: number;
  name: string;
  holding: string;
  number: string;
  memberFrom?: Date;
  rating: number;
  tags: string[];
  location?: string;
  otherObjects: PublicObjectShortModel[];
  buildings: number;
  pastures: number;
  pasturesArea: number;
  motherCows: number;
  otherAnimals: number;
  breeds: string[];
}

export interface EnterprisePublicData {
  id: number;
  name: string;
  holding: string;
  number: string;
  memberFrom?: Date;
  rating: number;
  tags: string[];
  location?: string;
  otherObjects: PublicObjectShortModel[];
  beefProcessing: boolean;
  lambProcessing: boolean;
  products: string[];
  brand: string;
  address: string;
  lastAnimalDelivery?: Date;
}

export interface ShopPublicData {
  id: number;
  name: string;
  holding: string;
  number: string;
  memberFrom?: Date;
  rating: number;
  tags: string[];
  location?: string;
  otherObjects: PublicObjectShortModel[];
  availableBeef: boolean;
  availableLamb: boolean;
  brand: string;
  address: string;
  lastMeetDelivery?: Date;
}

export interface BatchPublicData {
  batchNumber: string;
  deliveryDate: Date;
  UNECECode: string;
  weight: number;
  corpseClass: string;
  processedIn: string;
  raisedIn: string;
  bоrnIn: string;
}

export interface PublicObjectShortModel {
  id: number;
  settlement: string;
  type: string;
  typeName: string;
}

class PublicService {
  getFarmPublicData(id: number) {
    return client
      .query<{ id: number }, FarmPublicData>('farmPublicData', publicFarmDataQuery, { id })
      .catch((err) => console.error(err));
  }

  getEnterprisePublicData(id: number) {
    return client
      .query<{ id: number }, EnterprisePublicData>('enterprisePublicData', publicEnterpriseDataQuery, { id })
      .catch((err) => console.error(err));
  }

  getShopPublicData(id: number) {
    return client
      .query<{ id: number }, ShopPublicData>('shopPublicData', publicShopDataQuery, { id })
      .catch((err) => console.error(err));
  }
}

export default new PublicService();