import React from 'react';
import {
  Form,
  Field,
  FormElement,
  FormRenderProps
} from '@progress/kendo-react-form';
import { useLocalization } from '@progress/kendo-react-intl';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { Button } from '@progress/kendo-react-buttons';
import {
  FormComboBox,
  FormInput,
} from '../FormComponents/FormComponents';
import {
  NumberValidator,
  StringValidator,
} from '../Validators/Validators';
import {
  ENT_OWNER,
  OWNER_PLACEHOLDER,
  SUBMIT_FORM,
  TM_LINK,
  TM_LINK_PLACEHOLDER,
  TM_NAME,
  TM_NAME_PLACEHOLDER,
  TM_REG
} from '../../languages/languages';
import { AvailableFirmsType } from '../../slices/data/FirmSliceData';
import { TrademarkType } from '../../slices/data/TrademarkSliceData';

type TrademarkFormProps = {
  submitForm: (formData: TrademarkType) => void;
  availableFirms: AvailableFirmsType[];
};
const TrademarkForm = ({ submitForm, availableFirms }:TrademarkFormProps) => {
  const localizationService = useLocalization();
  return (
    <Form
      onSubmit={submitForm}
      render={(formRenderProps: FormRenderProps) =>
        (
          <FormElement>
            <fieldset className='k-form-fieldset'>
              <Field
                name='tradeMarkName'
                component={FormInput}
                label={localizationService.toLanguageString(TM_NAME, '')}
                placeholder={localizationService.toLanguageString(
                  TM_NAME_PLACEHOLDER,
                  ''
                )}
                validator={StringValidator}
              />

              <Field
                name='tradeMarkNumber'
                component={FormInput}
                label={localizationService.toLanguageString(TM_REG, '')}
                placeholder='№'
                validator={NumberValidator}
              />
              <Field
                name='tradeMarkLink'
                component={FormInput}
                label={localizationService.toLanguageString(TM_LINK, '')}
                placeholder={localizationService.toLanguageString(
                  TM_LINK_PLACEHOLDER,
                  ''
                )}
              />

              <Field
                onChange={({ value }: ComboBoxChangeEvent) => {
                  if (value === null) {
                    return;
                  }
                  formRenderProps.onChange('firmId', {
                    value: value.firmId
                  });
                }}
                placeholder={localizationService.toLanguageString(
                  OWNER_PLACEHOLDER,
                  ''
                )}
                id='firmName'
                name='firmId'
                label={localizationService.toLanguageString(ENT_OWNER, '')}
                component={FormComboBox}
                textField='firmName'
                data={availableFirms}
                validator={StringValidator}
              />

              {/* <Field
                name='tradeMarkFiles'
                component={FormUpload}
                label='Изображение'
              /> */}
            </fieldset>
            <fieldset className='k-form-buttons d-flex justify-content-end mb-l'>
              <Button
                name='TrademarkFormAdd'
                type='submit'
                primary
                icon='save'
                title={localizationService.toLanguageString(SUBMIT_FORM, '')}
                disabled={!formRenderProps.allowSubmit}
              >
                {localizationService.toLanguageString(SUBMIT_FORM, '')}
              </Button>
            </fieldset>
          </FormElement>
        )}
    />
  );
};

export default TrademarkForm;
