import bg_BG from './bg_BG';
import en_US from './en_US';

// VALIDATORS
export const REQUIRED_FIELD = 'RequiredField';

// Notifications
export const OTHER_EVENT = 'OtherEvent';
export const BUY_EVENT = 'BuyEvent';

// Grid variables
export const ADD = 'Add';
export const ADD_NEW = 'AddNew';
export const EDIT_SUBMISSION = 'EditSubmission';
export const ADD_NEW_COLUMN = 'AddNewColumn';
export const COLUMNS = 'Columns';
export const ACTIVE = 'Active';
export const INACTIVE = 'Inactive';
export const EXPORT = 'Export';
export const REMOVE = 'Remove';
export const ADD_MORE = 'AddMore';
export const ACCEPT = 'Accept';

// General
export const REVISE = 'Revise';
export const PREVIOUS = 'Previous';
export const NEXT = 'Next';
export const YES = 'Yes';
export const NO = 'No';
export const DAYS = 'Days';

// Dashborad variables
export const WIDGET_DESCRIPTION_PLACEHOLDER = 'WidgetDescriptionPlaceholder';
export const SALE_TRADE_CHART_TITLE = 'SaleTradeChartTitle';
export const PROFILE_STATUS_TITLE = 'ProfileStatusTitle';
export const PROFILE_RATING_TITLE = 'ProfileRatingTitle';
export const PROFILE_COMPLETION_TITLE = 'ProfileCompletionTitle';
export const HISTORY_CHART_TITLE = 'HistoryChartTitle';
export const SEUROP_TITLE = 'SEUROPTITLE';
export const ANIMAL_ACCEPTANCE_TITLE = 'AnimalAcceptanceTitle';
export const PLACEHOLDER_TITLE = 'PlaceholderTitle';
export const STATUS_CONTENT = 'StatusContent';

// Drawer variables
export const DASHBOARD_TITLE = 'DashboardTitle';
export const LOGS_TITLE = 'LogsTitle';
export const AMBULATORY_LOGS_TITLE = 'AmbulatoryLogsTitle';
export const ARTIFICIAL_INSEMENATION = 'ArtificialInsemenation';
export const PASTURE_LOG_TITLE = 'PastureLogTitle';
export const MORTALITY_LOG_TITLE = 'MortalityLogTitle';
export const CHEMICAL_TREATMENT_LOG_TITLE = 'ChemicalTreatementLogTitle';
export const PEST_CONTROL_LOG_TITLE = 'PestControlLogTitile';
export const USED_PESTICIDE_LOG_TITLE = 'UsedPesticideLogTitle';
export const ANIMALS_IN_FARM_TABLE_TITLE = 'AnimalsInFarmTableTitle';
export const ANIMALS_IN_BUTCHERY_TABLE_TITLE = 'AnimalsInButcheryTableTitle';
export const BUTCHERED_ANIMALS_TABLE = 'ButcheredAnimalsTable';
export const DEALS_TITLE = 'DealsTitle';
export const ADDRESS_BOOK_TITLE = 'AddressBookTitle';
export const HOLDING_PROFILE_TITLE = 'HoldingProfileTitle';
export const LINK_PLACEHOLDER_TITLE = 'LinkPlaceHolderTitle';
export const MOVEMENTS_TITLE = 'MovementsTitle';
export const MOVEMENTS_REGISTRATION_TITLE = 'MovementsRegistrationTitle';
export const MOVEMENTS_UNREGISTRATION_TITLE = 'MovementsUnregistrationTitle';
export const MOVEMENTS_SALE_TITLE = 'MovementsSaleTitle';

// AppBar container
export const SEARCH_BAR = 'SearchBar';
export const FERMER_TITLE = 'FermerTitle';
export const PROCESSOR_TITLE = 'ProcessorTitle';
export const TRADER_TITLE = 'TraderTitle';
export const CANDIDATE_TITLE = 'CandidateTitle';

// Holding Profile
export const HOLDING_PROFILE = 'HoldingProfile';
export const FIRMS_TAB = 'FirmsTab';
export const ANOBJ_TAB = 'AnimalObjectTab';
export const ENTERPRISE_TAB = 'EnterpriseTab';
export const SHOP_TAB = 'ShopTab';
export const TRADEMARK_TAB = 'TrademarkTab';

// General Info Holding Profile
export const HOLDING_NAME = 'HoldingName';
export const HOLDING_STRUCTURE = 'HoldingStructure';
export const HOLDING_SAVE = 'HoldingSave';
export const HOLDING_DECLINE = 'HoldingDecline';
export const IS_FARM = 'isFarm';
export const IS_FEEDLOT = 'isFeedlot';
export const IS_BUTCHERY = 'isButchery';
export const IS_ABATTOIR = 'isAbattoir';
export const IS_SHOP = 'isShop';
export const CATTLE = 'cattle';
export const SHEEP = 'sheep';
export const GENERAL_INFO = 'GeneralInfo';

// General Forms
export const STATUS = 'Stats';
export const SUBMIT_FORM = 'SubmitForm';
export const CLEAR_FORM = 'ClearForm';
export const UPDATE = 'Update';
export const DECLINE = 'Decline';
export const SEND = 'Send';
export const SAVE = 'Save';
export const EDIT = 'Edit';
export const CANCEL = 'Cancel';
export const CONFIRM = 'Confirm';
export const ADDRESS_PLACEHOLDER = 'AddressPlaceholder';
export const AMOUNT_PLACEHOLDER = 'AmountPlaceholder';
export const CITY_PLACEHODLDER = 'CityPlaceholder';
export const OWNER_PLACEHOLDER = 'OwnerPlaceholder';
export const DATE = 'Date';
export const CHOOSE_DROPDOWN_OPTION = 'ChooseDropdownOption';

// Firm Form
export const EIK = 'EIK';
export const FIRM_NAME = 'FirmName';
export const FIRM_COUNTRY = 'FirmCountry';
export const FIRM_CITY = 'FirMCity';
export const FIRM_ADDRESS = 'FirmAddress';
export const FIRM_MOL = 'FirmMol';

// Animal Object Form
export const AO_NAME = 'AnimalObjectName';
export const AO_NUMBER = 'AnimalObjectNumber';
export const AO_TRACES = 'AnimalObjectTraces';
export const AO_CITY = 'AnimalObjectCity';
export const AO_ADDRESS = 'AnimalObjectAddress';
export const AO_CAPACITY = 'AnimalObjectCapacity';
export const AO_BUILDINGS = 'AnimalObjectBuildings';
export const AO_BACREAGE = 'AnimalObjectBuildingsAcreage';
export const AO_PASTURES = 'AnimalObjectPastures';
export const AO_PACREAGE = 'AnimalObjectPasturesAcreage';
export const AO_OWNER = 'AnimalObjectOwner';
export const AO_BRANCH = 'AnimalObjectBranch';
export const AO_CATTLE = 'AnimalObjectCattle';
export const AO_SHEEP = 'AnimalObject';

// Enterprise Form
export const ENT_NAME = 'EnterpriseName';
export const ENT_NUMBER = 'EnterpriseNumber';
export const ENT_TYPE = 'EnterpriseType';
export const ENT_TYPE_PLACEHOLDER = 'EnterpriseTypePlaceholder';
export const ENT_OWNER = 'EnterpriseOwner';
export const ENT_ADDRESS = 'EnterpriseAddress';

// Shop Form
export const SHOP_NAME = 'ShopName';
export const SHOP_NUMBER = 'ShopNumber';
export const SHOP_TYPE = 'ShopType';
export const SHOP_OWNER = 'ShopOwner';
export const SHOP_ADDRESS = 'ShopAddress';
export const SHOP_DEFAULT_VALUE = 'ShopDefaultValue';
export const SHOP_SMALL_SHOP = 'ShopSmallShop';
export const SHOP_MTS = 'ShopModernTradingShop';

// Trademark Form
export const TM_NAME = 'TrademarkName';
export const TM_NAME_PLACEHOLDER = 'TrademarkNamePlaceholder';
export const TM_REG = 'TrademarkRegistration';
export const TM_LINK = 'TrademarkLink';
export const TM_LINK_PLACEHOLDER = 'TrademarkLinkPlaceholder';
export const TM_IMAGE = 'TrademarkImage';

// Animal Table
export const CATTLE_TAB = 'CattleTab';
export const SHEEP_TAB = 'SheepTab';
export const ANIMAL_ENTRY = 'Animal Entry';
export const ANIMAL_EXIT = 'AnimalExit';

// Animal Preview
export const SELECTED_ANIMALS_PREVIEW = 'SelectedAnimalsPreview';

// Sell Animals Component
// Steps
export const START = 'Start';
export const WEIGTHS = 'Weights';
export const PRICING = 'Pricing';
// Deal details
export const FIRMS_DETAILS = 'FirmsDetails';
export const TRANSPORT_DETAILS = 'TransportDetails';
export const SALE_INFO = 'SaleInfo';
export const SELLING_FIRM = 'SellingFirm';
export const SELL_FIRM_NAME = 'SellFirmName';
export const SELL_FIRM_EIK = 'SellFirmEIK';
export const SELL_FIRM_MOL = 'SellFirmMOL';
export const BUYING_FIRM = 'BuyingFirm';
export const BUY_FIRM_NAME = 'BuyFirmName';
export const BUY_FIRM_EIK = 'BuyFirmEIK';
export const BUY_FIRM_MOL = 'BuyFirmMOL';
export const QUANTITY_INFO = 'QuantityInfo';
export const VEHICLE_WEIGHT = 'VehicleWeight';
export const EMPTY_VEHICLE_WEIGHT = 'EmptyVehicleWeight';
export const VEHICLE_WEIGHT_PLUS_ANIMALS = 'VehicleWeightPlusAnimals';
export const ANIMAL_WEIGHT = 'AnimalWeight';
export const AVERAGE_WEIGHT = 'AverageWeight';
export const INDIVIDUAL_WEIGHT = 'IndividualWeight';
export const ANIMAL_NET_WEIGHT = 'AnimalNetWeight';
export const AVERAGE_GROUP_NET_WEIGHT = 'AverageGroupNetWeight';
export const AVERAGE_ANIMAL_NET_WEIGHT = 'AverageAnimalNetWeight';
export const FINANCIAL_INFO = 'FinancialInfo';
export const PRICE_PER_KILO = 'PricePerKilo';
export const PRICE_PER_ANIMAL = 'PricePerAnimal';
export const GROUP_TOTAL_PRICE = 'GroupTotalPrice';
export const AVERAGE_ITEM_PRICE = 'AverageItemPrice';
export const TRANSPORTATION_INFO = 'TransportationInfo';
export const TRANSPORTATION_DRIVER = 'TransportationDriver';
export const TRANSPORTATION_DRIVER_PIN = 'TransportationDriverPin';
export const TRANSPORTATION_VEHICLE_REG = 'TransportationVehicleReg';
export const TRANSPORTATION_DATE = 'TransportationDate';
export const TRANSPORTATION_SERIAL_NUMBER = 'TransportationSerialNumber';
export const ARRIVAL_DATE = 'ArrivalDate';
export const DEAL_PRICING = 'DealPricing';
export const ENTER_PRICE_PER_HEAD = 'EnterPricePerHead';
export const ENTER_PRICE_PER_ANIMAL = 'EnterPricePerAnimal';

// Select Animal Opearations
export const ANIMAL_OPERATIONS = 'AnimalOperations';
export const SELECT_OPERATION = 'SelectOperation';
export const SELL_OPERATION = 'SellOperation';
export const VETERINARY_INSPECTION = 'VeterinaryInspection';
export const VETERINARY_INSPECTOR = 'VeterinaryInspector';

// Address Book
export const ADDRESS_BOOK_CONTRAGENTS = 'AddressBookContragents';
export const ADDRESS_BOOK_EMPLOYEES = 'AddressBookEmployees';
export const ADDRESS_BOOK_TRUCKS = 'AddressBookTrucks';
export const ADDRESS_BOOK_FIRMS = 'AddressBookFirms';
export const ADDRESS_BOOK_NAME = 'AddressBookName';
export const ADDRESS_BOOK_ROLE = 'AddressBookRole';
export const ADDRESS_BOOK_PHONE = 'AddressBookPhone';
export const ADDRESS_BOOK_EMAIL = 'AddressBookEmail';
export const ADDRESS_BOOK_STATUS = 'AddressBookStatus';
export const ADDRESS_BOOK_FIRM = 'AddressBookFIrm';
export const ADDRESS_BOOK_FIRM_TYPE = 'AddressBookFirmType';
export const ADD_NEW_EMPLOYEE = 'AddNewEmployee';
export const EDIT_EMPLOYEE = 'EditEmployee';

// Deals
export const DEAL = 'Deal';
export const DEAL_ACCEPT_STATUS = 'DealAcceptStatus';
export const DEAL_REJECT_STATUS = 'DealRejectStatus';
export const DEAL_PARTIAL_ACCEPT_STATUS = 'DealPartialAcceptStatus';
export const DEAL_PENDING_STATUS = 'DealPendingStatus';
export const DEAL_ACCEPT = 'DealAccept';
export const DEAL_ACCEPT_PARTIAL = 'DealAcceptPartial';
export const DEAL_REJECT = 'DealReject';
export const DEAL_STATUS = 'DealStatus';
export const DEAL_NUMBER = 'DealNumber';
export const DEAL_CREATOR = 'DealCreator';
export const DEAL_DATE = 'DealDate';
// DealGrid
export const DEAL_ANIMAL_INFO = 'DealAnimalInfo';
export const DEAL_ANIMAL_WEIGHT = 'DealAnimalWeight';
export const DEAL_ANIMAL_PPH = 'DealAnimalPPH';
export const DEAL_ANIMAL_PPK = 'DealAnimalPPK';
export const DEAL_ANIMAL_PPHK = 'DealAnimalPPHK';
export const DEAL_ANIMAL_ARRIVAL_WEIGHT = 'DealAnimalArrivalWeight';
export const DEAL_ANIMAL_NOTE = 'DealAnimalNote';

// Kill Data
export const KILL_DATA_OPERATION = 'KillDataOption';
export const KILL_DATA_MATADOR = 'KillDataMatador';

// Carcass Quality Grading
export const ANIMAL_SELECTION_PLACEHOLDER = 'AnimalSelectionPlaceholder';
export const CARCASS_QUALITY_GRADE = 'CarcassQualityGrade';
export const GRADED_ANIMAL_IDENTIFICATOR = 'GradedAnimalIdentificator';
export const INSPECTION_DATE = 'InspectionDate';
export const INSPECTOR = 'Inspector';
export const AGE_CLASS = 'AgeClass';
export const UNKNOWN_CLASS = 'UnknownClass';
export const Z_CLASS = 'ZClass';
export const Z_CLASS_TITLE = 'ZClassTitle';
export const A_CLASS = 'AClass';
export const A_CLASS_TITLE = 'AClassTitle';
export const B_CLASS = 'BClass';
export const B_CLASS_TITLE = 'BClassTitle';
export const C_CLASS = 'CClass';
export const C_CLASS_TITLE = 'CClassTitle';
export const D_CLASS = 'DClass';
export const D_CLASS_TITLE = 'DClassTitle';
export const E_CLASS = 'EClass';
export const E_CLASS_TITLE = 'EClassTitle';
export const MUSCLE_CLASS = 'MuscleClass';
export const MUSCLE_CLASSIFICATION = 'MuscleClassification';
export const FAT_CLASS = 'FatClass';
export const FAT_COLOR = 'FatColor';
export const OSSIFI_SCORE = 'OssifiScore';
export const TEETH_FORMULA = 'TeethFormula';
export const MEAT_COLOR = 'MeatColor';
export const CARCASS_YIELD = 'CarcassYield';
export const CARCASS_QUALITY = 'CarcassQuality';
export const FAT_WHITE = 'FatWhite';
export const FAT_CREAMY_WHITE = 'FatCreamyWhite';
export const FAT_SLIGHTLY_YELLOW = 'FatSlightlyYellow';
export const FAT_MODERATELY_YELLOW = 'FatModeratelyYellow';
export const FAT_YELLOW = 'FatYellow';
export const TEETH_NONE = 'TeethNone';
export const CARCASS_QUALITY_PRIME = 'CarcassQualityPrime';
export const CARCASS_QUALITY_CHOICE = 'CarcassQualityChoice';
export const CARCASS_QUALITY_SELECT = 'CarcassQualitySelect';
export const CARCASS_QUALITY_STANDARD = 'CarcassQualityStandard';
export const CARCASS_QUALITY_COMMERCIAL = 'CarcassQualityCommercial';
export const CARCASS_QUALITY_UTILITY = 'CarcassQualityUtility';
export const CARCASS_QUALITY_CUTTER = 'CarcassQualityCutter';
export const CARCASS_QUALITY_CANNER = 'CarcassQualityCanner';

// AnimalCooling
export const ANIMAL_COOLING = 'AnimalCooling';
export const ANIMAL_COOLING_TITLE = 'AnimalCoolingTitle';
export const FIRST_CHECK = 'FirstCheck';
export const SECOND_CHECK = 'SecondCheck';
export const COOLING_START = 'CoolingStart';
export const PH_VALUE = 'CoolingPHValue';
export const TEMPERATURE = 'CoolingTemperature';

// Logs
// Artificial Insemination Log
export const COW_LID = 'CowLID';
export const FERTILIZATION_TYPE = 'FertilizationType';
export const SIRE_LID = 'SireLID';
export const FERTILIZATION_START = 'FertilizationStart';
export const FERTILIZATION_END = 'FertilizationEnd';
export const PREGNANCY_TEST_DATE = 'PregnancyTestDate';
export const TEST_RESULT = 'TestResult';
export const PREGNANCY_STAGE = 'PregnancyStage';
export const EXPECTED_DUE_DATE = 'ExpectedDueDate';
export const PREGNANCY_RESULT_YES = 'PregnancyResultYes';
export const PREGNANCY_RESULT_NO = 'PregnancyResultNo';
export const PREGNANCY_RESULT_UNID = 'PregnancyResultUnid';
export const FERTILIZATION_NATURALLY = 'FertilizationNaturally';
export const FERTILIZATION_ARTIFICIALLY = 'FertilizationArtificially';
// PastureLog
export const ANIMAL_COUNT = 'AnimalCount';
export const HERD_GROUP = 'HerdGroup';
export const PASTURE_NAME = 'PastureName';
export const PASTURE_DATE_OF_ENTRY = 'PastureDateOfEntry';
export const PASTURE_DATE_OF_EXIT = 'PastureDateOfExit';
export const DAYS_SPENT_IN_PASTURE = 'DatesSpentInPasture';
export const PASTURE_ACREAGE = 'PastureAcreage';
export const PASTURE_GRASS_HEIGHT_IN = 'PastureGrassHeightIn';
export const PASTURE_GRASS_HEIGHT_OUT = 'PastureGrassHeightOut';
// PurchasedMedicine
export const PURCHASED_MEDICINE_LOG_TITLE = 'PurchasedMedicineLogTitle';
export const PURCHASED_MEDICINE_LOG_ENTRY_DATE = 'PurchasedMedicineLogEntryDate';
export const PURCHASED_MEDICINE_NAME = 'PurchasedMedicineName';
export const MEDICINE_CATEGORY_NAME = 'MedicineCategoryName';
export const PURCHASED_MEDICINE_AMOUNT = 'PurchasedMedicineAmount';
export const PURCHASED_MEDICINE_DELIVERED_BY = 'PurchasedMedicineDeliveredBy';
export const MEDICINE_WITHDRAW_TITLE = 'MedicineWithdrawTitle';
export const PURCHASED_MEDICINE_WITHDRAW_PERIOD_MILK = 'PurchasedMedicineWithdrawPeriodMilk';
export const PURCHASED_MEDICINE_WITHDRAW_PERIOD_MEAT = 'PurchasedMedicineWithdrawPeriodMeat';
export const PURCHASED_MEDICINE_BATCH_NUMBER = 'PurchasedMedicineBatchNumber';
export const PURCHASED_MEDICINE_EXPIRY_DATE = 'PurchasedMedicineExpiryDate';
export const PURCHASED_MEDICINE_NOTE = 'PurchasedMedicineNote';
// Fodder Log
export const PURCHASED_FODDER_TITLE = 'PurchasedFodderTitle';
export const PRODUCED_FODDER_TITLE = 'ProducedFodderTitle';
export const FODDER_LOG_TITLE = 'FodderLogTitle';
export const FODDER_DATE = 'Fodder Date';
export const FODDER_NAME = 'FodderName';
export const FODDER_CATEGORY = 'FodderCategory';
export const FODDER_BATCH_NUMBER = 'FodderBatchNumber';
export const FODDER_AMOUNT_PRODUCED = 'FodderAmountProduced';
export const FODDER_AMOUNT_BOUGHT = 'FodderAmountBought';
export const FODDER_COURIER = 'FodderCourier';
export const FODDER_NOTE = 'FodderNote';
export const FODDER_SELF_PRODUCED = 'FodderSelfProduced';
export const FODDER_TYPE = 'FodderType';
// FodderCategories
export const UNKNOWN_CATEGORY = 'UnknownCategory';
export const WETFEED_CATEGORY = 'WetfeedCategory';
export const WATER_CATEGORY = 'WaterCategory';
export const OTHER_CATEGORY = 'OtherCategory';
export const LONGFIBER_CATEGORY = 'LongfiberCategory';
export const CONCENTRATE_CATEGORY = 'ConcentrateCategory';
export const ROOTS_CATEGORY = 'RootsCategory';
export const SOFTFIBER_CATEGORY = 'SoftfiberCategory';
export const VITAMINS_CATEGORY = 'VitaminsCategory';
export const LIQUIDS_CATEGORY = 'LiquidsCategory';

// PurchasedMedicine categories
export const ANTIBIOTIC_CATEGORY = 'AntibioticCategory';
export const VACCINE_CATEGORY = 'VaccineCategory';
export const VITAMIN_CATEGORY = 'VitaminCategory';
export const CALCIUM_CATEGORY = 'CalciumCategory';
export const PROBIOTIC_CATEGORY = 'ProbioticCategory';
export const ANITIINFLAMMATORY_CATEGORY = 'AntiinflammatoryCategory';
export const ANTIPARASITIC_CATEGORY = 'AntiparasiticCategory';
export const OINTMENT_CATEGORY = 'OintmentCategory';
export const HORMONAL_CATEGORY = 'HormonalCategory';
export const SUPPLIMENT_CATEGORY = 'SupplimentCategory';

// Mortality log
export const ANIMAL_MORTALITY = 'AnimalMortality';
export const ANIMAL_HEALTH_PROBLEM = 'AnimalHealthProblem';
export const ANIMAL_TERAPY = 'AnimalTerapy';
// Animal Information
export const MALE = 'Male';
export const FEMALE = 'Female';
export const CATTLE_TYPE = 'CattleType';
export const SHEEP_TYPE = 'Sheeptype';
export const ANIMAL_SEX = 'AnimalSex';
export const ANIMAL_BREED = 'AnimalBreed';
export const ANIMAL_AGE = 'AgeInMonths';
export const ANIMAL_LID = 'AnimalLID';

// Ambulatory Log
export const AMBULATORY_NUMBER = 'AmbulatoryNumber';
export const AMBULATORY_DATE = 'AmbulatoryDate';
export const AMBULATORY_ANIM_OBJ = 'AmbulatoryAnimalObj';
export const ANIMAL_TYPE = 'AnimalType';
export const AMBULATORY_CLINICAL_DATA = 'AmbulatoryClinicalData';
export const AMBULATORY_DIAGNOSTIC_TEST = 'AmbulatoryDiagnosticTest';
export const AMBULATORY_DIAGNOSIS = 'AmbulatoryDiagnosis';
export const AMBULATORY_MEDICINES = 'AmbulatoryMedicines';
export const AMBULATORY_DOSE = 'AmbulatoryDose';
export const AMBULATORY_WITHDRAWAL_PERIOD = 'AmbulatoryWithdrawalPeriod';
export const AMBULATORY_DIAGNOSIS_OUT = 'AmbulatoryDiagnosisOout';
export const AMBULATORY_NOTE = 'AmbulatoryNote';

// Chemical Treatement Log
export const CHEMICAL_TREATEMENT_PEST = 'ChemicalTreatementPest';
export const CHEMICAL_TREATEMENT_DOSE = 'ChemicalTreatementDose';
export const CHEMICAL_TREATEMENT_TREATED_AREA = 'ChemicalTreatementTreatedArea';
export const CHEMICAL_TREATEMENT_TECHNIQUE = 'ChemicalTreatementTechnique';
export const CHEMICAL_TREATEMENT_CHEMICAL = 'ChemicalTreatementChemical';
export const CHEMICAL_TREATEMENT_ACTIVE_CHEMICAL = 'ChemicalTreatementActiveChemical';
export const CHEMICAL_TREATEMENT_CONCENTRATE = 'ChemicalTreatementConcentrate';
// Pest Control Log
export const PEST = 'Pest';
export const PEST_DAMAGE_LEVEL = 'PestDamageLevel';
export const DAMAGED_CULTURES = 'DamagedCultures';
export const USED_CHEMICALS = 'UsedChemicals';
export const CHEMICAL_TYPE = 'ChemicalType';
export const METEOROLOGY = 'Meteorology';
export const APPLICATION_METHOD = 'ApplicationMethod';
// Damage Levels
export const DAMAGE_LEVEL_LOW = 'DamageLevelLow';
export const DAMAGE_LEVEL_MEDIUM = 'DamageLevelMedium';
export const DAMAGE_LEVEL_HIGH = 'DamageLevelHight';
export const DAMAGE_LEVEL_TOTAL = 'DamageLevelTotal';

// Used Pesticide Log
export const USED_PESTICIDE_OPERATOR = 'UsedPesticideOperator';
export const USED_PESTICIDE_NAME = 'UsedPesticideName';
export const USED_PESTICIDE_ACTIVE_CHEMICAL = 'UsedPesticideActiveChemical';
export const USED_PESTICIDE_NUMBER = 'UsedPesticideNumber';
export const USED_PESTICIDE_TREATED_CULTURE = 'UsedPesticideTreatedCulture';
export const USED_PESTICIDE_HECTARE_NORM = 'UsedPesticideHectareNorm';
export const USED_PESTICIDE_TREATED_AREA = 'UsedPesticideTreatedArea';
export const USED_PESTICIDE_TOTAL = 'UsedPesticideTotal';
export const USED_PESTICIDE_ENTRY_BAN_PERIOD = 'UsedPesticideEntryBanPeriod';
export const USED_PESTICIDE_MOWING_BAN_PERIOD = 'UsedPesticideMowingBanPeriod';

// Stepper
export const FORWARD = 'Forward';
export const BACKWORD = 'Backword';
export const STEPFROMTOTAL = 'StepFromTotal';

export default {
  en: {
    en_US
  },
  bg: {
    bg_BG
  }
};
