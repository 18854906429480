import { graphql } from 'msw';
import AUTH_CONFIG from '../../auth-variables';
import { PurchasedFodderType, SelfProducedFodderType } from '../../slices/data/FodderLogSliceData';

const randomAmount = () =>
  Math.floor(Math.random() * (1000 - 1) + 10000);

const selfProducedFodder: SelfProducedFodderType[] = [];

const purchasedFodder: PurchasedFodderType[] = [];

const blbTrace = graphql.link(AUTH_CONFIG.apiUrl);

export default [
  // Queries
  blbTrace.query('getSelfProducedFodder', (_, res, ctx) =>
    res(
      ctx.data({
        getProducedFodder: selfProducedFodder
      })
    )),

  blbTrace.query('getPurchasedFodder', (_, res, ctx) =>
    res(
      ctx.data({
        getPurchasedFodder: purchasedFodder
      })
    )),

  // Mutations
  blbTrace.mutation('saveProducedFodderEntry', (req, res, ctx) => {
    const { fodderLogEntryInput } = req.variables;
    // console.log('fodderLogEntryInput: ', fodderLogEntryInput);
    const fodderEntry = { id: randomAmount(), ...fodderLogEntryInput };
    selfProducedFodder.push(fodderEntry);
    // console.log('fodderEntry: ', fodderEntry);
    return res(
      ctx.data({
        saveProducedFodderEntry: fodderEntry
      })
    );
  })
];
