import {
  A_CLASS,
  A_CLASS_TITLE,
  B_CLASS,
  B_CLASS_TITLE,
  CARCASS_QUALITY_CANNER,
  CARCASS_QUALITY_CHOICE,
  CARCASS_QUALITY_COMMERCIAL,
  CARCASS_QUALITY_CUTTER,
  CARCASS_QUALITY_PRIME,
  CARCASS_QUALITY_SELECT,
  CARCASS_QUALITY_STANDARD,
  CARCASS_QUALITY_UTILITY,
  C_CLASS,
  C_CLASS_TITLE,
  D_CLASS,
  D_CLASS_TITLE,
  E_CLASS,
  E_CLASS_TITLE,
  FAT_CREAMY_WHITE,
  FAT_MODERATELY_YELLOW,
  FAT_SLIGHTLY_YELLOW,
  FAT_WHITE,
  FAT_YELLOW,
  TEETH_NONE,
  UNKNOWN_CLASS,
  Z_CLASS,
  Z_CLASS_TITLE
} from '../../languages/languages';

export const ageClass: { label: string; value: string; title?: string }[] = [
  {
    label: UNKNOWN_CLASS,
    value: 'UNKNOWN'
  },
  {
    label: Z_CLASS,
    value: 'Z',
    title: Z_CLASS_TITLE
  },
  {
    label: A_CLASS,
    value: 'A',
    title: A_CLASS_TITLE
  },
  {
    label: B_CLASS,
    value: 'B',
    title: B_CLASS_TITLE
  },
  {
    label: C_CLASS,
    value: 'C',
    title: C_CLASS_TITLE
  },
  {
    label: D_CLASS,
    value: 'D',
    title: D_CLASS_TITLE
  },
  {
    label: E_CLASS,
    value: 'E',
    title: E_CLASS_TITLE
  }
];

export const seuropMuscleClass: { label: string; value: string }[] = [
  {
    label: 'S',
    value: 'S'
  },
  {
    label: 'E',
    value: 'E'
  },
  {
    label: 'U',
    value: 'U'
  },
  {
    label: 'R',
    value: 'R'
  },
  {
    label: 'O',
    value: 'O'
  },
  {
    label: 'P',
    value: 'P'
  }
];

export const muscleClassification: { label: string; value: string }[] = [
  {
    label: '-',
    value: '-'
  },
  {
    label: '=',
    value: '='
  },
  {
    label: '+',
    value: '+'
  }
];

export const fatClass: { label: number; value: number }[] = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 }
];

export const muscleClass: { label: number; value: number }[] = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 }
];

export const fatColor: { label: string; value: string }[] = [
  { label: FAT_WHITE, value: 'WHITE' },
  { label: FAT_CREAMY_WHITE, value: 'CREAMYWHITE' },
  { label: FAT_SLIGHTLY_YELLOW, value: 'SLIGHTLYYELLOW' },
  { label: FAT_MODERATELY_YELLOW, value: 'MODERATELYYELLOW' },
  { label: FAT_YELLOW, value: 'YELLOW' }
];

export const ossifiScore: { label: number; value: number }[] = [
  { label: 100, value: 100 },
  { label: 110, value: 110 },
  { label: 120, value: 120 },
  { label: 130, value: 130 },
  { label: 140, value: 140 },
  { label: 150, value: 150 },
  { label: 160, value: 160 },
  { label: 170, value: 170 },
  { label: 180, value: 180 },
  { label: 190, value: 190 },
  { label: 200, value: 200 },
  { label: 300, value: 300 },
  { label: 400, value: 400 },
  { label: 500, value: 500 }
];

export const teethFormula: { label: string | number; value: string }[] = [
  { label: UNKNOWN_CLASS, value: 'UNKNOWN' },
  { label: TEETH_NONE, value: 'NONE' },
  { label: 2, value: 'TWO' },
  { label: 4, value: 'FOUR' },
  { label: 6, value: 'SIX' },
  { label: 8, value: 'EIGHT' }
];

export const meatColor: { label: number; value: number }[] = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 }
];

export const carcassYield: { label: number; value: number }[] = [
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 }
];

export const carcassQuality: { label: string; value: string }[] = [
  { label: UNKNOWN_CLASS, value: 'UNKNOWN' },
  { label: CARCASS_QUALITY_PRIME, value: 'PRIME' },
  { label: CARCASS_QUALITY_CHOICE, value: 'CHOICE' },
  { label: CARCASS_QUALITY_SELECT, value: 'SELECT' },
  { label: CARCASS_QUALITY_STANDARD, value: 'STANDARD' },
  { label: CARCASS_QUALITY_COMMERCIAL, value: 'COMMERCIAL' },
  { label: CARCASS_QUALITY_UTILITY, value: 'UTILITY' },
  { label: CARCASS_QUALITY_CUTTER, value: 'CUTTER' },
  { label: CARCASS_QUALITY_CANNER, value: 'CANNER' }
];
