import { Button } from '@progress/kendo-react-buttons';
import { useLocalization } from '@progress/kendo-react-intl';
import { Card, CardBody, TabStrip, TabStripSelectEventArguments, TabStripTab } from '@progress/kendo-react-layout';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ANIMALS } from '../../languages/keys/movements.keys';
import { AnimalInButcheryGridModel, IAnimalInButchery, IBatch, IBatchAnimal } from '../../slices/data/ButcheryProcessSliceData';
import AnimalsTab from './AnimalsTab';
import BatchTab from './BatchTab';
import CarcasesWeightsTab from './CarcasesWeightsTab';
import PhTTab from './PhTTab';
import SeuropTab from './SeuropTab';
import WarehouseTab from './WarehouseTab';

const ButcheryProcessTabs = () => {
  const $t = useLocalization();
  const [selected, setSelected] = useState<number>(0);
  const [lockAnimals, setLockAnimals] = useState(false);
  const [finished, setFinished] = useState(false);
  const history = useHistory();

  const [batch, setBatch] = useState<IBatch>({
    date: new Date(),
    number:
      new Date().getFullYear().toString() +
      (new Date().getMonth() + 1).toString() +
      new Date().getDate().toString(),
    enterpriseCarcaseNextNumber: 1,
  } as IBatch);

  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
    setLockAnimals(true);
  };

  const onAnimalsSelected = (
    animals: AnimalInButcheryGridModel[],
    enterpriseNumber: string,
    enterpriseId: number
  ) => {
    const tmp = {
      ...batch,
      enterpriseNumber,
      enterpriseId,
    };
    tmp.animals = animals.map(
      (a: IAnimalInButchery) =>
        ({
          ...a,
          cuts: [],
          cutsCountId: 0,
          cutsTypeId: 0,
          firstPhT: {},
          carcaseInternalNumber: 0,
          carcaseWeight: 0,
          carcaseWeightCold: 0,
          randeman: 0,
          uneceCode: '',
        } as IBatchAnimal)
    );
    setBatch(tmp);
    handleSelect({ selected: 1 });
  };

  const onNext = () => {
    setSelected(selected + 1);
  };

  const onFinished = () => {
    setFinished(true);
  };

  const gotoMainPage = () => {
    history.push('/processor/butchery-process');
  };

  return (
    <>
      {!finished && (
        <TabStrip selected={selected} onSelect={handleSelect}>
          <TabStripTab title={$t.toLanguageString(ANIMALS, '')} disabled={lockAnimals}>
            <AnimalsTab onNext={onAnimalsSelected} />
          </TabStripTab>
          <TabStripTab title='Тегла' disabled={!lockAnimals}>
            <BatchTab batch={batch} onNext={onNext} />
          </TabStripTab>
          <TabStripTab title='Трупно' disabled={!lockAnimals}>
            <CarcasesWeightsTab batch={batch} onNext={onNext} />
          </TabStripTab>
          <TabStripTab title='SEUROP' disabled={!lockAnimals}>
            <SeuropTab batch={batch} onNext={onNext} />
          </TabStripTab>
          <TabStripTab title='pH + t' disabled={!lockAnimals}>
            <PhTTab batch={batch} onNext={onNext} />
          </TabStripTab>
          <TabStripTab title='Склад' disabled={!lockAnimals}>
            <WarehouseTab batch={batch} onNext={onFinished} />
          </TabStripTab>
        </TabStrip>
      )}
      {finished && (
        <div className='row'>
          <div className='col-12 col-md-6 offset-md-3'>
            <Card>
              <CardBody className='d-flex'>
                {/* <CardTitle>Card Title</CardTitle>
              <CardTitle>Card Subtitle</CardTitle> */}
                <div className='d-flex justify-content-center align-items-center pl-l pr-l text-success'>
                  <i className='far fa-check-circle fa-4x text-success' />
                </div>
                <div className='d-flex flex-column justify-content-center align-items-center'>
                  <h2>Успешно създадохте нова партида </h2>
                  <p>Хронология на вписаните партиди може да видите на началния екран</p>

                  <Button look='outline' onClick={gotoMainPage}>
                    Към началния екран
                  </Button>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      )}
    </>
  );
};

export default ButcheryProcessTabs;
