import React, { useEffect, useState } from 'react';
import { MultiSelectChangeEvent } from '@progress/kendo-react-dropdowns';
import { Dialog } from '@progress/kendo-react-dialogs';
import { useLocalization } from '@progress/kendo-react-intl';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import {
  // editEmployee,
  getAllABEmployees,
  saveEmployee,
  selectAddressBookEmployees
} from '../../slices/EmployeesSlice';
import { EmployeesABType, SaveEmployeeABType } from '../../slices/data/EmployeesSliceData';
import EmployeeForm from '../../components/EmployeeForm/EmployeeForm';
import { getFirmNameAndID } from '../../slices/FirmSlice';
import EmployeeGrid from '../../components/EmployeeGrid/EmployeeGrid';
import { getAllUserRoles } from '../../slices/UserSlice';
import { UserRoleMultiSelectType } from '../../slices/data/UserSliceData';
import { ADD_NEW_EMPLOYEE, EDIT_EMPLOYEE } from '../../languages/languages';
import GrantAccess from '../GrantAccess/GrantAccess';

const REQUIRED_ROLES: string[] = ['Admin', 'Holding Owner', 'Farm Manager', 'Farm Veterinary Inspector'];
const CU_ROLES: string[] = ['Admin', 'Holding Owner', 'Farm Manager'];

const AddressBookEmployeesContainer = () => {
  const localizationService = useLocalization();
  const dispatch = useAppDispatch();
  const employees = useAppSelector(selectAddressBookEmployees);

  const [userRoles, setUserRoles] = useState<UserRoleMultiSelectType[]>([]);

  const handleRoleSelection = ({ target }: MultiSelectChangeEvent) => {
    setUserRoles(target.value);
  };

  useEffect(() => {
    const awaitData = () => {
      dispatch(getAllABEmployees());
      dispatch(getFirmNameAndID());
    };
    awaitData();
  }, []);

  // Add New Employee state and functions
  const [openAddForm, setOpenAddForm] = useState(false);

  const submitNewEmployee = (formData: SaveEmployeeABType) => {
    const employeeData: SaveEmployeeABType = {
      email: formData.email,
      roles: userRoles.map((usrRole) =>
        usrRole.value),
      userName: formData.userName
    };
    dispatch(saveEmployee(employeeData));
    setOpenAddForm(false);
    setUserRoles([]);
  };

  const handleCancelAdd = () => {
    setOpenAddForm(false);
  };

  const addNew = () => {
    setOpenAddForm(true);
    dispatch(getAllUserRoles());
  };

  // Edit form state and functions
  const [openEditForm, setOpenEditForm] = useState(false);
  const [editItem, setEditItem] = useState<EmployeesABType>(null);
  const enterEdit = (item: EmployeesABType) => {
    setOpenEditForm(true);
    setEditItem(item);
    setUserRoles(item?.roles.map((role) =>
      ({
        value: role.id,
        label: role.name
      })));
    dispatch(getAllUserRoles());
  };

  const handleCancelEdit = () => {
    setOpenEditForm(false);
  };

  const handleEdit = (formData: SaveEmployeeABType) => {
    const updatedEmployee: SaveEmployeeABType = {
      userId: formData.userId,
      userName: formData.userName,
      email: formData.email,
      roles: userRoles.map((role) =>
        (role.value))
    };
    console.log(updatedEmployee);
    // dispatch(editEmployee(formData));
    setOpenEditForm(false);
  };

  return (
    <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
      <EmployeeGrid
        addEmployee={addNew}
        enterEdit={enterEdit}
        data={employees}
      />
      <GrantAccess haveRoles='some' requiredRoles={CU_ROLES}>
        {openAddForm && (
        <Dialog
          title={localizationService.toLanguageString(ADD_NEW_EMPLOYEE, '')}
          onClose={handleCancelAdd}
        >
          <EmployeeForm
            setUserRoles={handleRoleSelection}
            cancel={handleCancelAdd}
            onSubmit={submitNewEmployee}
          />
        </Dialog>
        )}
        {openEditForm && (
        <Dialog
          title={localizationService.toLanguageString(EDIT_EMPLOYEE, '')}
          onClose={handleCancelEdit}
        >
          <EmployeeForm
            item={editItem}
            selectedRoles={userRoles}
            setUserRoles={handleRoleSelection}
            cancel={handleCancelEdit}
            onSubmit={handleEdit}
          />
        </Dialog>
        )}
      </GrantAccess>
    </GrantAccess>
  );
};

export default AddressBookEmployeesContainer;
