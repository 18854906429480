import { State } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { getter } from '@progress/kendo-react-common';
import { GridCellProps, GridDataStateChangeEvent, GridHeaderSelectionChangeEvent } from '@progress/kendo-react-grid';
import React, { useCallback, useEffect, useState } from 'react';

import { ColumnType } from '../../slices/data/AnimalColumnsSliceData';
import { IBatch, IBatchAnimal, IPhT } from '../../slices/data/ButcheryProcessSliceData';
import PhTModal from '../ButcheryProcess/PhTModal';
import { CattleBreedCell } from '../CustomGridCells/CustomGridCells';
import AnimalTable from '../SelectableGrid/SelectableGrid';

const DATA_ITEM_KEY: string = 'animalId';
const idGetter = getter(DATA_ITEM_KEY);

interface AnimalGridProps {
  onSelect?: (items: IBatchAnimal[]) => void;
  animals: IBatchAnimal[];
  batch: IBatch;
  // butcheredDate: Date;
}

const cattleColumns: ColumnType[] = [
  {
    title: 'Труп вътрешен №',
    field: 'carcaseInternalNumber',
    show: true,
    filter: 'text',
  } as ColumnType,
  {
    title: 'Животно №',
    field: 'lID',
    show: true,
    filter: 'text',
  } as ColumnType,
  {
    title: 'Пол',
    field: 'sex',
    show: true,
    filter: 'text',
  } as ColumnType,
  {
    title: 'Дата вход в склад',
    field: 'firstPhT.date',
    show: true,
    filter: 'date',
    format: '{0:dd.MM.yyy HH:mm}',
  } as ColumnType,
  {
    title: 'Начална t (℃)',
    field: 'firstPhT.temp',
    show: true,
    filter: 'numeric',
  } as ColumnType,
  {
    title: 'Начално pH',
    field: 'firstPhT.ph',
    show: true,
    filter: 'numeric',
  } as ColumnType,
] as ColumnType[];

const PhTGrid = ({ onSelect = undefined, animals, batch }: AnimalGridProps) => {
  const [items, setItems] = useState<IBatchAnimal[]>(animals);

  useEffect(() => {
    setItems(animals);
  }, []);

  // useEffect(() => {
  //   setItems(items.map((item) => ({ ...item, butcheredDate })));
  // }, [butcheredDate]);

  // Grid States
  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0,
  });

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  };

  // Select animals
  // Local state for the grid's checkbox that will indicate
  // the state of the checkbox
  const [selectedState, setSelectedState] = useState<{
    [id: string]: boolean | number[];
  }>({});

  // Local state for the selected animals, that will be previewed in
  // the next step of the logic.
  // const [selectedAnimals, setSelectedAnimals] = useState<AnimalType[]>([]);
  const [allSelValue, setAllSelValue] = useState<boolean>(false);

  // Check/Uncheck row in the grid
  // Use a callback so that the function is not fully
  // rerendered every time the component re-renders.
  // It should only update if there are changes to the
  // selectedState local state
  const onSelectionChange = useCallback(async () => {}, [selectedState]);

  // Set all page elements to be checked
  const onHeaderSelectionChange = ({
    dataItems,
    syntheticEvent,
  }: GridHeaderSelectionChangeEvent) => {
    const checkboxElement: any = syntheticEvent.target;
    const { checked } = checkboxElement;
    const newSelectedState = {};
    const selAnimals: any[] = [];
    setAllSelValue(!allSelValue);
    dataItems.forEach(async (animal) => {
      newSelectedState[idGetter(animal)] = checked;
      if (newSelectedState[idGetter(animal)]) {
        selAnimals.push(animal);
      }
    });
    setSelectedState(newSelectedState);
    // setSelectedAnimals(selAnimals);
    if (onSelect) {
      onSelect(selAnimals);
    }
  };

  // Handle Menu visibility
  const handleMenuVisibility = () => {
    console.log('menu visibility changed');
  };

  const [showEdit, setShowEdit] = useState(false);
  const [editItem, seteditItem] = useState<IBatchAnimal>(null);
  const onShowEdit = (item: IBatchAnimal) => {
    seteditItem(item);
    setShowEdit(true);
  };

  const cancelEdit = () => {
    seteditItem(null);
    setShowEdit(false);
  };

  const handleEdit = (animalId: number, item: IPhT, applyForAll: boolean) => {
    const index = items.findIndex((i) => i.animalId === animalId);

    if (index !== -1) {
      items[index].firstPhT = { ...item };

      if (applyForAll) {
        items.forEach((i) => {
          if (i.firstPhT) {
            i.firstPhT.date = item.date;
          } else {
            i.firstPhT = { date: item.date } as IPhT;
          }
        });
      }

      setItems([...items]);
      onSelect(items);
    }

    cancelEdit();
  };

  const actionCell = ({ dataItem, field }: GridCellProps) => {
    console.log(dataItem, field);
    return (
      <td className='text-center'>
        <Button
          data-testid='editButton'
          type='button'
          className='k-button k-secondary mr-l'
          icon='edit'
          look='outline'
          onClick={() => onShowEdit(dataItem)}
        />
      </td>
    );
  };

  return (
    <>
      <AnimalTable
        data={items}
        dataState={dataState}
        dataStateChange={dataStateChange}
        handleMenuVisibility={handleMenuVisibility}
        onHeaderSelectionChange={onHeaderSelectionChange}
        onSelectionChange={onSelectionChange}
        selectedState={selectedState}
        setDataState={setDataState}
        stateColumns={cattleColumns}
        cell={CattleBreedCell}
        DATA_ITEM_KEY='animalId'
        showToolbar={false}
        showColumns={false}
        actionCell={actionCell}
        selectable={false}
      />
      {showEdit && (
        <PhTModal
          item={editItem}
          batchNumber={batch.number}
          onSubmit={handleEdit}
          cancelEdit={cancelEdit}
        />
      )}
    </>
  );
};

PhTGrid.defaultProps = {
  onSelect: undefined,
};

export default PhTGrid;
