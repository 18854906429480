/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/StoreHooks';
import {
  DealsHistoryResponseType,
  DealHistoryType,
  GET_DEALS_HISTORY,
  GET_DEAL_INFO,
  DealInformationType,
  SingleDealId,
  ReceivedAnimalsType,
  SingleDealType,
  DealActionType,
  DealActionInputType,
  UPDATE_SALE_EVENT,
  RejectedOfferType,
  RejectedOfferInputType
} from './data/DealsSliceData';
import {
  createAsyncThunkGeneric,
  createAsyncThunkGenericVoid
} from './GenericThunks';

type DealsSliceType = {
  allDeals: DealHistoryType[];
  singleDeal: SingleDealType;
  acceptedAnimals: number[];
  dealsLoading: boolean;
};

const initialState: DealsSliceType = {
  allDeals: [],
  singleDeal: null,
  acceptedAnimals: [],
  dealsLoading: false
};

// Async thunks
export const getDealsHistory = createAsyncThunkGenericVoid(
  'dealsSlice/getDealsHistory',
  async ({ client }) => {
    const response = await client.query<void, DealsHistoryResponseType>(
      'holdingEvents',
      GET_DEALS_HISTORY
    );
    return response?.items || [];
  }
);

export const getSingleDealInfo = createAsyncThunkGeneric<
  number,
  DealInformationType
>('dealsSlice/getSingleDealInfo', async ({ client, input }) => {
  return await client.query<SingleDealId, DealInformationType>(
    'saleEvent',
    GET_DEAL_INFO,
    { id: input }
  );
});

export const acceptAllAnimals = createAsyncThunkGeneric<
  DealActionType,
  DealHistoryType
>('dealsSlice/acceptAllAnimals', async ({ client, input }) => {
  return await client.mutate<DealActionInputType, DealHistoryType>(
    'saleEvent',
    UPDATE_SALE_EVENT,
    { saleInput: input }
  );
});

export const rejectAllAnimals = createAsyncThunkGeneric<
  RejectedOfferType,
  RejectedOfferType
>('dealsSlice/rejectAllAnimals', async ({ client, input }) => {
  return await client.mutate<RejectedOfferInputType, RejectedOfferType>(
    'saleEvent',
    UPDATE_SALE_EVENT,
    { saleInput: input }
  );
});

export const dealsSlice = createSlice({
  name: 'dealsSlice',
  initialState,
  reducers: {
    setReceivedAnimals: (state, { payload }) => {
      state.singleDeal.animalWeights = payload;
    },
    setDealData: (state, { payload }) => {
      state.singleDeal.arrivalDate = payload;
    },
    setAcceptedAnimals: (state, { payload }) => {
      state.acceptedAnimals = payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDealsHistory.pending, (state, { payload }) => {
        state.dealsLoading = true;
      })
      .addCase(getDealsHistory.fulfilled, (state, { payload }) => {
        state.dealsLoading = false;
        // This is required, as the database returns the following array
        // [{saleEvent: {}}, {saleEvent: {}}] instead of {saleEvent: [{},{},{}]}
        state.allDeals = payload.map((event) => (event?.saleEvent));;
      })
      .addCase(getSingleDealInfo.pending, (state) => {
        state.dealsLoading = true;
      })
      .addCase(getSingleDealInfo.fulfilled, (state, { payload }) => {
        state.dealsLoading = false;
        // This is required so that the accepted animalWeights array
        // and included objects, can be properly re-mapped,
        // in order to display them in the DealInformationGrid
        const deal: SingleDealType = {
          ...payload,
          animalWeights: payload?.animalWeights.map((animal) => ({
            ...animal.animal,
            animalWeight: animal.weight,
            arrivalNote: animal.comment
          }))
        };
        state.singleDeal = deal;
      })
      .addCase(acceptAllAnimals.pending, (state) => {
        state.dealsLoading = true;
      })
      .addCase(acceptAllAnimals.fulfilled, (state, { payload }) => {
        state.dealsLoading = false;
        const updatedDealIndex = state.allDeals.indexOf(payload);
        state.allDeals[updatedDealIndex] = payload;
      });
  }
});

// Actions
export const { setReceivedAnimals, setDealData, setAcceptedAnimals } =
  dealsSlice.actions;
// Selectors
export const selectDealsHistory = (state: RootState) => state.deals.allDeals;
export const selectDealsLoadingState = (state: RootState) =>
  state.deals.dealsLoading;
export const selectSingleDeal = (state: RootState) => state.deals.singleDeal;
export const selectAcceptedAnimals = (state: RootState) =>
  state.deals.acceptedAnimals;

// Export reducer
export default dealsSlice.reducer;
