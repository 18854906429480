import { gql } from '@apollo/client';

import { AnimalType } from './AnimalSliceData';
import { INomenclatureData } from './NomenclatureData';

export interface IRegistrationData {
  animalsType: string;
  animalsCount: number;
  age: number;
  gender: string;
  acquisitionDate: Date;
  acquisitionType: string;
  prefix: string;
  idNumber: string;
  cattleBreed: string;
  sheepBreed: string;
  color: string;
  firmId: number;
  animalObjectId: number;
}

export interface IAnimalRegistrationData extends AnimalType {
  damLID: string;
  acquisitionDate: Date;
  acquisitionType: string;
  firmId: number;
  animalObjectId: number;
  breed: string;
  color: string;
  isCastrated: boolean;
}

export interface IAnimalRegistrationGridData extends IAnimalRegistrationData {
  modified: boolean,
  type: string
}

export type SaveRegistrationData = {
  registrationEventInput: {
    animals: IAnimalRegistrationData[]
  }
};

export interface UnregisterAnimal extends AnimalType {
  reason: INomenclatureData;
}

export interface UnregisterInput {
  unregisterEventInput: UnregisterInputData
}

export interface UnregisterInputData {
  animalObjectId: number;
  unregisterEventAnimals: [
    {
      animalId: number;
      reasonId: number;
    }
  ]
}

export const SAVE_REGISTRATIONS = gql`
  mutation addRegistration($registrationEventInput: registrationEventInput) {
    addRegistration(registrationEventInput: $registrationEventInput) {
      id
    }
  }
`;

export const UNREGISTER = gql`
  mutation unregisterAnimals($unregisterEventInput: unregisterEventInput){
    unregisterAnimals(unregisterEventInput: $unregisterEventInput) {
      animalObjectId,
      unregisterEventAnimals {
        animalId,
        reasonId
      }
    }
  }
`;
