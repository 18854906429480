/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/StoreHooks';
import {
  GET_HOLDING,
  HoldingProfile,
} from './data/HoldingSliceData';
import {
  createAsyncThunkGenericVoid,
} from './GenericThunks';

type HoldingState = {
  holding: HoldingProfile;
  isLoading: boolean;
};

const initialState: HoldingState = {
  holding: null,
  isLoading: false
};

// Async Thunks

export const getAllTypes = createAsyncThunkGenericVoid(
  'holding/getAllTypes',
  async ({ client }) =>
    client.query<void, HoldingProfile>(
      'holding',
      GET_HOLDING
    )
);

// Slice
const HoldingSlice = createSlice({
  name: 'holding',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllTypes.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllTypes.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.holding = payload;
      });
  }
});

// Selectors
export const selectHoldingProfile = (state: RootState) =>
  state.holding.holding;
export const selectLoading = (state: RootState) =>
  state.holding.isLoading;

// Export Reducer
export default HoldingSlice.reducer;
