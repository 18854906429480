import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { useLocalization } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import CattleBreeds from '../../features/FarmAnimalTableCattle/cattleBreeds';
import SheepBreeds from '../../features/FarmAnimalTableSheep/sheepBreeds';
import { DECLINE, FEMALE, MALE, UPDATE } from '../../languages/languages';
import { getAnimalObjectsByFirm, selectAnimalObjects } from '../../slices/AnimalObjectSlice';
import { AnimalSexEnum, AnimalTypeEnum } from '../../slices/data/AnimalSliceData';
import { AvailableFirmsType } from '../../slices/data/FirmSliceData';
import { IAnimalRegistrationData, IAnimalRegistrationGridData } from '../../slices/data/MovementSliceData';
import { INomenclatureData, INomenclatureResponceData, NomenclatureNames } from '../../slices/data/NomenclatureData';
import { getActiveFirmNameAndID, selectAvailableFirms } from '../../slices/FirmSlice';
import { getNomenclatureValues } from '../../slices/NomenclatureSlice';
import { FormDatePicker, FormDropDownList, FormInput, FormSwitch } from '../FormComponents/FormComponents';

type FirmEditFormProps = {
  item: IAnimalRegistrationGridData;
  cancelEdit: () => void;
  onSubmit: (formData: IAnimalRegistrationData) => void;
};
const FirmEditForm = ({
  item,
  cancelEdit,
  onSubmit
}: FirmEditFormProps) => {
  const localizationService = useLocalization();
  const title = `Редакция на "${item.lID}"`;
  const cattleBreeds = [];
  const sheepBreeds = [];
  const dispatch = useAppDispatch();
  const animalObjects = useAppSelector(selectAnimalObjects);
  const firms = useAppSelector(selectAvailableFirms);
  const [firm, setFirm] = useState<AvailableFirmsType>();

  const onFirmChange = (e) => {
    setFirm(e.value);
  };

  useEffect(() => {
    if (firm) {
      const awaitData = async () => {
        await dispatch(getAnimalObjectsByFirm({ firmId: firm.firmId, active: true }));
      };
      awaitData();
    }
  }, [firm]);

  useEffect(() => {
    const awaitData = async () => {
      await dispatch(getActiveFirmNameAndID());
    };
    awaitData();
  }, []);

  const animalSex = [
    {
      value: AnimalSexEnum.FEMALE,
      label: localizationService.toLanguageString(FEMALE, '')
    },
    {
      value: AnimalSexEnum.MALE,
      label: localizationService.toLanguageString(MALE, '')
    }
  ];

  const acquisitionTypes = [
    {
      value: 'unkown',
      label: 'Неустановено'
    },
    {
      value: 'purchase',
      label: 'Покупка'
    },
    {
      value: 'purchase|trace',
      label: 'Покупка | Trace |'
    },
    {
      value: 'ownProduction',
      label: 'Собствено производство'
    }
  ];

  Object.keys(CattleBreeds).forEach((val) => {
    cattleBreeds.push({
      value: val,
      label: CattleBreeds[val]
    });
  });

  Object.keys(SheepBreeds).forEach((val) => {
    sheepBreeds.push({
      value: val,
      label: SheepBreeds[val]
    });
  });

  const [colors, setColors] = useState([] as INomenclatureData[]);

  useEffect(() => {
    dispatch(getNomenclatureValues(NomenclatureNames.cattleColor)).then((response) => {
      setColors((response.payload as INomenclatureResponceData).values);
    });
  }, []);

  return (
    <Dialog title={title} onClose={cancelEdit}>
      <Form
        onSubmit={onSubmit}
        initialValues={item}
        render={(formRenderProps) =>
        (
          <FormElement id='registrationForm'>
            <div className='row'>
              <div className='col-12 col-md-6 pl-l pr-l'>
                <Field
                  id='lID'
                  name='lID'
                  component={FormInput}
                  label='ВЕТИС Марка №'
                  value={item.lID}
                />
                <Field
                  id='cattleType'
                  name='type'
                  component={FormInput}
                  label='Вид'
                  value={item.type}
                  disabled
                />
                <Field
                  id='sex'
                  name='sex'
                  label='Пол на животните'
                  component={FormDropDownList}
                  data={animalSex}
                  textField='label'
                  dataItemKey='value'
                />
                <Field
                  id='isCastrated'
                  name='isCastrated'
                  label='Кастрирано'
                  component={FormSwitch}
                  onLabel='Да'
                  offLabel='Не'
                  className='test'
                />
                {item.animalType === AnimalTypeEnum.CATTLE
                  ? (
                    <Field
                      id='cattleBreed'
                      name='cattleBreed'
                      label='Порода говедо'
                      component={FormDropDownList}
                      data={cattleBreeds}
                      textField='label'
                      dataItemKey='value'
                    />
                  )
                  : (
                    <Field
                      id='sheepBreed'
                      name='sheepBreed'
                      label='Порода овце'
                      component={FormDropDownList}
                      data={sheepBreeds}
                      textField='label'
                      dataItemKey='value'
                    />
                  )}
                <Field
                  id='color'
                  name='color'
                  label='Цвят'
                  component={FormDropDownList}
                  data={colors}
                  textField='text'
                  dataItemKey='id'
                />
              </div>
              <div className='col-12 col-md-6 pl-l pr-l'>
                {/* <Field
                  id='age'
                  name='age'
                  label='Възраст в месеци'
                  component={FormInput}
                  format='n0'
                /> */}
                <Field
                  id='dateOfBirth'
                  name='dateOfBirth'
                  label='Дата на раждане'
                  component={FormDatePicker}
                  format='dd-MM-yyyy'
                />
                <Field
                  id='acquisitionDate'
                  name='acquisitionDate'
                  label='Дата на придобиване'
                  component={FormDatePicker}
                  format='dd-MM-yyyy'
                />
                <Field
                  id='damLID'
                  name='damLID'
                  component={FormInput}
                  label='ВЕТИС марка на майката'
                  value={item.damLID}
                />
                <Field
                  id='firmId'
                  name='firmId'
                  label='Собственик'
                  component={FormDropDownList}
                  data={firms}
                  textField='firmName'
                  dataItemKey='firmId'
                  onChange={onFirmChange}
                  value={firm}
                />
                <Field
                  id='animalObjectId'
                  name='animalObjectId'
                  label='Ж.О.'
                  component={FormDropDownList}
                  data={animalObjects}
                  textField='animalObjectName'
                  dataItemKey='animalObjectId'
                />
                <Field
                  id='acquisitionType'
                  name='acquisitionType'
                  label='Вид придобиване'
                  component={FormDropDownList}
                  data={acquisitionTypes}
                  textField='label'
                  dataItemKey='value'
                />
              </div>
            </div>
            <fieldset className='k-form-buttons'>
              <Button
                name='FirmAdd'
                type='submit'
                title={localizationService.toLanguageString(UPDATE, '')}
                primary
                disabled={!formRenderProps.allowSubmit}
                icon='save'
              >
                {localizationService.toLanguageString(UPDATE, '')}
              </Button>
              <Button
                name='FirmCancel'
                type='submit'
                title={localizationService.toLanguageString(DECLINE, '')}
                primary
                look='outline'
                onClick={cancelEdit}
                icon='cancel'
              >
                {localizationService.toLanguageString(DECLINE, '')}
              </Button>
            </fieldset>
          </FormElement>
        )}
      />
    </Dialog>
  );
};

export default FirmEditForm;
