import { Grid, GridColumn, GridSortChangeEvent } from '@progress/kendo-react-grid';
import React, { useEffect, useState } from 'react';
import { orderBy, SortDescriptor } from '@progress/kendo-data-query';
import { BatchPublicData } from '../../services/public.service';

const initialSort: Array<SortDescriptor> = [{ field: 'ProductName', dir: 'asc' }];

const IncomingBatchesTable = () => {
  const [batches, setBatches] = useState([] as BatchPublicData[]);
  const [sort, setSort] = useState(initialSort);

  useEffect(() => {
    const data = [
      {
        batchNumber: '202303107',
        bоrnIn: '4021-0031 (BG)',
        raisedIn: '4021-0031 (BG)',
        processedIn: 'BG1201014',
        corpseClass: 'U+ 4',
        weight: 90,
        UNECECode: '10 1010 00153201040050',
        deliveryDate: new Date(2023, 0, 23),
      } as BatchPublicData,
      {
        batchNumber: '202303107',
        bоrnIn: '4021-0031 (BG)',
        raisedIn: '4021-0031 (BG)',
        processedIn: 'BG1201014',
        corpseClass: 'U+ 4',
        weight: 60,
        UNECECode: '10 1063 00153201040050',
        deliveryDate: new Date(2023, 0, 23),
      } as BatchPublicData,
    ];
    setBatches(data);
  }, []);

  return (
    <Grid
      data={orderBy(batches, sort)}
      sortable={true}
      sort={sort}
      onSortChange={(e: GridSortChangeEvent) => {
        setSort(e.sort);
      }}
    >
      <GridColumn field='batchNumber' title='Партида' sortable={true} />
      <GridColumn field='deliveryDate' title='В обекта от' format='{0:dd/MM/yyyy}' />
      <GridColumn field='UNECECode' title='Код UNECE' />
      <GridColumn field='weight' title='Тегло кг.' />
      <GridColumn field='corpseClass' title='Трупен клас (S).E.U.R.O.P' />
      <GridColumn field='processedIn' title='Преработено в' />
      <GridColumn field='raisedIn' title='Отгледано в' />
      <GridColumn field='bоrnIn' title='Родено в' />
    </Grid>
  );
};

export default IncomingBatchesTable;
