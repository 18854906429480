import { gql } from '@apollo/client';

export enum AnimalObjectEnumType {
  CATTLE,
  SHEEP
}

export type AnimalObjectType = {
  animalObjectId: number;
  animalObjectName: string;
  animalObjectNumber: string;
  animalObjectNumberTraces: string;
  animalObjectCity: string;
  animalObjectAddress: string;
  animalObjectCapacity: number;
  animalObjectBuildings: number;
  animalObjectBuildingsAcreage: number;
  animalObjectPastures: number;
  animalObjectPasturesAcreage: number;
  active: boolean;
  animalObjectBranch: AnimalObjectEnumType;
  firmId: number;
};

export type AnimalObjectResponseType = {
  items: AnimalObjectType[];
};

export type SaveAnimalObjectType = {
  animalObject: AnimalObjectType;
};

export type GetAnimalObjectByFirmParams = {
  firmId: number,
  active: boolean
};

// Queries & Mutations

export const GET_ANIMAL_OBJECTS = gql`
  query {
    animalObjects {
      items {
        animalObjectId
        animalObjectNumber
        animalObjectName
        animalObjectNumberTraces
        animalObjectCity
        animalObjectAddress
        animalObjectCapacity
        animalObjectBuildings
        animalObjectBuildingsAcreage
        animalObjectPastures
        animalObjectPasturesAcreage
        firmId
        active
      }
    }
  }
`;

export const GET_ACTIVE_ANIMAL_OBJECTS_BY_FIRM = gql`
  query animalObjectsByFirm($firmId: Int, $active: Boolean){
    animalObjectsByFirm(firmId: $firmId, active: $active) {
      items {
        animalObjectId
        animalObjectNumber
        animalObjectName
        animalObjectNumberTraces
        animalObjectCity
        animalObjectAddress
        animalObjectCapacity
        animalObjectBuildings
        animalObjectBuildingsAcreage
        animalObjectPastures
        animalObjectPasturesAcreage
        firmId
        active
      }
    }
  }
`;

export const EDIT_ANIMAL_OBJECT = gql`
  mutation updateAnimalObject($animalObject: animalObjectInput) {
    updateAnimalObject(animalObject: $animalObject) {
      animalObjectId
      animalObjectName
      animalObjectNumber
      animalObjectNumberTraces
      animalObjectCity
      animalObjectAddress
      animalObjectCapacity
      animalObjectBuildings
      animalObjectBuildingsAcreage
      animalObjectPastures
      animalObjectPasturesAcreage
      active
      animalObjectBranch
    }
  }
`;

export const SAVE_ANIMAL_OBJECT = gql`
  mutation addAnimalObject($animalObject: animalObjectInput) {
    addAnimalObject(animalObject: $animalObject) {
      animalObjectId
      animalObjectName
      animalObjectNumber
      animalObjectNumberTraces
      animalObjectCity
      animalObjectAddress
      animalObjectCapacity
      animalObjectBuildings
      animalObjectBuildingsAcreage
      animalObjectPastures
      animalObjectPasturesAcreage
      active
      animalObjectBranch
      firmId
    }
  }
`;
