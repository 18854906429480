/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../app/StoreHooks';
import { BUTCHERY_PROCESS_TITLE } from '../languages/keys/butchgery.keys';
import LanguageKeys from '../languages/keys/keys';
import { VIRTUAL_WAREHOUSE_TITLE, WAREHOUSE_TITLE } from '../languages/keys/warehouse.keys';
import { ADDRESS_BOOK_TITLE, AMBULATORY_LOGS_TITLE, ANIMALS_IN_BUTCHERY_TABLE_TITLE, ANIMALS_IN_FARM_TABLE_TITLE, ARTIFICIAL_INSEMENATION, BUTCHERED_ANIMALS_TABLE, CHEMICAL_TREATMENT_LOG_TITLE, DASHBOARD_TITLE, DEALS_TITLE, FERMER_TITLE, FODDER_LOG_TITLE, HOLDING_PROFILE_TITLE, LOGS_TITLE, MORTALITY_LOG_TITLE, MOVEMENTS_REGISTRATION_TITLE, MOVEMENTS_SALE_TITLE, MOVEMENTS_TITLE, MOVEMENTS_UNREGISTRATION_TITLE, PASTURE_LOG_TITLE, PEST_CONTROL_LOG_TITLE, PROCESSOR_TITLE, PURCHASED_MEDICINE_LOG_TITLE, TRADER_TITLE, USED_PESTICIDE_LOG_TITLE } from '../languages/languages';

export type MainMenuType = {
  text: string;
  id: 'farmer' | 'processor' | 'trader';
  selected: boolean;
  requiredRoles: string[];
};

export type DrawerItemsType = {
  id: number;
  title: string;
  text: string;
  icon?: string;
  class?: string;
  selected?: boolean;
  parentId?: number;
  visible?: boolean;
  'data-expanded'?: boolean;
  route: string;
  requiredRoles?: string[];
};

type SideMenuType = {
  farmer: DrawerItemsType[];
  processor: DrawerItemsType[];
  trader?: DrawerItemsType[];
};

type MenuStateType = {
  menuIndex: 'farmer' | 'processor' | 'trader';
  headerMenu: MainMenuType[];
  sideMenu: SideMenuType;
  drawerComponents: DrawerItemsType[];
};

type DrawerReducerType = {
  payload: DrawerItemsType[],
  type: string;
};

const initialState: MenuStateType = {
  menuIndex: null,
  headerMenu: [
    {
      text: FERMER_TITLE,
      id: 'farmer',
      selected: false,
      requiredRoles: [
        'Admin',
        'Holding Owner',
        'Farm Manager',
        'Farm Employee',
        'Farmer',
        'Farm Veterinary Inspector'
      ]
    },
    {
      text: PROCESSOR_TITLE,
      id: 'processor',
      selected: false,
      requiredRoles: [
        'Admin',
        'Holding Owner',
        'Abattoir Employee',
        'Abattoir Manager',
        'Abattoir Processing Manager',
        'Abattoir Receiver',
        'Abattoir SEUROP Inspector',
        'Abattoir Speditor',
        'Abattoir Tech',
        'Abattoir Veterinary Inspector',
        'Abattoir Worker'
      ]
    },
    {
      text: TRADER_TITLE,
      id: 'trader',
      selected: false,
      requiredRoles: [
        'Admin',
        'Holding Owner',
        'Trader',
        'Trader worker',
      ]
    }
  ],
  sideMenu: {
    farmer: [
      {
        text: DASHBOARD_TITLE,
        icon: 'fas fa-home',
        title: DASHBOARD_TITLE,
        id: 1,
        selected: true,
        route: '/dashboard',
        requiredRoles: [
          'Admin',
          'Holding Owner',
          'Farm Manager',
          'Farm Veterinary Inspector'
        ]
      },
      {
        text: LOGS_TITLE,
        icon: 'fas fa-file-alt',
        title: LOGS_TITLE,
        id: 2,
        'data-expanded': false,
        route: '#',
        requiredRoles: ['Admin', 'Holding Owner']
      },
      {
        text: PASTURE_LOG_TITLE,
        title: PASTURE_LOG_TITLE,
        id: 11,
        parentId: 2,
        route: '/farmer/logs/pastures-entry',
        class: 'sub-drawer-item',
        requiredRoles: ['Admin', 'Holding Owner']
      },
      {
        text: AMBULATORY_LOGS_TITLE,
        title: AMBULATORY_LOGS_TITLE,
        id: 12,
        parentId: 2,
        route: '/farmer/logs/ambulatory-logs',
        class: 'sub-drawer-item',
        requiredRoles: ['Admin', 'Holding Owner', 'Farm Manager']
      },
      {
        id: 13,
        parentId: 2,
        title: PURCHASED_MEDICINE_LOG_TITLE,
        text: PURCHASED_MEDICINE_LOG_TITLE,
        route: '/farmer/logs/purchased-medicines',
        class: 'sub-drawer-item',
        requiredRoles: ['Admin', 'Holding Owner']
      },
      {
        id: 14,
        parentId: 2,
        title: FODDER_LOG_TITLE,
        text: FODDER_LOG_TITLE,
        route: '/farmer/logs/fodder',
        class: 'sub-drawer-item',
        requiredRoles: ['Admin', 'Holding Owner']
      },
      {
        id: 15,
        parentId: 2,
        title: ARTIFICIAL_INSEMENATION,
        text: ARTIFICIAL_INSEMENATION,
        route: '/farmer/logs/artificial-insemination',
        class: 'sub-drawer-item',
        requiredRoles: [
          'Admin',
          'Holding Owner',
          'Farm Manager',
          'Farm Veterinary Inspector'
        ]
      },
      {
        id: 16,
        parentId: 2,
        title: MORTALITY_LOG_TITLE,
        text: MORTALITY_LOG_TITLE,
        route: '/farmer/logs/mortality',
        class: 'sub-drawer-item',
        requiredRoles: [
          'Admin',
          'Holding Owner',
          'Farm Manager',
          'Farm Veterinary Inspector'
        ]
      },
      {
        id: 17,
        parentId: 2,
        title: PEST_CONTROL_LOG_TITLE,
        text: PEST_CONTROL_LOG_TITLE,
        route: '/farmer/logs/pest-control',
        class: 'sub-drawer-item',
        requiredRoles: [
          'Admin',
          'Holding Owner',
          'Farm Manager',
          'Farm Veterinary Inspector'
        ]
      },
      {
        id: 18,
        parentId: 2,
        title: USED_PESTICIDE_LOG_TITLE,
        text: USED_PESTICIDE_LOG_TITLE,
        route: '/farmer/logs/used-pesticide',
        class: 'sub-drawer-item',
        requiredRoles: [
          'Admin',
          'Holding Owner',
          'Farm Manager',
          'Farm Veterinary Inspector'
        ]
      },
      {
        id: 19,
        parentId: 2,
        title: CHEMICAL_TREATMENT_LOG_TITLE,
        text: CHEMICAL_TREATMENT_LOG_TITLE,
        class: 'sub-drawer-item last',
        route: '/farmer/logs/chemical-treatment',
        requiredRoles: [
          'Admin',
          'Holding Owner',
          'Farm Manager',
          'Farm Veterinary Inspector'
        ]
      },
      {
        id: 3,
        text: ANIMALS_IN_FARM_TABLE_TITLE,
        icon: 'fas fa-table',
        title: ANIMALS_IN_FARM_TABLE_TITLE,
        route: '/farmer/animals-table-farm',
        requiredRoles: [
          'Admin',
          'Holding Owner',
          'Farm Manager',
          'Farm Veterinary Inspector'
        ]
      },
      {
        id: 100,
        text: MOVEMENTS_TITLE,
        icon: 'fas fa-paper-plane',
        title: MOVEMENTS_TITLE,
        'data-expanded': false,
        route: '#',
        requiredRoles: ['Admin', 'Holding Owner', 'Farm Manager']
      },
      {
        text: MOVEMENTS_REGISTRATION_TITLE,
        title: MOVEMENTS_REGISTRATION_TITLE,
        id: 101,
        parentId: 100,
        route: '/farmer/movements/registration',
        class: 'sub-drawer-item',
        requiredRoles: ['Admin', 'Holding Owner', 'Farm Manager']
      },
      {
        text: MOVEMENTS_UNREGISTRATION_TITLE,
        title: MOVEMENTS_UNREGISTRATION_TITLE,
        id: 102,
        parentId: 100,
        route: '/farmer/movements/unregistration',
        class: 'sub-drawer-item',
        requiredRoles: ['Admin', 'Holding Owner', 'Farm Manager']
      },
      {
        text: MOVEMENTS_SALE_TITLE,
        title: MOVEMENTS_SALE_TITLE,
        id: 103,
        parentId: 100,
        route: '/farmer/movements/sale',
        class: 'sub-drawer-item last',
        requiredRoles: ['Admin', 'Holding Owner', 'Farm Manager']
      },
      {
        id: 6,
        text: DEALS_TITLE,
        icon: 'fas fa-info',
        title: DEALS_TITLE,
        route: '/farmer/deals',
        requiredRoles: ['Admin', 'Holding Owner', 'Farm Manager']
      },
      {
        id: 12,
        text: VIRTUAL_WAREHOUSE_TITLE,
        icon: 'fas fa-warehouse',
        title: VIRTUAL_WAREHOUSE_TITLE,
        route: '/farmer/virtual-warehouse',
        requiredRoles: ['Admin', 'Holding Owner', 'Farm Manager']
      },
      {
        id: 7,
        text: ADDRESS_BOOK_TITLE,
        icon: 'fas fa-book',
        title: ADDRESS_BOOK_TITLE,
        route: '/address-book',
        requiredRoles: [
          'Admin',
          'Holding Owner',
          'Farm Manager',
          'Farm Veterinary Inspector'
        ]
      },
      {
        id: 8,
        text: HOLDING_PROFILE_TITLE,
        icon: 'fas fa-user-cog',
        title: HOLDING_PROFILE_TITLE,
        'data-expanded': false,
        route: '#',
        requiredRoles: ['Admin', 'Holding Owner']
      },
      {
        id: 200,
        parentId: 8,
        text: HOLDING_PROFILE_TITLE,
        title: HOLDING_PROFILE_TITLE,
        class: 'sub-drawer-item',
        route: '/holding-profile',
        requiredRoles: ['Admin', 'Holding Owner']
      },
      {
        id: 201,
        parentId: 8,
        text: LanguageKeys.uniceKeys.UNECE_MENU_TITLE,
        title: LanguageKeys.uniceKeys.UNECE_MENU_TITLE,
        class: 'sub-drawer-item',
        route: '/holding/unecefarmsettings',
        requiredRoles: ['Admin', 'Holding Owner']
      }
    ],
    processor: [
      {
        text: DASHBOARD_TITLE,
        icon: 'fas fa-home',
        title: DASHBOARD_TITLE,
        id: 1,
        selected: true,
        route: '/dashboard',
        requiredRoles: [
          'Admin',
          'Holding Owner',
          'Abattoir Manager',
          'Abattoir Veterinary Inspector',
          'Veterinary Inspector'
        ]
      },
      {
        id: 4,
        text: ANIMALS_IN_BUTCHERY_TABLE_TITLE,
        icon: 'fas fa-bookmark',
        title: ANIMALS_IN_BUTCHERY_TABLE_TITLE,
        route: '/processor/animals-table-butchery',
        requiredRoles: [
          'Admin',
          'Holding Owner',
          'Abattoir Veterinary Inspector'
        ]
      },
      {
        id: 5,
        text: BUTCHERED_ANIMALS_TABLE,
        icon: 'fas fa-table',
        title: BUTCHERED_ANIMALS_TABLE,
        route: '/processor/butchered-animals-table',
        requiredRoles: [
          'Admin',
          'Holding Owner',
          'Abattoir Veterinary Inspector'
        ]
      },
      {
        id: 6,
        text: DEALS_TITLE,
        icon: 'fas fa-info',
        title: DEALS_TITLE,
        route: '/processor/deals',
        requiredRoles: ['Admin', 'Holding Owner', 'Abattoir Manager']
      },
      {
        id: 11,
        text: BUTCHERY_PROCESS_TITLE,
        icon: 'fas fa-skull-crossbones',
        title: BUTCHERY_PROCESS_TITLE,
        route: '/processor/butchery-process',
        requiredRoles: ['Admin', 'Holding Owner', 'Abattoir Manager']
      },
      {
        id: 12,
        text: WAREHOUSE_TITLE,
        icon: 'fas fa-warehouse',
        title: WAREHOUSE_TITLE,
        route: '/processor/warehouse',
        requiredRoles: ['Admin', 'Holding Owner', 'Abattoir Manager']
      },
      // {
      //   id: 9,
      //   text: CARCASS_QUALITY_GRADE,
      //   icon: 'fas fa-calendar-check',
      //   title: CARCASS_QUALITY_GRADE,
      //   route: '/processor/carcass-quality-grading',
      //   requiredRoles: ['Admin', 'Holding Owner', 'Abattoir Manager']
      // },
      // {
      //   id: 10,
      //   text: ANIMAL_COOLING,
      //   icon: 'fas fa-thermometer-half',
      //   title: ANIMAL_COOLING,
      //   route: '/processor/animal-cooling',
      //   requiredRoles: ['Admin', 'Holding Owner', 'Abattoir Manager']
      // },
      {
        id: 7,
        text: ADDRESS_BOOK_TITLE,
        icon: 'fas fa-book',
        title: ADDRESS_BOOK_TITLE,
        route: '/address-book',
        requiredRoles: ['Admin', 'Holding Owner', 'Abattoir Manager']
      },
      {
        id: 8,
        text: HOLDING_PROFILE_TITLE,
        icon: 'fas fa-user-cog',
        title: HOLDING_PROFILE_TITLE,
        route: '/holding-profile',
        requiredRoles: ['Admin', 'Holding Owner', 'Abattoir Manager']
      },
      {
        id: 202,
        parentId: 8,
        text: LanguageKeys.uniceKeys.UNECE_MENU_TITLE,
        title: LanguageKeys.uniceKeys.UNECE_MENU_TITLE,
        class: 'sub-drawer-item',
        route: '/holding/uneceenterpricesettings',
        requiredRoles: ['Admin', 'Holding Owner']
      }
    ],
    trader: [
      {
        text: DASHBOARD_TITLE,
        icon: 'fas fa-home',
        title: DASHBOARD_TITLE,
        id: 10,
        selected: true,
        route: '/dashboard',
        requiredRoles: [
          'Admin',
          'Holding Owner',
          'Abattoir Manager',
          'Abattoir Veterinary Inspector',
          'Veterinary Inspector'
        ]
      },
      {
        id: 20,
        text: DEALS_TITLE,
        icon: 'fas fa-info',
        title: DEALS_TITLE,
        route: '/trader/deals',
        requiredRoles: ['Admin', 'Holding Owner', 'Abattoir Manager']
      },
      {
        id: 30,
        text: WAREHOUSE_TITLE,
        icon: 'fas fa-warehouse',
        title: WAREHOUSE_TITLE,
        route: '/trader/warehouse',
        requiredRoles: ['Admin', 'Holding Owner', 'Abattoir Manager']
      },
      {
        id: 40,
        text: ADDRESS_BOOK_TITLE,
        icon: 'fas fa-book',
        title: ADDRESS_BOOK_TITLE,
        route: '/address-book',
        requiredRoles: ['Admin', 'Holding Owner', 'Abattoir Manager']
      },
      {
        id: 50,
        text: HOLDING_PROFILE_TITLE,
        icon: 'fas fa-user-cog',
        title: HOLDING_PROFILE_TITLE,
        route: '/holding-profile',
        requiredRoles: ['Admin', 'Holding Owner', 'Abattoir Manager']
      },
    ],
  },
  drawerComponents: []
};

export const MenuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setMenuIndex: (state, { payload }) => {
      state.menuIndex = payload;
    },
    setSelectedItem: (state, { payload }) => {
      const selectedMenu = state.headerMenu.find((menu) =>
        menu.id === payload);
      state.headerMenu = state.headerMenu.map((menu) => {
        if (menu.id === selectedMenu.id) {
          return {
            ...menu,
            selected: true
          };
        }
        return {
          ...menu,
          selected: false
        };
      });
    },
    setDrawerComponentsIndex: (state, { payload }) => {
      state.drawerComponents = state.sideMenu[payload];
    },
    setDrawerComponents: (state, { payload }: DrawerReducerType) => {
      state.drawerComponents = payload;
    }
  }
});

export const {
  setMenuIndex, setSelectedItem, setDrawerComponentsIndex, setDrawerComponents
} = MenuSlice.actions;

export const selectMenuIndex = (state: RootState) =>
  state.menu.menuIndex;
export const selectHeaderMenu = (state: RootState) =>
  state.menu.headerMenu;
export const selectSideMenu = (state: RootState) =>
  state.menu.sideMenu;
export const selectDrawerComponents = (state: RootState) =>
  state.menu.drawerComponents;

export default MenuSlice.reducer;
