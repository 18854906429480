import React, { useState } from 'react';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridToolbar
} from '@progress/kendo-react-grid';
import { State, process } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { useLocalization } from '@progress/kendo-react-intl';
import GridSearchBar from '../../features/GridSearchBar/GridSearchBar';
import { AmbulatoryLogType } from '../../slices/data/AmbulatoryLogSliceData';
import ColumnMenu from '../ColumnMenu/ColumnMenu';
import {
  ADD_NEW,
  AMBULATORY_ANIM_OBJ,
  AMBULATORY_CLINICAL_DATA,
  AMBULATORY_DATE,
  AMBULATORY_DIAGNOSIS,
  AMBULATORY_DIAGNOSIS_OUT,
  AMBULATORY_DIAGNOSTIC_TEST,
  AMBULATORY_DOSE,
  AMBULATORY_MEDICINES,
  AMBULATORY_NOTE,
  AMBULATORY_NUMBER,
  AMBULATORY_WITHDRAWAL_PERIOD,
  ANIMAL_AGE,
  ANIMAL_BREED,
  ANIMAL_LID,
  ANIMAL_SEX,
  ANIMAL_TYPE,
  CATTLE_TYPE,
  FEMALE,
  MALE,
  SHEEP_TYPE,
  AMBULATORY_LOGS_TITLE
} from '../../languages/languages';
import { DateCell, EditCommandCell } from '../CustomGridCells/CustomGridCells';
import CattleBreeds from '../../features/FarmAnimalTableCattle/cattleBreeds';
import {
  AnimalSexEnum,
  AnimalTypeEnum
} from '../../slices/data/AnimalSliceData';
import { useAppSelector } from '../../app/StoreHooks';
import { selectAmbulatoryLogLoadingState } from '../../slices/AmbulatoryLogSlice';
import GridLoaderPanel from '../GridLoaderPanel/GridLoaderPanel';
import GrantAccess from '../../features/GrantAccess/GrantAccess';

const REQUIRED_ROLES: string[] = [
  'Admin',
  'Holding Owner',
  'Farm Manager',
  'Farm Veterinary Inspector'
];

type AmbulatoryLogGridProps = {
  data: AmbulatoryLogType[];
  addNew: () => void;
  editEntry: (item: AmbulatoryLogType) => void;
};
const AmbulatoryLogGrid = ({
  data,
  addNew,
  editEntry
}: AmbulatoryLogGridProps) => {
  const localizationService = useLocalization();
  const loadingState = useAppSelector(selectAmbulatoryLogLoadingState);
  //   Grid State
  const [dataState, setDataState] = useState<State>({
    sort: [{ field: 'code', dir: 'asc' }],
    take: 10,
    skip: 0
  });

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataState(event.dataState);
  };

  const MyEditCommandCell = (props: GridCellProps) =>
    (
      <GrantAccess requiredRoles={REQUIRED_ROLES} haveRoles='some'>
        <EditCommandCell {...props} enterEdit={editEntry} />
      </GrantAccess>
    );

  const MedicineNamesCell = ({ dataItem, field }: GridCellProps) => {
    const medNames = dataItem[field]?.map((medicine) =>
      medicine?.medicineName);
    return <td>{medNames?.join(', ')}</td>;
  };

  const BreedCell = ({ dataItem }: GridCellProps) => {
    const breedType = dataItem.animal?.cattleBreed;
    const breed = CattleBreeds[breedType];
    return <td>{breed}</td>;
  };

  const AnimalSexCell = ({ dataItem }: GridCellProps) => {
    const animalSex = dataItem.animal?.sex;
    return (
      <td>
        {AnimalSexEnum[animalSex] === AnimalSexEnum.MALE
          ? localizationService.toLanguageString(MALE, '')
          : localizationService.toLanguageString(FEMALE, '')}
      </td>
    );
  };

  const AnimalType = ({ dataItem }: GridCellProps) => {
    const animalType = dataItem.animal?.animalType;
    return (
      <td>
        {AnimalTypeEnum[animalType] === AnimalTypeEnum.CATTLE
          ? localizationService.toLanguageString(CATTLE_TYPE, '')
          : localizationService.toLanguageString(SHEEP_TYPE, '')}
      </td>
    );
  };

  const EntryNumberCell = ({ dataItem }: GridCellProps) =>
    (
      <td>{data.indexOf(dataItem) + 1}</td>
    );

  return (
    <>
      <h1 className='page-title'>
        {localizationService.toLanguageString(AMBULATORY_LOGS_TITLE, '')}
      </h1>
      <div className='content-wrapper section'>
        <Grid
          {...dataState}
          onDataStateChange={dataStateChange}
          pageable
          sortable
          data={process(data, dataState)}
        >
          <GridToolbar>
            <GridSearchBar
              filterBy='animal.lID'
              dataState={dataState}
              setDataState={setDataState}
            />
            <GrantAccess requiredRoles={REQUIRED_ROLES} haveRoles='some'>
              <Button
                title={localizationService.toLanguageString(ADD_NEW, '')}
                icon='plus'
                primary
                onClick={addNew}
              >
                {localizationService.toLanguageString(ADD_NEW, '')}
              </Button>
            </GrantAccess>
          </GridToolbar>
          <Column key='№' field='№' title='№' cell={EntryNumberCell} />
          <Column
            key='ambulatoryNumber'
            field='ambulatoryNumber'
            title={localizationService.toLanguageString(AMBULATORY_NUMBER, '')}
            filter='text'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='ambulatoryDate'
            field='ambulatoryDate'
            filter='text'
            title={localizationService.toLanguageString(AMBULATORY_DATE, '')}
            cell={DateCell}
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='animalObject.animalObjectName'
            field='animalObject.animalObjectName'
            title={localizationService.toLanguageString(
              AMBULATORY_ANIM_OBJ,
              ''
            )}
            filter='text'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='animal.animalType'
            field='animal.animalType'
            filter='text'
            title={localizationService.toLanguageString(ANIMAL_TYPE, '')}
            cell={AnimalType}
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='animal.sex'
            field='animal.sex'
            filter='text'
            title={localizationService.toLanguageString(ANIMAL_SEX, '')}
            cell={AnimalSexCell}
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='animal.dateOfBirth'
            field='animal.dateOfBirth'
            title={localizationService.toLanguageString(ANIMAL_AGE, '')}
            cell={DateCell}
            filter='text'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='animal.cattleBreed'
            field='animal.cattleBreed'
            filter='text'
            title={localizationService.toLanguageString(ANIMAL_BREED, '')}
            cell={BreedCell}
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='animal.lID'
            field='animal.lID'
            filter='text'
            title={localizationService.toLanguageString(ANIMAL_LID, '')}
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='clinicalData'
            field='clinicalData'
            filter='text'
            title={localizationService.toLanguageString(
              AMBULATORY_CLINICAL_DATA,
              ''
            )}
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='diagnosticTest'
            field='diagnosticTest'
            filter='text'
            title={localizationService.toLanguageString(
              AMBULATORY_DIAGNOSTIC_TEST,
              ''
            )}
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='diagnosis'
            field='diagnosis'
            title={localizationService.toLanguageString(
              AMBULATORY_DIAGNOSIS,
              ''
            )}
            filter='text'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='medicine'
            field='medicine'
            filter='text'
            title={localizationService.toLanguageString(
              AMBULATORY_MEDICINES,
              ''
            )}
            cell={MedicineNamesCell}
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='dose'
            field='dose'
            filter='text'
            title={localizationService.toLanguageString(AMBULATORY_DOSE, '')}
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='withdrawalPeriod'
            field='withdrawalPeriod'
            title={localizationService.toLanguageString(
              AMBULATORY_WITHDRAWAL_PERIOD,
              ''
            )}
            filter='text'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='diagnosisOut'
            field='diagnosisOut'
            title={localizationService.toLanguageString(
              AMBULATORY_DIAGNOSIS_OUT,
              ''
            )}
            filter='text'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='comment'
            field='comment'
            title={localizationService.toLanguageString(AMBULATORY_NOTE, '')}
            filter='text'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column cell={MyEditCommandCell} width={120} />
        </Grid>
        {loadingState && <GridLoaderPanel />}
      </div>
    </>
  );
};

export default AmbulatoryLogGrid;
