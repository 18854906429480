import React from 'react';

import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartLegend,
} from '@progress/kendo-react-charts';

import 'hammerjs';

const currentYear = new Date().getFullYear();
const categories = [
  currentYear - 5,
  currentYear - 4,
  currentYear - 2,
  currentYear - 2,
  currentYear - 1,
  currentYear,
];
const series = [
  {
    name: 'Крави',
    color: 'red',
    data: [15, 20, 11, 16, 8, 18],
  },
  {
    name: 'Млади',
    color: 'blue',
    data: [23, 18, 16, 25, 18, 21],
  },
];

const ChartContainer = () => (
  <Chart style={{ height: 350 }}>
    <ChartTitle text='Реализирано трупно месо по възрастови категории в тонове' />
    <ChartLegend position='top' orientation='horizontal' />
    <ChartCategoryAxis>
      <ChartCategoryAxisItem categories={categories} startAngle={45} />
    </ChartCategoryAxis>
    <ChartSeries>
      {series.map((item) => (
        <ChartSeriesItem
          key={item.name}
          type='line'
          tooltip={{ visible: true }}
          data={item.data}
          name={item.name}
          color={item.color}
        />
      ))}
    </ChartSeries>
  </Chart>
);

export default ChartContainer;
