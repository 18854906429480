import { gql } from '@apollo/client';

// Types
export type FirmType = {
  firmId?: number;
  firmEIK: string;
  firmMOL: string;
  firmName: string;
  firmCity: string;
  firmCountry: string;
  firmAddress: string;
  active: boolean;
};

export type SaveFirmInputType = { firm: FirmType };
export type GetFirmInputType = { active: boolean };

export type FirmResponseType = {
  items: FirmType[];
};

export type FirmUpdateInformation = {
  id: number;
  input: FirmType;
};

export type AvailableFirmsType = {
  firmId: number;
  firmName: string;
  firmEIK: string;
  firmMOL: string;
};

export type FirmAvailabilityResponseType = {
  items: AvailableFirmsType[];
};

// Queries & Mutation
export const GET_FIRMS = gql`
  query {
    firms {
      items {
        active
        firmId
        firmEIK
        firmMOL
        firmName
        firmCountry
        firmCity
        firmAddress
      }
    }
  }
`;

export const GET_AVAILABLE_FIRMS = gql`
  query firms($active: Boolean) {
    firms(active: $active) {
      items {
        firmId
        firmName
        firmEIK
        firmMOL
      }
    }
  }
`;

export const GET_ALL_TRACE_FIRMS = gql`
  {
    allFirmsInTrace {
      items {
        firmId
        firmName
        firmEIK
        firmMOL
      }
    }
  }
`;

export const SAVE_FIRM = gql`
  mutation addFirm($firm: firmInput) {
    addFirm(firm: $firm) {
      firmId
      firmEIK
      firmMOL
      firmName
      firmCity
      firmCountry
      firmAddress
      active
    }
  }
`;

export const UPDATE_FIRM = gql`
  mutation UpdateFirm($firm: firmInput) {
    updateFirm(firm: $firm) {
      firmId
      firmEIK
      firmName
      firmCountry
      firmCity
      firmAddress
      firmMOL
      active
    }
  }
`;
