import React, { useState } from 'react';
import { State, process } from '@progress/kendo-data-query';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridToolbar
} from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { useLocalization } from '@progress/kendo-react-intl';
import GridSearchBar from '../../features/GridSearchBar/GridSearchBar';
import {
  ADD_NEW,
  EXPORT,
  ADDRESS_BOOK_STATUS,
  ADDRESS_BOOK_NAME,
  ADDRESS_BOOK_ROLE,
  ADDRESS_BOOK_PHONE,
  ADDRESS_BOOK_EMAIL,
  ADDRESS_BOOK_FIRM
} from '../../languages/languages';
import ColumnMenu from '../ColumnMenu/ColumnMenu';
import { CustomRolesCell, EditCommandCell } from '../CustomGridCells/CustomGridCells';
import { BooleanCell, ABFirmCell } from '../FormComponents/FormComponents';
import { EmployeesABType } from '../../slices/data/EmployeesSliceData';
import { useAppSelector } from '../../app/StoreHooks';
import { selectAddressBookEmployeesLoading } from '../../slices/EmployeesSlice';
import GridLoaderPanel from '../GridLoaderPanel/GridLoaderPanel';
import GrantAccess from '../../features/GrantAccess/GrantAccess';

const REQUIRED_ROLES: string[] = ['Admin', 'Holding Owner', 'Farm Manager'];

type EmployeeGridProps = {
  data: EmployeesABType[];
  addEmployee: () => void;
  enterEdit: (item: EmployeesABType) => void;
};
const EmployeeGrid = ({
  data,
  addEmployee,
  enterEdit
}: EmployeeGridProps) => {
  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0
  });
  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataState(event.dataState);
  };

  const localizationService = useLocalization();
  const loadingState = useAppSelector(selectAddressBookEmployeesLoading);

  const MyEditCommandCell = (props: GridCellProps) =>
    (
      <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
        <EditCommandCell {...props} enterEdit={enterEdit} />
      </GrantAccess>
    );

  return (
    <>
      <Grid
        {...dataState}
        onDataStateChange={dataStateChange}
        sortable
        pageable
        data={process(data, dataState)}
      >
        <GridToolbar>
          <GridSearchBar
            dataState={dataState}
            setDataState={setDataState}
            filterBy='userName'
          />
          <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
            <Button
              title='Add New Employee'
              primary
              icon='plus'
              onClick={addEmployee}
            >
              {localizationService.toLanguageString(ADD_NEW, '')}
            </Button>
            <Button title='Export Excel' icon='download' look='outline'>
              {localizationService.toLanguageString(EXPORT, '')}
            </Button>
          </GrantAccess>
        </GridToolbar>

        <Column
          title={localizationService.toLanguageString(ADDRESS_BOOK_STATUS, '')}
          field='employeeABAccess'
          filter='boolean'
          cell={BooleanCell}
          width={150}
        />

        <Column
          title={localizationService.toLanguageString(ADDRESS_BOOK_NAME, '')}
          field='userName'
          filter='text'
          columnMenu={ColumnMenu}
        />

        <Column
          title={localizationService.toLanguageString(ADDRESS_BOOK_ROLE, '')}
          field='roles'
          filter='text'
          cell={CustomRolesCell}
          columnMenu={ColumnMenu}
        />

        <Column
          title={localizationService.toLanguageString(ADDRESS_BOOK_PHONE, '')}
          field='employeeABPhone'
          filter='text'
          columnMenu={ColumnMenu}
        />

        <Column
          title={localizationService.toLanguageString(ADDRESS_BOOK_EMAIL, '')}
          field='email'
          filter='text'
          columnMenu={ColumnMenu}
        />

        <Column
          title={localizationService.toLanguageString(ADDRESS_BOOK_FIRM, '')}
          field='employeeABFirmId'
          filter='text'
          columnMenu={ColumnMenu}
          cell={ABFirmCell}
        />

        <Column cell={MyEditCommandCell} width={120} />
      </Grid>
      {loadingState && <GridLoaderPanel />}
    </>
  );
};

export default EmployeeGrid;
