import { Fade } from '@progress/kendo-react-animation';
import { Notification, NotificationGroup } from '@progress/kendo-react-notification';
import React from 'react';

import AUTH_CONFIG from '../auth-variables';
import Layout from '../components/Layout/Layout';
import { Auth0Provider } from '../features/Auth/Auth0ContextWrapper';

import { removeSuccessMessage, selectError, setErrors } from '../slices/ErrorSlice';
import { useGraphQlClient } from '../utils/custom-hooks';
import { useAppDispatch, useAppSelector } from './StoreHooks';

import '@progress/kendo-theme-default/dist/all.css';
import '../styles/app.scss';

function App() {
  const initClient = useGraphQlClient();
  const { errors, successMessage } = useAppSelector(selectError);
  const dispatch = useAppDispatch();

  const closeError = (id: string) => {
    const filtered = errors.filter((error) =>
      error.id !== id);
    dispatch(setErrors(filtered));
  };
  const closeSuccess = () => {
    dispatch(removeSuccessMessage());
  };

  return (
    initClient && (
      <Auth0Provider
        domain={AUTH_CONFIG.domain}
        client_id={AUTH_CONFIG.clientId}
        redirect_uri={AUTH_CONFIG.callbackUrl}
        audience={AUTH_CONFIG.audience}
        cacheLocation='localstorage'
      >
        {/* <Main /> */}
        <Layout />
        <NotificationGroup
          style={{
            right: 0,
            bottom: 0,
            alignItems: 'flex-start',
            flexWrap: 'wrap-reverse'
          }}
        >
          <Fade enter exit>
            {errors?.map((error) =>
              (
                <Notification
                  type={{ style: 'error', icon: true }}
                  closable
                  key={error.id}
                  onClose={() =>
                    closeError(error.id)}
                >
                  <span>{error?.message}</span>
                </Notification>
              ))}
            {successMessage.map((success) =>
              (
                <Notification
                  type={{ style: 'success', icon: true }}
                  closable
                  key={success.id}
                  onClose={closeSuccess}
                >
                  <span>{success.message}</span>
                </Notification>
              ))}
          </Fade>
        </NotificationGroup>
      </Auth0Provider>
    )
  );
}

export default App;
