import { State } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import { getter } from '@progress/kendo-react-common';
import { GridCellProps, GridDataStateChangeEvent, GridHeaderSelectionChangeEvent } from '@progress/kendo-react-grid';
import React, { forwardRef, useCallback, useEffect, useState } from 'react';

import { ColumnType } from '../../slices/data/AnimalColumnsSliceData';
import { IBatchAnimal } from '../../slices/data/ButcheryProcessSliceData';
import AnimaWeightEditModal from '../ButcheryProcess/AnimaWeightEditModal';
import { CattleBreedCell } from '../CustomGridCells/CustomGridCells';
import AnimalTable from '../SelectableGrid/SelectableGrid';

const DATA_ITEM_KEY: string = 'animalId';
const idGetter = getter(DATA_ITEM_KEY);

interface AnimalGridProps {
  onSelect?: (items: IBatchAnimal[]) => void;
  animals: IBatchAnimal[];
  butcheredDate: Date;
}

export type RefHandle = {
  reload: () => void;
};

const cattleColumns: ColumnType[] = [
  {
    title: 'ВЕТИС Марка №',
    field: 'lID',
    show: true,
    filter: 'text',
  } as ColumnType,
  {
    title: 'Възраст (м)',
    field: 'age',
    show: true,
    filter: 'numeric',
  } as ColumnType,
  {
    title: 'Пол',
    field: 'sex',
    show: true,
    filter: 'text',
  } as ColumnType,
  {
    title: 'Порода',
    field: 'cattleBreed',
    show: true,
    filter: 'text',
  } as ColumnType,
  {
    title: 'Дата на пристигане',
    field: 'arrivalDate',
    show: true,
    filter: 'date',
    format: '{0:dd.MM.yyy}',
  } as ColumnType,
  {
    title: 'Ишлеме',
    field: 'processingAsService',
    show: true,
    filter: 'boolean',
    cell: null,
  } as ColumnType,
  {
    title: 'Заклано на',
    field: 'butcheredDate',
    show: true,
    filter: 'date',
    format: '{0:dd.MM.yyy}',
  } as ColumnType,
  {
    title: 'Живо тегло (кг)',
    field: 'weight',
    show: true,
    filter: 'numeric',
  } as ColumnType,
  {
    title: 'Брак',
    field: 'isWaste',
    show: true,
    filter: 'boolean',
    cell: null,
  } as ColumnType,
] as ColumnType[];

const AnimalsForButcheryWeightsGrid = forwardRef<RefHandle, AnimalGridProps>(
  ({ onSelect = undefined, animals, butcheredDate }: AnimalGridProps) => {
    const [items, setItems] = useState<IBatchAnimal[]>(animals);

    useEffect(() => {
      setItems(animals);
    }, []);

    useEffect(() => {
      setItems(items.map((item) => ({ ...item, butcheredDate })));
    }, [butcheredDate]);

    // Grid States
    const [dataState, setDataState] = useState<State>({
      take: 10,
      skip: 0,
    });

    const dataStateChange = (e: GridDataStateChangeEvent) => {
      setDataState(e.dataState);
    };

    // Select animals
    // Local state for the grid's checkbox that will indicate
    // the state of the checkbox
    const [selectedState, setSelectedState] = useState<{
      [id: string]: boolean | number[];
    }>({});

    // Local state for the selected animals, that will be previewed in
    // the next step of the logic.
    // const [selectedAnimals, setSelectedAnimals] = useState<AnimalType[]>([]);
    const [allSelValue, setAllSelValue] = useState<boolean>(false);

    // Check/Uncheck row in the grid
    // Use a callback so that the function is not fully
    // rerendered every time the component re-renders.
    // It should only update if there are changes to the
    // selectedState local state
    const onSelectionChange = useCallback(
      async () => {
        // If anything but the checkbox in the grid is selected,
        // return the function so that the component does not crash
        // if (!syntheticEvent?.target) {
        //   return;
        // }

        // // Get checked status of the checkbox cell
        // const checkboxElement: any = syntheticEvent.target;
        // const { checked } = checkboxElement;

        // // Create a local state for the selected animals,
        // // so that the checkbox element is dynamically updated
        // const newSelectedState = {
        //   ...selectedState,
        //   [idGetter(dataItem)]: checked,
        // };

        // let updatedSelection = [];
        // // If the the element is not checked, filter through
        // // the local selectedAnimals state to make sure
        // // only selected animals will be inserted for preview
        // if (!newSelectedState[idGetter(dataItem)]) {
        //   updatedSelection = selectedAnimals.filter(
        //     (animal) => animal.animalId !== dataItem.animalId
        //   );

        //   setSelectedAnimals(updatedSelection);
        // } else {
        //   // If the element is checked, add it to the
        //   // local selectedAnimals state
        //   updatedSelection = [...selectedAnimals, dataItem];
        // }
        // // Sets the selected animals for preview
        // setSelectedAnimals(updatedSelection);
        // if (onSelect) {
        //   onSelect(updatedSelection);
        // }

        // setSelectedState(newSelectedState);
      },
      [selectedState]
    );

    // Set all page elements to be checked
    const onHeaderSelectionChange = ({
      dataItems,
      syntheticEvent,
    }: GridHeaderSelectionChangeEvent) => {
      const checkboxElement: any = syntheticEvent.target;
      const { checked } = checkboxElement;
      const newSelectedState = {};
      const selAnimals: any[] = [];
      setAllSelValue(!allSelValue);
      dataItems.forEach(async (animal) => {
        newSelectedState[idGetter(animal)] = checked;
        if (newSelectedState[idGetter(animal)]) {
          selAnimals.push(animal);
        }
      });
      setSelectedState(newSelectedState);
      // setSelectedAnimals(selAnimals);
      if (onSelect) {
        onSelect(selAnimals);
      }
    };

    // Handle Menu visibility
    const handleMenuVisibility = () => {
      console.log('menu visibility changed');
    };

    const [showEdit, setShowEdit] = useState(false);
    const [editItem, seteditItem] = useState<IBatchAnimal>(null);
    const onShowEdit = (item: IBatchAnimal) => {
      seteditItem(item);
      setShowEdit(true);
    };

    const cancelEdit = () => {
      seteditItem(null);
      setShowEdit(false);
    };

    const handleEdit = (item: IBatchAnimal) => {
      const index = items.findIndex((i) => i.animalId === item.animalId);

      if (index !== -1) {
        items[index] = item;
        setItems([...items]);
        onSelect(items);
      }

      cancelEdit();
    };

    const actionCell = ({ dataItem, field }: GridCellProps) => {
      console.log(dataItem, field);
      return (
        <td className='text-center'>
          <Button
            data-testid='editButton'
            type='button'
            className='k-button k-secondary mr-l'
            icon='edit'
            look='outline'
            onClick={() => onShowEdit(dataItem)}
          />
        </td>
      );
    };

    return (
      <>
        <AnimalTable
          data={items}
          dataState={dataState}
          dataStateChange={dataStateChange}
          handleMenuVisibility={handleMenuVisibility}
          onHeaderSelectionChange={onHeaderSelectionChange}
          onSelectionChange={onSelectionChange}
          selectedState={selectedState}
          setDataState={setDataState}
          stateColumns={cattleColumns}
          cell={CattleBreedCell}
          DATA_ITEM_KEY='animalId'
          showToolbar={false}
          showColumns={false}
          actionCell={actionCell}
          showSearch={false}
          selectable={false}
        />
        {showEdit && (
          <AnimaWeightEditModal item={editItem} onSubmit={handleEdit} cancelEdit={cancelEdit} />
        )}
      </>
    );
  }
);

AnimalsForButcheryWeightsGrid.defaultProps = {
  onSelect: undefined,
};

export default AnimalsForButcheryWeightsGrid;
