/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/StoreHooks';
import {
  AddArtificialInsemenationType,
  AddArtInsemInputType,
  ArtificialInsemenationType,
  EDIT_ARTIFICIAL_INSEMENATION,
  GetArtificialInsemenationsType,
  GET_ALL_ARTIFICIAL_INSEMENATIONS,
  SAVE_ARTIFICIAL_INSEMENATION
} from './data/ArtificialInseminationSliceData';
import {
  createAsyncThunkGeneric,
  createAsyncThunkGenericVoid
} from './GenericThunks';

type ArtificialInsemenationStatetype = {
  artificialInsementaions: ArtificialInsemenationType[];
  artificialInsementaionsLoading: boolean;
};

const initialState: ArtificialInsemenationStatetype = {
  artificialInsementaions: [],
  artificialInsementaionsLoading: false
};

// Thunks
export const getAllArtificialInsementaions = createAsyncThunkGenericVoid(
  'artificialInsemenation/getAllArtificialInsementaions',
  async ({ client }) => {
    const response = await client.query<void, GetArtificialInsemenationsType>(
      'aIDiary',
      GET_ALL_ARTIFICIAL_INSEMENATIONS
    );
    return response.items ? response.items : [];
  }
);

export const saveArtificialInsemenation = createAsyncThunkGeneric<
AddArtificialInsemenationType,
ArtificialInsemenationType
>(
  'artificialInsemenation/saveArtificialInsemenation',
  async ({ client, input }) =>
    client.mutate<AddArtInsemInputType, ArtificialInsemenationType>(
      'addAIDiary',
      SAVE_ARTIFICIAL_INSEMENATION,
      { aIDiaryInput: input }
    )
);

export const editArtificialInsemenation = createAsyncThunkGeneric<
AddArtificialInsemenationType,
ArtificialInsemenationType
>(
  'artificialInsemenation/editArtificialInsemenation',
  async ({ client, input }) =>
    client.mutate<AddArtInsemInputType, ArtificialInsemenationType>(
      'updateAIDiary',
      EDIT_ARTIFICIAL_INSEMENATION,
      { aIDiaryInput: input }
    )
);

export const ArtificialInsemenationSlice = createSlice({
  name: 'artificialInsemenation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllArtificialInsementaions.pending, (state) => {
        state.artificialInsementaionsLoading = true;
      })
      .addCase(
        getAllArtificialInsementaions.fulfilled,
        (state, { payload }) => {
          state.artificialInsementaionsLoading = false;
          state.artificialInsementaions = payload?.map((artInsem) =>
            ({
              ...artInsem,
              fertilizationStart: new Date(artInsem?.fertilizationStart),
              fertilizationEnd: new Date(artInsem?.fertilizationEnd),
              pregnancyCheckDate: new Date(artInsem?.pregnancyCheckDate),
              expectedBornDate:
              artInsem?.expectedBornDate && new Date(artInsem?.expectedBornDate)
            }));
        }
      )
      .addCase(saveArtificialInsemenation.pending, (state) => {
        state.artificialInsementaionsLoading = true;
      })
      .addCase(saveArtificialInsemenation.fulfilled, (state, { payload }) => {
        state.artificialInsementaionsLoading = false;
        const newItem = {
          ...payload,
          fertilizationStart: new Date(payload?.fertilizationStart),
          fertilizationEnd: new Date(payload?.fertilizationEnd),
          pregnancyCheckDate: new Date(payload?.pregnancyCheckDate),
          expectedBornDate:
            payload.expectedBornDate && new Date(payload?.expectedBornDate)
        };
        state.artificialInsementaions = [
          ...state.artificialInsementaions,
          newItem
        ];
      })
      .addCase(editArtificialInsemenation.pending, (state) => {
        state.artificialInsementaionsLoading = true;
      })
      .addCase(editArtificialInsemenation.fulfilled, (state, { payload }) => {
        state.artificialInsementaionsLoading = false;
        const updIndx = state.artificialInsementaions.findIndex(
          (artInsem) =>
            artInsem.aIDiaryId === payload.aIDiaryId
        );
        state.artificialInsementaions[updIndx] = {
          ...payload,
          fertilizationStart: new Date(payload?.fertilizationStart),
          fertilizationEnd: new Date(payload?.fertilizationEnd),
          pregnancyCheckDate: new Date(payload?.pregnancyCheckDate),
          expectedBornDate:
              payload.expectedBornDate && new Date(payload?.expectedBornDate)
        };
      });
  }
});

// Selectors
export const selectAllArtificialInsementaions = (state: RootState) =>
  state.artificialInsemenation.artificialInsementaions;
export const selectArtificialInsementaionLoading = (state: RootState) =>
  state.artificialInsemenation.artificialInsementaionsLoading;

// Reducer
export default ArtificialInsemenationSlice.reducer;
