import { gql } from '@apollo/client';

// Types
export type TrademarkType = {
  tradeMarkId: number;
  tradeMarkName: string;
  tradeMarkNumber: string;
  tradeMarkLink: string;
  active: boolean;
  firmId: number;
};

export type TrademarksResponseType = {
  items: TrademarkType[];
};

export type TrademarkInputType = {
  tradeMark: TrademarkType;
};

// Queries & Mutations
export const GET_TRADEMARKS = gql`
  query {
    tradeMark {
      items {
        tradeMarkId
        tradeMarkName
        tradeMarkLink
        tradeMarkNumber
        active
        firmId
      }
    }
  }
`;

export const SAVE_TRADEMARK = gql`
  mutation addTradeMark($tradeMark: tradeMarkInput) {
    addTradeMark(tradeMark: $tradeMark) {
      tradeMarkId
      tradeMarkName
      tradeMarkNumber
      tradeMarkLink
      active
      firmId
    }
  }
`;

export const EDIT_TRADEMARK = gql`
  mutation updateTradeMark($tradeMark: tradeMarkInput) {
    updateTradeMark(tradeMark: $tradeMark) {
      active
      firmId
      tradeMarkId
      tradeMarkLink
      tradeMarkName
      tradeMarkNumber
    }
  }
`;
