import React, { useEffect, useState } from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { useLocalization } from '@progress/kendo-react-intl';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import {
  editPastureLog,
  getAllPastureLogs,
  savePastureLog,
  selectAllPastures
} from '../../slices/PastureLogSlice';
import PastureLogGrid from '../../components/PastureLogGrid/PastureLogGrid';
import PastureLogForm from '../../components/PastureLogForm/PastureLogForm';
import { PastureLogType } from '../../slices/data/PastureLogSliceData';
import { ADD_NEW, EDIT_SUBMISSION } from '../../languages/languages';
import GrantAccess from '../GrantAccess/GrantAccess';

const REQUIRED_ROLES: string[] = [
  'Admin',
  'Holding Owner',
  'Farm Manager',
  'Farm Veterinary Inspector'
];

const PastureLogContainer = () => {
  const localizationService = useLocalization();
  const dispatch = useAppDispatch();
  const pastures = useAppSelector(selectAllPastures);

  useEffect(() => {
    const loadData = () => {
      dispatch(getAllPastureLogs());
    };
    loadData();
  }, []);
  const [addViewForm, setAddViewForm] = useState<boolean>(false);

  const openAddViewForm = () => {
    setAddViewForm(true);
  };

  const closeAddViewForm = () => {
    setAddViewForm(false);
  };

  const submitNewLogEntry = (formData: PastureLogType) => {
    dispatch(savePastureLog(formData));
    closeAddViewForm();
  };

  const [editViewForm, setEditViewForm] = useState<boolean>(false);

  const [editItem, setEditItem] = React.useState<PastureLogType>(null);
  const openEditViewForm = (item: PastureLogType) => {
    setEditViewForm(true);
    setEditItem(item);
  };

  const closeEditViewForm = () => {
    setEditViewForm(false);
  };

  const submitEditLogEntry = (formData: PastureLogType) => {
    dispatch(editPastureLog(formData));
    closeEditViewForm();
  };

  return (
    <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
      <PastureLogGrid
        data={pastures}
        addNew={openAddViewForm}
        enterEdit={openEditViewForm}
      />
      <GrantAccess haveRoles='some' requiredRoles={['Admin', 'Holding Owner', 'Farm Manager']}>
        {addViewForm && (
          <Dialog
            title={localizationService.toLanguageString(ADD_NEW, '')}
            onClose={closeAddViewForm}
          >
            <PastureLogForm
              submit={submitNewLogEntry}
              close={closeAddViewForm}
            />
          </Dialog>
        )}
        {editViewForm && (
          <Dialog
            title={localizationService.toLanguageString(EDIT_SUBMISSION, '')}
            onClose={closeEditViewForm}
          >
            <PastureLogForm
              submit={submitEditLogEntry}
              close={closeEditViewForm}
              editItem={editItem}
            />
          </Dialog>
        )}
      </GrantAccess>
    </GrantAccess>
  );
};

export default PastureLogContainer;
