import * as React from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Form, Field, FormElement } from '@progress/kendo-react-form';
import { useLocalization } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';
import { FormInput, FormSwitch } from '../FormComponents/FormComponents';
import {
  DECLINE,
  EIK,
  FIRM_ADDRESS,
  FIRM_CITY,
  FIRM_COUNTRY,
  FIRM_MOL,
  FIRM_NAME,
  STATUS,
  UPDATE
} from '../../languages/languages';
import { FirmType } from '../../slices/data/FirmSliceData';

type FirmEditFormProps = {
  item: FirmType;
  cancelEdit: () => void;
  onSubmit: (formData: FirmType) => void;
};
const FirmEditForm = ({
  item,
  cancelEdit,
  onSubmit
}: FirmEditFormProps) => {
  const localizationService = useLocalization();

  return (
    <Dialog title={item.firmName} onClose={cancelEdit}>
      <Form
        onSubmit={onSubmit}
        initialValues={item}
        render={(formRenderProps) =>
          (
            <FormElement>
              <fieldset className='k-form-fieldset'>
                <Field
                  name='active'
                  component={FormSwitch}
                  label={localizationService.toLanguageString(STATUS, '')}
                  value={item.active}
                />
                <Field
                  name='firmEIK'
                  component={FormInput}
                  label={localizationService.toLanguageString(EIK, '')}
                />
                <Field
                  name='firmName'
                  component={FormInput}
                  label={localizationService.toLanguageString(FIRM_NAME, '')}
                />
                <Field
                  name='firmCountry'
                  component={FormInput}
                  label={localizationService.toLanguageString(FIRM_COUNTRY, '')}
                />
                <Field
                  name='firmCity'
                  component={FormInput}
                  label={localizationService.toLanguageString(FIRM_CITY, '')}
                />
                <Field
                  name='firmAddress'
                  component={FormInput}
                  label={localizationService.toLanguageString(FIRM_ADDRESS, '')}
                />
                <Field
                  name='firmMOL'
                  component={FormInput}
                  label={localizationService.toLanguageString(FIRM_MOL, '')}
                />
              </fieldset>
              <fieldset className='k-form-buttons'>
                <Button
                  name='FirmAdd'
                  type='submit'
                  title={localizationService.toLanguageString(UPDATE, '')}
                  primary
                  disabled={!formRenderProps.allowSubmit}
                  icon='save'
                >
                  {localizationService.toLanguageString(UPDATE, '')}
                </Button>
                <Button
                  name='FirmCancel'
                  type='submit'
                  title={localizationService.toLanguageString(DECLINE, '')}
                  primary
                  look='outline'
                  onClick={cancelEdit}
                  icon='cancel'
                >
                  {localizationService.toLanguageString(DECLINE, '')}
                </Button>
              </fieldset>
            </FormElement>
          )}
      />
    </Dialog>
  );
};

export default FirmEditForm;
