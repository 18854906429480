import { Grid, GridColumn } from '@progress/kendo-react-grid';
import React, { useEffect, useState } from 'react';
import { IUneceEnterpriceSettingsValues } from '../../slices/data/UneceSliceData';

interface CodeRepresentationProps {
  values: IUneceEnterpriceSettingsValues;
}

const codeRepresentation = ({ values }: CodeRepresentationProps) => {
  const [items, setItems] = useState([values]);

  useEffect(() => {
    setItems([values]);
  }, [values]);

  return (
    <div>
      <h3>Визуализация код UNECE</h3>
      <Grid data={items}>
        <GridColumn title='1' field='' />
        <GridColumn title='2' field='' />
        <GridColumn title='3' field='' />
        <GridColumn title='4' field='refrigerationId.code' />
        <GridColumn title='5' field='' />
        <GridColumn title='6' field='' />
        <GridColumn title='7' field='' />
        <GridColumn title='8' field='slaughterSystemId.code' />
        <GridColumn title='9' field='postSlaughterSystemId.code' />
        <GridColumn title='10' field='fatThicknessId.code' />
        <GridColumn title='11' field='bovineQualitySystemId.code' />
        <GridColumn title='12' field='weightRangingId.code' />
        <GridColumn title='13' field='packingCodesId.code' />
        <GridColumn title='14' field='conformityAssessmentId.code' />
      </Grid>
    </div>
  );
};

export default codeRepresentation;
