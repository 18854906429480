import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import {
  Field,
  Form,
  FormElement,
  FormRenderProps
} from '@progress/kendo-react-form';
import { useLocalization } from '@progress/kendo-react-intl';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import {
  APPLICATION_METHOD,
  CANCEL,
  CHEMICAL_TYPE,
  DAMAGED_CULTURES,
  DATE,
  METEOROLOGY,
  PEST,
  SAVE,
  USED_CHEMICALS
} from '../../languages/languages';
import {
  PestControlLogInputType,
  PestControlLogType,
  pestDamageLevel
} from '../../slices/data/PestControlSliceData';
import {
  FormComboBox,
  FormDatePicker,
  FormInput
} from '../FormComponents/FormComponents';
import { StringValidator } from '../Validators/Validators';

type PestControlLogFormProps = {
  submit: (formData: PestControlLogInputType) => void;
  close: () => void;
  item?: PestControlLogType;
};
const PestControlLogForm = ({ submit, close, item }: PestControlLogFormProps) => {
  const localizationService = useLocalization();

  const selectedDamageLevel = pestDamageLevel.find((lvl) =>
    lvl.value === item?.damageLevel);

  return (
    <Form
      initialValues={item}
      onSubmit={submit}
      render={(formRenderProps: FormRenderProps) =>
        (
          <FormElement>
            <Field
              name='controlDate'
              format='MM-yyyy'
              component={FormDatePicker}
              label={localizationService.toLanguageString(DATE, '')}
              validator={StringValidator}
            />
            <Field
              name='pest'
              component={FormInput}
              label={localizationService.toLanguageString(PEST, '')}
              validator={StringValidator}
            />
            <Field
              name='damageLevel'
              component={FormComboBox}
              data={pestDamageLevel}
              defaultValue={{
                label: localizationService.toLanguageString(selectedDamageLevel?.label, ''),
                value: selectedDamageLevel?.value
              }}
              textField='label'
              label={localizationService.toLanguageString(PEST, '')}
              validator={StringValidator}
              onChange={({ value }: ComboBoxChangeEvent) => {
                if (value === null) {
                  return;
                }
                formRenderProps.onChange('damageLevel', { value: value.value });
              }}
            />
            <Field
              name='damagedCulture'
              component={FormInput}
              label={localizationService.toLanguageString(DAMAGED_CULTURES, '')}
            />
            <Field
              name='usedChemical'
              component={FormInput}
              label={localizationService.toLanguageString(USED_CHEMICALS, '')}
            />
            <Field
              name='chemicalType'
              component={FormInput}
              label={localizationService.toLanguageString(CHEMICAL_TYPE, '')}
            />
            <Field
              name='meteorology'
              component={FormInput}
              label={localizationService.toLanguageString(METEOROLOGY, '')}
            />
            <Field
              name='applicationMetod'
              component={FormInput}
              label={localizationService.toLanguageString(APPLICATION_METHOD, '')}
            />
            <div className='k-form-buttons'>
              <Button
                name='PestControlLogFormSend'
                type='submit'
                primary
                icon='save'
                title={localizationService.toLanguageString(SAVE, '')}
                disabled={!formRenderProps.allowSubmit}
              >
                {localizationService.toLanguageString(SAVE, '')}
              </Button>
              <Button
                onClick={close}
                name='PestControlLogFormCancel'
                type='submit'
                primary
                look='outline'
                icon='cancel'
                title={localizationService.toLanguageString(CANCEL, '')}
              >
                {localizationService.toLanguageString(CANCEL, '')}
              </Button>
            </div>
          </FormElement>
        )}
    />
  );
};

PestControlLogForm.defaultProps = {
  item: {}
};

export default PestControlLogForm;
