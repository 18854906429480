import React, { useState } from 'react';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridToolbar
} from '@progress/kendo-react-grid';
import { useLocalization } from '@progress/kendo-react-intl';
import { State, process } from '@progress/kendo-data-query';
import { Button } from '@progress/kendo-react-buttons';
import GridSearchBar from '../../features/GridSearchBar/GridSearchBar';
import {
  PEST_CONTROL_LOG_TITLE,
  ADD_NEW,
  APPLICATION_METHOD,
  CHEMICAL_TYPE,
  DAMAGED_CULTURES,
  DATE,
  EXPORT,
  METEOROLOGY,
  PEST,
  PEST_DAMAGE_LEVEL,
  USED_CHEMICALS
} from '../../languages/languages';
import { DateCell, EditCommandCell } from '../CustomGridCells/CustomGridCells';
import ColumnMenu from '../ColumnMenu/ColumnMenu';
import { PestControlLogType, pestDamageLevel } from '../../slices/data/PestControlSliceData';
import { useAppSelector } from '../../app/StoreHooks';
import { selectPestControlLoadingState } from '../../slices/PestControlSlice';
import GridLoaderPanel from '../GridLoaderPanel/GridLoaderPanel';

type PestControlLogGridProps = {
  data: PestControlLogType[];
  addNew: () => void;
  openEdit: (editItem: PestControlLogType) => void;
};

const PestControlLogGrid = ({ data, addNew, openEdit }: PestControlLogGridProps) => {
  const localizationService = useLocalization();
  const loadingData = useAppSelector(selectPestControlLoadingState);

  // Grid States
  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0
  });

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  };

  // Custom cells
  const MyEditCommandCell = (props: GridCellProps) =>
    (
      <EditCommandCell {...props} enterEdit={openEdit} />
    );

  const DamageTypeCell = ({ dataItem, field }: GridCellProps) => {
    const damageLevel = pestDamageLevel.find((level) =>
      level.value === dataItem[field]).label;
    return (
      <td>
        {localizationService.toLanguageString(damageLevel, '')}
      </td>
    );
  };
  return (
    <>
      <h1 className='page-title'>{localizationService.toLanguageString(PEST_CONTROL_LOG_TITLE, '')}</h1>
      <div className='content-wrapper section'>
        <Grid
          data={process(data, dataState)}
          {...dataState}
          onDataStateChange={dataStateChange}
          pageable
          sortable
        >
          <GridToolbar>
            <GridSearchBar
              dataState={dataState}
              filterBy='pest'
              setDataState={setDataState}
            />
            <Button
              name='PestControlLogAddNewBtn'
              type='button'
              title={localizationService.toLanguageString(ADD_NEW, '')}
              onClick={addNew}
              primary
              icon='plus'
            >
              {localizationService.toLanguageString(ADD_NEW, '')}
            </Button>
            <Button
              name='PestControlLogExportBtn'
              type='button'
              title={localizationService.toLanguageString(EXPORT, '')}
              icon='download'
              primary
              look='outline'
            >
              {localizationService.toLanguageString(EXPORT, '')}
            </Button>
          </GridToolbar>
          <Column
            key='controlDate'
            field='controlDate'
            title={localizationService.toLanguageString(DATE, '')}
            filter='date'
            cell={DateCell}
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='pest'
            field='pest'
            title={localizationService.toLanguageString(PEST, '')}
            filter='text'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='damageLevel'
            field='damageLevel'
            cell={DamageTypeCell}
            title={localizationService.toLanguageString(PEST_DAMAGE_LEVEL, '')}
            filter='text'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='damagedCulture'
            field='damagedCulture'
            title={localizationService.toLanguageString(DAMAGED_CULTURES, '')}
            filter='text'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='usedChemical'
            field='usedChemical'
            title={localizationService.toLanguageString(USED_CHEMICALS, '')}
            filter='text'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='chemicalType'
            field='chemicalType'
            title={localizationService.toLanguageString(CHEMICAL_TYPE, '')}
            filter='text'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='meteorology'
            field='meteorology'
            title={localizationService.toLanguageString(METEOROLOGY, '')}
            filter='text'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column
            key='applicationMetod'
            field='applicationMetod'
            title={localizationService.toLanguageString(APPLICATION_METHOD, '')}
            filter='text'
            columnMenu={(props) =>
              <ColumnMenu {...props} />}
          />
          <Column cell={MyEditCommandCell} width={120} />
        </Grid>
        {loadingData && <GridLoaderPanel />}
      </div>
    </>
  );
};

export default PestControlLogGrid;
