import { Dialog } from '@progress/kendo-react-dialogs';
import { useLocalization } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import UsedPesticideLogForm from '../../components/UsedPesticideLogForm/UsedPesticideLogForm';
import UsedPesticideLogGrid from '../../components/UsedPesticideLogGrid/UsedPesticideLogGrid';
import { ADD_NEW, EDIT } from '../../languages/languages';
import { UsedPesticideLogType } from '../../slices/data/UsedPesticideLogSliceData';
import {
  editUsedPesticideEntry,
  getAllUsedPesticideEntries,
  saveUsedPesticideEntry,
  selectAllUsedPesticideLogEntries
} from '../../slices/UsedPesticideLogSlice';

const UsedPesticideLogContainer = () => {
  const dispatch = useAppDispatch();
  const usedPesticideLogs = useAppSelector(selectAllUsedPesticideLogEntries);
  const localizationService = useLocalization();

  useEffect(() => {
    const loadData = () => {
      dispatch(getAllUsedPesticideEntries());
    };
    loadData();
  }, []);

  const [addNewView, setAddNewView] = useState(false);

  const openAddNewView = () => {
    setAddNewView(true);
  };
  const closeAddNewView = () => {
    setAddNewView(false);
  };

  const submitNewLogEntry = (formData: UsedPesticideLogType) => {
    dispatch(saveUsedPesticideEntry(formData));
    closeAddNewView();
  };

  const [editItemView, setEditItemView] = useState(false);
  const [logEntryToEdit, setLogEntryToEdit] = useState<UsedPesticideLogType>(null);
  const showEditForm = (editItem: UsedPesticideLogType) => {
    setEditItemView(true);
    setLogEntryToEdit(editItem);
  };

  const closeEditForm = () => {
    setEditItemView(false);
  };

  const submitEditItem = (formData: UsedPesticideLogType) => {
    dispatch(editUsedPesticideEntry(formData));
    closeEditForm();
  };

  return (
    <>
      <UsedPesticideLogGrid
        editItem={showEditForm}
        addNew={openAddNewView}
        data={usedPesticideLogs}
      />
      {addNewView && (
        <Dialog
          title={localizationService.toLanguageString(ADD_NEW, '')}
          onClose={closeAddNewView}
        >
          <UsedPesticideLogForm
            submit={submitNewLogEntry}
            close={closeAddNewView}
          />
        </Dialog>
      )}
      {editItemView && (
        <Dialog
          title={localizationService.toLanguageString(EDIT, '')}
          onClose={closeEditForm}
        >
          <UsedPesticideLogForm
            item={logEntryToEdit}
            submit={submitEditItem}
            close={closeEditForm}
          />
        </Dialog>
      )}
    </>
  );
};

export default UsedPesticideLogContainer;
