import React from 'react';
import { setupServer } from 'msw/node';
import { setupWorker } from 'msw';
import { Provider } from 'react-redux';
import HoldingHandlers from './handlers/holdingHandlers';
import DashboardHandlers from './handlers/DashboardHandler';
import FirmHandlers from './handlers/FirmHandlers';
import AnimalObjectHandlers from './handlers/AnimalObjectHandler';
import EnterpriseHandlers from './handlers/EnterpriseHandler';
import ShoppingCetnerHandlers from './handlers/ShoppingCenterHandler';
import TrademarksHandlers from './handlers/TrademarkHandler';
import AddressBookContragentsHandlers from './handlers/AddressBookContragentsHandlers';
import FirmsAddressBookHandlers from './handlers/FirmsAddressBookHandlers';
import DealsHandlers from './handlers/DealsHandlers';
import KillDataHandlers from './handlers/KillDataHandlers';
import PurchaseMedicinesHandlers from './handlers/PurchaseMedicinesHandlers';
import FodderLogsHandlers from './handlers/FodderLogsHandlers';

import AddressBookEmployeesHandlers from './handlers/AddressBookEmployeesHandlers';
import store from '../app/Store';

const server = (() => {
  if (process.env.NODE_ENV === 'test') {
    return setupServer(
      ...HoldingHandlers,
      ...DashboardHandlers,
      ...FirmHandlers,
      ...AnimalObjectHandlers,
      ...EnterpriseHandlers,
      ...ShoppingCetnerHandlers,
      ...TrademarksHandlers,
      ...FirmsAddressBookHandlers,
      ...AddressBookEmployeesHandlers,
      ...AddressBookContragentsHandlers,
      ...DealsHandlers,
      ...KillDataHandlers,
      ...PurchaseMedicinesHandlers,
      ...FodderLogsHandlers
    );
  }
  return setupWorker(
    ...HoldingHandlers,
    ...DashboardHandlers,
    ...FirmHandlers,
    ...AnimalObjectHandlers,
    ...EnterpriseHandlers,
    ...ShoppingCetnerHandlers,
    ...TrademarksHandlers,
    ...FirmsAddressBookHandlers,
    ...AddressBookContragentsHandlers,
    ...AddressBookEmployeesHandlers,
    ...DealsHandlers,
    ...KillDataHandlers,
    ...PurchaseMedicinesHandlers,
    ...FodderLogsHandlers
  );
})();

export default server;

type ChildrenProps = {
  children: any;
};

export const setupLifeCycleServer = () => {
  const mockNodeServer: any = server;
  beforeAll(() =>
    mockNodeServer?.listen());
  afterEach(() =>
    mockNodeServer?.resetHandlers());
  afterAll(() =>
    mockNodeServer?.close());
};
const Client = ({ children }: ChildrenProps) =>
  <>{children}</>;
// const dispatch = useAppDispatch();
// const _ = createGQLClient(dispatch);

export const ProviderWithClient = ({ children }: ChildrenProps) =>
  (
    <Provider store={store}>
      <Client>{children}</Client>
    </Provider>
  );
