import { GridColumn as Column, Grid, GridCellProps, GridDetailRowProps } from '@progress/kendo-react-grid';
import React, { useEffect, useState } from 'react';

import { ColumnType } from '../../slices/data/AnimalColumnsSliceData';
import ColumnMenu from '../ColumnMenu/ColumnMenu';
import { CattleBreedCell, GridAnimalSexCell, GridTrueCheckboxCell } from '../CustomGridCells/CustomGridCells';
import GridLoaderPanel from '../GridLoaderPanel/GridLoaderPanel';

type NonSelectableGridPops = {
  data: any[];
  stateColumns: ColumnType[];
  actionCell?: ({ dataItem }: GridCellProps) => JSX.Element;
  detail?: (props: GridDetailRowProps) => JSX.Element;
};

const NonSelectableGrid = ({
  data,
  stateColumns,
  actionCell,
  detail = undefined,
}: NonSelectableGridPops) => {
  // TODO expose method foe setting loading state

  const loadingState = false;
  const [items, setItems] = useState<any[]>([]);

  useEffect(() => {
    if (data) {
      if (detail) {
        setItems(data.map((item) => ({ ...item, expanded: item.expanded || false })));
      } else {
        setItems([...data]);
      }
    }
  }, [data]);

  return (
    <>
      <Grid sortable pageable data={items}>
        {stateColumns.map((column) => {
          if (
            column.show &&
            ['breed', 'sheepBreed', 'cattleBreed'].some((val) => val === column.field)
          ) {
            return (
              <Column
                key={column.field}
                field={column.field}
                title={column.title}
                filter={column.filter}
                format={column.format}
                cell={CattleBreedCell}
                columnMenu={(props) => <ColumnMenu {...props} />}
              />
            );
          }
          if (column.show && (column.field === 'sex' || column.field === 'animalSex')) {
            return (
              <Column
                key={column.field}
                field={column.field}
                title={column.title}
                filter={column.filter}
                format={column.format}
                cell={GridAnimalSexCell}
                columnMenu={(props) => <ColumnMenu {...props} />}
              />
            );
          }
          if (column.show && column.filter === 'boolean') {
            return (
              <Column
                key={column.field}
                field={column.field}
                title={column.title}
                filter={column.filter}
                format={column.format}
                cell={GridTrueCheckboxCell}
                columnMenu={(props) => <ColumnMenu {...props} />}
              />
            );
          }
          if (column.show) {
            return (
              <Column
                key={column.field}
                field={column.field}
                title={column.title}
                filter={column.filter}
                format={column.format}
                filterable={column.filterable}
                columnMenu={(props) => <ColumnMenu {...props} />}
                cell={column.cell}
                width={column.width}
              />
            );
          }
          return null;
        })}
        {actionCell && <Column cell={actionCell} />}
      </Grid>
      {loadingState && <GridLoaderPanel />}
    </>
  );
};

NonSelectableGrid.defaultProps = {
  actionCell: undefined,
  detail: undefined,
};

export default NonSelectableGrid;
