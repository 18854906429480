import React, { useEffect } from 'react';
import { Button } from '@progress/kendo-react-buttons';
import { useLocalization } from '@progress/kendo-react-intl';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import KillDataForm from '../../components/KillDataForm/KillDataForm';
import KillDataGrid from '../../components/KillDataGrid/KillDataGrid';
import { SEND, DECLINE } from '../../languages/languages';
import {
  getAllABEmployees,
  selectAddressBookEmployees,
  selectMatadorsLoadingState
} from '../../slices/EmployeesSlice';
import {
  selectAnimalsToButcher,
  setButcheredAnimals,
  setMatador,
  setButcherDate,
  butcherAnimals
} from '../../slices/AnimalSlice';
import { SelectedAnimalType } from '../../slices/data/AnimalSliceData';

type KillDataContainerProps = {
  close: () => void;
};

const KillDataContainer = ({ close }: KillDataContainerProps) => {
  const localizationService = useLocalization();
  // Redux block
  const dispatch = useAppDispatch();

  // Selectors
  const matadorLoadingState = useAppSelector(selectMatadorsLoadingState);
  const availableMatadors = useAppSelector(selectAddressBookEmployees);
  const butcheredAnimals = useAppSelector(selectAnimalsToButcher);

  // End redux block

  useEffect(() => {
    const awaitData = () => {
      dispatch(getAllABEmployees());
    };
    awaitData();
  }, []);

  // Select matador
  const selectMatador = (id: number) => {
    dispatch(setMatador(id));
  };

  const updateAnimalInformation = (animals: SelectedAnimalType[]) => {
    dispatch(setButcheredAnimals(animals));
  };

  // Cancel the process and reset all state
  const handleCancellation = () => {
    dispatch(setMatador(null));
    dispatch(setButcheredAnimals([]));
    close();
  };

  const setDate = (date: Date) => {
    dispatch(setButcherDate(date));
  };

  // Submit the kill data
  const handleSubmit = () => {
    const butcherData = butcheredAnimals.animals.map((animal) =>
      ({
        animalID: animal.animalId,
        weight: animal.carcassWeight,
        comment: animal.carcassNote,
        dateWeight: butcheredAnimals.dateWeight,
        userId: butcheredAnimals.matadorID,
        weightType: 'CARCASSWEIGHT'
      }));
    dispatch(butcherAnimals(butcherData));
    close();
  };

  return (
    <>
      {matadorLoadingState ? (
        ''
      ) : (
        <KillDataForm
          matadors={availableMatadors}
          setMatador={selectMatador}
          setDate={setDate}
        />
      )}
      <KillDataGrid
        setAnimalData={updateAnimalInformation}
        animals={butcheredAnimals?.animals}
      />
      <div className='k-columnmenu-actions'>
        <Button
          name='KillDataSubmit'
          title={localizationService.toLanguageString(SEND, '')}
          primary
          icon='save'
          onClick={handleSubmit}
        >
          {localizationService.toLanguageString(SEND, '')}
        </Button>
        <Button
          name='KillDataCancel'
          title={localizationService.toLanguageString(DECLINE, '')}
          primary
          look='outline'
          icon='cancel'
          onClick={handleCancellation}
        >
          {localizationService.toLanguageString(DECLINE, '')}
        </Button>
      </div>
    </>
  );
};

export default KillDataContainer;
