import React from 'react';
import { State, process } from '@progress/kendo-data-query';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent
} from '@progress/kendo-react-grid';
import { useLocalization } from '@progress/kendo-react-intl';
import {
  EIK,
  FIRM_ADDRESS,
  FIRM_CITY,
  FIRM_COUNTRY,
  FIRM_MOL,
  FIRM_NAME,
  STATUS
} from '../../languages/languages';
import { FirmType } from '../../slices/data/FirmSliceData';
import ColumnMenu from '../ColumnMenu/ColumnMenu';
import { BooleanCell } from '../FormComponents/FormComponents';
import { useAppSelector } from '../../app/StoreHooks';
import { selectLoadingState } from '../../slices/FirmSlice';
import GridLoaderPanel from '../GridLoaderPanel/GridLoaderPanel';

type FirmGridProps = {
  dataState: State;
  dataStateChange: (event: GridDataStateChangeEvent) => void;
  data: FirmType[];
  cell: (props: GridCellProps) => any;
};

const FirmGrid = ({
  dataState,
  dataStateChange,
  data,
  cell
}: FirmGridProps) => {
  const localizationService = useLocalization();
  const loadingState = useAppSelector(selectLoadingState);

  return (
    <>
      <Grid
        {...dataState}
        onDataStateChange={dataStateChange}
        sortable
        pageable
        pageSize={4}
        data={process(data, dataState)}
      >
        <Column
          field='active'
          title={localizationService.toLanguageString(STATUS, '')}
          cell={BooleanCell}
          filter='boolean'
          columnMenu={ColumnMenu}
          width={150}
        />
        <Column
          field='firmEIK'
          filter='numeric'
          columnMenu={ColumnMenu}
          title={localizationService.toLanguageString(EIK, '')}
        />
        <Column
          field='firmName'
          filter='text'
          columnMenu={ColumnMenu}
          title={localizationService.toLanguageString(FIRM_NAME, '')}
        />
        <Column
          field='firmCountry'
          filter='text'
          columnMenu={ColumnMenu}
          title={localizationService.toLanguageString(FIRM_COUNTRY, '')}
        />
        <Column
          field='firmCity'
          filter='text'
          columnMenu={ColumnMenu}
          title={localizationService.toLanguageString(FIRM_CITY, '')}
        />
        <Column
          field='firmAddress'
          filter='text'
          columnMenu={ColumnMenu}
          title={localizationService.toLanguageString(FIRM_ADDRESS, '')}
        />
        <Column
          field='firmMOL'
          filter='text'
          columnMenu={ColumnMenu}
          title={localizationService.toLanguageString(FIRM_MOL, '')}
        />
        <Column cell={cell} width={120} />
      </Grid>
      {loadingState && <GridLoaderPanel />}
    </>
  );
};

export default FirmGrid;
