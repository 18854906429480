import React, { useEffect, useState, useRef } from 'react';
import {
  Input,
  CheckboxChangeEvent,
} from '@progress/kendo-react-inputs';
import { useLocalization } from '@progress/kendo-react-intl';
import CheckboxConstructor from '../../components/CheckboxConstructor/CheckboxConstructor';
import { useAppDispatch } from '../../app/StoreHooks';
import { HoldingProfile } from '../../slices/data/HoldingSliceData';
import { getFirmNameAndID } from '../../slices/FirmSlice';
import {
  CATTLE,
  GENERAL_INFO,
  HOLDING_NAME,
  HOLDING_STRUCTURE,
  IS_ABATTOIR,
  IS_BUTCHERY,
  IS_FARM,
  IS_FEEDLOT,
  IS_SHOP,
  SHEEP
} from '../../languages/languages';
import GrantAccess from '../GrantAccess/GrantAccess';

const setAttribute = (
  componentRef: React.RefObject<any>,
  attr: string,
  value: string
) => {
  if (componentRef && componentRef.current && componentRef.current.element) {
    componentRef.current.element.setAttribute(`${attr} = ${value}`);
  }
};

type GeneralInfoHoldingProfileProps = {
  data: HoldingProfile;
};

const GeneralInfoHoldingProfile = ({
  data
}: GeneralInfoHoldingProfileProps) => {
  const dispatch = useAppDispatch();

  const inputRef = useRef<Input>(null);
  const localizationService = useLocalization();

  const labels: { id: string; label: string }[] = [
    { id: 'isFarm', label: localizationService.toLanguageString(IS_FARM, '') },
    {
      id: 'isFeedlot',
      label: localizationService.toLanguageString(IS_FEEDLOT, '')
    },
    {
      id: 'isButchery',
      label: localizationService.toLanguageString(IS_BUTCHERY, '')
    },
    {
      id: 'isAbattoir',
      label: localizationService.toLanguageString(IS_ABATTOIR, '')
    },
    { id: 'isShop', label: localizationService.toLanguageString(IS_SHOP, '') },
    { id: 'cattle', label: localizationService.toLanguageString(CATTLE, '') },
    { id: 'sheep', label: localizationService.toLanguageString(SHEEP, '') }
  ];

  // Local state
  const [localHolding, setLocalHolding] = useState<HoldingProfile | any>({});

  useEffect(() => {
    setLocalHolding(data);
    const onLoad = () => {
      dispatch(getFirmNameAndID());
    };
    onLoad();
    setAttribute(inputRef, 'validator', 'StringValidator');
  }, []);

  const handleChange = async (id: string, value: boolean) => {
    setLocalHolding({
      ...localHolding,
      [id]: value
    });
  };

  return (
    <GrantAccess haveRoles='some' requiredRoles={['Holding Owner']}>
      <h3>
        {localizationService.toLanguageString(GENERAL_INFO, '')}
        :
      </h3>
      <Input
        name='holdingName'
        label={localizationService.toLanguageString(HOLDING_NAME, '')}
        placeholder={localizationService.toLanguageString(HOLDING_NAME, '')}
        disabled
        value={localHolding.holdingName}
      />
      <div id='holding-structure' className='mb-l mt-l'>
        <h3 className='subtitle-1 mb-s'>{localizationService.toLanguageString(HOLDING_STRUCTURE, '')}</h3>
        <div className='d-flex'>
          {labels.map(({ id, label }) =>
            (
              <CheckboxConstructor
                key={id}
                disabled
                checked={localHolding[id]}
                label={label}
                id={label}
                onChange={({ value }: CheckboxChangeEvent) =>
                  handleChange(id, value)}
              />
            ))}
        </div>
      </div>
    </GrantAccess>
  );
};

export default GeneralInfoHoldingProfile;
