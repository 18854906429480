import React, { useRef } from 'react';
import { State, process } from '@progress/kendo-data-query';
import { useLocalization } from '@progress/kendo-react-intl';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridToolbar
} from '@progress/kendo-react-grid';
import { Button } from '@progress/kendo-react-buttons';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { ContragentType } from '../../slices/data/ContragentsSliceData';
import {
  ADDRESS_BOOK_EMAIL,
  ADDRESS_BOOK_FIRM,
  ADDRESS_BOOK_NAME,
  ADDRESS_BOOK_PHONE,
  ADD_NEW,
  EXPORT
} from '../../languages/languages';
import GridSearchBar from '../../features/GridSearchBar/GridSearchBar';
import ColumnMenu from '../ColumnMenu/ColumnMenu';
import { useAppSelector } from '../../app/StoreHooks';
import { selectContragentsLoading } from '../../slices/ContragentsSlice';
import GridLoaderPanel from '../GridLoaderPanel/GridLoaderPanel';
import GrantAccess from '../../features/GrantAccess/GrantAccess';
import { EditCommandCell } from '../CustomGridCells/CustomGridCells';

const REQUIRED_ROLES: string[] = ['Admin', 'Holding Owner', 'Farm Manager'];

type ContragentsGridProps = {
  data: ContragentType[];
  enterEdit: (item: ContragentType) => void
  addContragent: () => void;
  exportExl: () => void;
};
const ContragentsGrid = ({
  data, addContragent, exportExl, enterEdit
}:ContragentsGridProps) => {
  const localizationService = useLocalization();
  const loadingState = useAppSelector(selectContragentsLoading);

  // Grid state and functions
  const [dataState, setDataState] = React.useState<State>({
    take: 10,
    skip: 0,
  });

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataState(event.dataState);
  };

  const MyEditCommandCell = (props: GridCellProps) =>
    (
      <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
        <EditCommandCell {...props} enterEdit={enterEdit} />
      </GrantAccess>
    );

  const exlExp = useRef<ExcelExport | null>(null);
  return (
    <ExcelExport data={data} ref={exlExp}>
      <Grid
        {...dataState}
        onDataStateChange={dataStateChange}
        sortable
        pageable
        data={process(data, dataState)}
      >
        <GridToolbar>
          <GridSearchBar
            dataState={dataState}
            setDataState={setDataState}
            filterBy='contragentName'
          />
          <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
            <Button title='Add New' icon='plus' primary onClick={addContragent}>
              {localizationService.toLanguageString(ADD_NEW, '')}
            </Button>
            <Button
              title='Export Excel'
              icon='download'
              look='outline'
              onClick={exportExl}
            >
              {localizationService.toLanguageString(EXPORT, '')}
            </Button>
          </GrantAccess>
        </GridToolbar>
        <Column
          title={localizationService.toLanguageString(ADDRESS_BOOK_NAME, '')}
          field='contragentName'
          filter='text'
          columnMenu={ColumnMenu}
        />
        <Column
          title={localizationService.toLanguageString(ADDRESS_BOOK_PHONE, '')}
          field='contragentPhone'
          filter='text'
          columnMenu={ColumnMenu}
        />
        <Column
          title={localizationService.toLanguageString(ADDRESS_BOOK_EMAIL, '')}
          field='contragentEmail'
          filter='text'
          columnMenu={ColumnMenu}
        />
        <Column
          title={localizationService.toLanguageString(ADDRESS_BOOK_FIRM, '')}
          field='firm.firmABName'
          filter='text'
          columnMenu={ColumnMenu}
        />
        <Column cell={MyEditCommandCell} width={120} />
      </Grid>
      {loadingState && <GridLoaderPanel />}
    </ExcelExport>
  );
};

export default ContragentsGrid;
