import { Dialog } from '@progress/kendo-react-dialogs';
import { useLocalization } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import PestControlLogForm from '../../components/PestControlLogForm/PestControlLogForm';
import PestControlLogGrid from '../../components/PestControlLogGrid/PestControlLogGrid';
import { ADD_NEW, EDIT } from '../../languages/languages';
import {
  PestControlLogInputType,
  PestControlLogType
} from '../../slices/data/PestControlSliceData';
import {
  editPestControlLogEntry,
  getAllPestControlLogs,
  savePestControlLogEntry,
  selectPestControlLogs
} from '../../slices/PestControlSlice';

const PestControlLogContainer = () => {
  const localizationService = useLocalization();
  const dispatch = useAppDispatch();
  const pestControlLogs = useAppSelector(selectPestControlLogs);

  useEffect(() => {
    const loadData = () => {
      dispatch(getAllPestControlLogs());
    };
    loadData();
  }, []);

  const [addNewView, setAddNewView] = useState(false);

  const openAddNewView = () => {
    setAddNewView(true);
  };
  const closeAddNewView = () => {
    setAddNewView(false);
  };

  const submitNewEntry = (formData: PestControlLogInputType) => {
    dispatch(savePestControlLogEntry(formData));
    closeAddNewView();
  };

  const [editFodderView, setEditFodderView] = useState<boolean>(false);
  const [logEntryToEdit, setLogEntryToEdit] = useState<PestControlLogType>(null);

  const openEditFormView = (editItem: PestControlLogType) => {
    setLogEntryToEdit(editItem);
    setEditFodderView(true);
  };

  const closeEditFormView = () => {
    setEditFodderView(false);
  };

  const submitEditEntry = (formData: PestControlLogInputType) => {
    dispatch(editPestControlLogEntry(formData));
    closeEditFormView();
  };

  return (
    <>
      <PestControlLogGrid
        openEdit={openEditFormView}
        data={pestControlLogs}
        addNew={openAddNewView}
      />
      {addNewView && (
        <Dialog
          title={localizationService.toLanguageString(ADD_NEW, '')}
          onClose={closeAddNewView}
        >
          <PestControlLogForm submit={submitNewEntry} close={closeAddNewView} />
        </Dialog>
      )}
      {editFodderView && (
        <Dialog
          title={localizationService.toLanguageString(EDIT, '')}
          onClose={closeEditFormView}
        >
          <PestControlLogForm
            item={logEntryToEdit}
            submit={submitEditEntry}
            close={closeEditFormView}
          />
        </Dialog>
      )}
    </>
  );
};

export default PestControlLogContainer;
