import { Rating } from '@progress/kendo-react-inputs';
import { AppBarSection } from '@progress/kendo-react-layout';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useLocalization } from '@progress/kendo-react-intl';
import moment from 'moment';
import 'moment/locale/bg';

import { ReactComponent as ReactLogo } from '../../images/BLB_logo_bg.svg';
import PublicService, { EnterprisePublicData } from '../../services/public.service';
import {
  FARM_MAIN_SUBTITLE,
  LOCATION,
  OTHER_HOLDING_OBJECTS,
  PAGE_MAIN_TITLE,
  SOCIAL_MEDIA_SHARE,
  TAGS,
} from '../../languages/keys/public.keys';
import BulletinSubsciption from '../BulletinSubscription/BulletinSubscription';
import SocialMediaShare from '../SocialMediaShare/SocialMediaShare';
import HoldingObjects from '../HoldingObjects/HoldingObject';
import { NO, YES } from '../../languages/languages';
import ProcessingChart from '../PublicEnterpriseCharts/ProcessingChart';
import GradesChart from '../PublicEnterpriseCharts/GradesChart';
import CarcassYieldChart from '../PublicEnterpriseCharts/CarcassYieldChart';
import Footer from '../PublicFooter/Footer';

interface Params {
  id: string;
}
moment.locale('bg');

const FarmerPublicData = () => {
  // We can use the `useParams` hook here to access
  // the dynamic pieces of the URL.
  const { id } = useParams<Params>();
  const [data, setData] = useState<EnterprisePublicData>();
  const $t = useLocalization();

  useEffect(() => {
    PublicService.getEnterprisePublicData(parseInt(id, 10)).then((enterpriseData) => {
      if (enterpriseData) {
        setData(enterpriseData);
      }
    });
  }, []);

  return (
    <div>
      <header className='public-header'>
        <AppBarSection className='logo-section col-sm-2'>
          <div className='main-logo'>
            <ReactLogo />
          </div>
        </AppBarSection>
        <div className='section row'>
          <div className='h2'>{$t.toLanguageString(PAGE_MAIN_TITLE, '')}</div>
          <div className='h5'>{$t.toLanguageString(FARM_MAIN_SUBTITLE, '')}</div>
        </div>
      </header>
      <section className='pl-l pr-l pt-l row main-content'>
        <div className='col-md-8 ml-auto mr-auto'>
          {data && (
            <>
              <div className='row pl-l pr-l mb-l'>
                <div className='col-12 col-md-6'>
                  <div>
                    <div style={{ height: '250px' }}>
                      <div
                        style={{
                          backgroundImage: 'url(/img/no-logo.png)',
                          backgroundOrigin: 'center',
                          backgroundPosition: 'center',
                          backgroundSize: 'contain',
                          backgroundRepeat: 'no-repeat',
                          width: '100%',
                          height: '100%',
                        }}
                      />
                    </div>
                    <div>
                      Оценка
                      <div>
                        <Rating defaultValue={data.rating} readonly />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div className='row pl-l pr-l mb-m'>
                    <div className='col-6 h3 fw-bold'>Ферма</div>
                    <div className='col-6 h3 fw-bold'>{data.name}</div>
                  </div>
                  <div className='row pl-l pr-l mb-s'>
                    <div className='col-6 fw-bold'>Холдинг</div>
                    <div className='col-6'>{data.holding}</div>
                  </div>
                  <div className='row pl-l pr-l mb-s'>
                    <div className='col-6 fw-bold'>БАБХ &#8470;</div>
                    <div className='col-6'>{data.number}</div>
                  </div>
                  <div className='row pl-l pr-l mb-s'>
                    <div className='col-6 fw-bold'>БГАМ членство от</div>
                    <div className='col-6'>{moment(data.memberFrom).format('DD MMMM YYYY')}</div>
                  </div>
                  <div className='row pl-l pr-l mb-s'>
                    <div className='col-6 fw-bold'>Преработка на говеждо</div>
                    <div className='col-6'>
                      {$t.toLanguageString(data.beefProcessing ? YES : NO, '')}
                    </div>
                  </div>
                  <div className='row pl-l pr-l mb-s'>
                    <div className='col-6 fw-bold'>Преаработка на агнешко</div>
                    <div className='col-6'>
                      {$t.toLanguageString(data.lambProcessing ? YES : NO, '')}
                    </div>
                  </div>
                  <div className='row pl-l pr-l mb-s'>
                    <div className='col-6 fw-bold'>Продукти</div>
                    <div className='col-6'>{data.products.join(', ')}</div>
                  </div>
                  <div className='row pl-l pr-l mb-s'>
                    <div className='col-6 fw-bold'>Бранд</div>
                    <div className='col-6'>{data.brand}</div>
                  </div>
                  <div className='row pl-l pr-l mb-s'>
                    <div className='col-6 fw-bold'>Адрес</div>
                    <div className='col-6'>{data.address}</div>
                  </div>
                  <div className='row pl-l pr-l mb-s'>
                    <div className='col-6 fw-bold'>Последна доставка жовотни</div>
                    <div className='col-6'>
                      {data.lastAnimalDelivery
                        ? moment(data.lastAnimalDelivery).format('DD MMMM YYYY')
                        : ''}
                    </div>
                  </div>
                </div>
              </div>
              <div className='row pl-l pr-l mb-l'>
                <div className='col-12 col-md-6'>
                  <div>{$t.toLanguageString(TAGS, '')}</div>
                  <div>
                    {data.tags.map((t) => (
                      <div className='k-chip k-chip-md k-rounded-md k-chip-solid k-chip-solid-base mr-s'>
                        <span className='k-chip-content'>
                          <span className='k-chip-label'>{t}</span>
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div>{$t.toLanguageString(SOCIAL_MEDIA_SHARE, '')}</div>
                  <SocialMediaShare />
                </div>
              </div>
              <div className='row pl-l pr-l mb-l'>
                <div className='col-12 col-md-6'>
                  <div className='h3'>{$t.toLanguageString(null, 'Преработка')}</div>
                  <div className='pl-s pr-s'>
                    <ProcessingChart />
                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div className='h3'>{$t.toLanguageString(LOCATION, '')}</div>
                  <div className='pl-s pr-s'>
                    <img src='/img/maps.webp' alt='location' style={{ height: '350px', width: '100%' }} />
                  </div>
                </div>
              </div>
              <div className='row pl-l pr-l mb-l'>
                <div className='col-12 col-md-6'>
                  <div className='h3'>{$t.toLanguageString(null, 'Добиви и оценки')}</div>
                  <div className='pl-s pr-s'>
                    <GradesChart />
                  </div>
                </div>
                <div className='col-12 col-md-6'>
                  <div className='h3'>{$t.toLanguageString(null, 'Излязло трупно')}</div>
                  <div className='pl-s pr-s'>
                    <CarcassYieldChart />
                  </div>
                </div>
              </div>
              <div className='row pl-l pr-l mb-l'>
                <div className='h3'>{$t.toLanguageString(OTHER_HOLDING_OBJECTS, '')}</div>
                <HoldingObjects objects={data.otherObjects} />
              </div>
              <BulletinSubsciption />
              <Footer />
            </>
          )}
        </div>
      </section>
    </div>
  );
};

export default FarmerPublicData;
