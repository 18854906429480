import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useLocalization } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import FarmarVirtualWarehouseGrid from '../../components/FarmarVirtualWarehouse/FarmarVirtualWarehouseGrid';
import { ACTIONS } from '../../languages/keys/keys';
import { VIRTUAL_WAREHOUSE_TITLE } from '../../languages/keys/warehouse.keys';
import { AvailableFirmsType } from '../../slices/data/FirmSliceData';
import { getActiveFirmNameAndID, selectAvailableFirms } from '../../slices/FirmSlice';

const EnterpriseWarehouseContainer = () => {
  const $t = useLocalization();
  const [selectedItems, setSelectedItems] = useState([]);
  const firms = useAppSelector(selectAvailableFirms);
  const [firm, setFirm] = useState<AvailableFirmsType>();
  const onFirmChange = (e) => {
    setFirm(e.value);
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getActiveFirmNameAndID());
  }, []);

  const onSelect = (data) => {
    setSelectedItems(data);
  };

  const gridActions = () => {
    console.log('grid actions');

    return (
      <>
        {selectedItems.length > 0 && (
          <Button iconClass='k-icon fas fa-bolt' primary look='outline'>
            {$t.toLanguageString(ACTIONS, '')}
          </Button>
        )}
      </>
    );
  };

  return (
    <div>
      <h1 className='page-title'>{$t.toLanguageString(VIRTUAL_WAREHOUSE_TITLE, '')}</h1>
      <div className='row'>
        <div className='col-12'>
          <div>Фирма</div>
          <DropDownList
            data={firms}
            textField='firmName'
            dataItemKey='firmId'
            onChange={onFirmChange}
          />
        </div>
      </div>
      <hr />
      {!firm && (
        <div className='text-error'>
          <i className='fa fa-exclamation-circle' /> &nbsp; Изберете Фирма
        </div>
      )}
      {firm && (
        <FarmarVirtualWarehouseGrid
          firmId={firm.firmId}
          onSelect={onSelect}
          actions={gridActions}
        />
      )}
    </div>
  );
};

export default EnterpriseWarehouseContainer;
