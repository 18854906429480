import { Button } from '@progress/kendo-react-buttons';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { useLocalization } from '@progress/kendo-react-intl';
import { format } from '@telerik/kendo-intl';
import React, { ElementRef, useEffect, useRef, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import GrantAccess from '../../features/GrantAccess/GrantAccess';
import { LOAD, STEP_FROM } from '../../languages/keys/keys';
import { CHOSEN, NO_ANIMAL_OBJECT_SELECTED, NO_RETURN_MSG, PROCESS_FORWARD_CONFIRM } from '../../languages/keys/movements.keys';
import { CONFIRM, FORWARD, NO, YES } from '../../languages/languages';
import { getAnimalObjectsByFirm, selectAnimalObjects } from '../../slices/AnimalObjectSlice';
import { AnimalObjectType } from '../../slices/data/AnimalObjectSliceData';
import { AnimalType } from '../../slices/data/AnimalSliceData';
import { AvailableFirmsType } from '../../slices/data/FirmSliceData';
import { getActiveFirmNameAndID, selectAvailableFirms } from '../../slices/FirmSlice';
import AnimalsInObjectGrid from '../AnimalGrid/AnimalsInObjectGrid';
import { FormDropDownList } from '../FormComponents/FormComponents';

// TODO кои допълнителни роли трябва да се добавят
const REQUIRED_ROLES: string[] = ['Admin', 'Holding Owner', 'Farm Manager'];

export interface UnregisterSelectionProps {
  onNext: (animals: AnimalType[], animalObjectId: number) => void;
}

const UnregisterAnimalSelection = ({ onNext }: UnregisterSelectionProps) => {
  const localizationService = useLocalization();
  type CountdownHandle = ElementRef<typeof AnimalsInObjectGrid>;
  const gridRef = useRef<CountdownHandle>(null);
  const dispatch = useAppDispatch();
  const animalObjects = useAppSelector(selectAnimalObjects);
  const firms = useAppSelector(selectAvailableFirms);
  const [firm, setFirm] = useState<AvailableFirmsType>();
  const [animalObject, setAnimalObject] = useState<AnimalObjectType>();
  const [animals, setAnimals] = useState<AnimalType[]>([]);
  const [showOnNextDialog, setOnNextDialog] = useState(false);
  const [showGrid, setShowGrid] = useState(false);

  const onLoadAnimal = () => {
    setShowGrid(true);
    // this is needed to put the function in the next callstack
    // so the reload method can be call
    setTimeout(() => {
      if (gridRef.current) {
        gridRef.current.reload();
      }
    }, 0);
  };

  const onFirmChange = (e) => {
    setFirm(e.value);
  };

  const onObejectChange = (e) => {
    setAnimalObject(e.value);
  };

  const onSubmit = () => {
    setOnNextDialog(true);
  };

  const onConfirm = () => {
    onNext(animals, animalObject.animalObjectId);
  };

  const closeDialog = () => {
    setOnNextDialog(false);
  };

  const onAnimalSelect = (items: AnimalType[]) => {
    setAnimals(items);
  };

  useEffect(() => {
    if (firm) {
      const awaitData = async () => {
        await dispatch(getAnimalObjectsByFirm({ firmId: firm.firmId, active: true }));
      };
      awaitData();
    }
  }, [firm]);

  useEffect(() => {
    const awaitData = async () => {
      await dispatch(getActiveFirmNameAndID());
    };
    awaitData();
  }, []);

  return (
    <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
      <h3 className='page-title'>
        {localizationService.toLanguageString(null, 'Избор на животни')}
      </h3>
      <div className='content-wrapper section'>
        <div>
          <Form
            onSubmitClick={onLoadAnimal}
            render={() => (
              <FormElement>
                <div className='row'>
                  <div className='col-12 col-md-4 col-xl-3 pl-s pr-s'>
                    <Field
                      id='firmId'
                      name='firmId'
                      label='Фирма'
                      component={FormDropDownList}
                      data={firms}
                      textField='firmName'
                      dataItemKey='firmId'
                      onChange={onFirmChange}
                      value={firm}
                    />
                  </div>
                  <div className='col-12 col-md-4 col-xl-3 pl-s pr-s'>
                    <Field
                      id='animalObjectId'
                      name='animalObjectId'
                      label='Ж.О.'
                      component={FormDropDownList}
                      data={animalObjects.map((x) => ({
                        animalObjectId: x.animalObjectId,
                        animalObjectName: `${x.animalObjectName} (${x.animalObjectNumber})`,
                      }))}
                      textField='animalObjectName'
                      dataItemKey='animalObjectId'
                      onChange={onObejectChange}
                      value={animalObject}
                    />
                  </div>
                  <div className='col-12 col-md-4 col-xl-3 pl-s pr-s d-flex align-items-center'>
                    <Button
                      name='loadData'
                      type='submit'
                      title={localizationService.toLanguageString(LOAD, '')}
                      primary
                      disabled={!animalObject}
                    >
                      {localizationService.toLanguageString(LOAD, '')}
                    </Button>
                  </div>
                </div>
              </FormElement>
            )}
          />
        </div>
        <hr />
        <div>
          {showGrid && (
            <AnimalsInObjectGrid
              objectId={animalObject?.animalObjectId}
              ref={gridRef}
              onSelect={onAnimalSelect}
            />
          )}
          {!showGrid && (
            <div className='text-error'>
              <i className='fas fa-exclamation-circle mr-s' />
              {localizationService.toLanguageString(NO_ANIMAL_OBJECT_SELECTED, '')}
            </div>
          )}
        </div>
        <hr />
        <div className='d-flex justify-content-center mb-l align-items-center'>
          <div className='mr-l' style={{ height: 'fit-content' }}>
            {format(localizationService.toLanguageString(STEP_FROM, ''), [1, 3])}
          </div>
          <Button
            name='AnimalSelectSubmit'
            type='button'
            title={localizationService.toLanguageString(FORWARD, '')}
            primary
            iconClass='fa fa-share'
            onClick={onSubmit}
            disabled={animals.length === 0}
          >
            {localizationService.toLanguageString(FORWARD, '')}
          </Button>
        </div>
        <hr />
        {showOnNextDialog && (
          <Dialog title={localizationService.toLanguageString(CONFIRM, '')} onClose={closeDialog}>
            <div>
              <h5 className='text-primary'>
                {format(localizationService.toLanguageString(CHOSEN, ''), [animals.length])}
              </h5>
              <h4>
                <strong>{localizationService.toLanguageString(PROCESS_FORWARD_CONFIRM, '')}</strong>
              </h4>
              <p className='text-error'>
                <i className='fa fa-exclamation-circle' />{' '}
                {localizationService.toLanguageString(NO_RETURN_MSG, '')}
              </p>
            </div>
            <DialogActionsBar>
              <Button
                primary
                className='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base'
                onClick={onConfirm}
              >
                {localizationService.toLanguageString(YES, '')}
              </Button>
              <Button
                className='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base'
                onClick={closeDialog}
                look='outline'
              >
                {localizationService.toLanguageString(NO, '')}
              </Button>
            </DialogActionsBar>
          </Dialog>
        )}
      </div>
    </GrantAccess>
  );
};

export default UnregisterAnimalSelection;
