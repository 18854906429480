import { Button } from '@progress/kendo-react-buttons';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useLocalization } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';

import { CONFIRM } from '../../languages/languages';
import dropdownService, { IDropdownModel } from '../../services/dropdown.service';
import { AnimalInButcheryGridModel } from '../../slices/data/ButcheryProcessSliceData';
import AnimalsForButcheryGrid from '../AnimalGrid/AnimalsForButcheryGrid';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';

interface AnimalTabProps {
  onNext: (
    animals: AnimalInButcheryGridModel[],
    enterpriseNumber: string,
    enterpriseId: number
  ) => void;
}

const AnimalTab = ({ onNext }: AnimalTabProps) => {
  const $t = useLocalization();
  const [selectedAnimals, setSelectedAnimals] = useState<AnimalInButcheryGridModel[]>([]);
  const [showConfirm, setShowConfirm] = useState(false);
  const [enterprises, setEnterprises] = useState<IDropdownModel[]>([]);
  const [enterprise, setEnterprise] = useState<IDropdownModel>();

  useEffect(() => {
    setSelectedAnimals(selectedAnimals);
    dropdownService
      .getEnterprices()
      .then((items) => {
        setEnterprises(items);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    // TODO put enterpriseId in the batch
  }, [enterprise]);

  const onSelectionChanged = (selection: AnimalInButcheryGridModel[]) => {
    setSelectedAnimals(selection);
  };

  const onConfirm = (result: boolean) => {
    setShowConfirm(false);

    if (result) {
      onNext(selectedAnimals, enterprise.info, enterprise.id);
    }
  };

  return (
    <div className='pl-l pr-l'>
      <div className='row mb-l'>
        <div className='col-12'>
          <h2>Избор на жовотни за партида</h2>
          <p>Изберете животните, които ще бъдат заклани.</p>
        </div>
        <hr />
      </div>
      <div className='row'>
        <div className='col-12'>
          <div>Кланица</div>
          <DropDownList
            data={enterprises}
            key='id'
            textField='text'
            onChange={(e) => setEnterprise(e.value)}
          />
        </div>
      </div>
      <hr />
      <div className='row'>
        <div className='col-12'>
          {enterprise && (
            <AnimalsForButcheryGrid onSelect={onSelectionChanged} enterpriseId={enterprise.id} />
          )}
        </div>
      </div>
      <div className='row'>
        <div className='col-12 pt-l d-flex justify-content-center'>
          {!enterprise && <div className='text-error'>Изберете кланица</div>}
          {selectedAnimals.length > 0 && (
            <Button primary onClick={() => setShowConfirm(true)}>
              Създай партида
            </Button>
          )}
        </div>
      </div>
      {showConfirm && (
        <ConfirmDialog
          message='Сигурни ли сте, че искате да създадете партида с избраните животни? Повече няма да можете да коригирате избора си!'
          title={$t.toLanguageString(CONFIRM, '')}
          onResult={onConfirm}
        />
      )}
    </div>
  );
};

export default AnimalTab;
