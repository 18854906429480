import { Button } from '@progress/kendo-react-buttons';
// import { useLocalization } from '@progress/kendo-react-dateinputs';
import { useLocalization } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';

import { CONFIRM, FORWARD } from '../../languages/languages';
import { IBatch, IBatchAnimal } from '../../slices/data/ButcheryProcessSliceData';
import PhTGrid from '../AnimalGrid/PhTGrid';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';

// const dateFormat = 'dd-MM-yyyy';

export interface IPhTTabProps {
  batch: IBatch;
  onNext: () => void;
}

const PhTTab = ({ batch, onNext }: IPhTTabProps) => {
  const $t = useLocalization();
  const [selectedAnimals, setSelectedAnimals] = useState<IBatchAnimal[]>([]);
  // const [storageDate, setStorageDate] = useState(new Date());

  // const onButcheredDateChanged = (ev: DatePickerChangeEvent) => {
  //   setStorageDate(ev.value);
  // };

  useEffect(() => {
    // batch.animals.forEach((animal) => {
    //   animal.butcheredDate = butcheredDate;
    // });
    setSelectedAnimals(batch.animals);
  }, [batch]);

  const setAnimals = (items: IBatchAnimal[]) => {
    setSelectedAnimals(items);
  };

  const saveWeights = () => {
    batch.animals = selectedAnimals;
  };

  const [showSaveMessage, setShowSaveMessage] = useState(false);

  const onSave = (result: boolean) => {
    if (result) {
      saveWeights();
      onNext();
    }
    setShowSaveMessage(false);
  };

  return (
    <div className='pl-l pr-l'>
      {/* <div className='row'>
        <div className='col-12 col-md-4'>
          <div>Вход в склад - дата</div>{' '}
          <div>
            <DatePicker onChange={onButcheredDateChanged} format={dateFormat} value={storageDate} />
          </div>
        </div>
      </div> */}
      <div className='row'>
        <div className='col-12'>
          {selectedAnimals.length && (
            <PhTGrid
              animals={selectedAnimals}
              batch={batch}
              onSelect={setAnimals}
            />
          )}
        </div>
        <div className='col-12 d-flex justify-content-center mt-l'>
          <Button primary onClick={() => setShowSaveMessage(true)}>
            {$t.toLanguageString(FORWARD, '')}
          </Button>
        </div>
      </div>
      {showSaveMessage && (
        <ConfirmDialog
          message='Всички животни маркирани като "Брак" ще бъдат отписани от системата. Желаете ли да продължите?'
          title={$t.toLanguageString(CONFIRM, '')}
          onResult={onSave}
        />
      )}
    </div>
  );
};

export default PhTTab;
