import React, { useState } from 'react';
import { Field } from '@progress/kendo-react-form';
import {
  NumericTextBoxChangeEvent,
  RadioButtonChangeEvent
} from '@progress/kendo-react-inputs';
import { useLocalization } from '@progress/kendo-react-intl';
import {
  FormNumericTextBox,
  FormRadioGroup
} from '../FormComponents/FormComponents';
import { requiredValidator } from '../Validators/Validators';
import { useAppDispatch } from '../../app/StoreHooks';
import { setSaleData } from '../../slices/SellOperationSlice';
import {
  DEAL_ANIMAL_PPH,
  DEAL_ANIMAL_PPK,
  DEAL_PRICING,
  ENTER_PRICE_PER_ANIMAL,
  ENTER_PRICE_PER_HEAD,
  PRICE_PER_ANIMAL,
  PRICE_PER_KILO
} from '../../languages/languages';

const options: { value: string; label: string }[] = [
  { value: 'pricePerHead', label: PRICE_PER_ANIMAL },
  { value: 'pricePerKilo', label: PRICE_PER_KILO }
];

const PricingDetails = () => {
  const localizationService = useLocalization();
  // Select which pricing option will be selected
  const [option, setOption] = useState<string>('');

  // Redux variables
  const dispatch = useAppDispatch();
  // const selectedAnimals = useAppSelector(selectSelectedAnimals);

  // Handle changes to the pricing option.
  const handleOptionChange = ({ value }: RadioButtonChangeEvent) => {
    setOption(value);
    dispatch(setSaleData(['pricePerKilo', null]));
    dispatch(setSaleData(['pricePerHead', null]));
  };

  // Set the pricePerHead and pricePerKilo values of the redux state
  // with the value in the input field, depending on which field is being updated
  const handleValueChange = ({ value, target }: NumericTextBoxChangeEvent) => {
    dispatch(setSaleData([target.name, value]));
  };

  // Render the appropriate fields, depending on the selected value
  const renderFields = () => {
    switch (option) {
      case 'pricePerHead':
        return (
          <fieldset className='k-form-fieldset'>
            <Field
              key='pricePerHead'
              id='pricePerHead'
              name='pricePerHead'
              label={localizationService.toLanguageString(
                ENTER_PRICE_PER_HEAD,
                ''
              )}
              placeholder={localizationService.toLanguageString(
                DEAL_ANIMAL_PPH,
                ''
              )}
              component={FormNumericTextBox}
              validator={requiredValidator}
              onChange={handleValueChange}
            />
          </fieldset>
        );

      case 'pricePerKilo':
        return (
          <fieldset className='k-form-fieldset'>
            <Field
              key='pricePerKilo'
              id='pricePerKilo'
              name='pricePerKilo'
              label={localizationService.toLanguageString(
                ENTER_PRICE_PER_ANIMAL,
                ''
              )}
              placeholder={localizationService.toLanguageString(
                DEAL_ANIMAL_PPK,
                ''
              )}
              component={FormNumericTextBox}
              validator={requiredValidator}
              onChange={handleValueChange}
            />
          </fieldset>
        );
      default:
        return null;
    }
  };

  const localizedOptions = options.map((opt) =>
    ({
      value: opt.value,
      label: localizationService.toLanguageString(opt.label, '')
    }));

  return (
    <div className='sell-details-form'>
      <legend className='subtitle-1'>
        {localizationService.toLanguageString(DEAL_PRICING, '')}
      </legend>

      {/* Pricing options */}
      <fieldset className='k-form-fieldset'>
        <Field
          layout='horizontal'
          key='priceOptions'
          id='priceOptions'
          name='priceOptions'
          data={localizedOptions}
          onChange={handleOptionChange}
          component={FormRadioGroup}
          validator={requiredValidator}
        />
      </fieldset>
      {renderFields()}
    </div>
  );
};

export default PricingDetails;
