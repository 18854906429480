import { gql } from '@apollo/client';

// Types
export type ShoppingCenterType = {
  shoppingCenterId: number;
  shoppingCenterName: string;
  shoppingCenterNumber: string;
  shoppingCenterAddress: string;
  active: boolean;
  firmId: number;
  shoppingCenterType: string;
};

export type ShoppingCenterResponseType = {
  items: ShoppingCenterType[];
};

export type ShoppingCenterInputType = {
  shoppingCenter: ShoppingCenterType
};

export const GET_SHOPPING_CENTERS = gql`
  query {
    shoppingCenter {
      items {
        shoppingCenterId
        shoppingCenterName
        shoppingCenterNumber
        shoppingCenterAddress
        active
        firmId
        shoppingCenterType
      }
    }
  }
`;

export const EDIT_SHOPPING_CENTER = gql`
  mutation updateShoppingCenter($shoppingCenter: shopingCenterInput) {
    updateShoppingCenter(shoppingCenter: $shoppingCenter) {
      shoppingCenterId
      shoppingCenterName
      shoppingCenterNumber
      shoppingCenterAddress
      active
      firmId
      shoppingCenterType
    }
  }
`;

export const SAVE_SHOPPING_CENTER = gql`
  mutation addShoppingCenter($shoppingCenter: shopingCenterInput) {
    addShoppingCenter(shoppingCenter: $shoppingCenter) {
      shoppingCenterId
      shoppingCenterName
      shoppingCenterNumber
      shoppingCenterAddress
      active
      firmId
      shoppingCenterType
    }
  }
`;
