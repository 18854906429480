/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/StoreHooks';
import {
  FirmsAddressBookType,
  GET_ADDRBOOK_FIRMS,
  AddressBookFirmInputType,
  SaveAddressBookFirmType,
  GetAllFirmsAddressBookResponseType,
  SAVE_ADDRBOOK_FIRM,
  EDIT_ADDRBOOK_FIRM,
  TransportFirmType,
  GetTransportFirmsType,
  GET_TRANSPORT_FIRMS,
  GET_SUPPLIER_FIRMS,
  GetSupplierFirmsType,
  SupplierFirmType
} from './data/FirmsAddressBookSliceData';
import {
  createAsyncThunkGeneric,
  createAsyncThunkGenericVoid
} from './GenericThunks';

type FirmAddressBookState = {
  firms: FirmsAddressBookType[];
  firmsAddressBookLoading: boolean;
  transportFirms: TransportFirmType[];
  trasnportFirmsLoading: boolean;
  supplierFirms: SupplierFirmType[];
  supplierFirmsLoading: boolean;
};

const initialState: FirmAddressBookState = {
  firms: [],
  firmsAddressBookLoading: false,
  transportFirms: [],
  trasnportFirmsLoading: false,
  supplierFirms: [],
  supplierFirmsLoading: false
};

export const getAllAddressBookFirms = createAsyncThunkGenericVoid(
  'firmsAddressBook/getAllAddressBookFirms',
  async ({ client }) => {
    const response = await client.query<
    void,
    GetAllFirmsAddressBookResponseType
    >('addressBookFirm', GET_ADDRBOOK_FIRMS);
    return response?.items ? response?.items : [];
  }
);

export const saveAddressBookFirm = createAsyncThunkGeneric<
AddressBookFirmInputType,
FirmsAddressBookType
>('firmsAddressBook/saveAddressBookFirm', async ({ client, input }) =>
  client.mutate<SaveAddressBookFirmType, FirmsAddressBookType>(
    'addAddressBookFirm',
    SAVE_ADDRBOOK_FIRM,
    { addressBookFirm: input }
  ));

export const updateAddressBookFirm = createAsyncThunkGeneric<
AddressBookFirmInputType,
FirmsAddressBookType
>('firmsAddressBook/updateAddressBookFirm', async ({ client, input }) =>
  client.mutate<SaveAddressBookFirmType, FirmsAddressBookType>(
    'updateAddressBookFirm',
    EDIT_ADDRBOOK_FIRM,
    { addressBookFirm: input }
  ));

export const getTransportFirms = createAsyncThunkGenericVoid(
  'firmsAddressBook/getTransportFirms',
  async ({ client }) => {
    const response = await client.query<void, GetTransportFirmsType>(
      'typeOfFirm',
      GET_TRANSPORT_FIRMS
    );
    return response?.items ? response?.items : [];
  }
);

export const getSupplierFirms = createAsyncThunkGenericVoid(
  'firmsAddressBook/getSupplierFirms',
  async ({ client }) => {
    const response = await client.query<void, GetSupplierFirmsType>(
      'typeOfFirm',
      GET_SUPPLIER_FIRMS
    );
    return response?.items ? response?.items : [];
  }
);

export const FirmsAddressBookSlice = createSlice({
  name: 'firmsAddressBook',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllAddressBookFirms.pending, (state) => {
        state.firmsAddressBookLoading = true;
      })
      .addCase(getAllAddressBookFirms.fulfilled, (state, { payload }) => {
        state.firmsAddressBookLoading = false;
        state.firms = payload;
      })
      .addCase(saveAddressBookFirm.pending, (state) => {
        state.firmsAddressBookLoading = true;
      })
      .addCase(saveAddressBookFirm.fulfilled, (state, { payload }) => {
        state.firmsAddressBookLoading = false;
        state.firms.push(payload);
      })
      .addCase(updateAddressBookFirm.pending, (state) => {
        state.firmsAddressBookLoading = true;
      })
      .addCase(updateAddressBookFirm.fulfilled, (state, { payload }) => {
        state.firmsAddressBookLoading = false;
        const updated = state.firms.findIndex(
          (firm) =>
            firm.firmABId === payload.firmABId
        );
        state.firms[updated] = payload;
      })
      .addCase(getTransportFirms.pending, (state) => {
        state.trasnportFirmsLoading = true;
      })
      .addCase(getTransportFirms.fulfilled, (state, { payload }) => {
        state.trasnportFirmsLoading = false;
        const localTruckFirms = [];
        payload?.forEach(({ firm }) => {
          if (firm) {
            localTruckFirms.push({
              firmABId: firm.firmABId,
              firmABMOL: firm.firmABMOL,
              firmABName: firm.firmABName
            });
          }
        });
        state.transportFirms = localTruckFirms;
      })
      .addCase(getSupplierFirms.pending, (state) => {
        state.supplierFirmsLoading = true;
      })
      .addCase(getSupplierFirms.fulfilled, (state, { payload }) => {
        state.supplierFirmsLoading = false;
        state.supplierFirms = payload.map(({ firm }) =>
          ({
            firmABId: firm.firmABId,
            firmABMOL: firm.firmABMOL,
            firmABName: firm.firmABName
          }));
      });
  }
});

// Export Selectors
export const selectFirmsAddressBook = (state: RootState) =>
  state.firmsAddressBook.firms;
export const selectFirmsAddressBookLoader = (state: RootState) =>
  state.firmsAddressBook.firmsAddressBookLoading;
export const selectTransportFirms = (state: RootState) =>
  state.firmsAddressBook.transportFirms;
export const selectTransportFirmsLoadingState = (state: RootState) =>
  state.firmsAddressBook.trasnportFirmsLoading;
export const selectSupplierFirms = (state: RootState) =>
  state.firmsAddressBook.supplierFirms;

// Export reducer
export default FirmsAddressBookSlice.reducer;
