import { graphql } from 'msw';
import AUTH_CONFIG from '../../auth-variables';

const blbTrace = graphql.link(AUTH_CONFIG.apiUrl);

let firms = [
  {
    firmId: 1,
    firmEik: '12314',
    firmName: 'Толев и ко.',
    firmCity: 'Пловдив',
    firmAddress: 'Жан Жорес 3',
    firmMol: 'Vat-19',
    isActive: true
  },
  {
    firmId: 2,
    firmEik: '40239i42i424',
    firmName: 'Толевi',
    firmCity: 'Пловдив',
    firmAddress: 'Жан Жорес 3',
    firmMol: 'Vat-19',
    isActive: true
  },
  {
    firmId: 4,
    firmEik: '40239i42i424',
    firmName: 'фирмаИмаИме',
    firmCity: 'Пловдив',
    firmAddress: 'Жан Жорес 3',
    firmMol: 'Vat-19',
    isActive: true
  },
  {
    firmId: 5,
    firmEik: '40239i42i424',
    firmName: 'безИме',
    firmCity: 'Пловдив',
    firmAddress: 'Жан Жорес 3',
    firmMol: 'Vat-19',
    active: true
  },
];

export default [
  // Get Firms information for the Firms Slice
  blbTrace.query('getFirmInformationQuery', (_, res, ctx) =>
    res(
      ctx.delay(3000),
      ctx.data({
        getInformation: {
          firms
        }
      })
    )),

  blbTrace.query('getAvailableFirmsQuery', (_, res, ctx) =>
    res(
      ctx.delay(3000),
      ctx.data({
        firms: {
          availableFirms: firms.map(({ firmId, firmName }) =>
            ({
              firmId,
              firmName
            }))
        }
      })
    )),

  blbTrace.query('getAllFirms', (_, res, ctx) => {
    res(
      ctx.delay(3000),
      ctx.data({
        getAllFirms: {
          items: firms.map(({
            firmId, firmName, firmEik, firmMol
          }) =>
            ({
              firmId,
              firmName,
              firmEik,
              firmMol
            }))
        }
      })
    );
  }),

  // Handle new Firms inputs
  blbTrace.mutation('saveFirmInformationMutation', (req, res, ctx) => {
    const { input } = req.variables;
    input.isActive = true;
    input.firmId = Math.random() * 10;
    firms = [...firms, input];
    return res(
      ctx.delay(3000),
      ctx.data({
        saveInformation: {
          input
        }
      })
    );
  }),

  // Update firm information
  blbTrace.mutation('updateFirmInformationMutation', (req, res, ctx) => {
    const { id, input } = req.variables;
    const updatedFirm = firms.findIndex((firm) =>
      firm.firmId === id);
    firms[updatedFirm] = input;
    return res(
      ctx.delay(5000),
      ctx.data({
        updateInformation: {
          input
        }
      })
    );
  })
];
