import React, { useState } from 'react';
import { State, process } from '@progress/kendo-data-query';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridToolbar
} from '@progress/kendo-react-grid';
import { useLocalization } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';
import GridSearchBar from '../../features/GridSearchBar/GridSearchBar';
import { PastureLogType } from '../../slices/data/PastureLogSliceData';
import {
  ANIMAL_COUNT,
  ANIMAL_ENTRY,
  DAYS_SPENT_IN_PASTURE,
  HERD_GROUP,
  PASTURE_ACREAGE,
  PASTURE_DATE_OF_ENTRY,
  PASTURE_DATE_OF_EXIT,
  PASTURE_GRASS_HEIGHT_IN,
  PASTURE_GRASS_HEIGHT_OUT,
  PASTURE_NAME,
  PASTURE_LOG_TITLE
} from '../../languages/languages';
import { DateCell, EditCommandCell } from '../CustomGridCells/CustomGridCells';
import { useAppSelector } from '../../app/StoreHooks';
import { selectPastureLoadingState } from '../../slices/PastureLogSlice';
import GridLoaderPanel from '../GridLoaderPanel/GridLoaderPanel';
import GrantAccess from '../../features/GrantAccess/GrantAccess';

const REQUIRED_ROLES: string[] = ['Admin', 'Farm Manager', 'Holding Owner'];

type PastureLogGridProps = {
  data: PastureLogType[];
  addNew: () => void;
  enterEdit: (item: PastureLogType) => void;
};

const PastureLogGrid = ({ data, addNew, enterEdit }: PastureLogGridProps) => {
  const localizationService = useLocalization();
  const loadingState = useAppSelector(selectPastureLoadingState);
  // Grid States
  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0
  });

  const dataStateChange = (e: GridDataStateChangeEvent) => {
    setDataState(e.dataState);
  };

  const MyEditCommandCell = (props: GridCellProps) =>
    (
      <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
        <EditCommandCell {...props} enterEdit={enterEdit} />
      </GrantAccess>

    );

  return (
    <>
      <h1 className='page-title'>{localizationService.toLanguageString(PASTURE_LOG_TITLE, '')}</h1>
      <div className='content-wrapper section'>
        <Grid
          {...dataState}
          data={process(data, dataState)}
          onDataStateChange={dataStateChange}
          sortable
          pageable
        >
          <GridToolbar>
            <div className='toolbar-section'>
              <GridSearchBar
                dataState={dataState}
                filterBy='lID'
                setDataState={setDataState}
              />
            </div>
            <div className='toolbar-section ml-auto'>
              <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
                <Button
                  name='ATCattleGridEntryBtn'
                  type='button'
                  title={localizationService.toLanguageString(ANIMAL_ENTRY, '')}
                  icon='plus'
                  onClick={addNew}
                  primary
                >
                  {localizationService.toLanguageString(ANIMAL_ENTRY, '')}
                </Button>
              </GrantAccess>
            </div>
          </GridToolbar>
          <Column
            key='animalsCount'
            field='animalsCount'
            title={localizationService.toLanguageString(ANIMAL_COUNT, '')}
          />
          <Column
            key='groupName'
            field='groupName'
            title={localizationService.toLanguageString(HERD_GROUP, '')}
          />
          <Column
            key='pastureName'
            field='pastureName'
            title={localizationService.toLanguageString(PASTURE_NAME, '')}
          />
          <Column
            key='dateIn'
            field='dateIn'
            cell={DateCell}
            title={localizationService.toLanguageString(PASTURE_DATE_OF_ENTRY, '')}
          />
          <Column
            key='dateOut'
            field='dateOut'
            cell={DateCell}
            title={localizationService.toLanguageString(PASTURE_DATE_OF_EXIT, '')}
          />
          <Column
            key='daysIn'
            field='daysIn'
            title={localizationService.toLanguageString(DAYS_SPENT_IN_PASTURE, '')}
          />
          <Column
            key='pastureSize'
            field='pastureSize'
            title={localizationService.toLanguageString(PASTURE_ACREAGE, '')}
          />
          <Column
            key='grassHeightIn'
            field='grassHeightIn'
            title={localizationService.toLanguageString(
              PASTURE_GRASS_HEIGHT_IN,
              ''
            )}
          />
          <Column
            key='grassHeightOut'
            field='grassHeightOut'
            title={localizationService.toLanguageString(
              PASTURE_GRASS_HEIGHT_OUT,
              ''
            )}
          />
          <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
            <Column cell={MyEditCommandCell} width={120} />
          </GrantAccess>
        </Grid>
        {loadingState && <GridLoaderPanel />}
      </div>
    </>
  );
};

export default PastureLogGrid;
