import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { Provider } from 'react-redux';
import { SingletonHooksContainer } from 'react-singleton-hook';
import { loadMessages, LocalizationProvider } from '@progress/kendo-react-intl';
import App from './app/App';
import store from './app/Store';
import reportWebVitals from './reportWebVitals';
import worker from './mock-server/MockServer';
import { useAppSelector } from './app/StoreHooks';
import { selectLanguage } from './slices/LanguageSlice';
import translations from './translation-main';

loadMessages(translations.bg_BG, 'bg');
loadMessages(translations.en_US, 'en');

const AppWithLanguage = () => {
  const { currentLanguage: language } = useAppSelector(selectLanguage);
  return (
    <LocalizationProvider language={language}>
      <App />
    </LocalizationProvider>
  );
};

const renderApp = () => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <SingletonHooksContainer />
        <AppWithLanguage />
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  );
};

const workingServer: any = worker;
// if (process.env.NODE_ENV === 'test') {
//   workingServer.start().then(renderApp);
// } else {

// }
workingServer.start().then(renderApp);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
