import React from 'react';

import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
} from '@progress/kendo-react-charts';

import 'hammerjs';

const categories = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const series = [
  {
    data: [150, 130, 200, 90, 140, 150, 210, 250, 110, 150, 180, 220],
  },
];

const ChartContainer = () => (
  <Chart style={{ height: 350 }}>
    {/* <ChartTitle text='Годишна статистика крави:телета' /> */}
    <ChartLegend position='top' orientation='horizontal' />
    <ChartCategoryAxis>
      <ChartCategoryAxisItem categories={categories} startAngle={45} />
    </ChartCategoryAxis>
    <ChartSeries>
      {series.map((item) => (
        <ChartSeriesItem type='line' tooltip={{ visible: true }} data={item.data} />
      ))}
    </ChartSeries>
  </Chart>
);

export default ChartContainer;
