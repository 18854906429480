import React, { useState } from 'react';
import { Field } from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import {
  NumericTextBoxChangeEvent,
  RadioButtonChangeEvent
} from '@progress/kendo-react-inputs';
import { useLocalization } from '@progress/kendo-react-intl';
import {
  FormNumericTextBox,
  FormRadioGroup
} from '../FormComponents/FormComponents';
import { requiredValidator, NumberValidator } from '../Validators/Validators';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import { selectSalelData, setSaleData } from '../../slices/SellOperationSlice';
import {
  selectSelectedAnimals,
  setSelectedAnimals
} from '../../slices/AnimalSlice';
import {
  EMPTY_VEHICLE_WEIGHT,
  VEHICLE_WEIGHT_PLUS_ANIMALS,
  UPDATE,
  ANIMAL_WEIGHT,
  AVERAGE_WEIGHT,
  INDIVIDUAL_WEIGHT
} from '../../languages/languages';

const options: { value: string; label: string }[] = [
  { value: 'combined', label: AVERAGE_WEIGHT },
  { value: 'individual', label: INDIVIDUAL_WEIGHT }
];

const AnimalWeight = () => {
  const localizationService = useLocalization();
  // Select which weighting option will be used
  const [option, setOption] = useState<string>('');

  // Redux block
  // init dispatch
  const dispatch = useAppDispatch();

  // Selectors
  const selectedAnimals = useAppSelector(selectSelectedAnimals);
  const saleData = useAppSelector(selectSalelData);
  // End redux block

  // Change the animal weight type operation
  const handleOptionChange = ({ value }: RadioButtonChangeEvent) => {
    // First set the local option value, that will determine weighting type
    setOption(value);

    // Then always reset the vehicleTaraWeight and vehicleTotalWeight values to null
    dispatch(setSaleData(['vehicleTaraWeight', null]));
    dispatch(setSaleData(['vehicleTotalWeight', null]));
  };

  // Update the vehicleTaraWeight and vehicleTotalWeight in the redux slice SellOperationSlice
  // based on the selected field
  const handleWeight = ({ value, target }: NumericTextBoxChangeEvent) => {
    dispatch(setSaleData([target.name, value]));
  };

  // Once the values have been updated, calculate the average weight and update it
  // for both the saleData state and for each animal's weight
  const saveAverageWeight = () => {
    const averageWeight = (saleData?.vehicleTotalWeight - saleData?.vehicleTaraWeight)
      / selectedAnimals.length;

    dispatch(setSaleData(['averageAnimalWeight', averageWeight]));

    // Generate a new array of animals, with their values inserted.
    const weightedAnimals = selectedAnimals.map((animal) =>
      ({
        ...animal,
        animalWeight: averageWeight
      }));
    // and dispatch it to the redux state
    dispatch(setSelectedAnimals(weightedAnimals));
  };

  const localizedOptions = options.map((opt) =>
    ({
      value: opt.value,
      label: localizationService.toLanguageString(opt.label, '')
    }));

  return (
    <div className='sell-details-form'>
      <legend className='subtitle-1'>
        {localizationService.toLanguageString(ANIMAL_WEIGHT, '')}
      </legend>
      <fieldset className='k-form-fieldset'>
        <Field
          layout='horizontal'
          key='weightOptions'
          id='weightOptions'
          name='weightOptions'
          data={localizedOptions}
          onChange={handleOptionChange}
          component={FormRadioGroup}
          validator={requiredValidator}
        />
      </fieldset>
      {option === 'combined' ? (
        <fieldset className='k-form-fieldset align-items-start'>
          <Field
            name='vehicleTaraWeight'
            component={FormNumericTextBox}
            key='vehicleTaraWeight'
            id='vehicleTaraWeight'
            label={localizationService.toLanguageString(
              EMPTY_VEHICLE_WEIGHT,
              ''
            )}
            placeholder='kg'
            validator={NumberValidator}
            onChange={handleWeight}
          />
          <Field
            name='vehicleTotalWeight'
            component={FormNumericTextBox}
            key='vehicleTotalWeight'
            id='vehicleTotalWeight'
            label={localizationService.toLanguageString(
              VEHICLE_WEIGHT_PLUS_ANIMALS,
              ''
            )}
            placeholder='kg'
            validator={NumberValidator}
            onChange={handleWeight}
          />
          <Button
            name='AnimalWeightSave'
            type='button'
            title={localizationService.toLanguageString(UPDATE, '')}
            primary
            icon='save'
            onClick={saveAverageWeight}
            className='mt-m'
          >
            {localizationService.toLanguageString(UPDATE, '')}
          </Button>
        </fieldset>
      ) : (
        option === '' && null
      )}
    </div>
  );
};

export default AnimalWeight;
