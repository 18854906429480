/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/StoreHooks';
import {
  GET_NOTIFICATIONS,
  NotificationQueryResponseType,
  NotificationType
} from './data/NotificationsSliceData';
import {
  createAsyncThunkGenericVoid
} from './GenericThunks';

type NotificationsStateType = {
  notifications: NotificationType[];
  notificationsLoading: boolean;
};

const initialState: NotificationsStateType = {
  notifications: [],
  notificationsLoading: false
};

// Async thunks
export const getNotifications = createAsyncThunkGenericVoid(
  'notifications/getNotifications',
  async ({ client }) => {
    const response = await client.query<void, NotificationQueryResponseType>(
      'holdingEvents',
      GET_NOTIFICATIONS
    );
    return response?.items ? response?.items : [];
  }
);

export const notificationsSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    changeSeenStatus: (state, { payload }) => {
      const notification = state.notifications.findIndex(
        (notif) =>
          notif.holdingEventsId === payload
      );
      state.notifications[notification] = {
        ...state.notifications[notification],
        seen: true
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotifications.pending, (state) => {
        state.notificationsLoading = true;
      })
      .addCase(getNotifications.fulfilled, (state, { payload }) => {
        state.notificationsLoading = false;
        state.notifications = payload;
      });
  }
});

// Actions
export const { changeSeenStatus } = notificationsSlice.actions;
// Selectors
export const selectNotifications = (state: RootState) =>
  state.notificactions.notifications;
export const seelctNotificationsLoadingState = (state: RootState) =>
  state.notificactions.notificationsLoading;

// Export Reducer
export default notificationsSlice.reducer;
