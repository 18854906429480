/* eslint-disable */
import { useLocalization } from '@progress/kendo-react-intl';
import {
  Drawer,
  DrawerContent,
  DrawerItem,
  DrawerSelectEvent
} from '@progress/kendo-react-layout';
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import { selectExpanded, setExpanded } from '../../slices/DrawerSlice';
import {
  selectDrawerComponents,
  selectMenuIndex,
  setDrawerComponentsIndex,
  setDrawerComponents
} from '../../slices/MenuSlice';
import AppBarContainer from '../AppBarContainer/AppBarContainer';
import GrantAccess from '../GrantAccess/GrantAccess';

const CustomItem = (props) => {
  const { visible, ...others } = props;
  const arrowDir = props['data-expanded']
    ? 'k-i-arrow-chevron-down'
    : 'k-i-arrow-chevron-right';

  return (
    <>
      {props.visible === false ? null : (
        <GrantAccess requiredRoles={props.requiredRoles} haveRoles='some'>
          <DrawerItem title={props.title} className={props.class} {...others}>
            <span className={`k-icon ${props.icon}`} />
            <span className='k-item-text'>{props.text}</span>
            {props['data-expanded'] !== undefined && (
              <span
                className={`k-icon ${arrowDir}`}
                style={{ position: 'absolute', right: 10 }}
              />
            )}
          </DrawerItem>
        </GrantAccess>
      )}
    </>
  );
};

const DrawerRouterContainer = (props) => {
  const dispatch = useAppDispatch();
  const expanded = useAppSelector(selectExpanded);
  const menuIndex = useAppSelector(selectMenuIndex);
  const drawerComponents = useAppSelector(selectDrawerComponents);

  useEffect(() => {
    dispatch(setDrawerComponentsIndex(menuIndex));
  }, [menuIndex]);

  const localizationService = useLocalization();

  const onSelect = (ev: DrawerSelectEvent) => {
    const currentItem = ev.itemTarget.props;
    const isParent = currentItem['data-expanded'] !== undefined;
    const nextExpanded = !currentItem['data-expanded'];

    if(expanded && currentItem.id === 2) {
      dispatch(setExpanded())
    }

    const newData = drawerComponents.map((item) => {
      const {
        selected,
        'data-expanded': currentExpanded,
        id,
        ...others
      } = item;
      const isCurrentItem = currentItem.id === id;

      return {
        selected: isCurrentItem,
        'data-expanded':
          isCurrentItem && isParent ? nextExpanded : currentExpanded,
        id,
        ...others
      };
    });
    props.history.push(ev.itemTarget.props.route);
    dispatch(setDrawerComponents(newData));
  };

  const drawerData = drawerComponents?.map((item) => {
    const { parentId, ...others } = item;
    if (parentId !== undefined) {
      const parent = drawerComponents.find((itm) => itm.id === parentId);
      return {
        ...others,
        visible: parent['data-expanded'],
        text: localizationService.toLanguageString(item.text, ''),
        title: localizationService.toLanguageString(item.title, '')
      };
    }
    return {
      ...item,
      text: localizationService.toLanguageString(item.text, ''),
      title: localizationService.toLanguageString(item.title, '')
    };
  });

  return (
    <>
      <AppBarContainer />
      <main id='content'>
        <Drawer
          expanded={expanded}
          mode='push'
          position='start'
          mini
          items={drawerData}
          item={CustomItem}
          onSelect={onSelect}
          className='container'
        >
          <DrawerContent className='main-content'>
            {props.children}
          </DrawerContent>
        </Drawer>
      </main>
    </>
  );
};

export default withRouter(DrawerRouterContainer);
