import { gql } from '@apollo/client';
import { UserRoleType } from './UserSliceData';

// Types
export type EmployeesABType = {
  userId: number;
  email: string;
  userName: string;
  roles: UserRoleType[];
};

export type SaveEmployeeABType = {
  userId?: number;
  email: string;
  userName: string;
  roles: string[];
};

export type AddressBookEmployeesFirmsType = {
  firmId: number;
  firmName: string;
};

export type AddressBookEmployeesResponseType = {
  items: EmployeesABType[];
};

export type AddressBookEmployeesFirmsResponseType = {
  items: AddressBookEmployeesFirmsType[];
};

export type SaveEmployeeInputType = {
  userInput: SaveEmployeeABType;
};
export type EditEmployeeType = {
  employee: EmployeesABType;
};

export type AvailableMatadorsType = {
  matadorID: number;
  matadorName: string;
};

export type AvailableMatadorsResponseType = {
  matadors: AvailableMatadorsType[];
};

// Queries & Mutations
export const GET_EMPLOYEES = gql`
  query {
    users {
      items {
        userId
        userName
        email
        roles {
          id
          name
        }
      }
    }
  }
`;

export const GET_HOLDING_FIRMS = gql`
  query getFirms {
    items {
      firmABId
      firmABName
    }
  }
`;

export const EDIT_EMPLOYEE = gql`
  mutation editEmployeeMutation($employee: EmployeesABType) {
    editEmployeeMutation(employee: $employee) {
      employee
    }
  }
`;

export const SAVE_EMPLOYEE = gql`
  mutation AddUser($userInput: userInput) {
    addUser(userInput: $userInput) {
      userId
      email
      userName
      roles {
        id
        name
      }
    }
  }
`;
