import { saveAs } from '@progress/kendo-file-saver';
import { QRCode } from '@progress/kendo-react-barcodes';
import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { useLocalization } from '@progress/kendo-react-intl';
import React from 'react';

import { SAVE_IMAGE, SAVE_SVG } from '../../languages/keys/keys';

interface Props {
  onClose: () => void;
  item: QrDataItem;
}

export interface QrDataItem {
  title: string;
  id: number;
  type: string;
}

const QrCodePrintDialog = ({ onClose, item }: Props) => {
  const t = useLocalization();
  const dataItem: QrDataItem = { ...item };
  // eslint-disable-next-line no-restricted-globals
  const url = `${location.origin}/public/${item.type}/${item.id}`;
  const qrcode = React.createRef<QRCode>();

  const exportQRCodeSVG = () => {
    if (!qrcode.current) {
      return;
    }

    qrcode.current.exportSVG().then((dataURI) => {
      saveAs(dataURI, `${dataItem.title.replace(' ', '_')}.svg`);
    });
  };

  const exportQRCodePng = () => {
    if (!qrcode.current) {
      return;
    }
    qrcode.current.exportImage().then((dataURI) => {
      saveAs(dataURI, `${dataItem.title.replace(' ', '_')}.png`);
    });
  };
  return (
    <Dialog title='QR' onClose={onClose}>
      <h3 className='text-center'>{dataItem.title}</h3>
      <div className='d-flex justify-content-center pb-m'>
        <QRCode ref={qrcode} value={url} errorCorrection='M' size={300} />
      </div>
      <div className='text-center'>
        <Button
          className='mr-s mb-m'
          onClick={exportQRCodePng}
          title={t.toLanguageString(SAVE_IMAGE, '')}
        >
          {t.toLanguageString(SAVE_IMAGE, '')}
        </Button>
        <Button className='mb-m' onClick={exportQRCodeSVG} title={t.toLanguageString(SAVE_SVG, '')}>
          {t.toLanguageString(SAVE_SVG, '')}
        </Button>
      </div>
    </Dialog>
  );
};

export default QrCodePrintDialog;
