import { Button, DropDownButton, DropDownButtonItemClickEvent } from '@progress/kendo-react-buttons';
import { AutoComplete, AutoCompleteChangeEvent } from '@progress/kendo-react-dropdowns';
import { useLocalization } from '@progress/kendo-react-intl';
import { AppBarSection, Menu, MenuItem, MenuSelectEvent } from '@progress/kendo-react-layout';
import React, { useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import { ReactComponent as ReactLogo } from '../../images/BLB_logo_bg.svg';
import { HOLDING_NAME, SEARCH_BAR } from '../../languages/languages';
import { setExpanded } from '../../slices/DrawerSlice';
import { selectHoldingProfile } from '../../slices/HoldingSlice';
import { setLanguage } from '../../slices/LanguageSlice';
import { selectDrawerComponents, setMenuIndex, setSelectedItem } from '../../slices/MenuSlice';
import { filterSearch, selectFilteredSearch, selectSearchLibrary } from '../../slices/SearchSlice';
import { useAuth0, useLocalStorage } from '../../utils/custom-hooks';
import useFilteredMenu from '../../utils/useFilteredMenu';
import account from './account';

type ThemeType = {
  [key: string]: string;
};

type ThemeOptionsType = {
  id: string;
  theme: ThemeType;
};

const themes: ThemeOptionsType[] = [
  {
    id: 'processor',
    theme: {
      '--color-primary': '#679186',
      '--color-primary-rgb': '103, 145, 134',
      '--color-secondary': '#F9B4AB',
      '--color-secondary-rgb': '249, 180, 171',
    },
  },
  {
    id: 'farmer',
    theme: {
      '--color-primary': '#72A1E5',
      '--color-primary-rgb': '114, 161, 229',
      '--color-secondary': '#9FC490',
      '--color-secondary-rgb': '114, 161, 229',
    },
  },
  {
    id: 'trader',
    theme: {
      '--color-primary': '#72A1E5',
      '--color-primary-rgb': '114, 161, 229',
      '--color-secondary': '#9FC490',
      '--color-secondary-rgb': '114, 161, 229',
    },
  },
];

const itemRender = (props: { item: any; itemIndex: number }) => (
  <div className='avatar-menu-item'>
    <span className={`k-icon k-i-${props.item.icon}`} role='presentation' />
    {props.item.text}
  </div>
);

const AppBarContainer = () => {
  const dispatch = useAppDispatch();
  const { user, logout } = useAuth0();
  const holding = useAppSelector(selectHoldingProfile);
  const localizationService = useLocalization();
  const searchLibrary = useAppSelector(selectSearchLibrary);
  const filteredSearch = useAppSelector(selectFilteredSearch);
  const drawerComponents = useAppSelector(selectDrawerComponents);
  const history = useHistory();

  const applyTheme = (nextTheme) => {
    if (!nextTheme) {
      return;
    }
    Object.keys(nextTheme).map((key) => {
      const value = nextTheme[key];
      return document.documentElement.style.setProperty(key, value);
    });
  };
  const [theme, setTheme] = useLocalStorage<ThemeOptionsType>('theme', {
    id: 'default',
    theme: {
      '--color-primary': '#ee3641',
      '--color-primary-rgb': '238, 54, 65',
      '--color-secondary': '#dcae97',
      '--color-secondary-rgb': '220, 174, 151',
    },
  });

  useEffect(() => {
    applyTheme(theme.theme);
  }, []);

  const headerMenus = useFilteredMenu();

  // Handle expansion of Drawer component
  const handleClick = () => {
    dispatch(setExpanded());
  };

  // Filter based on input
  const onChange = (ev: AutoCompleteChangeEvent) => {
    const { value } = ev;

    dispatch(filterSearch(value));
  };

  const onChangeLanguage = (ev: DropDownButtonItemClickEvent) => {
    const value = ev.item;
    dispatch(setLanguage(value.toLowerCase()));
  };

  // Handle the logout logic
  const handleLogout = useCallback(({ item }: DropDownButtonItemClickEvent) => {
    if (item.icon === 'logout') {
      logout({
        returnTo: `${window.location.origin}/dashboard`,
      });
      window.localStorage.removeItem('theme');
    }
  }, []);

  const handleMenuSelect = ({ item }: MenuSelectEvent) => {
    dispatch(setMenuIndex(item.data));
    dispatch(setSelectedItem(item.data));
    switch (item.data) {
      case 'farmer':
        applyTheme(themes.find((thm) => thm.id === 'farmer').theme);
        setTheme(themes.find((thm) => thm.id === 'farmer'));
        break;
      case 'processor':
        applyTheme(themes.find((thm) => thm.id === 'processor').theme);
        setTheme(themes.find((thm) => thm.id === 'processor'));
        break;
      case 'trader':
        applyTheme(themes.find((thm) => thm.id === 'trader').theme);
        setTheme(themes.find((thm) => thm.id === 'trader'));
        break;
      default:
        break;
    }
    history.push('/dashboard');
  };

  return (
    <header className='header'>
      <AppBarSection className='logo-section col-sm-2'>
        <a href='/' title='BLB Trace System' className='main-logo'>
          <ReactLogo />
        </a>
      </AppBarSection>
      <div className='section'>
        <AppBarSection className='d-flex align-items-center'>
          {drawerComponents && (
            <Button icon='menu' id='hamburger-menu-btn' primary onClick={handleClick} />
          )}
          <AutoComplete
            onChange={onChange}
            data={filteredSearch || searchLibrary}
            placeholder={localizationService.toLanguageString(SEARCH_BAR, '')}
            className='ml-m'
          />
        </AppBarSection>

        <AppBarSection>
          <Menu onSelect={handleMenuSelect}>
            {headerMenus.map((menu) => (
              <MenuItem
                data={menu.id}
                text={localizationService.toLanguageString(menu.text, '')}
                cssClass={menu.selected ? 'main-menu-active' : ''}
                key={menu.id}
              />
            ))}
          </Menu>
        </AppBarSection>
        <AppBarSection>
          <AppBarSection className='holdingNameContainer'>
            <b>{localizationService.toLanguageString(HOLDING_NAME, '')}:</b>
            <span className='name'>{holding?.holdingName}</span>
          </AppBarSection>
          <AppBarSection className='actions'>
            {/* <NotificationPanel /> */}
            {/* <Button look='outline' id='font-resizing' className='ml-s'>
            A+-
          </Button> */}
            <DropDownButton
              look='outline'
              icon='globe'
              items={['BG', 'EN']}
              onItemClick={onChangeLanguage}
              className='ml-s'
            />
            <DropDownButton
              className='avatar ml-s'
              itemRender={itemRender}
              items={account}
              look='flat'
              imageUrl={user?.picture}
              onItemClick={handleLogout}
            />
          </AppBarSection>
        </AppBarSection>
      </div>
    </header>
  );
};

export default AppBarContainer;
