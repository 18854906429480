import React from 'react';
import { State, process, getter } from '@progress/kendo-data-query';
import {
  Grid,
  GridColumn as Column,
  GridCellProps,
  GridRowProps,
  GridToolbar,
  GridDataStateChangeEvent,
  GridItemChangeEvent,
  GridSelectionChangeEvent,
  GridHeaderSelectionChangeEvent
} from '@progress/kendo-react-grid';
import { useLocalization } from '@progress/kendo-react-intl';
import { useAppSelector } from '../../app/StoreHooks';
import {
  ReceivedAnimalsType,
  SingleDealType
} from '../../slices/data/DealsSliceData';
import GridSearchBar from '../../features/GridSearchBar/GridSearchBar';
import ColumnMenu from '../ColumnMenu/ColumnMenu';
import {
  AnimalInfo,
  PricePerKilo,
  PricePerHead,
  PricePer100Kilos
} from '../CustomGridCells/CustomGridCells';
import { CellRender, RowRender } from '../SellAnimalsGridContainer/GridRenders';
import {
  DEAL_ANIMAL_ARRIVAL_WEIGHT,
  DEAL_ANIMAL_INFO,
  DEAL_ANIMAL_NOTE,
  DEAL_ANIMAL_PPH,
  DEAL_ANIMAL_PPHK,
  DEAL_ANIMAL_PPK,
  DEAL_ANIMAL_WEIGHT
} from '../../languages/languages';
import { selectDealsLoadingState } from '../../slices/DealsSlice';
import GridLoaderPanel from '../GridLoaderPanel/GridLoaderPanel';

// Variables for animal selection in the grid
const DATA_ITEM_KEY: string = 'animalId';
const SELECTED_FIELD: string = 'selected';
const idGetter = getter(DATA_ITEM_KEY);

type DealInformationGridProps = {
  deal: SingleDealType;
  dataState: State;
  setDataState: React.Dispatch<React.SetStateAction<State>>;
  dataStateChange: (e: GridDataStateChangeEvent) => void;
  editField: string;
  enterEdit: (dataItem: ReceivedAnimalsType, field: string) => void;
  exitEdit: () => void;
  itemChange: ({ field, value, dataItem }: GridItemChangeEvent) => void;
  onSelectionChange: (event: GridSelectionChangeEvent) => void;
  onHeaderSelectionChange: ({
    syntheticEvent,
    dataItems
  }: GridHeaderSelectionChangeEvent) => void;
  selectedState: { [id: string]: boolean | number[] };
};
const DealInformationGrid = ({
  deal,
  dataState,
  setDataState,
  dataStateChange,
  editField,
  enterEdit,
  exitEdit,
  itemChange,
  onSelectionChange,
  onHeaderSelectionChange,
  selectedState
}: DealInformationGridProps) => {
  const localizationService = useLocalization();
  const loadingState = useAppSelector(selectDealsLoadingState);
  const customCellRender: any = (
    td: React.ReactElement<HTMLTableCellElement>,
    props: GridCellProps
  ) =>
    (
      <CellRender
        defaultValue=''
        originalProps={props}
        td={td}
        enterEdit={enterEdit}
        editField={editField}
      />
    );

  const customRowRender: any = (
    tr: React.ReactElement<HTMLTableRowElement>,
    props: GridRowProps
  ) =>
    (
      <RowRender
        originalProps={props}
        tr={tr}
        exitEdit={exitEdit}
        editField={editField}
      />
    );

  // Custom Cell to combine several animal properties
  // so that they are displayed in one place
  const AnimalInfoCell = (props: GridCellProps) =>
    <AnimalInfo {...props} />;

  // Custom Cell to display the Price Per Kilo
  const PricePerKiloCell = (props: GridCellProps) =>
    (
      <PricePerKilo
        dealData={deal}
        animalsData={deal?.animalWeights}
        {...props}
      />
    );

  // Custom Cell to display the Price Per Head
  const PricePerHeadCell = (props: GridCellProps) =>
    (
      <PricePerHead
        dealData={deal}
        animalsData={deal?.animalWeights}
        {...props}
      />
    );

  // Custom Cell for the price per 100 kg.
  const PricePer100KilosCell = (props: GridCellProps) =>
    (
      <PricePer100Kilos
        dealData={deal}
        animalsData={deal?.animalWeights}
        {...props}
      />
    );

  return (
    <>
      <Grid
        sortable
        pageable
        {...dataState}
        data={process(
          deal?.animalWeights.map((item) =>
            ({
              ...item,
              [SELECTED_FIELD]: selectedState[idGetter(item)]
            })),
          dataState
        )}
        onDataStateChange={dataStateChange}
        onItemChange={itemChange}
        cellRender={customCellRender}
        rowRender={customRowRender}
        editField='inEdit'
        dataItemKey={DATA_ITEM_KEY}
        selectedField={SELECTED_FIELD}
        selectable={{
          enabled: true,
          drag: false,
          cell: false,
          mode: 'multiple'
        }}
        onSelectionChange={onSelectionChange}
        onHeaderSelectionChange={onHeaderSelectionChange}
      >
        <GridToolbar>
          <GridSearchBar
            dataState={dataState}
            setDataState={setDataState}
            filterBy='lID'
          />
        </GridToolbar>
        <Column
          editable={false}
          field={SELECTED_FIELD}
          headerSelectionValue={
          deal?.animalWeights?.findIndex(
            (item) =>
              !deal?.animalWeights[idGetter(item)]
          ) === -1
        }
          headerClassName='text-center'
        />
        <Column
          editable={false}
          key='lID'
          field='lID'
          title={localizationService.toLanguageString(DEAL_ANIMAL_INFO, '')}
          filter='text'
          cell={AnimalInfoCell}
          columnMenu={(props) =>
            <ColumnMenu {...props} />}
        />
        <Column
          editable={false}
          key='animalWeight'
          field='animalWeight'
          title={localizationService.toLanguageString(DEAL_ANIMAL_WEIGHT, '')}
          filter='numeric'
          columnMenu={(props) =>
            <ColumnMenu {...props} />}
        />
        <Column
          editable={false}
          key='pricePerHead'
          field='pricePerHead'
          title={localizationService.toLanguageString(DEAL_ANIMAL_PPH, '')}
          filter='numeric'
          cell={PricePerHeadCell}
          columnMenu={(props) =>
            <ColumnMenu {...props} />}
        />
        <Column
          editable={false}
          key='pricePerKilo'
          field='pricePerKilo'
          title={localizationService.toLanguageString(DEAL_ANIMAL_PPK, '')}
          filter='numeric'
          cell={PricePerKiloCell}
          columnMenu={(props) =>
            <ColumnMenu {...props} />}
        />
        <Column
          editable={false}
          key='pricePer100Kilo'
          field='pricePer100Kilo'
          title={localizationService.toLanguageString(DEAL_ANIMAL_PPHK, '')}
          filter='numeric'
          cell={PricePer100KilosCell}
          columnMenu={(props) =>
            <ColumnMenu {...props} />}
        />
        <Column
          editable={deal?.saleEventStatus === 'PENDING'}
          key='arrivalWeight'
          field='arrivalWeight'
          title={localizationService.toLanguageString(
            DEAL_ANIMAL_ARRIVAL_WEIGHT,
            ''
          )}
        />
        <Column
          editable={deal?.saleEventStatus === 'PENDING'}
          key='arrivalNote'
          field='arrivalNote'
          title={localizationService.toLanguageString(DEAL_ANIMAL_NOTE, '')}
        />
      </Grid>
      {loadingState && <GridLoaderPanel />}
    </>
  );
};

export default DealInformationGrid;
