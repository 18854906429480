import * as React from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import {
  Form,
  Field,
  FormElement,
  FormRenderProps
} from '@progress/kendo-react-form';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { useLocalization } from '@progress/kendo-react-intl';
import {
  FormInput,
  FormSwitch,
  FormComboBox,
} from '../FormComponents/FormComponents';
import {
  NumberValidator,
  StringValidator,
} from '../Validators/Validators';
import { useAppSelector } from '../../app/StoreHooks';
import { selectAvailableFirms } from '../../slices/FirmSlice';
import { TrademarkType } from '../../slices/data/TrademarkSliceData';
import {
  DECLINE,
  OWNER_PLACEHOLDER,
  STATUS,
  TM_LINK,
  TM_LINK_PLACEHOLDER,
  TM_NAME,
  TM_NAME_PLACEHOLDER,
  TM_REG,
  UPDATE
} from '../../languages/languages';

type EditFormProps = {
  item: TrademarkType;
  cancelEdit: () => void;
  onSubmit: (formData: TrademarkType) => void;
};
const TrademarkEditForm = ({
  item,
  cancelEdit,
  onSubmit
}: EditFormProps) => {
  const localizationService = useLocalization();
  const availableFirms = useAppSelector(selectAvailableFirms);

  const selectedFirm = availableFirms.find(
    (firm) =>
      firm.firmId === item.firmId
  );

  return (
    <Dialog title={item.tradeMarkName} onClose={cancelEdit}>
      <Form
        onSubmit={onSubmit}
        initialValues={item}
        render={(formRenderProps: FormRenderProps) =>
          (
            <FormElement>
              <Field
                name='active'
                component={FormSwitch}
                label={localizationService.toLanguageString(STATUS, '')}
                value={item.active}
              />
              <Field
                name='tradeMarkName'
                component={FormInput}
                label={localizationService.toLanguageString(TM_NAME, '')}
                placeholder={localizationService.toLanguageString(
                  TM_NAME_PLACEHOLDER,
                  ''
                )}
                validator={StringValidator}
              />

              <Field
                name='tradeMarkNumber'
                component={FormInput}
                label={localizationService.toLanguageString(TM_REG, '')}
                placeholder='№'
                validator={NumberValidator}
              />
              <Field
                name='tradeMarkLink'
                component={FormInput}
                label={localizationService.toLanguageString(TM_LINK, '')}
                placeholder={localizationService.toLanguageString(
                  TM_LINK_PLACEHOLDER,
                  ''
                )}
                defaultValue={item.tradeMarkLink}
                validator={StringValidator}
              />

              <Field
                onChange={({ value }: ComboBoxChangeEvent) => {
                  if (value === null) {
                    return;
                  }
                  formRenderProps.onChange('firmId', { value: value.firmId });
                }}
                placeholder='Собственост'
                id='firmName'
                name='firmId'
                label={localizationService.toLanguageString(
                  OWNER_PLACEHOLDER,
                  ''
                )}
                component={FormComboBox}
                textField='firmName'
                defaultValue={selectedFirm}
                data={availableFirms}
                validator={StringValidator}
              />

              {/* <Field
                name='tradeMarkFiles'
                component={FormUpload}
                label='Изображение'
              /> */}

              <div className='k-form-buttons'>
                <button
                  type='submit'
                  className='k-button k-primary'
                  disabled={!formRenderProps.allowSubmit}
                >
                  {localizationService.toLanguageString(UPDATE, '')}
                </button>
                <button type='submit' className='k-button' onClick={cancelEdit}>
                  {localizationService.toLanguageString(DECLINE, '')}
                </button>
              </div>
            </FormElement>
          )}
      />
    </Dialog>
  );
};

export default TrademarkEditForm;
