import * as React from 'react';
import { Loader } from '@progress/kendo-react-indicators';
import BarChartContainer from '../../components/BarChartContainer/BarChartContainer';
import {
  SaleTradeCategoryType,
  SaleTradesCategoriesQuery,
  SaleTradeSeriesType,
  SaleTradesSeriesQuery
} from './data/SaleTradeData';
import { useGraphQlClient } from '../../utils/custom-hooks';

type Series = {
  firstSeries: number[];
  secondSeries: number[];
};

const SaleTradeChart = () => {
  const [categories, setCategories] = React.useState<string[]>([]);
  const [series, setSeries] = React.useState<Series>(null);
  const client = useGraphQlClient();

  React.useEffect(() => {
    const loadData = async () => {
      const cats = await client.query<void, SaleTradeCategoryType[]>(
        'saleTrades',
        SaleTradesCategoriesQuery
      );
      const seriesAll = await client.query<void, SaleTradeSeriesType[][]>(
        'series',
        SaleTradesSeriesQuery
      );
      const [first = [], second = []] = seriesAll || [];
      setCategories(cats?.map((it) =>
        it.value));
      setSeries({
        firstSeries: first?.map((it) =>
          it.seriesValue),
        secondSeries: second?.map((it) =>
          it.seriesValue)
      });
    };
    loadData();
  }, []);

  return series ? (
    <BarChartContainer
      categories={categories}
      firstSeries={series?.firstSeries}
      secondSeries={series?.secondSeries}
    />
  ) : (
    <Loader size='large' type='pulsing' className='k-centered' />
  );
};

export default SaleTradeChart;
