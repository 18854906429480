import React from 'react';
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem
} from '@progress/kendo-react-charts';

interface AreaChartContainerProps {
  categories: string[];
  data: number[];
}

const AreaChartContainer = ({
  categories,
  data
}: AreaChartContainerProps) =>
  (
    <Chart>
      <ChartCategoryAxis>
        <ChartCategoryAxisItem categories={categories} />
      </ChartCategoryAxis>
      <ChartSeries>
        <ChartSeriesItem
          type='line'
          data={data}
          markers={{ visible: false }}
          line={{ style: 'smooth' }}
        />
      </ChartSeries>
    </Chart>
  );

export default AreaChartContainer;
