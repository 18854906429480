/* eslint-disable */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/StoreHooks';

type DrawerState = {
  expanded: boolean;
};

const initialState: DrawerState = {
  expanded: true
};

export const DrawerSlice = createSlice({
  name: 'drawer',
  initialState,
  reducers: {
    setExpanded: (state) => {
      if (!state.expanded) {
        state.expanded = true;
      } else {
        state.expanded = false;
      }
    }
  },
  extraReducers: {}
});

// Export actions
export const { setExpanded } = DrawerSlice.actions;

// Export Selectors
export const selectExpanded = (state: RootState) =>
  state.drawer.expanded;

// Export Reducer
export default DrawerSlice.reducer;
