import React from 'react';
import { Button } from '@progress/kendo-react-buttons';
import {
  Field,
  Form,
  FormElement,
  FormRenderProps
} from '@progress/kendo-react-form';
import { useLocalization } from '@progress/kendo-react-intl';
import { ComboBoxChangeEvent, MultiSelectChangeEvent } from '@progress/kendo-react-dropdowns';
import { useAppSelector } from '../../app/StoreHooks';
import {
  SAVE,
  AMBULATORY_CLINICAL_DATA,
  AMBULATORY_DATE,
  AMBULATORY_DIAGNOSIS,
  AMBULATORY_DIAGNOSIS_OUT,
  AMBULATORY_DIAGNOSTIC_TEST,
  AMBULATORY_DOSE,
  AMBULATORY_MEDICINES,
  AMBULATORY_NOTE,
  AMBULATORY_NUMBER,
  AMBULATORY_WITHDRAWAL_PERIOD,
  ANIMAL_BREED,
  ANIMAL_LID,
  ANIMAL_SEX,
  ANIMAL_TYPE,
  CANCEL,
  CATTLE_TYPE,
  FEMALE,
  MALE,
  SHEEP_TYPE
} from '../../languages/languages';
import { selectAllAnimals } from '../../slices/AnimalSlice';
import {
  AmbulatoryInputType,
  AmbulatoryLogMedicineType,
  AmbulatoryLogType
} from '../../slices/data/AmbulatoryLogSliceData';
import {
  AnimalSexEnum,
  AnimalTypeEnum
} from '../../slices/data/AnimalSliceData';
import {
  FormComboBox,
  FormDatePicker,
  FormInput,
  FormMultiSelect
} from '../FormComponents/FormComponents';
import CattleBreeds from '../../features/FarmAnimalTableCattle/cattleBreeds';
import { selectPurchasedMedicines } from '../../slices/PurchasedMedicinesSlice';

type AmbulatoryLogFormProps = {
  item?: AmbulatoryLogType;
  submit: (formData: AmbulatoryInputType | AmbulatoryLogType) => void;
  close: () => void;
  selectedMedicines: AmbulatoryLogMedicineType[];
  setSelectedMedicines: (event: MultiSelectChangeEvent) => void;
};
const AmbulatoryLogForm = ({
  item,
  submit,
  close,
  selectedMedicines,
  setSelectedMedicines
}: AmbulatoryLogFormProps) => {
  const localizationService = useLocalization();
  const animals = useAppSelector(selectAllAnimals).filter(
    (animal) =>
      animal.animalType === AnimalTypeEnum.CATTLE
      && animal.sex === AnimalSexEnum.FEMALE
  );
  const medicines = useAppSelector(selectPurchasedMedicines);
  const animalTypesLabel = [
    {
      value: AnimalTypeEnum.CATTLE,
      label: localizationService.toLanguageString(CATTLE_TYPE, '')
    },
    {
      value: AnimalTypeEnum.SHEEP,
      label: localizationService.toLanguageString(SHEEP_TYPE, '')
    }
  ];
  const animalSexLabel = [
    {
      value: AnimalSexEnum.FEMALE,
      label: localizationService.toLanguageString(FEMALE, '')
    },
    {
      value: AnimalSexEnum.MALE,
      label: localizationService.toLanguageString(MALE, '')
    }
  ];

  const selectedAnimal = animals.find(
    (animal) =>
      animal.animalId === item?.animal?.animalId
  );
  const animalType = animalTypesLabel.find(
    (animal) =>
      animal.value === item?.animal?.animalType
  )?.label;
  const animalSex = animalSexLabel.find(
    (animal) =>
      animal.value === item?.animal?.sex
  )?.label;
  const animalBreed = CattleBreeds[item?.animal?.cattleBreed];
  return (
    <Form
      initialValues={item}
      onSubmit={submit}
      render={(formRenderProps: FormRenderProps) =>
        (
          <FormElement className='two-column-grid' id='AmbulatoryLogForm'>
            <fieldset className='k-form-fieldset'>
              <Field
                id='animalId'
                name='animalId'
                label={localizationService.toLanguageString(ANIMAL_LID, '')}
                component={FormComboBox}
                textField='lID'
                data={animals}
                defaultValue={selectedAnimal}
                onChange={({ value }: ComboBoxChangeEvent) => {
                  formRenderProps.onChange('animalId', { value: value.animalId });
                  formRenderProps.onChange('animalType', {
                    value: animalTypesLabel.find(
                      (animal) =>
                        animal.value === value.animalType
                    ).label
                  });
                  formRenderProps.onChange('animalSex', {
                    value: animalSexLabel.find(
                      (animal) =>
                        animal.value === value.sex
                    ).label
                  });
                  formRenderProps.onChange('animalBreed', {
                    value: CattleBreeds[value.cattleBreed]
                  });
                }}
              />
              <Field
                id='animalType'
                name='animalType'
                label={localizationService.toLanguageString(ANIMAL_TYPE, '')}
                component={FormInput}
                defaultValue={animalType}
                disabled
              />
              <Field
                id='animalSex'
                name='animalSex'
                label={localizationService.toLanguageString(ANIMAL_SEX, '')}
                component={FormInput}
                defaultValue={animalSex}
                disabled
              />
              <Field
                id='animalBreed'
                name='animalBreed'
                defaultValue={animalBreed}
                label={localizationService.toLanguageString(ANIMAL_BREED, '')}
                component={FormInput}
                disabled
              />
              <Field
                id='ambulatoryNumber'
                name='ambulatoryNumber'
                label={localizationService.toLanguageString(AMBULATORY_NUMBER, '')}
                component={FormInput}
              />
              <Field
                id='ambulatoryDate'
                name='ambulatoryDate'
                label={localizationService.toLanguageString(AMBULATORY_DATE, '')}
                component={FormDatePicker}
              />
              <Field
                id='clinicalData'
                name='clinicalData'
                label={localizationService.toLanguageString(
                  AMBULATORY_CLINICAL_DATA,
                  ''
                )}
                component={FormInput}
              />
            </fieldset>
            <fieldset className='k-form-fieldset'>
              <Field
                id='diagnosticTest'
                name='diagnosticTest'
                label={localizationService.toLanguageString(
                  AMBULATORY_DIAGNOSTIC_TEST,
                  ''
                )}
                component={FormInput}
              />
              <Field
                id='diagnosis'
                name='diagnosis'
                label={localizationService.toLanguageString(
                  AMBULATORY_DIAGNOSIS,
                  ''
                )}
                component={FormInput}
              />
              <Field
                label={localizationService.toLanguageString(
                  AMBULATORY_MEDICINES,
                  ''
                )}
                id='medicine'
                name='medicine'
                textField='medicineName'
                data={medicines}
                defaultValue={selectedMedicines}
                onChange={setSelectedMedicines}
                component={FormMultiSelect}
              />

              <Field
                id='dose'
                name='dose'
                label={localizationService.toLanguageString(AMBULATORY_DOSE, '')}
                component={FormInput}
              />
              <Field
                id='withdrawalPeriod'
                name='withdrawalPeriod'
                label={localizationService.toLanguageString(
                  AMBULATORY_WITHDRAWAL_PERIOD,
                  ''
                )}
                component={FormInput}
              />
              <Field
                id='diagnosisOut'
                name='diagnosisOut'
                label={localizationService.toLanguageString(
                  AMBULATORY_DIAGNOSIS_OUT,
                  ''
                )}
                component={FormInput}
              />
              <Field
                id='comment'
                name='comment'
                label={localizationService.toLanguageString(AMBULATORY_NOTE, '')}
                component={FormInput}
              />
            </fieldset>
            <div className='k-form-buttons'>
              <Button
                name='AmbulatoryLogSave'
                type='submit'
                title={localizationService.toLanguageString(SAVE, '')}
                primary
                disabled={!formRenderProps.allowSubmit}
                icon='save'
              >
                {localizationService.toLanguageString(SAVE, '')}
              </Button>
              <Button
                name='AmbulatoryLogCancel'
                type='submit'
                title={localizationService.toLanguageString(CANCEL, '')}
                primary
                look='outline'
                icon='cancel'
                onClick={close}
              >
                {localizationService.toLanguageString(CANCEL, '')}
              </Button>
            </div>
          </FormElement>
        )}
    />
  );
};

AmbulatoryLogForm.defaultProps = {
  item: {}
};
export default AmbulatoryLogForm;
