/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/StoreHooks';
import {
  AmbulatoryInputType,
  AmbulatoryLogType,
  AmbulatoryMutationInputType,
  EDIT_AMBULATORY_LOG,
  GetAmbulatoryLogType,
  GET_ALL_AMBULATORY_LOG_ENTRIES,
  ReceivedAmbulatoryLogType,
  SAVE_AMBULATORY_LOG
} from './data/AmbulatoryLogSliceData';
import {
  createAsyncThunkGeneric,
  createAsyncThunkGenericVoid
} from './GenericThunks';

type AmbulatoryLogStateType = {
  ambulatoryLogs: AmbulatoryLogType[];
  ambulatoryLogsLoading: boolean;
};

const initialState: AmbulatoryLogStateType = {
  ambulatoryLogs: [],
  ambulatoryLogsLoading: false
};

// Thunks
export const getAllAmbulatoryLogs = createAsyncThunkGenericVoid(
  'ambulatoryLogs/getAllAmbulatoryLogs',
  async ({ client }) => {
    const response = await client.query<void, GetAmbulatoryLogType>(
      'ambulatoryDiary',
      GET_ALL_AMBULATORY_LOG_ENTRIES
    );
    return response?.items ? response?.items : [];
  }
);

export const saveAmbulatoryLog = createAsyncThunkGeneric<
AmbulatoryInputType,
ReceivedAmbulatoryLogType
>('ambulatoryLogs/saveAmbulatoryLog', async ({ client, input }) =>
  client.mutate<AmbulatoryMutationInputType, ReceivedAmbulatoryLogType>(
    'addAmbulatoryDiary',
    SAVE_AMBULATORY_LOG,
    { ambulatoryDiaryInput: input }
  ));

export const editAmbulatoryLog = createAsyncThunkGeneric<
AmbulatoryInputType,
ReceivedAmbulatoryLogType
>('ambulatoryLogs/editAmbulatoryLog', async ({ client, input }) =>
  client.mutate<AmbulatoryMutationInputType, ReceivedAmbulatoryLogType>(
    'updateAmbulatoryDiary',
    EDIT_AMBULATORY_LOG,
    { ambulatoryDiaryInput: input }
  ));

export const AmbulatoryLogSlice = createSlice({
  name: 'ambulatoryLogs',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllAmbulatoryLogs.pending, (state) => {
        state.ambulatoryLogsLoading = true;
      })
      .addCase(getAllAmbulatoryLogs.fulfilled, (state, { payload }) => {
        state.ambulatoryLogsLoading = false;
        const receivedData: AmbulatoryLogType[] = payload.map((item) =>
          ({
            ...item,
            ambulatoryDate: new Date(item?.ambulatoryDate),
            animal: {
              animalId: item?.animal?.animalId,
              lID: item?.animal?.lID,
              cattleBreed: item?.animal?.cattleBreed,
              animalType: item?.animal?.animalType,
              sex: item?.animal?.sex,
              dateOfBirth: new Date(item?.animal?.dateOfBirth)
            },
            animalObject: {
              animalObjectId: item?.animal?.animalObjects?.animalObjectId,
              animalObjectName: item?.animal?.animalObjects?.animalObjectName
            }
          }));
        state.ambulatoryLogs = receivedData;
      })
      .addCase(saveAmbulatoryLog.pending, (state) => {
        state.ambulatoryLogsLoading = true;
      })
      .addCase(saveAmbulatoryLog.fulfilled, (state, { payload }) => {
        state.ambulatoryLogsLoading = false;
        const receivedData: AmbulatoryLogType = {
          ...payload,
          ambulatoryDate: new Date(payload?.ambulatoryDate),
          animal: {
            animalId: payload?.animal?.animalId,
            lID: payload?.animal?.lID,
            cattleBreed: payload?.animal?.cattleBreed,
            animalType: payload?.animal?.animalType,
            sex: payload?.animal?.sex,
            dateOfBirth: new Date(payload?.animal?.dateOfBirth)
          },
          medicine: payload?.medicine?.map((med) =>
            ({
              medicineId: med.medicineId,
              medicineName: med.medicineName
            })),
          animalObject: {
            animalObjectId: payload?.animal?.animalObjects?.animalObjectId,
            animalObjectName: payload?.animal?.animalObjects?.animalObjectName
          }
        };
        state.ambulatoryLogs = [...state.ambulatoryLogs, receivedData];
      })
      .addCase(editAmbulatoryLog.pending, (state) => {
        state.ambulatoryLogsLoading = true;
      })
      .addCase(editAmbulatoryLog.fulfilled, (state, { payload }) => {
        state.ambulatoryLogsLoading = false;
        const receivedData: AmbulatoryLogType = {
          ...payload,
          ambulatoryDate: new Date(payload?.ambulatoryDate),
          animal: {
            animalId: payload?.animal?.animalId,
            lID: payload?.animal?.lID,
            cattleBreed: payload?.animal?.cattleBreed,
            animalType: payload?.animal?.animalType,
            sex: payload?.animal?.sex,
            dateOfBirth: new Date(payload?.animal?.dateOfBirth)
          },
          medicine: payload?.medicine?.map((med) =>
            ({
              medicineId: med.medicineId,
              medicineName: med.medicineName
            })),
          animalObject: {
            animalObjectId: payload?.animal?.animalObjects?.animalObjectId,
            animalObjectName: payload?.animal?.animalObjects?.animalObjectName
          }
        };
        const updIndx = state.ambulatoryLogs.findIndex((log) =>
          log.ambulatoryDiaryId === payload.ambulatoryDiaryId);
        state.ambulatoryLogs[updIndx] = receivedData;
      });
  }
});

// Selectors
export const selectAllAmbulatoryLogs = (state: RootState) =>
  state.ambulatoryLogs.ambulatoryLogs;
export const selectAmbulatoryLogLoadingState = (state: RootState) =>
  state.ambulatoryLogs.ambulatoryLogsLoading;

// Reducer
export default AmbulatoryLogSlice.reducer;
