/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from '../app/StoreHooks';
import { GET_ALL_USER_ROLES, GetUserRolesType, UserRoleType, UserType } from './data/UserSliceData';
import { createAsyncThunkGenericVoid } from './GenericThunks';

type UserState = {
  isAuthenticated?: boolean;
  user?: UserType;
  loading?: boolean;
  popupOpen?: boolean;
  roles: UserRoleType[];
  rolesLoading: boolean;
};

const initialState: UserState = {
  isAuthenticated: false,
  user: null,
  loading: false,
  popupOpen: false,
  roles: [],
  rolesLoading: false,
};

// Async Thunks
export const getAllUserRoles = createAsyncThunkGenericVoid(
  'user/getAllUserRoles',
  async ({ client }) => {
    const response = await client.query<void, GetUserRolesType>(
      'userRoles',
      GET_ALL_USER_ROLES
    );
    return response?.items ? response?.items : [];
  }
);

export const UserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuthentication: (state, { payload }: PayloadAction<boolean>) => {
      state.isAuthenticated = payload;
    },
    setLoading: (state, { payload }) => {
      state.loading = payload;
    },
    setPopupOpen: (state, { payload }) => {
      state.popupOpen = payload;
    },
    setUser: (state, { payload }) => {
      state.user = {
        name: payload?.name,
        email_verified: payload?.email_verified,
        nickname: payload?.nickname,
        org_id: payload?.org_id,
        permissions: payload?.permissions,
        picture: payload?.picture,
        sub: payload?.sub,
        updated_at: payload?.updated_at,
        email: payload?.email,
        roles: payload['https://blbtrace.org/roles']
      };
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllUserRoles.pending, (state) => {
        state.rolesLoading = true;
      })
      .addCase(getAllUserRoles.fulfilled, (state, { payload }) => {
        state.rolesLoading = false;
        state.roles = payload;
      });
  }
});

// Export actions
export const {
  setAuthentication, setLoading, setPopupOpen, setUser
} = UserSlice.actions;

// Selectors
export const selectIsAuthenticated = (state: RootState) =>
  state.user.isAuthenticated;

export const selectLoading = (state: RootState) =>
  state.user.loading;
export const selectPopupOpen = (state: RootState) =>
  state.user.popupOpen;
export const selectUser = (state: RootState) =>
  state.user.user;
export const selectUserRoles = (state: RootState) =>
  state.user.roles;

// Export the Slice
export default UserSlice.reducer;
