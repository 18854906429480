import React from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { useLocalization } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';
import {
  CANCEL,
  CONFIRM
} from '../../languages/languages';

type ConfirmDialogProps = {
  title: string;
  message: string;
  onResult: (result: boolean) => void;
};
const ConfirmDialog = ({
  title,
  message,
  onResult,
}: ConfirmDialogProps) => {
  const localizationService = useLocalization();
  const onReject = () => onResult(false);
  const onApprove = () => onResult(true);

  return (
    <Dialog title={title} onClose={onReject}>
      <div>
        {message}
      </div>
      <fieldset className='k-form-buttons'>
        <Button
          name='confirm'
          type='button'
          title={localizationService.toLanguageString(CONFIRM, '')}
          primary
          onClick={onApprove}
        >
          {localizationService.toLanguageString(CONFIRM, '')}
        </Button>
        <Button
          name='reject'
          type='button'
          title={localizationService.toLanguageString(CANCEL, '')}
          look='outline'
          onClick={onReject}
        >
          {localizationService.toLanguageString(CANCEL, '')}
        </Button>
      </fieldset>
    </Dialog>
  );
};

export default ConfirmDialog;
