import { gql } from '@apollo/client';

// Types

export enum AnimalTypeEnum {
  CATTLE = 'CATTLE',
  SHEEP = 'SHEEP'
}

export enum AnimalSexEnum {
  MALE = 'MALE',
  FEMALE = 'FEMALE'
}

export type AnimalType = {
  animalId: number;
  lID: string;
  eID: string;
  dateOfBirth: Date;
  comment: string;
  passportNumber: string;
  active: boolean;
  animalType: AnimalTypeEnum;
  cattleBreed: string;
  sheepBreed: string;
  sex: AnimalSexEnum;
  animalWeight?: number;
};

export type SelectedAnimalType = {
  animalId: number;
  lID: string;
  eID: string;
  dateOfBirth: Date;
  comment: string;
  passportNumber: string;
  active: boolean;
  animalType: AnimalTypeEnum;
  cattleBreed: string;
  sheepBreed: string;
  sex: AnimalSexEnum;
  animalWeight: number;
};

export type GetAnimalsResponseType = {
  items: AnimalType[];
};

export enum InspectionResultEnum {
  OKAY = 'OKAY',
  HOLD = 'HOLD',
  BAN = 'BAN'
}

export type InspectedAnimalType = {
  inspectorName: String;
  inspectionNote: string;
  inspectionDate: Date;
  animalId: number;
  inspectionResult: InspectionResultEnum;
};

export type VetInspectionInputType = {
  vetInspectionInput: InspectedAnimalType[];
};

export type ButcheredAnimalsType = {
  carcassWeight: number;
  carcassNote: string;
} & AnimalType;

export type GetButcheredAnimalsResponseType = {
  items: ButcheredAnimalsType[];
};

export type SlaughterInformationType = {
  matadorID: number;
  dateWeight: Date;
  animals: ButcheredAnimalsType[];
};

export type AnimalButcherType = {
  animalID: number;
  weight: number;
  comment: string;
  dateWeight: Date;
  userId: number;
  weightType: string;
};
export type KilledAnimalsInputType = {
  animalWeightInput: AnimalButcherType[];
};

export type AnimalGradingFormType = {
  animalId: number;
  inspectionDate: Date;
  inspector: number;
  ageClass: string;
  seuropMuscleClass: string;
  muscleClassification: string;
  fatClass: number;
  muscleClass: number;
  fatColor: string;
  ossifiScore: number;
  teethFormula: string;
  meatColor: number;
  carcassYield: number;
  carcassQuality: string;
};

export type AnimalToGradeType = {
  sEUROPDate: Date;
  sEUROPClass: string;
  muscleClass: number;
  ossifiScore: number;
  meatColor: number;
  carcassYield: number;
  animalId: number;
  userId: number;
  ageClass: string;
  fatColor: string;
  teethFormula: string;
  carcassQuality: string;
};

export type GradeInputType = {
  sEUROPInput: AnimalToGradeType;
};

export type AnimalCoolingType = {
  animalId: number;
  coolingId: number;
  coolingDateFirst: Date;
  pHFirst: number;
  temperatureFirst: number;
  coolingDateSecond: Date;
  pHSecond: number;
  temperatureSecond: number;
};

export type GetAnimalCoolingInputType = {
  id: number;
};

export type GetAnimalCoolingReponseType = {
  cooling: AnimalCoolingType;
};

export type FirstCoolingType = {
  animalId?: number;
  coolingDateFirst: Date;
  pHFirst: number;
  temperatureFirst: number;
};

export type FirstCoolingInputType = {
  coolingInput: FirstCoolingType;
};

export type SecondCoolingType = {
  coolingId?: number;
  coolingDateSecond: Date;
  pHSecond: number;
  temperatureSecond: number;
};

export type SecondCoolingInputType = {
  coolingInput: SecondCoolingType;
};

// Queries & Mutations
export const GET_ANIMALS = gql`
  query {
    animals(liveCycle: LIVE, location: FARM) {
      items {
        animalId
        lID
        eID
        dateOfBirth
        comment
        passportNumber
        active
        animalType
        cattleBreed
        sheepBreed
        sex
      }
    }
  }
`;

export const GET_ANIMALS_IN_OBJECT = gql`
  query animalsInObject($objectId: Int) {
    animalsInObject(animalType: 0, objectId: $objectId) {
      items {
        animalId
        lID
        eID
        dateOfBirth
        comment
        passportNumber
        active
        animalType
        cattleBreed
        sheepBreed
        sex,
        animalObjects {
          animalObjectNumber
        }
      }
    }
  }
`;

export const GET_ANIMALS_IN_BUTCHERY = gql`
  query {
    animals(liveCycle: LIVE, location: INBUTCHERY) {
      items {
        animalId
        lID
        eID
        dateOfBirth
        comment
        passportNumber
        active
        animalType
        cattleBreed
        sheepBreed
        sex
      }
    }
  }
`;

export const GET_BUTCHERED_ANIMALS = gql`
  query {
    animals(liveCycle: KILLED, location: INBUTCHERY) {
      items {
        animalId
        lID
        eID
        dateOfBirth
        comment
        passportNumber
        active
        animalType
        cattleBreed
        sheepBreed
        sex
      }
    }
  }
`;

export const GET_INSPECTED_CARCASSES = gql`
  query {
    animals(liveCycle: VETINSPECTION, location: INBUTCHERY) {
      items {
        animalId
        lID
        eID
        dateOfBirth
        comment
        passportNumber
        active
        animalType
        cattleBreed
        sheepBreed
        sex
      }
    }
  }
`;

export const GET_GRADED_CARCASSES = gql`
  query {
    animals(liveCycle: SEUROP, location: INBUTCHERY) {
      items {
        animalId
        lID
        eID
        dateOfBirth
        comment
        passportNumber
        active
        animalType
        cattleBreed
        sheepBreed
        sex
      }
    }
  }
`;

export const INSPECT_ANIMALS = gql`
  mutation AddVetInspection($vetInspectionInput: [vetInspectionInput]) {
    addVetInspection(vetInspectionInput: $vetInspectionInput) {
      vetInspectionId
      animal {
        lID
        animalId
      }
      inspectionResult
      inspectorName
      inspectionNote
      inspectionDate
    }
  }
`;

export const BUTCHER_ANIMALS = gql`
  mutation AddKillData($animalWeightInput: [animalWeightInput]) {
    addKillData(animalWeightInput: $animalWeightInput) {
      animal {
        animalId
        lID
        eID
        dateOfBirth
        comment
        passportNumber
        active
        animalType
        cattleBreed
        sheepBreed
      }
      weight
      weightID
      dateWeight
    }
  }
`;

export const GRADE_ANIMAL = gql`
  mutation AddSEUROP($sEUROPInput: sEUROPInput) {
    addSEUROP(sEUROPInput: $sEUROPInput) {
      sEUROPId
    }
  }
`;

export const GET_ANIMAL_COOLING = gql`
  query GetAnimalCooling($id: ID!) {
    animal(id: $id) {
      cooling {
        coolingId
        coolingDateFirst
        pHFirst
        temperatureFirst
        coolingDateSecond
        pHSecond
        temperatureSecond
      }
    }
  }
`;

export const FIRST_COOLING = gql`
  mutation AnimalCooling($coolingInput: coolingInput) {
    addCooling(coolingInput: $coolingInput) {
      coolingId
      coolingDateFirst
      pHFirst
      temperatureFirst
    }
  }
`;

export const SECOND_COOLING = gql`
  mutation UpdateCooling($coolingInput: coolingInput) {
    updateCooling(coolingInput: $coolingInput) {
      coolingId
      coolingDateFirst
      pHFirst
      temperatureFirst
      coolingDateSecond
      pHSecond
      temperatureSecond
    }
  }
`;
