import React from 'react';
import { Loader } from '@progress/kendo-react-indicators';
import { useLocalization } from '@progress/kendo-react-intl';
import TrademarkForm from '../../components/TrademarkForm/TrademarkForm';
import TrademarkGridContainer from '../TrademarkGridContainer/TrademarkGridContainer';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import {
  saveTrademark,
  selectTrademarksLoading
} from '../../slices/TrademarkSlice';
import { TRADEMARK_TAB } from '../../languages/languages';
import { TrademarkType } from '../../slices/data/TrademarkSliceData';
import { selectAvailableFirms } from '../../slices/FirmSlice';

const TrademarkContainer = () => {
  const localizationService = useLocalization();
  const isLoading = useAppSelector(selectTrademarksLoading);

  const dispatch = useAppDispatch();
  const availableFirms = useAppSelector(selectAvailableFirms);

  const handleSubmit = (formData: TrademarkType) => {
    dispatch(saveTrademark(formData));
  };

  return (
    <>
      <div className='holding-form'>
        <h3 className='subtitle-1 mb-s'>
          {localizationService.toLanguageString(TRADEMARK_TAB, '')}
        </h3>

        {isLoading ? (
          <Loader size='large' type='pulsing' className='k-centered' />
        ) : (
          <TrademarkForm
            availableFirms={availableFirms}
            submitForm={handleSubmit}
          />
        )}
      </div>

      <div className='holding-grid' id='trademark-table' />
      <TrademarkGridContainer />
    </>
  );
};

export default TrademarkContainer;
