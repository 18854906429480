import React from 'react';
import { Checkbox } from '@progress/kendo-react-inputs';

type ChechboxConstructorProps = {
  label: string;
  id: string;
  onChange?: any;
  checked?: boolean;
  disabled?: boolean;
};

const CheckboxConstructor = ({
  label,
  id,
  onChange,
  checked,
  disabled
}: ChechboxConstructorProps) =>
  (
    <div className='checkbox-wrapper'>
      <Checkbox
        checked={checked}
        onChange={onChange}
        label={label}
        id={id}
        disabled={disabled}
      />
    </div>
  );

CheckboxConstructor.defaultProps = {
  checked: false,
  onChange: null,
  disabled: false
};

export default CheckboxConstructor;
