import React from 'react';
import { useLocalization } from '@progress/kendo-react-intl';
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab
} from '@progress/kendo-react-layout';
import {
  FIRMS_TAB,
  ANOBJ_TAB,
  ENTERPRISE_TAB,
  SHOP_TAB,
  TRADEMARK_TAB
} from '../../languages/languages';
import { HoldingProfile } from '../../slices/data/HoldingSliceData';
import AnimalObjectContainer from '../../features/AnimalObjectContainer/AnimalObjectContainer';
import EnterpriseContainer from '../EnterpriseContainer/EnterpriseContainer';
import FirmContainer from '../../features/FirmContainer/FirmContainer';
import ShoppingCenterContainer from '../../features/ShoppingCenterContainer/ShoppingCenterContainer';
import TrademarkContainer from '../../features/TrademarkContainer/TrademarkContainer';

type HoldingStructureTableProps = {
  data: HoldingProfile;
  changeTab: (ev: TabStripSelectEventArguments) => void;
  selectedTab: number;
};
const HoldingStructureTable = ({
  changeTab,
  data,
  selectedTab
}: HoldingStructureTableProps) => {
  const localizationService = useLocalization();
  return (
    <div className='tab-strips'>
      {data?.holdingId && (
        <TabStrip selected={selectedTab} onSelect={changeTab}>
          <TabStripTab
            title={localizationService.toLanguageString(FIRMS_TAB, '')}
          >
            <FirmContainer />
          </TabStripTab>

          {(data?.isFarm || data?.isFeedlot) && (
            <TabStripTab
              title={localizationService.toLanguageString(ANOBJ_TAB, '')}
            >
              <AnimalObjectContainer />
            </TabStripTab>
          )}

          {(data?.isAbattoir || data?.isButchery) && (
            <TabStripTab
              title={localizationService.toLanguageString(ENTERPRISE_TAB, '')}
            >
              <EnterpriseContainer />
            </TabStripTab>
          )}

          {data?.isShop && (
            <TabStripTab
              title={localizationService.toLanguageString(SHOP_TAB, '')}
            >
              <ShoppingCenterContainer />
            </TabStripTab>
          )}
          {(data?.isShop || data?.isButchery || data?.isAbattoir) && (
            <TabStripTab
              title={localizationService.toLanguageString(TRADEMARK_TAB, '')}
            >
              <TrademarkContainer />
            </TabStripTab>
          )}
        </TabStrip>
      )}
    </div>
  );
};

export default HoldingStructureTable;
