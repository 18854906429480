import { Button } from '@progress/kendo-react-buttons';
import { useLocalization } from '@progress/kendo-react-intl';
import React from 'react';
import { Link } from 'react-router-dom';
import { PREVIEW } from '../../languages/keys/public.keys';
import { PublicObjectShortModel } from '../../services/public.service';

interface HoldingObjectsProps {
  objects: PublicObjectShortModel[];
}

const HoldingObjects = ({ objects }: HoldingObjectsProps) => {
  const $t = useLocalization();
  return (
    <div className='pt-l pb-m d-flex' style={{ overflowX: 'auto' }}>
      {objects &&
        objects.map((o) => (
          <div
            className='ml-s mr-s'
            style={{
              width: '10em',
              border: '1px solid lightgrey',
              borderRadius: '10px',
              padding: '15px 15px 15px 15px',
            }}
          >
            <div className='pb-m' style={{ display: 'flex', justifyContent: 'center' }}>
              {o.type === 'enterprise' && <span className='fa fa-industry fa-2x' />}
              {o.type === 'shoppingCenter' && <span className='fas fa-store fa-2x' />}
              {o.type === 'farm' && <span className='fa fa-tractor fa-2x' />}
            </div>
            <div className='text-center pb-m'>{o.typeName}</div>
            <div
              className='text-center pb-m'
              style={{
                color: 'lightgrey',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {o.settlement} &nbsp;
            </div>
            <div className='text-center'>
              <Link
                to={`/public/${o.type}/${o.id}`}
                target='_blank'
                style={{ textDecoration: 'none' }}
              >
                <Button color='primary'>{$t.toLanguageString(PREVIEW, '')}</Button>
              </Link>
            </div>
          </div>
        ))}
    </div>
  );
};

export default HoldingObjects;
