import { graphql } from 'msw';
import AUTH_CONFIG from '../../auth-variables';

const blbTrace = graphql.link(AUTH_CONFIG.apiUrl);

let enterprises = [
  {
    enterpriseId: 1,
    enterpriseName: 'Толев и ко.',
    enterpriseNumber: 1872369126313,
    enterpriseType: 'Кланица',
    firmId: 1,
    enterpriseAddress: 'гр. Пловдив, Център, ул. Тест 30',
    isActive: true
  }

];

export default [
  blbTrace.query('getAllEnterprisesQuery', (req, res, ctx) =>
    res(
      ctx.delay(5000),
      ctx.data({
        getEnterprises: {
          enterprises
        }
      })
    )),
  blbTrace.mutation('editEnterpriseMutation', (req, res, ctx) => {
    const { id, input } = req.variables;
    const editEnterprise = enterprises.findIndex((enterprise) =>
      enterprise.enterpriseId === id);
    enterprises[editEnterprise] = input;
    return res(
      ctx.delay(3000),
      ctx.data({
        editInformation: {
          input
        }
      })
    );
  }),
  blbTrace.mutation('saveEnterpriseMutation', (req, res, ctx) => {
    const { input } = req.variables;
    input.isActive = true;
    input.enterpriseId = enterprises.length + 1;
    enterprises = [...enterprises, input];
    return res(
      ctx.delay(5000),
      ctx.data({
        saveInformation: {
          input
        }
      })
    );
  })
];
