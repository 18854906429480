/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../app/StoreHooks';
import {
  EDIT_PASTURE_LOG,
  GetPastureLogsType,
  GET_ALL_PASTURE_LOGS,
  PastureLogType,
  SavePastureType,
  SAVE_PASTURE_LOG
} from './data/PastureLogSliceData';
import {
  createAsyncThunkGeneric,
  createAsyncThunkGenericVoid
} from './GenericThunks';

type PastureLogStateType = {
  pastureLogEntries: PastureLogType[];
  pastureLogEntriesLoading: boolean;
};

const initialState: PastureLogStateType = {
  pastureLogEntries: [],
  pastureLogEntriesLoading: false
};

// Thunks
export const getAllPastureLogs = createAsyncThunkGenericVoid(
  'pastureLog/getAllPastureLogs',
  async ({ client }) => {
    const response = await client.query<void, GetPastureLogsType>(
      'pastureDiary',
      GET_ALL_PASTURE_LOGS
    );
    return response?.items ? response?.items : [];
  }
);

export const savePastureLog = createAsyncThunkGeneric<
PastureLogType,
PastureLogType
>('pastureLog/savePastureLog', async ({ client, input }) =>
  client.mutate<SavePastureType, PastureLogType>(
    'addPastureDiary',
    SAVE_PASTURE_LOG,
    { pastureDiaryInput: input }
  ));

export const editPastureLog = createAsyncThunkGeneric<
PastureLogType,
PastureLogType
>('pastureLog/editPastureLog', async ({ client, input }) =>
  client.mutate<SavePastureType, PastureLogType>(
    'updatePastureDiary',
    EDIT_PASTURE_LOG,
    { pastureDiaryInput: input }
  ));

export const PastureLogSlice = createSlice({
  name: 'pastureLog',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllPastureLogs.pending, (state) => {
        state.pastureLogEntriesLoading = true;
      })
      .addCase(getAllPastureLogs.fulfilled, (state, { payload }) => {
        state.pastureLogEntriesLoading = false;
        state.pastureLogEntries = payload.map((entry) =>
          ({
            ...entry,
            dateIn: new Date(entry?.dateIn),
            dateOut: new Date(entry?.dateOut)
          }));
      })
      .addCase(savePastureLog.pending, (state) => {
        state.pastureLogEntriesLoading = true;
      })
      .addCase(savePastureLog.fulfilled, (state, { payload }) => {
        state.pastureLogEntriesLoading = false;
        state.pastureLogEntries = [
          ...state.pastureLogEntries,
          {
            ...payload,
            dateIn: new Date(payload?.dateIn),
            dateOut: new Date(payload?.dateOut)
          }
        ];
      })
      .addCase(editPastureLog.pending, (state) => {
        state.pastureLogEntriesLoading = true;
      })
      .addCase(editPastureLog.fulfilled, (state, { payload }) => {
        state.pastureLogEntriesLoading = false;
        const updIndex = state.pastureLogEntries.findIndex(
          (entry) =>
            entry.pastureDiaryId === payload.pastureDiaryId
        );
        state.pastureLogEntries[updIndex] = {
          ...payload,
          dateIn: new Date(payload?.dateIn),
          dateOut: new Date(payload?.dateOut)
        };
      });
  }
});

// Selectors
export const selectAllPastures = (state: RootState) =>
  state.pastureLog.pastureLogEntries;
export const selectPastureLoadingState = (state: RootState) =>
  state.pastureLog.pastureLogEntriesLoading;

// Reducers
export default PastureLogSlice.reducer;
