import React from 'react';
import { Dialog } from '@progress/kendo-react-dialogs';
import { ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import {
  Form,
  FormRenderProps,
  FormElement,
  Field
} from '@progress/kendo-react-form';
import { Button } from '@progress/kendo-react-buttons';
import { useLocalization } from '@progress/kendo-react-intl';
import {
  FormInput,
  FormComboBox,
  FormMaskedTextBox
} from '../FormComponents/FormComponents';
import {
  StringValidator,
  emailValidator,
  NumberValidator
} from '../Validators/Validators';
import {
  ContragentsFirms,
  ContragentType
} from '../../slices/data/ContragentsSliceData';
import {
  ADDRESS_BOOK_EMAIL,
  ADDRESS_BOOK_FIRM,
  ADDRESS_BOOK_NAME,
  ADDRESS_BOOK_PHONE,
  CANCEL,
  EDIT,
  TRANSPORTATION_DRIVER_PIN
} from '../../languages/languages';

type EditFormProps = {
  item: ContragentType;
  firms: ContragentsFirms[];
  cancelEdit: () => void;
  onSubmit: (formData: ContragentType) => void;
};

const EditContragentForm = ({
  item,
  firms,
  cancelEdit,
  onSubmit
}: EditFormProps) => {
  // Localization
  const localizationService = useLocalization();
  const selectedFirm = firms.find(
    (firm) =>
      firm.firmABName === item.firm.firmABName
  );
  return (
    <Dialog title={item.contragentName} onClose={cancelEdit}>
      <Form
        onSubmit={onSubmit}
        initialValues={item}
        render={(formRenderProps: FormRenderProps) =>
          (
            <FormElement>
              <fieldset className='k-form-fieldset'>
                <Field
                  name='contragentName'
                  component={FormInput}
                  label={localizationService.toLanguageString(
                    ADDRESS_BOOK_NAME,
                    ''
                  )}
                  placeholder='Име и фамилия'
                  validator={StringValidator}
                />

                <Field
                  name='contragentPhone'
                  component={FormMaskedTextBox}
                  label={localizationService.toLanguageString(
                    ADDRESS_BOOK_PHONE,
                    ''
                  )}
                  placeholder='+(359) 899 123 456'
                  mask='+359 000 000 000'
                  validator={NumberValidator}
                />

                <Field
                  name='contragentEmail'
                  component={FormInput}
                  label={localizationService.toLanguageString(
                    ADDRESS_BOOK_EMAIL,
                    ''
                  )}
                  placeholder='xxx@xx.xx'
                  validator={emailValidator}
                />
                <Field
                  name='contragentPIN'
                  component={FormInput}
                  label={localizationService.toLanguageString(
                    TRANSPORTATION_DRIVER_PIN,
                    ''
                  )}
                  placeholder='ЕГН'
                  validator={StringValidator}
                />
                <Field
                  onChange={({ value }: ComboBoxChangeEvent) => {
                    if (value === null) {
                      return;
                    }
                    formRenderProps.onChange('firmABId', {
                      value: value.firmABId
                    });
                  }}
                  id='firmABName'
                  name='firmABId'
                  label={localizationService.toLanguageString(
                    ADDRESS_BOOK_FIRM,
                    ''
                  )}
                  component={FormComboBox}
                  textField='firmABName'
                  defaultValue={selectedFirm}
                  data={firms}
                  placeholder='Избери опция...'
                  validator={StringValidator}
                />
              </fieldset>
              <div className='k-form-buttons'>
                <Button
                  name='Edit'
                  type='submit'
                  title='Обнови'
                  primary
                  disabled={!formRenderProps.allowSubmit}
                  icon='edit'
                >
                  {localizationService.toLanguageString(EDIT, '')}
                </Button>
                <Button
                  name='Cancel'
                  type='submit'
                  title='Откажи'
                  primary
                  look='outline'
                  disabled={!formRenderProps.allowSubmit}
                  icon='cancel'
                  onClick={cancelEdit}
                >
                  {localizationService.toLanguageString(CANCEL, '')}
                </Button>
              </div>
            </FormElement>
          )}
      />
    </Dialog>
  );
};

export default EditContragentForm;
