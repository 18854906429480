import Keys, { FEMALE_ABBR, MALE_ABBR, SAVE_IMAGE, SAVE_SVG } from './keys/keys';
import { ANIMALS, REASON, UNREGISTER, UNREGISTER_CHRONOLOGY_MSG, UNREGISTER_NEW, UNREGISTER_REASON, UNREGISTER_SUCCESS_MSG, UNREGISTER_TITLE } from './keys/movements.keys';

// VALIDATORS
export const REQUIRED_FIELD = 'RequiredField';

// Notifications
export const OTHER_EVENT = 'OtherEvent';
export const BUY_EVENT = 'BuyEvent';

// Grid variables
export const ADD_NEW = 'AddNew';
export const EDIT_SUBMISSION = 'EditSubmission';
export const COLUMNS = 'Columns';
export const ACTIVE = 'Active';
export const INACTIVE = 'Inactive';
export const EXPORT = 'Export';
export const REMOVE = 'Remove';
export const ADD_MORE = 'AddMore';
export const ACCEPT = 'Accept';

// General
export const REVISE = 'Revise';
export const PREVIOUS = 'Previous';
export const NEXT = 'Next';
export const YES = 'Yes';
export const NO = 'No';
export const DAYS = 'Days';

// Dashborad variables
export const WIDGET_DESCRIPTION_PLACEHOLDER = 'WidgetDescriptionPlaceholder';
export const SALE_TRADE_CHART_TITLE = 'SaleTradeChartTitle';
export const PROFILE_STATUS_TITLE = 'ProfileStatusTitle';
export const PROFILE_RATING_TITLE = 'ProfileRatingTitle';
export const PROFILE_COMPLETION_TITLE = 'ProfileCompletionTitle';
export const HISTORY_CHART_TITLE = 'HistoryChartTitle';
export const SEUROP_TITLE = 'SEUROPTITLE';
export const ANIMAL_ACCEPTANCE_TITLE = 'AnimalAcceptanceTitle';
export const PLACEHOLDER_TITLE = 'PlaceholderTitle';
export const STATUS_CONTENT = 'StatusContent';

// Drawer variables
export const DASHBOARD_TITLE = 'DashboardTitle';
export const LOGS_TITLE = 'LogsTitle';
export const AMBULATORY_LOGS_TITLE = 'AmbulatoryLogsTitle';
export const ARTIFICIAL_INSEMENATION = 'ArtificialInsemenation';
export const PASTURE_LOG_TITLE = 'PastureLogTitle';
export const MORTALITY_LOG_TITLE = 'MortalityLogTitle';
export const CHEMICAL_TREATMENT_LOG_TITLE = 'ChemicalTreatementLogTitle';
export const PEST_CONTROL_LOG_TITLE = 'PestControlLogTitile';
export const USED_PESTICIDE_LOG_TITLE = 'UsedPesticideLogTitle';
export const ANIMALS_IN_FARM_TABLE_TITLE = 'AnimalsInFarmTableTitle';
export const ANIMALS_IN_BUTCHERY_TABLE_TITLE = 'AnimalsInButcheryTableTitle';
export const BUTCHERED_ANIMALS_TABLE = 'ButcheredAnimalsTable';
export const DEALS_TITLE = 'DealsTitle';
export const ADDRESS_BOOK_TITLE = 'AddressBookTitle';
export const HOLDING_PROFILE_TITLE = 'HoldingProfileTitle';
export const LINK_PLACEHOLDER_TITLE = 'LinkPlaceHolderTitle';
export const MOVEMENTS_TITLE = 'MovementsTitle';
export const MOVEMENTS_REGISTRATION_TITLE = 'MovementsRegistrationTitle';
export const MOVEMENTS_UNREGISTRATION_TITLE = 'MovementsUnregistrationTitle';
export const MOVEMENTS_SALE_TITLE = 'MovementsSaleTitle';

// AppBar container
export const SEARCH_BAR = 'SearchBar';
export const FERMER_TITLE = 'FermerTitle';
export const PROCESSOR_TITLE = 'ProcessorTitle';
export const TRADER_TITLE = 'TraderTitle';
export const CANDIDATE_TITLE = 'CandidateTitle';

// Holding Profile
export const HOLDING_PROFILE = 'HoldingProfile';
export const FIRMS_TAB = 'FirmsTab';
export const ANOBJ_TAB = 'AnimalObjectTab';
export const ENTERPRISE_TAB = 'EnterpriseTab';
export const SHOP_TAB = 'ShopTab';
export const TRADEMARK_TAB = 'TrademarkTab';

// General Info Holding Profile
export const HOLDING_NAME = 'HoldingName';
export const HOLDING_STRUCTURE = 'HoldingStructure';
export const HOLDING_SAVE = 'HoldingSave';
export const HOLDING_DECLINE = 'HoldingDecline';
export const IS_FARM = 'isFarm';
export const IS_FEEDLOT = 'isFeedlot';
export const IS_BUTCHERY = 'isButchery';
export const IS_ABATTOIR = 'isAbattoir';
export const IS_SHOP = 'isShop';
export const CATTLE = 'cattle';
export const SHEEP = 'sheep';
export const GENERAL_INFO = 'GeneralInfo';

// General Forms
export const STATUS = 'Stats';
export const SUBMIT_FORM = 'SubmitForm';
export const CLEAR_FORM = 'ClearForm';
export const UPDATE = 'Update';
export const DECLINE = 'Decline';
export const SEND = 'Send';
export const SAVE = 'Save';
export const EDIT = 'Edit';
export const CANCEL = 'Cancel';
export const CONFIRM = 'Confirm';
export const ADDRESS_PLACEHOLDER = 'AddressPlaceholder';
export const AMOUNT_PLACEHOLDER = 'AmountPlaceholder';
export const CITY_PLACEHODLDER = 'CityPlaceholder';
export const OWNER_PLACEHOLDER = 'OwnerPlaceholder';
export const DATE = 'Date';
export const CHOOSE_DROPDOWN_OPTION = 'ChooseDropdownOption';

// Firm Tab
export const EIK = 'EIK';
export const FIRM_NAME = 'FirmName';
export const FIRM_COUNTRY = 'FirmCountry';
export const FIRM_CITY = 'FirMCity';
export const FIRM_ADDRESS = 'FirmAddress';
export const FIRM_MOL = 'FirmMol';

// Animal Object Form
export const AO_STATUS = 'AnimalObjectStatus';
export const AO_NAME = 'AnimalObjectName';
export const AO_NUMBER = 'AnimalObjectNumber';
export const AO_TRACES = 'AnimalObjectTraces';
export const AO_CITY = 'AnimalObjectCity';
export const AO_ADDRESS = 'AnimalObjectAddress';
export const AO_CAPACITY = 'AnimalObjectCapacity';
export const AO_BUILDINGS = 'AnimalObjectBuildings';
export const AO_BACREAGE = 'AnimalObjectBuildingsAcreage';
export const AO_PASTURES = 'AnimalObjectPastures';
export const AO_PACREAGE = 'AnimalObjectPasturesAcreage';
export const AO_OWNER = 'AnimalObjectOwner';
export const AO_BRANCH = 'AnimalObjectBranch';
export const AO_CATTLE = 'AnimalObjectCattle';
export const AO_SHEEP = 'AnimalObject';

// Enterprise Form
export const ENT_NAME = 'EnterpriseName';
export const ENT_NUMBER = 'EnterpriseNumber';
export const ENT_TYPE = 'EnterpriseType';
export const ENT_TYPE_PLACEHOLDER = 'EnterpriseTypePlaceholder';
export const ENT_OWNER = 'EnterpriseOwner';
export const ENT_ADDRESS = 'EnterpriseAddress';

// Shops Form
export const SHOP_NAME = 'ShopName';
export const SHOP_NUMBER = 'ShopNumber';
export const SHOP_TYPE = 'ShopType';
export const SHOP_OWNER = 'ShopOwner';
export const SHOP_ADDRESS = 'ShopAddress';
export const SHOP_DEFAULT_VALUE = 'ShopDefaultValue';
export const SHOP_SMALL_SHOP = 'ShopSmallShop';
export const SHOP_MTS = 'ShopModernTradingShop';

// Trademark Form
export const TM_NAME = 'TrademarkName';
export const TM_NAME_PLACEHOLDER = 'TrademarkNamePlaceholder';
export const TM_REG = 'TrademarkRegistration';
export const TM_LINK = 'TrademarkLink';
export const TM_LINK_PLACEHOLDER = 'TrademarkLinkPlaceholder';
export const TM_IMAGE = 'TrademarkImage';

// Animal Table
export const CATTLE_TAB = 'CattleTab';
export const SHEEP_TAB = 'SheepTab';
export const ANIMAL_ENTRY = 'Animal Entry';
export const ANIMAL_EXIT = 'AnimalExit';

// Animal Preview
export const SELECTED_ANIMALS_PREVIEW = 'SelectedAnimalsPreview';

// Sell Animals Component
// Steps
export const START = 'Start';
export const WEIGTHS = 'Weights';
export const PRICING = 'Pricing';
// Deal details
export const FIRMS_DETAILS = 'FirmsDetails';
export const TRANSPORT_DETAILS = 'TransportDetails';
export const SALE_INFO = 'SaleInfo';
export const SELLING_FIRM = 'SellingFirm';
export const SELL_FIRM_NAME = 'SellFirmName';
export const SELL_FIRM_EIK = 'SellFirmEIK';
export const SELL_FIRM_MOL = 'SellFirmMOL';
export const BUYING_FIRM = 'BuyingFirm';
export const BUY_FIRM_NAME = 'BuyFirmName';
export const BUY_FIRM_EIK = 'BuyFirmEIK';
export const BUY_FIRM_MOL = 'BuyFirmMOL';
export const QUANTITY_INFO = 'QuantityInfo';
export const VEHICLE_WEIGHT = 'VehicleWeight';
export const EMPTY_VEHICLE_WEIGHT = 'EmptyVehicleWeight';
export const ANIMAL_WEIGHT = 'AnimalWeight';
export const AVERAGE_WEIGHT = 'AverageWeight';
export const INDIVIDUAL_WEIGHT = 'IndividualWeight';
export const ANIMAL_NET_WEIGHT = 'AnimalNetWeight';
export const AVERAGE_GROUP_NET_WEIGHT = 'AverageGroupNetWeight';
export const AVERAGE_ANIMAL_NET_WEIGHT = 'AverageAnimalNetWeight';
export const FINANCIAL_INFO = 'FinancialInfo';
export const PRICE_PER_KILO = 'PricePerKilo';
export const PRICE_PER_ANIMAL = 'PricePerAnimal';
export const GROUP_TOTAL_PRICE = 'GroupTotalPrice';
export const AVERAGE_ITEM_PRICE = 'AverageItemPrice';
export const TRANSPORTATION_INFO = 'TransportationInfo';
export const TRANSPORTATION_DRIVER = 'TransportationDriver';
export const TRANSPORTATION_DRIVER_PIN = 'TransportationDriverPin';
export const TRANSPORTATION_VEHICLE_REG = 'TransportationVehicleReg';
export const TRANSPORTATION_DATE = 'TransportationDate';
export const TRANSPORTATION_SERIAL_NUMBER = 'TransportationSerialNumber';
export const ARRIVAL_DATE = 'ArrivalDate';
export const DEAL_PRICING = 'DealPricing';
export const ENTER_PRICE_PER_HEAD = 'EnterPricePerHead';
export const ENTER_PRICE_PER_ANIMAL = 'EnterPricePerAnimal';

// Select Animal Opearations
export const ANIMAL_OPERATIONS = 'AnimalOperations';
export const SELECT_OPERATION = 'SelectOperation';
export const SELL_OPERATION = 'SellOperation';
export const VETERINARY_INSPECTION = 'VeterinaryInspection';

// Address Book
export const ADDRESS_BOOK_CONTRAGENTS = 'AddressBookContragents';
export const ADDRESS_BOOK_EMPLOYEES = 'AddressBookEmployees';
export const ADDRESS_BOOK_TRUCKS = 'AddressBookTrucks';
export const ADDRESS_BOOK_FIRMS = 'AddressBookFirms';
export const ADDRESS_BOOK_NAME = 'AddressBookName';
export const ADDRESS_BOOK_ROLE = 'AddressBookRole';
export const ADDRESS_BOOK_PHONE = 'AddressBookPhone';
export const ADDRESS_BOOK_EMAIL = 'AddressBookEmail';
export const ADDRESS_BOOK_STATUS = 'AddressBookStatus';
export const ADDRESS_BOOK_FIRM = 'AddressBookFIrm';
export const ADDRESS_BOOK_FIRM_TYPE = 'AddressBookFirmType';
export const ADD_NEW_EMPLOYEE = 'AddNewEmployee';
export const EDIT_EMPLOYEE = 'EditEmployee';

// Deals
export const DEAL = 'Deal';
export const DEAL_ACCEPT_STATUS = 'DealAcceptStatus';
export const DEAL_REJECT_STATUS = 'DealRejectStatus';
export const DEAL_PARTIAL_ACCEPT_STATUS = 'DealPartialAcceptStatus';
export const DEAL_PENDING_STATUS = 'DealPendingStatus';
export const DEAL_ACCEPT = 'DealAccept';
export const DEAL_ACCEPT_PARTIAL = 'DealAcceptPartial';
export const DEAL_REJECT = 'DealReject';
export const DEAL_STATUS = 'DealStatus';
export const DEAL_NUMBER = 'DealNumber';
export const DEAL_CREATOR = 'DealCreator';
export const DEAL_DATE = 'DealDate';
// DealGrid
export const DEAL_ANIMAL_INFO = 'DealAnimalInfo';
export const DEAL_ANIMAL_WEIGHT = 'DealAnimalWeight';
export const DEAL_ANIMAL_PPH = 'DealAnimalPPH';
export const DEAL_ANIMAL_PPK = 'DealAnimalPPK';
export const DEAL_ANIMAL_PPHK = 'DealAnimalPPHK';
export const DEAL_ANIMAL_ARRIVAL_WEIGHT = 'DealAnimalArrivalWeight';
export const DEAL_ANIMAL_NOTE = 'DealAnimalNote';

// Kill Data
export const KILL_DATA_OPERATION = 'KillDataOption';
export const KILL_DATA_MATADOR = 'KillDataMatador';

// Carcass Quality Grading
export const ANIMAL_SELECTION_PLACEHOLDER = 'AnimalSelectionPlaceholder';
export const CARCASS_QUALITY_GRADE = 'CarcassQualityGrade';
export const GRADED_ANIMAL_IDENTIFICATOR = 'GradedAnimalIdentificator';
export const INSPECTION_DATE = 'InspectionDate';
export const INSPECTOR = 'Inspector';
export const AGE_CLASS = 'AgeClass';
export const UNKNOWN_CLASS = 'UnknownClass';
export const Z_CLASS = 'ZClass';
export const Z_CLASS_TITLE = 'ZClassTitle';
export const A_CLASS = 'AClass';
export const A_CLASS_TITLE = 'AClassTitle';
export const B_CLASS = 'BClass';
export const B_CLASS_TITLE = 'BClassTitle';
export const C_CLASS = 'CClass';
export const C_CLASS_TITLE = 'CClassTitle';
export const D_CLASS = 'DClass';
export const D_CLASS_TITLE = 'DClassTitle';
export const E_CLASS = 'EClass';
export const E_CLASS_TITLE = 'EClassTitle';
export const MUSCLE_CLASS = 'MuscleClass';
export const MUSCLE_CLASSIFICATION = 'MuscleClassification';
export const FAT_CLASS = 'FatClass';
export const FAT_COLOR = 'FatColor';
export const OSSIFI_SCORE = 'OssifiScore';
export const TEETH_FORMULA = 'TeethFormula';
export const MEAT_COLOR = 'MeatColor';
export const CARCASS_YIELD = 'CarcassYield';
export const CARCASS_QUALITY = 'CarcassQuality';
export const FAT_WHITE = 'FatWhite';
export const FAT_CREAMY_WHITE = 'FatCreamyWhite';
export const FAT_SLIGHTLY_YELLOW = 'FatSlightlyYellow';
export const FAT_MODERATELY_YELLOW = 'FatModeratelyYellow';
export const FAT_YELLOW = 'FatYellow';
export const TEETH_NONE = 'TeethNone';
export const CARCASS_QUALITY_PRIME = 'CarcassQualityPrime';
export const CARCASS_QUALITY_CHOICE = 'CarcassQualityChoice';
export const CARCASS_QUALITY_SELECT = 'CarcassQualitySelect';
export const CARCASS_QUALITY_STANDARD = 'CarcassQualityStandard';
export const CARCASS_QUALITY_COMMERCIAL = 'CarcassQualityCommercial';
export const CARCASS_QUALITY_UTILITY = 'CarcassQualityUtility';
export const CARCASS_QUALITY_CUTTER = 'CarcassQualityCutter';
export const CARCASS_QUALITY_CANNER = 'CarcassQualityCanner';

// AnimalCooling
export const ANIMAL_COOLING = 'AnimalCooling';
export const FIRST_CHECK = 'FirstCheck';
export const SECOND_CHECK = 'SecondCheck';
export const COOLING_START = 'CoolingStart';
export const PH_VALUE = 'CoolingPHValue';
export const TEMPERATURE = 'CoolingTemperature';

// Logs
// Artificial Insemination Log
export const COW_LID = 'CowLID';
export const FERTILIZATION_TYPE = 'FertilizationType';
export const SIRE_LID = 'SireLID';
export const FERTILIZATION_START = 'FertilizationStart';
export const FERTILIZATION_END = 'FertilizationEnd';
export const PREGNANCY_TEST_DATE = 'PregnancyTestDate';
export const TEST_RESULT = 'TestResult';
export const PREGNANCY_STAGE = 'PregnancyStage';
export const EXPECTED_DUE_DATE = 'ExpectedDueDate';
export const PREGNANCY_RESULT_YES = 'PregnancyResultYes';
export const PREGNANCY_RESULT_NO = 'PregnancyResultNo';
export const PREGNANCY_RESULT_UNID = 'PregnancyResultUnid';
export const FERTILIZATION_NATURALLY = 'FertilizationNaturally';
export const FERTILIZATION_ARTIFICIALLY = 'FertilizationArtificially';
// PastureLog
export const ANIMAL_COUNT = 'AnimalCount';
export const HERD_GROUP = 'HerdGroup';
export const PASTURE_NAME = 'PastureName';
export const PASTURE_DATE_OF_ENTRY = 'PastureDateOfEntry';
export const PASTURE_DATE_OF_EXIT = 'PastureDateOfExit';
export const DAYS_SPENT_IN_PASTURE = 'DatesSpentInPasture';
export const PASTURE_ACREAGE = 'PastureAcreage';
export const PASTURE_GRASS_HEIGHT_IN = 'PastureGrassHeightIn';
export const PASTURE_GRASS_HEIGHT_OUT = 'PastureGrassHeightOut';
// PurchasedMedicine
export const PURCHASED_MEDICINE_LOG_TITLE = 'PurchasedMedicineLogTitle';
export const PURCHASED_MEDICINE_LOG_ENTRY_DATE = 'PurchasedMedicineLogEntryDate';
export const PURCHASED_MEDICINE_NAME = 'PurchasedMedicineName';
export const MEDICINE_CATEGORY_NAME = 'MedicineCategoryName';
export const PURCHASED_MEDICINE_AMOUNT = 'PurchasedMedicineAmount';
export const PURCHASED_MEDICINE_DELIVERED_BY = 'PurchasedMedicineDeliveredBy';
export const MEDICINE_WITHDRAW_TITLE = 'MedicineWithdrawTitle';
export const PURCHASED_MEDICINE_WITHDRAW_PERIOD_MILK = 'PurchasedMedicineWithdrawPeriodMilk';
export const PURCHASED_MEDICINE_WITHDRAW_PERIOD_MEAT = 'PurchasedMedicineWithdrawPeriodMeat';
export const PURCHASED_MEDICINE_BATCH_NUMBER = 'PurchasedMedicineBatchNumber';
export const PURCHASED_MEDICINE_EXPIRY_DATE = 'PurchasedMedicineExpiryDate';
export const PURCHASED_MEDICINE_NOTE = 'PurchasedMedicineNote';
// Fodder Log
export const PURCHASED_FODDER_TITLE = 'PurchasedFodderTitle';
export const PRODUCED_FODDER_TITLE = 'ProducedFodderTitle';
export const FODDER_LOG_TITLE = 'FodderLogTitle';
export const FODDER_DATE = 'Fodder Date';
export const FODDER_NAME = 'FodderName';
export const FODDER_CATEGORY = 'FodderCategory';
export const FODDER_BATCH_NUMBER = 'FodderBatchNumber';
export const FODDER_AMOUNT_PRODUCED = 'FodderAmountProduced';
export const FODDER_AMOUNT_BOUGHT = 'FodderAmountBought';
export const FODDER_COURIER = 'FodderCourier';
export const FODDER_NOTE = 'FodderNote';
export const FODDER_SELF_PRODUCED = 'FodderSelfProduced';
export const FODDER_TYPE = 'FodderType';
// FodderCategories
export const UNKNOWN_CATEGORY = 'UnknownCategory';
export const WETFEED_CATEGORY = 'WetfeedCategory';
export const WATER_CATEGORY = 'WaterCategory';
export const OTHER_CATEGORY = 'OtherCategory';
export const LONGFIBER_CATEGORY = 'LongfiberCategory';
export const CONCENTRATE_CATEGORY = 'ConcentrateCategory';
export const ROOTS_CATEGORY = 'RootsCategory';
export const SOFTFIBER_CATEGORY = 'SoftfiberCategory';
export const VITAMINS_CATEGORY = 'VitaminsCategory';
export const LIQUIDS_CATEGORY = 'LiquidsCategory';

// PurchasedMedicine categories
export const ANTIBIOTIC_CATEGORY = 'AntibioticCategory';
export const VACCINE_CATEGORY = 'VaccineCategory';
export const VITAMIN_CATEGORY = 'VitaminCategory';
export const CALCIUM_CATEGORY = 'CalciumCategory';
export const PROBIOTIC_CATEGORY = 'ProbioticCategory';
export const ANITIINFLAMMATORY_CATEGORY = 'AntiinflammatoryCategory';
export const ANTIPARASITIC_CATEGORY = 'AntiparasiticCategory';
export const OINTMENT_CATEGORY = 'OintmentCategory';
export const HORMONAL_CATEGORY = 'HormonalCategory';
export const SUPPLIMENT_CATEGORY = 'SupplimentCategory';

// Mortality log
export const ANIMAL_MORTALITY = 'AnimalMortality';
export const ANIMAL_HEALTH_PROBLEM = 'AnimalHealthProblem';
export const ANIMAL_TERAPY = 'AnimalTerapy';
// Animal Information
export const MALE = 'Male';
export const FEMALE = 'Female';
export const CATTLE_TYPE = 'CattleType';
export const SHEEP_TYPE = 'Sheeptype';
export const ANIMAL_SEX = 'AnimalSex';
export const ANIMAL_BREED = 'AnimalBreed';
export const ANIMAL_AGE = 'AgeInMonths';
export const ANIMAL_LID = 'AnimalLID';

// Ambulatory Log
export const AMBULATORY_NUMBER = 'AmbulatoryNumber';
export const AMBULATORY_DATE = 'AmbulatoryDate';
export const AMBULATORY_ANIM_OBJ = 'AmbulatoryAnimalObj';
export const ANIMAL_TYPE = 'AnimalType';
export const AMBULATORY_CLINICAL_DATA = 'AmbulatoryClinicalData';
export const AMBULATORY_DIAGNOSTIC_TEST = 'AmbulatoryDiagnosticTest';
export const AMBULATORY_DIAGNOSIS = 'AmbulatoryDiagnosis';
export const AMBULATORY_MEDICINES = 'AmbulatoryMedicines';
export const AMBULATORY_DOSE = 'AmbulatoryDose';
export const AMBULATORY_WITHDRAWAL_PERIOD = 'AmbulatoryWithdrawalPeriod';
export const AMBULATORY_DIAGNOSIS_OUT = 'AmbulatoryDiagnosisOout';
export const AMBULATORY_NOTE = 'AmbulatoryNote';

// Chemical Treatement Log
export const CHEMICAL_TREATEMENT_PEST = 'ChemicalTreatementPest';
export const CHEMICAL_TREATEMENT_DOSE = 'ChemicalTreatementDose';
export const CHEMICAL_TREATEMENT_TREATED_AREA = 'ChemicalTreatementTreatedArea';
export const CHEMICAL_TREATEMENT_TECHNIQUE = 'ChemicalTreatementTechnique';
export const CHEMICAL_TREATEMENT_CHEMICAL = 'ChemicalTreatementChemical';
export const CHEMICAL_TREATEMENT_ACTIVE_CHEMICAL = 'ChemicalTreatementActiveChemical';
export const CHEMICAL_TREATEMENT_CONCENTRATE = 'ChemicalTreatementConcentrate';
// Pest Control Log
export const PEST = 'Pest';
export const PEST_DAMAGE_LEVEL = 'PestDamageLevel';
export const DAMAGED_CULTURES = 'DamagedCultures';
export const USED_CHEMICALS = 'UsedChemicals';
export const CHEMICAL_TYPE = 'ChemicalType';
export const METEOROLOGY = 'Meteorology';
export const APPLICATION_METHOD = 'ApplicationMethod';
// Damage Levels
export const DAMAGE_LEVEL_LOW = 'DamageLevelLow';
export const DAMAGE_LEVEL_MEDIUM = 'DamageLevelMedium';
export const DAMAGE_LEVEL_HIGH = 'DamageLevelHight';
export const DAMAGE_LEVEL_TOTAL = 'DamageLevelTotal';

// Used Pesticide Log
export const USED_PESTICIDE_OPERATOR = 'UsedPesticideOperator';
export const USED_PESTICIDE_NAME = 'UsedPesticideName';
export const USED_PESTICIDE_ACTIVE_CHEMICAL = 'UsedPesticideActiveChemical';
export const USED_PESTICIDE_NUMBER = 'UsedPesticideNumber';
export const USED_PESTICIDE_TREATED_CULTURE = 'UsedPesticideTreatedCulture';
export const USED_PESTICIDE_HECTARE_NORM = 'UsedPesticideHectareNorm';
export const USED_PESTICIDE_TREATED_AREA = 'UsedPesticideTreatedArea';
export const USED_PESTICIDE_TOTAL = 'UsedPesticideTotal';
export const USED_PESTICIDE_ENTRY_BAN_PERIOD = 'UsedPesticideEntryBanPeriod';
export const USED_PESTICIDE_MOWING_BAN_PERIOD = 'UsedPesticideMowingBanPeriod';

// Stepper
export const FORWARD = 'Forward';
export const BACKWORD = 'Backword';
export const STEPFROMTOTAL = 'StepFromTotal';

export default {
  // Validators
  [REQUIRED_FIELD]: 'This field is required',

  // Notifications
  [OTHER_EVENT]: 'Other notification',
  [BUY_EVENT]: 'There is a new animal order',

  // Grid variables
  [ADD_NEW]: 'Add new',
  [EDIT_SUBMISSION]: 'Edit entry',
  [COLUMNS]: 'Columns',
  [ACTIVE]: 'Active',
  [INACTIVE]: 'Inactive',
  [EXPORT]: 'Export',
  [REMOVE]: 'Remove',
  [ADD_MORE]: 'Add More',
  [ACCEPT]: 'Accept',

  // General
  [REVISE]: 'Revise',
  [PREVIOUS]: 'Previous',
  [NEXT]: 'Next',
  [YES]: 'Yes',
  [NO]: 'No',
  [DAYS]: 'Days',
  [Keys.LOAD]: 'Load',

  // Dashboard component
  [WIDGET_DESCRIPTION_PLACEHOLDER]: 'Widget Description',
  [SALE_TRADE_CHART_TITLE]: 'Trade/Sale',
  [PROFILE_STATUS_TITLE]: 'Status',
  [PROFILE_RATING_TITLE]: 'Rating',
  [PROFILE_COMPLETION_TITLE]: 'Profile Completion',
  [HISTORY_CHART_TITLE]: 'History',
  [SEUROP_TITLE]: 'S.E.U.R.O.P Rating',
  [ANIMAL_ACCEPTANCE_TITLE]: 'Animal Acceptance',
  [PLACEHOLDER_TITLE]: 'Placeholder',

  // Dashboard component contents
  [STATUS_CONTENT]: 'Your profile is under review',

  // Drawer component titles
  [DASHBOARD_TITLE]: 'Dashboard',
  [LOGS_TITLE]: 'Logs',
  [AMBULATORY_LOGS_TITLE]: 'Ambulatory',
  [ARTIFICIAL_INSEMENATION]: 'Artificial Insemenation',
  [PASTURE_LOG_TITLE]: 'Pastures',
  [MORTALITY_LOG_TITLE]: 'Mortality and important health occurrences',
  [CHEMICAL_TREATMENT_LOG_TITLE]: 'Chemical treatment',
  [PEST_CONTROL_LOG_TITLE]: 'Pest Control',
  [USED_PESTICIDE_LOG_TITLE]: 'Used Pesticide',
  [ANIMALS_IN_FARM_TABLE_TITLE]: 'Animals table',
  [ANIMALS_IN_BUTCHERY_TABLE_TITLE]: 'Animals in butchery',
  [BUTCHERED_ANIMALS_TABLE]: 'Slaughtered animals table',
  [DEALS_TITLE]: 'Deals',
  [ADDRESS_BOOK_TITLE]: 'Address Book',
  [HOLDING_PROFILE_TITLE]: 'Holding Profile',
  [LINK_PLACEHOLDER_TITLE]: 'Link',
  [MOVEMENTS_TITLE]: 'Movements',
  [MOVEMENTS_REGISTRATION_TITLE]: 'Register',
  [MOVEMENTS_UNREGISTRATION_TITLE]: 'Unregister',
  [MOVEMENTS_SALE_TITLE]: 'Sale',

  // AppBar component titles
  [SEARCH_BAR]: 'Search...',
  [FERMER_TITLE]: 'Farmer Zone',
  [PROCESSOR_TITLE]: 'Processor Zone',
  [TRADER_TITLE]: 'Trader Zone',
  [CANDIDATE_TITLE]: 'Candidate',

  // HOLDING PROFILE
  [HOLDING_PROFILE]: 'Holding Profile',
  [FIRMS_TAB]: 'Firms',
  [ANOBJ_TAB]: 'Animal Objects',
  [ENTERPRISE_TAB]: 'Enterprises',
  [SHOP_TAB]: 'Shops',
  [TRADEMARK_TAB]: 'Trademarks',

  // General Info Holding Profile
  [HOLDING_NAME]: 'Holding Name',
  [HOLDING_STRUCTURE]: 'Holding structure',
  [HOLDING_SAVE]: 'Save',
  [HOLDING_DECLINE]: 'Decline',
  [IS_FARM]: 'Farm',
  [IS_FEEDLOT]: 'Feedlot',
  [IS_BUTCHERY]: 'Butchery',
  [IS_ABATTOIR]: 'Abattoir',
  [IS_SHOP]: 'Shop',
  [CATTLE]: 'Cattle',
  [SHEEP]: 'Sheep',
  [GENERAL_INFO]: 'General Info',

  // General Forms
  [STATUS]: 'Status',
  [SUBMIT_FORM]: 'Add New',
  [CLEAR_FORM]: 'Clear',
  [UPDATE]: 'Save',
  [DECLINE]: 'Decline',
  [SEND]: 'Send',
  [SAVE]: 'Save',
  [EDIT]: 'Edit',
  [CANCEL]: 'Cancel',
  [CONFIRM]: 'Потвърди',
  [ADDRESS_PLACEHOLDER]: 'Address',
  [AMOUNT_PLACEHOLDER]: 'amount',
  [CITY_PLACEHODLDER]: 'City',
  [OWNER_PLACEHOLDER]: 'Legal Entity / Individual',
  [DATE]: 'Date',
  [CHOOSE_DROPDOWN_OPTION]: 'Please, select an option...',

  // Firm Tab
  [EIK]: 'EIK/PIN',
  [FIRM_NAME]: 'Firm Name',
  [FIRM_COUNTRY]: 'Country',
  [FIRM_CITY]: 'City',
  [FIRM_ADDRESS]: 'Address',
  [FIRM_MOL]: 'Mol',

  // Animal Object Tab
  [AO_STATUS]: 'Status',
  [AO_NAME]: 'Name',
  [AO_NUMBER]: 'Animal Object №',
  [AO_TRACES]: '№ TRACES',
  [AO_CITY]: 'City',
  [AO_ADDRESS]: 'Address',
  [AO_CAPACITY]: 'Capacity',
  [AO_BUILDINGS]: 'Buildings',
  [AO_BACREAGE]: 'Building acreage / m2',
  [AO_PASTURES]: 'Number of pastures',
  [AO_PACREAGE]: 'Pastures acreage / m2',
  [AO_OWNER]: 'Owner',
  [AO_BRANCH]: 'Animal Object Branch',
  [AO_CATTLE]: 'Cattle',
  [AO_SHEEP]: 'Sheep',

  // Enterprise Tab
  [ENT_NAME]: 'Entity',
  [ENT_NUMBER]: 'Number',
  [ENT_TYPE]: 'Enterprise Type',
  [ENT_OWNER]: 'Owner',
  [ENT_ADDRESS]: 'City',
  [ENT_TYPE_PLACEHOLDER]: 'Select the enterprise`s type',

  // Shop Form
  [SHOP_NAME]: 'Entity name',
  [SHOP_NUMBER]: 'Entity number',
  [SHOP_TYPE]: 'Shop type',
  [SHOP_OWNER]: 'Owner',
  [SHOP_ADDRESS]: 'City & Address',
  [SHOP_DEFAULT_VALUE]: 'Select type',
  [SHOP_SMALL_SHOP]: 'Small Shop',
  [SHOP_MTS]: 'Modern trade entity',

  // Trademark Form
  [TM_NAME]: 'Name',
  [TM_NAME_PLACEHOLDER]: 'Trademark Name',
  [TM_REG]: 'Registration Number',
  [TM_LINK]: 'Link',
  [TM_LINK_PLACEHOLDER]: 'Trademark Link',
  [TM_IMAGE]: 'Image',

  // Animal Table
  [CATTLE_TAB]: 'Cattle',
  [SHEEP_TAB]: 'Sheep',
  [ANIMAL_ENTRY]: 'Add',
  [ANIMAL_EXIT]: 'Operations',

  // Animal Preview
  [SELECTED_ANIMALS_PREVIEW]: 'Preview selected animals',

  // SellAnimals
  // Steps
  [START]: 'Beggining',
  [WEIGTHS]: 'Weights',
  [PRICING]: 'Pricing',
  // Deal details
  [FIRMS_DETAILS]: 'Firms details',
  [TRANSPORT_DETAILS]: 'Transport details',
  [SALE_INFO]: 'Sale Info',
  [SELL_FIRM_NAME]: 'Selling Firm Name',
  [SELL_FIRM_EIK]: 'Selling Firm UIC',
  [SELL_FIRM_MOL]: 'Selling Firm Accountable Person',
  [SELLING_FIRM]: 'Selling Firm',
  [BUYING_FIRM]: 'Buying Firm',
  [BUY_FIRM_NAME]: 'Buying Firm Name',
  [BUY_FIRM_EIK]: 'Buying Firm UIC',
  [BUY_FIRM_MOL]: 'Buying Firm Accountable Person',
  [TRANSPORTATION_SERIAL_NUMBER]: 'Way-bill Number',
  [QUANTITY_INFO]: 'Quality Details',
  [VEHICLE_WEIGHT]: 'Vehicle Gross Weight',
  [EMPTY_VEHICLE_WEIGHT]: 'Empty Vehicle Weight',
  [ANIMAL_WEIGHT]: 'Animal weights',
  [AVERAGE_WEIGHT]: 'Weighting with a vehicle',
  [INDIVIDUAL_WEIGHT]: 'Individual weighting',
  [ANIMAL_NET_WEIGHT]: 'Animal Net Weight',
  [AVERAGE_ANIMAL_NET_WEIGHT]: 'Net Average Animal Weight',
  [FINANCIAL_INFO]: 'Financial Details',
  [PRICE_PER_KILO]: 'Price Per kg/live weight',
  [PRICE_PER_ANIMAL]: 'Price Per Animal',
  [GROUP_TOTAL_PRICE]: 'Total Group Price',
  [AVERAGE_ITEM_PRICE]: 'Average Net Price',
  [TRANSPORTATION_INFO]: 'Transportation Details',
  [TRANSPORTATION_DRIVER]: 'Driver',
  [TRANSPORTATION_DRIVER_PIN]: 'PIN',
  [TRANSPORTATION_VEHICLE_REG]: 'Transporting vehicle license plate',
  [TRANSPORTATION_DATE]: 'Transportation date',
  [ARRIVAL_DATE]: 'Arrival Date',
  [DEAL_PRICING]: 'Deal Pricing',
  [ENTER_PRICE_PER_HEAD]: 'Enter price per head',
  [ENTER_PRICE_PER_ANIMAL]: 'Enter price per kg/live weight',

  // Select Animal Operations
  [ANIMAL_OPERATIONS]: 'Animal Operations',
  [SELECT_OPERATION]: 'Select an action',
  [SELL_OPERATION]: 'Sell',
  [VETERINARY_INSPECTION]: 'Veterinary Inspection',

  // Address Book
  [ADDRESS_BOOK_CONTRAGENTS]: 'Contragents',
  [ADDRESS_BOOK_EMPLOYEES]: 'Employees',
  [ADDRESS_BOOK_FIRMS]: 'Firms',
  [ADDRESS_BOOK_TRUCKS]: 'Trucks',
  [ADDRESS_BOOK_NAME]: 'Name',
  [ADDRESS_BOOK_PHONE]: 'Phone number',
  [ADDRESS_BOOK_ROLE]: 'Role',
  [ADDRESS_BOOK_EMAIL]: 'Email',
  [ADDRESS_BOOK_STATUS]: 'TRACE Status',
  [ADDRESS_BOOK_FIRM]: 'Firm',
  [ADDRESS_BOOK_FIRM_TYPE]: 'Firm type',
  [ADD_NEW_EMPLOYEE]: 'Add new employee',
  [EDIT_EMPLOYEE]: 'Edit existing employee entry',

  // Deals
  [DEAL]: 'Deal',
  [DEAL_ACCEPT_STATUS]: 'Accepted',
  [DEAL_REJECT_STATUS]: 'Rejected',
  [DEAL_PARTIAL_ACCEPT_STATUS]: 'Partially accepted',
  [DEAL_PENDING_STATUS]: 'Pending',
  [DEAL_ACCEPT]: 'Accept all',
  [DEAL_ACCEPT_PARTIAL]: 'Accept Selected Only',
  [DEAL_REJECT]: 'Reject',
  [DEAL_STATUS]: 'Deal Status',
  [DEAL_NUMBER]: 'Deal Number',
  [DEAL_CREATOR]: 'Deal Creator',
  [DEAL_DATE]: 'Deal Date',
  // DealGrid
  [DEAL_ANIMAL_INFO]: 'Animal details',
  [DEAL_ANIMAL_WEIGHT]: 'Weight',
  [DEAL_ANIMAL_PPH]: 'Price per head',
  [DEAL_ANIMAL_PPK]: 'Price per kg.',
  [DEAL_ANIMAL_PPHK]: 'Price per 100 kg.',
  [DEAL_ANIMAL_ARRIVAL_WEIGHT]: 'Arrival weight',
  [DEAL_ANIMAL_NOTE]: 'Note',

  // Kill Data
  [KILL_DATA_OPERATION]: 'Slaughter',
  [KILL_DATA_MATADOR]: 'Matador',

  // Carcass Quality Grading
  [ANIMAL_SELECTION_PLACEHOLDER]: 'Pleace select an animal...',
  [CARCASS_QUALITY_GRADE]: 'Carcass Quality Grade',
  [GRADED_ANIMAL_IDENTIFICATOR]: 'Selected Animal Identificator',
  [INSPECTION_DATE]: 'Inspection Date',
  [INSPECTOR]: 'Inspector',
  [AGE_CLASS]: 'Age Class',
  [UNKNOWN_CLASS]: 'Unknown',
  [Z_CLASS]: 'Z (Young cattle)',
  [Z_CLASS_TITLE]: 'Carcasses of animals aged between 8 months and 12 months',
  [A_CLASS]: 'A (Young bulls)',
  [A_CLASS_TITLE]: 'Трупове на некастрирани мъжки животни на възраст между 12 и 24 месеца',
  [B_CLASS]: 'B (Bulls)',
  [B_CLASS_TITLE]: 'Carcasses of uncastrated male animals aged between 12 and 24 months',
  [C_CLASS]: 'C (Steers)',
  [C_CLASS_TITLE]: 'Carcasses of castrated male animals over 12 months of age',
  [D_CLASS]: 'D (Cows)',
  [D_CLASS_TITLE]: 'Carcasses of female animals that have calved',
  [E_CLASS]: 'E (Heifers)',
  [E_CLASS_TITLE]: 'Carcasses of other female animals over 12 months of age',
  [MUSCLE_CLASS]: 'Muscle Class',
  [MUSCLE_CLASSIFICATION]: 'Muscle Classification',
  [FAT_CLASS]: 'Fat Class',
  [FAT_COLOR]: 'Fat Color',
  [OSSIFI_SCORE]: 'Ossifi Score',
  [TEETH_FORMULA]: 'Teeth Formula',
  [MEAT_COLOR]: 'Meat Color',
  [CARCASS_YIELD]: 'Carcass Yield',
  [CARCASS_QUALITY]: 'Carcass Quality',
  [FAT_WHITE]: 'White',
  [FAT_CREAMY_WHITE]: 'Creamy White',
  [FAT_SLIGHTLY_YELLOW]: 'Slightly yellow',
  [FAT_MODERATELY_YELLOW]: 'Moderately Yellow',
  [FAT_YELLOW]: 'Yellow',
  [TEETH_NONE]: 'None',
  [CARCASS_QUALITY_PRIME]: 'Prime',
  [CARCASS_QUALITY_CHOICE]: 'Choice',
  [CARCASS_QUALITY_SELECT]: 'Select',
  [CARCASS_QUALITY_STANDARD]: 'Standard',
  [CARCASS_QUALITY_COMMERCIAL]: 'Commercial',
  [CARCASS_QUALITY_UTILITY]: 'Utility',
  [CARCASS_QUALITY_CUTTER]: 'Cutter',
  [CARCASS_QUALITY_CANNER]: 'Canner',

  // Animal Cooling
  [ANIMAL_COOLING]: 'Cooling procedure',
  [FIRST_CHECK]: 'First Check',
  [SECOND_CHECK]: 'Second Check',
  [COOLING_START]: 'Start of cooling',
  [PH_VALUE]: 'pH Value',
  [TEMPERATURE]: 'Meat temperature',

  // Logs
  // Artificial Insemination
  [COW_LID]: 'Cow L. ID',
  [FERTILIZATION_TYPE]: 'Fertilization Type',
  [SIRE_LID]: 'Sire L. ID',
  [FERTILIZATION_START]: 'Fertilization Start',
  [FERTILIZATION_END]: 'Fertilization End',
  [PREGNANCY_TEST_DATE]: 'Pregnancy test date',
  [TEST_RESULT]: 'Pregnancy test result',
  [PREGNANCY_STAGE]: 'Pregnancy Stage',
  [EXPECTED_DUE_DATE]: 'Expected Due Date',
  [PREGNANCY_RESULT_YES]: 'Yes',
  [PREGNANCY_RESULT_NO]: 'No',
  [PREGNANCY_RESULT_UNID]: 'Unidentified',
  [FERTILIZATION_NATURALLY]: 'Naturally',
  [FERTILIZATION_ARTIFICIALLY]: 'Artificially',
  // Pasture Log
  [ANIMAL_COUNT]: 'Animal Count',
  [HERD_GROUP]: 'Herd Group',
  [PASTURE_NAME]: 'Pasture Name',
  [PASTURE_DATE_OF_ENTRY]: 'Date of entry',
  [PASTURE_DATE_OF_EXIT]: 'Date of exit',
  [DAYS_SPENT_IN_PASTURE]: 'Days spent in the pasture',
  [PASTURE_ACREAGE]: 'Pasture Acreage',
  [PASTURE_GRASS_HEIGHT_IN]: 'Pasture grass height upon entry',
  [PASTURE_GRASS_HEIGHT_OUT]: 'Pasture grass height upon exit',
  // Purchased medicine log
  [PURCHASED_MEDICINE_LOG_TITLE]: 'Purchased medicines log',
  [PURCHASED_MEDICINE_LOG_ENTRY_DATE]: 'Medicine purchase date',
  [PURCHASED_MEDICINE_NAME]: 'Medicine name',
  [MEDICINE_CATEGORY_NAME]: 'Category',
  [PURCHASED_MEDICINE_AMOUNT]: 'Purchased amount',
  [PURCHASED_MEDICINE_DELIVERED_BY]: 'Name & address of courier',
  [MEDICINE_WITHDRAW_TITLE]: 'Medicine withdrawal period',
  [PURCHASED_MEDICINE_WITHDRAW_PERIOD_MEAT]: 'Meat',
  [PURCHASED_MEDICINE_WITHDRAW_PERIOD_MILK]: 'Milk',
  [PURCHASED_MEDICINE_BATCH_NUMBER]: 'Medicine batch number',
  [PURCHASED_MEDICINE_EXPIRY_DATE]: 'Medicine expiry date',
  [PURCHASED_MEDICINE_NOTE]: 'Notes',
  // Fodder Log
  [FODDER_DATE]: 'Date',
  [PURCHASED_FODDER_TITLE]: 'Purchased fodder',
  [PRODUCED_FODDER_TITLE]: 'Produced fodder',
  [FODDER_LOG_TITLE]: 'Fodder log',
  [FODDER_NAME]: 'Name',
  [FODDER_CATEGORY]: 'Category',
  [FODDER_BATCH_NUMBER]: 'Batch number',
  [FODDER_AMOUNT_PRODUCED]: 'Produced amount',
  [FODDER_AMOUNT_BOUGHT]: 'Purchased amount',
  [FODDER_COURIER]: 'Courier',
  [FODDER_NOTE]: 'Note',
  [FODDER_SELF_PRODUCED]: 'Self produced',
  [FODDER_TYPE]: 'Type',
  // FodderCategories
  [UNKNOWN_CATEGORY]: 'Unknown',
  [WETFEED_CATEGORY]: 'Wet feed',
  [WATER_CATEGORY]: 'WaterВода',
  [OTHER_CATEGORY]: 'Other',
  [LONGFIBER_CATEGORY]: 'Long fibers (Аlfalfa, Hay)',
  [CONCENTRATE_CATEGORY]: 'Concentrates (Premix, Grain)',
  [ROOTS_CATEGORY]: 'Roots',
  [SOFTFIBER_CATEGORY]: 'Softfibers (Haylage, Silage)',
  [VITAMINS_CATEGORY]: 'Vitamins & Minerals',
  [LIQUIDS_CATEGORY]: 'Liquids (Мolasses)',

  // Medicine categories
  [UNKNOWN_CATEGORY]: 'Unknown',
  [ANTIBIOTIC_CATEGORY]: 'Antibiotics',
  [VACCINE_CATEGORY]: 'Vaccines',
  [VITAMIN_CATEGORY]: 'Vitamins & Minerals',
  [OTHER_CATEGORY]: 'Other',
  [CALCIUM_CATEGORY]: 'Calcium',
  [PROBIOTIC_CATEGORY]: 'Probiotics',
  [ANITIINFLAMMATORY_CATEGORY]: 'Antiinflammatory',
  [ANTIPARASITIC_CATEGORY]: 'Antiparasitic',
  [OINTMENT_CATEGORY]: 'Ointments',
  [HORMONAL_CATEGORY]: 'Hormonal',
  [SUPPLIMENT_CATEGORY]: 'Suppliments',

  // Mortality Log
  [ANIMAL_MORTALITY]: 'Dead',
  [ANIMAL_HEALTH_PROBLEM]: 'Health problem',
  [ANIMAL_TERAPY]: 'Therapy',
  // Animal Information
  [MALE]: 'Male',
  [MALE_ABBR]: 'M',
  [FEMALE]: 'Female',
  [FEMALE_ABBR]: 'F',
  [CATTLE_TYPE]: 'Cattle',
  [SHEEP_TYPE]: 'Sheep',
  [ANIMAL_SEX]: 'Sex',
  [ANIMAL_BREED]: 'Breed',
  [ANIMAL_AGE]: 'Age ( in months)',
  [ANIMAL_LID]: 'Ear Tag',

  // Ambulatory Log
  [AMBULATORY_NUMBER]: 'Ambulatory №',
  [AMBULATORY_DATE]: 'Date',
  [AMBULATORY_ANIM_OBJ]: 'Name and address of Animal Farm',
  [ANIMAL_TYPE]: 'Animal Type',
  [AMBULATORY_CLINICAL_DATA]: 'Clinical Data',
  [AMBULATORY_DIAGNOSTIC_TEST]: 'Diagnostics Test',
  [AMBULATORY_DIAGNOSIS]: 'Diagnosis',
  [AMBULATORY_MEDICINES]: 'Prescribed medicines',
  [AMBULATORY_DOSE]: 'Dose',
  [AMBULATORY_WITHDRAWAL_PERIOD]: 'Medicine withdrawal period',
  [AMBULATORY_DIAGNOSIS_OUT]: 'Diagnosis Out',
  [AMBULATORY_NOTE]: 'Note',

  // Chemical Treatement Log
  [CHEMICAL_TREATEMENT_PEST]: 'Pest',
  [CHEMICAL_TREATEMENT_DOSE]: 'Dose per decare',
  [CHEMICAL_TREATEMENT_TREATED_AREA]: 'Treated area',
  [CHEMICAL_TREATEMENT_TECHNIQUE]: 'Application technique',
  [CHEMICAL_TREATEMENT_CHEMICAL]: 'Chemical',
  [CHEMICAL_TREATEMENT_ACTIVE_CHEMICAL]: 'Active chemical',
  [CHEMICAL_TREATEMENT_CONCENTRATE]: 'Conc. %',
  // Pest Control Log
  [PEST]: 'Pest',
  [PEST_DAMAGE_LEVEL]: 'Damage level',
  [DAMAGED_CULTURES]: 'Damaged cultures',
  [USED_CHEMICALS]: 'Used Chemicals',
  [CHEMICAL_TYPE]: 'Chemical Type',
  [METEOROLOGY]: 'Meteorology',
  [APPLICATION_METHOD]: 'Application method',
  [DAMAGE_LEVEL_LOW]: 'Low',
  [DAMAGE_LEVEL_MEDIUM]: 'Medium',
  [DAMAGE_LEVEL_HIGH]: 'Hight',
  [DAMAGE_LEVEL_TOTAL]: 'Total',

  // Used Pestecide Log
  [USED_PESTICIDE_OPERATOR]: 'Operator',
  [USED_PESTICIDE_NAME]: 'Pesticide Name',
  [USED_PESTICIDE_ACTIVE_CHEMICAL]: 'Active Chemical',
  [USED_PESTICIDE_NUMBER]: 'Batch №',
  [USED_PESTICIDE_TREATED_CULTURE]: 'Treated Culture',
  [USED_PESTICIDE_HECTARE_NORM]: 'Norm per ha',
  [USED_PESTICIDE_TREATED_AREA]: 'Treated area in ha.',
  [USED_PESTICIDE_TOTAL]: 'Total amount',
  [USED_PESTICIDE_ENTRY_BAN_PERIOD]: 'Entry period ban days',
  [USED_PESTICIDE_MOWING_BAN_PERIOD]: 'Mowing period ban days',

  // Stepper
  [FORWARD]: 'Напред',
  [BACKWORD]: 'Напред',
  [STEPFROMTOTAL]: 'Стъпка от ',

  // UNECE Settings
  [Keys.uniceKeys.FEEDING_SYSTEM]: 'Feeding system',
  [Keys.uniceKeys.PRODUCING_SYSTEM]: 'Producting system',
  [Keys.uniceKeys.UNECE_TITLE_FARMER]: 'UNECE Settings - Farm',
  [Keys.uniceKeys.UNECE_MENU_TITLE]: 'UNECE Settings',
  [Keys.uniceKeys.UNECE_TITLE_PROCESSOR]: 'UNECE Settings - Processor',
  [Keys.uniceKeys.BOVINE_QUALITY_SYSTEM]: '11 - Bovine quality system',
  [Keys.uniceKeys.CONFORMITY_ASSESSMENT]: '14 - Conformity assessment',
  [Keys.uniceKeys.FAT_THICKNESS]: '10 - Fat thickness',
  [Keys.uniceKeys.PACKING_CODES]: '13 - Packing codes',
  [Keys.uniceKeys.POST_SLAUGHTER_SYSTEM]: '9 - Post slaughter system',
  [Keys.uniceKeys.REFRIGERATION]: '4 - Refrigeration',
  [Keys.uniceKeys.SLAUGHTER_SYSTEM]: '8 - Slaughter system',
  [Keys.uniceKeys.WEIGHT_RANGING]: '12 - Weight ranging',

  // QR
  [SAVE_IMAGE]: 'Save image',
  [SAVE_SVG]: 'Save SVG',

  // Unregister animals
  [ANIMALS]: 'Animals',
  [REASON]: 'Reason',
  [UNREGISTER]: 'Unregister',
  [UNREGISTER_CHRONOLOGY_MSG]: 'Unregister events chronology can be seen on the dashboard',
  [UNREGISTER_NEW]: 'New unregister',
  [UNREGISTER_REASON]: 'Unregister reason',
  [UNREGISTER_SUCCESS_MSG]: 'The group of animals unregistered successfully',
  [UNREGISTER_TITLE]: 'Unregister',

  // Butchery
  [Keys.butcheryKeys.BUTCHERY_PROCESS_TITLE]: 'Butchery',

  // Public data
  [Keys.publicKeys.BATCH_HISTORY]: 'Incoming batches history',
  [Keys.publicKeys.BATCH_HISTORY_SUBTEXT]: 'Loading meat from blbbg.org certified businesses and farms and traded on the system',
  [Keys.publicKeys.BULLETIN]: 'BULLETIN',
  [Keys.publicKeys.ENTERPRISE_MAIN_SUBTITLE]: 'Certified enterprise profile',
  [Keys.publicKeys.FARM_MAIN_SUBTITLE]: 'Certified farm profile',
  [Keys.publicKeys.HERD_INVENTORY]: 'Herd inventory',
  [Keys.publicKeys.LOCATION]: 'Location',
  [Keys.publicKeys.OTHER_HOLDING_OBJECTS]: 'Other objects in holding',
  [Keys.publicKeys.PAGE_MAIN_TITLE]: 'BEEF AND LAMB BOARD',
  [Keys.publicKeys.PREVIEW]: 'Preview',
  [Keys.publicKeys.QUАNTITIES]: 'Quantities',
  [Keys.publicKeys.QUАNTITIES_SUBTEXT]: 'Carcass purchased under the TRACE system',
  [Keys.publicKeys.QUАNTITIES_SUBTEXT_SMALL]: 'Wholesale cuts purchased in the current year in kg.',
  [Keys.publicKeys.SHOP_MAIN_SUBTITLE]: 'Certified merchant profile',
  [Keys.publicKeys.SOCIAL_MEDIA_SHARE]: 'Share on social networks',
  [Keys.publicKeys.SUBSCRIBE]: 'Subscribe',
  [Keys.publicKeys.SUBSCRIBE_FOR_MONTHLY]: 'SUBSCRIBE FOR MONTHLY',
  [Keys.publicKeys.SUBSCRIBE_NOW]: 'Subscribe now!',
  [Keys.publicKeys.SUBSCRIBE_TEXT]: 'Subscribe to our monthly newsletter with publications and news from the world of farming and meat processing.',
  [Keys.publicKeys.TAGS]: 'Tags',

  // Warehouse
  [Keys.warehouseKeys.WAREHOUSE_TITLE]: 'Warehouse',
  [Keys.warehouseKeys.VIRTUAL_WAREHOUSE_TITLE]: 'Virtual warehouse',
  [Keys.ACTIONS]: 'Actions',

  // Trader
  [Keys.SHOPPING_CENTER]: 'Shopping center',
};