import { Button } from '@progress/kendo-react-buttons';
import { Dialog } from '@progress/kendo-react-dialogs';
import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { useLocalization } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';

import { DECLINE, UPDATE } from '../../languages/languages';
import { IBatchAnimal, IPhT } from '../../slices/data/ButcheryProcessSliceData';
import { FormCheckbox, FormDateTimePicker, FormNumericTextBox } from '../FormComponents/FormComponents';

type AnimaWeightEditModalProps = {
  item: IBatchAnimal;
  batchNumber: string;
  cancelEdit: () => void;
  onSubmit: (animalId: number, formData: IPhT, applyForAll: boolean) => void;
  isSecondMeasure?: boolean;
};
const PhTModal = ({
  item,
  batchNumber,
  cancelEdit,
  onSubmit,
  isSecondMeasure,
}: AnimaWeightEditModalProps) => {
  const localizationService = useLocalization();
  const title = `Редакция на "${item.lID || (item as any).animal.lID}|${batchNumber}|${
    item.carcaseInternalNumber
  }"`; // FIXME
  const [data, setData] = useState<IPhT>();

  const onFormSubmit = (formData: IPhT) => {
    const { applyForAll } = formData as any;
    delete (formData as any).applyForAll;
    onSubmit(item.animalId, formData, applyForAll);
  };

  useEffect(() => {
    let d = {
      date: new Date(),
      measureNumber: 1,
      ph: 0,
      temp: 0,
    } as IPhT;

    if (!isSecondMeasure && item.firstPhT) {
      d = { ...item.firstPhT };
    }

    if (isSecondMeasure && item.secondPhT) {
      d = { ...item.secondPhT };
    }

    setData(d);
  }, []);

  useEffect(() => {
    let d = {
      date: new Date(),
      measureNumber: 1,
      ph: 0,
      temp: 0,
    } as IPhT;

    if (!isSecondMeasure && item.firstPhT) {
      d = { ...item.firstPhT };
    }

    if (isSecondMeasure && item.secondPhT) {
      d = { ...item.secondPhT };
    }

    setData(d);
  }, [item]);

  return (
    <Dialog title={title} onClose={cancelEdit}>
      {data && (
        <Form
          onSubmit={onFormSubmit}
          initialValues={data}
          render={(formRenderProps) => (
            <FormElement id='registrationForm'>
              <div className='row'>
                <div className='col-12 pl-l pr-l'>
                  <Field
                    id='date'
                    name='date'
                    component={FormDateTimePicker}
                    label={isSecondMeasure ? 'Час на второ измерване' : 'Час на първо измерване'}
                    value={data.date}
                    required={true}
                  />
                  <Field
                    id='applyForAll'
                    name='applyForAll'
                    component={FormCheckbox}
                    label='Приложи за всички'
                  />
                </div>
                <div className='col-12 pl-l pr-l'>
                  <Field
                    id='temp'
                    name='temp'
                    component={FormNumericTextBox}
                    label={isSecondMeasure ? 'Второ измерване t(℃)' : 'Първо измерване t(℃)'}
                    value={data.temp}
                  />
                </div>
                <div className='col-12 pl-l pr-l'>
                  <Field
                    id='ph'
                    name='ph'
                    component={FormNumericTextBox}
                    label={isSecondMeasure ? 'Второ измерване на pH' : 'Първо измерване на pH'}
                    value={data.ph}
                  />
                </div>
              </div>
              <fieldset className='k-form-buttons'>
                <Button
                  name='submit'
                  type='submit'
                  title={localizationService.toLanguageString(UPDATE, '')}
                  primary
                  disabled={!formRenderProps.allowSubmit}
                  icon='save'
                >
                  {localizationService.toLanguageString(UPDATE, '')}
                </Button>
                <Button
                  name='FirmCancel'
                  type='button'
                  title={localizationService.toLanguageString(DECLINE, '')}
                  primary
                  look='outline'
                  onClick={cancelEdit}
                  icon='cancel'
                >
                  {localizationService.toLanguageString(DECLINE, '')}
                </Button>
              </fieldset>
            </FormElement>
          )}
        />
      )}
    </Dialog>
  );
};

PhTModal.defaultProps = {
  isSecondMeasure: false,
};

export default PhTModal;
