import { GridCellProps, GridRowProps } from '@progress/kendo-react-grid';
import * as React from 'react';
import { AnimalType } from '../../slices/data/AnimalSliceData';

interface CellRenderProps {
  originalProps: GridCellProps,
  defaultValue: any;
  td:React.ReactElement<HTMLTableCellElement>,
  enterEdit: (dataItem: AnimalType, field: string | undefined) => void,
  editField: string | undefined
}

interface RowRenderProps {
  originalProps: GridRowProps,
  tr: React.ReactElement<HTMLTableRowElement>,
  exitEdit: () => void,
  editField: string | undefined
}

export const CellRender = (props: CellRenderProps) => {
  const { dataItem } = props.originalProps;
  const cellField = props.originalProps.field;
  const inEditField = dataItem[props.editField || 'animalWeight'];
  const additionalProps = cellField && cellField === inEditField
    ? {
      ref: (td) => {
        const input = td && td.querySelector('input');
        const { activeElement } = document;

        if (!input
                    || !activeElement
                    || input === activeElement
                    || !activeElement.contains(input)) {
          return;
        }

        if (input.type === 'checkbox') {
          input.focus();
        } else {
          input.select();
        }
      }
    } : {
      onClick: () => { props.enterEdit(dataItem, cellField); }
    };

  const clonedProps: any = { ...props.originalProps, ...additionalProps };
  return React.cloneElement(props.td, clonedProps, props.td.props.children);
};

export const RowRender = (props: RowRenderProps) => {
  const trProps = {
    ...props.tr.props,
    onBlur: () => {
      props.exitEdit();
    }
  };
  return React.cloneElement(props.tr, { ...trProps }, props.tr.props.children);
};
