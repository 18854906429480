import React, { useEffect } from 'react';
import { Route, RouteProps } from 'react-router-dom';
import { useAuth0 } from '../../utils/custom-hooks';

interface PrivateRouteProps extends RouteProps {
  component: React.ComponentType;
  layout: React.ComponentType
}

const PrivateRoute = ({
  component: Component,
  path,
  layout = (() =>
    (<></>)),
  ...rest
}: PrivateRouteProps) => {
  const { loading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    const fn = async () => {
      if (!isAuthenticated) {
        if (loginWithRedirect) {
          await loginWithRedirect({
            appState: { targetUrl: path }
          });
        }
      }
    };
    fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  const render = (props) => {
    const finalProps = { ...props, ...rest };
    return isAuthenticated === true
      ? React.createElement(layout, finalProps, React.createElement(Component, finalProps))
      : null;
  };

  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;
