/* eslint-disable import/no-cycle */
import { createSlice } from '@reduxjs/toolkit';

import { RootState } from '../app/StoreHooks';
import { ColumnType } from './data/AnimalColumnsSliceData';

type AnimalColumnsStateType = {
  cattleColumns: ColumnType[];
  sheepColumns: ColumnType[];
  originalColumnsSelection: ColumnType[];
};

const initialState: AnimalColumnsStateType = {
  cattleColumns: [
    {
      title: 'ВЕТИС Марка №',
      field: 'lID',
      show: true,
      filter: 'text'
    },
    {
      title: 'Дата на раждане',
      field: 'dateOfBirth',
      show: true,
      filter: 'date',
      format: '{0:dd.MM.yyy}'
    },
    {
      title: 'Пол',
      field: 'sex',
      show: true,
      filter: 'text'
    },
    {
      title: 'Порода',
      field: 'cattleBreed',
      show: true,
      filter: 'text'
    },
    {
      title: 'Цвят',
      field: 'cattleColor',
      show: false,
      filter: 'text'
    },
    {
      title: 'Ж.О №',
      field: 'animalObjects.animalObjectNumber',
      show: true,
      filter: 'text',
      cell: null
    },
    // TODO Стара колона, показва само ID на Ж.О. Трябва да се премахне
    // {
    //   title: 'Ж.О № (не се ползва)',
    //   field: 'cattleAnimalObjectId',
    //   show: false,
    //   filter: 'text',
    //   cell: null
    // },
    {
      title: '№ Паспорт / Син Картон',
      field: 'passportNumber',
      show: false,
      filter: 'text'
    },
    {
      title: 'Предназначение',
      field: 'cattlePurpose',
      show: false,
      filter: 'text'
    },
    {
      title: 'ВЕТИС Марка на Майката',
      field: 'cattleMVetisNumber',
      show: false,
      filter: 'text'
    },
    {
      title: 'ВЕТИС Марка на Бащата',
      field: 'cattleFVetisNumber',
      show: false,
      filter: 'text'
    },
    {
      title: 'ЕГН / ЕИК на собственика',
      field: 'cattleOwnerEIK',
      show: false,
      filter: 'numeric'
    },
    {
      title: 'Дата на пристигане в обекта',
      field: 'cattleArrivalDate',
      show: false,
      filter: 'date'
    },
    {
      title: 'Дата на напускане на обекта',
      field: 'cattleLeaveDate',
      show: false,
      filter: 'date'
    },
    {
      title: 'Дата на смърт',
      field: 'cattleDeathDate',
      show: false,
      filter: 'date'
    },
    {
      title: '№ на свидетелство за придвижване',
      field: 'cattleTransportCertificateNumber',
      show: false,
      filter: 'text'
    },
    {
      title: '№ на потвърдителна бележка (екарисаж)',
      field: 'cattleAuthroziationNumber',
      show: false,
      filter: 'text'
    },
    {
      title: 'ЕГН на нов собственик',
      field: 'cattleNewOwnerId',
      show: false,
      filter: 'text'
    },
    {
      title: 'ЕГН на Превозвач',
      field: 'cattleTransportDriverId',
      show: false,
      filter: 'text'
    },
    {
      title: 'Регистрационен № на превозното средство',
      field: 'cattleTransportVehicleId',
      show: false,
      filter: 'text'
    }
  ],
  sheepColumns: [
    {
      title: 'ВЕТИС Марка №',
      field: 'lID',
      show: true,
      filter: 'text'
    },
    {
      title: '№ Паспорт / Син Картон',
      field: 'passportNumber',
      show: true,
      filter: 'text'
    },
    {
      title: 'Ж.О №',
      field: 'sheepAnimalObjectId',
      show: true,
      filter: 'text',
      cell: null
    },
    {
      title: 'Дата на раждане',
      field: 'dateOfBirth',
      show: true,
      filter: 'date',
      format: '{0:D}'
    },
    {
      title: 'Пол',
      field: 'sex',
      show: true,
      filter: 'text'
    },
    {
      title: 'Порода',
      field: 'sheepBreed',
      show: false,
      filter: 'text'
    },
    {
      title: 'Цвят',
      field: 'sheepColor',
      show: false,
      filter: 'text'
    },
    {
      title: 'Предназначение',
      field: 'sheepPurpose',
      show: false,
      filter: 'text'
    },
    {
      title: 'ВЕТИС Марка на Майката',
      field: 'sheepMVetisNumber',
      show: false,
      filter: 'text'
    },
    {
      title: 'ВЕТИС Марка на Бащата',
      field: 'sheepFVetisNumber',
      show: false,
      filter: 'text'
    },
    {
      title: 'ЕГН / ЕИК на собственика',
      field: 'sheepOwnerEIK',
      show: false,
      filter: 'numeric'
    },
    {
      title: 'Дата на пристигане в обекта',
      field: 'sheepArrivalDate',
      show: false,
      filter: 'date'
    },
    {
      title: 'Дата на напускане на обекта',
      field: 'sheepLeaveDate',
      show: false,
      filter: 'date'
    },
    {
      title: 'Дата на смърт',
      field: 'sheepDeathDate',
      show: false,
      filter: 'date'
    },
    {
      title: '№ на свидетелство за придвижване',
      field: 'sheepTransportCertificateNumber',
      show: false,
      filter: 'text'
    },
    {
      title: '№ на потвърдителна бележка (екарисаж)',
      field: 'sheepAuthroziationNumber',
      show: false,
      filter: 'text'
    },
    {
      title: 'ЕГН на нов собственик',
      field: 'sheepNewOwnerId',
      show: false,
      filter: 'text'
    },
    {
      title: 'ЕГН на Превозвач',
      field: 'sheepTransportDriverId',
      show: false,
      filter: 'text'
    },
    {
      title: 'Регистрационен № на превозното средство',
      field: 'sheepTransportVehicleId',
      show: false,
      filter: 'text'
    }
  ],
  originalColumnsSelection: [],
};

const AnimalColumnsSlice = createSlice({
  name: 'animalColumnsSlice',
  initialState,
  reducers: {
    setCattleColumns: (state, { payload }) => {
      state.cattleColumns = payload;
    },
    setSheepColumns: (state, { payload }) => {
      state.sheepColumns = payload;
    },
    setOriginalColumns: (state, { payload }) => {
      state.originalColumnsSelection = payload;
    }
  }
});

// Actions
export const { setCattleColumns, setSheepColumns, setOriginalColumns } = AnimalColumnsSlice.actions;

// Selectors
export const selectCattleColumns = (state:RootState) =>
  state.animalColumns.cattleColumns;
export const selectSheepColumns = (state:RootState) =>
  state.animalColumns.sheepColumns;
export const selectOriginalColumns = (state:RootState) =>
  state.animalColumns.originalColumnsSelection;

// Reducer
export default AnimalColumnsSlice.reducer;
