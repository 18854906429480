import React, { useEffect, useState } from 'react';
import { ComboBox, ComboBoxChangeEvent } from '@progress/kendo-react-dropdowns';
import { useLocalization } from '@progress/kendo-react-intl';
import { Loader } from '@progress/kendo-react-indicators';
import {
  FIRST_CHECK,
  GRADED_ANIMAL_IDENTIFICATOR,
  SECOND_CHECK,
  ANIMAL_COOLING_TITLE,
  GENERAL_INFO
} from '../../languages/languages';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import {
  animalFirstCooling,
  animalSecondCooling,
  getAnimalCooling,
  getGradedCarcasses,
  selectGradedAnimals,
  selectCooledAnimal,
  selectCoolingLoading
} from '../../slices/AnimalSlice';
import AnimalCoolingForm from '../../components/AnimalCoolingForm/AnimalCoolingForm';
import {
  FirstCoolingType,
  SecondCoolingType
} from '../../slices/data/AnimalSliceData';

const AnimalCoolingContainer = () => {
  const [animalToCool, setAnimalToCool] = useState<number>(null);
  const dispatch = useAppDispatch();
  const animals = useAppSelector(selectGradedAnimals);
  const cooledAnimal = useAppSelector(selectCooledAnimal);
  const coolingLoading = useAppSelector(selectCoolingLoading);
  const localizationService = useLocalization();

  useEffect(() => {
    const awaitData = () => {
      dispatch(getGradedCarcasses());
    };
    awaitData();
  }, []);

  const changeAnimal = ({ value }: ComboBoxChangeEvent) => {
    dispatch(getAnimalCooling(value?.animalId));
    setAnimalToCool(value?.animalId);
  };

  const submitCoolingCheck = (
    formData: FirstCoolingType | SecondCoolingType
  ) => {
    if (!cooledAnimal) {
      const data = {
        animalId: animalToCool,
        ...formData
      } as FirstCoolingType;
      dispatch(
        animalFirstCooling(data)
      );
    } else {
      const data = {
        coolingId: cooledAnimal?.coolingId,
        ...formData
      } as SecondCoolingType;
      dispatch(
        animalSecondCooling(data)
      );
    }
  };

  return (
    <>
      <h1 className='page-title'>{localizationService.toLanguageString(ANIMAL_COOLING_TITLE, '')}</h1>
      <div className='content-wrapper section' id='animalCoolingContainer'>
        <div className='general-info mb-l'>
          <h4>
            {localizationService.toLanguageString(GENERAL_INFO, '')}
            :
          </h4>
          <ComboBox
            label={localizationService.toLanguageString(
              GRADED_ANIMAL_IDENTIFICATOR,
              ''
            )}
            data={animals}
            onChange={changeAnimal}
            textField='lID'
          />
        </div>
        {coolingLoading ? (
          <Loader size='large' type='pulsing' className='k-centered' />
        ) : (
          <>
            <div className='firstCheck mb-m'>
              <h4>{localizationService.toLanguageString(FIRST_CHECK, '')}</h4>
              <AnimalCoolingForm
                type='first'
                submitForm={submitCoolingCheck}
                values={cooledAnimal}
              />
            </div>
            <div className='secondCheck'>
              <h4>{localizationService.toLanguageString(SECOND_CHECK, '')}</h4>
              <AnimalCoolingForm
                type='second'
                submitForm={submitCoolingCheck}
                values={cooledAnimal}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AnimalCoolingContainer;
