import { graphql } from 'msw';
import AUTH_CONFIG from '../../auth-variables';

const blbTrace = graphql.link(AUTH_CONFIG.apiUrl);

let holdingProfile = {
  cattle: true,
  holdingId: 1,
  holdingName: 'Hldignqwedwdqnlwe',
  isFarm: true,
  isFeedlot: false,
  isAbattoir: false,
  isButchery: false,
  isShop: false,
  holdingCode: 'MRT'
};

const availableHoldings = [
  { id: 1, name: 'merata' },
  { id: 2, name: 'tesst2' }
];

export default [
  // Get all types
  blbTrace.query('getAllTypesQuery', (req, res, ctx) =>
    res(
      ctx.data({
        getAllHoldingTypes: holdingProfile
      })
    )),
  blbTrace.mutation('saveShopAvailabilityMutation', (req, res, ctx) => {
    const { value } = req.variables;
    holdingProfile = value;
    if (!holdingProfile.holdingId) {
      holdingProfile.holdingId = Math.floor(Math.random() * 10);
    }
    return res(
      ctx.data({
        setShop: holdingProfile
      })
    );
  }),

  // Get available holdings
  blbTrace.query('getAvailableHoldingsQuery', (req, res, ctx) =>
    res(
      ctx.data({
        getAvailable: availableHoldings
      })
    )),
  blbTrace.query('getHoldingQuery', (req, res, ctx) => {
    const { id } = req.variables;
    const currentHolding = availableHoldings.filter((holding) =>
      holding.id === id);

    res(
      ctx.data({
        getCurrentHolding: currentHolding
      })
    );
  })
];
