import React from 'react';
import SellAnimalsForm from '../../features/SellAnimalsForm/SellAnimalsForm';
import SellAnimalsGridContainer from '../SellAnimalsGridContainer/SellAnimalsGridContainer';

type SellAnimalsProps = {
  setVisibility: React.Dispatch<boolean>;
};
const SellAnimals = ({ setVisibility }: SellAnimalsProps) =>
  (
    <div>
      <SellAnimalsForm setVisibility={setVisibility} />
      <SellAnimalsGridContainer />
    </div>
  );

export default SellAnimals;
