import React, { useEffect } from 'react';
import {
  GridCellProps,
  GridDataStateChangeEvent
} from '@progress/kendo-react-grid';
import { State } from '@progress/kendo-data-query';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import { EditCommandCell } from '../../components/CustomGridCells/CustomGridCells';
import {
  editTrademark,
  getAllTrademarks,
  selectTrademarks
} from '../../slices/TrademarkSlice';
import { TrademarkType } from '../../slices/data/TrademarkSliceData';
import TrademarkGrid from '../../components/TrademarkGrid/TrademarkGrid';
import TrademarkEditForm from '../../components/TrademarkEditForm/TrademarkEditForm';

const TrademarkGridContainer = () => {
  const dispatch = useAppDispatch();
  const trademarks = useAppSelector(selectTrademarks);

  const [dataState, setDataState] = React.useState<State>({
    sort: [{ field: 'code', dir: 'asc' }],
    take: 10,
    skip: 0
  });

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataState(event.dataState);
  };

  useEffect(() => {
    const awaitData = async () => {
      await dispatch(getAllTrademarks());
    };
    awaitData();
  }, []);

  // Edit form state and functions
  const [openForm, setOpenForm] = React.useState(false);
  const [editItem, setEditItem] = React.useState<TrademarkType>(null);
  const enterEdit = (item: TrademarkType) => {
    setOpenForm(true);
    setEditItem(item);
  };

  const MyEditCommandCell = (props: GridCellProps) =>
    (
      <EditCommandCell {...props} enterEdit={enterEdit} />
    );

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const handleEdit = (formData: TrademarkType) => {
    dispatch(editTrademark(formData));
    setOpenForm(false);
  };

  return (
    <div>
      <TrademarkGrid
        cell={MyEditCommandCell}
        data={trademarks}
        dataState={dataState}
        dataStateChange={dataStateChange}
      />
      {openForm && (
        <TrademarkEditForm
          cancelEdit={handleCancelEdit}
          onSubmit={handleEdit}
          item={editItem}
        />
      )}
    </div>
  );
};

export default TrademarkGridContainer;
