import React, { useState } from 'react';
import {
  Grid,
  GridCellProps,
  GridColumn as Column,
  GridDataStateChangeEvent,
  GridToolbar
} from '@progress/kendo-react-grid';
import { State, process } from '@progress/kendo-data-query';
import { useLocalization } from '@progress/kendo-react-intl';
import { Button } from '@progress/kendo-react-buttons';
import {
  ADD_NEW,
  EIK,
  FIRM_ADDRESS,
  FIRM_CITY,
  FIRM_COUNTRY,
  FIRM_MOL,
  FIRM_NAME
} from '../../languages/languages';
import { FirmsAddressBookType } from '../../slices/data/FirmsAddressBookSliceData';
import ColumnMenu from '../ColumnMenu/ColumnMenu';
import GridSearchBar from '../../features/GridSearchBar/GridSearchBar';
import { useAppSelector } from '../../app/StoreHooks';
import { selectFirmsAddressBookLoader } from '../../slices/FirmsAddressBookSlice';
import GridLoaderPanel from '../GridLoaderPanel/GridLoaderPanel';
import GrantAccess from '../../features/GrantAccess/GrantAccess';
import { EditCommandCell } from '../CustomGridCells/CustomGridCells';

const REQUIRED_ROLES: string[] = ['Admin', 'Holding Owner', 'Farm Manager'];

type AddressBookFirmGridProps = {
  data: FirmsAddressBookType[];
  enterEdit: (item: FirmsAddressBookType) => void
  addFirm: () => void;
};

const AddressBookFirmGrid = ({
  data,
  addFirm,
  enterEdit
}: AddressBookFirmGridProps) => {
  const localizationService = useLocalization();
  const loadingStatus = useAppSelector(selectFirmsAddressBookLoader);

  // Grid state and functions
  const [dataState, setDataState] = useState<State>({
    take: 10,
    skip: 0,
  });

  const dataStateChange = (event: GridDataStateChangeEvent) => {
    setDataState(event.dataState);
  };

  const MyEditCommandCell = (props: GridCellProps) =>
    (
      <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
        <EditCommandCell {...props} enterEdit={enterEdit} />
      </GrantAccess>
    );

  return (
    <>
      <Grid
        {...dataState}
        onDataStateChange={dataStateChange}
        sortable
        pageable
        data={process(data, dataState)}
      >
        <GridToolbar>
          <GridSearchBar
            dataState={dataState}
            setDataState={setDataState}
            filterBy='firmABName'
          />
          <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
            <Button
              name='Добави нов запис'
              icon='plus'
              primary
              onClick={addFirm}
              title='Add new'
            >
              {localizationService.toLanguageString(ADD_NEW, '')}
            </Button>
          </GrantAccess>
        </GridToolbar>
        <Column
          title={localizationService.toLanguageString(EIK, '')}
          field='firmABEIK'
          filter='text'
          columnMenu={ColumnMenu}
        />
        <Column
          title={localizationService.toLanguageString(FIRM_NAME, '')}
          field='firmABName'
          filter='text'
          columnMenu={ColumnMenu}
        />
        <Column
          title={localizationService.toLanguageString(FIRM_COUNTRY, '')}
          field='firmABCountry'
          filter='text'
          columnMenu={ColumnMenu}
        />
        <Column
          title={localizationService.toLanguageString(FIRM_CITY, '')}
          field='firmABCity'
          filter='text'
          columnMenu={ColumnMenu}
        />
        <Column
          title={localizationService.toLanguageString(FIRM_ADDRESS, '')}
          field='firmABAddress'
          filter='text'
          columnMenu={ColumnMenu}
        />
        <Column
          title={localizationService.toLanguageString(FIRM_MOL, '')}
          field='firmABMOL'
          filter='text'
          columnMenu={ColumnMenu}
        />
        <Column cell={MyEditCommandCell} width={120} />
      </Grid>
      {loadingStatus && <GridLoaderPanel />}
    </>
  );
};

export default AddressBookFirmGrid;
