import { graphql } from 'msw';
import AUTH_CONFIG from '../../auth-variables';

const blbTrace = graphql.link(AUTH_CONFIG.apiUrl);

const animalObjects = [
  {
    animalObjectId: 1,
    animalObjectName: 'Тестови обект 1',
    animalObjectNumber: '92874319',
    animalObjectNumberTraces: '3947243838941',
    animalObjectCity: 'Plovdiv',
    animalObjectAddress: 'Adress street 3',
    animalObjectCapacity: 250,
    animalObjectBuildings: 5,
    animalObjectBuildingsAcreage: 35.65,
    animalObjectPastures: 9,
    animalObjectPasturesAcreage: 250.44,
    isActive: true,
    firmId: 1
  }
];

export default [
  // Get All Animal Objects from the DB
  blbTrace.query('getAllAnimalObjectsQuery', (req, res, ctx) =>
    res(
      ctx.data({
        getAnimalObjects: {
          animalObjects
        }
      })
    )),
  blbTrace.mutation('editAnimalObjectMutation', (req, res, ctx) => {
    const { id, input } = req.variables;
    const editAnObj = animalObjects.findIndex((animalObject) =>
      animalObject.animalObjectId === id);
    animalObjects[editAnObj] = input;
    return res(
      ctx.delay(3000),
      ctx.data({
        editInformation: {
          input
        }
      })
    );
  }),
  blbTrace.mutation('saveAnimalObjectMutation', (req, res, ctx) => {
    const { input } = req.variables;
    // console.log(input);
    input.isActive = true;
    input.animalObjectId = animalObjects.length + 1;
    animalObjects.push(input);
    return res(
      ctx.delay(5000),
      ctx.data(
        {
          saveAnimalObject: {
            input
          }
        }
      )
    );
  })
];
