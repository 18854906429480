import { gql } from '@apollo/client';

// Types
export type NotificationType = {
  holdingEventClass?: string;
  holdingEventsId?: number;
  saleEventID?: number;
  eventDate?: Date;
  seen?: boolean;
};

export type NotificationQueryResponseType = {
  items: NotificationType[];
};

// Queries & Mutations
export const GET_NOTIFICATIONS = gql`
  {
    holdingEvents {
      items {
        holdingEventClass
        holdingEventsId
        eventDate
        seen
        saleEventID
      }
    }
  }
`;
