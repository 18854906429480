import React, { useEffect, useState } from 'react';
import { useLocalization } from '@progress/kendo-react-intl';
import { Dialog } from '@progress/kendo-react-dialogs';
import { useAppDispatch, useAppSelector } from '../../app/StoreHooks';
import {
  editProducedFodder,
  getProducedFodder,
  saveProducedFodder,
  selectSelfProducedFodder
} from '../../slices/FodderLogSlice';
import ProducedFodderLogGrid from '../../components/ProducedFodderLogGrid/ProducedFodderLogGrid';
import ProducedFodderForm from '../../components/ProducedFodderForm/ProducedFodderForm';
import {
  FeedDiaryTypeEnum,
  SelfProducedFodderType
} from '../../slices/data/FodderLogSliceData';
import { ADD_NEW, EDIT } from '../../languages/languages';
import GrantAccess from '../GrantAccess/GrantAccess';

const REQUIRED_ROLES: string[] = ['Admin', 'Farm Manager', 'Holding Owner', 'Farm Veterinary Inspector'];
const UPDATE_ROLES: string[] = ['Admin', 'Farm Manager', 'Holding Owner'];

const ProducedFodderLogGridContainer = () => {
  const localizationService = useLocalization();
  const dispatch = useAppDispatch();
  const selfProducedFodder = useAppSelector(selectSelfProducedFodder);

  useEffect(() => {
    const awaitData = () => {
      dispatch(getProducedFodder());
    };
    awaitData();
  }, []);

  const [addFodderLog, setAddFodderLog] = useState<boolean>(false);

  const closeFodderForm = () => {
    setAddFodderLog(false);
  };

  const openFodderForm = () => {
    setAddFodderLog(true);
  };

  const submitProducedFodderLog = (formData: SelfProducedFodderType) => {
    dispatch(
      saveProducedFodder({
        ...formData,
        feedDiaryType: FeedDiaryTypeEnum.SELFMADE
      })
    );
    closeFodderForm();
  };

  const [editFodderView, setEditFodderView] = useState<boolean>(false);
  const [logEntryToEdit, setLogEntryToEdit] = useState<SelfProducedFodderType>(null);

  const openEditFormView = (editItem: SelfProducedFodderType) => {
    setLogEntryToEdit(editItem);
    setEditFodderView(true);
  };

  const closeEditFormView = () => {
    setEditFodderView(false);
  };

  const submitEditedFodderLog = (formData: SelfProducedFodderType) => {
    dispatch(
      editProducedFodder({
        ...formData,
        feedDiaryType: FeedDiaryTypeEnum.SELFMADE
      })
    );
    closeEditFormView();
  };

  return (
    <GrantAccess haveRoles='some' requiredRoles={REQUIRED_ROLES}>
      <ProducedFodderLogGrid
        openLogForm={openFodderForm}
        data={selfProducedFodder}
        openEditLogForm={openEditFormView}
      />
      <GrantAccess haveRoles='some' requiredRoles={UPDATE_ROLES}>
        {addFodderLog && (
        <Dialog title={localizationService.toLanguageString(ADD_NEW, '')} onClose={closeFodderForm}>
          <ProducedFodderForm
            close={closeFodderForm}
            submit={submitProducedFodderLog}
          />
        </Dialog>
        )}
        {editFodderView && (
        <Dialog title={localizationService.toLanguageString(EDIT, '')} onClose={closeEditFormView}>
          <ProducedFodderForm
            editItem={logEntryToEdit}
            close={closeEditFormView}
            submit={submitEditedFodderLog}
          />
        </Dialog>
        )}
      </GrantAccess>
    </GrantAccess>
  );
};

export default ProducedFodderLogGridContainer;
