// import React from 'react';
// import { useLocalization } from '@progress/kendo-react-intk

const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const urlRegex = new RegExp(
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi
);
export const reqField: string = 'Това поле е задължително';

export function StringValidator(value: string) {
  return !value ? reqField : '';
}

export const emailValidator = (value: string) => {
  if (!value) {
    return reqField;
  }
  if (!emailRegex.test(value)) {
    return 'Моля въведете имейл адреса правилно';
  }
  return '';
};

export const NumberValidator = (value: number) =>
  (!value ? reqField : '');

export const CheckboxValidator: (value: any) => string = (value) =>
  (!value ? reqField : '');

export const UrlValidator = (value: string) => {
  if (!value) {
    return reqField;
  }
  if (!urlRegex.test(value)) {
    return 'Моля въведете правилен линк';
  }
  return '';
};

export const requiredValidator = (value) =>
  (value ? '' : 'Това поле е задължително');
