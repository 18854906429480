import { Button } from '@progress/kendo-react-buttons';
import {
  Field,
  Form,
  FormElement,
  FormRenderProps
} from '@progress/kendo-react-form';
import { useLocalization } from '@progress/kendo-react-intl';
import React from 'react';
import {
  ANIMAL_COUNT,
  CANCEL,
  DAYS_SPENT_IN_PASTURE,
  HERD_GROUP,
  PASTURE_ACREAGE,
  PASTURE_DATE_OF_ENTRY,
  PASTURE_DATE_OF_EXIT,
  PASTURE_GRASS_HEIGHT_IN,
  PASTURE_GRASS_HEIGHT_OUT,
  PASTURE_NAME,
  SAVE
} from '../../languages/languages';
import { PastureLogType } from '../../slices/data/PastureLogSliceData';
import {
  FormDatePicker, FormInput, FormNumericTextBox, FormTextArea
} from '../FormComponents/FormComponents';

type PastureLogFormProps = {
  editItem?: PastureLogType;
  submit: (formData: PastureLogType) => void;
  close: () => void;
};
const PastureLogForm = ({ editItem, submit, close }: PastureLogFormProps) => {
  const localizationService = useLocalization();

  return (
    <Form
      initialValues={editItem}
      onSubmit={submit}
      render={(formRenderProps: FormRenderProps) =>
        (
          <FormElement className='two-column-grid'>
            <fieldset className='k-form-fieldset'>
              <Field
                key='animalsCount'
                id='animalsCount'
                name='animalsCount'
                format='0'
                label={localizationService.toLanguageString(ANIMAL_COUNT, '')}
                component={FormNumericTextBox}
              />
              <Field
                key='groupName'
                id='groupName'
                name='groupName'
                label={localizationService.toLanguageString(HERD_GROUP, '')}
                component={FormTextArea}
              />
              <Field
                key='pastureName'
                id='pastureName'
                name='pastureName'
                label={localizationService.toLanguageString(PASTURE_NAME, '')}
                component={FormInput}
              />
              <Field
                key='dateIn'
                id='dateIn'
                name='dateIn'
                label={localizationService.toLanguageString(
                  PASTURE_DATE_OF_ENTRY,
                  ''
                )}
                component={FormDatePicker}
                format='dd-MM-yyyy'

              />
            </fieldset>
            <fieldset className='k-form-fieldset'>
              <Field
                key='dateOut'
                id='dateOut'
                name='dateOut'
                label={localizationService.toLanguageString(
                  PASTURE_DATE_OF_EXIT,
                  ''
                )}
                component={FormDatePicker}
                format='dd-MM-yyyy'

              />
              <Field
                key='daysIn'
                id='daysIn'
                name='daysIn'
                format='0'
                label={localizationService.toLanguageString(
                  DAYS_SPENT_IN_PASTURE,
                  ''
                )}
                component={FormNumericTextBox}
              />
              <Field
                key='pastureSize'
                id='pastureSize'
                name='pastureSize'
                label={localizationService.toLanguageString(PASTURE_ACREAGE, '')}
                component={FormNumericTextBox}
              />
              <Field
                key='grassHeightIn'
                id='grassHeightIn'
                name='grassHeightIn'
                label={localizationService.toLanguageString(
                  PASTURE_GRASS_HEIGHT_IN,
                  ''
                )}
                component={FormNumericTextBox}
              />
              <Field
                key='grassHeightOut'
                id='grassHeightOut'
                name='grassHeightOut'
                label={localizationService.toLanguageString(
                  PASTURE_GRASS_HEIGHT_OUT,
                  ''
                )}
                component={FormNumericTextBox}
              />
            </fieldset>
            <div className='k-form-buttons mt-0'>
              <Button
                name='PastureLogAdd'
                type='submit'
                title={localizationService.toLanguageString(SAVE, '')}
                primary
                disabled={!formRenderProps.allowSubmit}
                icon='save'
              >
                {localizationService.toLanguageString(SAVE, '')}
              </Button>
              <Button
                name='PastureLogCancel'
                type='submit'
                title={localizationService.toLanguageString(CANCEL, '')}
                primary
                look='outline'
                icon='cancel'
                onClick={close}
              >
                {localizationService.toLanguageString(CANCEL, '')}
              </Button>
            </div>
          </FormElement>
        )}
    />
  );
};

PastureLogForm.defaultProps = {
  editItem: {}
};

export default PastureLogForm;
