import { Button } from '@progress/kendo-react-buttons';
import { useLocalization } from '@progress/kendo-react-intl';
import React, { useEffect, useState } from 'react';

import { CONFIRM, FORWARD } from '../../languages/languages';
import { IBatch, IBatchAnimal } from '../../slices/data/ButcheryProcessSliceData';
import { ageClass } from '../../utils/animals';
import SeuropGrid from '../AnimalGrid/SeuropGrid';
import ConfirmDialog from '../ConfirmDialog/ConfirmDialog';

const SeuropTab = ({ batch, onNext }: { batch: IBatch; onNext: () => void }) => {
  const $t = useLocalization();
  const [items, setItems] = useState<IBatchAnimal[]>([]);
  const [showSaveMessage, setShowSaveMessage] = useState(false);

  useEffect(() => {
    setItems(batch?.animals);
  }, []);

  useEffect(() => {
    batch.animals.forEach((a) => {
      a.ageClass = ageClass(a.age, a.sex, a.isCastrated);
    });
    setItems([...batch.animals]);
  }, [batch.animals]);

  const onSave = (result: boolean) => {
    if (result) {
      batch.animals = [...items];
      onNext();
    }
  };

  const onChange = (data: IBatchAnimal[]) => {
    setItems([...data]);
  };

  return (
    <div className='pl-l pr-l'>
      <div className='row'>
        <div className='col-12'>
          <SeuropGrid animals={items} onSelect={onChange} />
        </div>
      </div>
      <div className='row'>
        <div className='col-12 d-flex justify-content-center mt-l'>
          <Button primary onClick={() => setShowSaveMessage(true)}>
            {$t.toLanguageString(FORWARD, '')}
          </Button>
        </div>
      </div>
      {showSaveMessage && (
        <ConfirmDialog
          message='Желаете ли да продължите?'
          title={$t.toLanguageString(CONFIRM, '')}
          onResult={onSave}
        />
      )}
    </div>
  );
};

export default SeuropTab;
