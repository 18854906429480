import * as React from 'react';
import { Dialog, DialogActionsBar } from '@progress/kendo-react-dialogs';
import { Button } from '@progress/kendo-react-buttons';

type RejectionConfirmationProps = {
  accept: () => void;
  close: () => void;
};

const RejectionConfirmation = ({
  accept,
  close
}: RejectionConfirmationProps) =>
  (
    <Dialog title='Моля потвърдете избора си' onClose={close}>
      <p style={{ margin: '25px', textAlign: 'center' }}>
        Сигурни ли сте че искате да откажете сделката
      </p>
      <DialogActionsBar>
        <Button className='k-button' onClick={close}>
          Не
        </Button>
        <Button className='k-button' onClick={accept}>
          Да
        </Button>
      </DialogActionsBar>
    </Dialog>
  );

export default RejectionConfirmation;
